import { catchError, retry } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { CommonService } from '../services/common.service';
import { IHttpError } from '../models/http-error.model';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(public commonservice: CommonService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.commonservice.getAccessToken();
    if (token) {
      request = request.clone({
        setHeaders: {
          'Authorization': `Bearer ${token}`,
          'Access-Control-Allow-Origin': '*'
        }
      });
    }
    return next.handle(request).pipe(
      retry(3),
      catchError((error: HttpErrorResponse) => {
        let errorMsg = '';
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMsg = `Error: ${error.error.message}`;
        } else {
          // server-side error
          errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
        }
        console.error(errorMsg);
        const err: IHttpError = {
          ...error.error
        };
        return throwError(err);
      })
    );
  }
}
