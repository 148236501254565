import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportShipmentComponent } from './components/reportShipment/reportShipment.component';
import { AlertsRoutingModule } from './reportShipment-routing.module';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { AgmCoreModule } from '@agm/core';
import { environment } from 'src/environments/environment';
import { NgxEchartsModule } from 'ngx-echarts';
import { SharedModule } from 'src/app/shared/shared.module';
import { ReportShipmentPdfComponent } from './components/report-shipment-pdf/report-shipment-pdf.component';
import { CalendarModule } from 'primeng/calendar';
import { OverlayPanelModule } from 'primeng/overlaypanel';

@NgModule({
  declarations: [ReportShipmentComponent, ReportShipmentPdfComponent],
  imports: [
    CommonModule,
    AlertsRoutingModule,
    TableModule,
    DropdownModule,
    FormsModule,
    SharedModule,
    CalendarModule,
    OverlayPanelModule,
    AgmCoreModule.forRoot({
      apiKey: environment.mapAPIKey,
      libraries: ['visualization']
    }),
    NgxEchartsModule
  ]
})
export class ReportShipmentModule {

 }
