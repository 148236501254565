import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ApiService } from 'src/app/core/services/api.service';
import { CommonService } from 'src/app/core/services/common.service';

@Injectable({
  providedIn: 'root'
})
export class EdgeService {
  constructor(private http: HttpClient, private commonService: CommonService) { }

  /**
   * @description get all edgelist
   */
  getAllEdge() {
    const { user } = this.commonService.getCurrentUserData();
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
     // params: new HttpParams({fromString:`email=${ user.officialEmail}`})
    };
    return this.http.get(ApiService.URLs.edgeManagement, options);
  }

  /**
   * @description get all edgelist by status
   */
  getAllEdgeByStatus(status) {
    const { user } = this.commonService.getCurrentUserData();
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      params: new HttpParams({fromString:`email=${ user.officialEmail}&status=${status}`})
    };
    return this.http.get(ApiService.URLs.edgeManagement, options);
  }

  /**
   * @description get all broker node
   */
  getBrokerNode() {
    const { user } = this.commonService.getCurrentUserData();
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      params: new HttpParams({fromString:`email=${ user.officialEmail}`})
    };
    return this.http.get(ApiService.URLs.getBrokerNode, options);
  }

  getEndpointByEdge(blSerialNumber) {
    const { user } = this.commonService.getCurrentUserData();
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      params: new HttpParams({fromString:`email=${ user.officialEmail}&blocklockId=${blSerialNumber}`})
    };
    return this.http.get(ApiService.URLs.endPointManagement, options);
  }



  updateDevice(data) {
    const body = JSON.stringify(data);
    const { user } = this.commonService.getCurrentUserData();
    // const options = {
    //   headers: new HttpHeaders().set('Content-Type', 'application/json'),
    //   params: new HttpParams({fromString:`email=${ user.officialEmail}`})
    // };
    const token = localStorage.getItem('token');
    const headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
       'Authorization': "Bearer "+ token,
    });
    const options = {
      headers:headers_object,
      params: new HttpParams({ fromString: `trackerId=${data._id}` })
    };
  return this.http.put(ApiService.URLs.updateEndPointManagement, {}, options);
  }

  updateEdge(data) {
    const body = JSON.stringify(data);
    const { user } = this.commonService.getCurrentUserData();
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      params: new HttpParams({fromString:`email=${ user.officialEmail}&tenantName=${user.organizationName}`})
    };
    return this.http.post(ApiService.URLs.updateEdgeManagement, body, options);
  }

  deleteDevice(data){
    const body = JSON.stringify(data);
    const { user } = this.commonService.getCurrentUserData();
    // const options = {
    //   headers: new HttpHeaders().set('Content-Type', 'application/json'),
    //   params: new HttpParams({fromString:`email=${ user.officialEmail}`})
    // };

    const token = localStorage.getItem('token');
    const headers_object = new HttpHeaders({
      'Content-Type': 'application/json',
       'Authorization': "Bearer "+ token,
    });
    const options = {
      headers:headers_object,
      params: new HttpParams({ fromString: `trackerId=${data._id}` })
    };
    return this.http.put(ApiService.URLs.updateEndPointManagement, {}, options);
  }

  getAllEdgeStats() {
    const { user } = this.commonService.getCurrentUserData();
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      params: new HttpParams({fromString:`email=${ user.officialEmail}`})
    };
    return this.http.get(ApiService.URLs.edgeManagementStats, options);
  }

  getEdgeByStatus(status) {
    const { user } = this.commonService.getCurrentUserData();
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      params: new HttpParams({fromString:`email=${ user.officialEmail}&status=${status}`})
    };
    return this.http.get(ApiService.URLs.edgeManagement, options);
  }
}
