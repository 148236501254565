import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ApiService } from 'src/app/core/services/api.service';
import { CommonService } from 'src/app/core/services/common.service';
import { BehaviorSubject } from 'rxjs';
import { INotifiactionAction } from 'src/app/shared/models/side-nav.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationAlertService {

  public notification = new BehaviorSubject<INotifiactionAction>({ type: null, notification: null, isOpenViewModal: false,  shipmentId: null });
  public notifications = this.notification.asObservable();

  constructor(private http:HttpClient, private commonService:CommonService) { }

  notificationEvent(data: INotifiactionAction) { this.notification.next(data); }  

  /**
   * @description add notification alert
   */
  addNotificationAlert(notificationAlert) {
    return this.http.post(ApiService.URLs.notificationAlert, notificationAlert);
  }
  /**
   * @description get notification alert
  */
  getNotificationAlert() {
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    }
    return this.http.get(ApiService.URLs.notificationAlert, options);
  }
  /**
   * @description update notification alert
   */
  dismissNotificationAlert(alert) {
    return this.http.put(`${ApiService.URLs.notificationAlert}/${alert.id}`, alert);
  }
  /**
   * @description delete notification alert
   */
  deleteNotificationAlert(id) {
    return this.http.delete(`${ApiService.URLs.notifications}/${id}`);
  }

  /**
   * @description update notification read status
  */
  updateNotificationReadStatus(id) {
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    }
    return this.http.put(`${ApiService.URLs.notifications}/${id}`, options, {});
  }

  /**
   * @description update notification read status
  */
  getAllNotifications(query?) {
    const options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    const url = query ? `${ApiService.URLs.notifications}?${query}` : `${ApiService.URLs.notifications}`; 
    return this.http.get(url, options);
  }
}
