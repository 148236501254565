<div class="position-relative h-100">
  <app-side-nav [showManageWidgets]="showManageWidgets"></app-side-nav>
  <div class="content-wrapper main position-relative">
    <section class="content">
      <div class="container-fluid">
        <router-outlet></router-outlet>
      </div>
    </section>

    <ngx-spinner
      bdColor="rgba(0, 0, 0, 0.8)"
      size="default"
      type="square-loader"
      [fullScreen]="false">
      <p style="color: white">Loading...</p>
    </ngx-spinner>
  </div>

  <app-footer></app-footer>
</div>
