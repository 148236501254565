<div class="tab-pane container-fluid active" id="system_log_table">
    <ng-scrollbar #scrollable track="horizontal" visibility="always" class="tabContent-scrollbar text-nowrap">
      <p-table [value]="(systemLogData$ | async)" [rows]="config?.itemsPerPage"
        [paginator]="true" [loading]="!(systemLogData$ | async)" [resizableColumns]="true" columnResizeMode="expand">
        <ng-template pTemplate="header">
          <tr>
            <th pResizableColumn>Username</th>
            <th pResizableColumn nowrap>IP Address</th>
            <th pResizableColumn>Browser</th>
            <th pResizableColumn nowrap>Event Time</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-userLog>
          <tr>
            <td class="align-middle">{{userLog?.email}}</td>
            <td class="align-middle">{{userLog?.ip}}</td>
            <td class="align-middle">{{userLog?.browser}}</td>
            <td class="align-middle">{{userLog?.time | date:"yyyy-MM-dd hh:mm:ss a" }}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="4" class="text-center">No logs found.</td>
          </tr>
        </ng-template>
      </p-table>
    </ng-scrollbar>
</div>
