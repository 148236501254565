<div class="tab-pane container-fluid active" id="communication_chain">
    <ng-scrollbar #scrollable track="horizontal" visibility="always" class="tabContent-scrollbar text-nowrap">
      <p-table [value]="eventData$ | async" [rows]="10"
        [paginator]="false" [loading]="!(eventData$ | async)" [resizableColumns]="true" columnResizeMode="expand">
        <ng-template pTemplate="header">
          <tr>
            <th pResizableColumn>Sensor Name</th>
            <th pResizableColumn>Sensor Type</th>
            <th pResizableColumn>Tracker Data</th>
            <th pResizableColumn>Sensor Data</th>
            <th pResizableColumn [width]="100">Block</th>
            <th pResizableColumn [width]="270">Communication Meta Data</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-event>
          <tr>
            <td class="align-middle">{{event.sensorName}}</td>
            <td class="align-middle">{{event.sensorType}}</td>
            <td class="align-middle">{{event.trackerData}}</td>
            <td class="align-middle">{{event.sensorData}}</td>
            <td [width]="100" class="align-middle">
                <button type="button" data-toggle="modal"
                data-target="#blockModal" (click)="openBlock(event)"
                class="btn primary-btn one small">
                <div class='insider'></div>
                Block
              </button>
            </td>

            <td [width]="270" class="align-middle">
                <button type="button" data-toggle="modal" (click)="openMetaData(event)" data-target="#metadataModalEvent"
                class="btn primary-btn one small">
                <div class='insider'></div>
                <i class="fas fa-eye-slash pr-1"></i> Communication Meta Data
              </button>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="6" class="text-center">No data found.</td>
            </tr>
        </ng-template>
      </p-table>
      <p-paginator
          [pageLinkSize]="10"
          [rows]="1"
          [totalRecords]="config?.totalItems"
          (onPageChange)="getMoreEvents($event)"
        >
        </p-paginator>
    </ng-scrollbar>
</div>

<!-- Popups code -->
<div class="modal fade" id="metadataModalEvent" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header bg-gradient-primary">
                <h6 class="modal-title" id="exampleModalLongTitle">Communication Meta Data</h6>
                <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form role="form">
                    <div class="row">
                        <label class="col-md-3 text-right text-capitalize small">Device Token:</label>
                        <div class="col-md-9 small px-4">
                            <label class="font-weight-normal small">{{deviceToken}}</label>
                        </div>
                    </div>

                    <div class="row">
                        <label class="col-md-3 text-right text-capitalize small">Timestamp:</label>
                        <div class="col-md-9 small px-4">
                            <label class="font-weight-normal small">{{timestamp}}</label>
                        </div>
                    </div>

                    <div class="row">
                        <label class="col-md-3 text-right text-capitalize small">Device UUID:</label>
                        <div class="col-md-9 small px-4">
                            <label class="font-weight-normal small">{{deviceUUID}}</label>
                        </div>
                    </div>

                    <!-- <div class="row" hidden>
                        <label class="col-md-3 text-right text-capitalize small">Device Id:</label>
                        <div class="col-md-9 small px-4">
                            <label class="font-weight-normal small"></label>
                        </div>
                    </div> -->

                    <!-- <div class="table-responsive text-nowrap">
                        <table class="table table-border">
                            <thead class="bg-light">
                                <tr >
                                    <th>#</th>
                                    <th>Sensor</th>
                                    <th>Value</th>
                                    <th>Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>


                            </tbody>
                        </table>
                    </div> -->
                </form>
            </div>
            <div class="modal-footer p-2">
                <button type="button" class="btn btn-secondary px-4" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="blockModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header bg-gradient-primary">
                <h6 class="modal-title" id="exampleModalLongTitle">Communication Block</h6>
                <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form role="form">
                    <div class="row">
                        <label class="col-md-3 text-right text-capitalize small">Device token:</label>
                        <div class="col-md-9 small px-4">
                            <label class="font-weight-normal small">{{deviceToken}}</label>
                        </div>
                    </div>
                    <div class="row">
                        <label class="col-md-3 text-right text-capitalize small">Signature:</label>
                        <div class="col-md-9 small px-4">
                            <label class="font-weight-normal small">{{signature}}</label>
                        </div>
                    </div>
                    <div class="row">
                        <label class="col-md-3 text-right text-capitalize small">Previous Signature:</label>
                        <div class="col-md-9 small px-4">
                            <label class="font-weight-normal small">{{previousSignature}}</label>
                        </div>
                    </div>

                    <div class="row">
                        <label class="col-md-3 text-right text-capitalize small">Genesis Hash:</label>
                        <div class="col-md-9 small px-4">
                            <label class="font-weight-normal small">{{genisisToken}}</label>
                        </div>
                    </div>
                    <div class="row">
                        <label class="col-md-3 text-right text-capitalize small">Time Stamp:</label>
                        <div class="col-md-9 small px-4">
                            <label class="font-weight-normal small">{{timestamp}}</label>
                        </div>
                    </div>

                </form>
            </div>
            <div class="modal-footer p-2">
                <button type="button" class="btn btn-secondary px-4" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
