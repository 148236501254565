<form id="update_user" role="form">

    <div class="row mt-2">
        <div class="form-group col-md-6" id="name">
            <!-- <span class="badge text-danger">*</span> -->
            <label class="has-float-label">
                <input type="text" class="form-control" [(ngModel)]="fullname" name="realname" pattern="^[a-zA-Z\s]+$"
                    placeholder="User Full Name" required>
                <span>User Full Name</span>
            </label>
        </div>
        <div class="form-group col-md-6" id="email">
            <!-- <span class="badge text-danger">*</span> -->
            <label class="has-float-label">
                <input type="text" readonly class="form-control" [value]="email" name="email" placeholder="Email" />
                <span>Email</span>
            </label>
            <!-- <small class="w-100" ><i>This email will be your user name.</i></small> -->

        </div>
    </div>
    <div class="row mt-2">
        <div class="form-group has-float-label col-md-6 mb-3 passwField" id="phone_number">
            <input type="number" onKeyPress="if(this.value.length==10) return false;" class="form-control"
                [(ngModel)]="phoneno" name="phone" required placeholder="Phone Number">
            <label for="">Phone Number</label>
        </div>
        <div class="form-group col-md-6 has-float-label">
            <select class="form-control" name="levelName" [(ngModel)]="selectaccess" required>
                <option value="" hidden>Select Access Level</option>
                <option [disabled]="(data.levelname === userLevel.SUPERADMIN) || loaderFlag" *ngFor="let data of accessData"
                    value="{{data.levelname}}">
                    {{data?.levelname}}</option>
            </select> <i class="fa fa-circle-o-notch fa-spin" *ngIf="loaderFlag"></i>
            <span>User Role</span>
        </div>
    </div>
    <div class="row py-1">
        <ng-container *ngIf="!editFlag">
            <div class="form-group has-float-label col-md-4 passwField" hidden>
                <input [type]="passwordEye ? 'text' : 'password'" class="form-control" [placeholder]="'Password' ">
                <label>Password</label>
                <i class="fa fa-eye password-field-icon"
                    [ngClass]="{'fa-eye-slash': !passwordEye,'fa-eye': passwordEye}"></i>

            </div>
            <div class="form-group has-float-label col-md-4 passwField" hidden>
                <input [type]="confirmPasswordEye ? 'text' : 'password'" class="form-control"
                    placeholder="Verify Password">
                <label for="">Verify Password</label>
                <i class="fa fa-eye password-field-icon"
                    [ngClass]="{'fa-eye-slash': !confirmPasswordEye,'fa-eye': confirmPasswordEye}"></i>
            </div>
            <div class="form-group col-md-12 mb-3" hidden>
                <span class="small">Note: Use 8 or more characters
                    with
                    a mix of letters,
                    numbers & symbols.</span>
            </div>
        </ng-container>

    </div>

    <div class="row py-1">
        <div class="form-group col-md-12" id="address">
            <label class="has-float-label">
                <textarea class="form-control" [(ngModel)]="address" name="address"></textarea>
                <span>Address</span>
            </label>
        </div>
    </div>

    <div class="row py-1">
        <div class="form-group col-md-6" id="city">
            <label class="has-float-label">
                <input type="text" class="form-control" name="city" [(ngModel)]="city">
                <span>City</span>
            </label>
        </div>
        <div class="form-group col-md-6" id="zip">
            <label class="has-float-label">
                <input type="text" class="form-control" name="zip" [(ngModel)]="zip">
                <span>Zip</span>
            </label>
        </div>

    </div>

    <div class="row py-1">
        <div class="form-group col-md-6" id="state">
            <label class="has-float-label">
                <input type="text" class="form-control" name="state" [(ngModel)]="state">
                <span>State</span>
            </label>
        </div>
        <div class="form-group col-md-6" id="country">
            <label class="has-float-label">
                <select class="form-control" name="country" placeholder="Select country" [(ngModel)]="selectcountry">
                    <option value="">Select Country</option>
                    <option *ngFor="let country of selectcountries" [value]="country.name">{{country.name}}</option>
                </select>
                <span>Select country</span>
            </label>
        </div>
    </div>

    <div class="row">
        <div class="form-group col-md-12" *ngIf="selectaccess.toLowerCase() === 'user' && showAssignShipment">
            <label class="has-float-label">
                <p-listbox [options]="shipmentList" [ngModelOptions]="{standalone: true}"
                    [(ngModel)]="selectedShipments" optionLabel="shipmentName" [checkbox]="true" [filter]="true"
                    [multiple]="true" [listStyle]="{'max-height':'250px'}"></p-listbox>
                <span>Assign Shipment</span>
            </label>
        </div>
    </div>

    <div class="row py-1">
        <div class="form-group mb-0 col pt-2">
            <input type="checkbox" [(ngModel)]="sendAlerts" class="ml-2 mr-2 sendAlertsCheck"
                (change)="sendAlerts != sendAlerts" name="sendAlerts" [checked]="sendAlerts">
            <span>Enable email alerts</span>
            <input type="checkbox" [(ngModel)]="sendReport" class="ml-4 mr-2 sendReportCheck"
                (change)="sendReport != sendReport" name="sendReport" [checked]="sendReport">
            <span>Enable daily report</span>
        </div>
    </div>
    <hr/>
    <div class="row">
        <div class="col-md-12 text-right">
            <button type="button" class="btn bg-gradient-secondary text-uppercase px-4 mr-2" (click)="closeModalEvent.emit(true);">Cancel</button>
            <button type="submit" id="save_button" class="btn bg-gradient-primary text-uppercase px-4 mx-0" data-dismiss="modal"
                [disabled]="selectaccess == '' || email == '' || fullname == ''"
                (click)="addNewUser()"
            >Update</button>
        </div>
    </div>
</form>