import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from 'primeng/api';
import { API_ERROR } from 'src/app/core/constants/global-error.constants';
import { CommonService } from 'src/app/core/services/common.service';
import { DashboardService } from 'src/app/modules/dashboard/services/dashboard.service';
import { INotifiactionAction, INotification } from 'src/app/shared/models/side-nav.model';
import { NotificationAlertService } from '../../services/notification-alert.service';

@Component({
  selector: 'app-alerts-notification',
  templateUrl: './alerts-notification.component.html',
  styleUrls: ['./alerts-notification.component.css']
})
export class AlertsNotificationComponent implements OnInit {

  notificationAlerts: INotification[] = [];
  isLoading = true;
  totalAlerts = 0;
  selectedNotification: INotification;
  page = 1;
  userInfo;

  constructor(
    private notificationalertService: NotificationAlertService,
    private confirmationService: ConfirmationService,
    private dashboardService: DashboardService,
    private commonService: CommonService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    const { user } = this.commonService.getCurrentUserData();
    this.userInfo = user;
    this.getNotificationsAlerts();
    this.getNotifications();
    this.notificationalertService.notifications.subscribe((event: INotifiactionAction) => {
      if (event.type && event.notification) {
        if (event.isOpenViewModal) {
          this.selectedNotification = event.notification;
          setTimeout(() => { this.notificationalertService.notificationEvent({ type: null, notification: null, isOpenViewModal: false, shipmentId: event.shipmentId }) }, 0);
        }
        this.getNotificationsAlerts(this.page);
      }
    });
  }

  getNotificationsAlerts(index?: number) {
    this.page = index ? index : this.page;
    this.isLoading = true;
    this.notificationalertService.getAllNotifications(`limit=10&page=${this.page}&userLevel=${this.userInfo.userLevel}&userId=${this.userInfo.id}`).subscribe((data: any) => {
      this.totalAlerts = data?.meta.totalItems;
      this.notificationAlerts = data?.items;
      // this.notificationAlerts.sort((x, y) => {
      //   return (x.readStatus === y.readStatus) ? 0 : x.readStatus ? 1 : -1;
      // });
      this.isLoading = false;
    }, (error) => {
      if (error?.message === API_ERROR.USER_LOGOUT) {
        this.commonService.logout(API_ERROR.USER_LOGOUT)
      }
    });
  }

  dismissAlert(notification: INotification) {
    this.selectedNotification = notification;
    if (notification.hasOwnProperty('readStatus') && notification?.readStatus === false) {
      this.notificationalertService.updateNotificationReadStatus(notification.id).subscribe((data: INotification) => {
        this.notificationalertService.notificationEvent({ type: 'Update', notification: data, isOpenViewModal: false,  shipmentId: data.shipmentId });
        this.getNotificationsAlerts(this.page);
      }, (error) => {
        if (error?.message === API_ERROR.USER_LOGOUT) {
          this.commonService.logout(API_ERROR.USER_LOGOUT)
        }
      });
    }
  }

  getNotifications(): void {
    this.dashboardService.getSmartNotification().subscribe((res: INotification) => {
      if (res && this.page === 1) {
        this.notificationAlerts.unshift(res);
      }
    });
    this.dashboardService.requestLatestEvent().subscribe((res: INotification) => {
      let events = res[2];
      for (let event of events) {
        this.notificationAlerts.unshift(event);
      }
    });
  }

  deleteAlert(id: any) {
    this.notificationalertService.deleteNotificationAlert(id).subscribe((data: INotification) => {
      this.toastr.success('Notification deleted successfully', ' ', {
        timeOut: 3000
      });
      this.notificationalertService.notificationEvent({ type: 'Delete', notification: data, isOpenViewModal: false,  shipmentId: data.shipmentId });
      this.getNotificationsAlerts();
    }, (error) => {
      if (error?.message === API_ERROR.USER_LOGOUT) {
        this.commonService.logout(API_ERROR.USER_LOGOUT)
      }
      this.toastr.error('Notification could not be deleted at this moment.', ' ', {
        timeOut: 3000
      });
    });
  }

  opendeleteModal(alert: any) {
    this.confirmationService.confirm({
      message: `Delete this notification:<br><b>${alert?.message || ''}</b>`,
      header: `Delete`,
      acceptButtonStyleClass: "p-button-outlined p-button-danger",
      rejectButtonStyleClass: "p-button-outlined p-button-secondary",
      accept: () => {
        this.deleteAlert(alert.id);
      }
    });
  }

  getMoreAlerts(event) {
    this.isLoading = true;
    let page = event.page + 1;
    this.getNotificationsAlerts(page);
  }

}
