import { IHttpResponse } from './../../../shared/models/http-response.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiService } from 'src/app/core/services/api.service';
import { CommonService } from 'src/app/core/services/common.service';
import { Socket } from 'ngx-socket-io';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
// import { EventList } from '../models/events.model';

@Injectable({
  providedIn: 'root'
})
export class CommentsService {

  constructor(private http: HttpClient, private commonService: CommonService, private socket: Socket) { }

  addComment(comment){
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    };
    return this.http.post(`${ApiService.URLs.addComment}`, comment, options); 
  }

  getShipmentComments(shipmentId){
    return this.http.get(`${ApiService.URLs.addComment}?sid=${shipmentId}`);
  }

}

