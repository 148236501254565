import { Component, OnInit, ViewChild } from '@angular/core';
import { NgTerminal } from 'ng-terminal';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-terminal',
  templateUrl: './terminal.component.html',
  styleUrls: ['./terminal.component.css']
})
export class TerminalComponent implements OnInit {

  @ViewChild('term', { static: true }) child: NgTerminal;

  constructor() { };


  ngOnInit() {
    this.bindTeminal();
  }

  bindTeminal() {
    // const socket;
    const terminalContainer = document.getElementById('terminal-container');
    //@ts-ignore
    const term = new Terminal({ cursorBlink: true });
    term.open(terminalContainer);
    // term.fit();
    //@ts-ignore
    const socket = io.connect(environment.terminalUrl);
    // tslint:disable-next-line: only-arrow-functions
    socket.on('connect', function () {
      term.write('\r\n*** Connected to backend***\r\n');

      // Browser -> Backend
      // tslint:disable-next-line: only-arrow-functions
      term.on('data', function (data) {
        //console.log(data);
        // alert("Not allowd to write. Please don't remove this alert without permission of Ankit or Samir sir. It will be a problem for server'");
        socket.emit('data', data);
      });

      // Backend -> Browser
      // tslint:disable-next-line: only-arrow-functions
      socket.on('data', function (data) {
        term.write(data);
      });

      // tslint:disable-next-line: only-arrow-functions
      socket.on('disconnect', function () {
        term.write('\r\n*** Disconnected from backend***\r\n');
      });
    });
  }

}
