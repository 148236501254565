import { Observable, throwError } from 'rxjs';
import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { NgScrollbar } from 'ngx-scrollbar';
import { APIDefinition, Columns, Config } from 'ngx-easy-table';
import { ToastrService } from 'ngx-toastr';
import { ReportsService } from 'src/app/modules/reports/reports.service';
import { map, catchError } from 'rxjs/operators';
import { CommonService } from 'src/app/core/services/common.service';
import { API_ERROR } from 'src/app/core/constants/global-error.constants';


@Component({
  selector: 'app-communication-chain',
  templateUrl: './communication-chain.component.html',
  styleUrls: ['./communication-chain.component.css']
})
export class CommunicationChainComponent implements OnInit {

  // ngx table configuration
  public configuration: Config;
  public endPointcolumns: Columns[];
  public actInactDevices = [77, 33];

  @ViewChild(NgScrollbar, { static: true }) scrollbarRef: NgScrollbar;

  @ViewChild('table', { static: true }) table: APIDefinition;
  @ViewChild('levelHeaderActionTemplate', { static: true }) levelHeaderActionTemplate: TemplateRef<
    any>;
  eventData$: Observable<any>;
  config: { itemsPerPage: number; currentPage: number; totalItems: number; };
  deviceToken: any;
  currentHash: any;
  previousHash: any;
  timestamp: any;
  deviceUUID: any;
  signature: any;
  previousSignature: any;
  genisisToken: any;

  constructor(
    private toastr: ToastrService,
    private reportsService : ReportsService,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.getAllEventList();
  }


  /**
  * @description get all devices list
  */

  getAllEventList(page?: number) {
    let pageNo = page ? page : 1 ;
    this.eventData$ = this.reportsService.getReportInfo(`event&page=${pageNo}&limit=10`).pipe(
      map((res: any) => {
        const eventData = res.items || [];
        eventData.splice(0, 1);
        if (eventData.length) {
          this.config = {
            itemsPerPage:10,
            currentPage:1,
            totalItems: res.meta.totalPages,
          }
        }
        try {
          for (let i = 0; i < eventData.length; i++) {
            const parsedData = this.commonService.parseEventPayload(eventData[i].payload);
            if (parsedData) {
              eventData[i] = { ...eventData[i], ...parsedData }
            } else {
              eventData.splice(i, 1);
            }
          }
          return eventData;
        } catch (error) {
          if (error?.message === API_ERROR.USER_LOGOUT) {
            this.commonService.logout(API_ERROR.USER_LOGOUT);
          }
          console.log('error', error);
        }
      }),
      catchError(error => {
        console.log('error', error);
        if (error?.message === API_ERROR.USER_LOGOUT) {
          this.commonService.logout(API_ERROR.USER_LOGOUT);
        } else {
          this.toastr.error('Event information cannot be retrieved at this moment. Please try again.', ' ', {
            timeOut: 3000
          });
          return throwError(error);
        }
      })
    );
  }

  getMoreEvents(event : any){
    let page = event.page + 1 ;
    this.getAllEventList(page)
  }

  /**
    * Description: open block data
   * @description open block data
   * @param data selected data to populate block
   */
  openBlock(data) {
    this.deviceToken = data.deviceToken;
    this.currentHash = data.currentHash;
    this.previousHash = data.previousHash;
    this.timestamp = data.timeStamp;
    this.signature = data.signature;
    this.previousSignature = data.previousSign;
    this.genisisToken = data.genesisBlk;
  }

  /**
    * Description: open meta data
   * @description open meta data
   * @param data selected data to populate meta data
   */
  openMetaData(data) {
    // this.deviceName = data.model_name;
    // this.mac_addr = data.mac_addr;
    this.deviceToken = data.deviceToken;
    this.deviceUUID = data.deviceUUID;
    this.timestamp = data.timeStamp;
  }
}
