import { TableModule } from 'primeng/table';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { HttpClientModule } from '@angular/common/http';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { FooterComponent } from './components/footer/footer.component';
import { AppLayoutComponent } from './components/app-layout/app-layout.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { RouterModule } from '@angular/router';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PipesModule } from './pipes/pipes.module';
import { BadgeModule } from 'primeng/badge';
import { MapComponent } from './components/map/components/map.component';
import { SignupUserComponent } from './components/signup-user/signup-user.component';
import { DropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';
import { ListboxModule } from 'primeng/listbox';
@NgModule({
  declarations: [SideNavComponent, FooterComponent, AppLayoutComponent, MapComponent, SignupUserComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgScrollbarModule,
    NgxSpinnerModule,
    RouterModule,
    OverlayPanelModule,
    TableModule,
    PipesModule,
    BadgeModule,
    DropdownModule,
    DialogModule,
    ListboxModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SideNavComponent,
    HttpClientModule,
    FooterComponent,
    MapComponent,
    SignupUserComponent
  ],
})
export class SharedModule {}
