<section class="content mx-4" id="smart_contract">
  <div class="card no-border">
    <div class="card-body p-0 tab-content" id="tab1content">
      <div class="row">
        <div class="col-md-12 bg-white p-3 tab-content cus-rounded-left cus-rounded-right vhHeight">
          <div class="tab-pane container-fluid active">
            <div class="form-group col-12 text-right">

              <button type="button" class="btn primary-btn one text-uppercase" title="ADD NEW SMART CONTRACT"
                (click)="addNewContract()">
                <i class="fas fa-plus pr-2"></i> ADD NEW SMART CONTRACT
              </button>
              <div class="searchDiv ml-3">
                <input pInputText type="text" id="globalSearch"
                  (input)="smartContract.filterGlobal($event.target.value, 'contains')"
                  placeholder="Search by Name or Type"
                  [ngClass]="{ open: isopenSearch }"
                />
                <button type="reset" title="Search"
                  class="searchBtn btn primary-btn one text-uppercase"
                  (click)="isopenSearch = !isopenSearch"
                ><i class="fas" [ngClass]="{'fa-search-minus': isopenSearch, 'fa-search-plus': !isopenSearch}"></i></button>
              </div>
            </div>
            <ng-scrollbar #scrollable track="horizontal" visibility="always" class="tabContent-scrollbar text-nowrap">
              <p-table #smartContract [value]="smartContracts"
                [globalFilterFields]="['smartContractNm', 'smartContractDesc']" [rows]="10" [paginator]="true"
                [loading]="!smartContracts" [resizableColumns]="true" columnResizeMode="expand">
                <ng-template pTemplate="header">
                  <tr>
                    <th pResizableColumn>Smart Contract Name</th>
                    <th pResizableColumn>Description</th>
                    <th pResizableColumn>Status</th>
                    <th pResizableColumn>Created At</th>
                    <!--<th>Modify At</th> -->
                    <th pResizableColumn>Action</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-event>
                  <tr>
                    <td class="align-middle">{{event.smartContractNm}}</td>
                    <td class="align-middle">{{event.smartContractDesc}}</td>
                    <td class="align-middle">{{event.isEnableRules ? "Enabled" : "Disabled"}}</td>
                    <td class="align-middle">{{event.createdAt | date: "dd MMM y, HH:mm:ss"}}</td>
                    <!-- <td class="align-middle">{{event.updatedAt | date: "dd MMM y, HH:mm:ss"}}</td> -->
                    <td [width]="120" class="align-middle">
                      <button type="button" class="btn editable-table-btn btn-sm icn-btn"
                        (click)="addNewContract(event)"><i class="fas fa-edit text-primary"></i></button>
                      <button type="button" class="btn editable-table-btn btn-sm icn-btn"
                        (click)="opendeleteModal(event)"><i class="fas fa-trash-alt text-danger"></i></button>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </ng-scrollbar>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<p-confirmDialog [style]="{ width: '450px' }" [baseZIndex]="1052">
</p-confirmDialog>

<div class="editModal-container" [class.open]="showPanel">
  <div class="row bg-modal-header px-3 py-2 mx-0">
    <h6 class="float-left">
      {{ selectedContractId ? "Update Smart Contract" : "Add new Smart Contract" }}
    </h6>
    <button type="button" class="float-right close text-white" (click)="closePanel()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form *ngIf="contractForm" [formGroup]="contractForm">
    <div class="col-12 mt-2">
      <input type="text" class="form-control" formControlName="contractName" placeholder="Smart Contract Name" />
    </div>
    <div class="col-12 mt-2">
      <input type="text" class="form-control" formControlName="description" placeholder="Description" />
    </div>
    <div class="col-12 mt-2">
      <!-- {{contractForm?.value?.isEnableRules}} -->
      <label class="switch">
        <input type="checkbox" name="status" formControlName="isEnableRules"
          [checked]="contractForm?.value?.isEnableRules">
        <div class="slider round">
          <span class="on">Enabled</span>
          <span class="off">Disabled</span>
        </div>
      </label>
    </div>
    <div class="col-12 mt-2">
      <div>
        <p-accordion>
          <p-accordionTab (click)="disableSelectedAction(i)" formArrayName="rules" *ngFor="let control of formData.controls; let i = index;">
            <ng-container formGroupName="{{i}}">
              <ng-template pTemplate="header">
                <div class="d-flex w-100 align-content-center justify-content-between">
                  <div class="pt-1">Rule #{{i + 1}}</div>
                  <div>
                    <button *ngIf="i < 15 && (i === (formData.controls.length - 1))"
                      (click)="$event.stopPropagation(); addRules()" class="btn editable-table-btn btn-sm icn-btn"><i
                        class="fas fa-plus text-primary"></i></button>
                    <button *ngIf="formData.controls.length > 1" (click)="$event.stopPropagation(); removeRules(i)"
                      class="btn editable-table-btn btn-sm icn-btn"><i
                        class="fas fa-trash-alt text-danger"></i></button>
                  </div>
                </div>
              </ng-template>
              <ng-template pTemplate="content">
                <div class="row">
                  <div class="col">
                    <select (change)="disableSelectedActionStaus(i)" class="form-control" formControlName="chainType">
                      <option value="" hidden>Select chain</option>
                      <option *ngFor="let chain of chainType" [value]="chain.value">
                        {{chain.label}}</option>
                    </select>
                  </div>
                  <div class="col">
                    <!-- {{!formData.controls[i].value.chainType}} -->
                    <select (change)="disableSelectedAction(i)" class="form-control" formControlName="actionType">
                      <option value="" hidden>Select action</option>
                      <option *ngFor="let action of actionType" [disabled]="action.isSelected" [value]="action.value">
                        {{action.label}}</option>
                    </select>
                  </div>
                  <div class="col">
                    <select class="form-control" (change)="selectTemplate(i)" formControlName="templateType">
                      <option value="" hidden>Select template</option>
                      <option *ngFor="let type of templateType" [value]="type.value">{{type.label}}</option>
                    </select>
                  </div>
                  <div class="col-12 mt-2">
                    <textarea class="form-control" formControlName="rule" placeholder="Rules"></textarea>
                  </div>
                  <div class="col-12 mt-2">
                    <!-- {{formData.controls[i].value.isEnable}} -->
                    <label class="switch">
                      <input type="checkbox" name="status" formControlName="isEnable" [checked]="formData.controls[i].value.isEnable">
                      <div class="slider round">
                        <span class="on">Enabled</span>
                        <span class="off">Disabled</span>
                      </div>
                    </label>
                  </div>
                </div>
              </ng-template>
            </ng-container>
          </p-accordionTab>
        </p-accordion>
      </div>

    </div>
    <div class="col-12 mt-2 text-right">
      <button type="submit" [disabled]="!contractForm.valid" class="btn bg-gradient-success"
        (click)="addAndUpdateSmartContract()">{{selectedContractId ? 'Update' : 'Save'}}</button>
    </div>
  </form>
</div>