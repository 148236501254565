import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class SmartContractsManagementService {

  constructor(private http:HttpClient) { }

  addSmartContract(smartContract) {
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
    };
    return this.http.post(ApiService.URLs.smartContracts, smartContract, options );
  }

  updateSmartContract(smartContract, id) {
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
    };
    return this.http.put(`${ApiService.URLs.smartContracts}/${id}`, smartContract, options );
  }

  getSmartContracts() {
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
    };
    return this.http.get(ApiService.URLs.smartContracts, options);
  }

  removeSmartContract(id) {
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
    };
    return this.http.delete(`${ApiService.URLs.smartContracts}/${id}`, options);
  }
}
