import { Subscription } from 'rxjs';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { NgScrollbar } from 'ngx-scrollbar';
import { AuthService } from 'src/app/core/auth/auth.service';
import { CommonService } from 'src/app/core/services/common.service';
import { EdgeService } from 'src/app/modules/edge-management/services/edge.service';
import { API_ERROR } from 'src/app/core/constants/global-error.constants';

@Component({
  selector: 'app-edge-chain',
  templateUrl: './edge-chain.component.html',
  styleUrls: ['./edge-chain.component.css']
})
export class EdgeChainComponent implements OnInit, OnDestroy {

  @ViewChild(NgScrollbar, { static: true }) scrollbarRef: NgScrollbar;
  config: { itemsPerPage: number; currentPage: number; totalItems: any; };
  deviceData : any;
  deviceToken: any;
  currentHash: any;
  previousHash: any;
  timestamp: any;
  name: any;
  mac: any;
  serialNumber: any;
  manufacturer: any;
  modelName: any;
  productId: any;
  activatedDate: any;
  registeredDate: any;
  registeredBy: any;
  activatedBy: any;
  edgeSerialNumber: any;
  identifier: any;

  private _sub: Subscription;

  constructor(
    private edgeService: EdgeService,
    private authService: AuthService,
    private commonService: CommonService) { }

  ngOnInit(): void {
    this.getAllEdgeList();
  }

  /**
   * @description get all block lock list
   */
  getAllEdgeList() {
    this._sub = this.edgeService.getBrokerNode().subscribe((brokerRes) => {
      let brokerNodeArr = brokerRes as any[];
      this.edgeService.getAllEdge().subscribe(
        ((res) => {
          // let tempRes = res;
          this.deviceData = res;
          let allbroker = [];
          for (let i = 0; i < this.deviceData.length; i++) {
            if (this.deviceData[i] && this.deviceData[i].data && this.deviceData[i].data.serialNumber) {
              if (brokerNodeArr.indexOf(this.deviceData[i].data.serialNumber) > -1) {
                allbroker.push(this.deviceData[i])
              }
            }
          }
          for (let j = 0; j < this.deviceData.length; j++) {
            for (let k = 0; k < allbroker.length; k++) {
              if (this.deviceData[j] && this.deviceData[j].data && this.deviceData[j].data.serialNumber) {
                if (allbroker[k].data.identifier == this.deviceData[j].data.identifier) {
                  const currentUser = this.commonService.getCurrentUserData();
                  const user = {
                    emailId: currentUser.user.officialEmail,
                    id: allbroker[k].data.userId
                  }
                  let _device = this.deviceData.find((device) => {
                    return device.data.serialNumber == this.deviceData[j].data.registeredBy
                  });

                  this.authService.getUserById(user).subscribe(
                    (res) => {
                      this.deviceData[j].data.registeredBy = res.fullName + ' via ' + _device.data.name;;
                    },
                    (error) => {
                      this.deviceData[j].data.registeredBy = this.deviceData[j].data.registeredBy;
                    }
                  )
                }
              }
            }
          }
          if (this.deviceData && this.deviceData.length > 0) {
            this.config = {
              itemsPerPage: 10,
              currentPage: 1,
              totalItems: this.deviceData.length,
            }
          }
          else {
            this.deviceData = [];
          }
        }), (error => {
          if (error?.message === API_ERROR.USER_LOGOUT) {
            this.commonService.logout(API_ERROR.USER_LOGOUT);
          }
          console.log('error', error);
        })
      )
    }), (error => {
      if (error?.message === API_ERROR.USER_LOGOUT) {
        this.commonService.logout(API_ERROR.USER_LOGOUT);
      }
      console.log('error', error);
    })

  }

  /**
    * Description: open block data
   * @description open block data
   * @param data selected data to populate block
   */
  openBlock(data) {
    this.deviceToken = data.deviceToken;
    this.currentHash = data.currentHash;
    this.previousHash = data.previousHash;
    this.timestamp = data.timestamp;
    this.edgeSerialNumber = data.data.serialNumber;
    this.identifier = data.data.identifier;
  }

  /**
    * Description: open meta data
   * @description open meta data
   * @param data selected data to populate meta data
   */
  openMetaData(data) {
    this.name = data.name;
    this.mac = data.mac;
    this.serialNumber = data.serialNumber;
    this.manufacturer = data.manufacturer;
    this.modelName = data.modelName;
    this.productId = data.productId;
    this.registeredDate = data.registeredDate;
    this.activatedDate = data.activatedDate;
    this.registeredBy = data.registeredBy;
    this.activatedBy = data.activatedBy;
  }

  ngOnDestroy() {
    this._sub.unsubscribe();
  }
}
