<div class="public-page">
  <!-- Site wrapper -->
  <div class="wrap">
    <div class="vertical-align-wrap">
      <div class="vertical-align-middle auth-main">
        <div class="auth-box">
          <!-- <div class="row app-logo top">
            <div class="col-md-5 mb-2 d-flex align-items-center justify-content-end p-0">
              <img class="img-fluid float-left" src="assets/images/IMPACT_by_honeywell-Logo.png" alt="IMPACT by honeywell Logo">
            </div>
          </div> -->
          <!-- <div class="col-md-5 d-flex align-items-center justify-content-start p-0">
            <img class="img-fluid float-right" src="assets/images/logo-1.png" alt="Renesas Logo">                
              </div>
              <div class="col-md-5 ml-auto d-flex align-items-center justify-content-end p-0">
                <img class="img-fluid float-left" src="assets/images/logo-2.png" alt="SmartAxiom Logo">                   
              </div>
          </div> -->

          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>
