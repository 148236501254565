import { Observable, throwError } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { SysLogService } from '../../../services/sys-log.service';
import { map, catchError } from 'rxjs/operators';

@Component({
  selector: 'app-system-log',
  templateUrl: './system-log.component.html',
  styleUrls: ['./system-log.component.css']
})
export class SystemLogComponent implements OnInit {

  systemLogData$: Observable<any>;
  config: { itemsPerPage: number; currentPage: number; totalItems: any; };

  constructor(private sysLogService:SysLogService) { }

  ngOnInit(): void {
    this.getSystemLogs();
  }

  /**
   * @description get user system logs
   */
  getSystemLogs() {
    this.systemLogData$ = this.sysLogService.getSystemLogs().pipe(
      map((res: any[]) => {
        const systemLogData = res || [];
        this.config = {
          itemsPerPage:10,
          currentPage:1,
          totalItems: systemLogData.length,
        }
        return systemLogData;
      }),
      catchError(error => {
        console.log('error: ' + error);
        return throwError(error);
      })
    );
  }

}
