    <!-- Main content -->
<style>
  #terminal-container {
    width: 100%;
    height: 100%;
    display: inline-block;
    padding: 2px;
  }

  .function {
    display: inline-block;
    float: right;
  }

  #terminal-container .terminal {
    background-color: #111;
    color: #fafafa;
    padding: 2px;
  }

    #terminal-container .terminal:focus .terminal-cursor {
      background-color: #fafafa;
    }

  .xterm-scroll-area {
    display: none !important;
  }
</style>


<section class="content">
  <div class="card no-border">
    <div class="card-body p-0 tab-content" id="tab1content">
      <div class="row">
        <div class="col-md-12 bg-white p-3 tab-content cus-rounded-left cus-rounded-right">
          <!-- Terminal tab code starts from here -->
          <div class="tab-pane container-fluid active" id="aa">
            <div class="row py-2">
              <div class="col-md-12">
                <!--<ng-terminal #term></ng-terminal>-->
                <div id="terminal-container"></div>
              </div>
            </div>
          </div>
        </div>
        <!-- /.card -->
      </div>
      <!-- /.card-body -->
    </div>
  </div>
</section>

<!-- /.content -->
