import { Component, OnInit, ViewChild } from '@angular/core';
import { NgScrollbar } from 'ngx-scrollbar';

@Component({
  selector: 'app-sensors',
  templateUrl: './sensors.component.html',
  styleUrls: ['./sensors.component.css']
})
export class SensorsComponent implements OnInit {

  @ViewChild(NgScrollbar, { static: true }) scrollbarRef: NgScrollbar;

  constructor() { }

  ngOnInit(): void {
  }

}
