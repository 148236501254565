<!-- Main content -->
<div class="row">
    <div class="col-12">
        <section class="content mt-4">
            <div class="col-12 mb-3">

            </div>
            <!-- Default box -->
            <section class="content mx-3 mt-4">
                <div class="card no-border">
                    <div class="card-body p-0 tab-content" id="tab1content">
                        <div class="row">
                            <div class="col-md-12 bg-white p-3 tab-content cus-rounded-right cus-rounded-left">
                                <div class="tab-pane container-fluid active mb-3" id="a">
                                    <form role="form">
                                        <h6 class="mb-3 font-weight-bold pageTitle text-uppercase col-md-12">
                                            Edit Profile</h6>

                                        <div class="row mt-5 py-1">
                                            <div class="form-group col-md-4">
                                                <!-- <span class="badge text-danger">*</span> -->
                                                <label class="has-float-label">
                                                    <input type="text" class="form-control" name="realname"
                                                        pattern="^[a-zA-Z\s]+$" placeholder="User Full Name"
                                                        [(ngModel)]="username">
                                                    <span>User Full Name</span>
                                                </label>
                                            </div>
                                            <div class="form-group col-md-4">
                                                <!-- <span class="badge text-danger">*</span> -->
                                                <label class="has-float-label">
                                                    <input type="text" class="form-control" [(ngModel)]="email"
                                                        name="email" readonly required placeholder="Email" />
                                                    <span>Email</span>
                                                </label>
                                                <!-- <small class="w-100" ><i>This email will be your user name.</i></small> -->

                                            </div>
                                        </div>

                                        <div class="row py-1">
                                            <div class="form-group col-md-4">
                                                <label class="has-float-label">
                                                    <input type="text" class="form-control" name="address"
                                                        [(ngModel)]="address" placeholder="Address">
                                                    <span>Address</span>
                                                </label>
                                            </div>

                                            <div class="form-group col-md-4">
                                                <label class="has-float-label">
                                                    <input type="text" class="form-control" name="city"
                                                        [(ngModel)]="city" placeholder="City">
                                                    <span>City</span>
                                                    <div *ngIf="isloading" class="spinner-border text-primary" role="status"></div>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="row py-1">
                                            <div class="form-group col-md-4">
                                                <label class="has-float-label">
                                                    <input type="text" class="form-control" name="state"
                                                        [(ngModel)]="state" placeholder="State">
                                                    <span>State</span>
                                                </label>
                                            </div>

                                            <div class="form-group col-md-4">
                                                <!-- <label class="has-float-label">
                                                    <input type="text" class="form-control"
                                                        name="country" [(ngModel)]="country"
                                                        placeholder="Country">
                                                    <span>Country</span>
                                                </label> -->
                                                <select class="form-control" name="country" [(ngModel)]="selectcountry">
                                                    <option value="" selected>Select Country</option>
                                                    <option *ngFor="let country of selectcountries"
                                                        [value]="country.name">{{country.name}}</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="row py-1">
                                            <div class="form-group col-md-4">
                                                <label class="has-float-label">
                                                    <input type="text" class="form-control" name="zip" [(ngModel)]="zip"
                                                        placeholder="Zip">
                                                    <span>Zip</span>
                                                </label>
                                            </div>


                                            <div class="form-group col-md-4 mb-3 passwField">
                                                <label class="has-float-label">
                                                    <input type="number"
                                                        onKeyPress="if(this.value.length==10) return false;"
                                                        class="form-control" [(ngModel)]="phoneno" name="phone" required
                                                        placeholder="Phone Number">
                                                    <span> Phone Number</span>
                                                </label>
                                            </div>
                                        </div>


                                        <div class="row py-1">
                                            <div class="form-group col-md-4" *ngIf="!enableBase64">
                                                <div class="input-group">
                                                    <div class="custom-file">
                                                        <input type="file" (change)="onFileChanged($event)"
                                                            class="custom-file-input form-control" id="exampleInputFile"
                                                            placeholder="Upload Floor Plan" name="user_image">
                                                        <label class="custom-file-label" for="exampleInputFile"
                                                            aria-describedby="inputGroupFileAddon"
                                                            [title]="fileName">{{fileName | slice:0:30}}
                                                        </label>
                                                        <label class="custom-file-label" for="exampleInputFile"
                                                            *ngIf="!fileName"
                                                            aria-describedby="inputGroupFileAddon">Upload
                                                            Profile Photo</label>
                                                    </div>
                                                    <i *ngIf="fileName"
                                                        class="far fa-times-circle text-danger close-UploadFile"
                                                        (click)="removeImage()"></i>
                                                    <div class="input-group-append">
                                                        <!--<span class="input-group-text" >Upload</span>-->
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- sachin -->
                                            <div class="col-md-4 form-group" *ngIf="enableBase64">
                                                <input accept="image/png, image/jpeg, image/jpg" type="file"
                                                    class="form-control" (change)="fileChangeEvent($event)" />
                                            </div>


                                            <div class="form-group col-md-2 pt-2" style="font-size: 18px; font-weight: 100;" *ngIf="currentUser">
                                                <input type="checkbox" [(ngModel)]="sendAlerts" class="ml-2 mr-2 sendAlertsCheck"
                                                    (change)="sendAlerts != sendAlerts" name="sendAlerts"
                                                    [checked]="sendAlerts">
                                                <span>Enable Email Alerts</span>
                                                <div *ngIf="sendAlerts" class="pl-4 ml-2">
                                                    <div *ngFor="let option of options" class="form-check" style="font-size: medium">
                                                        <input class="form-check-input" type="radio" name="options" [(ngModel)]="selectedReportType" [value]="option">
                                                        <label class="form-check-label" [for]="option">{{ option }}</label>
                                                    </div>
                                                </div>
                                             </div>

                                            <!--  <div class="form-group col-md-2 pt-2" style="font-size: 18px; font-weight: 100;" *ngIf="currentUser && currentUser.userLevel === 'SUPERADMIN'">
                                                <input type="checkbox" [(ngModel)]="sendReport" class="ml-2 mr-2 sendReportCheck"
                                                    (change)="sendReport != sendReport" name="sendReport"
                                                    [checked]="sendReport">
                                                <span>Enable Daily Report</span>
                                             </div> -->

                                             <div class="form-group col-md-2 pt-2" style="font-size: 18px; font-weight: 100;" *ngIf="currentUser">
                                                <input type="checkbox" 
                                                       class="ml-2 mr-2 sendAlertsCheck"
                                                       (click)="enablesound()" 
                                                       name="sendAlerts"
                                                       [checked]="isPlaying"> <!-- Use [checked] binding instead of value -->
                                                <span>Enable Alert Notifications</span>
                                               
                                            </div>

                                              </div>
                                        <div class="row py-1" *ngIf="enableBase64">
                                            <div class="form-group col-md-4">
                                                <image-cropper [imageChangedEvent]="imageChangedEvent"
                                                    [maintainAspectRatio]="true" [aspectRatio]="1/1" [imageQuality]="90"
                                                    [resizeToWidth]="200" format="jpeg"
                                                    (imageCropped)="imageCropped($event)"
                                                    (imageLoaded)="imageLoaded($event)" (cropperReady)="cropperReady()"
                                                    [roundCropper]="true" [cropperMaxWidth]="4000">
                                                </image-cropper>
                                            </div>
                                            <div class="form-group col-md-4 mb-3 passwField">
                                                <img [src]="croppedImage" />
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <div class="row d-flex justify-content-between mx-0">
                                    <div class="col-md-12">
                                        <button type="submit" class="btn btn-success px-4" data-dismiss="modal"
                                            (click)="editProfile()">Save</button>
                                        
                                    </div>
                                  
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </section>

    </div>
</div>
<!-- /Main content -->

<!-- Control Sidebar -->
<aside class="control-sidebar control-sidebar-dark">
    <!-- Control sidebar content goes here -->
</aside>
<!-- /.control-sidebar -->