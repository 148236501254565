<!-- Main content -->
<div class="row">
  <div class="col-12">
    <section class="content mt-4 mx-3">
      <section class="content">
        <!-- Default box -->
        <div class="card no-border">
          <div class="card-body p-0">
            <div class="row" id="report_shipment">
              <div
                class="col-md-12 bg-white px-3 pt-3 pb-0 tab-content cus-rounded-left cus-rounded-right"
              >
                <div class="tab-pane container-fluid active" id="a">
                  <div class="d-flex flex-nowrap justify-content-between">
                    <h6 class="mb-0 pt-2 font-weight-bold pageTitle text-uppercase">Shipment Report</h6>
                    <div>
                      <div class="d-flex flex-nowrap">
                        <div class="p-field">
                          <label for="fromDate" class="mr-2">From Date: </label>
                          <p-calendar
                            #fDate
                            [formControl]="fromDate"
                            [showIcon]="true"
                            [showTime]="true"
                            inputId="fromDate"
                            [hideOnDateTimeSelect]="false"
                          >
                            <p-footer>
                              <button
                                pButton
                                class="p-button-sm mb-2 ml-2"
                                type="button"
                                label="Close"
                                (click)="fDate.hideOverlay()"
                              ></button>
                            </p-footer>
                          </p-calendar>
                        </div>
                        <div class="p-field ml-2">
                          <label for="toDate" class="mr-2">To Date: </label>
                          <!-- [disabled]="!fromDate.value" -->
                          <p-calendar
                            #tDate
                            [hideOnDateTimeSelect]="false"
                            [formControl]="toDate"
                            [minDate]="fromDate.value"
                            [showIcon]="true"
                            [showTime]="true"
                            inputId="toDate"
                          >
                            <p-footer>
                              <button
                                pButton
                                class="p-button-sm mb-2 ml-2"
                                type="button"
                                label="Close"
                                (click)="tDate.hideOverlay()"
                              ></button>
                            </p-footer>
                          </p-calendar>
                        </div>
                        <button
                          [disabled]="!trackerData[0]?.events.length || !fromDate.value"
                          class="btn btn-primary cursor-pointer px-2 py-0 ml-2"
                          (click)="isDateFiltered ? clearFilterDataByDates() : filterDataByDates()"
                        > {{isDateFiltered ? 'Clear Filter' : 'Apply Filter'}}</button>
                      </div>
                    </div>
                  </div>
                  <div class="row clearfix" id="widgets">
                    <div
                      id="name"
                      class="d-flex align-items-stretch"
                      [ngClass]="{
                        'col-lg-2 col-md-2 col-sm-2':
                          trackerData[0]?.events[0]?.parsedData?.signal,
                        'col-lg-3 col-md-3 col-sm-3': !trackerData[0]?.events[0]
                          ?.parsedData?.signal
                      }"
                    >
                      <div class="card number-chart text-center">
                        <div class="body bg-white pt-2 px-0 pb-0 h-100">
                          <div class="number h-100">
                            <h6
                              class="mb-2 font-weight-bold font-size13 text-capitalize"
                            >
                              <a>Shipment Name</a>
                            </h6>
                            <h5
                              *ngIf="shipmentData; else loading"
                              (click)="navigateTo()"
                              class="cursor-pointer"
                            >
                              <i *ngIf="currentUser.accesslevel.permissions['shipments']
                                && currentUser?.accesslevel.permissions['shipments'].update
                                && (shipmentData.status === 'STARTED' || shipmentData.status === 'PAUSED')"
                                class="fas fa-pencil-alt text-primary"
                              ></i>
                              {{ shipmentData?.shipmentName }}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="type"
                      class="col-lg-2 col-md-2 col-sm-2 d-flex align-items-stretch"
                    >
                      <div class="card number-chart text-center">
                        <div class="body bg-white pt-2 px-0 pb-0 h-100">
                          <div class="number h-100">
                            <h6
                              class="mb-2 font-weight-bold font-size13 text-capitalize"
                            >
                              <a>Shipment Type</a>
                            </h6>
                            <h5 *ngIf="shipmentData; else loading">
                              {{ shipmentData?.shipmentType }}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      id="uuid"
                      class="d-flex align-items-stretch"
                      [ngClass]="{
                        'col-lg-2 col-md-2 col-sm-2':
                          trackerData[0]?.events[0]?.parsedData?.signal,
                        'col-lg-3 col-md-3 col-sm-3': !trackerData[0]?.events[0]
                          ?.parsedData?.signal
                      }"
                    >
                      <div class="card number-chart">
                        <div
                          class="body bg-white bg-white pt-2 px-0 pb-0 h-100 text-center"
                        >
                          <div class="number h-100">
                            <h6
                              class="mb-2 font-weight-bold font-size13 text-capitalize"
                            >
                              <a
                                >{{ constantVariables.ENDPOINT.LABEL1 }} UUID</a
                              >
                            </h6>
                            <h5
                              *ngIf="
                                trackerData[0]?.trackers?.length;
                                else emptyuuid
                              "
                            >
                              {{ trackerData[0].trackers[0]?.data.deviceUUID }}
                            </h5>
                            <ng-template #emptyuuid>
                              <div class="font-weight-bold">-</div>
                            </ng-template>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="battery"
                      class="col-lg-2 col-md-2 col-sm-2 d-flex align-items-stretch"
                    >
                      <div class="card number-chart">
                        <div
                          class="body bg-white pt-2 px-0 pb-0 h-100 text-center"
                        >
                          <div class="number h-100">
                            <h6
                              class="mb-2 font-weight-bold font-size13 text-capitalize"
                            >
                              <a>Battery</a>
                            </h6>
                            <h5
                              *ngIf="
                                !shipmentData?.events?.length;
                                else batteryStatus
                              "
                            >
                              N/A
                            </h5>
                            <ng-template #batteryStatus>
                              <h5 *ngIf="trackerData.length; else emptyBattery">
                                {{
                                  calculateBatteryPercentage(
                                    trackerData[0]?.events[0]?.parsedData?.battery.split(
                                      "V"
                                    )[0]
                                  ) + "%"
                                }}
                                <i
                                  class="ml-2 fas widget-icon"
                                  [ngClass]="
                                    calculateBatteryPercentage(
                                      trackerData[0]?.events[0]?.parsedData?.battery.split('V')[0], true
                                    )"
                                ></i>
                              </h5>
                            </ng-template>
                            <ng-template #emptyBattery>
                              <div class="font-weight-bold">
                                0%
                                <i
                                  class="ml-2 fas widget-icon fa-battery-quarter text-danger"
                                ></i>
                              </div>
                            </ng-template>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      id="signal"
                      *ngIf="trackerData[0]?.events[0]?.parsedData?.signal"
                      class="col-lg-2 col-md-2 col-sm-2 d-flex align-items-stretch"
                    >
                      <div class="card number-chart">
                        <div
                          class="body bg-white pt-2 px-0 pb-0 h-100 text-center"
                        >
                          <div class="number h-100">
                            <h6
                              class="mb-2 font-weight-bold font-size13 text-capitalize"
                            ><a>Signal</a></h6>
                            <div class="d-flex justify-content-center">
                              <h5>
                                {{trackerData[0]?.events[0]?.parsedData?.signal}}
                              </h5>
                              <div>
                                <svg
                                  *ngIf="
                                    trackerData[0]?.events[0]?.parsedData
                                      ?.signal === 0
                                  "
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  fill="currentColor"
                                  class="bi bi-wifi-off m-0 p-0 pb-1"
                                  viewBox="2 4 10 12"
                                >
                                  <path
                                    d="M10.706 3.294A12.545 12.545 0 0 0 8 3C5.259 3 2.723 3.882.663 5.379a.485.485 0 0 0-.048.736.518.518 0 0 0 .668.05A11.448 11.448 0 0 1 8 4c.63 0 1.249.05 1.852.148l.854-.854zM8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065 8.448 8.448 0 0 1 3.51-1.27L8 6zm2.596 1.404.785-.785c.63.24 1.227.545 1.785.907a.482.482 0 0 1 .063.745.525.525 0 0 1-.652.065 8.462 8.462 0 0 0-1.98-.932zM8 10l.933-.933a6.455 6.455 0 0 1 2.013.637c.285.145.326.524.1.75l-.015.015a.532.532 0 0 1-.611.09A5.478 5.478 0 0 0 8 10zm4.905-4.905.747-.747c.59.3 1.153.645 1.685 1.03a.485.485 0 0 1 .047.737.518.518 0 0 1-.668.05 11.493 11.493 0 0 0-1.811-1.07zM9.02 11.78c.238.14.236.464.04.66l-.707.706a.5.5 0 0 1-.707 0l-.707-.707c-.195-.195-.197-.518.04-.66A1.99 1.99 0 0 1 8 11.5c.374 0 .723.102 1.021.28zm4.355-9.905a.53.53 0 0 1 .75.75l-10.75 10.75a.53.53 0 0 1-.75-.75l10.75-10.75z"
                                  />
                                </svg>
                                <svg
                                  *ngIf="
                                    trackerData[0]?.events[0]?.parsedData
                                      ?.signal === 1
                                  "
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  fill="currentColor"
                                  class="bi bi-wifi-1 m-0 p-0 pb-1"
                                  viewBox="2 4 10 12"
                                >
                                  <path
                                    d="M11.046 10.454c.226-.226.185-.605-.1-.75A6.473 6.473 0 0 0 8 9c-1.06 0-2.062.254-2.946.704-.285.145-.326.524-.1.75l.015.015c.16.16.407.19.611.09A5.478 5.478 0 0 1 8 10c.868 0 1.69.201 2.42.56.203.1.45.07.611-.091l.015-.015zM9.06 12.44c.196-.196.198-.52-.04-.66A1.99 1.99 0 0 0 8 11.5a1.99 1.99 0 0 0-1.02.28c-.238.14-.236.464-.04.66l.706.706a.5.5 0 0 0 .707 0l.708-.707z"
                                  />
                                </svg>
                                <svg
                                  *ngIf="
                                    trackerData[0]?.events[0]?.parsedData
                                      ?.signal > 1 &&
                                    trackerData[0]?.events[0]?.parsedData
                                      ?.signal < 31
                                  "
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  fill="currentColor"
                                  class="bi bi-wifi-2 m-0 p-0 pb-1"
                                  viewBox="2 4 10 12"
                                >
                                  <path
                                    d="M13.229 8.271c.216-.216.194-.578-.063-.745A9.456 9.456 0 0 0 8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065A8.46 8.46 0 0 1 8 7a8.46 8.46 0 0 1 4.577 1.336c.205.132.48.108.652-.065zm-2.183 2.183c.226-.226.185-.605-.1-.75A6.473 6.473 0 0 0 8 9c-1.06 0-2.062.254-2.946.704-.285.145-.326.524-.1.75l.015.015c.16.16.408.19.611.09A5.478 5.478 0 0 1 8 10c.868 0 1.69.201 2.42.56.203.1.45.07.611-.091l.015-.015zM9.06 12.44c.196-.196.198-.52-.04-.66A1.99 1.99 0 0 0 8 11.5a1.99 1.99 0 0 0-1.02.28c-.238.14-.236.464-.04.66l.706.706a.5.5 0 0 0 .708 0l.707-.707z"
                                  />
                                </svg>
                                <svg
                                  *ngIf="
                                    trackerData[0]?.events[0]?.parsedData
                                      ?.signal > 30 &&
                                    trackerData[0]?.events[0]?.parsedData
                                      ?.signal < 52
                                  "
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  fill="currentColor"
                                  class="bi bi-wifi m-0 p-0 pb-1"
                                  viewBox="2 4 10 12"
                                >
                                  <path
                                    d="M15.384 6.115a.485.485 0 0 0-.047-.736A12.444 12.444 0 0 0 8 3C5.259 3 2.723 3.882.663 5.379a.485.485 0 0 0-.048.736.518.518 0 0 0 .668.05A11.448 11.448 0 0 1 8 4c2.507 0 4.827.802 6.716 2.164.205.148.49.13.668-.049z"
                                  />
                                  <path
                                    d="M13.229 8.271a.482.482 0 0 0-.063-.745A9.455 9.455 0 0 0 8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065A8.46 8.46 0 0 1 8 7a8.46 8.46 0 0 1 4.576 1.336c.206.132.48.108.653-.065zm-2.183 2.183c.226-.226.185-.605-.1-.75A6.473 6.473 0 0 0 8 9c-1.06 0-2.062.254-2.946.704-.285.145-.326.524-.1.75l.015.015c.16.16.407.19.611.09A5.478 5.478 0 0 1 8 10c.868 0 1.69.201 2.42.56.203.1.45.07.61-.091l.016-.015zM9.06 12.44c.196-.196.198-.52-.04-.66A1.99 1.99 0 0 0 8 11.5a1.99 1.99 0 0 0-1.02.28c-.238.14-.236.464-.04.66l.706.706a.5.5 0 0 0 .707 0l.707-.707z"
                                  />
                                </svg>
                                <svg
                                  *ngIf="
                                    trackerData[0]?.events[0]?.parsedData
                                      ?.signal === 99
                                  "
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  fill="currentColor"
                                  class="bi bi-reception-0 m-0 p-0 pb-1"
                                  viewBox="2 4 10 12"
                                >
                                  <path
                                    d="M0 13.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm4 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm4 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm4 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="total_events"
                      class="col-lg-2 col-md-2 col-sm-2 d-flex align-items-stretch"
                    >
                      <div class="card number-chart">
                        <div
                          class="body bg-white pt-2 px-0 pb-0 h-100 text-center w-100"
                        >
                          <div class="number h-100">
                            <h6
                              class="mb-2 font-weight-bold font-size13 text-capitalize"
                            >
                              <a> Total Events</a>
                            </h6>
                            <div>
                              <h5
                                *ngIf="
                                  !shipmentData?.events?.length;
                                  else trackerStatus
                                "
                              >
                                N/A
                              </h5>
                              <ng-template #trackerStatus>
                                <h5 class="margin-btm10">
                                  {{ trackerDataEvents?.length }}
                                </h5>
                              </ng-template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div
                      *ngIf="shipmentData && shipmentData.trackers"
                      class="col pb-3"
                    >
                    <!-- (click)="downloadCallibration(device.data.deviceUUID)" -->
                      <a
                        href="assets/certificates/calibration-certificate.pdf" download
                        *ngFor="let device of shipmentData.trackers"
                        style="
                          color: cornflowerblue;
                          margin: 10px;
                          cursor: pointer;
                        "
                      >
                        <u
                          >Download callibration certificate for Sensor -
                          {{ device.data.deviceUUID }}</u
                        >
                      </a>
                    </div>
                    <div
                      *ngIf="trackerData[0]?.events.length"
                      class="col text-right pb-3"
                    >
                      <a
                        (click)="generatePDF()"
                        class="text-md"
                        style="
                          color: cornflowerblue;
                          margin: 10px;
                          cursor: pointer;
                        "
                      >
                        <i class="fa fa-file-pdf mt-1"></i
                        ><u> Download Report</u>
                      </a>
                    </div>
                  </div>

                  <div class="row">
                    <!-- START DETAIL -->
                    <div class="col-xl-12 col-lg-12 detailing-card">
                      <div class="card shadow-sm mb-3 rounded-md">
                        <div class="card-body p-0">
                          <div class="d-flex justify-content-between">
                            <h6
                              class="m-0 p-2 w-100 bg-light d-flex justify-content-between"
                            >
                              <span>Details</span>
                              <span class="text-xs"
                                ><label>Status:</label>
                                {{ shipmentData?.status }}</span
                              >
                            </h6>
                          </div>

                          <ul
                            class="list-group list-group-flush description-text"
                          >
                            <li class="list-group-item">
                              <div class="row">
                                <div class="col">
                                  <label>Description: </label>
                                  {{ shipmentData?.shipmentDesc }}
                                </div>
                                <div class="col">
                                  <label>Event Interval: </label>
                                  {{ eventFrequency }} minutes
                                </div>
                              </div>
                            </li>
                            <li class="list-group-item">
                              <div class="row">
                                <div class="col">
                                  <label>Pickup from: </label>
                                  {{ shipmentData?.pickupLocation }}
                                </div>
                                <div class="col">
                                  <label>Pickup Date: </label>
                                  {{
                                    shipmentData?.pickupDate
                                      | date: "EE dd MMM y"
                                  }}
                                </div>
                              </div>
                            </li>
                            <li
                              class="list-group-item"
                              *ngIf="
                                shipmentData?.shipmentType === 'Movable' ||
                                shipmentData?.shipmentType === 'movable'
                              "
                            >
                              <div class="row">
                                <div class="col">
                                  <label>Drop at: </label>
                                  {{ shipmentData?.destinationLocation }}
                                </div>
                                <div class="col">
                                  <label>Drop Date: </label>
                                  {{
                                    shipmentData?.deliveryDate
                                      | date: "EE dd MMM y"
                                  }}
                                </div>
                              </div>
                            </li>
                            <li class="list-group-item">
                              <div class="row">
                                <div class="col text-truncate">
                                  <label>Current location: </label>
                                  <span
                                    [title]="
                                      trackerData[0]?.events[0]
                                        ?.formattedAddress ||
                                      trackerData[0]?.events[0]?.parsedData
                                        ?.location ||
                                      ''
                                    "
                                  >
                                    {{
                                      trackerData[0]?.events[0]
                                        ?.formattedAddress ||
                                        trackerData[0]?.events[0]?.parsedData
                                          ?.location ||
                                        "N/A"
                                    }}
                                  </span>
                                </div>
                                <div class="col d-flex justify-content-between">
                                  <span>
                                    <label>Latest Event time: </label>
                                    {{
                                      trackerDataEvents?.length
                                        ? (trackerData[0]?.events[0]
                                            ?.timeStamp * 1000
                                          | date: "dd MMM y, h:mm a")
                                        : "N/A"
                                    }}
                                  </span>
                                  <!-- <span
                                    *ngIf="shipmentData?.status === 'COMPLETED'"
                                  >
                                    <label>Completed At: </label>
                                    {{
                                      shipmentData?.completedAt
                                        | date: "dd MMM y, HH:mm:ss"
                                    }}
                                  </span> -->
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <!-- END DETAIL -->

                    <!-- START MAP -->
                    <div
                      [class.d-none]="!trackerDataEvents.length"
                      class="col-xl-12 col-lg-12"
                    >
                      <span class="ml-2 mb-0 font-size12" style="color: '#245EAA';" *ngIf="shipmentData && shipmentData.geofence_radius && shipmentData.geofence_radius !== '0'">
                        Geofencing is enabled for radius of {{ shipmentData.geofence_radius}}m</span>
                      <div
                        class="card shadow-sm rounded-xl mb-3 graphsCard-loader"
                      >
                        <div
                          class="card-body bg-white p-1 row position-relative d-flex align-items-center justify-content-center"
                        >
                          <!-- <div class="map col" id="map"></div>
                          <div
                            *ngIf="isMapLoading"
                            class="spinner-grow text-dark col position-absolute mx-auto"
                          ></div> -->
                          <app-map [ngClass]="!noLocation ? {'display':'block'} : {'display':'none'}"
                            id="mapcard"
                            class="map col"
                            style="height: 550px"
                            page="report"
                            [type]="type"
                            [hData]="mapData"
                            [zoom]="zoom"
                            [mapType]="mapType"
                            htmlEle="map"
                            [status]="shipmentData ? shipmentData.status : ''"
                            [id]="shipmentData ? shipmentData.id : ''"
                            [freq]="
                              shipmentData && shipmentData.trackers
                                ? shipmentData.trackers[0]?.freq
                                : 0
                            "
                            [pickup]="
                              shipmentData ? shipmentData.pickupLocation : ''
                            "
                            [destination]="
                              shipmentData
                                ? shipmentData.destinationLocation
                                : ''
                            "
                          ></app-map>
                            <h5 style="margin-right: 50%;" *ngIf="noLocation">No locations found.</h5>
                        </div>
                      </div>

                      <!--Total Events -->
                      <!-- <div
                        id="totalevents"
                        class="card shadow mb-3 widgetCards graphsCard-loader"
                      >
                        <div class="card-header pt-3 pb-2 clearfix">
                          <h6
                            class="m-0 font-weight-bold float-left font-size13 text-capitalize"
                          >
                            Total Events
                          </h6>
                        </div>
                        <div style="height: 302px" class="card-body p-0 px-0">
                          <div
                            echarts
                            [options]="optionsensorEvents"
                            [autoResize]="true"
                            class="events-chart"
                          ></div>
                        </div>
                      </div> -->
                    </div>
                    <!-- END MAP -->

                    <!-- START ALERT -->
                    <div
                      id="alert"
                      *ngIf="eventAlertObj"
                      class="col-xl-12 col-lg-12 detailing-card"
                    >
                      <div class="card shadow-sm mb-3 rounded-md">
                        <div class="card-body p-0">
                          <div class="d-flex justify-content-between">
                            <h6 class="m-0 p-2 w-100 bg-light">
                              <span>Alerts and Summary</span>
                            </h6>
                          </div>

                          <ul
                            class="list-group list-group-flush description-text"
                          >
                            <li class="list-group-item">
                              <div
                                *ngIf="eventAlertObj?.startedLocation"
                                class="row"
                              >
                                <div class="col">
                                  <label>Shipment Start Location: </label>
                                  {{ eventAlertObj?.startedLocation }}
                                </div>
                                <div class="col-3">
                                  <label>Shipment Start Time: </label>
                                  {{
                                    shipmentData?.startedAt
                                      | date: "dd MMM y, h:mm a"
                                  }}
                                </div>
                              </div>
                            </li>
                              <li class="list-group-item"   *ngIf="eventAlertObj?.completedLocation">
                              <div class="row" >
                                <div class="col">
                                  <label>Shipment Complete Location: </label>
                                  {{ eventAlertObj?.completedLocation }}
                                </div>
                                <div class="col-3">
                                  <label>Shipment Complete Time: </label>
                                  {{
                                    shipmentData?.completedAt
                                      | date: "dd MMM y, h:mm a"
                                  }}
                                </div>
                              </div>
                            </li>
                            <li
                              class="list-group-item"
                            >
                              <div class="row">
                                <div class="col">
                                  <label>Shipment Duration: </label>
                                  {{ duration }}
                                </div>
                              </div>
                            </li>
                            <li
                              *ngIf="eventAlertObj?.emptyLocation"
                              class="list-group-item"
                            >
                              <div class="row">
                                <div class="col">
                                  <label>Empty locations: </label>
                                  {{ eventAlertObj?.emptyLocation || 0 }}
                                </div>
                              </div>
                            </li>
                            <li
                              *ngIf="eventAlertObj?.batteryBelow30Percent"
                              class="list-group-item"
                            >
                              <div class="row">
                                <div class="col">
                                  <label>Battery below 30.0%: </label>
                                  {{
                                    eventAlertObj?.batteryBelow30Percent || 0
                                  }}
                                </div>
                              </div>
                            </li>
                            <li class="list-group-item">
                              <div class="row">
                                <div class="col">
                                  <label>(RSSI) Signal lost: </label>
                                  {{ eventAlertObj?.signalLost || 0 }}
                                </div>
                              </div>
                            </li>
                            <li
                              *ngIf="eventAlertObj?.maxTrackerTemp"
                              class="list-group-item"
                            >
                              <div class="row">
                                <div class="col">
                                  <label>Tracker High Temperature: </label>
                                  {{ eventAlertObj?.maxTrackerTemp }}
                                </div>
                              </div>
                            </li>
                            <li
                              *ngIf="eventAlertObj?.minTrackerTemp"
                              class="list-group-item"
                            >
                              <div class="row">
                                <div class="col">
                                  <label>Tracker Low Temperature: </label>
                                  {{ eventAlertObj?.minTrackerTemp || 0 }}
                                </div>
                              </div>
                            </li>

                            <li
                              *ngIf="eventAlertObj?.maxTrackerHumidity"
                              class="list-group-item"
                            >
                              <div class="row">
                                <div class="col">
                                  <label>Tracker High Humidity: </label>
                                  {{ eventAlertObj?.maxTrackerHumidity || 0 }}
                                </div>
                              </div>
                            </li>
                            <li
                              *ngIf="eventAlertObj?.minTrackerHumidity"
                              class="list-group-item"
                            >
                              <div class="row">
                                <div class="col">
                                  <label>Tracker Low Humidity: </label>
                                  {{ eventAlertObj?.minTrackerHumidity || 0 }}
                                </div>
                              </div>
                            </li>
                            <li
                              *ngIf="
                                eventAlertObj?.emptyTagsTemp &&
                                chartDataOptions.length
                              "
                              class="list-group-item"
                            >
                              <div class="row">
                                <div class="col">
                                  <label>Empty Tags Temperature: </label>
                                  {{ eventAlertObj?.emptyTagsTemp || 0 }}
                                </div>
                              </div>
                            </li>
                            <li
                              *ngIf="eventAlertObj?.maxTagsTemp"
                              class="list-group-item"
                            >
                              <div class="row">
                                <div class="col">
                                  <label>Tags high temperature: </label>
                                  {{ eventAlertObj?.maxTagsTemp || 0 }}
                                </div>
                              </div>
                            </li>
                            <li
                              *ngIf="eventAlertObj?.minTagsTemp"
                              class="list-group-item"
                            >
                              <div class="row">
                                <div class="col">
                                  <label>Tags low temperature: </label>
                                  {{ eventAlertObj?.minTagsTemp || 0 }}
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <!-- END ALERT -->
                  </div>

                  <!-- TEMPERATURE -->
                  <div
                    class="row" *ngIf="tempDataCharts.length && tempDataCharts[0]"
                  >
                    <div class="col-md-12 mb-3">
                      <div
                        class="card shadow-sm mb-1 widgetCards graphsCard-loader p-0"
                      >
                        <div class="card-body p-0">
                          <h6
                            class="m-0 p-2 w-100 bg-light text-sm font-weight-bold"
                          >
                            {{ constantVariables.ENDPOINT.LABEL1 }}
                            {{ tempDataCharts[0].label }} Summary [{{ tempDataCharts[0]?.unit }}]
                          </h6>
                          <div
                            class="d-flex align-items-stretch bg-white"
                            *ngIf="tempDataCharts[0].isLoadChart; else notAvailable"
                          >
                            <div
                              style="width: 295px"
                              class="d-flex align-items-center pl-2"
                            >
                              <ul
                                class="list-group list-group-flush description-text"
                              >
                                <li class="list-group-item">
                                  <label class="mb-0"
                                    >Current {{ tempDataCharts[0].label }}:
                                  </label>
                                  {{
                                    tempDataCharts[0]?.latestTemp?.val
                                      ? (tempDataCharts[0]?.latestTemp?.val
                                          | number: "1.2-2") + tempDataCharts[0]?.unit
                                      : "N/A"
                                  }}<br />
                                  <span class="text-xs text-secondary">{{
                                    tempDataCharts[0]?.latestTemp?.timeStamp
                                      | date: "dd MMM y, h:mm a"
                                  }}</span>
                                </li>
                                <li class="list-group-item">
                                  <label class="mb-0"
                                    >Maximum {{ tempDataCharts[0].label }}:</label
                                  >
                                  {{
                                    tempDataCharts[0]?.maxTemp?.val
                                      ? (tempDataCharts[0]?.maxTemp?.val
                                          | number: "1.2-2") + tempDataCharts[0]?.unit
                                      : "N/A"
                                  }}<br />
                                  <span class="text-xs text-secondary">{{
                                    tempDataCharts[0]?.maxTemp?.timeStamp
                                      | date: "dd MMM y, h:mm a"
                                  }}</span>
                                </li>
                                <li class="list-group-item">
                                  <label class="mb-0"
                                    >Minimum {{ tempDataCharts[0].label }}:
                                  </label>
                                  {{
                                    tempDataCharts[0]?.minTemp?.val
                                      ? (tempDataCharts[0]?.minTemp?.val
                                          | number: "1.2-2") + tempDataCharts[0]?.unit
                                      : "N/A"
                                  }}<br />
                                  <span class="text-xs text-secondary">{{
                                    tempDataCharts[0]?.minTemp?.timeStamp
                                      | date: "dd MMM y, h:mm a"
                                  }}</span>
                                </li>
                                <li class="list-group-item">
                                  <label class="mb-0"
                                    >Average {{ tempDataCharts[0].label }}:
                                  </label>
                                  {{
                                    tempDataCharts[0]?.avgTemp
                                      ? (tempDataCharts[0]?.avgTemp | number: "1.2-2") +
                                        tempDataCharts[0]?.unit
                                      : "N/A"
                                  }}
                                </li>
                                <li class="list-group-item" *ngIf="tempDataCharts[0].label === 'Temperature'">
                                  <label class="mb-0"
                                    >Mostly Reported Temp:
                                  </label>
                                  {{ eventAlertObj && eventAlertObj['meanTemperature']  ? eventAlertObj['meanTemperature'] + tempDataCharts[0]?.unit : 'N/A' }}
                                </li>
                                <li class="list-group-item" *ngIf="tempDataCharts[0].label === 'Humidity'">
                                  <label class="mb-0"
                                    >Mostly Reported {{ tempDataCharts[0].label }}:
                                  </label>
                                  {{ eventAlertObj && eventAlertObj['meanHumidity'] ? eventAlertObj['meanHumidity'] + tempDataCharts[0]?.unit : 'N/A' }}
                                </li>
                                <li class="list-group-item" *ngIf="tempDataCharts[0].label === 'Temperature'">
                                  <label class="mb-0"
                                    >Mean Kinetic {{ tempDataCharts[0].label }}:
                                  </label>
                                  {{ eventAlertObj && eventAlertObj['MKT'] ? eventAlertObj['MKT'].toFixed(2) + tempDataCharts[0]?.unit : 'N/A' }}
                                </li>
                              </ul>
                            </div>
                            <div
                              [id]="tempDataCharts[0].label"
                              class="col border-left bg-white"
                              style="height: 302px"
                              [ngClass]="tempDataCharts[0].label === 'Temperature' ? 'tempGraph' : ''"
                              echarts
                              [options]="chartData"
                              [merge]="tempDataCharts[0].chart"
                              [autoResize]="true"
                            ></div>
                          </div>
                          <ng-template #notAvailable>
                            <div
                              class="d-flex justify-content-center w-100 h-100 align-items-center bg-white"
                            >
                              N/A
                            </div>
                          </ng-template>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- TEMPERATURE -->

                  <!-- HUMIDITY -->
                  <div
                    class="row" *ngIf="tempDataCharts.length && tempDataCharts[1]"
                  >
                    <div class="col-md-12 mb-3">
                      <div
                        class="card shadow-sm mb-1 widgetCards graphsCard-loader p-0"
                      >
                        <div class="card-body p-0">
                          <h6
                            class="m-0 p-2 w-100 bg-light text-sm font-weight-bold"
                          >
                            {{ constantVariables.ENDPOINT.LABEL1 }}
                            {{ tempDataCharts[1].label }} Summary [{{ tempDataCharts[1]?.unit }}]
                          </h6>
                          <div
                            class="d-flex align-items-stretch bg-white"
                            *ngIf="tempDataCharts[1].isLoadChart; else notAvailable"
                          >
                            <div
                              style="width: 295px"
                              class="d-flex align-items-center pl-2"
                            >
                              <ul
                                class="list-group list-group-flush description-text"
                              >
                                <li class="list-group-item">
                                  <label class="mb-0"
                                    >Current {{ tempDataCharts[1].label }}:
                                  </label>
                                  {{
                                    tempDataCharts[1]?.latestTemp?.val
                                      ? (tempDataCharts[1]?.latestTemp?.val
                                          | number: "1.2-2") + tempDataCharts[1]?.unit
                                      : "N/A"
                                  }}<br />
                                  <span class="text-xs text-secondary">{{
                                    tempDataCharts[1]?.latestTemp?.timeStamp
                                      | date: "dd MMM y, h:mm a"
                                  }}</span>
                                </li>
                                <li class="list-group-item">
                                  <label class="mb-0"
                                    >Maximum {{ tempDataCharts[1].label }}:</label
                                  >
                                  {{
                                    tempDataCharts[1]?.maxTemp?.val
                                      ? (tempDataCharts[1]?.maxTemp?.val
                                          | number: "1.2-2") + tempDataCharts[1]?.unit
                                      : "N/A"
                                  }}<br />
                                  <span class="text-xs text-secondary">{{
                                    tempDataCharts[1]?.maxTemp?.timeStamp
                                      | date: "dd MMM y, h:mm a"
                                  }}</span>
                                </li>
                                <li class="list-group-item">
                                  <label class="mb-0"
                                    >Minimum {{ tempDataCharts[1].label }}:
                                  </label>
                                  {{
                                    tempDataCharts[1]?.minTemp?.val
                                      ? (tempDataCharts[1]?.minTemp?.val
                                          | number: "1.2-2") + tempDataCharts[1]?.unit
                                      : "N/A"
                                  }}<br />
                                  <span class="text-xs text-secondary">{{
                                    tempDataCharts[1]?.minTemp?.timeStamp
                                      | date: "dd MMM y, h:mm a"
                                  }}</span>
                                </li>
                                <li class="list-group-item">
                                  <label class="mb-0"
                                    >Average {{ tempDataCharts[1].label }}:
                                  </label>
                                  {{
                                    tempDataCharts[1]?.avgTemp
                                      ? (tempDataCharts[1]?.avgTemp | number: "1.2-2") +
                                        tempDataCharts[1]?.unit
                                      : "N/A"
                                  }}
                                </li>
                                <li class="list-group-item" *ngIf="tempDataCharts[1].label === 'Temperature'">
                                  <label class="mb-0"
                                    >Mostly Reported Temp:
                                  </label>
                                  {{ eventAlertObj && eventAlertObj['meanTemperature']  ? eventAlertObj['meanTemperature'] + tempDataCharts[1]?.unit : 'N/A' }}
                                </li>
                                <li class="list-group-item" *ngIf="tempDataCharts[1].label === 'Humidity'">
                                  <label class="mb-0"
                                    >Mostly Reported {{ tempDataCharts[1].label }}:
                                  </label>
                                  {{ eventAlertObj && eventAlertObj['meanHumidity'] ? eventAlertObj['meanHumidity'] + tempDataCharts[1]?.unit : 'N/A' }}
                                </li>
                                <li class="list-group-item" *ngIf="tempDataCharts[1].label === 'Temperature'">
                                  <label class="mb-0"
                                    >Mean Kinetic {{ tempDataCharts[1].label }}:
                                  </label>
                                  {{ eventAlertObj && eventAlertObj['MKT'] ? eventAlertObj['MKT'].toFixed(2) + tempDataCharts[1]?.unit : 'N/A' }}
                                </li>
                              </ul>
                            </div>
                            <div
                              [id]="tempDataCharts[1].label"
                              class="col border-left bg-white"
                              style="height: 302px"
                              [ngClass]="tempDataCharts[1].label === 'Temperature' ? 'tempGraph' : ''"
                              echarts
                              [options]="chartData"
                              [merge]="tempDataCharts[1].chart"
                              [autoResize]="true"
                            ></div>
                          </div>
                          <ng-template #notAvailable>
                            <div
                              class="d-flex justify-content-center w-100 h-100 align-items-center bg-white"
                            >
                              N/A
                            </div>
                          </ng-template>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- HUMIDITY -->

                  <!--BluTag Temperature Range Alert -->
                  <div
                    class="row"
                    *ngFor="let option of chartDataOptions; let i = index"
                  >
                    <div class="col-md-12 mb-3">
                      <div
                        class="card shadow-sm mb-1 widgetCards graphsCard-loader p-0"
                      >
                        <div class="card-body p-0">
                          <h6
                            class="m-0 p-2 w-100 bg-light text-sm font-weight-bold"
                          >
                            {{ option.bluTag }} Temperature Summary [°C]
                          </h6>
                          <div
                            class="d-flex align-items-stretch bg-white"
                            *ngIf="option.isLoadChart; else notAvailable"
                          >
                            <div
                              style="width: 295px"
                              class="d-flex align-items-center pl-2"
                            >
                              <ul
                                class="list-group list-group-flush description-text"
                              >
                                <li class="list-group-item">
                                  <label class="mb-0"
                                    >Current Temperature:
                                  </label>
                                  {{
                                    option?.latestTemp?.val
                                      ? (option?.latestTemp?.val
                                          | number: "1.2-2") + " °C"
                                      : "N/A"
                                  }}<br />
                                  <span class="text-xs text-secondary">{{
                                    option?.latestTemp?.timeStamp * 1000
                                      | date: "dd MMM y, h:mm a"
                                  }}</span>
                                </li>
                                <li class="list-group-item">
                                  <label class="mb-0"
                                    >Maximum Temperature:
                                  </label>
                                  {{
                                    option?.maxTemp?.val
                                      ? (option?.maxTemp?.val
                                          | number: "1.2-2") + " °C"
                                      : "N/A"
                                  }}<br />
                                  <span class="text-xs text-secondary">{{
                                    option?.maxTemp?.timeStamp * 1000
                                      | date: "dd MMM y, h:mm a"
                                  }}</span>
                                </li>
                                <li class="list-group-item">
                                  <label class="mb-0"
                                    >Minimum Temperature:
                                  </label>
                                  {{
                                    option?.minTemp?.val
                                      ? (option?.minTemp?.val
                                          | number: "1.2-2") + " °C"
                                      : "N/A"
                                  }}<br />
                                  <span class="text-xs text-secondary">{{
                                    option?.minTemp?.timeStamp * 1000
                                      | date: "dd MMM y, h:mm a"
                                  }}</span>
                                </li>
                                <li class="list-group-item">
                                  <label class="mb-0"
                                    >Average Temperature:
                                  </label>
                                  {{
                                    option?.avgTemp
                                      ? (option?.avgTemp | number: "1.2-2") +
                                        " °C"
                                      : "N/A"
                                  }}
                                </li>
                                <li class="list-group-item" *ngIf="eventAlertObj && eventAlertObj['bluTagMode']">
                                  <label class="mb-0"
                                    >Mostly Reported Temp:
                                  </label>
                                  {{ eventAlertObj['bluTagMode'][option.bluTag]  }}
                                </li>
                              </ul>
                            </div>
                            <div
                              [id]="option.bluTag"
                              class="col border-left bg-white"
                              style="height: 302px"
                              echarts
                              [options]="chartData"
                              [merge]="option.chart"
                              [autoResize]="true"
                            ></div>
                          </div>
                          <ng-template #notAvailable>
                            <div
                              class="d-flex justify-content-center w-100 h-100 align-items-center bg-white"
                            >
                              N/A
                            </div>
                          </ng-template>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- BluTag Temperature Range Alert -->

                  <div class="row">
                    <!-- START EVENT -->
                    <div
                      *ngIf="shipmentData?.events?.length; else loading"
                      class="col bg-white detailing-card"
                      id="event_log"
                    >
                      <div
                        class="card shadow-sm mb-4 graphsCard-loader event-logs p-0"
                      >
                        <div class="card-body bg-white mt-0 p-0">
                          <p-table #ptable
                            [value]="trackerDataEvents"
                            [rows]="10"
                            class="table"
                            [paginator]="true"
                            [loading]="isLoading"
                            id="eventLog"
                            [resizableColumns]="true" columnResizeMode="expand"
                          >
                            <ng-template pTemplate="header">
                              <tr id="thead" class="thead">
                                <th pResizableColumn>Event Time</th>
                                <th pResizableColumn>Location</th>
                                <th pResizableColumn colspan="2">Address</th>
                                <th pResizableColumn *ngIf="chartDataOptions.length ">BluTag</th>
                                <th pResizableColumn *ngIf="chartDataOptions.length ">BluTag Temperature</th>
                                <th pResizableColumn>Temperature</th>
                                <th pResizableColumn>Humidity</th>
                                <th>Battery</th>
                                <th pResizableColumn
                                  *ngIf="
                                    trackerData[0].events[0].parsedData.signal 
                                  "
                                >
                                  Signal
                                </th>
                                <th pResizableColumn>Comments</th>
                              </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-row>
                              <!-- <pre [innerHtml]="row | json"></pre> -->
                              <!-- {{row.payload}} -->
                              <tr class="tbody">
                                <!-- <td
                                  *ngIf="chartDataOptions.length"
                                  [title]="row?.blueTag ||''"
                                  class="align-middle text-truncate"
                                >
                                  {{ row.blueTag }}
                                </td> -->
                                <td
                                  class="align-middle text-truncate"
                                  [title]="[(row.time | date: 'dd MMM y, h:mm a'), ' (' + row.timeStamp + ')']"
                                >
                                  {{ row.time | date: "dd MMM y, h:mm a" }}
                                </td>
                                <td
                                  [title]="row?.parsedData?.location && row?.parsedData?.location !== 'N/A' ? row?.parsedData?.location : ''"
                                  class="align-middle"
                                >
                                  {{ row.parsedData?.location || 'N/A' }}
                                </td>
                                <td
                                  [title]="row?.formattedAddress && row?.formattedAddress !== 'N/A' ? row?.formattedAddress : ''"
                                  [class.text-truncate]="isPaginated"
                                  class="align-middle"
                                  colspan="2"
                                >
                                  {{ row?.formattedAddress || 'N/A'}}
                                </td>
                                
                                <td *ngIf="chartDataOptions.length" class="align-middle text-truncate px-0">
                                  <table style="border-color: white!important;">
                                    <tr *ngFor="let sensor of row.sensors; index as i" class="text-center align-middle">
                                      <td class="cellTable p-0" style="border-color: #FFFFFF!important;"
                                      >{{sensor.tag}}</td>
                                      <!-- [ngStyle]="{'border-bottom' : i === row.sensors.length-1 ? 'grey !important ' : '' }" -->
                                    </tr>
                                  </table>
                                </td>
                                <td
                                  *ngIf="chartDataOptions.length"
                                  class="align-middle px-0"
                                >
                                <table style="border-color: white!important;">
                                  <tr *ngFor="let sensor of row.sensors; index as i" class="text-center align-middle">
                                    <td class="cellTable p-0" style="border-color: #FFFFFF!important;"
                                    >{{ sensor.temperature ? (sensor.temperature | number : '1.2-2') : 'N/A' }}</td>
                                    <!-- [ngStyle]="{'border-bottom' : i === row.sensors.length-1 ? 'grey !important ' : '' }" -->
                                  </tr>
                                </table>
                                  <!-- {{ row.temperature ? (row.temperature | number: "1.2-2") : '' }} -->
                                </td>

                                <td class="align-middle">
                                  {{ row.parsedData?.temp ? (row.parsedData?.temp | number: "1.2-2") : 'N/A' }}
                                </td>
                                <td class="align-middle">
                                  {{ row.parsedData?.humidity ? (row.parsedData?.humidity | number: "1.2-2") : 'N/A'}}
                                </td>
                                <td class="align-middle">
                                  {{ calculateBatteryPercentage(row.parsedData?.battery.split('V')[0]) + '% ' }}
                                  <i
                                    *ngIf="row.parsedData?.battery.split('V')[0]"
                                    class="fas widget-icon"
                                    [title]="calculateBatteryPercentage(row.parsedData?.battery.split('V')[0]) + '%'"
                                    [ngClass]="calculateBatteryPercentage(row.parsedData?.battery.split('V')[0], true)"
                                  ></i>
                                </td>
                                <td
                                  *ngIf="
                                    trackerData[0].events[0].parsedData.signal
                                  "
                                  class="align-middle"
                                >
                                  {{ row.parsedData?.signal }}
                                </td>
                                <td>
                                  <button
                                    type="text"
                                    class="btn editable-table-btn btn-xs icn-btn"
                                    (click)="op.toggle($event)"
                                    (click)="clearText(row)"
                                  >
                                    <i
                                      class="fas fa-pencil-alt text-primary"
                                    ></i>
                                  </button>
                                </td>
                              </tr>
                              <tr class='thead font-weight-bold text-center border-bottom comment-bg' *ngIf="row.comments">
                                <td class="align-middle" [colSpan]="columns-4">
                                  Comment
                                </td>
                                <td class="align-middle" colspan="2">Commented By</td>
                                <td class="align-middle" colspan="2">Time</td>
                              </tr>
                                <tr *ngFor="let comment of row.comments" class="comments comment-bg">
                                  <td [colSpan]="columns-4">{{comment.comment}} </td>
                                  <td colspan="2">{{comment.user}}</td> 
                                  <td colspan="2"> {{comment.timeStamp * 1000  | date: 'dd MMM y, h:mm a'}}</td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                              <tr>
                                <td colspan="7" class="text-center">
                                  No events found.
                                </td>
                              </tr>
                            </ng-template>
                          </p-table>
                          <div
                            class="w-100 p-2 text-sm text-muted bg-white text-center"
                            *ngIf="
                              !isPaginated && trackerDataEvents.length >= 400
                            "
                          >
                            **Report showing only 400 latest record as per
                            criteria, Please filter again by selecting date-time
                            accordingly to view old data.**
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- END EVENT -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  </div>
</div>
<!-- /Main content -->

<ng-template #loading>
  <div
    class="card-loader d-flex justify-content-center flex-wrap align-content-center w-100 h-75"
  >
    <div
      *ngIf="isLoading; else noData"
      class="spinner-grow text-dark"
      role="status"
    ></div>
  </div>
</ng-template>
<ng-template #noData>
  <div
    *ngIf="!shipmentData?.events?.length"
    id="no_events"
    class="card-loader d-flex justify-content-center flex-wrap align-content-center w-100 h-75"
  >
    <div class="text-dark" role="status">No events found.</div>
  </div>
</ng-template>

<p-overlayPanel #op appendTo="body">
  <div class="d-flex flex-col">
    <textarea class="form-control" placeholder="Write a Comment..." id="commentSection" rows="4"></textarea>
    <button (click)="op.hide()" (click)="saveComment()" class="btn btn-outline-success">Post</button>
  </div>
</p-overlayPanel>
