// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // host: 'http://20.198.123.163:4000',
  // terminalUrl: 'http://20.198.123.163:65000',
  host: window["env"]["host"] || 'http://localhost:4000',
  terminalUrl: window["env"]["host"] || 'http://localhost:65000',
  enableBase64: true,
  mapAPIKey: window["env"]["GOOGLE_API_KEY"] || "AIzaSyD7C5p7_J9BEAl9FAiWTUnMZMkRKMSyiGo"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
