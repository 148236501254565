<div class="tab-pane container-fluid active" id="identity_chain">
    <ng-scrollbar #scrollable track="horizontal" visibility="always" class="tabContent-scrollbar text-nowrap">
      <p-table [value]="(deviceData$ | async)" [rows]="config?.itemsPerPage"
        [paginator]="true" [loading]="!(deviceData$ | async)" [resizableColumns]="true" columnResizeMode="expand">
        <ng-template pTemplate="header">
          <tr>
            <th pResizableColumn>{{constantVariables.ENDPOINT.LABEL1}} Name</th>
            <!-- <th>MAC Address</th> -->
            <th pResizableColumn>Added By</th>
            <th pResizableColumn>Block</th>
            <th pResizableColumn>Identity Meta Data</th>
            <th pResizableColumn hidden >Event Ledger</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row>
          <tr>
            <td class="align-middle">{{row.data.model_name}}</td>
            <!-- <td class="align-middle">{{row.data.mac_addr}}</td> -->
            <td class="align-middle">{{row.edgeName | slice:0:50 }} {{row.edgeName?.length > 50 ? '...' : '' }} </td>
            <td class="align-middle">
                <button type="button"data-toggle="modal"
                data-target="#blockModal" (click)="openBlock(row)"
                class="btn primary-btn one small">
                <div class='insider'></div>
                Block
              </button>
            </td>
            <td class="align-middle">
                <button type="button" data-toggle="modal"
                data-target="#metadataModalDevice" (click)="openMetaData(row)"
                class="btn primary-btn one small">
                <div class='insider'></div>
                <i class="fas fa-eye-slash pr-1"></i> Identity Meta Data
              </button>
            </td>
            <td hidden class="align-middle">
                <button type="button"
                class="btn primary-btn one small">
                <div class='insider'></div>
                <i class="fas fa-eye-slash pr-1"></i> Event Ledger
              </button>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="5" class="text-center">No data found.</td>
            </tr>
        </ng-template>
      </p-table>
    </ng-scrollbar>
</div>


<!-- Popups code -->
<div class="modal fade" id="metadataModalDevice" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header bg-gradient-primary">
                <h6 class="modal-title" id="exampleModalLongTitle">Identity Meta Data</h6>
                <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form role="form">
                    <div class="row">
                        <label class="col-md-3 text-right text-capitalize small">Device Name:</label>
                        <div class="col-md-9 small px-4">
                            <label class="font-weight-normal small">{{deviceName}}</label>
                        </div>
                    </div>

                    <div class="row">
                        <label class="col-md-3 text-right text-capitalize small">Serial Number:</label>
                        <div class="col-md-9 small px-4">
                            <label class="font-weight-normal small">{{serialNumber}}</label>
                        </div>
                    </div>

                    <div class="row">
                        <label class="col-md-3 text-right text-capitalize small">Edge Name:</label>
                        <div class="col-md-9 small px-4">
                            <label class="font-weight-normal small">{{edgeName}}</label>
                        </div>
                    </div>

                    <div class="row">
                        <label class="col-md-3 text-right text-capitalize small">Edge Serial Number:</label>
                        <div class="col-md-9 small px-4">
                            <label class="font-weight-normal small">{{blSerialNumber}}</label>
                        </div>
                    </div>

                    <!-- <div class="row">
                        <label class="col-md-3 text-right text-capitalize small">MAC Address:</label>
                        <div class="col-md-9 small px-4">
                            <label class="font-weight-normal small">{{mac_addr}}</label>
                        </div>
                    </div> -->
                    <div class="row">
                        <label class="col-md-3 text-right text-capitalize small">Firmware:</label>
                        <div class="col-md-9 small px-4">
                            <label class="font-weight-normal small">{{Firmware}}</label>
                        </div>
                    </div>
                    <div class="row">
                        <label class="col-md-3 text-right text-capitalize small">Active:</label>
                        <div class="col-md-9 small px-4">
                            <label class="font-weight-normal small">{{active}}</label>
                        </div>
                    </div>
                    <div class="row">
                        <label class="col-md-3 text-right text-capitalize small">Boot rom version:</label>
                        <div class="col-md-9 small px-4">
                            <label class="font-weight-normal small">{{boot_rom_ver}}</label>
                        </div>
                    </div>
                    <div class="row">
                        <label class="col-md-3 text-right text-capitalize small">Device UUID:</label>
                        <div class="col-md-9 small px-4">
                            <label class="font-weight-normal small">{{deviceUUID}}</label>
                        </div>
                    </div>
                    <div class="row">
                        <label class="col-md-3 text-right text-capitalize small">Manufacturer's Name:</label>
                        <div class="col-md-9 small px-4">
                            <label class="font-weight-normal small">{{mfg_name}}</label>
                        </div>
                    </div>

                    <div class="row">
                        <label class="col-md-3 text-right text-capitalize small">Software Version:</label>
                        <div class="col-md-9 small px-4">
                            <label class="font-weight-normal small">{{sw_ver}}</label>
                        </div>
                    </div>



                    <!-- <div class="row">
                        <label class="col-md-3 text-right text-capitalize small">Product Id:</label>
                        <div class="col-md-9 small px-4">
                            <label class="font-weight-normal small"></label>
                        </div>
                    </div> -->
                    <!-- <div class="row">
                        <label class="col-md-3 text-right text-capitalize small">Device Type:</label>
                        <div class="col-md-9 small px-4">
                            <label class="font-weight-normal small"></label>
                        </div>
                    </div> -->

                    <!-- <div class="table-responsive text-nowrap mt-3">
                        <table class="table table-border">
                            <thead class="bg-light">
                                <tr>
                                    <th>#</th>
                                    <th>Sensor</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div> -->
                </form>
            </div>
            <div class="modal-footer p-2">
                <button type="button" class="btn btn-secondary px-4" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="blockModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header bg-gradient-primary">
                <h6 class="modal-title" id="exampleModalLongTitle">Identity Block</h6>
                <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form role="form">
                    <div class="row">
                        <label class="col-md-3 text-right text-capitalize small">Device token:</label>
                        <div class="col-md-9 small px-4">
                            <label class="font-weight-normal small">{{deviceToken}}</label>
                        </div>
                    </div>
                    <div class="row">
                        <label class="col-md-3 text-right text-capitalize small">Current hash:</label>
                        <div class="col-md-9 small px-4">
                            <label class="font-weight-normal small">{{currentHash}}</label>
                        </div>
                    </div>
                    <div class="row">
                        <label class="col-md-3 text-right text-capitalize small">Previous hash:</label>
                        <div class="col-md-9 small px-4">
                            <label class="font-weight-normal small">{{previousHash}}</label>
                        </div>
                    </div>
                    <div class="row">
                        <label class="col-md-3 text-right text-capitalize small">Time stamp:</label>
                        <div class="col-md-9 small px-4">
                            <label class="font-weight-normal small">{{timestamp}}</label>
                        </div>
                    </div>

                </form>
            </div>
            <div class="modal-footer p-2">
                <button type="button" class="btn btn-secondary px-4" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
