

<div class="tab-pane container-fluid active" id="a">
    <ng-scrollbar id="user_chain" #scrollable track="horizontal" visibility="always" class="tabContent-scrollbar text-nowrap">
      <p-table [value]="(userChain$ | async)" [rows]="config?.itemsPerPage"
        [paginator]="true" [loading]="!(userChain$ | async)" [resizableColumns]="true" columnResizeMode="expand">
        <ng-template pTemplate="header">
          <tr>
            <th pResizableColumn>User Name</th>
            <th pResizableColumn>Email</th>
            <th pResizableColumn>Block</th>
            <th pResizableColumn>User Meta Ledger</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row>
          <tr>
            <td class="align-middle">{{row.data.fullName}}</td>
            <td class="align-middle">{{row.data.officialEmail}}</td>
            <td class="align-middle">
                <button type="button" data-toggle="modal"
                data-target="#blockModal" (click)="openBlock(row)"
                class="btn primary-btn one small">
                <div class='insider'></div>
                 Block
              </button>
            </td>

            <td class="align-middle">
                <button type="button" data-toggle="modal"
                data-target="#metadataModalUser" (click)="openMetaData(row)"
                class="btn primary-btn one small">
                <div class='insider'></div>
                <i class="fas fa-eye-slash pr-1"></i> User Meta Ledger
              </button>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="4" class="text-center">No data found.</td>
            </tr>
        </ng-template>
      </p-table>
    </ng-scrollbar>
</div>

<!-- Popups -->
<div class="modal fade" id="metadataModalUser" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header bg-gradient-primary">
                <h6 class="modal-title" id="exampleModalLongTitle">User Meta Data</h6>
                <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form role="form">

                    <div class="row">
                        <label class="col-md-3 text-right text-capitalize small">Level:</label>
                        <div class="col-md-9 small px-4">
                            <label class="font-weight-normal small">{{userLevel}}</label>
                        </div>
                    </div>

                    <div class="row">
                        <label class="col-md-3 text-right text-capitalize small">realname:</label>
                        <div class="col-md-9 small px-4">
                            <label class="font-weight-normal small">{{fullName}}</label>
                        </div>
                    </div>
                    <!-- <div class="row">
                        <label class="col-md-3 text-right text-capitalize small">username :</label>
                        <div class="col-md-9 small px-4">
                            <label class="font-weight-normal small">{{userName}}</label>
                        </div>
                    </div> -->
                    <div class="row">
                        <label class="col-md-3 text-right text-capitalize small">email:</label>
                        <div class="col-md-9 small px-4">
                            <label class="font-weight-normal small">{{officialEmail}}</label>
                        </div>
                    </div>
                    <!-- <div class="row">
                        <label class="col-md-3 text-right text-capitalize small">Favourite:</label>
                        <div class="col-md-9 small px-4">
                            <label class="font-weight-normal small"></label>
                        </div>
                    </div> -->
                    <div class="row">
                        <label class="col-md-3 text-right text-capitalize small">Organization Name:</label>
                        <div class="col-md-9 small px-4">
                            <label class="font-weight-normal small">{{organizationName}}</label>
                        </div>
                    </div>
                    <!-- <div class="row">
                        <label class="col-md-3 text-right text-capitalize small">Generatedby:</label>
                        <div class="col-md-9 small px-4">
                            <label class="font-weight-normal small">{{generatedBy}}</label>
                        </div>
                    </div>
                    <div class="row">
                        <label class="col-md-3 text-right text-capitalize small">CreateAt:</label>
                        <div class="col-md-9 small px-4">
                            <label class="font-weight-normal small">{{createdAt}}</label>
                        </div>
                    </div> -->

                    <!-- <div class="row">
                        <label class="col-md-3 text-right text-capitalize small">UpdatedAt:</label>
                        <div class="col-md-9 px-4 small">
                            <label class="font-weight-normal small"></label>
                        </div>
                    </div> -->

                    <div class="row">
                        <label class="col-md-3 text-right text-capitalize small">Address:</label>
                        <div class="col-md-9 px-4 small">
                            <label class="font-weight-normal small">{{address}}</label>
                        </div>
                    </div>

                    <div class="row">
                        <label class="col-md-3 text-right text-capitalize small">City:</label>
                        <div class="col-md-9 px-4 small">
                            <label class="font-weight-normal small">{{city}}</label>
                        </div>
                    </div>
                    <div class="row">
                        <label class="col-md-3 text-right text-capitalize small">State:</label>
                        <div class="col-md-9 px-4 small">
                            <label class="font-weight-normal small">{{state}}</label>
                        </div>
                    </div>
                    <div class="row">
                        <label class="col-md-3 text-right text-capitalize small">Country:</label>
                        <div class="col-md-9 px-4 small">
                            <label class="font-weight-normal small">{{country}}</label>
                        </div>
                    </div>
                    <div class="row">
                        <label class="col-md-3 text-right text-capitalize small">Zip Code:</label>
                        <div class="col-md-9 px-4 small">
                            <label class="font-weight-normal small">{{zipCode}}</label>
                        </div>
                    </div>
                    <div class="row">
                        <label class="col-md-3 text-right text-capitalize small">Account Type:</label>
                        <div class="col-md-9 px-4 small">
                            <label class="font-weight-normal small">{{accountType}}</label>
                        </div>
                    </div>

                </form>
            </div>
            <div class="modal-footer p-2">
                <button type="button" class="btn btn-secondary px-4" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="blockModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header bg-gradient-primary">
                <h6 class="modal-title" id="exampleModalLongTitle">User Block</h6>
                <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form role="form">
                    <!-- <div class="row">
                        <label class="col-md-3 text-right text-capitalize small">Device token:</label>
                        <div class="col-md-9 small px-4">
                            <label class="font-weight-normal small"></label>
                        </div>
                    </div>
                    <div class="row">
                        <label class="col-md-3 text-right text-capitalize small">Device/User hash:</label>
                        <div class="col-md-9 small px-4">
                            <label class="font-weight-normal small"></label>
                        </div>
                    </div> -->
                    <div class="row">
                        <label class="col-md-3 text-right text-capitalize small">Current hash:</label>
                        <div class="col-md-9 small px-4">
                            <label class="font-weight-normal small">{{currentHash}}</label>
                        </div>
                    </div>

                    <div class="row">
                        <label class="col-md-3 text-right text-capitalize small">Previous hash:</label>
                        <div class="col-md-9 small px-4">
                            <label class="font-weight-normal small">{{previousHash}}</label>
                        </div>
                    </div>
                    <div class="row">
                        <label class="col-md-3 text-right text-capitalize small">Time stamp:</label>
                        <div class="col-md-9 small px-4">
                            <label class="font-weight-normal small">{{timestamp}}</label>
                        </div>
                    </div>

                </form>
            </div>
            <div class="modal-footer p-2">
                <button type="button" class="btn btn-secondary px-4" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
