import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UtilityComponent } from './components/utility/utility.component';
import { TimeClockComponent } from './components/time-clock/time-clock.component';
import { WeatherComponent } from './components/weather/weather.component';
import { TerminalComponent } from './components/terminal/terminal.component';
import { EventLogComponent } from './components/logs/event-log/event-log.component';
import { SystemLogComponent } from './components/logs/system-log/system-log.component';
import { LedgerComponent } from './components/ledger/ledger.component';
import { UserChainComponent } from './components/ledger/user-chain/user-chain.component';
import { IdentityChainComponent } from './components/ledger/identity-chain/identity-chain.component';
import { EdgeChainComponent } from './components/ledger/edge-chain/edge-chain.component';
import { CommunicationChainComponent } from './components/ledger/communication-chain/communication-chain.component';
import { SmartContractComponent } from './components/ledger/smart-contract/smart-contract.component';
import { LogsComponent } from './components/logs/logs.component';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { NodeMapComponent } from './components/node-map/node-map.component';
import { AlertsNotificationComponent } from './components/alerts-notification/alerts-notification.component';
import { SmartContractsManagementComponent } from './components/smart-contracts-management/smart-contracts-management.component';



const routes: Routes = [
  {
    path: '', component: UtilityComponent, canActivate:[AuthGuard],
    children: [
      { path: 'time-&-clocks', component: TimeClockComponent },
      { path: 'weather', component: WeatherComponent },
      { path: '', redirectTo: '/time-&-clocks', pathMatch: 'full' },
      { path: 'terminals', component: TerminalComponent },
      { path: 'node-maps', component: NodeMapComponent },
      { path: 'alerts-&-notifications', component: AlertsNotificationComponent },
      { path: 'smart-contracts', component: SmartContractsManagementComponent },
      
      // {path:'connectivity',component:},
      // {path:'data-storage',component},
      // {path:'node-maps',component:},
      {
        path:'ledger', component: LedgerComponent, canActivate:[AuthGuard],
        children: [
          { path:'user-chain', component: UserChainComponent },
          { path:'identity-chain', component: IdentityChainComponent },
          { path:'edge-chain', component: EdgeChainComponent },
          { path:'communication-chain', component: CommunicationChainComponent },
          { path:'smart-contract-chain', component: SmartContractComponent },
          { path: '', redirectTo:'user-chain',pathMatch:'full' },
        ]
      },
      {
        path:'logs', component: LogsComponent, canActivate:[AuthGuard],
        children: [
          { path:'system-logs', component: SystemLogComponent },
          { path:'event-logs', component: EventLogComponent },
          { path: '', redirectTo:'system-logs',pathMatch:'full' },
        ]
    }
    ]
  },
];

@NgModule({
  imports: [RouterModule,RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UtilityRoutingModule { }
