import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccessControlComponent } from './components/access-control/access-control.component';
import { ManagementComponent } from './components/management/management.component';
import { PoliciesComponent } from './components/policies/policies.component';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { AddNewUserComponent } from './components/add-new-user/add-new-user.component';
import { AddNewAccessLevelComponent } from './components/add-new-access-level/add-new-access-level.component';


const routes: Routes = [
  {
    path: '', component: AccessControlComponent, canActivate:[AuthGuard],
    children: [
      { path: 'user-access-policies', component: PoliciesComponent},
      { path: 'user-management', component: ManagementComponent},
      { path: '', redirectTo: 'user-access-policies', pathMatch: 'full' },
      {path:'add-new-user/:id', component: AddNewUserComponent},
      {path:'add-new-access-level', component: AddNewAccessLevelComponent}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccessControlRoutingModule { }
