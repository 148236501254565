<!-- Main content -->
<div class="row">
    <div class="col-12">
        <section class="content mt-4 mx-3">
            <div class="col-12 mb-3">

            </div>
            <!-- Default box -->
            <section class="content">
                <!-- Default box -->
                <div class="card no-border">
                    <div class="card-body p-0">
                        <div class="row">

                            <div
                                class="col-md-12 bg-white p-3 tab-content cus-rounded-left cus-rounded-right">
                                <div class="tab-pane container-fluid active mb-3" id="a1">
                                    <div class="row py-1 mx-2">
                                        <div class="col-md-4 col-xl-6 col-lg-6 col-sm-12">
                                            <div class="row">
                                                <div
                                                    class="form-group col-md-12 col-xl-6 col-lg-6 col-sm-12">
                                                    <div class="multiselect" id="multiselect">
                                                        <div class="multiselectBox">
                                                            <select class="form-control w-100">
                                                                <option value="" disabled selected>
                                                                </option>
                                                            </select>
                                                            <div class="overSelect"></div>
                                                        </div>

                                                        <div class="card selectcheckboxes select-sensorType"
                                                            id="selectcheckboxes">
                                                            <div class="card-body p-0">
                                                                <label for="selAll"
                                                                    class="font-weight-normal align-middle pt-2 px-3">
                                                                    All
                                                                </label>
                                                                <label for=""
                                                                    class="font-weight-normal align-middle pt-2 px-3">
                                                                    <img src="../../../assets/images/icons/temp.png"
                                                                        class="pr-3 img-fluid" />
                                                                    Temperature
                                                                </label>
                                                                <label for=""
                                                                    class="font-weight-normal align-middle pt-2 px-3">
                                                                    <img src="../../../assets/images/icons/humidity-black.png"
                                                                        class="img-fluid" /> Humidity
                                                                </label>
                                                                <label for=""
                                                                    class="font-weight-normal align-middle pt-2 px-3">
                                                                    <img src="../../../assets/images/icons/relay-black.png"
                                                                        class="img-fluid" />Relay
                                                                </label>
                                                                <label for=""
                                                                    class="font-weight-normal align-middle pt-2 px-3">
                                                                    <img src="../../../assets/images/icons/pir.png"
                                                                        class="img-fluid" /> PIR
                                                                </label>
                                                                <label for=""
                                                                    class="font-weight-normal align-middle pt-2 px-3">
                                                                    <img src="../../../assets/images/icons/ambi.png"
                                                                        class="img-fluid" /> Ambi
                                                                </label>
                                                                <label for=""
                                                                    class="font-weight-normal align-middle pt-2 px-3">
                                                                    <img src="../../../assets/images/icons/6-Wall Switch dark.png"
                                                                        class="img-fluid" />6 Button
                                                                    Controller
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-8 col-xl-6 col-lg-6 col-sm-12">
                                            <div class="row">
                                                <div
                                                    class="form-group col-md-5 col-xl-6 col-lg-6 col-sm-12">
                                                    <label class="has-float-label">
                                                        <input type="text" class="form-control"
                                                            placeholder="Filter by IP Address"
                                                            name="searchIp">
                                                        <span>Filter by IP Address</span>
                                                    </label>
                                                </div>
                                                <div
                                                    class="form-group col-md-5 col-xl-5 col-lg-5 col-sm-12">
                                                    <label class="has-float-label">
                                                        <input type="text" class="form-control"
                                                            placeholder="Filter by MAC Address"
                                                            name="searchMac">
                                                        <span>Filter by MAC Address</span>
                                                    </label>
                                                </div>
                                                <div
                                                    class="form-group col-md-2 col-xl-1 col-lg-1 col-sm-2 p-0">
                                                    <button type="button"
                                                        class="btn bg-gradient-primary w-100"
                                                        title="Refresh Data"><i
                                                            class="fas fa-sync"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- <ng-scrollbar #scrollable track="horizontal" visibility="always"
                                        class="tabContent-scrollbar text-nowrap"> -->
                                    <table class="table table-border mb-2">
                                        <thead class="bg-light">
                                            <tr>
                                                <!-- <th class="text-left"></th> -->
                                                <th>IP Address</th>
                                                <th>MAC Address</th>
                                                <th>Hostname</th>
                                                <th>EIP1</th>
                                                <th>EIP2</th>
                                                <th>EIP3</th>
                                                <th>EIP4</th>
                                                <th>EIP5</th>
                                                <th>Zones</th>
                                                <th>Enabled Timer</th>
                                                <th>HEA</th>
                                                <!-- <th class="text-center">Edit</th>
                    <th class="text-center">Delete</th> -->
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <!-- <td class="text-left"><img src="../../../assets/images/icons/temp.png" alt="Sensors Icon" /></td> -->
                                                <!--above image name will according to selected Sensor Type from filter section-->
                                                <td class="align-middle"></td>
                                                <td class="align-middle"></td>
                                                <td class="align-middle"></td>
                                                <td class="align-middle"></td>
                                                <td class="align-middle"></td>
                                                <td class="align-middle"></td>
                                                <td class="align-middle"></td>
                                                <td class="align-middle"></td>
                                                <td class="align-middle"></td>
                                                <td class="align-middle"></td>
                                                <td class="align-middle"></td>
                                                <!-- <td class="text-center" data-toggle="modal" data-target="#AddNewSensor"><i class="fa fa-pencil-alt"></i></td>
                    <td class="text-center" data-toggle="modal" data-target="#deleteModal"><i class="fa fa-trash text-danger"></i></td> -->
                                            </tr>


                                        </tbody>

                                    </table>

                                    <!-- </ng-scrollbar> -->
                                </div>


                                <div class="row d-flex justify-content-between mt-3">
                                    <div class="col-sm-6">
                                        <button type="button" class="mr-3 btn primary-btn one text-uppercase"
                                            data-toggle="modal" data-target="#ImportSensor">
                                            <div class='insider'></div>
                                            <i class="fas fa-plus pr-2"></i> IMPORT SENSORS
                                        </button>

                                        <button type="button" class="btn primary-btn one text-uppercase"
                                            data-toggle="modal" data-target="#AddNewSensor">
                                            <div class='insider'></div>
                                            <i class="fas fa-plus pr-2"></i> ADD NEW SENSOR
                                        </button>

                                    </div>
                                    <div class="col-sm-6 text-right">
                                        <!-- <pagination-controls (pageChange)="pageChanged($event)" *ngIf="sensorData && sensorData.length>0"></pagination-controls>
-->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- /.card -->

                    </div>
                    <!-- /.card-body -->
                </div>
            </section>




            <!--   Loader -->
            <!-- <div class="loader d-flex align-items-stretch justify-content-center align-items-center">
                <div class="d-flex justify-content-center align-items-center m-3">
                    <div class="sk-cube-grid">
                        <div class="sk-cube sk-cube1"></div>
                        <div class="sk-cube sk-cube2"></div>
                        <div class="sk-cube sk-cube3"></div>
                        <div class="sk-cube sk-cube4"></div>
                        <div class="sk-cube sk-cube5"></div>
                        <div class="sk-cube sk-cube6"></div>
                        <div class="sk-cube sk-cube7"></div>
                        <div class="sk-cube sk-cube8"></div>
                        <div class="sk-cube sk-cube9"></div>
                    </div>
                </div>
            </div> -->
        </section>

        <!--Delete Sensor table entry popup-->
        <div class="modal fade" id="deleteModal" tabindex="-1" role="dialog"
            aria-labelledby="deleteModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Delete Sensor</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p>Delete the Sensor</p>
                        <p class="m-0">Sensor Name: <b></b></p>
                        <p class="m-0">Sensor Type: <b></b></p>
                        <p class="m-0">IP Address: <b></b></p>
                        <p class="m-0">Mac Address: <b></b></p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            data-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-success"
                            data-dismiss="modal">Confirm</button>
                    </div>
                </div>
            </div>
        </div>
        <!--Delete Sensor table entry popup code ends here-->
        <!-- Modal -->
        <div class="modal fade" id="AddNewSensor" tabindex="-1" role="dialog"
            aria-labelledby="exampleModalLongTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-gradient-info">
                        <h6 class="modal-title" id="exampleModalLongTitle">Add New Sensor</h6>
                        <button type="button" class="close text-white" data-dismiss="modal"
                            aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form role="form">
                            <div class="row">
                                <div class="form-group col-md-6 py-2">
                                    <input type="text" class="form-control"
                                        placeholder="Create Sensor Name">
                                </div>
                                <div class="form-group col-md-6 py-2">
                                    <select class="form-control">
                                        <option>Select Sensor Type</option>
                                        <option>Sensor 2</option>
                                        <option>Sensor 3</option>
                                        <option>Sensor 4</option>
                                        <option>Sensor 5</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row py-2">
                                <div class="form-group col-md-6">
                                    <select class="form-control">
                                        <option>X Position</option>
                                        <option>Left</option>
                                        <option>Center</option>
                                        <option>Right</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-6">
                                    <select class="form-control">
                                        <option>Y Position</option>
                                        <option>Top</option>
                                        <option>Middle</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row py-2">
                                <div class="form-group col-md-6">
                                    <select class="form-control">
                                        <option>Select Campus</option>
                                        <option>Option 2</option>
                                        <option>Option 3</option>
                                        <option>Option 4</option>
                                        <option>Option 5</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-6">
                                    <select class="form-control">
                                        <option>Select Building</option>
                                        <option>Option 2</option>
                                        <option>Option 3</option>
                                        <option>Option 4</option>
                                        <option>Option 5</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row py-2">
                                <div class="form-group col-md-6">
                                    <select class="form-control">
                                        <option>Select Floor</option>
                                        <option>Option 2</option>
                                        <option>Option 3</option>
                                        <option>Option 4</option>
                                        <option>Option 5</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-6">
                                    <select class="form-control">
                                        <option>Select Zone</option>
                                        <option>Option 2</option>
                                        <option>Option 3</option>
                                        <option>Option 4</option>
                                        <option>Option 5</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row py-2">
                                <div class="form-group col-md-6">
                                    <div class="input-group">
                                        <select class="form-control">
                                            <option>Select MAC Address</option>
                                            <option>Top</option>
                                            <option>Middle</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <input type="text" class="form-control"
                                        placeholder="IP Address">
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> -->
                        <button type="button" class="btn btn-success">Save</button>
                    </div>
                </div>
            </div>
        </div>


        <!-- Modal to import Sensors -->
        <div class="modal fade" id="ImportSensor" tabindex="-1" role="dialog"
            aria-labelledby="exampleModalLongTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-gradient-info">
                        <h6 class="modal-title" id="exampleModalLongTitle">Import Sensor</h6>
                        <button type="button" class="close text-white" data-dismiss="modal"
                            aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form role="form">
                            <div class="row py-2">
                                <div class="form-group col-md-6">
                                    <select class="form-control">
                                        <option>Select Campus</option>
                                        <option>Option 2</option>
                                        <option>Option 3</option>
                                        <option>Option 4</option>
                                        <option>Option 5</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-6">
                                    <select class="form-control">
                                        <option>Select Building</option>
                                        <option>Option 2</option>
                                        <option>Option 3</option>
                                        <option>Option 4</option>
                                        <option>Option 5</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row py-2">
                                <div class="form-group col-md-6">
                                    <select class="form-control">
                                        <option>Select Floor</option>
                                        <option>Option 2</option>
                                        <option>Option 3</option>
                                        <option>Option 4</option>
                                        <option>Option 5</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-6">
                                    <div class="input-group">
                                        <div class="custom-file">
                                            <input type="file" class="custom-file-input"
                                                id="exampleInputFile" placeholder="Upload Data CSV"
                                                name="">
                                            <label class="custom-file-label" for="exampleInputFile"
                                                aria-describedby="inputGroupFileAddon"></label>
                                        </div>
                                        <div class="input-group-append">
                                            <!--<span class="input-group-text" >Upload</span>-->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row py-2">
                                <div class="form-group col-md-6">
                                    <a href="">Download Sample CSV</a>
                                </div>
                                <div class="form-group col-md-6">
                                    <span class="pr-4">Data Type</span>
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" class="custom-control-input"
                                            id="customRadio" name="example1" value="customEx">
                                        <label class="custom-control-label font-weight-normal"
                                            for="customRadio">Append Data</label>
                                    </div>
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" class="custom-control-input"
                                            id="customRadio" name="example1" value="customEx">
                                        <label class="custom-control-label font-weight-normal"
                                            for="customRadio">Clear Data</label>
                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            data-dismiss="modal">Cancel</button>
                        <button type="button" class="btn btn-success">Upload</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
<!-- /Main content -->

<!-- Control Sidebar -->
<aside class="control-sidebar control-sidebar-dark">
    <!-- Control sidebar content goes here -->
</aside>
<!-- /.control-sidebar -->
