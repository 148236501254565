import { IPermission } from './../../../../shared/models/side-nav.model';
import { Component, OnInit, ChangeDetectionStrategy, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { AccessService } from '../../services/access.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { API_ERROR } from 'src/app/core/constants/global-error.constants';
import { LEVELS } from 'src/app/core/constants/level.constants';
import { LIMIT } from 'src/app/core/constants/pagination.constants';
import { API_SUCCESS, BASE64LOGO } from 'src/app/core/constants/global-success.constants';
import { CommonService } from 'src/app/core/services/common.service';
import { MustMatchValidators } from 'src/app/shared/utilites/must-match.validators';
import { NgxSpinnerService } from 'ngx-spinner';
import { APIDefinition, Columns, Config, DefaultConfig} from 'ngx-easy-table';
import { NgScrollbar } from 'ngx-scrollbar';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import * as moment from 'moment';


@Component({
  selector: 'app-management',
  templateUrl: './management.component.html',
  styleUrls: ['./management.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManagementComponent implements OnInit, OnDestroy {

  @ViewChild(NgScrollbar, { static: true }) scrollbarRef: NgScrollbar;
  @ViewChild('table', { static: true }) table: APIDefinition;
  superLevel = LEVELS.SUPERADMIN;
  newUserForm: FormGroup;
  passwordEye: boolean;
  confirmPasswordEye: boolean;
  loader: boolean;
  isopenSearch: boolean;
  userData: any;
  config: { itemsPerPage: number; currentPage: number; totalItems: any; };
  editFlag = false;
  isReadOnly: boolean;
  accessData: any;
  userSmartContractData: any;
  // userId: any;
  deleteUserData: any;
  accessMenu: { [key: string]: IPermission };
  activeClass = "active";

  /**multiselect dropdown property for logical group */
  logicalGrpList = [];
  selectedLogicalGrp = [];
  logicalGrpSettings = {};
  logical_grp_1_devices = [];
  /**multiselect dropdown property for blocklock group */
  blocklockGrpList = [];
  selectedblocklockGrp = [];
  blocklockGrpSettings = {};


  nextButtonFlag = true;
  saveButtonFlag: boolean;
  tabIdTag: string;

  // ngx table configuration
  public configuration: Config;
  public userDatacolumns: Columns[];
  logedUserLevel: any;
  userInfo: any;

  showSmartContract: boolean = false;
  showUserModal: boolean = false;
  showSignupUserModal: boolean = false;
  userId: string;

  private _sub: Subscription = new Subscription();

  constructor(private accessService: AccessService, private formBuilder: FormBuilder
    , private toastr:ToastrService, private commonService:CommonService,
    private spinner: NgxSpinnerService, private route:ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.getLoginUserDetails();
    this.getUserData();
    this.addNewUserForm();
    this.setupMultiselectDropdownForLogicalGroup();
    this.setupMultiselectDropdownForBlocklockGroup();
    // this.toggleActiveClassOnLinksAndTabs();

    //ngx Table
    this.configuration.fixedColumnWidth = false;
    this.configuration.paginationEnabled = true;
    this.configuration.paginationMaxSize = 20;
    this.configuration.horizontalScroll = false;
    // Devices columns

    // if(this.logedUserLevel !== LEVELS.SUPERADMIN){
      this.userDatacolumns = [
        { key: 'photo', title: 'Photo' },
        { key: 'name', title: 'Name' },
        { key: 'client_name', title: "Client Name" },
        { key: 'user_name', title: 'User Name' },
        { key: 'security_level', title: 'Security Level'},
        { key: 'action', title: 'Actions' },
        // { key: 'smart_contract', title: 'Smart Contract' },
      ];
    /* } else {
      this.userDatacolumns = [
        { key: 'photo', title: 'Photo' },
        { key: 'name', title: 'Name' },
        { key: 'client_name', title: "Client Name" },
        { key: 'user_name', title: 'User Name' },
        { key: 'security_level', title: 'Security Level'},
        // { key: 'smart_contract', title: 'Smart Contract' },
        // { key: 'copy_token', title: 'Copy Token' },
        { key: 'action', title: 'Actions' },
      ];

    } */

  }

  toggleActiveClassOnLinksAndTabs() {
    let i; const items = $('.myclass'), pane = $('.my-tab-pane');
      // next
      $('.nexttab').on('click', ()=>{
          for(i = 0; i < items.length; i++){
              if($(items[i]).hasClass('active') == true){
                  break;
              }
          }
          if(i < items.length - 1){
              // for tab
              $(items[i]).removeClass('active');
              $(items[i+1]).addClass('active');
              // for pane
              $(pane[i]).removeClass('active');
            $(pane[i + 1]).addClass('active');
            if (pane[i + 1].id === 'd1-manageRule') {
              $('.nexttab').css('display', 'none');
              this.saveButtonFlag = true;
            }
          }
      });
  }

  setupMultiselectDropdownForLogicalGroup() {
    this.logicalGrpList = [
      { "id": 1, "itemName": "logical_grp_1" },
      { "id": 2, "itemName": "logical_grp_2" },
      { "id": 3, "itemName": "logical_grp_3" },
      { "id": 4, "itemName": "logical_grp_4" },
      { "id": 5, "itemName": "logical_grp_5" },
      { "id": 6, "itemName": "logical_grp_6" }
  ];
  this.selectedLogicalGrp = [];
    this.logicalGrpSettings = {
      singleSelection: false,
      text: "Select Logical Group",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: "myclass custom-class",
  };
  }

  setupMultiselectDropdownForBlocklockGroup() {
    this.blocklockGrpList = [
      { "id": 1, "itemName": "blocklock_grp_1" },
      { "id": 2, "itemName": "blocklock_grp_2" },
      { "id": 3, "itemName": "blocklock_grp_3" },
      { "id": 4, "itemName": "blocklock_grp_4" },
      { "id": 5, "itemName": "blocklock_grp_5" },
      { "id": 6, "itemName": "blocklock_grp_6" }
  ];
  this.selectedblocklockGrp = [];
    this.blocklockGrpSettings = {
      singleSelection: false,
      text: "Select Blocklock Group",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: "myclass custom-class",
  };
  }


  /**
   * @description create new user form with validation
   */
  addNewUserForm() {
    this.newUserForm = this.formBuilder.group({
      fullName: ['', Validators.required],
      officialEmail : ['', [Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      password: ['', [Validators.required,Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,30}$/)]],
      confirmPassword : ['', [Validators.required,Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,30}$/)]],
      address: ['',Validators.required],
      contactNumber : ['',[Validators.required,Validators.pattern("^[0-9]{10}$")]],
      profilePic: [''],
      fileName:[''],
      userLevel: ['', Validators.required],
      logicalGroup: [[]],
      blockLockGroup:[[]]
    }, {
      validators:MustMatchValidators('password','confirmPassword')
    })

  }


  /**
   *
   * @param event
   * @description upload user profile image
   */
  onFileChanged(event) {
    const [file] = event.target.files;
    if (file) {
      this.newUserForm.controls['fileName'].setValue(file.name);
      this.newUserForm.controls['profilePic'].setValue(file);
      if (!this.validateFile(file.name)) {
        this.toastr.error(API_ERROR.FILE_NOT_SUPPORTED, ' ', {
            timeOut: 3000
        });
        this.newUserForm.controls['fileName'].setValue('');
      this.newUserForm.controls['profilePic'].setValue('');
        return false;
    }
    }
  }

  /**
   * @description remove selected image
   */
  removeImage() {
    this.newUserForm.controls['profilePic'].setValue('');
    this.newUserForm.controls['fileName'].setValue('');
  }

  /**
   *
   * @param name
   * @description validate file name
   */
  validateFile(name: string) {
    const ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() == 'jpg' || ext.toLowerCase() == 'png') {
        return true;
    }
    else {
        return false;
    }
}

  /**
   * @description get login user data
   */
  getLoginUserDetails() {
    const { user } = this.commonService.getCurrentUserData();
    this.userInfo = user;
    const { accesslevel } = user;
    this.logedUserLevel = accesslevel.name;
    if (accesslevel && accesslevel.permissions) {
      this.accessMenu = accesslevel.permissions['access-control'].submenu;
    }
  }

  /**
   * @description get all access level list
   */
  getAccessLevels() {
    this.spinner.show();
    this._sub.add(this.accessService.getAccessLevel().subscribe(
        (response) => {
        this.accessData = response;
        // this.accessData = this.accessData.filter(levels => levels.levelname != LEVELS.ADMIN);
        this.spinner.hide();
        this.cdr.detectChanges();
        },
        (error) => {
          console.log(error);
          this.spinner.hide();
          if (error?.message === API_ERROR.USER_LOGOUT) {
            this.commonService.logout(API_ERROR.USER_LOGOUT);
          }
        }
    ));
  }

  /**
   *
   * @param roleId
   * @description filter user smart contract data based on roleid
   */
  getUserSmartContract(roleId) {
    this.showSmartContract = true;
    this.spinner.show('modal-loading');
    this._sub.add(this.accessService.getAccessLevel().subscribe(
      (response) => {
      this.accessData = response;
      // this.accessData = this.accessData.filter(levels => levels.levelname != LEVELS.ADMIN);
      this.userSmartContractData = this.accessData?.find(roleObj => roleObj.id === roleId);
      this.spinner.hide('modal-loading');
      },
      (error) => {
        console.log(error);
        this.spinner.hide('modal-loading');
        if (error?.message === API_ERROR.USER_LOGOUT) {
          this.commonService.logout(API_ERROR.USER_LOGOUT);
        }
      }
    ));
  }

  copyUserToken(roleId) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (roleId));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
    this.toastr.success('Token Copied!');
  }

  setupActiveClassOnTabs() {
    $('#policy').removeClass('active');
    $('#device-access').removeClass('active');
    $('#manage-rules').removeClass('active');
    $('#user').addClass('active');
    $('#a1-user').addClass('active');
    $('#d1-manageRule').removeClass('active');
    $('#c1-device').removeClass('active');
    $('#b1-accessLevel').removeClass('active');
    $('.nexttab').css('display', 'block');
    this.saveButtonFlag = false;
  }

  /**
   * @description reset form to its initial stage
   */
  resetForm() {
    this.setupActiveClassOnTabs();

    this.newUserForm.controls['fullName'].setValue('');
    this.newUserForm.controls['officialEmail'].setValue('');
    this.newUserForm.controls['address'].setValue('');
    this.newUserForm.controls['contactNumber'].setValue('');
    this.newUserForm.controls['password'].setValue('');
    this.newUserForm.controls['confirmPassword'].setValue('');
    this.newUserForm.controls['userLevel'].setValue('');
    this.newUserForm.controls['profilePic'].setValue('');
    this.newUserForm.controls['fileName'].setValue('');
    this.newUserForm.controls['password'].enable();
    this.newUserForm.controls['password'].setValidators([Validators.required,Validators.pattern("^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$")]);
    this.newUserForm.controls['password'].updateValueAndValidity();
    this.newUserForm.controls['confirmPassword'].enable();
    this.newUserForm.controls['confirmPassword'].setValidators([Validators.required,Validators.pattern("^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$")]);
    this.newUserForm.controls['confirmPassword'].updateValueAndValidity();
    this.newUserForm.updateValueAndValidity();
  }

  /**
   *
   * @param type
   * @description based on type toggle usertab flag
   */
  resetFlag(type) {
    if (type != 'manage-rules') {
      $('.nexttab').css('display', 'block');
      this.saveButtonFlag = false;
    } else {
      $('.nexttab').css('display', 'none');
      this.saveButtonFlag = true;
    }
}

  /**
   * @description get all user list
   */
  getUserData() {
    this.configuration = { ...DefaultConfig };
    this.configuration.isLoading = true;
    this._sub.add(this.accessService.getUserManagement().subscribe(
      (response: { data }) => {
        this.configuration.isLoading = false;
        this.userData = response.data;
        if (this.userData && this.userData.length)
          this.config = {
            itemsPerPage: LIMIT.PER_PAGE,
            currentPage: LIMIT.CURRENT_PAGE,
            totalItems: this.userData.length,
          };
          this.cdr.markForCheck();
      },
      (error) => {
        this.configuration.isLoading = false;
        error;
        if (error?.message === API_ERROR.USER_LOGOUT) {
          this.commonService.logout(API_ERROR.USER_LOGOUT);
        }
      }
    ));
  }


  // Generate PDF report
   generatePDF(name: string, email: string) {
     let sessionReport;
     this.accessService.getUserActionReport([email]).subscribe((events: any) => {
      sessionReport = events[0];
      // Initialize PDF document
      const doc = new jsPDF('p', 'mm', 'a4', true);
      // Define report title and metadata
      const reportTitle = name + "'s Session Report";
      const reportDate = new Date().toLocaleDateString();
      doc.setProperties({
        title: reportTitle,
        author: 'Your Name',
        subject: 'User session report',
        keywords: 'user session, report, pdf'
      });
  
      // Define report content
      let y = 30;
      const leftMargin = 10;
      doc.setFontSize(18);
      doc.text(reportTitle, 10, y);
      doc.setFontSize(12);
      y += 10;
      doc.text(`Report generated on: ${reportDate}`, leftMargin, y);
      y += 10;
      doc.text(`Report generated by: ${this.userInfo.fullName}`, leftMargin, y);
      y += 20;
      doc.setFontSize(16);
      doc.text('Session Summary', leftMargin, y);
      y += 10;
      doc.setFontSize(12);
      doc.text(`Total number of sessions: ${sessionReport.totalSessions}`, leftMargin, y);
      y += 10;
      doc.text(`Total time spent in sessions: ${this.formatDuration(sessionReport.sessionTime)}`, leftMargin, y);
      y += 20;
      doc.setFontSize(16);
      doc.text('Session History', leftMargin, y);
      y += 10;
      doc.setFontSize(12);
      const headers = ['Start Time', 'End Time', 'Total Time (seconds)'];
      const data = sessionReport.sessionHistory.map(session => [this.convertTime(session.start), this.convertTime(session.end), this.formatDuration(session.timeSpent)]);
      // doc.autoTable({ startY: y, head: [headers], body: data });
      autoTable(doc, 
        {
          startY: y, head: [headers], body: data, 
          margin: {
            top: 29,
            left: 10,
            right: 4
          }
        });
      
      this.addFooters(doc);
      // Save and download PDF document
      doc.save(`${reportTitle} ${reportDate}.pdf`);
      // doc.output('dataurlnewwindow');
       var string = doc.output('datauristring');
       var iframe = "<iframe width='100%' height='100%' src='" + string + "'></iframe>"
       var x = window.open();
       x.document.open();
       x.document.write(iframe);
       x.document.title = `${reportTitle} ${reportDate}`;
       x.document.close();
    });
  }

 formatDuration(duration: number): string {
  const days = Math.floor(duration / 86400);
  const hours = Math.floor((duration % 86400) / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const seconds = Math.floor(duration % 60);
   let formattedDuration = '';
   if (days > 0) {
     formattedDuration += `${days}days `;
   }
   if (hours > 0 || formattedDuration) {
     formattedDuration += `${hours.toString().padStart(2, '0')}hr `;
   }
   if (minutes > 0 || formattedDuration) {
     formattedDuration += `${minutes.toString().padStart(2, '0')}min `;
   }
   formattedDuration += `${seconds.toString().padStart(2, '0')}sec`;

   return formattedDuration;
 }

convertTime(time){
  return moment(new Date(time * 1000)).format("ddd MMM DD y h:mm a")
}

  private addFooters(doc) {
    const pageCount = doc.internal.getNumberOfPages()

    doc.setFont('helvetica', 'italic')
    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i)
      doc.addImage(BASE64LOGO, 'PNG', 5.5, 10, 25, 10);
      doc.setFontSize(12)
      doc.text('Report Timestamp', (doc.internal.pageSize.width / 1.2350), 12.5)
      doc.setFontSize(8)
      const date = moment(new Date()).format("ddd MMM DD y h:mm a");
      doc.text(`${date}`, (doc.internal.pageSize.width / 1.22550), 17.5)
      doc.setFontSize(8)
      doc.text('Page ' + String(i) + ' of ' + String(pageCount), (doc.internal.pageSize.width / 1.025), 290, {
        align: 'right',
      });
    }
  }

  /**
   * @description page change in pagination
   */
  // pageChanged(event) {
  //   this.config.currentPage = event;
  // }


  /**
   * @description save new user and update new user based on edit flag
   */
  addNewUser() {

    this.isReadOnly = false;
    if (this.newUserForm.invalid) return;

    if (this.editFlag) {
      const updatedData = { ...this.newUserForm.value, id:this.userId };
      this._sub.add(this.accessService.postUserManagement(updatedData).subscribe(
        (res) => {
          this.toastr.success(this.newUserForm.controls['fullName'].value + API_SUCCESS.UPDATED_SUCCESS, ' ');
          this.getUserData();
          this.editFlag = false;
        },
        (error) => {
          if (error?.message === API_ERROR.USER_LOGOUT) {
            this.commonService.logout(API_ERROR.USER_LOGOUT);
          } else if (error.error.message == API_ERROR.CANTNOT_CREATE_SUPERADMIN_AGAIN) {
            this.toastr.error(API_ERROR.SUPERADMIN_ALREADY_EXISTS, '');
          } else if (error.error.message == API_ERROR.ACCESSLEVEL_SUPERADMIN_UPDATE_ERROR) {
            this.toastr.error(API_ERROR.ACCESSLEVEL_SUPERADMIN_UPDATE_ERROR,'')
          }else {
            this.toastr.error(API_ERROR.SOMETHING_WENT_WRONG, "");
        }
          this.editFlag = false;
        })
      )
    } else {
        this._sub.add(this.accessService.postUserManagement(this.newUserForm.value).subscribe(
          (response) => {
            response
            this.toastr.success(this.newUserForm.controls['fullName'].value + API_SUCCESS.CREATED_SUCCESS, ' ',);
            this.newUserForm.reset(this.newUserForm.value);
            this.editFlag = false;
              this.getUserData();
          },
          (error) => {
            this.editFlag = false;
            if (error?.message === API_ERROR.USER_LOGOUT) {
              this.commonService.logout(API_ERROR.USER_LOGOUT);
            } else if (error.error.message == API_ERROR.EMAIL_ALREADY_EXISTS) {
                this.toastr.error(API_ERROR.EMAIL_ALREADY_EXISTS, '');
            } else if (error.error.message == API_ERROR.CANTNOT_CREATE_SUPERADMIN_AGAIN) {
              this.toastr.error(API_ERROR.SUPERADMIN_ALREADY_EXISTS, '');
            }else if (error.error.message == API_ERROR.ACCESSLEVEL_SUPERADMIN_UPDATE_ERROR) {
              this.toastr.error(API_ERROR.ACCESSLEVEL_SUPERADMIN_UPDATE_ERROR,'')
            }
            else {
                this.toastr.error(API_ERROR.SOMETHING_WENT_WRONG, "");
            }
          }
      ))
    }
  }

  /**
   *
   * @param data
   * @description open delete modal
   */
  openDeleteModal(data) {
    this.deleteUserData = data;
  }

  /**
   * @description delete user except admin
   */
  deleteUser() {
    if (this.deleteUserData.userLevel.toLowerCase() === LEVELS.SUPERADMIN.toLowerCase()) {
      this.toastr.warning(API_ERROR.NOT_AUTHORISED, ' ', {
          timeOut: 3000
      });
      return false;
  }
    this._sub.add(this.accessService.deleteUserManagement(this.deleteUserData).subscribe(
        (response) => {
            response;
            this.toastr.success(this.deleteUserData.fullName + API_SUCCESS.DELETED_SUCCESS, ' ', {
                timeOut: 3000
            });
            this.getUserData();
        },
        (error) => {
          if (error?.message === API_ERROR.USER_LOGOUT) {
            this.commonService.logout(API_ERROR.USER_LOGOUT);
          }
            error
        }
    ));
}

/**
 * @description close new user modal popup and make  email field readonly flag false
 */
  closeAddNewUserModel() {
    this.isReadOnly = false;
    this.editFlag = false;
  }

  /**
   *
   * @param data
   * @description open edit modal and store user data on form fields
   */
  openEditModel(data) {
    this.setupActiveClassOnTabs();
    this.editFlag = true;
    this.isReadOnly = true;
    this.newUserForm.controls['fullName'].setValue(data.fullName);
    this.newUserForm.controls['officialEmail'].setValue(data.officialEmail);
    this.newUserForm.controls['address'].setValue(data.address);
    this.newUserForm.controls['contactNumber'].setValue(data.contactNumber);
    this.newUserForm.controls['password'].setValue(data.password);
    // this.newUserForm.controls['confirmPassword'].setValue('');
    this.newUserForm.controls['userLevel'].setValue(data.userLevel);
    this.newUserForm.controls['profilePic'].setValue(data.profilePicture);
    this.newUserForm.controls['fileName'].setValue(data.profilePicture);
    this.userId = data.id;
    this.newUserForm.controls['password'].clearValidators();
    this.newUserForm.controls['password'].disable();
    this.newUserForm.controls['password'].updateValueAndValidity();
    this.newUserForm.controls['confirmPassword'].clearValidators();
    this.newUserForm.controls['confirmPassword'].disable();
    this.newUserForm.controls['confirmPassword'].updateValueAndValidity();
    this.newUserForm.updateValueAndValidity();
  }

  /*convinience getter to easy access form fields*/
  get f() {
    return this.newUserForm.controls;
  }

  /*toggle eye icon for password*/
  toggelEyeButtonPassword() {
    if (this.f.password.value)
      this.passwordEye = !this.passwordEye;
  }

  /*toggle eye icon for confirmPassword*/
  toggelEyeButtonConPassword() {
    if (this.f.confirmPassword.value)
      this.confirmPasswordEye = !this.confirmPasswordEye;
  }

  onItemSelect(item: any) {
    this.logical_grp_1_devices = [
      { id: "1", deviceName: "Dell", macAddress: "12.23.12", status: "active", access: "View Access" },
      { id: "2", deviceName: "intel", macAddress: "12.23.12", status: "active", access: "View Access" },
      { id: "3", deviceName: "hp", macAddress: "12.23.12", status: "active", access: "View Access" },
    ]
    // console.log(item);
    // console.log(this.selectedItems);
  }
  OnItemDeSelect(item: any) {
    this.logical_grp_1_devices = [];
    // console.log(item);
    // console.log(this.selectedItems);
  }
  onSelectAll(items: any) {
    // this.selectedItems = items;
    // // this.newUserForm.controls['logicalGroup'].setValue(items);
    // console.log(items);
  }
  onDeSelectAll(items: any) {
    // this.selectedItems = items;
    // this.newUserForm.controls['logicalGroup'].setValue(items);
    // console.log(items);
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }

}
