import { Observable, throwError } from 'rxjs';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgScrollbar } from 'ngx-scrollbar';
import { EndPointService } from 'src/app/modules/end-point-management/services/end-point.service';
import { ConstantVariables } from 'src/constants/constants';
import { catchError, map } from 'rxjs/operators';
import { CommonService } from 'src/app/core/services/common.service';
import { API_ERROR } from 'src/app/core/constants/global-error.constants';

@Component({
  selector: 'app-identity-chain',
  templateUrl: './identity-chain.component.html',
  styleUrls: ['./identity-chain.component.css']
})
export class IdentityChainComponent implements OnInit {

  @ViewChild(NgScrollbar, { static: true }) scrollbarRef: NgScrollbar;
  config: { itemsPerPage: number; currentPage: number; totalItems: any; };
  deviceToken: any;
  currentHash: any;
  previousHash: any;
  timestamp: any;
  deviceName: any;
  mac_addr: any;
  serialNumber: any;
  blSerialNumber: any;
  Firmware: any;
  active: any;
  boot_rom_ver: any;
  client_id: any;
  deviceUUID: any;
  mfg_name: any;
  model_name: any;
  sw_ver: any;
  token: any;
  index: any;
  edgeName: any;

  deviceData :any;
  deviceData$: Observable<any>;

  constructor(
    public constantVariables: ConstantVariables,
    private commonService: CommonService,
    private endPointService: EndPointService,
  ) { }

  ngOnInit(): void {
    this.getAllDeviceList();
  }

   /**
    * Description: get all identity chain list api calls
   * @description get all identity chain list api calls
   */
  getAllDeviceList() {
    this.deviceData$ = this.endPointService.getAllEndPoint().pipe(
      map((res: any[]) => {
        const deviceData = res || [];
        if (deviceData.length > 0) {
          this.config = {
            itemsPerPage: 10,
            currentPage: 1,
            totalItems: deviceData.length
          }
        }
        return deviceData;
      }),
      catchError(error => {
        if (error?.message === API_ERROR.USER_LOGOUT) {
          this.commonService.logout(API_ERROR.USER_LOGOUT);
        } else {
          return throwError(error);
        }
      })
    );
  }
  /**
    * Description: open block data
   * @description open block data
   * @param data selected data to populate block
   */
  openBlock(data) {
    this.deviceToken = data.data.token;
    this.currentHash = data.currentHash;
    this.previousHash = data.previousHash;
    this.timestamp = data.timestamp;
  }

  /**
    * Description: open meta data
   * @description open meta data
   * @param data selected data to populate meta data
   */
  openMetaData(data) {
    this.deviceName = data.data.model_name;
    this.mac_addr = data.data.mac_addr;
    this.serialNumber = data.data.serial_number;
    this.blSerialNumber = data.data.blSerialNumber;
    this.Firmware = data.data.Firmware;
    this.active = data.data.active;
    this.boot_rom_ver = data.data.boot_rom_ver;
    this.deviceUUID = data.data.deviceUUID;
    this.mfg_name = data.data.mfg_name;
    this.model_name = data.data.model_name;
    this.sw_ver = data.data.sw_ver;
    this.token = data.data.token;
    this.edgeName = data.edgeName;

  }
}
