import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Company_Size } from 'src/app/core/enums/company-size.enum';
import { AuthService } from 'src/app/core/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { API_ERROR } from 'src/app/core/constants/global-error.constants';
import { TenantSignup } from 'src/app/shared/models/tenant-signup.model';
import { MustMatchValidators } from 'src/app/shared/utilites/must-match.validators';
import { API_SUCCESS } from 'src/app/core/constants/global-success.constants';
import { CountdownComponent } from 'ngx-countdown';
import { countries } from 'src/app/modules/dashboard/components/edit-profile/country';
import { NgScrollbar } from 'ngx-scrollbar';

@Component({
  selector: 'app-tenant-signup',
  templateUrl: './tenant-signup.component.html',
  styleUrls: ['./tenant-signup.component.css']
})
export class TenantSignupComponent implements OnInit {
  
  @ViewChild("cd") private countdown: CountdownComponent;
  @ViewChild(NgScrollbar, { static: true }) scrollbarRef: NgScrollbar;
  countdownFlag:boolean = false;
  isDisabled = false;

  signupForm: FormGroup;
  loading: boolean;
  display = 'none';
  submitted: boolean;
  passwordEye: boolean;
  confirmPasswordEye: boolean;
  company_Size = Company_Size;
  isEnterprise = true;
  isBuyNow = "none";
  showResendLink: boolean;
  selectcountry: any = '';
  selectcountries: any = countries;
  termsConditionFlag: boolean;
  showContactNumber: boolean = false;

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private toaster: ToastrService,
  ) {}

  ngOnInit(): void {
    if (window.getSelection) {
      if (window.getSelection().empty) {  // Chrome
        window.getSelection().empty();
      } else if (window.getSelection().removeAllRanges) {  // Firefox
        window.getSelection().removeAllRanges();
      }
    } 
    this.createForm();
    this.toaster.clear();
    this.sendResendLink();
  }

  sendResendLink() {
    const {isResendLink, emailForResendLink } = window.history.state;
    if (isResendLink && emailForResendLink) {
      this.submitted = true;
      this.signupForm.controls.tenantEmail.setValue(emailForResendLink);
      this.showResendLink = true;
    }
    
  }

  afterFinishCounter(e:Event) {
    if (e["action"] == "done"){
      this.countdownFlag = false;
      this.isDisabled = false;
    };
  }

  resendEmail() {
    this.countdownFlag = true;
    this.isDisabled = true;
    this.countdown.restart();
    this.countdown.begin();
    this.authService.resendMail(this.f.tenantEmail.value).subscribe(res => {
      this.toaster.success(API_SUCCESS.RESEND_LINK, '');
    }, error => {
      if (error?.message !== API_ERROR.USER_LOGOUT) {
        this.toaster.error(API_ERROR.RESEND_LINK_FAIL, '');
      }
    })
  }

  /**
   * @description disable scrolling on mobile number
   */
  @HostListener('window:keydown', ['$event'])
  disableScrolling(event:KeyboardEvent) {
     if (event.keyCode === 38 || event.keyCode === 40) {
        event.preventDefault();
      }
    }

  /*create signup form */
  createForm() {
    /*Initialize all signup form fields with validation*/
    this.signupForm = this.formBuilder.group({
      tenantName: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      accountType:['Enterprise'],
      // contactPersonName:['', Validators.required],
      tenantEmail:['', [Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      phoneCode:[''],
      contactNumber:[''],
      // tenantEmpSize: ['', Validators.required],
      password: ['', [Validators.required,Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,30}$/)]],
      confirmPassword: ['', [Validators.required,Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,30}$/)]],
      privacyPolicyTC: [false, Validators.requiredTrue],
      // address: [''],
      // city: [''],
      // country: ['',Validators.required],
      // state: [''],
      // zipCode: ['',Validators.required],
      enableSms:[false],
      tryforFee:[true],
      BuyNow:[false]
    }, {
      validators:MustMatchValidators('password','confirmPassword')
    });
  }

  /*convinience getter to easy access form fields*/
  get f() {
    return this.signupForm.controls;
  }

  checkAccoutType() {
    if (this.f.accountType.value === 'Enterprise') {
      this.signupForm.controls['tenantName'].setValidators(Validators.required);
      this.signupForm.controls['tenantEmpSize'].setValidators(Validators.required);
       this.isEnterprise = true;
    } else {
      this.signupForm.controls['tenantName'].clearValidators();
      this.signupForm.controls['tenantEmpSize'].clearValidators();
      this.isEnterprise = false;
    }
    this.signupForm.controls['tenantName'].updateValueAndValidity();
      this.signupForm.controls['tenantEmpSize'].updateValueAndValidity();
  }

  /** 
   * validate signup form and send data to authservice signup method 
   * based on response account created popup will display 
   * */ 
  signUp(): void {
    if (this.signupForm.invalid) return;
    this.loading = true;
    let user: TenantSignup = { ...this.signupForm.value };
    if (!this.isEnterprise) {
      delete user.tenantName;
      delete user.tenantEmpSize;
    }
    user['contactNumber'] = String(user['phoneCode']) + String(user['contactNumber'])
    user['sendEmailAlerts'] = true;
    user['reportType'] = 'Default';
    user['sendDailyReport'] = true;
    delete user['phoneCode'];
    
    delete user.confirmPassword;
    // user.password = window.btoa(user.password);
    this.authService.tenantSignUp(user).subscribe(
      (response) => {
        response;
        sessionStorage.setItem('user', JSON.stringify(response));
        this.display = 'block';
        this.loading = false;
        this.submitted = true;
      },
      (error) => {
        if (error?.message !== API_ERROR.USER_LOGOUT) {
          if (error.message == API_ERROR.EMAIL_ALREADY_EXISTS) this.toaster.error(API_ERROR.EMAIL_ALREADY_EXISTS, '');
          else if (error.message == API_ERROR.USER_ALREADY_EXISTS) {
            this.toaster.error(API_ERROR.USER_ALREADY_EXISTS, '');
          }
          else this.toaster.error(API_ERROR.SOMETHING_WENT_WRONG, '');
        }
        this.loading = false;
      }
    );
  }

  clickBuyNow() {
    this.isBuyNow = "block";
  }

  /*toggle eye icon for password*/
  toggelEyeButtonPassword() {
    if (this.f.password.value)
      this.passwordEye = !this.passwordEye;
  }

  /*toggle eye icon for confirmPassword*/
  toggelEyeButtonConPassword() {
    if (this.f.confirmPassword.value)
      this.confirmPasswordEye = !this.confirmPasswordEye;
  }
  
  toggelPrivacyPolicyTC() {
    this.signupForm.value['privacyPolicyTC'] = !this.signupForm.value['privacyPolicyTC'];
  }

  toggleContactNumber() {
    this.signupForm.value['enableSms'] = !this.signupForm.value['enableSms'];
    this.showContactNumber = !this.showContactNumber;
    if(this.showContactNumber){
      this.signupForm.controls['contactNumber'].setValidators(Validators.required);
      this.signupForm.controls['phoneCode'].setValidators(Validators.required);
    } else{
      this.signupForm.get('contactNumber').clearValidators();
      this.signupForm.get('contactNumber').updateValueAndValidity();
      this.signupForm.get('phoneCode').clearValidators();
      this.signupForm.get('phoneCode').updateValueAndValidity();
    }
  }

}
