export const access = {
    permissions: {
        dashboard: {
            name: "Dashboard",
            action: false,
            create: false,
            view: true,
            update: false,
            delete: false
        },
        shipments: {
            name: "Shipments",
            action: false,
            create: false,
            view: false,
            update: false,
            delete: false
        },
        reports: {
            name: "Reports",
            action: false,
            create: false,
            view: false,
            update: false,
            delete: false
        },
        "End Point Management": {
            name: "End Point Management",
            action: false,
            create: false,
            view: false,
            update: false,
            delete: false
        },
        edgeManagement: {
            name: "Edge Management",
            action: false,
            create: false,
            view: false,
            update: false,
            delete: false
        },
        "Provisioning Queue": {
            name: "Provisioning Queue",
            action: false,
            create: false,
            view: false,
            update: false,
            delete: false
        },
        sensor: {
            name: "Sensors",
            create: false,
            view: false,
            update: false,
            delete: false,
            action: false,
            submenu: [{
                name: "",
                create: false,
                view: false,
                update: false,
                delete: false,
                action: false
            }]
        },
        "Smart IOT Rule Engine": {
            name: "Smart IOT Rule Engine",
            create: false,
            view: false,
            update: false,
            delete: false,
            action: false,
            submenu: [{
                name: "",
                create: false,
                view: false,
                update: false,
                delete: false,
                action: false
            }]
        },
        utility: {
            name: "Utility",
            create: false,
            view: false,
            update: false,
            delete: false,
            action: false,
            submenu: {
                "connectivity": {
                    name: "Connectivity",
                    create: false,
                    view: false,
                    update: false,
                    delete: false,
                    action: false
                },
                "timeclock": {
                    name: "Time & Clocks",
                    create: false,
                    view: false,
                    update: false,
                    delete: false,
                    action: false
                },
                "weather": {
                    name: "Weather",
                    create: false,
                    view: false,
                    update: false,
                    delete: false,
                    action: false
                },
                "dataStorage": {
                    name: "Data Storage",
                    create: false,
                    view: false,
                    update: false,
                    delete: false,
                    action: false
                },
                "node-map": {
                    name: "Node Maps",
                    create: false,
                    view: false,
                    update: false,
                    delete: false,
                    action: false
                },
                "terminal": {
                    name: "Terminals",
                    create: false,
                    view: false,
                    update: false,
                    delete: false,
                    action: false
                },
                "ledger": {
                    name: "Ledger",
                    create: false,
                    view: false,
                    update: false,
                    delete: false,
                    action: false,
                    ledgerSubMenu: {
                        "user": {
                            name: "User Chain",
                            create: false,
                            view: false,
                            update: false,
                            delete: false,
                            action: false
                        },
                        "device": {
                            name: " Identity Chain",
                            create: false,
                            view: false,
                            update: false,
                            delete: false,
                            action: false
                        },
                        "edge": {
                            name: "Edge Chain",
                            create: false,
                            view: false,
                            update: false,
                            delete: false,
                            action: false
                        },
                        "event": {
                            name: "Communication Chain",
                            create: false,
                            view: false,
                            update: false,
                            delete: false,
                            action: false
                        },
                        "smart-contract": {
                            name: "Smart Contract Chain",
                            create: false,
                            view: false,
                            update: false,
                            delete: false,
                            action: false
                        }
                    }
                },
                "logs": {
                    name: "Logs",
                    create: false,
                    view: false,
                    update: false,
                    delete: false,
                    action: false,
                    logSubMenu: {
                        "systemLog": {
                            name: "System Log",
                            create: false,
                            view: false,
                            update: false,
                            delete: false,
                            action: false
                        },
                        "eventLog": {
                            name: "Event Log",
                            create: false,
                            view: false,
                            update: false,
                            delete: false,
                            action: false
                        }
                    }
                },
                "alertsNotifications": {
                    name: "Alerts & Notifications",
                    action: false,
                    create: true,
                    view: false,
                    update: false,
                    delete: false,
                }
            }
        },
        "access-control": {
            name: "Access Control",
            action: false,
            create: false,
            view: false,
            update: false,
            delete: false,
            submenu: {
                "user-access-policies": {
                    name: "User Access Policies",
                    action: false,
                    create: false,
                    view: false,
                    update: false,
                    delete: false
                },
                "user-management": {
                    name: "User Management",
                    action: false,
                    create: false,
                    view: false,
                    update: false,
                    delete: false
                }
            }
        },
        settings: {
            name: "System Settings",
            create: false,
            view: false,
            update: false,
            delete: false,
            action: false,
            submenu: {
                "update": {
                    name: "Update",
                    create: false,
                    view: false,
                    update: false,
                    delete: false,
                    action: false
                },
                "provisioningSettings": {
                    name: "Provisioning Settings",
                    create: false,
                    view: false,
                    update: false,
                    delete: false,
                    action: false
                },
                "alertsNotifications": {
                    name: "Alerts & Notifications",
                    action: false,
                    create: false,
                    view: false,
                    update: false,
                    delete: false,
                    submenu: {
                        "campus": {
                            name: "Campus",
                            action: false,
                            create: false,
                            view: false,
                            update: false,
                            delete: false
                        },
                        "building": {
                            name: "Building",
                            action: false,
                            create: false,
                            view: false,
                            update: false,
                            delete: false
                        },
                        "floor": {
                            name: "Floor",
                            action: false,
                            create: false,
                            view: false,
                            update: false,
                            delete: false
                        },
                        "zones": {
                            name: "Zones",
                            action: false,
                            create: false,
                            view: false,
                            update: false,
                            delete: false
                        },
                        "maps": {
                            name: "Maps",
                            action: false,
                            create: false,
                            view: false,
                            update: false,
                            delete: false
                        }
                    }
                },
                "resetSettings": {
                    name: "Reset Settings",
                    create: false,
                    view: false,
                    update: false,
                    delete: false,
                    action: false
                }
            }
        }
    }
}