<div class="tab-pane container-fluid active" id="event_log_table">
    <ng-scrollbar #scrollable track="horizontal" visibility="always" class="tabContent-scrollbar text-nowrap">
      <p-table [value]="eventData" [rows]="10"
        [paginator]="false" [loading]="!eventData" [resizableColumns]="true" columnResizeMode="expand">
        <ng-template pTemplate="header">
          <!-- <tr>
            <th>Source Device UUID</th>
            <th>Sensor Type</th>
            <th>Events</th>
            <th>Value</th>
            <th>Event Time</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row>
          <tr>
            <td class="align-middle">{{row.deviceUUID}}</td>
            <td class="align-middle">{{row.sensorType}}</td>
            <td class="align-middle text-truncate" [title]="row.eventMessage">{{row.eventMessage}}</td>
            <td class="align-middle text-truncate" [title]="row.payload">{{row.payload}}</td>
            <td class="align-middle text-truncate" [title]="row.time">{{row.time}}</td>
          </tr> -->
          <tr>
            <th pResizableColumn>Shipment Name</th>
            <th pResizableColumn>{{constantVariables.ENDPOINT.LABEL2}} UUID</th>
            <th pResizableColumn>BluTag</th>
            <th pResizableColumn>BluTag Temperature</th>
            <th pResizableColumn>Temperature</th>
            <th pResizableColumn>Humidity</th>
            <th pResizableColumn>Battery</th>
            <th pResizableColumn>Signal</th>
            <th pResizableColumn>Location</th>
            <th pResizableColumn>Event Time</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row>
          <tr>
            <td class="align-middle text-truncate">{{row.shipmentName}}</td>
            <td class="align-middle">{{row.deviceUUID}}</td>
            <td class="align-middle">{{row.blueTag}}</td>
            <!-- <td class="align-middle text-truncate" [title]="row.payload">{{row.payload}}</td> -->
            <td class="align-middle text-truncate">{{row.temperature | number : '1.2-2'}}</td>
            <td class="align-middle text-truncate">{{row.temp | number : '1.2-2'}}</td>
            <td class="align-middle text-truncate">{{row.humidity | number : '1.2-2'}}</td>
            <td class="align-middle">{{row.battery}}</td>
            <td class="align-middle">{{row.signal}}</td>
            <td class="align-middle" [title]="row?.formattedAddress || row?.location || ''">{{row.location}}</td>
            <td class="align-middle text-truncate" [title]="row.time">{{row.time | date:'dd MMM y, h:mm a'}}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="10" class="text-center">No events found.</td>
          </tr>
        </ng-template>
      </p-table>
      <p-paginator
          [pageLinkSize]="10"
          [rows]="1"
          [totalRecords]="totalEvents"
          (onPageChange)="getMoreEvents($event)"
        >
        </p-paginator>
    </ng-scrollbar>
</div>
