import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { CommonService } from '../services/common.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private route: Router, private authService: AuthService,
    private activeRoute: ActivatedRoute,
    private commonService: CommonService,) { }

  user: any;

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const token = next.queryParamMap.get("token");
    this.user = this.commonService.getCurrentUserData();
    if (token) {
      const decrypt_token = this.commonService.getDecryptedToken(token);
      const user = {
        emailId: decrypt_token.officialEmail,
        id: decrypt_token.id
      }
      // this.commonService.setAccessToken(token);
      this.authService.getBlUser(user).subscribe(res => {
        const { newuser } = res['data'];
        this.commonService.setAccessToken(newuser.user.token);
        this.commonService.setUserData(newuser);
        this.getUserDataAndNavigate();
        return true;
      }, error => {

      }, () => {
        return true;
      })
    } else {
      // this.route.navigate(['/dashboard']);
      // return true;
      // this.checkNavigation();
      const accessToken = this.commonService.getAccessToken();
      if (!accessToken) {
        this.route.navigate(['login']);
        return false;
      } else {
        const newurl = state.url.split("/");
        const { user } = this.commonService.getCurrentUserData();

        if(newurl[1].includes('user-signup')){
          if(user?.accesslevel.permissions['access-control'] && 
          user?.accesslevel.permissions['access-control'].submenu['user-management'].create)
            return true;
          else return false;
        } else {
          switch (newurl[1]) {
            case 'master-data': if (user.accesslevel.permissions['Master Data'] && user.accesslevel.permissions['Master Data'].view)
              return true;
            else
              return false;
            case 'dashboard': if (user.accesslevel.permissions['dashboard'] && user.accesslevel.permissions['dashboard'].view)
              return true;
            else
              return false;
            case 'end-point-management': if (user.accesslevel.permissions['End Point Management'] && user.accesslevel.permissions['End Point Management'].view)
              return true;
            else
              return false;
            case 'provisioning-queue': if (user.accesslevel.permissions['Provisioning Queue'] && user.accesslevel.permissions['Provisioning Queue'].view)
              return true;
            else
              return false;
            case 'access-control': if (user.accesslevel.permissions['access-control'] && user.accesslevel.permissions['access-control'].view)
              return true;
            else
              return false;
            case 'dashboard': if (user.accesslevel.permissions['dashboard'] && user.accesslevel.permissions['dashboard'].view)
              return true;
            else
              return false;
            case 'edge-management': if (user.accesslevel.permissions['edgeManagement'] && user.accesslevel.permissions['edgeManagement'].view)
              return true;
            else
              return false;
            case 'reports': if (user.accesslevel.permissions['reports'] && user.accesslevel.permissions['reports'].view)
              return true;
            else
              return false;
            case 'shipments': if (user.accesslevel.permissions['shipments'] && user.accesslevel.permissions['shipments'].view)
              return true;
            case 'reportShipment': if (user.accesslevel.permissions['shipments'] && user.accesslevel.permissions['shipments'].view)
              return true;
            else
              return false;
            case 'utility': if (user.accesslevel.permissions['utility'] && user.accesslevel.permissions['utility'].view) {
              if (newurl[2] == "time-&-clocks" && user.accesslevel.permissions['utility'].submenu.timeclock.view == true) {
                return true;
              } else if (newurl[2] == "weather" && user.accesslevel.permissions['utility'].submenu.weather.view == true) {
                return true;
              } else if (newurl[2] == "node-maps" && user.accesslevel.permissions['utility'].submenu['node-map'].view == true) {
                return true;
              } else if (newurl[2] == "alerts-&-notifications" && user.accesslevel.permissions['utility'].submenu['alertsNotifications'].view == true) {
                return true;
              }
            }
              else
                return false;
          }
        }
      }
    }

  }

  checkNavigation() {
    var windowUrl = window.location.origin
    //@ts-ignore
    if (document.URL != windowUrl + '/#/') {
      const newurl = document.URL.replace(windowUrl + '/#/', "").split("/");
      const { user } = this.commonService.getCurrentUserData();
      const { accesslevel } = user;
      switch (newurl.length) {
        case 1:
          // @ts-ignore
          if (newurl[0] == "reports"  && (accesslevel.permissions['reports'].view == false)) {
            setTimeout(() => {
              this.getUserDataAndNavigate();
            }, 100);
          } else if(newurl[0] == "edge-management" && accesslevel.permissions.edgeManagement.view == false){
            setTimeout(() => {
              this.getUserDataAndNavigate();
            }, 100);
          } else if(newurl[0] == "provisioning-queue" && accesslevel.permissions["Provisioning Queue"].view == false){
            setTimeout(() => {
              this.getUserDataAndNavigate();
            }, 100);
          } else if(newurl[0] == "end-point-management" && accesslevel.permissions["End Point Management"].view == false){
            setTimeout(() => {
              this.getUserDataAndNavigate();
            }, 100);
          }
          break;
        case 2:
          // @ts-ignore
          if (newurl[0] == "access-control" && accesslevel.permissions['access-control'].view == false) {
            setTimeout(() => {
              this.getUserDataAndNavigate();
            }, 100);
          } else if (newurl[0] == "utility" && newurl[1] == "time-&-clocks" && accesslevel.permissions['utility'].submenu.timeclock.view == false) {
            setTimeout(() => {
              this.getUserDataAndNavigate();
            }, 100);
          } else if (newurl[0] == "utility" && newurl[1] == "weather" && accesslevel.permissions['utility'].submenu.weather.view == false) {
            setTimeout(() => {
              this.getUserDataAndNavigate();
            }, 100);
          } else if (newurl[0] == "utility" && newurl[1] == "node-maps" && accesslevel.permissions['utility'].submenu['node-map'].view == false) {
            setTimeout(() => {
              this.getUserDataAndNavigate();
            }, 100);
          } else if (newurl[0] == "utility" && newurl[1] == "alerts-&-notifications" && accesslevel.permissions['utility'].submenu['alertsNotifications'].view == false) {
            setTimeout(() => {
              this.getUserDataAndNavigate();
            }, 100);
          }
          break;
        case 3:
          // @ts-ignore
          if (newurl[1] == "logs" && newurl[2] == "system-logs" && accesslevel.permissions['utility'].submenu.logs.logSubMenu.systemLog.view == false) {
            setTimeout(() => {
              this.getUserDataAndNavigate();
            }, 100);
          }
          else if (newurl[1] == "logs" && newurl[2] == "event-logs" && accesslevel.permissions['utility'].submenu.logs['logSubMenu'].eventLog.view == false) {
            setTimeout(() => {
              this.getUserDataAndNavigate();
            }, 100);
          } else if (newurl[1] == "ledger" && newurl[2] == "user-chain" && accesslevel.permissions['utility'].submenu.ledger['ledgerSubMenu'].user.view == false) {
            setTimeout(() => {
              this.getUserDataAndNavigate();
            }, 100);
          } else if (newurl[1] == "ledger" && newurl[2] == "identity-chain" && accesslevel.permissions['utility'].submenu.ledger['ledgerSubMenu'].device.view == false) {
            setTimeout(() => {
              this.getUserDataAndNavigate();
            }, 100);
          } else if (newurl[1] == "ledger" && newurl[2] == "communication-chain" && accesslevel.permissions['utility'].submenu.ledger['ledgerSubMenu'].event.view == false) {
            setTimeout(() => {
              this.getUserDataAndNavigate();
            }, 100);
          } else if (newurl[1] == "ledger" && newurl[2] == "edge-chain" && accesslevel.permissions['utility'].submenu.ledger['ledgerSubMenu'].edge.view == false) {
            setTimeout(() => {
              this.getUserDataAndNavigate();
            }, 100);
          } else if(newurl[1] == "ledger" && newurl[2] == "smart-contract-chain" && accesslevel.permissions['utility'].submenu.ledger['ledgerSubMenu']['smart-contract'].view == false){
            setTimeout(() => {
              this.getUserDataAndNavigate();
            }, 100);
          }
          break;
      }
    }

  }

  getUserDataAndNavigate() {
    const { user } = this.commonService.getCurrentUserData();
    this.route.navigate([user && user?.userLevel === "SUPERADMINUSER" ? '/master-data' : '/dashboard']);
    return user;
  }

}
