import { IPermission } from './../../../../shared/models/side-nav.model';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AccessService } from '../../../access-control/services/access.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { API_ERROR } from 'src/app/core/constants/global-error.constants';
import { CommonService } from 'src/app/core/services/common.service';
import { MustMatchValidators } from 'src/app/shared/utilites/must-match.validators';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/auth/auth.service';
import {Md5} from 'ts-md5';
import { DashboardService } from '../../services/dashboard.service';


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  @Input() isOpenInModal = false;
  @Output() newItemEvent = new EventEmitter<boolean>();

  newUserForm: FormGroup;
  passwordEye: boolean;
  confirmPasswordEye: boolean;
  loader: boolean;
  userData: any;
  editFlag = false;
  isReadOnly: boolean;
  accessData: any;
  userSmartContractData: any;
  userId: any;
  deleteUserData: any;
  accessMenu: { [key: string]: IPermission };;
  activeClass = 'active';
  oldPasswordEye: boolean;
  oldpasswordCheck: boolean = false;

  constructor(private accessService: AccessService, private formBuilder: FormBuilder
    , private toastr: ToastrService, private commonService: CommonService, private dashboardService: DashboardService,private route:Router,private authService:AuthService,) { }

  ngOnInit(): void {
    this.getLoginUserDetails();
    this.getUserData();
    this.addNewUserForm();
    const { user } = this.commonService.getCurrentUserData();
    if (user) {
      const socketConnectionId = user.token + new Date().getTime();
      this.dashboardService.connectSocket(socketConnectionId);
      this.dashboardService.getNotificationData();
    }
  }

  getUserData() {
    this.loader = true;
    this.accessService.getUserManagement().subscribe(
      (response) => {
        this.loader = false;
        this.userData = response;
        this.userData = this.userData.data;

          this.getAccessLevels();
      },
      (error) => {
        this.loader = false;
        if (error?.message === API_ERROR.USER_LOGOUT) {
          this.commonService.logout(API_ERROR.USER_LOGOUT);
        }
        error;
      }
    );
  }


  /**
   * @description get all access level list
   */
  getAccessLevels() {
    this.accessService.getAccessLevel().subscribe(
        (response) => {
        this.accessData = response;
        // this.accessData = this.accessData.filter(levels => levels.levelname != LEVELS.ADMIN);
        },
        (error) => {
          if (error?.message === API_ERROR.USER_LOGOUT) {
            this.commonService.logout(API_ERROR.USER_LOGOUT);
          }
            error
        }
    )
  }

    /**
   * @description create new user form with validation
   */
  addNewUserForm() {
    this.newUserForm = this.formBuilder.group({
      password: ['', [Validators.required,Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,30}$/)]],
      confirmPassword : ['', [Validators.required,Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,30}$/)]],
      oldPassword : ['', [Validators.required,Validators.pattern('^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$')]]
    }, {
      validators:MustMatchValidators('password','confirmPassword')

    })
  }


  /**
   * @description get login user data
   */
  getLoginUserDetails() {
    const { user } = this.commonService.getCurrentUserData();
    const { accesslevel } = user;
    this.userId = this.commonService.getCurrentUserData();
    if (accesslevel?.permissions && accesslevel.permissions['access-control']) {
      this.accessMenu = accesslevel.permissions['access-control'].submenu;
    }
  }



    /*convinience getter to easy access form fields*/
    get f() {
      return this.newUserForm.controls;
    }

    /*toggle eye icon for password*/
    toggelEyeButtonPassword() {
      if (this.f.password.value)
        this.passwordEye = !this.passwordEye;
    }

    /*toggle eye icon for confirmPassword*/
    toggelEyeButtonConPassword() {
      if (this.f.confirmPassword.value)
        this.confirmPasswordEye = !this.confirmPasswordEye;
    }

    toggelEyeOldButtonPassword(){
      if (this.f.oldPassword.value)
      this.oldPasswordEye = !this.oldPasswordEye;
    }

  ResetPassword() {
    if (this.f.oldPassword.value === this.f.password.value || this.f.oldPassword.value === this.f.confirmPassword.value) {
      this.toastr.error(API_ERROR.PASSWORD_MISMATCH, '');
      return false;
    }
    const data = {
      id: this.userId.user.id,
      password: this.f.confirmPassword.value,
      officialEmail: this.userId.user.officialEmail
    }
    if (this.f.oldPassword.value) {
        this.authService.resetPassword(data).subscribe(
          (res) => {
            sessionStorage.removeItem('token');
            sessionStorage.removeItem('data');
            sessionStorage.removeItem('user');
            if (this.isOpenInModal) {
              this.newItemEvent.emit(true);
            }
            this.route.navigate(['/login']);
            this.toastr.success('Please login again.', 'Password changed successfully.');
          },
          (error) => {
            // this.loading = false;
            if(error?.message === API_ERROR.PASSWORD_REUSED){
              this.toastr.error('Please try another one.', API_ERROR.PASSWORD_REUSED);
            } else if (error?.message === API_ERROR.USER_LOGOUT) {
              this.commonService.logout(API_ERROR.USER_LOGOUT);
            } else if (error.error.message) {
              this.toastr.error(API_ERROR.PASSWORD_UPDATE_FAIL, '');
            } else {
              this.toastr.error(API_ERROR.SOMETHING_WENT_WRONG, '');
            }
          }
        )
    }
  }

  checkPassword() {
    const md5 = new Md5();
    let encryptedPassword = md5.appendStr(this.f.oldPassword.value).end();
    if (encryptedPassword !== this.userId.user.password) {
      this.oldpasswordCheck = true ;
      $('#Old_password').addClass('ng-invalid');
    } else{
      this.oldpasswordCheck = false;
      $('#Old_password').removeClass('ng-invalid');
    }

  }

}
