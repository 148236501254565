import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  // {path:'dashboard',loadChildren:()=>import('../dashboard/dashboard.module').then(m => m.DashboardModule)},
  // {path:'access-control', loadChildren: () => import('../access-control/access-control.module').then(m => m.AccessControlModule) },
  // {path:'utility',loadChildren:()=>import('../utility/utility.module').then(m => m.UtilityModule)},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PrivateRoutingModule { }
