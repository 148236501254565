

<section class="content mx-3 mt-4" id="ledger_router">

    <!-- Default box -->
    <div class="card no-border">

        <div class="card-body p-0 tab-content" id="tab1content">
            <div class="row">
                <div class="col-md-2 bg-light p-0 pt-3 cus-rounded-left shadow">
                    <div class="row">
                        <div class="col-md-12">
                            <ul class="nav nav-pills flex-column cus-tab">
                                <li class="nav-item text-center border-0">
                                    <a class="nav-link text-capitalize " data-toggle="pill" routerLinkActive="active"
                                        routerLink="/utility/ledger/user-chain" href="javascript:void(0)">
                                        User Chain
                                    </a>
                                </li>
                                <li class="nav-item text-center border-0">
                                    <a class="nav-link text-capitalize" data-toggle="pill" routerLinkActive="active"
                                    routerLink="/utility/ledger/identity-chain" href="javascript:void(0)">
                                        Identity Chain
                                    </a>
                                </li>
                                <li class="nav-item text-center border-0">
                                    <a class="nav-link text-capitalize " data-toggle="pill" routerLinkActive="active"
                                    routerLink="/utility/ledger/communication-chain" href="javascript:void(0)">
                                        Communication Chain
                                    </a>
                                </li>
                                <!-- <li class="nav-item text-center border-0">
                                    <a class="nav-link text-capitalize" data-toggle="pill" routerLinkActive="active"
                                    routerLink="/utility/ledger/edge-chain" href="javascript:void(0)">
                                        Edge Chain
                                    </a>
                                </li> -->
                                <li class="nav-item text-center border-0">
                                    <a class="nav-link text-capitalize" data-toggle="pill" routerLinkActive="active"
                                    routerLink="/utility/ledger/smart-contract-chain" href="javascript:void(0)">
                                        Smart Contract
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-10 bg-white p-3 tab-content cus-rounded-right">
                    <router-outlet></router-outlet>
                </div>
            </div>
            <!-- /.card -->

        </div>
        <!-- /.card-body -->
    </div>
</section>