import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { ReportShipmentPdfComponent } from './components/report-shipment-pdf/report-shipment-pdf.component';


const routes: Routes = [
  { path: '', component: ReportShipmentPdfComponent , canActivate: [AuthGuard]},
  // { path: '/:sid', component: ReportShipmentPdfComponent , canActivate: [AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AlertsRoutingModule { }
