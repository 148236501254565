import { DialogModule } from 'primeng/dialog';
import { NgModule } from '@angular/core';
import { NgxPaginationModule } from 'ngx-pagination';
import {AngularMultiSelectModule } from 'angular2-multiselect-dropdown';

import { AccessControlRoutingModule } from './access-control-routing.module';
import { AccessControlComponent } from './components/access-control/access-control.component';
import { ManagementComponent } from './components/management/management.component';
import { PoliciesComponent } from './components/policies/policies.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { AddNewUserComponent } from './components/add-new-user/add-new-user.component';
import { TreeviewModule } from 'ngx-treeview';
import { AddNewAccessLevelComponent } from './components/add-new-access-level/add-new-access-level.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import * as table from 'primeng/table';
import { TableModule } from 'ngx-easy-table';
import { ListboxModule } from 'primeng/listbox';


@NgModule({
  declarations: [AccessControlComponent, ManagementComponent, PoliciesComponent, AddNewUserComponent, AddNewAccessLevelComponent],
  imports: [
    SharedModule,
    AngularMultiSelectModule,
    AccessControlRoutingModule,
    NgxPaginationModule,
    NgScrollbarModule,
    TreeviewModule.forRoot(),
    NgxSpinnerModule,
    table.TableModule,
    TableModule,
    DialogModule,
    ListboxModule
  ]
})
export class AccessControlModule { }
