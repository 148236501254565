import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { CommonService } from 'src/app/core/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { NgScrollbar } from 'ngx-scrollbar';
import { API_SUCCESS } from 'src/app/core/constants/global-success.constants';
import { GRAPH_LIMIT, LEVELS, SHOWING_SHIPMENTS } from 'src/app/core/constants/level.constants';
import { DashboardService } from '../../services/dashboard.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import * as $ from 'jquery';
import '../../../../../assets/js/jquery.sparkline.min';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { EndPointService } from 'src/app/modules/end-point-management/services/end-point.service';
import { of, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, first, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ConstantVariables, sparklineChartOption } from 'src/constants/constants';
import { CommunicationChainService } from 'src/app/modules/utility/services/communication-chain.service';
import { ShipmentsService } from 'src/app/modules/shipments/services/shipments.service';
import { API_ERROR } from 'src/app/core/constants/global-error.constants';
import { Paginator } from 'primeng/paginator';
import { Table } from 'primeng/table';
import * as moment from 'moment';
import { calculateBatteryPercentage } from 'src/app/shared/utilites/battery-percentage-calculator.util';
import { FormControl } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  @ViewChild(NgScrollbar, { static: true }) scrollbarRef: NgScrollbar;
  @ViewChild('welcomeModal', { static: true }) welcomeModal: TemplateRef<any>;
  @ViewChild('resetPasswordModal', { static: true }) resetPasswordModal: TemplateRef<any>;
  @ViewChild('paginator') paginator: Paginator;
  @ViewChild('ptable') ptable: Table;
  // @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  // @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow

  private map: any;
  mapType = 'roadmap'; // roadmap | satellite | hybrid | terrain;
  public mapView = 'Satellite View'; // roadmap | satellite | hybrid | terrain;
  zoom = 2;
  public iseditBlocklockdev: boolean;
  public tabName = 'Overview';
  public chartDataOptions: any;
  public deviceDataInfo: any[] = [];
  public bluTags: string[] = [];
  public locationData = [];
  public humidity = [];
  public temperature = [];
  public alerts = [];

  public calculateBatteryPercentage = calculateBatteryPercentage;

  resetAccrodian = false;
  hData = [];
  cluster;
  // modalOptions: NgbModalOptions;
  // optionedge: any;
  // optionendpt: any;
  // optionevents: any;
  // optiondevices: any;
  isSelectedShipment: boolean = false;
  isSelectedShipmentData: boolean = true;
  isSelectedShipmentName: any = [];
  totalEvents: number = 0;
  // optionsensorEvents: any;
  todayDate = new Date();
  fullName: string;
  url: string;
  // Sparkline data for Active/Inactive devices
  // public actInactDevices = [56, 13];
  // endpointSparklineArr: any;
  // provisioningSparklineArr: any;
  // eventsSparklineArr: any;
  batteryCounts = {
    high: 0,
    medium: 0,
    low: 0
  };
  tempAlertCounts = {
    high: 0,
    low: 0
  };
  loadAlerts = true;
  humidityAlertCounts = {
    high: 0,
    low: 0
  };
  geofenceAlertCount = 0;
  // activeTrackers: any;
  // activeTrackerEvents: any;

  // Donut chart for Blocklock count
  autoResize = true;

  // totalBlocklock: any;
  // totalProvisons: any;
  totalDevices: any;
  activeDevices: any;
  connectedDevices: number = 0;
  readyDevices: number = 0;
  connectedShipment: number = 0; 
  readyShipment: number = 0;
  eventCount: any;
  // deviceData: any;
  public deviceBlockLockData: any;
  public shipmentData: any;
  // userList: any;
  // info: any;
  // edgeActiveCount: any;
  // filterTotalEvents: string;
  // edgePercentage: any;
  // filterTotalDevices: string;
  polygon: any;
  // Manage widgets
  // get devicesFilter$(): Observable<boolean> {
  //   return this.dashboardService.devicesFilter$;
  // }
  // get sensorsFilter$(): Observable<boolean> {
  //   return this.dashboardService.sensorsFilter$;
  // }
  // get pqueueFilter$(): Observable<boolean> {
  //   return this.dashboardService.pqueueFilter$;
  // }
  // get teventsFilter$(): Observable<boolean> {
  //   return this.dashboardService.teventsFilter$;
  // }
  // get avgcountsFilter$(): Observable<boolean> {
  //   return this.dashboardService.avgcountsFilter$;
  // }
  // get nodemapsFilter$(): Observable<boolean> {
  //   return this.dashboardService.nodemapsFilter$;
  // }
  // get mapCardSection$(): Observable<boolean> {
  //   return this.dashboardService.mapCardSection$;
  // }
  // get eventLogCardSection$(): Observable<boolean> {
  //   return this.dashboardService.eventLogCardSection$;
  // }
  public selectedDevice: any;
  // endPointsList: Object;
  shipmentList: any;
  search = new FormControl('');
  eventData = [];
  isEventDataLoading: boolean = false;
  page = 1;
  config: any;
  // shipmentGraphList: any = [];
  // shipmentFilterList: any = [];
  changeDataOnLoad: boolean = false;
  // currentFilter: string = 'total';
  totalShipments: number = 0;
  movableShipments: number = 0;
  fixedShipments: number = 0;
  completedShipments: number = 0;
  unassignShipments: number = 0;
  // selectedEndpoint: any;
  // selectedSensor: any = ''; //'Temp&Hum';
  // filterAvgSensorEvents: string;
  // selectedSensorEvt: any = ''; //'Temp&Hum';
  // filterBySensorEvents: string;
  // options: Observable<any>;
  shipmentsWithEvents = [];
  originalShipmentsWithEvents: any = [];
  isShipmentsWithEvents: boolean;
  // Socket filters for graphs
  // filterTotalEventsDuration = 'hourly';
  // filterTotalDevicesDuration = 'hourly';
  // filterAverageSensorEventsDuration = 'hourly';
  // filterAverageSensorEventsSensorName = '';
  // filterSensorEventsDuration = 'hourly';
  // filterSensorEventsSensorName = '';
  // edgeChainCountSubscription: Subscription;
  // activeEdgeCountSubscription: Subscription;
  endPointCountSubscription: Subscription;
  // eventCountSubscription: Subscription;
  // getEventsOfSelectedShipmentSubscription: Subscription;
  // generateAlertsSubscription: Subscription;
  // provisionEndPointCountSubscription: Subscription;
  // eventByDurationSubscription: Subscription;
  // deviceByDurationSubscription: Subscription;
  // averageSensorDataSubscription: Subscription;
  // reciveSensor: Subscription;
  // reciveSensorSubscription: Subscription;
  // reciveAvgEventDataSensorSubscription: Subscription;
  // reciveEventDataSensorSubscription: Subscription;
  // sensorEventsDataSubscription: Subscription;
  selectedBluTagData: any = {
    tag: '',
    filterTagData: []
  };

  showBluTagCol: boolean = false;

  socketConnectionId: string;
  popupFlag: boolean;
  // edgeInactiveCount: any;
  // sensorList: any = '';
  // sensorAvgEventDataList: any = '';
  // sensorEventDataList: any = '';
  // edgeList: any = '';
  // provisionEdgeList: any = '';
  // edgeEndPointListAvgSensor: any = '';
  // edgeEndPointListSensor: any = '';
  // selectedEdgeAvgSensor: any = '';
  // selectedEdgeSensor: any = '';
  // selectedEndPointAvgSensor: any = '';
  // selectedEndPointSensor: any = '';
  allEndPoints: any = '';
  isMapLoading: boolean;
  userInfo: any;
  // selectedTab = 'endpoint-tab';
  // endPointCount: number = 0;
  // sensorAvgDataList: any;
  // receiveSensorGraphData: any;
  showLoadShipment = false;
  type: any;
  showLoadShipmentDetails = false;
  interval: any;

  mapData: any;
  smallMapData: any;
  noLocations = true;


  // public selectedShipment: any = '';
  // public selectedShipmentForSE: any = '';
  // public selectedShipmentObj: any = '';
  // public selectedTracker: any = '';
  // public selectedTrackerForSE: any = '';
  // public selectedTrackerObj: any = '';
  // public selectedTrackerObjForSE: any = '';
  // public selectedSensorObj: any = '';
  // public selectedSensorForSE: any = '';
  // public selectedSensorObjForSE: any = '';
  // public shipmentList: any = [];
  // public trackerList: any = [];
  // public trackerListForSE: any = [];
  // public sensorsList: any = [];
  // public sensorsListForSE: any = [];

  public eventLogData = undefined;
  public eventLogCard = true;
  public eventInterVal = null;
  public refreshEventLog = false;

  public loadDetailGraph = false;
  public noGraphData = false;
  public chartData = {
    backgroundColor: '',
    grid: {
      top: 20,
      left: '8%',
      right: '4%',
      bottom: '8%',
      width: 'auto',
      height: 'auto',
      containLabel: true,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      },
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: [],
      axisLabel: {
        show: true,
        fontSize: 10,
        color: '#E5E5E5'
      },
      name: 'Date',
      nameLocation: 'middle',
      nameGap: 26,
      nameTextStyle: {
        color: '#E5E5E5',
        fontSize: 11,
        fontWeight: 'normal',
      },
      axisTick: {
        show: true
      },
      lineStyle: {
        color: '#E5E5E5'
      },
      axisLine: {
        show: true,
      },
    },
    yAxis: {
      type: 'value',
      min: GRAPH_LIMIT.MIN,
      splitNumber: 5,
      axisLine: {
        show: true
      },
      minInterval: 1,
      lineStyle: {
        color: '#E5E5E5'
      },
      axisTick: { show: false },
      axisLabel: {
        show: true,
        fontSize: 9,
        color: '#E5E5E5'
      },
      nameLocation: 'middle',
      nameGap: 30,
      nameTextStyle: {
        color: '#E5E5E5',
        fontSize: 11,
        fontWeight: 'normal',
      },
    },
    series: [{
      type: 'line',
      data: [],
      smooth: true,
      areaStyle: {
        color: '#F1594E'

      }
    }]
  };

  chartOptions = sparklineChartOption;

  private modalRef: NgbModalRef;
  private _sub: Subscription = new Subscription();

  constructor(
    public constantVariables: ConstantVariables,
    private toaster: ToastrService,
    private router: Router,
    private dashboardService: DashboardService,
    private authService: AuthService,
    private commonService: CommonService,
    private modalService: NgbModal,
    private endPointService: EndPointService,
    private communicationChainService: CommunicationChainService,
    private toastr: ToastrService,
    private shipmentService: ShipmentsService,
   
  ) {
    // this.modalOptions = {
    //   backdrop: 'static',
    //   backdropClass: 'customBackdrop'
    // };
  }

  ngOnInit(): void {
   
    this.isMapLoading = true;
    const { user } = this.commonService.getCurrentUserData();
    if (user) {
      this.userInfo = user;
      this.socketConnectionId = this.userInfo.id;
      // if (user?.passwordUpdatedAt) {
      //   const differenceInTime = new Date().getTime() - (user.passwordUpdatedAt * 1000);
      //   const differenceInDays = differenceInTime / (1000 * 3600 * 24);
      //   if (differenceInDays > 90) { this.open(this.resetPasswordModal, 'reset-password'); }
      // } else {
      //   // this.open(this.resetPasswordModal, 'reset-password');
      // }
      
      // this.socketConnectionId = this.userInfo.token + new Date().getTime();
      this.dashboardService.connectSocket(this.socketConnectionId);
      // this.filterTotalDevices = 'hourly';
      // this.filterAvgSensorEvents = 'hourly';
      // this.filterTotalEvents = 'hourly';
      // this.filterBySensorEvents = 'hourly';
      // this.filterTotalEventsDuration = 'hourly';
      // this.filterTotalDevicesDuration = 'hourly';
      // this.filterAverageSensorEventsDuration = 'hourly';
      // this.filterAverageSensorEventsSensorName = '';
      // this.filterSensorEventsDuration = 'hourly';
      // this.filterSensorEventsSensorName = '';

      this.displayInviteUserPopup();

      this._sub.add(this.dashboardService.openPopup$.subscribe(value => {
        this.popupFlag = value;
        this.open(this.welcomeModal);
      }));

      // this.filterTotalEvents = 'hourly'
      // this.filterTotalEventsGraph('hourly');
      // this.filterTotalDevices = 'hourly';
      // this.filterTotalDevicesGraph('hourly');
      // this.filterAverageSensorEvents('hourly', this.selectedSensor);
      // this.filterSensorEvents('hourly', this.selectedSensorEvt);

      // this.getAllEndPoints();
      // this.eventInterVal = setInterval(() => {
      //   this.getAllEventList();
      // }, 120000);
      this.getShipmentList();
      this.alertCount();

      this.changeDataOnLoad = true;

      //Bar Graph - No. of Devices
      const xAxisData = [];
      const data1 = [];
      const data2 = [];
      for (let i = 0; i < 24; i++) {
        xAxisData.push(i + ':00');
        data1.push((Math.sin(i / 5) * (i / 6) + i / 6) * 15);
        data2.push((Math.cos(i / 5) * (i / 6) + i / 6) * 15);
      }
      //Socket for new events
      this.getNewEvent();
      // setTimeout(() => {
      //   this.dashboardService.getLatestEvent().subscribe((data) => { });
      //   console.log("called!!");
      // }, 30000);

      //Get all edge list
      // this.getAllEdgeList();
      //Get shipment list
      this.getAllDashboardData();
      this.getSocketEvents();
    }
    
    this.search.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(800)
    ).subscribe((val) => {
      if (this.originalShipmentsWithEvents && this.originalShipmentsWithEvents.length && val) {
        this.shipmentsWithEvents = this.originalShipmentsWithEvents.filter(
          (shipment) => shipment.shipmentName.toLowerCase().match(val.toLowerCase()));
      } 
      if(val === '')
        this.reset();      
    });
  }

  reset() {
    this.search.reset();
    this.shipmentsWithEvents = this.originalShipmentsWithEvents;
  }

  ngOnDestroy() {
    this.dashboardService.disconnectSocket();
    this.endPointCountSubscription.unsubscribe();
    // this.edgeChainCountSubscription.unsubscribe();
    // this.getEventsOfSelectedShipmentSubscription.unsubscribe();
    // this.activeEdgeCountSubscription.unsubscribe();
    // this.provisionEndPointCountSubscription.unsubscribe();
    // this.eventCountSubscription.unsubscribe();
    // this.generateAlertsSubscription.unsubscribe();
    // this.eventByDurationSubscription.unsubscribe();
    // this.deviceByDurationSubscription.unsubscribe();
    // this.averageSensorDataSubscription.unsubscribe();
    // this.sensorEventsDataSubscription.unsubscribe();
    // this.reciveSensorSubscription.unsubscribe();
    this._sub.unsubscribe();
    clearInterval(this.eventInterVal);
  }

  getAllDashboardData() {
    this.dashboardService.requestEndPointCount(this.userInfo);
    // console.log("cehck->>",this.selectedShipment,this.selectedTracker,this.selectedSensor)
    // this.plotTotalDevicesGraph()
    // let shipment = [], tracker = [], sensor = [];
    // if (this.selectedShipment)
    //   shipment.push(this.selectedShipment)
    // else {
    //   this.shipmentGraphList?.map((shipments) => {
    //     shipment.push(shipments.id)
    //   })
    // }
    // if (this.selectedTracker)
    //   tracker.push(this.selectedTrackerObj?.data)


    // if (this.selectedSensor)
    //   sensor.push(this.selectedSensorObj)

    // this.dashboardService.requestgenerateAlerts();

    // this.dashboardService.requestAvgEventDataSensorList(this.selectedSensorObj?.id, this.selectedSensorObj?.trackerId);
    // this.dashboardService.requestSensorGraphData(shipment, tracker, sensor, this.filterAvgSensorEvents)
    // this.dashboardService.requestEvnetDataSensorList(this.selectedEdgeSensor, this.selectedEndPointSensor);
    // this.dashboardService.requestEventByDuration(this.filterTotalEventsDuration);
    // this.dashboardService.requestDeviceByDuration(this.filterTotalDevicesDuration);


    // let shipmentSE = [], trackerSE = [], sensorSE = [];
    // if (this.selectedShipmentForSE)
    //   shipmentSE.push(this.selectedShipmentForSE)
    // else {
    //   this.shipmentGraphList?.map((shipments) => {
    //     shipmentSE.push(shipments.id)
    //   })
    // }
    // if (this.selectedTrackerObjForSE)
    //   trackerSE.push(this.selectedTrackerObjForSE?.data)

    // if (this.selectedSensorObjForSE)
    //   sensorSE.push(this.selectedSensorObjForSE)
    // this.averageSensorDataSubscription = this.dashboardService.averageSensorData().subscribe((data: any) => {
    //   if (this.socketConnectionId == data[0]) this.filterAverageSensorEventsSocket(data[1]);
    // });
    // this.dashboardService.requestSensorEventsData(this.filterSensorEventsDuration, sensorSE, trackerSE, shipmentSE);
    // this.dashboardService.requestSensorEventsData(this.filterSensorEventsDuration, this.filterSensorEventsSensorName, this.selectedEdgeSensor, this.selectedEndPointSensor);
    // this.dashboardService.requestSensorList();

    // this.setTimeOutSubscription = setTimeout(() => {
    //   this.getAllDashboardData();
    // }, 15000);
  }
  
  /* Get List of All Shipments */
  private getShipmentList() {
    this.isShipmentsWithEvents = true;
    this._sub.add(this.shipmentService.getShipmentsList('limit=500').subscribe(
      ((res) => {
        this.totalShipments = res.meta.totalItems;
        this.shipmentList = this.filterShipment(res);
        this.connectedShipment = this.shipmentList.length;
        const startedShipment = res.items.filter((shipment) => shipment.status === 'STARTED');
        this.readyShipment = startedShipment.length ? Math.abs(startedShipment.length - this.shipmentList.length) : 0;
        this.completedShipments = res.items.filter((shipment) => shipment.status !== 'STARTED' && shipment.status !== 'PAUSED').length;
        this.unassignShipments = res.items.filter((shipment) => shipment.status == 'PAUSED').length;
        if (this.shipmentList.length) {
          this.shipmentList.map((shipment) => {
            if (shipment?.trackers.length && shipment?.trackers[0]?.latestEvent) {
              shipment['events'] = [{
                ...shipment.trackers[0]?.latestEvent,
                time: new Date(shipment.trackers[0]?.latestEvent?.timeStamp * 1000),
                dateString: new Date(shipment.trackers[0]?.latestEvent?.timeStamp * 1000).toLocaleString(),
                shipmentId: shipment.id,
                trackerId: shipment.trackers[0].id,
                parsedData: shipment.trackers[0]?.latestEvent.payload ? this.commonService.parseEventPayload(shipment.trackers[0]?.latestEvent.payload) : null
              }]
              if (shipment['events'][0].parsedData) {
                shipment['latitude'] = shipment['events'][0].parsedData.latitude,
                  shipment['longitude'] = shipment['events'][0].parsedData.longitude
              }
            }
          })
          this.getTrackersWithEvents();
          this.getAllEventDataById();
        } else {
          this.isShipmentsWithEvents = false;
          this.mapData = [];
          setTimeout(() => {
            this.map = new google.maps.Map(document.getElementById('map'), {
              zoom: 3,
              center: new google.maps.LatLng(40.822265, -7.425957),
              mapTypeId: this.mapType,
              disableDefaultUI: true
            });
          }, 5);
        }
        // this.filterAverageSensorEvents();
      }),
      (error => {
        console.log('error', error);
        if (error?.message !== API_ERROR.USER_LOGOUT) {
          this.toastr.error(`${this.constantVariables.ENDPOINT.LABEL2} cannot be retrieved at this moment. Please try again.`, ' ', {
            timeOut: 3000
          });
        }
        this.isShipmentsWithEvents = false;
      })
    ));
    // this.dashboardService.getShipmentList().subscribe((responseData: any) => {
    //   this.shipmentList = responseData.items.filter((shipment) => shipment.status === 'STARTED' || shipment.status === 'COMPLETED');
    //   this.filterAverageSensorEvents()
    // })
  }

  /* Get Paginated Data for Event Log Table */
  getAllEventDataById() {
    this.isSelectedShipment = false;
    this.isSelectedShipmentName = [];
    this.isEventDataLoading = true;
    let shipmentIds = this.shipmentList.length ? this.shipmentList.map((shipment) => shipment.id) : [];
    this._sub.add(this.communicationChainService.getAllEventDataById(shipmentIds, true, 1)
      .subscribe((chain: any) => {
        this.totalEvents = chain.meta.totalItems;
        this.prepareEventData(chain.items);
      }, ((error) => {
        this.isEventDataLoading = false;
        console.log('error--------', error);
      })));
  }

  /* Pagination function for Event Log Table */
  getMoreEvents(event) {
    this.isEventDataLoading = true;
    this.page = event.page + 1;
    let shipmentIds;
    if (this.isSelectedShipment)
      shipmentIds = this.isSelectedShipmentName[0]
    else
      shipmentIds = this.shipmentList.map((shipment) => shipment.id)
    this._sub.add(this.communicationChainService.getAllEventDataById(shipmentIds, true, this.page)
      .subscribe((chain: any) => {
        this.prepareEventData(chain.items);
      }, ((error) => {
        this.isEventDataLoading = false;
        if (error?.message === API_ERROR.USER_LOGOUT) {
          this.commonService.logout(API_ERROR.USER_LOGOUT);
        } else {
          this.toastr.error(error.message, 'Error', {
            timeOut: 3000
          });
        }
      })));
  }

  /* Prepare Data for Event Log Table */
  prepareEventData(data) {
    this.showBluTagCol = false;
    
    let events = JSON.parse(JSON.stringify(data));
    
    // this.eventData.splice(0, 1);
    if (events && events.length > 0) {
      this.config = {
        itemsPerPage: 10,
        currentPage: 1,
        totalItems: events.length,
      }
    }
    try {
      for (let i = 0; i < events.length; i++) {
        const parsedData = this.commonService.parseEventPayload(events[i].payload);
        if (parsedData) {
          events[i] = { ...events[i], ...parsedData }
          events[i]['time'] = new Date(events[i].timeStamp * 1000);
          const shipment = this.shipmentList.find((s) => s.id === events[i].shipmentId)
          events[i]['shipmentName'] = shipment.shipmentName;
        } else {
          events.splice(i, 1);
        }
        if (events[i].sensors.length)
          this.showBluTagCol = true;
      }
    } catch (error) { }
    this.eventData = JSON.parse(JSON.stringify(events));
    this.isEventDataLoading = false;
    if (this.config && this.config.itemsPerPage && this.eventData.length > this.config.itemsPerPage && this.isSelectedShipmentName[1].toLowerCase() === 'fixed') {
      this.eventData.splice(this.config.itemsPerPage, this.eventData.length - this.config.itemsPerPage)
    }
    // this.dashboardService.connectSocket(this.socketConnectionId);
  }

  /* Socket for latest events */
  getNewEvent() {
    this._sub.add(this.dashboardService.latestEvent$.subscribe((data) => {
      // this._sub.add(this.dashboardService.requestLatestEvent().subscribe((data) => {
      // if (this.socketConnectionId == data[0]) {
      if (data.length) {
        let events = data[1];
        let eventData;
        /* Adding latest event to respective shipment */
        this.deviceBlockLockData?.map((shipment, i) => {
          if (shipment.id === events[0].shipmentId) {
            if (!this.deviceBlockLockData[i].events || !this.deviceBlockLockData[i].events.length) {
              this.shipmentsWithEvents.push(this.deviceBlockLockData[i]);
              this.originalShipmentsWithEvents.push(this.deviceBlockLockData[i]);
              this.deviceBlockLockData[i].events = events;
              if (this.deviceBlockLockData[i].shipmentType.toLowerCase() === "fixed")
                this.fixedShipments++;
              else if (this.deviceBlockLockData[i].shipmentType.toLowerCase() === "movable")
                this.movableShipments++;
            } else if (!this.selectedDevice && this.deviceBlockLockData[i].events[0].timeStamp < events[0].timeStamp) {
              this.deviceBlockLockData[i].events = events;
            }
          }
        });

        /* Get Tracker Data for Trackers Widget */
        this.dashboardService.requestEndPointCount(this.userInfo);
        // this.getAllEndPoints();

        /* Update Tracker Alerts */
        let shipment = this.deviceBlockLockData && this.deviceBlockLockData.find((shipment) => shipment.id === events[0].shipmentId)
        if (shipment && shipment.trackers && shipment.trackers.length && events[0].parsedData) {
          if (shipment.trackers[0].min && events[0].parsedData.temp && Number(events[0].parsedData.temp) < Number(shipment.trackers[0].min))
            this.tempAlertCounts.low++;
          if (shipment.trackers[0].max && events[0].parsedData.temp && Number(events[0].parsedData.temp) > Number(shipment.trackers[0].max))
            this.tempAlertCounts.high++;
          if (shipment.trackers[0].minH && events[0].parsedData.humidity && Number(events[0].parsedData.humidity) < Number(shipment.trackers[0].minH))
            this.humidityAlertCounts.low++;
          if (shipment.trackers[0].maxH && events[0].parsedData.humidity && Number(events[0].parsedData.humidity) > Number(shipment.trackers[0].maxH))
            this.humidityAlertCounts.high++;
        }
        // console.log('this.deviceBlockLockData', this.deviceBlockLockData)
        // this.batteryCount(this.deviceBlockLockData)

        /* Update Event Log Table */
        if (!this.isSelectedShipment && !this.isSelectedShipmentName?.length && this.shipmentList?.length && this.page === 1)
          this.getAllEventDataById();

        /* Update shipment data if the data is of Selected Shipment */
        for (let eventIndex = 0; eventIndex < events.length; eventIndex++) {
          let event = events[eventIndex];
          if (this.selectedDevice?.id === event.shipmentId && this.selectedDevice.events[0].timeStamp < event.timeStamp) {
            eventData = this.selectedDevice.events;
            this.selectedDevice.events.unshift(event);
            if (event['sensors']) {
              for (let sensor of event['sensors']) {
                if (!this.deviceDataInfo.find(tabing => tabing.tab === 'Tags')) {
                  this.deviceDataInfo = [...this.deviceDataInfo, { tab: 'Tags' }]
                }
                if (!this.bluTags.find(tag => tag === sensor.tag)) {
                  this.bluTags = [...this.bluTags, sensor.tag];
                }
              }
            }

            let sensorData = event.sensors && event.sensors.length ? event.sensors.find((sensor) => sensor.tag === this.selectedBluTagData.tag) : null;
            if (event?.temperature && sensorData)
              this.selectedBluTagData.filterTagData.unshift({
                time: event.time,
                blueTag: sensorData.tag,
                temperature: sensorData.temperature,
                alert: sensorData.alert
              });

            this.locationData.push({
              id: event?.id,
              sensors: event?.sensors ? event?.sensors : [],
              latitude: event?.parsedData?.latitude ? event?.parsedData?.latitude : null,
              longitude: event?.parsedData?.longitude ? event?.parsedData?.longitude : null,
              location: event?.parsedData?.location ? event?.parsedData?.location : null,
              battery: event?.parsedData?.battery ? event?.parsedData?.battery : null,
              signal: event?.parsedData?.signal ? event?.parsedData?.signal : null,
              time: event?.time ? event?.time : null,
              timeStamp: event?.timeStamp ? event?.timeStamp : 0
            });
          }
        }

        /* Update Event Log Table */
        if (this.isSelectedShipmentName.length && this.isSelectedShipmentName[0] === events[0].shipmentId && (this.isSelectedShipmentName[1].toLowerCase() === "movable") || (this.isSelectedShipmentName.length && this.isSelectedShipmentName[0] === events[0].shipmentId && this.isSelectedShipmentName[1].toLowerCase() === "fixed" && this.page === 1)) {
          this.isEventDataLoading = true;
          // eventData = eventData.concat(events);
          // this.isEventDataLoading = false;
          this.prepareEventData(eventData);
        }

        /* Update graphs for temperature and humidity and BluTags Data */
        if (this.selectedDevice?.id === events[0].shipmentId) {
          let alertData = {}, date = [], data = [];
          this.selectedDevice.events.forEach((event, i) => {
            if (this.selectedDevice?.events[i - 1]?.timeStamp != event?.timeStamp) {
              if (this.tabName === 'Humidity' && event?.parsedData?.humidity) {
                alertData = {
                  min: this.selectedDevice.trackers[0].minH ? Number(this.selectedDevice.trackers[0].minH) : null,
                  max: this.selectedDevice.trackers[0].maxH ? Number(this.selectedDevice.trackers[0].maxH) : null
                };
                date.push(this.convertedDate(event.time));
                data.push(event?.parsedData?.humidity);
              }
              if (this.tabName === 'Temperature' && event?.parsedData?.temp) {
                alertData = {
                  min: this.selectedDevice.trackers[0].min ? Number(this.selectedDevice.trackers[0].min) : null,
                  max: this.selectedDevice.trackers[0].max ? Number(this.selectedDevice.trackers[0].max) : null
                };
                date.push(this.convertedDate(event.time));
                data.push(event?.parsedData?.temp);
              }
            }
          });
          if (this.tabName === 'Tags') {
            this.dataFilterByTag(this.selectedBluTagData.tag)
          } else
            this.ChartDataGraph(date, data, 'Date', this.tabName, alertData);
        }
      }
    }));
  }

  /**
   * Description: show invite user popup to admin on firsttime login
   * @description show invite user popup to admin on firsttime login
   */
  displayInviteUserPopup() {
    const { user } = this.commonService.getCurrentUserData();
    const userObj = { officialEmail: user.officialEmail, id: user.id };
    this._sub.add(this.authService.getUserById(userObj).pipe(
      switchMap(user => {
        this.fullName = user.fullName;
        this.url = user.domainName;
        if (!user.firstTimeLogin && user.userLevel === LEVELS.SUPERADMIN) {
          // this.open(this.welcomeModal);
          return this.dashboardService.setFirstTimeLoginStatus(user);
        }
        return of(null);
      }),
      first(),
    ).subscribe());
  }

  manageEventLogVisibily(event: boolean) { this.eventLogCard = event; }

  /**
 * Description: get all devices list
 * @description get all devices list
 */
  getAllEndPoints() {
    // this._sub.add(this.endPointService.getAllEndPoint().subscribe(
    //   ((res) => {
    //     // this.endPointsList = res;
    //     this.allEndPoints = res;
    //     if (this.allEndPoints && !this.allEndPoints.length) {
    //       this.isMapLoading = false;
    //     }
    //   }),
    //   (error => {
    //     console.log('error', error);
    //     if (error?.message === API_ERROR.USER_LOGOUT) {
    //       this.commonService.logout(API_ERROR.USER_LOGOUT);
    //     } else {
    //       this.toastr.error(`${this.constantVariables.ENDPOINT.LABEL2} cannot be retrieved at this moment. Please try again.`, ' ', {
    //         timeOut: 3000
    //       });
    //     }
    //     this.isMapLoading = false;
    //   })
    // ));
    // this._sub.add(this.shipmentService.getShipmentsList('limit=500').subscribe(
    //   ((res) => {
    //     this.shipmentList = res.items;
    //     this.shipmentFilterList = res.items;
    //     this.shipmentGraphList = res.items
    //     console.log("res itemss",res.items)
    //     if (res.items && res.items.length) {
    //       this.getTrackersWithEvents();
    //     }

    //   }),
    //   (error => {
    //     console.log('error', error);
    //     this.toastr.error(`${this.constantVariables.ENDPOINT.LABEL2} cannot be retrieved at this moment. Please try again.`, ' ', {
    //       timeOut: 3000
    //     });

    //   })
    // ));
  }

  /* Get Temperature and Humidity Alerts for Tracker */
  alertCount() {
    this._sub.add(this.dashboardService.requestAlertCount(this.userInfo.userLevel, this.userInfo.id, this.userInfo.token).subscribe((dataAlert: any) => {
      if (dataAlert.userToken === this.userInfo.token) {
        this.tempAlertCounts = {
          high: dataAlert.tempHigh,
          low: dataAlert.tempLow
        }
        this.humidityAlertCounts = {
          high: dataAlert.humidityHigh,
          low: dataAlert.humidityLow
        }
        this.geofenceAlertCount = dataAlert.geofence;
        this.loadAlerts = false;
      }
    }));
  }

  /* Get counts for Shipments Widget */
  widgetCount(res) {
    let events = [];
    res.map((item, i) => {
      if (item.events && item.events.length) {
        this.shipmentsWithEvents.push(item);
        this.originalShipmentsWithEvents.push(item);
      }
      // this.totalShipments++;
      if (item.status === "COMPLETED")
        this.completedShipments++;
      else if (item.shipmentType.toLowerCase() === "movable" && item.status.toLowerCase() !== "completed")
        this.movableShipments++;
      else if (item.shipmentType.toLowerCase() === "fixed" && item.status.toLowerCase() !== "completed")
        this.fixedShipments++;
      if ((item.status.toLowerCase() !== "completed" && item.status.toLowerCase() !== "cancelled") && item.events)
        events.push(item.events[0])
    })
    // this._sub.add(this.endPointService.getAllEndPoint().subscribe((trackers) => {
    //   this.activeTrackers = trackers;
    // }));
    this.batteryCount(events);
  }

  /* Get count for battery widget */
  batteryCount(data) {
    let high = 0, medium = 0, low = 0;
    // this.activeTrackerEvents = data;
    data.map((event) => {
      if (event?.payload && event?.payload.match(',bat:')) {
        let battery = event.payload.split(',bat:')[1].split('V')[0]
        if (battery > 3.7) {
          high++;
        } else if (battery > 3.5) {
          medium++;
        } else {
          low++;
        }
      }
    });
    this.batteryCounts = {
      high: high,
      medium: medium,
      low: low
    }
  }

  /**
   * Description: prepare data for dashboard
   * @description prepare data for dashboard
   */
  getTrackersWithEvents() {
    let res = this.shipmentList;
    this.isSelectedShipment = false;
    this.isSelectedShipmentName = [];
    this.resetAccrodian = true;
    setTimeout(() => { this.resetAccrodian = false; }, 0);
    if (res?.length) {
      // this._sub.add(this.communicationChainService.getShipmentsWithEvents(this.shipmentFilterList).subscribe(
      //   ((res) => {
      this.deviceBlockLockData = res;
      this.loadMap(res);
      if (this.changeDataOnLoad) {
        this.widgetCount(res);
        this.changeDataOnLoad = false;
      }
      this.isShipmentsWithEvents = false;
      //   }),
      //   (error => {
      //     if (error?.message !== API_ERROR.USER_LOGOUT) {
      //       this.toastr.error(`${this.constantVariables.ENDPOINT.LABEL2} cannot be retrieved at this moment. Please try again.`, ' ', {
      //         timeOut: 3000
      //       });
      //     }
      //     this.isShipmentsWithEvents = false;
      //   })
      // ));
    }
    else {
      this.map = new google.maps.Map(document.getElementById('map'), {
        zoom: this.zoom,
        center: new google.maps.LatLng(this.shipmentList[0]?.latitude, this.shipmentList[0]?.longitude),
        mapTypeId: this.mapType,
        disableDefaultUI: true
      });
    }
  }

  /* Prepare Data for Map */
  public loadMap(mapData) {
    this.hData = [];

    mapData.map((item, i) => {
      if (item.events && item.events.length && item.latitude && item.longitude) {
        this.hData.push({
          deviceSerial: item.id,
          latitude: item.latitude,
          longitude: item.longitude,
        });
      }
    });
    if (this.hData.length)
      this.noLocations = false;
    // this.endPointCount = this.hData.length;
    this.mapData = this.hData;
    this.type = "cluster";
    setTimeout(() => {

      this.cluster = this.hData.map(device => {
        const infowindow = new google.maps.InfoWindow();
        const marker = new google.maps.Marker({
          position: new google.maps.LatLng(device.latitude, device.longitude),
          map: this.map,
          draggable: false,
          title: 'Click to show device status',
          icon: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png"
        });

        marker.addListener('click', () => {
          infowindow.open(this.map, marker);
        });

        const content = '<div id="content">' +
          // tslint:disable-next-line: max-line-length
          '<div style="border-bottom: 1px solid #80808030;text-align:center;padding-bottom:5px;font-size:12px;">Track Id: ' + device.deviceSerial + '</div>' +
          '<div id="bodyContent" >' +
          // tslint:disable-next-line: max-line-length
          '<div style="padding-top:5px;font-size:12px;text-align:center" (click)="OpneMenu()"><i class="fa fa-info-circle" aria-hidden="true"></i></div>' +
          '</div>' +
          '</div>';

        this.makeInfoWindowEvent(this.map, infowindow, content, marker, device);

        return marker;

      });
    }, 5);
    // const clusterer = new MarkerClusterer({
    //   markers: cluster,
    //   map: this.map,
    // })

    // this.isMapLoading = false;

    /** HEATMAP CONFIGURATION STARTS */
    // this.heatmap = new google.maps.visualization.HeatmapLayer({
    //   data: heatMapData,
    //   map: this.map,
    // });
    // this.heatmap.setOptions({
    //   dissipating: true,
    //   // maxIntensity: 1,
    //   radius: 10,
    //   opacity: 1,
    // });
    /** HEATMAP CONFIGURATION ENDS */
  }

  private makeInfoWindowEvent(maps, infowindow, contentString, marker, device) {
    google.maps.event.addListener(marker, 'click', () => {
      this.isSelectedShipmentData = false;
      this.tabName = 'Overview';
      this.deviceDataInfo = [];
      this.bluTags = [];
      this.locationData = [];
      this.humidity = [];
      this.temperature = [];
      this.alerts = [];
      this.selectedDevice = null;
      this.selectedBluTagData.tag = 'All Tags';
      this.selectedBluTagData.filterTagData = [];
      this.closeModel();
      this.iseditBlocklockdev = !this.iseditBlocklockdev;
      infowindow.setContent(contentString);
      infowindow.close(maps, marker);
      this.selectedDevice = this.deviceBlockLockData.find(
        (item: any) => item.id === device.deviceSerial);
      this.deviceDataInfo = [
        { tab: 'Overview' },
        { tab: 'Locations' },
        { tab: 'Humidity' },
        { tab: 'Temperature' }
      ];
      $('.editModal-container').addClass('open');
      $('#Overview').addClass('active show');

      setTimeout(() => {
        this._sub.add(this.communicationChainService.getTrackersWithEvents([this.selectedDevice], false).subscribe(
          ((res) => {
            this.getDeviceData();
          }),
          (error => {
            if (error?.message !== API_ERROR.USER_LOGOUT) {
              this.toastr.error(`${this.constantVariables.ENDPOINT.LABEL2} cannot be retrieved at this moment. Please try again.`, ' ', {
                timeOut: 3000
              });
            }
          })
        ))
      }, 50);
    });
  }

  closeModel() {
    $('.editModal-container').removeClass('open');
    $(`#${this.tabName}`).removeClass('active show');
  }

  convertedDate(date) {
    // const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    // const dateObj = new Date(date);
    // const month = monthNames[dateObj.getMonth()];
    // const day = String(dateObj.getDate()).padStart(2, '0');
    // const year = dateObj.getFullYear();
    // const convertDate = `${day} ${month} ${year}, ${String(dateObj.getHours()).padStart(2, '0')}:${String(dateObj.getMinutes()).padStart(2, '0')}:${String(dateObj.getSeconds()).padStart(2, '0')}`;
    const convertDate = moment(new Date(date)).format("ddd MMM DD y h:mm a");
    return convertDate;
  }

  // click on Markup node
  public getDeviceData() {
    this.deviceDataInfo = [
      { tab: 'Overview' },
      { tab: 'Locations' },
      { tab: 'Humidity' },
      { tab: 'Temperature' }
    ];
    const trackers = [];
    this.selectedDevice.trackers.forEach((tracker) => {
      const eventsData = this.selectedDevice.events.filter((event) => event.deviceUUID === tracker.data.deviceUUID);
      if (eventsData.length) {
        const bluTagsData = [];
        eventsData.forEach((event) => {
          if (event['sensors']) {
            for (let sensor of event['sensors']) {
              if (!this.deviceDataInfo.find(tabing => tabing.tab === 'Tags')) {
                this.deviceDataInfo = [...this.deviceDataInfo, { tab: 'Tags' }]
              }
              if (!this.bluTags.find(tag => tag === sensor.tag)) {
                this.bluTags = [...this.bluTags, sensor.tag];
              }
              if (!bluTagsData.find(tag => tag.tag === sensor.tag) && sensor.temperature) {
                bluTagsData.push({ tag: sensor.tag, temp: sensor.temperature, alert: sensor.alert, highAlert: 0, lowAlert: 0 })
              }
              if (sensor.alert) {
                let index = bluTagsData.findIndex((tag) => tag.tag === sensor.tag);
                if (index !== -1) {
                  if (sensor.alert === 'High')
                    bluTagsData[index]['highAlert'] += 1;
                  if (sensor.alert === 'Low')
                    bluTagsData[index]['lowAlert'] += 1;
                }
              }
            }
          }
          this.locationData.push({
            id: event?.id,
            sensors: event?.sensors ? event?.sensors : [],
            latitude: event?.parsedData?.latitude ? event?.parsedData?.latitude : null,
            longitude: event?.parsedData?.longitude ? event?.parsedData?.longitude : null,
            location: event?.parsedData?.location ? event?.parsedData?.location : null,
            battery: event?.parsedData?.battery ? event?.parsedData?.battery : null,
            signal: event?.parsedData?.signal ? event?.parsedData?.signal : null,
            time: event?.time ? event?.time : null,
            timeStamp: event?.timeStamp ? event?.timeStamp : 0
          });
        });

        trackers.push({ uuid: tracker.data.deviceUUID, signal: eventsData[0]?.parsedData.signal, battery: eventsData[0]?.parsedData?.battery || '0.00V', bluTagsData });
        // trackers.push({ uuid: tracker.data.deviceUUID, battery: eventsData[0]?.parsedData?.battery || '0.00V', bluTagsData });
      }
    })

    this.selectedDevice.detailed = trackers;
    this.isSelectedShipmentData = true;
    // if (this.selectedDevice && this.selectedDevice.shipmentId) {
    //   /* this.dashboardService.getShipmentById(this.selectedDevice.shipmentId)
    //     .subscribe((responseData: any) => {
    //       if (responseData) { */
    //   /* for (let i = 0; i < this.locationData.length; i++) {
    //     this.locationData[i] = { lat: Number(this.locationData[i].latitude), lng: Number(this.locationData[i].longitude), name: this.locationData[i].name }
    //   }

    //   const service = new google.maps.DirectionsService;
    //   const map = new google.maps.Map(document.getElementById('deviceMap'));

    //   // Route Points
    //   var lngs = this.locationData.map(function (station) { return station.lng; });
    //   var lats = this.locationData.map(function (station) { return station.lat; });
    //   map.fitBounds({
    //     west: Math.min.apply(null, lngs),
    //     east: Math.max.apply(null, lngs),
    //     north: Math.min.apply(null, lats),
    //     south: Math.max.apply(null, lats),
    //   });

    //   // Start Location Marker
    //   new google.maps.Marker({
    //     position: this.locationData[0],
    //     map: map,
    //     icon: {
    //       url: 'http://maps.google.com/mapfiles/ms/icons/green-dot.png'
    //     },
    //     title: 'Start Location'
    //   });

    //   // End Location Marker
    //   new google.maps.Marker({
    //     position: this.locationData[this.locationData.length - 1],
    //     map: map,
    //     icon: {
    //       url: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png'
    //     },
    //     title: 'Current Location'
    //   });

    //   // Divide route to several parts because max stations limit is 25 (23 waypoints + 1 origin + 1 destination)
    //   for (var i = 0, parts = [], max = 25 - 1; i < this.locationData.length; i = i + max)
    //     parts.push(this.locationData.slice(i, i + max + 1));

    //   // Service callback to process service results
    //   const service_callback = function (response, status) {
    //     if (status != 'OK') {
    //       console.log('Directions request failed due to ' + status);
    //       return;
    //     }
    //     var renderer = new google.maps.DirectionsRenderer;
    //     renderer.setMap(map);
    //     renderer.setOptions({ suppressMarkers: true, preserveViewport: true });
    //     renderer.setDirections(response);
    //   };

    //   // Send requests to service to get route (for stations count <= 25 only one request will be sent)
    //   for (var i = 0; i < parts.length; i++) {
    //     // Waypoints does not include first station (origin) and last station (destination)
    //     var waypoints = [];
    //     for (var j = 1; j < parts[i].length - 1; j++)
    //       waypoints.push({ location: parts[i][j], stopover: false });

    //     // Service options
    //     const service_options = {
    //       origin: parts[i][0],
    //       destination: parts[i][parts[i].length - 1],
    //       waypoints: waypoints,
    //       travelMode: google.maps.TravelMode.DRIVING
    //     };
    //     // Send request
    //     service.route(service_options, service_callback);
    //   } */
    //   // const pointA = responseData.pickupLocation ? responseData.pickupLocation : '';
    //   // const pointB = responseData.destinationLocation ? responseData.destinationLocation : '';
    //   const pointA = new google.maps.LatLng(this.locationData[0].latitude, this.locationData[0].longitude);
    //   const pointB = new google.maps.LatLng(this.locationData[this.locationData.length - 1].latitude, this.locationData[this.locationData.length - 1].longitude);
    //   this.smallMap = new google.maps.Map(document.getElementById('deviceMap'), {
    //     center: pointA,
    //     zoom: this.zoom,
    //     mapTypeId: this.mapType,
    //     disableDefaultUI: true
    //   });
    //   const SELF = this;
    //   // Instantiate a directions service.
    //   const directionsService = new google.maps.DirectionsService();
    //   directionsService.route({
    //     origin: pointA,
    //     destination: pointB,
    //     avoidTolls: false,
    //     avoidHighways: false,
    //     // travelMode: google.maps.TravelMode.DRIVING
    //     travelMode: google.maps.TravelMode.DRIVING
    //   }, function (response, status) {
    //     if (status == google.maps.DirectionsStatus.OK) {
    //       new google.maps.DirectionsRenderer({
    //         map: SELF.smallMap,
    //         directions: response,
    //         suppressMarkers: true
    //       });
    //       var leg = response.routes[0].legs[0];
    //       const startIcon = {
    //         // path: google.maps.SymbolPath.CIRCLE,
    //         // scale: 4,
    //         // strokeWeight: 2,
    //         // fillColor: 'rgb(230 36 36)',
    //         // strokeColor: 'rgb(230 36 36)',
    //         // fillOpacity: 1
    //         url: 'http://maps.google.com/mapfiles/ms/icons/green-dot.png',
    //         // size: new google.maps.Size(20, 40),
    //         // scaledSize: new google.maps.Size(36, 50),
    //         // anchor: new google.maps.Point(0, 50)
    //       }
    //       const endIcon = {
    //         // path: google.maps.SymbolPath.CIRCLE,
    //         // scale: 4,
    //         // strokeWeight: 2,
    //         // fillColor: 'chartreuse',
    //         // strokeColor: 'chartreuse',
    //         // fillOpacity: 1
    //         url: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png',
    //         // size: new google.maps.Size(20, 40),
    //         // scaledSize: new google.maps.Size(36, 50),
    //         // anchor: new google.maps.Point(0, 50)
    //       }
    //       SELF.makeMarker(leg.start_location, startIcon, "title", SELF.smallMap);
    //       SELF.makeMarker(leg.end_location, endIcon, 'title', SELF.smallMap);

    //     } else {
    //       alert("Unable to retrive route");
    //     }
    //   });
    /* }
  }, (error: HttpErrorResponse) => {
    console.log('error', error);
  }); */
    // }
    $('.editModal-container').addClass('open');
    $('#Overview').addClass('active show');
    $('#Locations').removeClass('active show');
    $('#Temperature').removeClass('active show');
    $('#Humidity').removeClass('active show');
    $('#Tags').removeClass('active show');
  }

  public changeTab(tabName) {
    
    const data = [];
    const date = [];
    let alertData = {};
    this.loadDetailGraph = true;
    this.tabName = tabName;
    if (this.locationData.length && tabName === 'Locations') {
      this.noLocations = true;
      let focusPoint: any;
      focusPoint = new google.maps.LatLng(this.locationData[0].latitude, this.locationData[0].longitude);
      if (this.selectedDevice.shipmentType.toLowerCase() === "fixed") {
        var mapData = JSON.parse(JSON.stringify(this.selectedDevice.events[0]));
        if (mapData.location === 'N/A') {
          const data = this.selectedDevice.events.find(e => e.location && e.location !== 'N/A')
          if (data && data.parsedData && data.parsedData.location) {
            mapData.parsedData.location = data.parsedData.location;
            mapData.parsedData.latitude = data.parsedData.latitude;
            mapData.parsedData.longitude = data.parsedData.longitude;
            mapData.location = data.parsedData.location;
          }
        }
        
        if ((mapData.parsedData && mapData.parsedData.location === 'N/A') || mapData.location === 'N/A')
          this.noLocations = true;
        else
          this.noLocations = false;
          
          this.smallMapData = mapData.parsedData;
          this.smallMapData['geofence_radius'] = this.selectedDevice.geofence_radius;
          this.type = "fixed";
          this.showLoadShipment = false;
      }
      else {
        let waypoints = [];
        let waypts = [];
        
        for (let i = 0; i < this.locationData.length; i++) {
          const battery = this.locationData[i].battery.split('V');
          let batValue = this.calculateBatteryPercentage(battery[0]);
          const batClass = this.calculateBatteryPercentage(battery[0], true);
          let bat = `<i class="fas ${batClass}"></i>`;
          let signal = this.locationData[i].signal;
          let content;

          if (this.locationData[i].signal) {
            content = this.commonService.getInfoWindowContent(this.locationData[i], bat, batValue, signal)
          } else {
            content = this.commonService.getInfoWindowContent(this.locationData[i], bat, batValue);
          }

          if (waypoints.findIndex(element1 => element1.latitude === this.locationData[i].latitude) === -1 &&
            waypoints.findIndex(element1 => element1.longitude === this.locationData[i].longitude) === -1) {
            waypoints.push({
              id: this.locationData[i].id,
              latitude: this.locationData[i].latitude,
              longitude: this.locationData[i].longitude,
              time: this.locationData[i].timeStamp,
              content: content
            });
            waypts.push({
              location: new google.maps.LatLng(this.locationData[i].latitude, this.locationData[i].longitude),
              stopover: true,
            })
          }

          if (this.locationData[i]['bluTag']) {
            let eventIndex = waypoints.findIndex(element1 => element1.id === this.locationData[i].id);
            if (eventIndex !== -1) {
              if (this.locationData[i].signal) {
                waypoints[eventIndex].content = this.commonService.getInfoWindowContent(this.locationData[i], bat, batValue, signal, true, waypoints[eventIndex].content)
              } else {
                waypoints[eventIndex].content = this.commonService.getInfoWindowContent(this.locationData[i], bat, batValue, undefined, true, waypoints[eventIndex].content);
              }
            }
          }
        }
        
        waypoints.reverse();
        const locationsExist = waypoints.find((e) => e.latitude !== null && e.longitude !== null);
        if (locationsExist) {
          this.noLocations = false;
        } else
          this.noLocations = true;
        
        this.type = "route";
        this.smallMapData = waypoints;

      }
    }

    // date.push(this.convertedDate(this.selectedDevice.events[0].time));
    // this.selectedBluTagData.filterTagData.push(this.selectedDevice.events[0]);

    if (this.tabName === 'Humidity') {
      data.push(this.selectedDevice.events[0]?.parsedData.humidity);
    } else if (this.tabName === 'Temperature') {
      data.push(this.selectedDevice.events[0]?.parsedData.temp);
    }
    this.selectedBluTagData.filterTagData = []
    this.selectedDevice.events.forEach((event, i) => {
      if (this.selectedDevice?.events[i - 1]?.timeStamp != event?.timeStamp) {
        if (this.tabName === 'Humidity' && event?.parsedData?.humidity) {
          alertData = {
            min: this.selectedDevice.trackers[0].minH ? Number(this.selectedDevice.trackers[0].minH) : null,
            max: this.selectedDevice.trackers[0].maxH ? Number(this.selectedDevice.trackers[0].maxH) : null
          };
          date.push(this.convertedDate(event.time));
          data.push(event?.parsedData?.humidity);
        }
        if (this.tabName === 'Temperature' && event?.parsedData?.temp) {
          alertData = {
            min: this.selectedDevice.trackers[0].min ? Number(this.selectedDevice.trackers[0].min) : null,
            max: this.selectedDevice.trackers[0].max ? Number(this.selectedDevice.trackers[0].max) : null
          };
          date.push(this.convertedDate(event.time));
          data.push(event?.parsedData?.temp);
        }
      }
      // if (this.tabName === 'Tags') {
      //   console.log("tags----", this.bluTags);

      //   let index = this.selectedDevice.trackers[0].sensors.findIndex((tag) => tag.mac_addr === event.blueTag);
      //   if (this.tabName === 'Tags' && event?.temperature && index !== -1) {
      //     alertData = {
      //       min: Number(
      //         this.selectedDevice?.trackers[0]?.sensors[index]?.config?.min
      //           ? this.selectedDevice?.trackers[0]?.sensors[index]?.config?.min : null
      //       ),
      //       max: Number(
      //         this.selectedDevice?.trackers[0]?.sensors[index]?.config?.max
      //           ? this.selectedDevice?.trackers[0]?.sensors[index]?.config?.max : null
      //       )
      //     };
      //     let assignedAt = this.selectedDevice?.trackers[0]?.sensors
      //       ? this.selectedDevice?.trackers[0]?.sensors[index].assignedAt : undefined;
      //     assignedAt = assignedAt ? Math.trunc(new Date(assignedAt).getTime() / 1000) : undefined;

      //     if (!assignedAt || event.timeStamp >= assignedAt) {
      //       date.push(this.convertedDate(event.time));
      //       this.selectedBluTagData.filterTagData.push(event);
      //       data.push(Number(event?.temperature));
      //     }
      //   }
      // }
    });

    if (tabName == 'Tags') {
      this.dataFilterByTag(this.bluTags[0])
    } else {
      this.ChartDataGraph(date, data, 'Date', this.tabName, alertData);
    }
  }

  public dataFilterByTag(tag) {
    this.loadDetailGraph = true;
    this.selectedBluTagData.tag = tag;
    this.selectedBluTagData.filterTagData = [];
    const data = [];
    const date = [];

    let index = this.selectedDevice.trackers[0].sensors.findIndex((tag1) => tag1.mac_addr === tag);
    const alertData = {
      min: Number(
        this.selectedDevice?.trackers[0]?.sensors[index]?.config?.min
          || this.selectedDevice?.trackers[0]?.sensors[index]?.config?.min === 0
          ? this.selectedDevice?.trackers[0]?.sensors[index]?.config?.min : null
      ),
      max: Number(
        this.selectedDevice?.trackers[0]?.sensors[index]?.config?.max
          || this.selectedDevice?.trackers[0]?.sensors[index]?.config?.max === 0
          ? this.selectedDevice?.trackers[0]?.sensors[index]?.config?.max : null
      )
    };
    let assignedAt = this.selectedDevice?.trackers[0]?.sensors
      ? this.selectedDevice?.trackers[0]?.sensors[index]?.assignedAt : undefined;
    assignedAt = assignedAt ? Math.trunc(new Date(assignedAt).getTime() / 1000) : undefined;

    this.selectedDevice.events.forEach((event) => {
      const sensorData = event.sensors.find((sensor) => sensor.tag === tag);
      if ((!assignedAt || event.timeStamp >= assignedAt)) {
      // if (sensorData?.temperature && (!assignedAt || event.timeStamp >= assignedAt)) {
        if (sensorData?.tag === tag) {
          this.selectedBluTagData.filterTagData.push({
            time: event.time,
            blueTag: sensorData.tag,
            temperature: sensorData.temperature,
            alert: sensorData.alert
          });
          if (sensorData?.temperature) {
            date.push(this.convertedDate(event.time));
            data.push(Number(sensorData?.temperature));
          }
        } else if (tag === 'All Tags') {        
          this.selectedBluTagData.filterTagData.push({
            time: event.time,
            blueTag: sensorData.tag,
            temperature: sensorData.temperature,
            alert: sensorData.alert
          });
          if (sensorData?.temperature) {
            date.push(this.convertedDate(event.time));
            data.push(Number(sensorData?.temperature));
          }
        }
      }
    });
    // data.push(undefined)
    this.ChartDataGraph(date, data, 'Date', 'Tags', alertData);
  }

  public ChartDataGraph(x, y, xlable, title, alertData?) {
    const maxEventValue = Math.max(...y);
    const minEventValue = Math.min(...y);
    let maxVal = maxEventValue;
    let minVal = minEventValue;
    minVal = Math.floor(minVal)
    maxVal = Math.ceil(maxVal)
    if (y.length) {
      this.noGraphData = false;
      if (title === 'Tags' && alertData.min && alertData.max) {
        this.chartDataOptions = {
          tooltip: {
            trigger: 'axis'
          },
          visualMap: {
            show: false,
            seriesIndex: 0,
            pieces: [
              {
                lte: alertData.min,
                color: '#BE271B'
              },
              {
                gt: alertData.min,
                lte: alertData.max,
                color: '#7EB337'
              },
              {
                gt: alertData.max,
                color: '#BE271B'
              },
            ]
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: x.reverse(),
            name: xlable,
            splitLine: {
              show: false
            }
          },
          yAxis: {
            max: (alertData.max > maxVal ? alertData.max : maxVal) + 10,
            min: (alertData.min < minVal ? alertData.min : minVal) - 10,
            name: title,
            type: 'value',
            axisLabel: {
              formatter: '{value} °C'
            },
            splitLine: {
              lineStyle: {
                color: ['#333'],
              }
            }
          },
          series: [
            {
              name: 'Temp',
              type: 'line',
              data: y.reverse(),
              markArea: {
                silent: true,
                data: [
                  [
                    {
                      yAxis: alertData.min,
                      itemStyle: {
                        color: "#fffff08c"
                      }
                    },
                    {
                      yAxis: alertData.max,
                      itemStyle: {
                        color: "#fffff08c"
                      }
                    }
                  ]
                ]
              },
              markLine: {
                symbol: 'none',
                label:
                {
                  position: 'middle',
                  show: true,
                },
                data: [
                  {
                    yAxis: alertData.max, name: 'Max', symbol: 'circle',
                    label: {
                      position: 'middle',
                      formatter: `Max Temp: ${alertData.max} °C`
                    },
                    lineStyle: {
                      color: "ivory"
                    }
                  },
                  {
                    yAxis: alertData.min, name: 'Min',
                    label: {
                      position: 'insideMiddleBottom',
                      formatter: `Min Temp: ${alertData.min} °C`,
                    },
                    symbol: 'circle',
                    lineStyle: {
                      color: "ivory"
                    }
                  }
                ]
              },
              areaStyle: null
            }
          ]
        };
      } else if (title === 'Tags') {
        this.chartDataOptions = {
          tooltip: {
            trigger: 'axis'
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: x.reverse(),
            name: xlable,
            splitLine: {
              show: false
            }
          },
          yAxis: {
            max: maxVal + 20,
            min: minVal - 20,
            name: title,
            type: 'value',
            axisLabel: {
              formatter: '{value} °C'
            },
            splitLine: {
              lineStyle: {
                color: ['#BBBBBB'],
              }
            }
          },
          series: [
            {
              name: 'Temp',
              type: 'line',
              data: y.reverse(),
              areaStyle: null,
            }
          ]
        };
      } else if (alertData.min && alertData.max) {
        this.chartDataOptions = {
          tooltip: {
            trigger: 'axis'
          },
          visualMap: {
            show: false,
            seriesIndex: 0,
            pieces: [
              {
                lte: Number(alertData.min),
                color: 'rgb(250, 37, 37)'
              },
              {
                gt: Number(alertData.min),
                lte: Number(alertData.max),
                color: '#2196F3'
              },
              {
                gt: Number(alertData.max),
                color: 'rgb(250, 37, 37)'
              },
            ]
          },
          xAxis: {
            data: x.reverse(),
            name: xlable,
          },
          yAxis: {
            max: (alertData.max > maxVal ? alertData.max : maxVal) + 10,
            min: (alertData.min < minVal ? alertData.min : minVal) - 10,
            name: title,
            splitLine: {
              lineStyle: {
                color: ['#BBBBBB'],
              }
            }
          },
          series: [{
            data: y.reverse(),
            type: 'line',
            name: `${title}`,
            areaStyle: null,
            markArea: {
              silent: true,
              data: [
                [
                  {
                    yAxis: alertData.min,
                    itemStyle: {
                      color: "#ff767624"
                    }
                  },
                  {
                    yAxis: alertData.max,
                    itemStyle: {
                      color: "#ff767624"
                    }
                  }
                ]
              ]
            },
            markLine: {
              symbol: 'none',
              label:
              {
                position: 'middle',
                show: true,
              },
              data: [
                {
                  yAxis: alertData.max, name: 'Max', symbol: 'circle',
                  label: {
                    position: 'middle',
                    formatter: `Max ${title}: ${alertData.max} °C`
                  },
                  lineStyle: {
                    color: 'orange'
                  }
                },
                {
                  yAxis: alertData.min, name: 'Min', symbol: 'circle',
                  label: {
                    position: 'insideMiddleBottom',
                    formatter: `Min ${title}: ${alertData.min} °C`,
                  },
                  lineStyle: {
                    color: 'orange'
                  },
                }
              ]
            },
          }]
        };
      } else {
        this.chartDataOptions = {
          xAxis: {
            data: x.reverse(),
            name: xlable,
          },
          yAxis: {
            max: maxVal + 10,
            min: minVal - 10,
            name: title,
          },
          series: [{
            data: y.reverse(),
          }]
        };
      }
      setTimeout(() => { this.loadDetailGraph = false; }, 500);
    } else
      this.noGraphData = true;
  }

  private getSocketEvents() {

    this.endPointCountSubscription = this.dashboardService.endPointCount().subscribe((event: any) => {
      if (this.socketConnectionId == event[0] && this.userInfo.token === event[4]) {
        this.totalDevices = event[1] || 0;
        this.activeDevices = event[2] || 0;
        this.connectedDevices = event[5] || 0;
        this.readyDevices = event[6] || 0;
      }
    });
    this._sub.add(this.dashboardService.getNotificationData().subscribe((resp: any) => {
      // console.log('********getNotificationData',resp);
    }));


    // this.edgeChainCountSubscription = this.dashboardService.edgeChainCount().subscribe((event: any) => {
    //   if (this.socketConnectionId == event[0]) {
    //     this.totalBlocklock = event[1];
    //     if (this.totalBlocklock != 0)
    //       this.edgePercentage = ((this.edgeActiveCount / this.totalBlocklock) * 100).toFixed(2);
    //     else
    //       this.edgePercentage = 0;
    //   }
    // });
    // this.activeEdgeCountSubscription = this.dashboardService.activeEdgeCount().subscribe((event: any) => {
    //   if (this.socketConnectionId == event[0]) {
    //     this.edgeActiveCount = event[1];
    //     this.edgeInactiveCount = event[2];
    //     if (this.totalBlocklock != 0)
    //       this.edgePercentage = ((this.edgeActiveCount / this.totalBlocklock) * 100).toFixed(2);
    //     else
    //       this.edgePercentage = 0;
    //   }
    // });
    // this.provisionEndPointCountSubscription = this.dashboardService.provisionEndPointCount().subscribe((event: any) => {
    //   if (this.socketConnectionId == event[0]) this.totalProvisons = event[1];
    // });
    // this.eventCountSubscription = this.dashboardService.eventCount().subscribe((event: any) => {
    //   if (this.socketConnectionId == event[0]) {
    //     this.eventCount = event[1];
    //     // this.batteryCounts = {
    //     //   high : this.eventCount?.high,
    //     //   medium: this.eventCount?.medium,
    //     //   low : this.eventCount?.low
    //     // }
    //   }
    // });
    // this.generateAlertsSubscription = this.dashboardService.generateAlerts().subscribe((event: any) => {
    //   if (this.socketConnectionId == event[0]) {
    //     console.log("dataaa", event[1]);
    //     // this.batteryCounts = {
    //     //   high : this.eventCount?.high,
    //     //   medium: this.eventCount?.medium,
    //     //   low : this.eventCount?.low
    //     // }
    //   }
    // });
    // this.eventByDurationSubscription = this.dashboardService.eventByDuration().subscribe((data: any) => {
    //   if (this.socketConnectionId == data[0]) this.filterTotalEventsGraphSocket(data[1]);
    // });
    // this.deviceByDurationSubscription = this.dashboardService.deviceByDuration().subscribe((data: any) => {
    //   if (this.socketConnectionId == data[0]) this.filterTotalDevicesGraphSocket(data[1]);
    // });
    // this.averageSensorDataSubscription = this.dashboardService.averageSensorData().subscribe((data: any) => {
    //   if (this.socketConnectionId == data[0]) this.filterAverageSensorEventsSocket(data[1]);
    // });
    // this.sensorEventsDataSubscription = this.dashboardService.sensorEventsData().subscribe((data: any) => {
    //   if (this.socketConnectionId == data[0]) this.filterSensorEventsSocket(data[1]);
    // });

    // this.reciveSensorSubscription = this.dashboardService.reciveSensor().subscribe((data: any) => {
    //   if (this.socketConnectionId == data[0]) this.sensorList = data[1];

    //   if (this.selectedSensor == "") this.selectedSensor = (this.sensorList[0]) ? this.sensorList[0] : '';
    //   if (this.selectedSensorEvt == "") this.selectedSensorEvt = (this.sensorList[0]) ? this.sensorList[0] : '';
    //   // console.log("sensorlist", this.sensorList, this.selectedSensor);
    //   if (this.filterSensorEventsSensorName == "") this.filterSensorEventsSensorName = (this.sensorList[0]) ? this.sensorList[0] : '';
    //   if (this.filterAverageSensorEventsSensorName == "") this.filterAverageSensorEventsSensorName = (this.sensorList[0]) ? this.sensorList[0] : '';
    // });

    // this.reciveAvgEventDataSensorSubscription = this.dashboardService.avgEventDataSensorList().subscribe((data: any) => {
    //   if (this.socketConnectionId == data[0] && !(this.arraysEqual(this.sensorAvgEventDataList, data[1]))) {
    //     this.sensorAvgEventDataList = data[1];
    //     if (this.selectedSensor == "") this.selectedSensor = (this.sensorAvgEventDataList[0]) ? this.sensorAvgEventDataList[0].name : '';
    //     if (this.filterAverageSensorEventsSensorName == "") this.filterAverageSensorEventsSensorName = (this.sensorAvgEventDataList[0]) ? this.sensorAvgEventDataList[0].name : '';
    //   }

    // });

    // this.reciveEventDataSensorSubscription = this.dashboardService.evnetDataSensorList().subscribe((data: any) => {
    //   if (this.socketConnectionId == data[0] && !(this.arraysEqual(this.sensorEventDataList, data[1]))) {
    //     this.sensorEventDataList = data[1];
    //     if (this.selectedSensorEvt == "") this.selectedSensorEvt = (this.sensorEventDataList[0]) ? this.sensorEventDataList[0].name : '';
    //     if (this.filterSensorEventsSensorName == "") this.filterSensorEventsSensorName = (this.sensorEventDataList[0]) ? this.sensorEventDataList[0].name : '';
    //   }
    // });
    // this.getNodeMap();
    // this.getSparkLineGraph("provisioningQueue");
    // this.getSparkLineGraph("totalEndpoints");
    // this.getSparkLineGraph("events");
    // this.getActiveEdge('edge')

    // this.getTotalBlocklock();
    // this.getTotalDevices();
    // this.getTotalProvisons();
    // this.getAllDeviceList();
    // this.getAllNewUsersList();
    // this.getActiveEndpoints();
    // this.getAllEventList();
  }

  public showShipmentDetails(item) {
    this.showLoadShipmentDetails = true;
    if (item.shipmentType.toLowerCase() === 'fixed') {
      this._sub.add(this.communicationChainService.getTrackersWithEvents([item], false).subscribe(
        ((res) => {
          this.deviceBlockLockData = res;
          let deviceData = {
            deviceSerial: item.id,
            latitude: item.latitude,
            longitude: item.longitude,
          }
          this.tabName = 'Overview';
          this.deviceDataInfo = [];
          this.bluTags = [];
          this.locationData = [];
          this.humidity = [];
          this.temperature = [];
          this.alerts = [];
          this.selectedDevice = null;
          this.selectedBluTagData.tag = 'All Tags';
          this.selectedBluTagData.filterTagData = [];
          this.closeModel();
          this.iseditBlocklockdev = !this.iseditBlocklockdev;
          this.selectedDevice = this.deviceBlockLockData.find(
            (item: any) => item.id === deviceData.deviceSerial);
          setTimeout(() => {
            this.getDeviceData();
          }, 50);
          this.showLoadShipmentDetails = false;
        }),
        (error => {
          if (error?.message !== API_ERROR.USER_LOGOUT) {
            this.toastr.error(`${this.constantVariables.ENDPOINT.LABEL2} cannot be retrieved at this moment. Please try again.`, ' ', {
              timeOut: 3000
            });
          }
        })
      ));
    } else {
      let deviceData = {
        deviceSerial: item.id,
        latitude: item.latitude,
        longitude: item.longitude,
      }
      this.tabName = 'Overview';
      this.deviceDataInfo = [];
      this.bluTags = [];
      this.locationData = [];
      this.humidity = [];
      this.temperature = [];
      this.alerts = [];
      this.selectedDevice = null;
      this.selectedBluTagData.tag = 'All Tags';
      this.selectedBluTagData.filterTagData = [];
      this.closeModel();
      this.iseditBlocklockdev = !this.iseditBlocklockdev;
      this.selectedDevice = this.deviceBlockLockData.find(
        (item: any) => item.id === deviceData.deviceSerial);
      setTimeout(() => {
        this.getDeviceData();
      }, 50);
      this.showLoadShipmentDetails = false;
    }
  }

  getEvents(item) {
    this.closeModel();
    this.showLoadShipment = true;
    this.isSelectedShipment = true;
    this.isSelectedShipmentName = [item.id, item.shipmentType];
    this.isEventDataLoading = true;
    if (this.paginator)
      this.paginator.changePage(0);
    this.ptable.reset();
    if (item.shipmentType.toLowerCase() === 'movable')
      setTimeout(() => {
        this._sub.add(this.communicationChainService.getTrackersWithEvents([item], false).subscribe(
          ((res) => {
            this.deviceBlockLockData = res;
            this.prepareEventData(res[0].events)
            this.showShipmentRoute(item);
          }),
          (error => {
            if (error?.message !== API_ERROR.USER_LOGOUT) {
              this.toastr.error(`${this.constantVariables.ENDPOINT.LABEL2} cannot be retrieved at this moment. Please try again.`, ' ', {
                timeOut: 3000
              });
            }
          })
        ))
      }, 5);
    else {
      this.showShipmentRoute(item);
      setTimeout(() => {
        this._sub.add(this.communicationChainService.getTrackersWithEvents([item], false, 1).subscribe(
          ((res) => {
            this.totalEvents = res[0].eventsLength;
            this.deviceBlockLockData = res;
            this.prepareEventData(res[0].events)
          }),
          (error => {
            if (error?.message !== API_ERROR.USER_LOGOUT) {
              this.toastr.error(`${this.constantVariables.ENDPOINT.LABEL2} cannot be retrieved at this moment. Please try again.`, ' ', {
                timeOut: 3000
              });
            }
          })
        ))
      }, 5);
    }
  }

  async showShipmentRoute(item) {
    this.noLocations = true;
    const event = {
      id: item.id,
      latitude: item.latitude,
      longitude: item.longitude,
      shipmentType: item.shipmentType
    }

    if (event.latitude && event.longitude)
      this.noLocations = false;
    else
      this.noLocations = true;
    this.showLoadShipment = true;
    this.mapData = event;
    this.type = "fixed";
    this.zoom = 4;

    if (event.shipmentType.toLowerCase() === "fixed") {
      if(!this.noLocations){
        
        this.zoom = 8;
        this.mapData = event;
        this.type = "fixed";
        this.mapData['geofence_radius'] = item.geofence_radius;
        if (this.mapData.latitude === null || this.mapData.longitude === null) {
          const locationEvent = item.events.find((e) => e.location !== 'N/A')
          if(locationEvent){
            this.mapData = {
              id: item.id,
              latitude: locationEvent.latitude,
              longitude: locationEvent.longitude,
              shipmentType: item.shipmentType
            }
            this.noLocations = false;
          } else
            this.noLocations = true;
        }
      }
      this.showLoadShipment = false;
      this.showLoadShipmentDetails = false;
      this.selectedDevice = this.deviceBlockLockData.find(
        (item: any) => item.id === event.id);
    }
    else {
      this.showLoadShipment = true;
      let deviceData = {
        deviceSerial: event.id,
        latitude: event.latitude,
        longitude: event.longitude,
      }
      this.tabName = 'Overview';
      this.deviceDataInfo = [];
      this.bluTags = [];
      this.locationData = [];
      this.humidity = [];
      this.temperature = [];
      this.alerts = [];
      this.selectedDevice = null;
      this.selectedBluTagData.tag = 'All Tags';
      this.selectedBluTagData.filterTagData = [];
      this.closeModel();
      this.iseditBlocklockdev = !this.iseditBlocklockdev;
      this.selectedDevice = this.deviceBlockLockData.find(
        (item: any) => item.id === deviceData.deviceSerial);

      let waypoints = [];
      const trackers = [];
      this.selectedDevice?.trackers.forEach((tracker) => {
        const eventsData = this.selectedDevice.events.filter((event) => event.deviceUUID === tracker.data.deviceUUID);
        if (eventsData.length) {
          const bluTagsData = [];
          eventsData.forEach((event, i) => {
            if (event['sensors']) {
              for (let sensor of event['sensors']) {
                if (!this.deviceDataInfo.find(tabing => tabing.tab === 'Tags')) {
                  this.deviceDataInfo = [...this.deviceDataInfo, { tab: 'Tags' }]
                }
                if (!this.bluTags.find(tag => tag === sensor.tag)) {
                  this.bluTags = [...this.bluTags, sensor.tag];
                }
                if (!bluTagsData.find(tag => tag.tag === sensor.tag) && sensor.temperature) {
                  bluTagsData.push({ tag: sensor.tag, temp: sensor.temperature, alert: sensor.alert, highAlert: 0, lowAlert: 0 })
                }
                if (sensor.alert) {
                  let index = bluTagsData.findIndex((tag) => tag.tag === sensor.tag);
                  if (index !== -1) {
                    if (sensor.alert === 'High')
                      bluTagsData[index]['highAlert'] += 1;
                    if (sensor.alert === 'Low')
                      bluTagsData[index]['lowAlert'] += 1;
                  }
                }
              }
            }

            this.locationData.push({
              id: event?.id,
              sensors: event?.sensors ? event?.sensors : [],
              latitude: event?.parsedData?.latitude ? event?.parsedData?.latitude : null,
              longitude: event?.parsedData?.longitude ? event?.parsedData?.longitude : null,
              location: event?.parsedData?.location ? event?.parsedData?.location : null,
              battery: event?.parsedData?.battery ? event?.parsedData?.battery : null,
              signal: event?.parsedData?.signal ? event?.parsedData?.signal : null,
              time: event?.time ? event?.time : null,
              timeStamp: event?.timeStamp ? event?.timeStamp : 0
            });
            const battery = this.locationData[i].battery.split('V');
            let batValue = this.calculateBatteryPercentage(battery[0]);
            const batClass = this.calculateBatteryPercentage(battery[0], true);
            let bat = `<i class="fas ${batClass}"></i>`;
            let signal = this.locationData[i].signal;
            let content;

            if (this.locationData[i].signal) {
              content = this.commonService.getInfoWindowContent(this.locationData[i], bat, batValue, signal)
            } else {
              content = this.commonService.getInfoWindowContent(this.locationData[i], bat, batValue);
            }
            if (waypoints.findIndex(element1 => element1.latitude === event.parsedData.latitude) === -1 &&
              waypoints.findIndex(element1 => element1.longitude === event.parsedData.longitude) === -1) {
              if (event.parsedData.longitude !== 'gps:')
                waypoints.push({
                  id: event.id,
                  latitude: event.parsedData.latitude,
                  longitude: event.parsedData.longitude,
                  time: event.timeStamp,
                  content: content
                });
            }
          });

          trackers.push({ uuid: tracker.data.deviceUUID, battery: eventsData[0]?.parsedData?.battery || '0.00V', bluTagsData });
        }
      })
      const locationsExist = waypoints.find((e) => e.latitude !== null && e.longitude !== null);
      if (locationsExist) {
        this.noLocations = false;
      } else
        this.noLocations = true;
      waypoints.reverse();
      this.selectedDevice.detailed = trackers;
      this.mapData = waypoints;
      this.type = "route";
      this.showLoadShipment = false;
    }
  }

  downloadCallibration(uuid) {
    this._sub.add(this.endPointService.getCalibrationCertificate(uuid).subscribe((data: Blob) => {
      const file = new Blob([data], { type: 'application/pdf' })
      const fileURL = URL.createObjectURL(file);

      window.open(fileURL, '_blank'); // target new window for preview

      /* direct download */
      // const a = document.createElement('a'); 
      // a.href = fileURL;
      // a.download = device.data.deviceUUID + '.pdf';
      // a.click();
    }, error => {
      if (error?.message !== API_ERROR.USER_LOGOUT) {
        this.toastr.error(`${uuid} certificate unable to retrieve at this moment. Please try again`, '', { timeOut: 3000 });
      }
    }));
  }

  public removeAlert(alert) {
    const index = this.alerts.indexOf(alert);
    this.alerts.splice(index, 1);
  }

  getOpenPopupFlag(value: boolean) {
    this.popupFlag = value;
    this.open(this.welcomeModal);
  }

  public makeMarker(position, icon, title, map) {
    new google.maps.Marker({
      position: position,
      map: map,
      icon: icon,
      title: title
    });
  }

  public navigateTo() {
    this.shipmentService.pannelEvent({ openModel: true, shipmentId: '' });
    this.router.navigateByUrl('shipments')
  }
  
  /**
   * Description: copy domain url to clipboard
   * @description copy domain url to clipboard
   */
  copyUrlToClipboard(element: HTMLInputElement) {
    element.select();
    document.execCommand('copy');
    this.toaster.success(API_SUCCESS.LINKS_COPIED);
  }

  open(content: TemplateRef<any>, modalClass?: string) {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: modalClass ? 'xs' : 'sm',
      windowClass: modalClass ? modalClass : 'welcmScreen-popup'
    };
    this.modalRef = this.modalService.open(content, ngbModalOptions);
  }

  closeModal(event) {
    // console.log('event', event);
    if (event) { this.modalRef.close(); }
  }

  arraysEqual(array1: any[], array2: any[]) {
    if (array1 === array2) return true;
    if (array1 == null || array2 == null) return false;
    if (array1.length !== array2.length) return false;

    for (var i = 0; i < array1.length; ++i) {
      if (array1[i].name !== array2[i].name) return false;
    }
    return true;
  }



  /**
* Description: get total devices Api call
* @description copy domain url to clipboard
*/

  // getTotalDevices() {
  //   this._sub.add(this.dashboardService.getTotalDevices().subscribe(
  //     ((res) => {
  //       this.totalDevices = res;
  //     }),
  //     (error => {
  //       console.log('error', error);
  //     })
  //   ));
  // }
  /**
* Description: get total blocklock api call
* @description get total blocklock api call
*/
  // getTotalBlocklock() {
  //   this._sub.add(this.dashboardService.getTotalBlocklock().subscribe(
  //     ((res: any) => {
  //       // console.log('res', res);
  //       this.totalBlocklock = res;
  //       if (this.totalBlocklock != 0)
  //         this.edgePercentage = ((this.edgeActiveCount / this.totalBlocklock) * 100).toFixed(2);
  //       else
  //         this.edgePercentage = 0;
  //       if (this.edgePercentage === NaN) {
  //         this.edgePercentage = 0;
  //       }
  //       // const progressStyle = this.edgePercentage + '%';
  //       // $(".progress-bar").css("width", progressStyle);
  //     }),
  //     (error => {
  //       console.log('error', error);
  //     })
  //   ));
  // }

  /**
* Description: get total provisions api call
* @description get total provisions api call
*/
  // getTotalProvisons() {
  //   this._sub.add(this.dashboardService.getTotalProvisons().subscribe(
  //     ((res) => {
  //       this.totalProvisons = res;
  //     }),
  //     (error => {
  //       console.log('error', error);
  //     })
  //   ));
  // }

  /**
* Description: get all devices api call
* @description get all devices api call
*/
  // getAllDeviceList() {
  //   this._sub.add(this.endPointService.getEndPointByStatus(false).subscribe(
  //     ((res) => {
  //       // let tempRes = res;
  //       this.deviceData = res;
  //     }),
  //     (error => {
  //       console.log('error', error);
  //       this.toastr.error(`${this.constantVariables.ENDPOINT.LABEL2} cannot be retrieved at this moment.`, ' ', {
  //         timeOut: 3000
  //       });
  //     })
  //   ));
  // }

  /**
* Description:get total active endpoints api call
* @description get total active endpoints api call
*/
  // getActiveEndpoints() {
  //   this._sub.add(this.reportsService.getReportCounts('endPoint').subscribe(
  //     ((res) => {
  //       // console.log('res', res);
  //       // let tempRes = res;
  //       // this.endPointCount = res;
  //     }),
  //     (error => {
  //       console.log('error', error);
  //     })
  //   ));
  // }

  /**
   * Description: get events api call
   * @description get events api call
   */

  // getAllEventList(refresh?: boolean) {
  //   this.refreshEventLog = refresh;
  //   this._sub.add(this.reportsService.getReportInfo('event').subscribe(
  //     ((res: any) => {
  //       this.eventLogData = [];
  //       setTimeout(() => {
  //         this.eventLogData = res?.items?.slice(0, this.constantVariables.limit);
  //         try {
  //           for (let i = 0; i < this.eventLogData.length; i++) {
  //             this.eventLogData[i].time = new Date(this.eventLogData[i].timeStamp * 1000);
  //             this.eventLogData[i].deviceUUID = this.eventLogData[i].deviceUUID.replace(/[^a-z0-9]|\s+|\r?\n|\r/gmi, '');
  //             const parsedData = this.commonService.parseEventPayload(this.eventLogData[i].payload);
  //             if (parsedData) {
  //               this.eventLogData[i] = { ...this.eventLogData[i], ...parsedData };
  //             } else { this.eventLogData.splice(i, 1); }

  //             if (i === (this.eventLogData.length - 1) && this.refreshEventLog) {
  //               this.refreshEventLog = false;
  //             }
  //           }
  //         } catch (error) { }
  //       }, 0);

  //     }),
  //     (error => {
  //       console.log('error', error);
  //     })
  //   ));
  // }

  /**
* Description: get all new users request call api
* @description get all new users request call api
*/
  // getAllNewUsersList() {
  //   this._sub.add(this.dashboardService.getAllUsers().subscribe(
  //     ((res) => {
  //       // let tempRes = res;
  //       this.userList = res;
  //     }),
  //     (error => {
  //       console.log('error', error);
  //       this.toastr.error('Users cannot be retrieved at this moment.', ' ', {
  //         timeOut: 3000
  //       });
  //     })
  //   ));
  // }

  /**
* Description: update device api calls
* @description update device api calls
*/
  // updateDevice(row) {
  //   this.isMapLoading = true;
  //   row.data.active = true;
  //   // $('#button'+ row.index).css('display','none');
  //   // $('#tick'+ row.index).css('display','inline-block');
  //   this.spinner.show();
  //   this._sub.add(this.endPointService.updateEndpoint(row).subscribe(
  //     ((res) => {
  //       // console.log('res', res);
  //       $('#button' + row.index).css('display', 'none');
  //       $('#tick' + row.index).css('display', 'inline-block');
  //       this.toastr.success(row.data.model_name + ' Activated Successfully', ' ', {
  //         timeOut: 3000
  //       });

  //       setTimeout(() => {
  //         this.getAllEndPoints();
  //         this.spinner.hide();
  //       }, 5000);
  //     }),
  //     (error => {
  //       console.log('error', error);
  //       this.toastr.error('Device details can\'t be updated at the moment. Please try again.', ' ', {
  //         timeOut: 3000
  //       });
  //     })
  //   ));
  // }

  /**
 * Description: update edge api call
 * @description   update edge api call
 */
  // updateEdge(row) {
  //   row.data.active = true;
  //   this._sub.add(this.edgeService.updateEdge(row).subscribe(
  //     (res) => {
  //       console.log('res', res);
  //       $('#edgebutton' + row.index).css('display', 'none');
  //       $('#edgetick' + row.index).css('display', 'inline-block');
  //       this.toastr.success((row.data.name).substring(0, 50) + '... Activated Successfully', ' ', {
  //         timeOut: 3000,
  //       });
  //       this.getAllEdgeList();
  //     },
  //     (error) => {
  //       console.log('error', error);
  //       this.toastr.error('Edge controller can\'t be activated at the moment. Please try again.', ' ', {
  //         timeOut: 3000,
  //       });
  //     }
  //   ));
  // }


  // Map
  // click(event: google.maps.MouseEvent) {
  //   console.log(event)
  // }

  /**
* Description: plot marker on maps
* @description plot marker on maps
*/
  // addMarker() {
  //   this.markers.push({
  //     position: {
  //       lat: this.center.lat + ((Math.random() - 0.5) * 2) / 10,
  //       lng: this.center.lng + ((Math.random() - 0.5) * 2) / 10,
  //     },
  //     label: {
  //       color: 'red',
  //       text: 'Marker label ' + (this.markers.length + 1),
  //     },
  //     title: 'ALS ' + (this.markers.length + 1),
  //     info: 'Sensor' + (this.markers.length + 1),
  //     options: {
  //       animation: google.maps.Animation.BOUNCE,
  //     },
  //   })
  // }

  // openInfo(marker: MapMarker, content) {
  //   this.infoContent = content
  //   this.info.open(marker)
  // }


  // openInfo(marker, MapMarker, content) {
  //   this.infoContent = content
  //   this.info.open(marker)
  // }

  /**
 * Description: get active edges api call
 * @description get active edges api call
 */
  // getActiveEdge(type) {
  //   this._sub.add(this.reportsService.getReportCounts(type).subscribe(
  //     ((res) => {
  //       // console.log('res', res);
  //       // let tempRes = res;
  //       this.edgeActiveCount = res;
  //     }),
  //     (error => {
  //       console.log('error', error);
  //     })
  //   ));
  // }

  /**
   * Description: filter according time basis of Total Events Graph
   * @description filter according time basis of Total Events Graph
   * @param type
   */
  // filterTotalEventsGraph() {
  //   // console.log(type);
  //   this.filterTotalEventsDuration = this.filterTotalEvents;
  //   clearTimeout(this.setTimeOutSubscription);
  //   this.getAllDashboardData();
  //   // Area Chart - Total counts of events
  //   // let xAxisArr
  //   // let yAxisArr
  //   // if (type == 'hourly') {
  //   //   xAxisArr = ['0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'];
  //   //   this.dashboardService.getTotalEventByDuration(type).subscribe(
  //   //     ((res) => {
  //   //       // console.log('res', res);
  //   //       // let tempRes = res;
  //   //       yAxisArr = res;
  //   //       this.plotTotalEventGraph(xAxisArr, yAxisArr, 'Hours');
  //   //     }),
  //   //     (error => {
  //   //       console.log('error', error);
  //   //     })
  //   //   );
  //   // }
  //   // else if (type == 'daily') {
  //   //   xAxisArr = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  //   //   this.dashboardService.getTotalEventByDuration(type).subscribe(
  //   //     ((res) => {
  //   //       // console.log('res', res);
  //   //       // let tempRes = res;
  //   //       yAxisArr = res;
  //   //       this.plotTotalEventGraph(xAxisArr, yAxisArr, 'Days');
  //   //     }),
  //   //     (error => {
  //   //       console.log('error', error);
  //   //     })
  //   //   );
  //   // }
  //   // else if (type == 'weekly') {
  //   //   this.dashboardService.getTotalEventByDuration(type).subscribe(
  //   //     ((res) => {
  //   //       // console.log('res', res);
  //   //       // let tempRes = res;
  //   //       yAxisArr = res;
  //   //       xAxisArr = [];
  //   //       for( let i = 1 ; i<=yAxisArr.length ; i++) {
  //   //         xAxisArr.push('Week ' + i)
  //   //       }
  //   //       this.plotTotalEventGraph(xAxisArr, yAxisArr, 'Weeks');
  //   //     }),
  //   //     (error => {
  //   //       console.log('error', error);
  //   //     })
  //   //   );
  //   // }
  // }


  // filterTotalEventsGraphSocket(data) {
  //   // Area Chart - Total counts of events
  //   let xAxisArr: any[] = [];
  //   let yAxisArr: any[] = [];
  //   if (this.filterTotalEventsDuration == 'hourly') {
  //     // xAxisArr = ['0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'];
  //     // yAxisArr = data;
  //     xAxisArr = data['xAxis'];
  //     yAxisArr = data['ArrayEvent'];
  //     this.plotTotalEventGraph(xAxisArr, yAxisArr, '');
  //   }
  //   else if (this.filterTotalEventsDuration == 'daily') {
  //     // xAxisArr = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  //     // yAxisArr = data;
  //     xAxisArr = data['xAxis'];
  //     yAxisArr = data['ArrayEvent'];
  //     this.plotTotalEventGraph(xAxisArr, yAxisArr, '');
  //   }
  //   else if (this.filterTotalEventsDuration == 'weekly') {
  //     // yAxisArr = data;
  //     // xAxisArr = [];
  //     // for( let i = 1 ; i<=yAxisArr.length ; i++) {
  //     //   xAxisArr.push('Week ' + i)
  //     // }

  //     yAxisArr = data['ArrayEvent'];
  //     const xaxs = data['xAxis'];
  //     xAxisArr = [];
  //     for (let i = 0; i < xaxs.length; i++) {
  //       xAxisArr.push('Week ' + xaxs[i])
  //     }
  //     this.plotTotalEventGraph(xAxisArr, yAxisArr, '');
  //   }
  // }

  /**
   * Description: plot total events graph
   * @description plot Total Events Graph
   * @param x xaxis data
   * @param y yaxis data
   * @param xlable  xaxis lable
   */
  // plotTotalEventGraph(x, y, xlable) {
  //   let maxVal = GRAPH_LIMIT.MAX;
  //   let maxEventValue = Math.max(...y);
  //   if (maxVal < maxEventValue)
  //     maxVal = maxEventValue + Math.round(maxEventValue * GRAPH_LIMIT.PERCENTAGE_INCREASE / 100);
  //   this.optionevents = {
  //     backgroundColor: '',
  //     grid: {
  //       top: 20,
  //       left: '8%',
  //       right: '4%',
  //       bottom: '8%',
  //       width: 'auto',
  //       height: 'auto',
  //       containLabel: true,
  //     },
  //     tooltip: {
  //       trigger: 'axis',
  //       axisPointer: {
  //         type: 'shadow'
  //       }
  //     },
  //     xAxis: {
  //       type: 'category',
  //       // boundaryGap: false,
  //       data: x,
  //       axisLabel: {
  //         show: true,
  //         fontSize: 10
  //       },
  //       name: xlable,
  //       nameLocation: 'middle',
  //       nameGap: 26,
  //       nameTextStyle: {
  //         color: '#666',
  //         fontSize: 11,
  //         fontWeight: 'normal',
  //       },
  //       axisTick: {
  //         show: true
  //       },
  //       lineStyle: {
  //         color: '#fff'
  //       },
  //       axisLine: {
  //         show: true,
  //       },
  //     },
  //     yAxis: {
  //       type: 'value',
  //       min: GRAPH_LIMIT.MIN,
  //       max: maxVal,
  //       splitNumber: 5,
  //       axisLine: {
  //         show: true
  //       },
  //       minInterval: 1,
  //       lineStyle: {
  //         color: '#666'
  //       },
  //       axisTick: { show: false },
  //       axisLabel: {
  //         show: true,
  //         fontSize: 9
  //       },
  //       name: 'No. of Events',
  //       nameLocation: 'middle',
  //       nameGap: 30,
  //       nameTextStyle: {
  //         color: '#666',
  //         fontSize: 11,
  //         fontWeight: 'normal',
  //       },
  //     },
  //     series: [{
  //       data: y,
  //       type: 'line',
  //       smooth: true,
  //       areaStyle: {}
  //     }]
  //   };
  // }

  /**
   * description: plot sparkline
   * @description: plot sparkline
   * @param type
   */
  // getSparkLineGraph(type) {
  //   this._sub.add(this.dashboardService.getSparklineGraph(type).subscribe(
  //     ((res: any[]) => {
  //       switch (type) {
  //         case 'totalEndpoints':
  //           this.endpointSparklineArr = getEchartConfig(res, '#dc3545', '#D48265');
  //           break;
  //         case 'provisioningQueue':
  //           this.provisioningSparklineArr = getEchartConfig(res, '#A668FD', '#DFBAE6');
  //           break;
  //         case 'events':
  //           this.eventsSparklineArr = getEchartConfig(res, '#0657af', '#007bff');
  //           break;
  //         default:
  //           break;
  //       }
  //     }),
  //     (error => {
  //       console.log('error', error);
  //     })
  //   ));
  // }

  /**
  * Description: filter according time basis of Total Devices Graph
  * @description filter according time basis of Total Devices Graph
  * @param type
  */
  // filterTotalDevicesGraph() {
  //   this.filterTotalDevicesDuration = this.filterTotalDevices;
  //   clearTimeout(this.setTimeOutSubscription);
  //   this.getAllDashboardData();
  //   // Area Chart - Total counts of events
  //   // let xAxisArr
  //   // let yAxisArrEdge
  //   // let yAxisArrEnd
  //   // if (type == 'hourly') {
  //   //   xAxisArr = ['0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'];
  //   //   this.dashboardService.getTotalDevicesGraph(type).subscribe(
  //   //     ((res) => {
  //   //       console.log('res', res);
  //   //       // let tempRes = res;
  //   //       yAxisArrEdge = res['ArrayEdge'];
  //   //       yAxisArrEnd = res['ArrayEnd'];
  //   //       this.plotTotalDevicesGraph(xAxisArr, yAxisArrEdge, yAxisArrEnd, 'Hours');
  //   //     }),
  //   //     (error => {
  //   //       console.log('error', error);
  //   //     })
  //   //   );
  //   // }
  //   // else if (type == 'daily') {
  //   //   xAxisArr = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  //   //   this.dashboardService.getTotalDevicesGraph(type).subscribe(
  //   //     ((res) => {
  //   //       console.log('res', res);
  //   //       yAxisArrEdge = res['ArrayEdge'];
  //   //       yAxisArrEnd = res['ArrayEnd'];
  //   //       // let tempRes = res;
  //   //       this.plotTotalDevicesGraph(xAxisArr, yAxisArrEdge, yAxisArrEnd, 'Days');
  //   //     }),
  //   //     (error => {
  //   //       console.log('error', error);
  //   //     })
  //   //   );
  //   // }
  //   // else if (type == 'weekly') {
  //   //   this.dashboardService.getTotalDevicesGraph(type).subscribe(
  //   //     ((res) => {
  //   //       console.log('res', res);
  //   //       // let tempRes = res;
  //   //       yAxisArrEdge = res['ArrayEdge'];
  //   //       yAxisArrEnd = res['ArrayEnd'];
  //   //       xAxisArr = [];
  //   //       for( let i = 1 ; i<=yAxisArrEnd.length ; i++) {
  //   //         xAxisArr.push('Week ' + i)
  //   //       }
  //   //       this.plotTotalDevicesGraph(xAxisArr, yAxisArrEdge, yAxisArrEnd, 'Weeks');
  //   //     }),
  //   //     (error => {
  //   //       console.log('error', error);
  //   //     })
  //   //   );
  //   // }
  // }

  // filterTotalDevicesGraphSocket(data) {
  //   // let xAxisArr
  //   // let yAxisArrEdge
  //   // let yAxisArrEnd
  //   // let xAxisArr: any[] = [];
  //   // let yAxisArrEdge: any[] = [];
  //   // let yAxisArrEnd: any[] = [];

  //   // if (this.filterTotalDevicesDuration == 'hourly') {
  //   //   xAxisArr = data['endXAxis'];
  //   //   yAxisArrEdge = data['ArrayEdge'];
  //   //   yAxisArrEnd = data['ArrayEnd'];
  //   //   this.plotTotalDevicesGraph(xAxisArr, yAxisArrEdge, yAxisArrEnd, '');
  //   // }
  //   // else if (this.filterTotalDevicesDuration == 'daily') {
  //   //   // xAxisArr = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  //   //   xAxisArr = data['dayAxis'];
  //   //   yAxisArrEdge = data['ArrayEdge'];
  //   //   yAxisArrEnd = data['ArrayEnd'];
  //   //   this.plotTotalDevicesGraph(xAxisArr, yAxisArrEdge, yAxisArrEnd, '');
  //   // }
  //   // else if (this.filterTotalDevicesDuration == 'weekly') {
  //   //   yAxisArrEdge = data['ArrayEdge'];
  //   //   yAxisArrEnd = data['ArrayEnd'];
  //   //   const xaxs = data['weekAxis'];
  //   //   xAxisArr = [];
  //   //   for (let i = 0; i < xaxs.length; i++) {
  //   //     xAxisArr.push('Week ' + xaxs[i])
  //   //   }
  //   //   this.plotTotalDevicesGraph(xAxisArr, yAxisArrEdge, yAxisArrEnd, '');
  //   // }
  // }

  /**
* Description: plot Total Devices Graph
* @description plot Total Devices Graph
* @param x xaxis data
* @param yEdge yaxis edge data
* @param yEnd yaxis end data
* @param xlable xaxis lable
*/
  // plotTotalDevicesGraph() {
  //   this.optiondevices = {
  //     xAxis: {
  //       type: 'category',
  //       data: ['Started Shipments', 'Completed Shipments']
  //     },
  //     yAxis: {
  //       type: 'value',
  //     },
  //     series: [
  //       {
  //         data: [
  //           {
  //             value: this.startedShipments,
  //             itemStyle: {
  //               color: '#61A0A8'
  //             }
  //           },
  //           {
  //             value: this.completedShipments,
  //             itemStyle: {
  //               color: '#304554'
  //             }
  //           },
  //         ],
  //         type: 'bar'
  //       }
  //     ]
  //   };
  // }

  /**
  * Description: get data for average sensors events
  * @description get data for average sensors events
  */
  // filterAverageSensorEvents() {
  //   this.filterAverageSensorEventsDuration = this.filterAvgSensorEvents;
  //   this.filterAverageSensorEventsSensorName = this.selectedSensor;
  //   this.getAllDashboardData();
  //   // let xAxisArr;
  //   // let yAxisArr;
  //   // if (type == 'hourly') {
  //   //   xAxisArr = ['0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'];
  //   //   this.dashboardService.getAverageSensorGraph(type, sensorName).subscribe(
  //   //     ((res) => {
  //   //       console.log('res', res);
  //   //       // let tempRes = res;
  //   //       yAxisArr = res
  //   //       this.plotAverageSensorEvents(xAxisArr, yAxisArr, 'Hours');
  //   //     }),
  //   //     (error => {
  //   //       console.log('error', error);
  //   //     })
  //   //   );
  //   // }
  //   // else if (type == 'daily') {
  //   //   xAxisArr = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  //   //   this.dashboardService.getAverageSensorGraph(type, sensorName).subscribe(
  //   //     ((res) => {
  //   //       console.log('res', res);
  //   //       yAxisArr = res;
  //   //       this.plotAverageSensorEvents(xAxisArr, yAxisArr, 'Days');
  //   //       // let tempRes = res;
  //   //     }),
  //   //     (error => {
  //   //       console.log('error', error);
  //   //     })
  //   //   );
  //   // }
  //   // else if (type == 'weekly') {
  //   //   this.dashboardService.getAverageSensorGraph(type, sensorName).subscribe(
  //   //     ((res) => {
  //   //       console.log('res', res);
  //   //       // let tempRes = res;
  //   //       yAxisArr = res
  //   //       xAxisArr = [];
  //   //       for( let i = 1 ; i<=yAxisArr.length ; i++) {
  //   //         xAxisArr.push('Week ' + i)
  //   //       }
  //   //       this.plotAverageSensorEvents(xAxisArr, yAxisArr, 'Weeks');
  //   //     }),
  //   //     (error => {
  //   //       console.log('error', error);
  //   //     })
  //   //   );
  //   // }

  // }

  // filterAverageSensorEventsSocket(data) {

  //   let xAxisArr: any[] = [];
  //   let yAxisArr: any[] = [];
  //   if (this.filterAverageSensorEventsDuration == 'hourly') {
  //     xAxisArr = data['xAxis'];
  //     yAxisArr = data['Shipments'];
  //     this.plotAverageSensorEvents(xAxisArr, yAxisArr, '', data['ShipmentName']);
  //   }
  //   else if (this.filterAverageSensorEventsDuration == 'daily') {
  //     xAxisArr = data['xAxis'];
  //     yAxisArr = data['Shipments'];
  //     this.plotAverageSensorEvents(xAxisArr, yAxisArr, '', data['ShipmentName']);
  //   }
  //   else if (this.filterAverageSensorEventsDuration == 'weekly') {
  //     // yAxisArr = data
  //     // xAxisArr = [];
  //     // for( let i = 1 ; i<=yAxisArr.length ; i++) {
  //     //   xAxisArr.push('Week ' + i)
  //     // }
  //     const xAxisArrTemp = data['xAxis'];
  //     yAxisArr = data['Shipments'];
  //     xAxisArr = [];
  //     for (let i = 0; i < xAxisArrTemp.length; i++) {
  //       xAxisArr.push('Week ' + xAxisArrTemp[i])
  //     }
  //     this.plotAverageSensorEvents(xAxisArr, yAxisArr, '', data['ShipmentName']);
  //   }
  // }

  /**
   * Description: plot averagesensorevents graph
   * @description plot averagesensorevents graph
   * @param event
   */
  // plotAverageSensorEvents(x, y, xlable, ylabel) {
  //   //Bar Graph - Avg count of EndPoints Events
  //   let maxVal = GRAPH_LIMIT.MAX;
  //   let maxEvents = 0;
  //   let yaxis = [];
  //   y.map((shipments, index) => {
  //     if (ylabel[index]) {
  //       yaxis.push({
  //         name: ylabel && ylabel[index] ? ylabel[index] : null,
  //         type: 'line',
  //         smooth: true,
  //         barWidth: '40%',
  //         data: shipments,
  //         areaStyle: { opacity: 0.5 },
  //         // emphasis: {
  //         //   focus: 'series'
  //         // },
  //       });
  //     }

  //     if (Math.max(...shipments) > maxEvents)
  //       maxEvents = Math.max(...shipments)
  //   })
  //   if (maxVal < maxEvents)
  //     maxVal = maxEvents + Math.round(maxEvents * GRAPH_LIMIT.PERCENTAGE_INCREASE / 100);
  //   this.optionendpt = {
  //     tooltip: {
  //       trigger: 'axis',
  //       axisPointer: {
  //         type: 'shadow'
  //       }
  //     },
  //     grid: {
  //       top: 20,
  //       left: '8%',
  //       right: '4%',
  //       bottom: '8%',
  //       width: 'auto',
  //       height: 'auto',
  //       containLabel: true,
  //     },
  //     xAxis: [
  //       {
  //         type: 'category',
  //         data: x,
  //         splitLine: {
  //           show: false
  //         },
  //         axisLabel: {
  //           show: true,
  //           fontSize: 10
  //         },
  //         name: xlable,
  //         nameLocation: 'middle',
  //         nameGap: 26,
  //         nameTextStyle: {
  //           color: '#666',
  //           fontSize: 11,
  //           fontWeight: 'normal',
  //         },
  //         axisTick: {
  //           show: true
  //         },
  //         lineStyle: {
  //           color: '#fff'
  //         },
  //         axisLine: {
  //           show: true,
  //         },
  //       }
  //     ],
  //     yAxis: {
  //       type: 'value',
  //       min: GRAPH_LIMIT.MIN,
  //       max: maxVal,
  //       axisLine: {
  //         show: true
  //       },
  //       minInterval: 1,
  //       lineStyle: {
  //         color: '#fff'
  //       },
  //       axisTick: { show: false },
  //       axisLabel: {
  //         show: true,
  //         fontSize: 9
  //       },
  //       name: this.selectedSensor,
  //       nameLocation: 'middle',
  //       nameGap: 40,
  //       nameTextStyle: {
  //         color: '#666',
  //         fontSize: 11,
  //         fontWeight: 'normal',
  //       },
  //     },
  //     series: yaxis
  //   };
  // }

  /**
* Description: get data for average sensors events
* @description get data for average sensors events
*/
  // filterSensorEvents() {
  //   this.filterSensorEventsDuration = this.filterBySensorEvents;
  //   this.filterSensorEventsSensorName = this.selectedSensorEvt;
  //   clearTimeout(this.setTimeOutSubscription);
  //   this.getAllDashboardData();

  //   // let xAxisArr;
  //   // let yAxisArr;
  //   // if (type == 'hourly') {
  //   //   xAxisArr = ['0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'];
  //   //   this.dashboardService.getSensorEvents(type, sensorName).subscribe(
  //   //     ((res) => {
  //   //       console.log('res', res);
  //   //       // let tempRes = res;
  //   //       yAxisArr = res
  //   //       this.plotSensorEvents(xAxisArr, yAxisArr, 'Hours');
  //   //     }),
  //   //     (error => {
  //   //       console.log('error', error);
  //   //     })
  //   //   );
  //   // }
  //   // else if (type == 'daily') {
  //   //   xAxisArr = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  //   //   this.dashboardService.getSensorEvents(type, sensorName).subscribe(
  //   //     ((res) => {
  //   //       console.log('res', res);
  //   //       yAxisArr = res;
  //   //       this.plotSensorEvents(xAxisArr, yAxisArr, 'Days');
  //   //       // let tempRes = res;
  //   //     }),
  //   //     (error => {
  //   //       console.log('error', error);
  //   //     })
  //   //   );
  //   // }
  //   // else if (type == 'weekly') {
  //   //   this.dashboardService.getSensorEvents(type, sensorName).subscribe(
  //   //     ((res) => {
  //   //       console.log('res', res);
  //   //       // let tempRes = res;
  //   //       yAxisArr = res
  //   //       xAxisArr = [];
  //   //       for( let i = 1 ; i<=yAxisArr.length ; i++) {
  //   //         xAxisArr.push('Week ' + i)
  //   //       }
  //   //       this.plotSensorEvents(xAxisArr, yAxisArr, 'Weeks');
  //   //     }),
  //   //     (error => {
  //   //       console.log('error', error);
  //   //     })
  //   //   );
  //   // }

  // }

  // filterSensorEventsSocket(data) {
  //   let xAxisArr: any[] = [];
  //   let yAxisArr: any[] = [];
  //   if (this.filterSensorEventsDuration == 'hourly') {
  //     xAxisArr = data['xAxis'];
  //     yAxisArr = data['Data'];
  //     this.plotSensorEvents(xAxisArr, yAxisArr, '', data['ShipmentName']);
  //   }
  //   else if (this.filterSensorEventsDuration == 'daily') {
  //     // xAxisArr = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  //     // yAxisArr = data
  //     xAxisArr = data['xAxis'];
  //     yAxisArr = data['Data'];
  //     this.plotSensorEvents(xAxisArr, yAxisArr, '', data['ShipmentName']);
  //   }
  //   else if (this.filterSensorEventsDuration == 'weekly') {
  //     // yAxisArr = data
  //     // xAxisArr = [];
  //     // for( let i = 1 ; i<=yAxisArr.length ; i++) {
  //     //   xAxisArr.push('Week ' + i)
  //     // }

  //     yAxisArr = data['Data'];
  //     const xaxs = data['xAxis'];
  //     xAxisArr = [];
  //     for (let i = 0; i < xaxs.length; i++) {
  //       xAxisArr.push('Week ' + xaxs[i])
  //     }
  //     this.plotSensorEvents(xAxisArr, yAxisArr, '', data['ShipmentName']);
  //   }

  // }

  /**
   * @description
   * @param event
   */
  // plotSensorEvents(x, y, xlable, ylabel?) {
  //   let maxVal = GRAPH_LIMIT.MAX;
  //   let maxEvents = 0;
  //   let yaxis = [];
  //   y.map((shipments, index) => {
  //     if (ylabel && ylabel[index]) {
  //       yaxis.push({
  //         name: ylabel && ylabel[index] ? ylabel[index] : null,
  //         type: 'line',
  //         smooth: true,
  //         barWidth: '40%',
  //         data: shipments,
  //         areaStyle: { opacity: 0.5 },
  //         // emphasis: {
  //         //   focus: 'series'
  //         // },
  //       })
  //       if (Math.max(...shipments) > maxEvents)
  //         maxEvents = Math.max(...shipments)
  //     }
  //   })
  //   if (maxVal < maxEvents)
  //     maxVal = maxEvents + Math.round(maxEvents * GRAPH_LIMIT.PERCENTAGE_INCREASE / 100);
  //   this.optionsensorEvents = {
  //     tooltip: {
  //       trigger: 'axis',
  //       axisPointer: {
  //         type: 'shadow'
  //       }
  //     },
  //     grid: {
  //       top: 20,
  //       left: '8%',
  //       right: '4%',
  //       bottom: '8%',
  //       width: 'auto',
  //       height: 'auto',
  //       containLabel: true,
  //     },
  //     xAxis: [
  //       {
  //         type: 'category',
  //         data: x,
  //         splitLine: {
  //           show: false
  //         },
  //         axisLabel: {
  //           show: true,
  //           fontSize: 10
  //         },
  //         name: xlable,
  //         nameLocation: 'middle',
  //         nameGap: 26,
  //         nameTextStyle: {
  //           color: '#666',
  //           fontSize: 11,
  //           fontWeight: 'normal',
  //         },
  //         axisTick: {
  //           show: true
  //         },
  //         lineStyle: {
  //           color: '#fff'
  //         },
  //         axisLine: {
  //           show: true,
  //         },
  //       }
  //     ],
  //     yAxis: [{
  //       type: 'value',
  //       min: GRAPH_LIMIT.MIN,
  //       max: maxVal,
  //       axisLine: {
  //         show: true,
  //       },
  //       minInterval: 1,
  //       lineStyle: {
  //         color: '#666'
  //       },
  //       axisTick: { show: false },
  //       axisLabel: {
  //         show: true,
  //         fontSize: 9
  //       },
  //       name: 'No. of Events',
  //       nameLocation: 'middle',
  //       nameGap: 30,
  //       nameTextStyle: {
  //         color: '#666',
  //         fontSize: 11,
  //         fontWeight: 'normal',
  //       },
  //     }],
  //     series: yaxis
  //   };
  // }

  // getAllEndPoints() {
  //   this._sub.add(this.endPointService.getAllEndPoint().subscribe(
  //     ((res) => {
  //       this.endPointsList = res;
  //       this.allEndPoints = res;
  //       if (this.allEndPoints && this.allEndPoints.length) {
  //         this.getTrackersWithEvents();
  //       }
  //     }),
  //     (error => {
  //       console.log('error', error);
  //       this.toastr.error(`${this.constantVariables.ENDPOINT.LABEL2} cannot be retrieved at this moment. Please try again.`, ' ', {
  //         timeOut: 3000
  //       });

  //     })
  //   ));
  // }

  /**
   * Description: This method will return node map graph
   */
  // getNodeMap() {
  //   this._sub.add(this.nodeMapService.getNodeMap().subscribe((resp) => {
  //     this.options = this.http.get('assets/data/les-miserables.gexf', { responseType: 'text' }).pipe(
  //       map(xml => {
  //         let graph = parse(xml);
  //         if (resp) {
  //           graph = resp;
  //         }
  //         const categories = [
  //           { name: 'Aggregation Node' },
  //           { name: 'Broker Node' },
  //           {
  //             name: 'Slave'
  //           },
  //           {
  //             name: 'End Device'
  //           },
  //           // { name: 'PoE Switch' },

  //           // { name: 'Spicebox' },
  //           // {
  //           //   name: 'Light'
  //           // },
  //           // { name: 'LED' },
  //           // { name: 'Motion' },
  //           // { name: 'Wall Switch' },
  //           // {
  //           //   name: 'Sensors'
  //           // }
  //         ];

  //         graph.nodes.forEach(function (node) {
  //           let classNo = 0;
  //           if (node.name.indexOf("Aggregation Node") != -1) {
  //             classNo = 0;
  //           } else if (node.name.indexOf("Broker Node") != -1) {
  //             classNo = 1;
  //           } else if (node.name.indexOf("Slave") != -1) {
  //             classNo = 2;
  //           } else if (node.name.indexOf("End Device") != -1) {
  //             classNo = 3;
  //           }

  //           //else if (node.name.indexOf("PoE") != -1) {
  //           //   classNo = 1;
  //           // } else if (node.name.indexOf("Spice") != -1) {
  //           //   classNo = 2;
  //           // } else if (node.name.indexOf("ALS") != -1) {
  //           //   classNo = 3;
  //           //   var replacedName = node.name.replace(/^.+:/, 'Light : ');
  //           //   node.name = replacedName;
  //           // } else if (node.name.indexOf("LED") != -1) {
  //           //   classNo = 4;
  //           // } else if (node.name.indexOf("PIR") != -1) {
  //           //   classNo = 5;
  //           //   var replacedName = node.name.replace(/^.+:/, 'Motion : ');
  //           //   node.name = replacedName;
  //           // } else if (node.name.indexOf("6BC") != -1) {
  //           //   classNo = 6;
  //           //   var replacedName = node.name.replace(/^.+:/, 'Wall Switch : ');
  //           //   node.name = replacedName;
  //           // } else {
  //           //   classNo = 7;
  //           // }
  //           node.itemStyle = null;
  //           node.symbolSize = 7;
  //           node.value = node.symbolSize;
  //           node.category = classNo;
  //           // Use random x, y
  //           node.x = node.y = null;
  //           node.draggable = true;
  //         });
  //         return {
  //           tooltip: {
  //             trigger: 'item',
  //             textStyle: {
  //               fontSize: '12',
  //             },
  //             formatter: function (params) {
  //               if (params.dataType == "node") {
  //                 return `${params.name}`;
  //               } else {
  //                 let sourceNode = graph.nodes.find(x => x.id === params.data.source);
  //                 let deviceNameSource = sourceNode.name.split(':');
  //                 let targetNode = graph.nodes.find(x => x.id === params.data.target);
  //                 let deviceNametarget = targetNode.name.split(':');
  //                 if (deviceNameSource[0].indexOf('End Device') != - 1) {
  //                   deviceNameSource[0] = deviceNameSource[0].split(',')[0];
  //                 } else if (deviceNametarget[0].indexOf('End Device') != - 1) {
  //                   deviceNametarget[0] = deviceNametarget[0].split(',')[0];
  //                 }
  //                 return `${deviceNameSource[0]}  > ${deviceNametarget[0]}`;
  //               }
  //             }
  //           },
  //           legend: [{
  //             type: 'scroll',
  //             top: 0,
  //             left: 0,
  //             orient: 'vertical',
  //             itemWidth: 20,
  //             itemHeight: 12,
  //             data: categories.map(function (a) {
  //               return a.name;
  //             })
  //           }] as EChartOption.Legend,
  //           animation: false,
  //           series: [
  //             {
  //               name: '',
  //               type: 'graph',
  //               layout: 'force',
  //               data: graph.nodes,
  //               links: graph.links,
  //               categories: categories,
  //               roam: true,
  //               label: {
  //                 show: false,
  //                 position: "inside",
  //                 fontSize: 1,
  //                 lineHeight: 0,
  //                 color: "rgba(255, 255, 255, 0)",
  //                 cursor: "pointer",
  //               },
  //               force: {
  //                 repulsion: 100
  //               }
  //             }
  //           ]
  //         };
  //       })
  //     );
  //   }, error => {

  //   }));

  // }

  /**
  * Description: get all edge list
  * @description get all edge list
  */
  // refershGraphs() {
  //   this.getAllDashboardData();
  // }

  // getAllEdgeList() {
  //   this._sub.add(this.edgeService.getAllEdgeByStatus(false).subscribe(
  //     ((res) => {
  //       this.provisionEdgeList = res;
  //     }),
  //     (error => {
  //       console.log('error', error);
  //     })
  //   ));
  //   this._sub.add(this.edgeService.getAllEdge().subscribe(
  //     ((res) => {
  //       this.edgeList = res;
  //     }),
  //     (error => {
  //       console.log('error', error);
  //     })
  //   ));
  // }
  // public filterSelectedTracker(shipmentId: string) {
  //   if (this.shipmentList.length) {
  //     this.trackerList = [];
  //     this.selectedTracker = '';
  //     this.selectedTrackerObj = '';
  //     this.sensorsList = [];
  //     this.selectedSensor = '';
  //     this.selectedSensorObj = '';
  //     this.shipmentList.find((resp: any) => {
  //       if (resp.id === shipmentId) {
  //         const tempTrackerList = resp.trackers ? resp.trackers : []
  //         tempTrackerList.filter((item: any) => item.data.active === true)
  //         this.trackerList = tempTrackerList;
  //         this.selectedShipment = shipmentId;
  //         this.selectedShipmentObj = resp;
  //       }
  //     })
  //     console.log('this.trackerList', this.trackerList);
  //   }
  //   this.filterAverageSensorEvents()
  // }

  // public filterSelctedSensor(trackerId: string) {
  //   if (this.trackerList.length) {
  //     this.sensorsList = [];
  //     this.selectedSensor = '';
  //     this.selectedSensorObj = '';
  //     this.trackerList.find((resp: any) => {
  //       if (resp.id === trackerId) {
  //         const tempSensorsList = resp.sensors ? resp.sensors : []
  //         tempSensorsList.filter((item: any) => item.assign === true)
  //         this.sensorsList = tempSensorsList;
  //         this.selectedTracker = trackerId;
  //         this.selectedTrackerObj = resp;
  //       }
  //     })
  //     console.log('this.sensorsList', this.sensorsList);
  //   }
  //   this.filterAverageSensorEvents()
  // }

  // public selectedSensorId(sensorId: string) {
  //   this.sensorsList.find((resp: any) => {
  //     if (resp.id === sensorId) {
  //       this.selectedSensor = sensorId;
  //       this.selectedSensorObj = resp;
  //       // this.plotAverageSensorEvents([], [], []);
  //       this.getAllDashboardData()
  //     }
  //   })
  // }

  // public selectShipmentForSE(shipmentId: string) {
  //   this.trackerListForSE = [];
  //   this.selectedTrackerForSE = '';
  //   this.selectedTrackerObjForSE = '';
  //   this.sensorsListForSE = [];
  //   this.selectedSensorForSE = '';
  //   this.selectedSensorObjForSE = '';
  //   this.shipmentList.find((resp: any) => {
  //     if (resp.id === shipmentId) {
  //       const tempTrackerList = resp.trackers ? resp.trackers : []
  //       tempTrackerList.filter((item: any) => item.data.active === true)
  //       this.trackerListForSE = tempTrackerList;
  //       this.selectedShipmentObj = resp;
  //       this.selectedShipmentForSE = shipmentId;
  //       this.getAllDashboardData();
  //     }
  //   })
  // }

  // public selecteTrackerForSE(trackerId: string) {
  //   this.sensorsListForSE = [];
  //   this.selectedSensorForSE = '';
  //   this.selectedSensorObjForSE = '';
  //   this.trackerListForSE.find((resp: any) => {
  //     if (resp.id === trackerId) {
  //       const tempSensorsList = resp.sensors ? resp.sensors : []
  //       tempSensorsList.filter((item: any) => item.assign === true)
  //       this.sensorsListForSE = tempSensorsList;
  //       this.selectedTrackerForSE = trackerId;
  //       this.selectedTrackerObjForSE = resp;
  //       this.getAllDashboardData();
  //     }
  //   })
  //   console.log('this.sensorsListForSE', this.sensorsListForSE);
  // }

  // public filterSelctedSensorForSE(sensorId: string) {
  //   this.sensorsListForSE.find((resp: any) => {
  //     if (resp.id === sensorId) {
  //       this.selectedSensorForSE = sensorId;
  //       this.selectedSensorObjForSE = resp;
  //       this.getAllDashboardData();
  //     }
  //   })
  // }

  /**Heatmap functions */
  // public changeGradient(): void {
  //   const gradient = [
  //     'rgba(0, 255, 255, 0)',
  //     'rgba(0, 255, 255, 1)',
  //     'rgba(0, 191, 255, 1)',
  //     'rgba(0, 127, 255, 1)',
  //     'rgba(0, 63, 255, 1)',
  //     'rgba(0, 0, 255, 1)',
  //     'rgba(0, 0, 223, 1)',
  //     'rgba(0, 0, 191, 1)',
  //     'rgba(0, 0, 159, 1)',
  //     'rgba(0, 0, 127, 1)',
  //     'rgba(63, 0, 91, 1)',
  //     'rgba(127, 0, 63, 1)',
  //     'rgba(191, 0, 31, 1)',
  //     'rgba(255, 0, 0, 1)'
  //   ];
  //   this.heatmap.set('gradient', this.heatmap.get('gradient') ? null : gradient);
  // }

  // public changeOpacity(): void {
  //   this.heatmap.set('opacity', this.heatmap.get('opacity') ? null : 0.25);
  // }

  // public changeRadius(): void {
  //   this.heatmap.set('radius', this.heatmap.get('radius') ? null : 20);
  // }

  // public toggleHeatmap(): void {
  //   this.heatmap.setMap(this.heatmap.getMap() ? null : this.map);
  // }
  // public resetFocus(): void {
  //   const setMapType = this.map.get('mapTypeId');
  //   // this.latitude = 0;
  //   // this.longitude = 0;
  //   this.zoom = 3;
  // }

  // public setMapTypeId(mType: string) {
  //   this.mapType = mType === 'roadmap' ? 'satellite' : 'roadmap';
  //   this.mapView = this.mapType === 'roadmap' ? 'Satellite View' : 'Map View';
  //   this.map.set('mapTypeId', this.mapType);
  // }
  /**
  * Description: get endpoint list associated with seleted edge
  * @description get endpoint list associated with seleted edge
  */
  // filterSelectedEdgeEndpointAvgSensor(selectedEdgeAvgSensor) {
  //   try {
  //     if (this.allEndPoints && this.allEndPoints.length > 0)
  //       this.edgeEndPointListAvgSensor = this.allEndPoints.filter(x => x.data.blSerialNumber == selectedEdgeAvgSensor);
  //     this.refershGraphs();
  //   } catch (e) {
  //     console.log('error filterSelectedEdgeEndpointAvgSensor', e);
  //   }
  // }

  /**
  * Description: get endpoint list associated with seleted edge
  * @description get endpoint list associated with seleted edge
  */
  // filterSelectedEdgeEndpointSensor(selectedEdgeSensor) {
  //   try {
  //     if (this.allEndPoints && this.allEndPoints.length > 0)
  //       this.edgeEndPointListSensor = this.allEndPoints.filter(x => x.data.blSerialNumber == selectedEdgeSensor);
  //     this.refershGraphs();
  //   } catch (e) {
  //     console.log('error filterSelectedEdgeEndpointSensor', e);
  //   }
  // }

  private filterShipment(shipments) {
    // Calculate the timestamp for 24 hours ago
    var twentyFourHoursAgo = (new Date().getTime() - (30 * 24 * 60 * 60 * 1000));
    let shipment = []; 
    // Return shipments based on the active state of the device.
    if (SHOWING_SHIPMENTS.startedOnly) {
      const isShipmentStarted = (shipment) => shipment.status === 'STARTED';
      const hasValidTracker = (shipment) => shipment?.trackers?.length && shipment.trackers[0]?.latestEvent?.timeStamp;
      const getThresholdTimestamp = (shipment) => {
        const freq = Number(shipment.trackers[0]?.freq);
        return (freq < (20 * 60))
          ? twentyFourHoursAgo
          : twentyFourHoursAgo - (freq * 60 * 1000);
      };

      const isLatestEventWithinThreshold = (shipment) => {
        const latestEventTimestamp = shipment.trackers[0]?.latestEvent?.timeStamp;
        return latestEventTimestamp > getThresholdTimestamp(shipment) / 1000;
      };

      const filteredShipments = shipments.items.filter((shipment) =>
        isShipmentStarted(shipment) && hasValidTracker(shipment) && isLatestEventWithinThreshold(shipment));
      // const filteredShipments = shipments.items.filter((shipment) => isShipmentStarted(shipment));
      shipment = [...filteredShipments]
    } else if (SHOWING_SHIPMENTS.viewAll) {
      shipment = [...shipments.items]
    } else {
      // Return shipments based on the status
      shipment = [...shipments.items.filter((shipment) => shipment.status === 'STARTED' || shipment.status === 'COMPLETED')];
    }
    return shipment;
  }
}
