
<!-- Main content -->
<div class="row">
    <div class="col-12">
        <section class="content mt-4">
            <div class="col-12" id="user_router">
                <ul class="nav nav-tabs flex-row cus-tab tab-bar" id="tab1">
                    <li *ngIf="userInfo?.accesslevel.permissions['access-control']
                        && userInfo?.accesslevel.permissions['access-control'].submenu['user-access-policies'].view"
                        class="nav-item tab"
                    >
                        <div class="eff-7"></div>
                        <a class="nav-link text-uppercase font-weight-bold" data-toggle="pill"
                            href="javascript:void()" routerLink="/access-control/user-access-policies" routerLinkActive="active">
                            <img src="../../../assets/images/icons/privacy-policy.png" class="pr-1 img-fluid pb-1"/>
                            User Access policies
                        </a>
                    </li>
                    <li *ngIf="userInfo?.accesslevel.permissions['access-control'] && 
                        userInfo?.accesslevel.permissions['access-control'].submenu['user-management'].view"
                        class="nav-item tab"
                    >
                        <div class="eff-7"></div>
                        <a class="nav-link text-uppercase font-weight-bold"
                            data-toggle="pill" href="javascript:void()" routerLink="/access-control/user-management" routerLinkActive="active">
                            <img src="../../../assets/images/icons/management.png" class="pr-1 img-fluid pb-1"/>
                            User Management
                        </a>
                    </li>
                    <div class="indicator"></div>
                    <li class="nav-item tab pt-1">
                        <button class="btn btn-primary mt-2 ml-3 text-uppercase" (click)="downloadURI()">Download User Config</button>
                    </li>
                </ul>
            </div>
            <router-outlet></router-outlet>
        </section>
    </div>
</div>
<!-- /Main content -->

<!-- Control Sidebar -->
<aside class="control-sidebar control-sidebar-dark">
    <!-- Control sidebar content goes here -->
</aside>
<!-- /.control-sidebar -->


