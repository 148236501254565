import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-led-fixture',
  templateUrl: './led-fixture.component.html',
  styleUrls: ['./led-fixture.component.css']
})
export class LedFixtureComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
