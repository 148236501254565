import { AppLayoutComponent } from './shared/components/app-layout/app-layout.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';


const routes: Routes = [
  {
    path:'', loadChildren: () => import('./modules/public/public.module').then(m => m.PublicModule)
  },
  {
    path: '',
    component: AppLayoutComponent,
    children: [
      {path:'dashboard',loadChildren:()=>import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthGuard]},
      {path:'end-point-management',loadChildren:()=>import('./modules/end-point-management/end-point-management.module').then(m => m.EndPointManagementModule), canActivate: [AuthGuard]},
      {path:'edge-management',loadChildren:()=>import('./modules/edge-management/edge-management.module').then(m => m.EdgeManagementModule), canActivate: [AuthGuard]},
      {path:'sensors',loadChildren:()=>import('./modules/sensors/sensors.module').then(m => m.SensorsModule)},
      {path: 'shipments', loadChildren: () => import('./modules/shipments/shipments.module').then(m => m.ShipmentsModule), canActivate: [AuthGuard]},
      {path: 'master-data', loadChildren: () => import('./modules/master-data/masterData.module').then(m => m.MasterDataModule)},
      {path: 'reportShipment/:sid', loadChildren: () => import('./modules/reportShipment/reportShipment.module').then(m => m.ReportShipmentModule)},
      {path: 'alerts', loadChildren: () => import('./modules/alerts/alerts.module').then(m => m.AlertsModule)},
      {path: 'alerts/:sid/:macid', loadChildren: () => import('./modules/alerts/alerts.module').then(m => m.AlertsModule)},
      {path: 'alerts/:sid', loadChildren: () => import('./modules/alerts/alerts.module').then(m => m.AlertsModule)},
      {path:'access-control', loadChildren: () => import('./modules/access-control/access-control.module').then(m => m.AccessControlModule), canActivate: [AuthGuard] },
      {path:'utility',loadChildren:()=>import('./modules/utility/utility.module').then(m => m.UtilityModule), canActivate: [AuthGuard]},
      {path:'system-settings',loadChildren:()=>import('./modules/system-settings/system-settings.module').then(m => m.SystemSettingsModule)},
      {path:'reports',loadChildren:()=>import('./modules/reports/reports.module').then(m => m.ReportsModule), canActivate: [AuthGuard]},
      {path:'provisioning-queue',loadChildren:()=>import('./modules/provisioning-queue/provisioning-queue.module').then(m => m.ProvisioningQueueModule), canActivate: [AuthGuard]},
    ]
  }
];

@NgModule({
  imports: [RouterModule,RouterModule.forRoot(routes,{useHash:true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
