import { GoogleMap } from '@angular/google-maps';
import { ITracker } from './../../../end-point-management/models/tracker.model';
import { IMeta, ROWS_PER_PAGE } from './../../../../shared/models/http-response.model';
import { first, switchMap, tap, map, catchError, distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { IShipment, SHIPMENT_TYPE, STATUS, ShipmentResponse } from './../../models/shipment.model';
import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, Subscription, throwError } from 'rxjs';
import { ConstantVariables } from 'src/constants/constants';
import { ShipmentsService } from '../../services/shipments.service';
import { ConfirmationService, LazyLoadEvent, MenuItem, SortEvent } from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';
import { ISensor } from '../../models/sensor.model';
import { IHttpError } from 'src/app/core/models/http-error.model';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
declare var require: any;
let polyline = require('google-polyline');
import { HttpClient } from '@angular/common/http';

import { CommonService } from 'src/app/core/services/common.service';
import { DashboardService } from 'src/app/modules/dashboard/services/dashboard.service';
import { API_ERROR } from 'src/app/core/constants/global-error.constants';
import { GRAPH_LIMIT, MANUFACTUREDBY } from 'src/app/core/constants/level.constants';
import * as moment from 'moment';
import { BASE64LOGO } from 'src/app/core/constants/global-success.constants';
import { calculateBatteryPercentage } from 'src/app/shared/utilites/battery-percentage-calculator.util';
import { async } from '@angular/core/testing';
@Component({
  selector: 'app-shipments',
  templateUrl: './shipments.component.html',
  styleUrls: ['./shipments.component.css']
})
export class ShipmentsComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('pickup') pickup: ElementRef;
  @ViewChild(GoogleMap) map: GoogleMap;

  public shipments$: Observable<IShipment[]>;
  public isLoading = false;
  public showPanel = false;
  public isopenSearch = false;
  Favorite: boolean ;
  public shipmentForm: FormGroup = this.formBuilder.group({
    id: [''],
    shipmentName: ['', Validators.required],
    shipmentDesc: ['', Validators.required],
    shipmentType: ['', Validators.required],
    pickupLocation: ['', Validators.required],
    pickupDate: ['', Validators.required],
    destinationLocation: ['', Validators.required],
    deliveryDate: ['', Validators.required],
    geofence_radius: [0, Validators.required]
  });
  public calculateBatteryPercentage = calculateBatteryPercentage;

  searchQuery: string;
  autocompleteResults: any[] = [];
  selectedShipment = null;
  trackers = [];

  blutagForm: FormGroup = this.formBuilder.group({
    trackerId: [''],
    min: [''],
    max: ['']
  });

  statusForm: FormGroup = this.formBuilder.group({
    status: ['PAUSED', Validators.required]
  });

  configForm: FormGroup = this.formBuilder.group({
    trackerId: '',
    freq: ['', Validators.required],
    min: ['', Validators.required],
    max: ['', Validators.required],
    minH: ['', Validators.required],
    maxH: ['', Validators.required]
  });
  freq: number;

  shipmentAddresses: any = [];

  ROWS_PER_PAGE = ROWS_PER_PAGE;
  meta: IMeta | undefined;

  steps: MenuItem[];
  currentStep: number = 0;

  STATUS = STATUS;
  shipmentId: string = '';
  statuses: { name: string, value: STATUS }[] = [
    { name: 'Started', value: STATUS.STARTED },
    { name: 'Paused', value: STATUS.PAUSED },
    { name: 'Completed', value: STATUS.COMPLETED },
    { name: 'Canceled', value: STATUS.CANCELLED }
  ];
  shipmentTypes: { name: string, value: SHIPMENT_TYPE }[] = [
    { name: SHIPMENT_TYPE.MOVABLE, value: SHIPMENT_TYPE.MOVABLE },
    { name: SHIPMENT_TYPE.FIXED, value: SHIPMENT_TYPE.FIXED },
  ];

  showsPsuggestions = false;
  showsDsuggestions = false;
  terms = [];

  minPickupDate: Date = new Date();
  minDeliveryDate: Date = new Date();
  shipmentsData: any[] = [];
  trackerList: ITracker[] = [];
  originalTracker: ITracker[] = [];
  selectedTrackers: ITracker[] = [];
  originalSelectedTrackers: ITracker[] = [];
  sensorsList: ISensor[] = [];

  modalVisible: boolean = false;
  selectedTracker: ITracker = null;
  isDisabled: boolean = false;
  isTrackerAssigned: Number = 0;
  shipmentChangeStatus: any = '';
  shipmentStatus: any = '';
  status: { label: string, command: any, value: STATUS, disabled: boolean }[] = [];
  querystring = `limit=${ROWS_PER_PAGE}`;
  userInfo;
  manufacturedBy = MANUFACTUREDBY;

  search = new FormControl('');
  trackerSearch = new FormControl('');
  loadShipments = true;
  private _sub: Subscription = new Subscription();

  constructor(
    public constantVariables: ConstantVariables,
    private formBuilder: FormBuilder,
    private shipmentsService: ShipmentsService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private confirmationService: ConfirmationService,
    private commonService: CommonService,
    private dashboardService: DashboardService,
    private http: HttpClient,
  ) { }

  public ngOnInit(): void {
    const { user } = this.commonService.getCurrentUserData();
    if (user) {
      this.userInfo = user;
      const socketConnectionId = user.token + new Date().getTime();
      this.dashboardService.connectSocket(socketConnectionId);
      this.dashboardService.getNotificationData();
    }
    this.freq = user?.defaultFrequency;
    if (this.freq) {
      this.configForm.controls['freq'].setValidators([Validators.min(this.freq), Validators.required])
    }
    this.status = [
      { label: 'Started', command: (event) => { this.confirmStatusModal(event) }, value: STATUS.STARTED, disabled: false },
      { label: 'Paused', command: (event) => { this.confirmStatusModal(event) }, value: STATUS.PAUSED, disabled: false },
      { label: 'Completed', command: (event) => { this.confirmStatusModal(event) }, value: STATUS.COMPLETED, disabled: false },
      { label: 'Canceled', command: (event) => { this.confirmStatusModal(event) }, value: STATUS.CANCELLED, disabled: false }
    ];
    this.steps = [
      {
        label: 'Add new shipment'
      },
      {
        label: 'Gateway mapping'
      },
      {
        label: 'Blutag mapping'
      },
      {
        label: 'Status'
      }
    ];

    this.minPickupDate.setDate(this.minPickupDate.getDate());
    this.setMinDeliveryDate(this.minPickupDate);

    this._sub.add(this.shipmentForm.controls['shipmentType'].valueChanges.subscribe((type) => {
      this.shipmentForm.controls['destinationLocation'].setValidators(type === 'Fixed' ? null : Validators.required);
      this.shipmentForm.controls['destinationLocation'].updateValueAndValidity();
      this.shipmentForm.controls['deliveryDate'].setValidators(type === 'Fixed' ? null : Validators.required);
      this.shipmentForm.controls['deliveryDate'].updateValueAndValidity();
    }));

    this._sub.add(this.shipmentsService.isOpenPanel.subscribe((isOpenEvent: { openModel: boolean, shipmentId: string }) => {
      if (isOpenEvent.openModel) {
        isOpenEvent.shipmentId ? this.getShipmentById(isOpenEvent.shipmentId) : this.addNewShipment();
        setTimeout(() => { this.shipmentsService.pannelEvent({ openModel: false, shipmentId: '' }) }, 0);
      }
    }));
    this._sub.add(this.trackerSearch.valueChanges.pipe().subscribe((val) => {
      if (val) {
        this.trackers = this.originalTracker.filter((tracker) => tracker.data.deviceUUID.match(val))
        this.selectedTrackers = this.originalSelectedTrackers.filter((tracker) => tracker.data.deviceUUID.match(val))
      } else {
        this.reset();
      }
    }));

    this.filterShipment()
  }

  ngOnDestroy(): void {
    this.dashboardService.disconnectSocket();
    this._sub.unsubscribe();
  }

  public ngAfterViewInit() {
    //   // to make sure google map was loaded before loading autocomplete
    //   this.waitForGoogleMapApi();
  }

  public changeShipmentStatus(shipment: any) {
    if (shipment?.id) {
      this.status.find(item => (item.value === shipment.status && (item.disabled = true, true)));
      this.status.find(item => item.value !== shipment.status && (item.disabled = false, true));
      this.shipmentChangeStatus = shipment?.id;
    }
    if (shipment?.item) { this.shipmentStatus = shipment?.item?.value; }
    if (this.shipmentChangeStatus && this.shipmentStatus) {
      this.spinner.show('spinner');
      this._sub.add(this.shipmentsService.saveStatus(this.shipmentChangeStatus, this.shipmentStatus).pipe(
        first()
      ).subscribe(() => {
        this.shipmentChangeStatus = '';
        this.shipmentStatus = '';
        this.spinner.hide('spinner');
        this.toastr.success('Updated Status Successfully', '', {
          timeOut: 3000
        });
      
        this.shipments$ = this.shipmentsService.getShipmentsList(this.querystring).pipe(
          map((resp: any) => {
            this.meta = resp.meta;
            this.shipmentsData=resp.items
            return resp.items
          })
        );
      }, error => this.handleError(error)));
    }

  }

  public confirmStatusModal(shipment) {
    this.confirmationService.confirm({
      message: `Update status`,
      header: `Update ${this.constantVariables.SHIPMENT} status`,
      acceptButtonStyleClass: 'p-button-outlined p-button-danger',
      rejectButtonStyleClass: 'p-button-outlined p-button-secondary',
      accept: () => {
        this.changeShipmentStatus(shipment)
      }
    });
  }



  












  public opendeleteModal(shipment: IShipment) {
    this.confirmationService.confirm({
      message: `Delete this shipment <p class="font-weight-bold">${shipment.shipmentName}</p>`,
      header: `Delete ${this.constantVariables.SHIPMENT}`,
      acceptButtonStyleClass: 'p-button-outlined p-button-danger',
      rejectButtonStyleClass: 'p-button-outlined p-button-secondary',
      accept: () => {
        this.shipments$ = this.shipmentsService.deleteShipment(shipment.id).pipe(
          switchMap(() => this.shipmentsService.getShipmentsList(this.querystring)),
          map((resp: any) => {
            this.meta = resp.meta;
            return resp.items
          }),
          tap(() => {
            this.toastr.success(shipment.shipmentName + ' Deleted Successfully', ' ', {
              timeOut: 3000
            });
            this.resetAllForms();
          }),
          catchError(error => {
            if (error?.message === API_ERROR.USER_LOGOUT) {
              this.commonService.logout(API_ERROR.USER_LOGOUT);
            } else {
              this.toastr.error(`${this.constantVariables.SHIPMENT} cannot be deleted at this moment. Please try again.`, ' ', {
                timeOut: 3000
              });
            }
            return throwError(error);
          })
        );
      }
    });
  }

  public addNewShipment() {
    this.currentStep = 0;
    this.shipmentId = '';
    // this.resetAllForms();
    this.showPanel = true;
    this.shipmentForm.controls['geofence_radius'].setValue(0);
    this._sub.add(this.shipmentsService.getShipmentAdderess().subscribe((data) => {
      this.shipmentAddresses = data;
    }));
  }

  async getStandardRoute(start, end, id?) {
    let directionsService = new google.maps.DirectionsService;
    let directionsDisplay = new google.maps.DirectionsRenderer;
    let bounds = new google.maps.LatLngBounds;
    var request = {
      origin: start,
      destination: end,
      optimizeWaypoints: true,
      travelMode: google.maps.TravelMode.DRIVING,
    };
    await directionsService.route(request,
      async (result, status) => {
        if (status == 'OK') {
          directionsDisplay = new google.maps.DirectionsRenderer({
            suppressMarkers: true,
            suppressPolylines: true
          });
          directionsDisplay.setDirections(result);
          bounds.union(result.routes[0].bounds);
          let startPoint, endPoint;
          const geocoder = new google.maps.Geocoder();
          await geocoder.geocode({ 'address': start }, (results: any, status) => {
            if (status == google.maps.GeocoderStatus.OK) {
              startPoint = [Number(results[0].geometry.location.lat()), Number(results[0].geometry.location.lng())];
            } else {
              this.toastr.error("Address not found on Maps, therefore geofencing cannot be enabled.")
            }
          });
          await geocoder.geocode({ 'address': end }, (results: any, status) => {
            if (status == google.maps.GeocoderStatus.OK) {
              endPoint = [Number(results[0].geometry.location.lat()), Number(results[0].geometry.location.lng())];
            } else {
              this.toastr.error("Address not found on Maps, therefore geofencing cannot be enabled.")
            }
          });
          
          let points = [startPoint, ...polyline.decode(result.routes[0].overview_polyline), endPoint];
          let details = {
            shipmentId: this.shipmentId,
            radius: this.shipmentForm.controls['geofence_radius'].value ? this.shipmentForm.controls['geofence_radius'].value : 0,
            points
          }
          if (id) {
            this._sub.add(this.shipmentsService.updateRouteData(details).subscribe((data) => { }));
          } else {
            this._sub.add(this.shipmentsService.insertRouteData(details).subscribe((data) => { }));
          }
        }
      });
  }

  public saveShipment(): void {
    this.spinner.show('spinner');
    if (this.shipmentForm.dirty) {
      this.isLoading = true;
      const type = this.shipmentForm.controls['shipmentType'].value;
      const currentDate = new Date();
      const incrementedDate = new Date(currentDate.getTime() + 5 * 60000);
      const pickupDate = (!this.shipmentId && incrementedDate > <Date>this.shipmentForm.controls['pickupDate'].value) ? incrementedDate : <Date>this.shipmentForm.controls['pickupDate'].value;
      const deliveryDate = (!this.shipmentId && type === 'Fixed') ? new Date(pickupDate.getTime() + 5 * 60000)
        : (!this.shipmentId && pickupDate > <Date>this.shipmentForm.controls['deliveryDate'].value)
          ? new Date(pickupDate.getTime() + 5 * 60000) : <Date>this.shipmentForm.controls['deliveryDate'].value;
      const destinationLocation = type === 'Fixed' ? this.shipmentForm.controls['pickupLocation'].value : this.shipmentForm.controls['destinationLocation'].value
      const shipment: IShipment = {
        ...this.shipmentForm.value,
        id: this.shipmentId || '',
        destinationLocation,
        pickupDate,
        deliveryDate
      };

      if (this.shipmentId && shipment.shipmentType === "Movable") {
        this.getStandardRoute(shipment.pickupLocation, shipment.destinationLocation, this.shipmentId);
      } else if (shipment.shipmentType === "Movable") {
        this.getStandardRoute(shipment.pickupLocation, shipment.destinationLocation);
      } else {
        let location = [];
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ 'address': shipment.pickupLocation }, (results: any, status) => {
          if (status == google.maps.GeocoderStatus.OK) {
            location = [results[0].geometry.location.lat(), results[0].geometry.location.lng()];
            const details = {
              shipmentId: this.shipmentId,
              radius: this.shipmentForm.controls['geofence_radius'].value ? this.shipmentForm.controls['geofence_radius'].value : 0,
              point: location
            }
            if (this.shipmentId) {
              this._sub.add(this.shipmentsService.updateRouteData(details).subscribe((data) => { }));
            } else {
              this._sub.add(this.shipmentsService.insertRouteData(details).subscribe((data) => { }));
            }
          } else {
            this.toastr.error("Address not found on Maps, therefore geofencing cannot be enabled.")
          }
        });
      }


      const request$ = this.shipmentId ? this.shipmentsService.updateShipment(shipment) : this.shipmentsService.addShipment(shipment);
      this._sub.add(request$.pipe(
        first()
      ).subscribe(shipment => {
        this.shipmentForm.markAsPristine();
        this.shipmentId = shipment.id;
        this.toastr.success(shipment.id ? 'Updated Successfully' : 'Added Successfully', '', { timeOut: 3000 });
        this.isLoading = false;
        // reload shipment table
        this.shipments$ = this.shipmentsService.getShipmentsList(this.querystring).pipe(
          map((resp: any) => {
            this.meta = resp.meta;
            return resp.items
          })
        );
        this.getTrackerOptions();
        this.goNext();
      }, error => {
        this.shipmentForm.setErrors({ 'invalid': true });
        this.handleError(error);
      }));
    } else {
      this.getTrackerOptions();
      this.goNext();
    }
  }


  
  public nextShipmentPage(event: LazyLoadEvent | undefined, meta: IMeta | undefined) {

    this.isLoading = true;
    let query = `limit=${ROWS_PER_PAGE}`;
    if ((event?.first || event?.first === 0) && meta) {
      query += `&page=${event.first / meta.itemsPerPage + 1}`;
    }
    if (this.search.value) { query += `&search=${this.search.value}`; }
    this.querystring = query;
    this.shipments$ = this.shipmentsService.getShipmentsList(this.querystring).pipe(
      tap(resp => {
     
       this.shipmentsData = resp.items; 
        this.meta = resp.meta;
        this.isLoading = false;

      }),
      map(resp => {
        const filterValue = <string>event?.globalFilter || '';
        const sortField = event?.sortField;
        const sortOrder = event?.sortOrder;
        let result = resp.items.filter(x => x.shipmentName.toLowerCase().includes(filterValue.toLowerCase())
          || x.shipmentType.toLowerCase().includes(filterValue.toLowerCase()));
        if (sortField && sortOrder) {
          result.sort((a, b) => {
            if (sortOrder > 0) {
              return a[sortField].localeCompare(b[sortField]);
            } else {
              return b[sortField].localeCompare(a[sortField]);
            }
          });
        }
     
        return result;
      }),
      catchError(error => {
        this.handleError(error);
        return throwError(error);
      })
    );
    this.cdr.detectChanges();
  }

  generatePDF(shipmentId: string, shipmentName: string) {

    this.shipmentsService.getShipmentReport([shipmentId]).subscribe((resp: any) => {
      let events = resp[0];
      resp[0].reverse();
     
      const doc = new jsPDF('p', 'mm', 'a4', true);

      const reportTitle = shipmentName + " Report";
      const reportDate = new Date().toLocaleDateString();
      doc.setProperties({
        title: reportTitle,
        author: 'Your Name',
        subject: 'shipment report',
        keywords: 'shipment, report, pdf'
      });

      // Define report content
      let y = 30;
      const leftMargin = 10;
      doc.setFontSize(18);
      doc.text(reportTitle, 10, y);
      doc.setFontSize(12);
      y += 10;
      doc.text(`Report generated on: ${reportDate}`, leftMargin, y);
      y += 10;
      doc.text(`Report generated by: ${this.userInfo.fullName}`, leftMargin, y);
      y += 20;
      doc.setFontSize(12);
      const headers = ['Event', 'Time'];
      const data = [];
      events.map(event => {
        let eventString = event.user + ' ' + event.historyType.toLowerCase() + ' the shipment.';
        if (event.historyType === 'Updated' && event.shipmentHistory) {
          for (let key of Object.keys(event.shipmentHistory)) {
            if (event.shipmentHistory.hasOwnProperty(key)) {
              const field = event.shipmentHistory[key];
              if (field && field.currentValue !== null) {
                // console.log(key.split(/(?=[A-Z])/));
                let updatedField = key.split(/(?=[A-Z])/).join(' ').toLowerCase();
                eventString = eventString + `\n- ${updatedField} updated from '${field.previousValue}' to '${field.currentValue}`;
                // data.push([eventString, '']);
              }
            }
          }
        }
        data.push([eventString, this.convertTime(event.timeStamp)]);
      });
      autoTable(doc,
        {
          startY: y, head: [headers], body: data,
          margin: {
            top: 29,
            left: 10,
            right: 4
          }
        });

      this.addFooters(doc);
      // Save and download PDF document
      doc.save(`${reportTitle} ${reportDate}.pdf`);
      // doc.output('dataurlnewwindow');
      var string = doc.output('datauristring');
      var iframe = "<iframe width='100%' height='100%' src='" + string + "'></iframe>"
      var x = window.open();
      x.document.open();
      x.document.write(iframe);
      x.document.title = `${reportTitle} ${reportDate}`;
      x.document.close();
      
    });
  }

  convertTime(time) {
    return moment(new Date(time * 1000)).format("ddd MMM DD y h:mm a")
  }

  private addFooters(doc) {
    const pageCount = doc.internal.getNumberOfPages()

    doc.setFont('helvetica', 'italic')
    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i)
      doc.addImage(BASE64LOGO, 'PNG', 5.5, 10, 25, 10);
      doc.setFontSize(12)
      doc.text('Report Timestamp', (doc.internal.pageSize.width / 1.2350), 12.5)
      doc.setFontSize(8)
      const date = moment(new Date()).format("ddd MMM DD y h:mm a");
      doc.text(`${date}`, (doc.internal.pageSize.width / 1.22550), 17.5)
      doc.setFontSize(8)
      doc.text('Page ' + String(i) + ' of ' + String(pageCount), (doc.internal.pageSize.width / 1.025), 290, {
        align: 'right',
      });
    }
  }

  public getShipmentById(id: string) {
    this.resetAllForms();
    this.currentStep = 0;
    this.showPanel = true;
    this.spinner.show('spinner');
    this._sub.add(this.shipmentsService.getShipmentById(id).pipe(
      first()
    ).subscribe(resp => {
      this.shipmentId = resp.id;
      this.selectedShipment = resp;
      this.shipmentForm.setValue({
        id: resp.id || '',
        shipmentName: resp.shipmentName,
        shipmentDesc: resp.shipmentDesc,
        shipmentType: resp.shipmentType,
        pickupLocation: resp.pickupLocation,
        pickupDate: new Date(resp.pickupDate),
        destinationLocation: resp.shipmentType.toLowerCase() === 'movable' ? resp.destinationLocation : '',
        deliveryDate: resp.shipmentType.toLowerCase() === 'movable' ? new Date(resp.deliveryDate) : '',
        geofence_radius: resp.geofence_radius ? resp.geofence_radius : 0
      });
      if (resp.status) {
        this.statusForm.setValue({
          status: resp.status
        });
      }

      this.spinner.hide('spinner');
    }, error => {
      this.handleError(error);
    }));
  }

  public goNext() {
    if (this.currentStep < 3) { this.currentStep++; }
  }

  public goBack() {
    if (this.currentStep > 0) { this.currentStep--; }
  }

  public removeTracker(trackerId: string) {
    this.confirmationService.confirm({
      message: `Remove this tracker`,
      header: `Remove tracker`,
      acceptButtonStyleClass: 'p-button-outlined p-button-danger',
      rejectButtonStyleClass: 'p-button-outlined p-button-secondary',
      accept: () => {
        this.spinner.show('spinner');
        this._sub.add(this.shipmentsService.removeTrackers(this.shipmentId, [trackerId]).pipe(
          first()
        ).subscribe((resp: any) => {
          // remove shipmentId from tracker after removing
          const removedTracker = this.trackerList.find(x => x.data.token === trackerId);
          if (removedTracker) {
            removedTracker.shipmentId = '';
            this.selectedTrackers = this.selectedTrackers.filter(x => x.data.token !== removedTracker.data.token);
            this.originalSelectedTrackers = this.selectedTrackers.filter(x => x.data.token !== removedTracker.data.token);
            this.selectedTrackers.map(t => {
              t.data.deviceUUID = this.removeGarbageValue(t.data.deviceUUID);
            });
            this.trackerList.forEach(x => {
              if (x.data.token === trackerId) {
                delete x.min;
                delete x.max;
                delete x.freq;
                x.data.active = false;
                x.disabled = true;
              }
            })
            this.trackers = this.trackerList.filter(x => x.data.active === false);
            // if(!this.trackers.length) {
            //   this.trackers.push(removedTracker);
            // }
            // this.trackerForm.setValue({
            //   trackers: this.selectedTrackers.map(x => x.data.token)
            // });
          }
          this.spinner.hide('spinner');
          this.toastr.success('Tracker removed successfully.', '', { timeOut: 3000 });
        }, error => this.handleError(error)));
      }
    });
  }

  public addTracker() {
    this.spinner.show('spinner');
    const { trackerId, ...config } = this.configForm.value;
    this._sub.add(this.shipmentsService.saveTrackers(this.shipmentId, [trackerId], config).pipe(
      first()
    ).subscribe(resp => {
      // add shipmentId to tracker after adding
      let addedTracker = this.trackerList.find(x => x.data.token === trackerId);

      if (addedTracker) {
        addedTracker.data.active = true;
        addedTracker = {
          ...addedTracker,
          shipmentId: this.shipmentId,
          ...config
        };
        this.selectedTrackers.push(addedTracker);

        this.selectedTrackers.map(t => {
          t.data.deviceUUID = this.removeGarbageValue(t.data.deviceUUID);
          t.disabled = true;
        });
        this.selectedTracker = null;
        // update tracker list
        this.trackerList = this.trackerList.map(x => x.data.token === trackerId ? { ...addedTracker } : x);
        this.trackers = this.trackerList.filter(x => x.data.active === false);
        this.originalTracker = this.trackerList.filter(x => x.data.active === false);
        // this.cdr.detectChanges();

        // this.trackerForm.setValue({
        //   trackers: this.selectedTrackers.map(x => x.data.token)
        // });
      }
      this.spinner.hide('spinner');
      this.isLoading = false;
      this.toastr.success('Tracker saved successfully.', '', { timeOut: 3000 });
      this.modalVisible = false;
    }, error => this.handleError(error)));
  }

  public goToStep3() {
    this.isTrackerAssigned = this.trackerList.filter((i) => i.shipmentId).length
    if (this.shipmentId) {
      this.spinner.show('spinner');
      this._sub.add(this.shipmentsService.getGateways(this.shipmentId).pipe(
        switchMap((trackers: any) => {
          this.selectedTrackers = trackers.shipmentTrackers || [];
          this.originalSelectedTrackers = trackers.shipmentTrackers || [];
          if (this.selectedTrackers.length) {
            this.selectedTrackers.forEach((tracker) => {
              tracker.data.deviceUUID = this.removeGarbageValue(tracker.data.deviceUUID);
            });
            this.blutagForm.setValue({
              trackerId: this.selectedTrackers[0].data.token,
              min: GRAPH_LIMIT.MIN,
              max: GRAPH_LIMIT.MAX
            });
            const deviceToken = this.selectedTrackers[0].data.token || '';
            return this.shipmentsService.getSensors(deviceToken);
          }
          return of([]);
        }),
        first(),
      ).subscribe((resp: ISensor[]) => {
        this.sensorsList = resp.filter((sensor) => sensor?.mac_addr?.length < 15);
        this.spinner.hide('spinner');
        this.goNext();
      }));
    }
  }

  public removeBlutag(bluTagId: string) {
    this.confirmationService.confirm({
      message: `Remove this blutag`,
      header: `Remove blutag`,
      acceptButtonStyleClass: 'p-button-outlined p-button-danger',
      rejectButtonStyleClass: 'p-button-outlined p-button-secondary',
      accept: () => {
        const tracker = this.selectedTrackers.find(tracker => tracker.data.token === this.blutagForm.controls['trackerId'].value);
        const deviceToken = tracker && tracker.data.token ? tracker.data.token : '';
        if (this.shipmentId && deviceToken && bluTagId) {
          this.spinner.show('spinner');
          this._sub.add(this.shipmentsService.removeSensors(this.shipmentId, deviceToken, [bluTagId]).pipe(
            first()
          ).subscribe((resp) => {
            let removedBlutag: any = this.sensorsList.find(x => x.id === bluTagId);
            if (removedBlutag) {
              removedBlutag = {
                ...removedBlutag,
                shipmentId: '',
                assign: false,
                config: { min: GRAPH_LIMIT.MIN, max: GRAPH_LIMIT.MAX }
              };
              this.sensorsList = this.sensorsList.map(x => x.id === bluTagId ? { ...removedBlutag } : x);
            }
            this.spinner.hide('spinner');
            this.toastr.success('Blutag removed successfully.', '', { timeOut: 3000 });
          }, error => this.handleError(error)));
        }
      }
    });
  }

  public saveBluTag(bluTagId: string) {
    const tracker = this.selectedTrackers.find(tracker => tracker.data.token === this.blutagForm.controls['trackerId'].value);
    const deviceToken = tracker?.data.token || '';
    if (this.shipmentId && deviceToken && bluTagId) {
      const config = {
        min: this.blutagForm.value['min'] || GRAPH_LIMIT.MIN,
        max: this.blutagForm.value['max'] || GRAPH_LIMIT.MAX,
      }
      this.spinner.show('spinner');
      this._sub.add(this.shipmentsService.saveSensors(this.shipmentId, deviceToken, [bluTagId], config).pipe(
        first()
      ).subscribe((res) => {
        let addedBlutag: any = this.sensorsList.find(x => x.id === bluTagId);
        if (addedBlutag) {
          addedBlutag = {
            ...addedBlutag,
            shipmentId: this.shipmentId,
            config,
            assign: true
          };
          this.sensorsList = this.sensorsList.map(x => x.id === bluTagId ? { ...addedBlutag } : x);
        }
        this.spinner.hide('spinner');
        this.toastr.success('Blutag saved Successfully', '', {
          timeOut: 3000
        });
      }, error => this.handleError(error)));
    }
  }

  public goToStep4() {
    this.goNext();
  }

  public saveStatus() {
    const status = this.statusForm.controls['status'].value || '';
    if (this.shipmentId && status && this.statusForm.dirty) {
      this.isLoading = true;
      this.spinner.show('spinner');
      this._sub.add(this.shipmentsService.saveStatus(this.shipmentId, status).pipe(
        first()
      ).subscribe(() => {
        this.isLoading = false;
        this.spinner.hide('spinner');
        this.toastr.success('Update Status Successfully', '', {
          timeOut: 3000
        });
        this.resetAllForms();
        this.showPanel = false;
        // reload shipment table
        this.shipments$ = this.shipmentsService.getShipmentsList(this.querystring).pipe(
          map((resp: any) => {
            this.meta = resp.meta;
            return resp.items
          })
        );
      }, error => this.handleError(error)));
    } else {
      this.showPanel = false;
      this.resetAllForms();
    }
  }

  public setMinDeliveryDate(date: Date) {
    this.minDeliveryDate.setDate(date.getDate());
  }

  public closePanel() {
    this.showPanel = false;
    this.currentStep = 0;
    this.resetAllForms();
  }

  public scan() {
    this.spinner.show('spinner');
    const deviceToken = this.blutagForm.controls['trackerId'].value;
    this._sub.add(this.shipmentsService.scanSensors(deviceToken).pipe(
      first()
    ).subscribe((resp: any) => {
      this.sensorsList = resp.filter((sensor) => sensor.mac_addr.length < 15);
      this.spinner.hide('spinner');
    }));
  }

  public customSort(event: SortEvent) {
    // console.log(event);
  }

  public selectTrackerOnTag(event: any) {
    if (event.value) {
      this.spinner.show('spinner');
      const getExistingTracker = this.selectedTrackers.find((resp: ITracker) => resp.data.token === event.value && !resp.isRemoveTracker);
      if (getExistingTracker) {
        this._sub.add(this.shipmentsService.getSensors(getExistingTracker.data.token).pipe(
          first()
        ).subscribe((resp: ISensor[]) => {
          this.sensorsList = resp.filter((sensor) => sensor.mac_addr.length < 15);
          this.spinner.hide('spinner');
        }));
      }
    }
  }

  public showConfigModal(tracker: ITracker) {
     
    if (tracker.shipmentId) {
      // this.isDisabled = true;
      this.configForm.markAsPristine();
    } else {
      // this.isDisabled = false;
      this.configForm.markAsDirty();
    }
    this.configForm.setValue({
      trackerId: tracker.data.token,
      freq: tracker.freq || 10,
      min: tracker.min || GRAPH_LIMIT.MIN,
      max: tracker.max || GRAPH_LIMIT.MAX,
      minH: tracker.minH || GRAPH_LIMIT.MINHUM,
      maxH: tracker.maxH || GRAPH_LIMIT.MAXHUM,
    });
    // this.modalVisible = true;
    this.isTrackerAssigned = this.trackerList.filter((i) => i.shipmentId).length
  }

  public showBlutagConfig(blutag) {
    this.blutagForm.setValue({
      trackerId: blutag.trackerId,
      min: blutag?.config?.min || GRAPH_LIMIT.MIN,
      max: blutag?.config?.max || GRAPH_LIMIT.MAX
    });
  }

  onTabOpen(event) {
    // this.messageService.add({ severity: 'info', summary: 'Tab Expanded', detail: 'Index: ' + event.index });
  }

  public configGateway() {
    this.spinner.show('spinner');
    this.isLoading = true;
    const { trackerId, ...body } = this.configForm.value;
    const tracker = this.selectedTrackers.find(x => x.data.token === trackerId);
    if (!tracker) {
      this.addTracker();
    }
    else {
      this._sub.add(this.shipmentsService.configGateway(trackerId, body).pipe(
        first()
      ).subscribe(resp => {
        this.selectedTrackers = this.selectedTrackers.map(x => x.data.token === trackerId ? { ...x, ...body } : x);
        this.originalSelectedTrackers = this.selectedTrackers.map(x => x.data.token === trackerId ? { ...x, ...body } : x);
        this.selectedTracker = null;
        this.configForm.markAsPristine();
        this.spinner.hide('spinner');
        this.isLoading = false;
        this.toastr.success('Gateway configured Successfully', '', {
          timeOut: 3000
        });
        this.modalVisible = false;
      }, error => this.handleError(error)));
    }
  }

  public getTrackerOptions() {
    // this._sub.add(this.shipmentsService.getTrackerOptions().pipe(
    //   switchMap(trackers => {
    //     trackers = trackers.filter(tracker => !tracker.data.active && !tracker['shipmentId']);
    //     return this.shipmentsService.getGateways(this.shipmentId).pipe(
    //       map(items => {
    //         this.selectedTrackers = items ?? [];
    //         this.selectedTrackers.map(t => {
    //           t.data.deviceUUID = this.removeGarbageValue(t.data.deviceUUID);
    //         });
    //         const checkUniqueData = this.selectedTrackers.filter((cv: ITracker) => {
    //           return !trackers.find((e: ITracker) => e.data.token === cv.data.token);
    //         });
    //         this.trackers = this.trackerList.filter(x => x.data.active === false);
    //         return [...trackers, ...checkUniqueData].map(x => ({
    //           ...x,
    //           data: {
    //             ...x.data,
    //             deviceUUID: this.removeGarbageValue(x.data.deviceUUID)
    //           }
    //         }));
    //       })
    //     );
    //   }),
    //   first()
    // ).subscribe(trackers => {
    //   this.spinner.hide('spinner');
    //   this.trackerList = trackers;
    //   console.log("---trac", trackers);

    //   this.trackers = this.trackerList.filter(x => x.data.active === false);
    //   this.isTrackerAssigned = this.trackerList.filter((i) => i.shipmentId).length;
    // }));

    this.shipmentsService.getGateways(this.shipmentId).subscribe((data: any) => {
      
      const items = data.shipmentTrackers;
      this.shipmentId
      let trackers = data.availableTrackers;
      this.selectedTrackers = items ?? [];
      this.originalSelectedTrackers = items ?? [];
      this.selectedTrackers.map(t => {
        t.data.deviceUUID = this.removeGarbageValue(t.data.deviceUUID);
        t.freq = this.selectedShipment?.trackers[0].freq;
        t.min = this.selectedShipment?.trackers[0].min;
        t.max = this.selectedShipment?.trackers[0].max;
        t.minH = this.selectedShipment?.trackers[0].minH;
        t.maxH = this.selectedShipment?.trackers[0].maxH;
      });
      const checkUniqueData = this.selectedTrackers.filter((cv: ITracker) => {
        return !trackers.find((e: ITracker) => e.data.token === cv.data.token);
      });
      this.trackers = this.trackerList.filter(x => x.data.active === false);
      trackers = [...trackers, ...checkUniqueData].map(x => ({
        ...x,
        data: {
          ...x.data,
          deviceUUID: this.removeGarbageValue(x.data.deviceUUID)
        }
      }));
      this.spinner.hide('spinner');
      this.trackerList = trackers;

      this.trackers = this.trackerList.filter(x => x.data.active === false);
      this.isTrackerAssigned = this.trackerList.filter((i) => i.shipmentId).length;
      this.originalTracker = this.trackers;
    });
  }

  private handleError(error: IHttpError) {
    this.isLoading = false;
    this.spinner.hide('spinner');
    if (error?.message === API_ERROR.USER_LOGOUT) {
      this.commonService.logout(API_ERROR.USER_LOGOUT);
    } else {
      this.toastr.error(error.message, 'Error', {
        timeOut: 3000
      });
    }
  }

  private resetAllForms() {
    this.shipmentForm.reset();
    // this.trackerForm.reset();
    this.blutagForm.reset();
    this.selectedTracker = null;
    this.statusForm.reset();
    this.shipmentId = '';
    this.trackerList = [];
    this.originalTracker = [];
    this.selectedTrackers = [];
    this.originalSelectedTrackers = [];
    this.trackers = [];
    this.sensorsList = [];
  }

  private removeGarbageValue(deviceId: string): string {
    return deviceId.replace(/[^\x20-\x7E]/g, '');
  }

  private loadAutocomplete(inpurtId: string) {
    const input: any = document.getElementById(inpurtId) as HTMLInputElement;
    if (input) {
      const options = {
        componentRestrictions: { country: ['us', 'in', 'vn'] },
        fields: ['address_components', 'geometry', 'icon', 'name'],
        strictBounds: true,
        types: ['address'],
      };
      const autocomplete = new google.maps.places.Autocomplete(input);
      autocomplete.addListener("place_changed", () => {
        // const place = autocomplete.getPlace();
        // let address = "";
        // let postcode = "";
        // for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
        //   const componentType = component.types[0];
        //   switch (componentType) {
        //     case "street_number": {
        //       address = `${component.long_name}`;
        //       break;
        //     }
        //     case "route": {
        //       address = `${address} ${component.long_name}`;
        //       break;
        //     }
        //     case "postal_code": {
        //       postcode = `${component.long_name}`;
        //       break;
        //     }
        //     case "postal_code_suffix": {
        //       postcode = `${postcode}-${component.long_name}`;
        //       break;
        //     }
        //     case "locality":
        //       address = `${address}, ${component.long_name}`;
        //       break;
        //     case "administrative_area_level_1": {
        //       address = `${address}, ${component.long_name}`;
        //       break;
        //     }
        //     case "country":
        //       address = `${address}, ${component.short_name}`;
        //       break;
        //   }
        // }
        if (inpurtId === 'pickup') {
          this.shipmentForm.patchValue({
            pickupLocation: input.value
          });
        } else {
          this.shipmentForm.patchValue({
            destinationLocation: input.value
          });
        }
      });
    }
  }

  selectAddress(address: any) {
    // console.log(address);
    // Implement your logic when an address is selected
  }



  public waitForGoogleMapApi(loadMap: string) {
    if (typeof google != 'undefined') {
      this.loadAutocomplete(loadMap);
    }
  }


  autocompleteMatch(input) {
    if (input == '') {
      return this.shipmentAddresses;
    }
    var reg = new RegExp(input)
    return this.shipmentAddresses.filter(function (term) {
      if (term.match(reg)) {
        return term;
      }
    });
  }

  showResults(val, label) {
    if (label === 'pickup')
      this.showsPsuggestions = true;
    else
      this.showsDsuggestions = true;
    if (val === '') {
      this.terms = this.shipmentAddresses;
    } else {
      this.terms = this.autocompleteMatch(val);
    }
  }

  assignValue(val, label) {
    if (label === 'pickup')
      this.showsPsuggestions = true;
    else
      this.showsDsuggestions = true;
    if (label === 'pickup') {
      this.shipmentForm.patchValue({
        pickupLocation: `${val}`
      });
    } else {
      this.shipmentForm.patchValue({
        destinationLocation: `${val}`
      });
    }
  }

  hideSuggestions(label) {
    if (label === 'pickup')
      this.showsPsuggestions = false;
    else
      this.showsDsuggestions = false;
  }

  private filterShipment() {
 
    this._sub.add(this.search.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(1500)
    ).subscribe((val) => {
     
     
      if (this.isopenSearch) {
        this.loadShipments = false;
        setTimeout(() => {
          this.loadShipments = true;
        }, 0);
        // if (!val) this.isopenSearch = false; // TODO: Uncomment if need to hide input with no value in it.
      }
    }));
  }

  reset() {
    if (this.trackerSearch.value) this.trackerSearch.reset();
    this.trackers = this.originalTracker;
    this.selectedTrackers = this.originalSelectedTrackers;
  }

  getBatteryValue(payload, batteryRange?: boolean): String | Number {
    const batteryValue = payload.match(/bat:([0-9.]+V)/);
    return batteryValue ? calculateBatteryPercentage(batteryValue[1].replace('V', ''), batteryRange) : 'N/A';
  }
}
