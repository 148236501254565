import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss']
})
export class AppLayoutComponent implements OnInit, OnDestroy {

  showManageWidgets: boolean = false;
  private _sub: Subscription;

  constructor(private router: Router) {
    this._sub = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
        this.showManageWidgets = event.url === '/dashboard';
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this._sub.unsubscribe();
  }

}
