import { IHttpResponse } from './../../../shared/models/http-response.model';
import { IShipment, STATUS, ShipmentResponse } from './../models/shipment.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ApiService } from 'src/app/core/services/api.service';
import { CommonService } from 'src/app/core/services/common.service';
import { Socket } from 'ngx-socket-io';
import { ITracker } from '../../end-point-management/models/tracker.model';
import { ISensor, ISensorResponse } from '../models/sensor.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// import { EventList } from '../models/events.model';

@Injectable({
  providedIn: 'root'
})
export class ShipmentsService {

  public pannel = new BehaviorSubject<{ openModel: boolean, shipmentId: string }>({ openModel: false, shipmentId: ''});
  public isOpenPanel = this.pannel.asObservable();

  constructor(private http: HttpClient, private commonService: CommonService, private socket: Socket) { }

  pannelEvent(data: { openModel: boolean, shipmentId: string }) { this.pannel.next(data); }  

  getShipmentsList(querystring: string = '', notAssigned?:boolean) {
    let url = querystring ? `${ApiService.URLs.shipmentManagement}?${querystring}` : ApiService.URLs.shipmentManagement;
    const headersObject = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const options = {
      headers: headersObject,
    };
    if(notAssigned){
      url = url + `&notAssigned=${notAssigned}`;
    }
    return this.http.get<IHttpResponse<IShipment>>(url, options);
  }

  deleteShipment(id: string) {
    return this.http.delete(`${ApiService.URLs.shipmentManagement}/${id}`);
  }

  addShipment(shipment: IShipment) {
    return this.http.post<IShipment>(ApiService.URLs.shipmentManagement, shipment);
  }

  insertRouteData(points: any){
    const options = {
      headers: new HttpHeaders().set('Content-Type', "application/json")
    }
    return this.http.post(`${ApiService.URLs.locationAlerts}/${points.shipmentId}`, points, options)
  }

  updateRouteData(points: any){
    return this.http.put(`${ApiService.URLs.locationAlerts}/${points.shipmentId}`, points);
  }

  getShipmentAdderess(){
    return this.http.get<IShipment>(`${ApiService.URLs.shipmentManagement}/addresses`);
  }

  getShipmentById(id: string) {
    return this.http.get<IShipment>(`${ApiService.URLs.shipmentManagement}/${id}`);
  }

  updateShipment(shipment: IShipment) {
    return this.http.put<IShipment>(`${ApiService.URLs.shipmentManagement}/${shipment.id}`, shipment);
  }
 
  FavoriteShipment(shipmentId: string, updateData: { Favorite: boolean }) {
    return this.http.put<IShipment>(`${ApiService.URLs.shipmentManagement}/${shipmentId}`, updateData);
  }
  

  getTrackerOptions() {
    return this.http.get<ITracker[]>(`${ApiService.URLs.endPointManagement}`);
  }

  getTrackerDetails(deviceUUID: string) {
    return this.http.get<ITracker>(`${ApiService.URLs.endPointManagementById}?deviceUUID=${deviceUUID}`);
  }

  saveTrackers(shipmentId: string, trackerIds: string[], body: { min: number, max: number, freq: number }) {
    const data = {
      min: body.min.toString(),
      max: body.max.toString(),
      freq: body.freq.toString()
    };
    return this.http.post(`${ApiService.URLs.saveTracker}/${shipmentId}?trackerId=${trackerIds.join(',')}`, data);
  }

  removeTrackers(shipmentId: string, trackerIds: string[]) {
    return this.http.delete(`${ApiService.URLs.removeEndPointManagementById}/${shipmentId}?trackerId=${trackerIds.join(',')}`);
  }

  removeSensors(shipmentId: string, trackerToken: string, sensorsIds: string[] ) {
    return this.http.delete<ISensorResponse>(`${ApiService.URLs.removeSensorsById}/${shipmentId}/${trackerToken}?sensorId=${sensorsIds.join(',')}`);
  }

  getGateways(shipmentId: string) {
    return this.http.get<ITracker[]>(`${ApiService.URLs.endPointManagementById}/${shipmentId}`);
  }

  getSensors(deviceToken: string = '') {
    const url = deviceToken ? `${ApiService.URLs.sensors}?deviceToken=${deviceToken}` : `${ApiService.URLs.sensors}`;
    return this.http.get<ISensor[]>(url).pipe(
      map((res: ISensor[]) => {
        for (let x of res) { if(x['_id']) { x['id'] = x['_id']; } }
        return res; 
      })
    );
  }

  scanSensors(deviceToken: string = '') {
    const url = deviceToken ? `${ApiService.URLs.scanSensors}?deviceToken=${deviceToken}` : `${ApiService.URLs.scanSensors}`;
    return this.http.get<ISensor[]>(url);
  }

  saveSensors(shipmentId: string, trackerToken: string, blueTagIds: string[],config: any) {
    return this.http.post<ISensorResponse>(`${ApiService.URLs.saveSensors}/${shipmentId}/${trackerToken}?sensorId=${blueTagIds.join(',')}`, config);
  }

  saveStatus(shipmentId: string, status: STATUS) {
    return this.http.put<IShipment>(`${ApiService.URLs.shipmentManagement}/${shipmentId}`, { status });
  }

  configGateway(trackerId: string, body: { min: number, max: number, minH: number, maxH: number, freq: number }) {
    const data = {
      min: body.min.toString(),
      max: body.max.toString(),
      minH: body.minH.toString(),
      maxH: body.maxH.toString(),
      freq: body.freq.toString()
    };
    return this.http.put<{ data: string }>(`${ApiService.URLs.configure}/${trackerId}`, data);
  }


  getShipmentReport(id: Array<String>) {
    const { user } = this.commonService.getCurrentUserData();
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      params: new HttpParams({ fromString: `email=${user.officialEmail}&id=${id}&type=shipment` }),
    };
    return this.http.get(`${ApiService.URLs.getUserActionReport}`, options);
  }

}

