export const LEVELS = {
  SUPERADMIN:'SUPERADMIN',  
  SUPERADMINUSER:'SUPERADMINUSER',  
  ADMIN:'ADMIN' ,
  USER : 'User' 
}

export const GRAPH_LIMIT = {
  MIN: -30,
  MAX: 45,
  MINHUM: 20,
  MAXHUM: 55,
  PERCENTAGE_INCREASE:20
}

export const SHOWING_SHIPMENTS = {
  viewAll: false,
  startedOnly: true
}

export const MANUFACTUREDBY = "IMPACT By Honeywell"