import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  Input,
  TemplateRef,
  Renderer2,
  OnDestroy,
  HostListener
} from '@angular/core';
import { CommonService } from 'src/app/core/services/common.service';
import { Router } from '@angular/router';
import { NgScrollbar } from 'ngx-scrollbar';
import { WeatherService } from 'src/app/modules/utility/components/weather/weather.service';
import { DashboardService } from 'src/app/modules/dashboard/services/dashboard.service';
import { API_SUCCESS } from 'src/app/core/constants/global-success.constants';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { LEVELS } from 'src/app/core/constants/level.constants';
import { countries } from 'src/app/modules/dashboard/components/edit-profile/country';
import { environment } from 'src/environments/environment';
import { ConstantVariables } from 'src/constants/constants';
import { Subscription, timer } from 'rxjs';
import { IMenu, INotifiactionAction, INotification, IPermission, Menu_Path } from '../../models/side-nav.model';
import { IUser } from '../../models/user-signup.model';
import { NotificationAlertService } from 'src/app/modules/utility/services/notification-alert.service';
import { UserIdleService } from 'angular-user-idle';
import { HttpErrorResponse } from '@angular/common/http';
import { map, share, switchMap } from 'rxjs/operators';
import { API_ERROR } from 'src/app/core/constants/global-error.constants';
import { AuthService } from 'src/app/core/auth/auth.service';
import { AccessService } from 'src/app/modules/access-control/services/access.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit, OnDestroy {
  @ViewChild('welcomeModal', { static: true }) welcomeModal: TemplateRef<any>;
  @ViewChild(NgScrollbar, { static: true }) scrollbarRef: NgScrollbar;

  modalOptions: NgbModalOptions;
  profilePicUrl: any;
  menuControls: IMenu[] = [];
  menuIcons: {
    Dashboard: string;
    Reports: string;
    'IMPACT Tracker Management': string;
    'Edge Management': string;
    'Provisioning Queue': string;
    'Access Control': string;
    Utility: string;
    Shipments: string;
    'Master Data': string;
  };
  subMenuIcons: {
    'User Access Policies': string;
    'User Management': string;
    'Time & Clocks': string;
    Weather: string;
    'Logical Groups': string;
    'Alerts & Notifications': string;
    'Node Maps': string;
    Ledger: string;
    'User Chain': string;
    'Identity Chain': string;
    'Edge Chain': string;
    'Communication Chain': string;
    'Smart Contract Chain': string;
    Logs: string;
    'System Log': string;
    'Event Log': string;
  };
  // toggle: boolean;
  toggleClass: any;
  menuPath: any;
  ledgerMenuControl = [];
  logMenuControl = [];
  @Input() showManageWidgets: boolean;
  toggle: boolean = false;
  ismanageWidget: boolean;
  url: string;
  ROLE: string = LEVELS.SUPERADMIN;
  roleLevels = LEVELS;
  @Output() onOpenPopup: EventEmitter<any> = new EventEmitter();
  selectedCountries: any = countries;

  //ngModels for Manage widgets
  nodevices: any = true;
  sensorevents: any = true;
  isPlaying:boolean
  recentactivity: any = true;
  lmap: any = true;
  pqueue: any = true;
  totlevents: any = true;
  avgeventscount: any = true;
  nodemaps: any = true;
  mapcard: any = true;
  eventLog: any = true;
  clickbtn: any;
  location: any;
  currentTemp: any;
  weatherData: any;
  user: IUser;
  fullName: any;
  country: any;
  pastEventId: any = [];
  isOpenViewModal: boolean;
  downloadReportButton = false;
  showUserModal = false;
  organizationName = "";
  currentUser:any
isLoading :boolean=true
  notifications: INotification[] = [];
  // notificationCounter: number = 0;

  rxTime = new Date();
  intervalId;
  subscription: Subscription;

  private _sub: Subscription = new Subscription();
  userData: any;

  constructor(
    private dashboardService: DashboardService,
    private route: Router,
    private commonService: CommonService,
    private weatherService: WeatherService,
    private toaster: ToastrService,
    private modalService: NgbModal,
    public constantVariables: ConstantVariables,
    private renderer: Renderer2,
    private notificationAlertService: NotificationAlertService,
    private toastr: ToastrService,
    private accessSer:AccessService,
    private userIdle: UserIdleService  ) {
    this.modalOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop',
    };
  }

  // @HostListener('window:beforeunload', ['$event'])
  // beforeunloadHandler(event: BeforeUnloadEvent) {
  //   event.preventDefault();
  //   event.returnValue = 'Are you sure you want to leave?';
  //   // Your function code goes here
  //   this.commonService.logout();
  // }


  ngOnInit(): void {
    this.getNotifications();
    // Optionally you can set time for `idle`, `timeout` and `ping` in seconds.
    // Default values: `idle` is 600 (10 minutes), `timeout` is 300 (5 minutes)
    // and `ping` is 120 (2 minutes).
    this.userIdle.setConfigValues({ idle: 1800, timeout: 3, ping: 0 })
    //Start watching for user inactivity.
    this.userIdle.startWatching();

    this.setIcons();
    this.getUserData();
    this.getAccessControl();
    this.getAllNotification();
    this.getAlertNotification();
  

    if (this.user) {
      this.getCountryCode();
      this.getWeather();
      if (
        this.user.profilePicture !== undefined ||
        this.user.profilePicture == ''
      )
        this.getProfileImage();
    }
    // Using RxJS Timer
    this.subscription = timer(0, 1000)
      .pipe(
        map(() => new Date()),
        share()
      )
      .subscribe(time => {
        this.rxTime = time;
      });
  }

  
  getAlertNotification(): void {
    this._sub.add(this.dashboardService.requestLatestEvent().subscribe((data) => {
      const { user } = this.commonService.getCurrentUserData();
      this.dashboardService.getLatestEvents(data);
      let events = data[2];
      if (events.length) {
        for (let event of events) {
          this.toastr.info(`${event?.message}.`, ' ', { timeOut: 3000 });        
          if(user.enableNotificationSound != false)
          {
               this.commonService.playAudio();
            }
          this.notifications.unshift(event);
        }
      }
    }));

    this._sub.add(this.dashboardService.updateSmartNotification().subscribe((event: [string, INotification]) => {
      if (event.length) {
        this.notifications = this.notifications.filter((n) => n.id !== event[1].id);
        this.notificationAlertService.notificationEvent({ type: 'Update', notification: event[1], isOpenViewModal: this.isOpenViewModal, shipmentId: event[1].shipmentId });
        if (this.notifications.length < 20) { this.getAllNotification() }
        this.isOpenViewModal = false;
      }
    }));

    this._sub.add(this.dashboardService.removedSmartNotification().subscribe((event: [string, INotification]) => {
      if (event) {
        this.notifications = this.notifications.filter((n) => n.id !== event[1].id);
        this.notificationAlertService.notificationEvent({ type: 'Delete', notification: event[1], isOpenViewModal: false, shipmentId: event[1].shipmentId });
        if (this.notifications.length < 20) { this.getAllNotification() }
      }
    }));

    this._sub.add(this.notificationAlertService.notifications.subscribe((event: INotifiactionAction) => {
      // this._sub.add(this.dashboardService.getLatestEvent().subscribe(() => { }));
      // console.log('event---------------', event)
      if (event.type && event.notification) {
        //   this.notifications = this.notifications.filter((n) => n.id !== event.notification.id);
        //   if (event.type === 'Update' || (event.type === 'Delete' && !event.notification.readStatus)) {
        //     console.log('event.type------------------', event.type)
        //     // this.notificationCounter = this.notificationCounter - 1;
        //   }
        //   if (this.notifications.length < 20) { this.getAllNotification() }
        // this.route.navigate(['utility', 'alerts-&-notifications']);
      }
    }));

  }

  togglePlayPause() {
    this.isPlaying = !this.isPlaying;
    const { user } = this.commonService.getCurrentUserData();
  this.currentUser=user
    const userUpdateData = {
      id: user.id,
      enableNotificationSound: this.isPlaying,
      fullName: user.fullName,
      officialEmail: user.officialEmail,
      address: user.address || "",
      contactNumber: user.contactNumber || "",
      city: user.city || "",
      state: user.state || "",
      zipCode: user.zipCode || "",
      country: user.country || "",
      userLevel: user.userLevel || "",
      sendEmailAlerts: user.sendEmailAlerts || false,
      sendDailyReport: user.sendDailyReport || false,
    };
    this._sub.add(
      this.accessSer.editProfile(userUpdateData).pipe(
        switchMap((response) => {
          return this.accessSer.getUserManagement();
        })
      ).subscribe(
        (res) => {
          this.userData = res['data'];
          this.currentUser.enableNotificationSound = this.userData[0].enableNotificationSound || this.isPlaying;
          this.commonService.setUserData(this.currentUser);
         // window.location.reload();
        //  setTimeout(() => {
          
        //   window.location.reload();
        // }, 100);
        
          this.toastr.success('User Updated Successfully', ' ', {
            timeOut: 3000,
          });
        },
        (error) => {
          // Error handling
          console.log(error);
          if (error?.message === API_ERROR.USER_LOGOUT) {
            this.commonService.logout(API_ERROR.USER_LOGOUT);
          } else {
            this.toastr.error(
              "Can't be updated at the moment. Please try again.",
              ' ',
              {
                timeOut: 3000,
              }
            );
          }
        }
      )
    );
}



  


  getNotifications(): void {
    this._sub.add(this.dashboardService.getSmartNotification().subscribe((res: INotification) => {
      if (res) {
        const { user } = this.commonService.getCurrentUserData();
        this.notifications.unshift(res);
        this.toastr.info(res.message, ' ', {
          timeOut: 3000
        });
        if(user.enableNotificationSound != false)
        {
          this.commonService.playAudio()
        }
        // this.notificationCounter = this.notificationCounter + 1;
        //this.commonService.playAudio();
      }

    }));
  }

  updateNotificationStatus(id): void {
    this.isOpenViewModal = true;
    this._sub.add(this.notificationAlertService.updateNotificationReadStatus(id).subscribe((res: any) => {
      this.route.navigate(['utility', 'alerts-&-notifications']);
    }
      , (error: HttpErrorResponse) => {
        console.log('error-----------', error);
        this.isOpenViewModal = false;
      }));
  }

  getAllNotification(): void {
    const query = `readStatus=false&limit=20&userLevel=${this.user.userLevel}&userId=${this.user.id}`;
    if (this.user && this?.user?.userLevel !== "SUPERADMINUSER") {
      this._sub.add(this.notificationAlertService.getAllNotifications(query).subscribe((res: any) => {
        if (res) {
          this.notifications = res.items;
          // this.notificationCounter = res.meta.totalItems;
        }
      }, (error: HttpErrorResponse) => {
        console.log('error-----------', error);
        if (error?.message === API_ERROR.USER_LOGOUT) {
          this.commonService.logout(API_ERROR.USER_LOGOUT);
        } else {
          this.toastr.error(error.message, 'Error', {
            timeOut: 3000
          });
        }
      }));
    }
  }


  async processBlob(blob: Blob) {
    const jsonString = await this.blobToText(blob);
    const jsonData = JSON.parse(jsonString);
    return jsonData; // Process the JSON data as needed
  }

  private async blobToText(blob: Blob): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.onerror = reject;
      reader.readAsText(blob);
    });
  }

  generateReport(isDownload?: boolean) {
    this.downloadReportButton = true;
    this.dashboardService.downloadOverallReport(this.user, isDownload).subscribe(async(res: any) => {
      if (res.hasOwnProperty('download') && !res['download']) {
        this.toaster.success(API_SUCCESS.EMAIL_REPORT_SUCCESS);
      } else {
        try {
          const resObj = await this.processBlob(res);
          if (resObj['code'] === 201) {
            this.toaster.info(API_SUCCESS.NO_DATA_FOUND);
          }
        } catch {
          if (res.type === 'application/zip'){
            const blob = new Blob([res], { type: 'application/zip' });
            const url = window.URL.createObjectURL(blob);

            // Create a temporary anchor element to trigger the download
            const link = document.createElement('a');
            link.href = url;
            link.download = 'Reports.zip';
            link.click();

            // Clean up the URL
            window.URL.revokeObjectURL(url);
          } else {
            const myBlob = new Blob([res], { type: 'application/vnd.oasis.opendocument.spreadsheet' });
            const downloadUrl = URL.createObjectURL(myBlob);

            const a = document.createElement('a');
            a.href = downloadUrl;
            a.download = 'Report.xlsx';
            a.click();
            setTimeout(() => {
              URL.revokeObjectURL(downloadUrl);
            }, 0);
          }
        }
      }
      this.downloadReportButton = false;
    }, (error) => {
      console.log('error', error)
      this.downloadReportButton = false;
      this.toaster.error(isDownload ? API_ERROR.FILE_NOT_FOUND : API_ERROR.EMAIL_NOT_EXISTS);
    });
  }

  getProfileImage() {
  
    if (environment.enableBase64) {
      this._sub.add(this.dashboardService.getImageBase64().subscribe(
        (res: any) => {
        
          this.profilePicUrl = res.image;
        
        },
        (error) => {
          this.profilePicUrl = 'assets/images/icons/profile.jpg';
        }
      ));
    } else {
      this._sub.add(this.dashboardService.getImage().subscribe(
        (res: any) => {
          console.log("image",res)
          this.profilePicUrl = res;
        },
        (error) => {
          this.profilePicUrl = 'assets/images/icons/profile.jpg';
        }
      ));
    }
  }

  onImageUrlSet(url) {
  
    this.profilePicUrl = url;
  }

  /**
   * Description : set icon on side navigation
   * @description : set icon on side navigation
   */
  setIcons() {
    this.menuIcons = {
      Dashboard: 'fa-chart-pie',
      Reports: 'fa-chart-line',
      'Access Control': 'fa-users-cog',
      Utility: 'fa-tools',
      'IMPACT Tracker Management': 'fa-microchip',
      'Edge Management': 'fa-server',
      'Provisioning Queue': 'fa-layer-group',
      Shipments: 'fa-shipping-fast',
      'Master Data': 'fa-database'
    };

    this.subMenuIcons = {
      'User Access Policies': '',
      'User Management': '',
      'Time & Clocks': '',
      Weather: '',
      'Node Maps': '',
      'Alerts & Notifications': '',
      'Logical Groups': '',
      'Edge Chain': '',
      'Communication Chain': '',
      'Smart Contract Chain': '',
      'Identity Chain': '',
      'User Chain': '',
      'System Log': '',
      'Event Log': '',
      Ledger: '',
      Logs: '',
    };
  }

  /**
   * Description : logout function clear local storage
   * @description : logout function clear local storage
   */
  logout() {
    this.commonService.logout();
  }

  /**
   * Description : get local storage from userinde
   * @description : get local storage from user
   */
  getUserData() {
    this.isLoading = true; // Start loading
      const { user } = this.commonService.getCurrentUserData();
      this.user = user || null;
      if (user === null) {
        this.isLoading = true;  
      } else {
        this.isLoading = false; 
      }
      this.isPlaying = user.enableNotificationSound !== undefined ? user.enableNotificationSound : true;     
    };

  /**
   * Description : get accesscontrol of user
   * @description : get accesscontrol of user
  */
  getAccessControl() {
    const accesslevel = this.user.accesslevel;
    if (accesslevel?.permissions) {
      if (this.user?.userLevel !== 'SUPERADMINUSER') {
        delete accesslevel?.permissions['Master Data'];
      }
      const controls: { [key: string]: IPermission } = accesslevel.permissions;
      for (const menu in accesslevel.permissions) {
        const ctrlObj: IPermission = controls[menu];
        if (ctrlObj.view) {
          const menuItem: IMenu = {
            name: ctrlObj.name === 'End Point Management' ? `${this.constantVariables.ENDPOINT.LABEL1} Management` : ctrlObj.name,
            path: Menu_Path[ctrlObj.name] || '',
            isOpen: false,
            submenu: []
          };
          for (const subControl in ctrlObj.submenu) {
            if (ctrlObj?.submenu[subControl]?.view) {
              const path = ctrlObj?.submenu[subControl].name.replace(/\s+/g, '-').toLowerCase();
              menuItem.submenu.push({
                name: ctrlObj?.submenu[subControl].name,
                path: `${menuItem.path}/${path}`
              });
            }
          }
          this.menuControls.push(menuItem);
        }
      }
    }
  }

  /**
   * Description : Menu open class toggle method
   * @description : Menu open class toggle method
   */
  clickEvent() {
    this.toggle = !this.toggle;
    if (this.toggle) {
      this.renderer.removeClass(document.body, 'sidebar-collapse');
    } else {
      this.renderer.addClass(document.body, 'sidebar-collapse');
    }
  }

  /**
   * Description : navigate menu
   * @description : navigate menu
   */
  openMenu(menu: IMenu) {
    this.menuControls.forEach(item => item.isOpen = !item.isOpen && item.name === menu.name ? true : false);
  }

  /**
   * Description : get short name on side nav
   * @description : get short name on side nav
   */
  getShortName() {
    const userFullName: string = this.user.fullName;
    const fullNameArr = userFullName ? userFullName.split(' ')
      : this.user.officialEmail.substring(0, this.user.officialEmail.lastIndexOf('@'));
    let shortName = '';
    for (let i = 0; i < fullNameArr.length; i++) {
      shortName = shortName + fullNameArr[i].substring(0, 1);
    }
    return shortName;
  }

  // manage widgets filter
  onClick1(event: boolean): void {
    this.dashboardService.toggleDevicesFilter(event);
  }

  onClick2(event: boolean): void {
    this.dashboardService.toggleAvgcountsFilter(event);
  }

  onClick3(event: boolean): void {
    this.dashboardService.toggleSensorsFilter(event);
  }

  // onClick4(event: boolean): void {
  // this.activityFilter.emit(event);
  // }

  onClick6(event: boolean): void {
    this.dashboardService.togglePqueueFilter(event);
  }

  onClick7(event: boolean): void {
    this.dashboardService.toggleTeventsFilter(event);
  }
  onClick8(event: boolean): void {
    this.dashboardService.toggleNodemapsFilter(event);
  }

  onClickMap(event: boolean): void {
    this.dashboardService.toggleMapCardSection(event);
  }

  onClickEventLog(event: boolean): void {
    this.dashboardService.toggleEventLogCardSection(event);
  }

  getWeather() {
    if (this.user?.zipCode && this.user?.country) {
      this._sub.add(this.weatherService.getWeather(this.user?.zipCode, this.country).subscribe(
        (res) => {
          this.weatherData = res;
          this.location = this.weatherData.name;
          this.currentTemp = this.weatherData.main.temp;
        },
        (error) => {
          console.log('error', error);
        }
      ));
    }
  }

  /**
   * Description: copy domain url to clipboard
   * @description copy domain url to clipboard
   */
  copyUrlToClipboard(element: HTMLInputElement) {
    element.select();
    document.execCommand('copy');
    this.toaster.success(API_SUCCESS.LINKS_COPIED);
  }

  open(content: TemplateRef<any>) {
    this.modalService.open(content, {
      size: 'sm',
      windowClass: 'welcmScreen-popup',
    });
  }

  openPopup() {
    this.dashboardService.togglePopup(true);
  }

  getCountryCode() {
    const country = this.selectedCountries.find(x => x.name === this.user?.country);
    if (country) {
      this.country = country.code;
    }
    // for (let i = 0; i < this.selectedCountries.length; i++) {
    //   if (this.user?.country == this.selectedCountries[i].name) {
    //     this.country = this.selectedCountries[i].code;
    //   }
    // }
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
    clearInterval(this.intervalId);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
