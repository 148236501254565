import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { parse } from 'echarts/extension/dataTool/gexf';
import { NodeMapService } from './node-map.service';
import { EChartOption } from 'echarts';
import { NgxSpinnerService } from 'ngx-spinner';
import { handleError } from 'src/app/shared/utilites/http-error-handling';

@Component({
  selector: 'app-node-map',
  templateUrl: './node-map.component.html',
  styleUrls: ['./node-map.component.css']
})
export class NodeMapComponent implements OnInit {
  options: Observable<any>;
  constructor(private http: HttpClient,
    private nodeMapService:NodeMapService,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.getNodeMap();
  }

  /**
   * Description: This method will return node map graph
   */
  getNodeMap() {
    this.spinner.show()
    this.options = this.nodeMapService.getNodeMap().pipe(
      switchMap(resp => {
        return this.http.get('assets/data/les-miserables.gexf', { responseType: 'text' }).pipe(
          map(xml => ({ resp, xml }))
        )
      }),
      map(({ resp, xml }) => {
        let graph = parse(xml);
          if (resp) {
            graph = resp;
          }
          const categories = [

            { name: 'Aggregation Node' },
            { name: 'Broker Node' },
            {
              name: 'Slave'
            },
            {
              name: 'End Device'
            },
            // { name: 'PoE Switch' },

            // { name: 'Spicebox' },
            // {
            //   name: 'Light'
            // },
            // { name: 'LED' },
            // { name: 'Motion' },
            // { name: 'Wall Switch' },
            // {
            //   name: 'Sensors'
            // }
          ];
          cursor: "pointer"
          graph.nodes.forEach(function (node) {
            let classNo = 0;
            if (node.name.indexOf("Aggregation Node") != -1) {
              classNo = 0;
            } else if (node.name.indexOf("Broker Node") != -1) {
              classNo = 1;
            } else if (node.name.indexOf("Slave") != -1) {
              classNo = 2;
            } else if (node.name.indexOf("End Device") != -1) {
              classNo = 3;
            }

            //else if (node.name.indexOf("PoE") != -1) {
            //   classNo = 1;
            // } else if (node.name.indexOf("Spice") != -1) {
            //   classNo = 2;
            // } else if (node.name.indexOf("ALS") != -1) {
            //   classNo = 3;
            //   var replacedName = node.name.replace(/^.+:/, 'Light : ');
            //   node.name = replacedName;
            // } else if (node.name.indexOf("LED") != -1) {
            //   classNo = 4;
            // } else if (node.name.indexOf("PIR") != -1) {
            //   classNo = 5;
            //   var replacedName = node.name.replace(/^.+:/, 'Motion : ');
            //   node.name = replacedName;
            // } else if (node.name.indexOf("6BC") != -1) {
            //   classNo = 6;
            //   var replacedName = node.name.replace(/^.+:/, 'Wall Switch : ');
            //   node.name = replacedName;
            // } else {
            //   classNo = 7;
            // }
            node.itemStyle = null;
            node.symbolSize = 7;
            node.value = node.symbolSize;
            node.category = classNo;
            // Use random x, y
            node.x = node.y = null;
            node.draggable = true;
          });
          return {
            tooltip: {
              trigger: 'item',
              textStyle: {
                fontSize: '12',
              },
              formatter: function (params) {
                if (params.dataType == "node") {
                  return `${params.name}`;
                } else {
                  let sourceNode = graph.nodes.find(x => x.id === params.data.source);
                  let deviceNameSource = sourceNode.name.split(' : ');
                  let targetNode = graph.nodes.find(x => x.id === params.data.target);
                  let deviceNametarget = targetNode.name.split(' : ');
                  if (deviceNameSource[0].indexOf('End Device') != - 1) {
                    deviceNameSource[0] = deviceNameSource[0].split(',')[0];
                  } else if (deviceNametarget[0].indexOf('End Device') != - 1) {
                    deviceNametarget[0] = deviceNametarget[0].split(',')[0];
                  }
                  return `${deviceNameSource[0]}  > ${deviceNametarget[0]}`;
                }
              }
            },
            legend: [{
              type: 'scroll',
              top: 0,
              left: 0,
              orient: 'vertical',
              itemWidth: 20,
              itemHeight: 12,
              data: categories.map(function (a) {
                return a.name;
              })
            }] as EChartOption.Legend,
            animation: false,
            series: [
              {
                name: '',
                type: 'graph',
                layout: 'force',
                data: graph.nodes,
                links: graph.links,
                categories: categories,
                roam: true,
                label: {
                  show: false,
                  position: "inside",
                  fontSize: 1,
                  lineHeight: 0,
                  color: "rgba(255, 255, 255, 0)",
                  cursor: "pointer",
                },
                force: {
                  repulsion: 100
                }
              }
            ]
          };
      }),
      tap(() => this.spinner.hide()),
      catchError(error => {
        this.spinner.hide();
        return handleError(error);
      })
    );

  }

}
