import { Component, OnDestroy, OnInit, ViewChild  } from '@angular/core';
import { NgScrollbar } from 'ngx-scrollbar';
import { Subscription } from 'rxjs/internal/Subscription';
import { CommonService } from 'src/app/core/services/common.service';
import { DashboardService } from 'src/app/modules/dashboard/services/dashboard.service';
import { ReportsService } from 'src/app/modules/reports/reports.service';
import { ShipmentsService } from 'src/app/modules/shipments/services/shipments.service';
import { ConstantVariables } from 'src/constants/constants';
import { CommunicationChainService } from '../../../services/communication-chain.service';

@Component({
  selector: 'app-event-log',
  templateUrl: './event-log.component.html',
  styleUrls: ['./event-log.component.css']
})
export class EventLogComponent implements OnInit, OnDestroy {

  @ViewChild(NgScrollbar, { static: true }) scrollbarRef: NgScrollbar;
  eventData : any;
  config: { itemsPerPage: number; currentPage: number; totalItems: number; };
  shipments = [];
  totalEvents = 0;
  //Socket
  receiveEventsSubscription: Subscription;
  socketConnectionId:string;

  constructor(
    private reportsService: ReportsService,
    private dashboardService: DashboardService,
    private commonService:CommonService,
    public constantVariables: ConstantVariables,
    private shipmentService: ShipmentsService,
    private communicationChainService: CommunicationChainService
  ) { }

  ngOnInit(): void {
    const { user } = this.commonService.getCurrentUserData();
    this.socketConnectionId = user.token + new Date().getTime();
    this.dashboardService.connectSocket(this.socketConnectionId);
    this.receiveEventsSubscription = this.dashboardService.receiveEvents().subscribe((event: any) => {
      if(this.socketConnectionId == event[0]) {
        // this.generateEventListFromData(event[1]);
      }
    });
    // this.getAllEventList();

    // this.getEventListData();

    this.getShipments();

  }

  ngOnDestroy() {
    this.dashboardService.disconnectSocket();
    this.receiveEventsSubscription.unsubscribe();
  }

  /**
   * @description get all shipments 
  */
  getShipments() {
    this.shipmentService.getShipmentsList(`limit=500`).subscribe((res) => {
      this.shipments = res.items;
      const shipmentsId = [];
      for(let shipment of this.shipments) { shipmentsId.push(shipment.id)}

      this.communicationChainService.getAllEventDataById(shipmentsId, true,1)
        .subscribe((chain: any) => { 
          this.generateEventListFromData(chain.items)
          this.totalEvents = chain.meta.totalPages-1;
          // console.log('chain----------', JSON.stringify(chain));
        }, ((error) => {console.log('error--------', error)}))
    })
  }

  /**
   * Description: get events api call
   * @description get events api call
  */
  // getAllEventList() {
  //   this.receiveEventsSubscription.add(this.reportsService.getReportInfo('event').subscribe(
  //     ((res) => {
  //       this.eventData = res;
  //       this.eventData.splice(0, 1);
  //       if (this.eventData && this.eventData.length > 0) {
  //         this.config = {
  //           itemsPerPage:10,
  //           currentPage:1,
  //           totalItems: this.eventData.length,
  //         }
  //       }
  //       try {
  //         for (let i = 0; i < this.eventData.length; i++) {
  //           const parsedData = this.commonService.parseEventPayload(this.eventData[i].payload);
  //           if (parsedData){
  //             this.eventData[i] = { ...this.eventData[i], ...parsedData }
  //             this.eventData[i]['time'] = new Date(this.eventData[i].timeStamp * 1000);
  //           } else {
  //             this.eventData.splice(i, 1);
  //           }
  //         }
  //       } catch (error) {
  //         console.log('error: ' + error);
  //       }

  //     }),
  //     (error => {
  //       console.log('error: ' + error)
  //     })
  //   ));
  // }

  getEventListData(){
    this.dashboardService.requestEvents();
    setTimeout(() => {
      this.getEventListData();
    }, 15000);
  }

  getMoreEvents(event : any){
    let page = event.page + 1 ;
    let shipmentIds = this.shipments.map((shipment) => shipment.id)
    this.communicationChainService.getAllEventDataById(shipmentIds, true, page)
    .subscribe((chain: any) => {
      this.generateEventListFromData(chain.items)
    }, ((error) => { console.log('error--------', error) }))

  }

  generateEventListFromData(data) {
    this.eventData = data;
    // this.eventData.splice(0, 1);
    if (this.eventData && this.eventData.length > 0) {
      this.config = {
        itemsPerPage: 10,
        currentPage: 1,
        totalItems: this.eventData.length,
      }
    }
    try {
      for (let i = 0; i < this.eventData.length; i++) {
        const parsedData = this.commonService.parseEventPayload(this.eventData[i].payload);
        if (parsedData) {
          this.eventData[i] = { ...this.eventData[i], ...parsedData }
          this.eventData[i]['time'] = new Date(this.eventData[i].timeStamp * 1000);
          // console.log(this.eventData[i].shipmentId)
          const shipment = this.shipments.find((s) => s.id === this.eventData[i].shipmentId)
          // console.log(shipment.id, shipment.shipmentName)
          this.eventData[i].shipmentId;
          this.eventData[i]['shipmentName'] = shipment.shipmentName;
        } else {
          this.eventData.splice(i, 1);
        }
      }
    } catch (error) {

    }
  }
}
