import { Injectable } from '@angular/core';
import { HttpEvent, HttpRequest, HttpResponse, HttpInterceptor, HttpHandler } from '@angular/common/http';
import { Observable,of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { RequestCache } from '../services/request-cache.service';

@Injectable()
export class CachingInterceptor implements HttpInterceptor {
  constructor(private cache: RequestCache) {}
  UrlList = ['dashboard'];
  skipGetURL = [
    // "send-command"
  ];

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // if (req.method == "GET") {
    const cachedResponse = this.cache.get(req);
    if (cachedResponse) {
      return of(cachedResponse);
    } else {
      const tempCachedResponse = this.cache.getTemp(req);
      if (tempCachedResponse) {
        this.sendRequest(req, next, this.cache);
        return of(tempCachedResponse);
      } else {
        this.sendRequest(req, next, this.cache);
      }
    }
    return cachedResponse
      ? of(cachedResponse)
      : this.sendRequest(req, next, this.cache);
    // } else {
    //     this.sendRequest(req, next, this.cache);
    // }
  }

  sendRequest(
    req: HttpRequest<any>,
    next: HttpHandler,
    cache: RequestCache
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((event) => {
        if (event instanceof HttpResponse && this.checkinList(req.url)) {
          if (req.method == 'GET' && !this.checkinSkipList(req.url))
            this.cache.put(req, event);
          else this.cache.delete(req, event, this.UrlList);
        }
      })
    );
  }

  checkinList(url: string) {
    let addCache = false;
    for (const URL of this.UrlList) {
      if (url && url.indexOf(URL) != -1) {
        addCache = true;
        break;
      }
    }
    return addCache;
  }

  checkinSkipList(url: string) {
    let skip = false;
    for (const URL of this.skipGetURL) {
      if (url && url.indexOf(URL) != -1) {
        skip = true;
        break;
      }
    }
    return skip;
  }
}
