import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgScrollbar } from 'ngx-scrollbar';
import { CommonService } from 'src/app/core/services/common.service';
import { DashboardService } from 'src/app/modules/dashboard/services/dashboard.service';

@Component({
  selector: 'app-utility',
  templateUrl: './utility.component.html',
  styleUrls: ['./utility.component.css']
})
export class UtilityComponent implements OnInit, OnDestroy {

  @ViewChild(NgScrollbar, { static: true }) scrollbarRef: NgScrollbar;
  userLevel: any;
  user = null;

  constructor(private commonService:CommonService,
    private dashboardService: DashboardService,
  ) { }

  ngOnInit(): void {
    const { user } = this.commonService.getCurrentUserData();
    this.user = user;
    if (user.userLevel == 'User')
      this.userLevel = false;
    else
      this.userLevel = true;
    
    if (user) {
      const socketConnectionId = user.token + new Date().getTime();
      this.dashboardService.connectSocket(socketConnectionId);
      this.dashboardService.getNotificationData();
    }
  }

  ngOnDestroy(): void {
    this.dashboardService.disconnectSocket();
  }

}
