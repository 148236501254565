import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MustMatchValidators } from 'src/app/shared/utilites/must-match.validators';
import { AuthService } from 'src/app/core/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/core/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { API_SUCCESS } from 'src/app/core/constants/global-success.constants';
import { API_ERROR } from 'src/app/core/constants/global-error.constants';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  passwordEye: boolean;
  confirmPasswordEye: boolean;
  resetPwdForm: FormGroup;
  loading: boolean;

  constructor(private formBuilder:FormBuilder, private route:Router, private toastr:ToastrService, private authService:AuthService, private commonService:CommonService, private activeRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.checkResetLinkExpired();
    this.initializeResetPwdForm();
  }

  initializeResetPwdForm() {
    this.resetPwdForm = this.formBuilder.group({
      password: ['', [Validators.required,Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,30}$/)]],
      confirmPassword: ['', [Validators.required,Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,30}$/)]],
    }, {
      validators:MustMatchValidators('password','confirmPassword')
    }
    )
  }

  checkResetLinkExpired() {
    const resetPwdToken = this.activeRoute.snapshot.queryParamMap.get('token');
    const decrypt_token = this.commonService.getDecryptedToken(resetPwdToken);
    if (decrypt_token) {
      this.authService.resetLinkExpired(decrypt_token).subscribe(
        (res => {
          // console.log('res', res);
        }),
        (error => {
          if (error.error.message == API_ERROR.LINK_ALREADY_USE) {
            this.toastr.error(API_ERROR.LINK_ALREADY_USE)  
            this.route.navigate(['forgot-password']);
          } else if (error.error.message == API_ERROR.LINK_EXPIRED) {
            this.toastr.error(API_ERROR.LINK_EXPIRED);
            this.route.navigate(['forgot-password']);
          } else {
            this.toastr.error(API_ERROR.SOMETHING_WENT_WRONG)  
          }
        })
      )
    }
  }

  sendResetPassword() {
    this.loading = true;
    const resetPwdToken = this.activeRoute.snapshot.queryParamMap.get('token');
    const decrypt_token = this.commonService.getDecryptedToken(resetPwdToken);
    if (decrypt_token) {
      const officialEmail = decrypt_token.emailId ? decrypt_token.emailId : decrypt_token.officialEmail
      const data = { ...this.resetPwdForm.value, officialEmail, id: decrypt_token.id,urlId:decrypt_token.urlId };
      this.authService.resetPassword(data).subscribe(
        (res) => {
          this.loading = false;
          this.toastr.success(API_SUCCESS.RESET_SUCCESSFULLY);
          this.resetPwdForm.reset();
          this.route.navigate(['login']);
        },
        (error) => {
          this.loading = false;
          if (error.message) {
            this.toastr.error('Unable to update your password at the moment. Please try again.', '');
          } else {
            this.toastr.error(API_ERROR.SOMETHING_WENT_WRONG, '');
          }
        }
      )
    }
  }

  get f() {
    return this.resetPwdForm.controls;
  }

  get pwdValue() {
    return this.resetPwdForm.controls['password'].value;
  }

  get confPwdValue() {
    return this.resetPwdForm.controls['confirmPassword'].value;
  }

   /*toggle eye icon for password*/
   toggelEyeButtonPassword() {
    if (this.f.password.value)
      this.passwordEye = !this.passwordEye;
  }

  /*toggle eye icon for confirmPassword*/
  toggelEyeButtonConPassword() {
    if (this.f.confirmPassword.value)
      this.confirmPasswordEye = !this.confirmPasswordEye;
  }

}
