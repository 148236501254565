import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DeviceRoutingModule } from './device-routing.module';
import { DeviceComponent } from './components/device/device.component';
import { TouchScreenComponent } from './components/touch-screen/touch-screen.component';
import { LedFixtureComponent } from './components/led-fixture/led-fixture.component';


@NgModule({
  declarations: [DeviceComponent, TouchScreenComponent, LedFixtureComponent],
  imports: [
    CommonModule,
    DeviceRoutingModule
  ]
})
export class DeviceModule { }
