<!-- Main content -->
<section class="content mx-3 mt-4">
  <!-- Default box -->
  <div class="card no-border">
    <div class="card-body p-0 tab-content" id="tab1content">
      <div class="row">
        <div class="col-md-2 bg-light p-0 pt-3 cus-rounded-left shadow">
          <div class="row" id="log_router">
            <div class="col-md-12">
              <ul class="nav nav-pills flex-column cus-tab" >
                <li class="nav-item text-center border-0">
                  <a
                    class="nav-link text-capitalize"
                    data-toggle="pill"
                    routerLinkActive="active"
                    routerLink="/utility/logs/system-logs"
                    href="javascript:void(0)"
                  >
                    System Log
                  </a>
                </li>
                <li class="nav-item text-center border-0">
                  <a
                    class="nav-link text-capitalize"
                    data-toggle="pill"
                    routerLinkActive="active"
                    routerLink="/utility/logs/event-logs"
                    href="javascript:void(0)"
                  >
                    Event Log
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-10 bg-white p-3 tab-content cus-rounded-right">
          <router-outlet></router-outlet>
        </div>
      </div>
      <!-- /.card -->
    </div>
    <!-- /.card-body -->
  </div>
</section>
