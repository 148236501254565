import { Component, OnInit, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TimeZoneService } from './time-zone.service';
import * as moment from 'moment-timezone';
import { Moment } from 'moment';
import 'moment-range';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/core/services/common.service';
import { API_ERROR } from 'src/app/core/constants/global-error.constants';
// import dayGridPlugin from '@fullcalendar/daygrid';
// import timeGrigPlugin from '@fullcalendar/timegrid';
// import interactionPlugin from '@fullcalendar/interaction';
// import bootstrapPlugin from '@fullcalendar/bootstrap';

@Component({
  selector: 'app-time-clock',
  templateUrl: './time-clock.component.html',
  styleUrls: ['./time-clock.component.css']
})
export class TimeClockComponent implements OnInit, OnDestroy {

  // public calendarPlugins = [dayGridPlugin, timeGrigPlugin, interactionPlugin, bootstrapPlugin];
  public eventsArr = [{ title: 'event 1', date: '2020-06-06' },
      { title: 'event 2', date: '2020-06-22' },
      { title: 'All Day Event', date: '2020-06-29' }];

  public tzNames: string[];

  public userTz: string;
  public selectedTz: string;
  public utcTz: string;
  public displayTz: string;

  public selectedLocale: string;

  public date: any;
  public fromNow: string;

  public a: moment.Moment;
  public aFormat: string;
  public aUtcFormat: string;
  public aDateFormat: string;
  public aTimeFormat: string;
  public aSpecialFormat: string;
  public tenantDateTime: any;
  public tenantDateTimeFormatted: any;
  public clocktype: any;
  public IsAnalog: boolean;
  public offsetTmz :any  = []
  // public tenantDateTime: moment.Moment;
  // public tenantDateTimeFormatted: moment.Moment;

  public utcDateTime: Moment;

  private format = 'LLL Z z';
  private dateFormat = 'L';
  private timeFormat = 'LTS';

  private allFormats = [
      'LT',  // 12:32 PM
      'LT (UTC Z)',  // 12:32 PM -08:00
      'LTS', // 12:32:18 PM
      'LTS (UTC Z)', // 12:32:18 PM -08:00
      'HH:mm:ss.SSS',
      'HH:mm:ss.SSS (UTC Z)',
      'L',   // 10/05/2018
      'LL',  // October 5, 2018
      'LLL', // October 5, 2018 12:32 PM
      'LLL (UTC Z)',  // 12:32 PM -08:00
      'LLLL',// Friday, October 5, 2018 12:32 PM
      'LLLL (UTC Z)'
  ]
  user: any;
  userInfo: any

  private _sub: Subscription = new Subscription();


  constructor(private timeZoneService: TimeZoneService, private toastr: ToastrService, private commonService: CommonService) {
      this.userTz = moment.tz.guess();
      this.utcTz = 'UTC'
      this.tzNames = moment.tz.names();
      for(const i in this.tzNames)
      {
          this.offsetTmz.push( {name: this.tzNames[i] + " ("+moment.tz(this.tzNames[i]).format('z Z')+")", value: this.tzNames[i] });
      }


  }
  interval;
  /**
   * description: on change time
   * @description on change time
   * @param timeZone
   */
  public timeZoneChanged(timeZone: string): void {

      clearInterval(this.interval);
      this.selectedTz = timeZone;

      this.interval = setInterval(function () {
          // this.updateTime(this.selectedTz);
          this.displayTz = timeZone;

          this.date = moment.utc();
          this.fromNow = this.date.fromNow();

          this.a = moment.tz(timeZone);

          const momentNow = this.a;
          $('#date-part').html(momentNow.format('YYYY MMMM DD') + ' '
              + momentNow.format('dddd')
                  .substring(0, 3).toUpperCase());
          document.documentElement.style.setProperty('--timer-day', "'" + moment().format("ddd") + "'");
          document.documentElement.style.setProperty('--timer-hours', "'" + momentNow.format('hh'));
          document.documentElement.style.setProperty('--timer-minutes', "'" + momentNow.format('mm'));
          $('#ss').html(momentNow.format('ss'));
      }, 100);
  }

/**
   * description: on change time
   * @description display timezone
   * @param displayTz time to display
*/
  public changeDisplayTz(displayTz: string): void {
      this.updateTime(displayTz);
  }

  /**
   * description: change locale
   * @description change locale
   * @param locale selected timezone
*/
  public changeLocale(locale: string) {
      this.selectedLocale = locale;
      moment.locale(this.selectedLocale);
      this.updateTime(this.selectedTz);
  }

    /**
   * description: format time
   * @description format time
   * @param format format time
*/
  public formats(format: string): string {
      return this.a.format(format);
  }

  private updateTime(timeZone: string) {


  }


  ngOnInit() {
    this.getCurrentUserData();
      if (sessionStorage.getItem('timeZone')){
          this.timeZoneChanged(sessionStorage.getItem('timeZone'));
          this.selectedTz = sessionStorage.getItem('timeZone')
      }   else{
          this.timeZoneChanged('America/New_York');
      }
      this.changeLocale('en');
  }

  ngOnDestroy(){
      clearInterval(this.interval);
      this._sub.unsubscribe();
  }

  /**
   * Description : set timezone api call
   * @description set timezone api call
   */
  setTimeZone() {
      const body = {
          "timezone": this.selectedTz,
          "userId": this.user.id
      }
      this._sub.add(this.timeZoneService.UpdateTimezone(body).subscribe(
          (response) => {
              response;
              this.toastr.success(this.selectedTz + ' TimeZone updated successfully', ' ', {
                  timeOut: 3000
              });
              sessionStorage.setItem('timeZone',this.selectedTz);
          },
          (error) => {
              if (error?.message === API_ERROR.USER_LOGOUT) { 
                  this.commonService.logout(API_ERROR.USER_LOGOUT);
              } else {
                  this.toastr.error('Timezone can\'t be updated the moment. Please try again.', ' ', {
                      timeOut: 3000
                  });
              }
          }
      ));

  }

/**
   * Description : get user local storage
   * @description get user from local storage
   */
  getCurrentUserData() {
    const userData = sessionStorage.getItem('data');
    const { user } = this.commonService.getCurrentUserData();
    this.userInfo = user;
    if (userData !== null) {
      this.user = JSON.parse(window.atob(userData));
    }
  }

}
