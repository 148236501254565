import { Component, OnInit, ViewChild } from '@angular/core';
import { NgScrollbar } from 'ngx-scrollbar';
import { CommonService } from 'src/app/core/services/common.service';
import { DashboardService } from 'src/app/modules/dashboard/services/dashboard.service';



@Component({
  selector: 'app-access-control',
  templateUrl: './access-control.component.html',
  styleUrls: ['./access-control.component.css']
})
export class AccessControlComponent implements OnInit {

  @ViewChild(NgScrollbar, { static: true }) scrollbarRef: NgScrollbar;

  userInfo = null;

  constructor(
    private dashboardService: DashboardService,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    const { user } = this.commonService.getCurrentUserData();
    if (user) {
      this.userInfo = user;
      const socketConnectionId = user.token + new Date().getTime();
      this.dashboardService.connectSocket(socketConnectionId);
      this.dashboardService.getNotificationData();
    }
  }

  ngOnDestroy(): void {
    this.dashboardService.disconnectSocket();
  }

  downloadURI() {
    const { user } = this.commonService.getCurrentUserData();
    const userConfig = {
      TOKEN: user['token'],
      IP_ADDRESS: user['ipAddress'],
      BLOCKLOCK_SERVER_PORT: user['blocklockServerPort'],
      BROKER_BLOCKLOCK_PORT: user['brokerBlocklockPort'],
      BLOCKLOCK_CHORD_PORT: user['blocklockChordPort'],
      INITIATOR_PORT: user['initiatorPort'],
      SLAVE_BLOCKLOCK_SERVER_PORT: user['slaveBlocklockServerPort'],
      BLOCKLOCK_PORT: user['blocklockPort'],
      SLAVE_BLOCKLOCK_CHORD_PORT: user['slaveBlocklockChordPort'],
      RESPONDER_PORT: user['responderPort'],
    }
    var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(userConfig, undefined, 4));
    var downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", 'userConfig.json');
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }

}
