import { AccessService } from 'src/app/modules/access-control/services/access.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/core/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { API_SUCCESS } from 'src/app/core/constants/global-success.constants';
import { API_ERROR } from 'src/app/core/constants/global-error.constants';
import * as $ from 'jquery';
import { CommonService } from 'src/app/core/services/common.service';
import { environment } from 'src/environments/environment';
import { of, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { extractIpString, getBrowserName } from 'src/app/shared/utilites/browsers-name.util';

export interface IUserInfo {
  exp: number;
  iat: number;
  id: string;
  officialEmail: string;
  tenantName: string;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;
  loading: boolean;
  passwordEye: boolean;
  isChecked: boolean = true;
  fromEmailClick: boolean;
  emailVerifyToken: string;
  ipAddress = "";
  prevUserEmail: any;
  prevUserPwd: any;

  private _sub: Subscription = new Subscription();

  constructor(
    private authService: AuthService,
    private route: Router,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private activeRoute: ActivatedRoute,
    private accessService: AccessService
  ) {
    if (this.authService.isUserAuthenticate()) {
      const { user } = this.commonService.getCurrentUserData();
      this.route.navigate([user && user?.userLevel === "SUPERADMINUSER" ? '/master-data' : '/dashboard']);
    }
  }

  ngOnInit(): void {
    this._sub.add(this.authService.getIPAddress().subscribe((res: any) => {
      this.ipAddress = res.ip;
    }));
    /*create login form fields*/
    this.createLoginForm();
    this.getUserAndCheckEmailVerification();
    /*get remember user from sessionStorage*/
    if (sessionStorage.getItem('rememberUser') !== null) {
      const rememberData = JSON.parse(
        window.atob(sessionStorage.getItem('rememberUser'))
      );
      if (rememberData) {
        this.loginForm.controls.email.setValue(rememberData.email);
        this.loginForm.controls.password.setValue(rememberData.password);
        this.prevUserEmail = rememberData.email;
        this.prevUserPwd = rememberData.password;
        this.isChecked = true;
      }
    }
  }

  getUserAndCheckEmailVerification() {
    this.emailVerifyToken = this.activeRoute.snapshot.queryParamMap.get(
      'token'
    );
    const decrypt_token: IUserInfo = this.commonService.getDecryptedToken(this.emailVerifyToken);
    if (decrypt_token) {
      this.commonService.setAccessToken(this.emailVerifyToken);
      this._sub.add(this.authService.getUserById(decrypt_token).pipe(
        switchMap(user => {
          if (!user.emailVerified) {
            this.toastr.success(
              API_SUCCESS.EMAIL_SUCCESS,
              API_SUCCESS.EMAIL_VERIFIED_TITLE,
            );
            return this.authService.saveEmailVerifiedStatus(this.emailVerifyToken).pipe(
              map(() => user)
            );
          } else {
            this.toastr.success(
              API_SUCCESS.EMAIL_ALREADY_VERIFYIED,'',
            );
            return of(user);
          }
        })
      ).subscribe((res) => {
        console.log('User verified');
      },
      (error) => {
        console.log('error', error);
      }));
    }
  }

  /*create login form*/
  createLoginForm() {
    /*Initialized form field*/
    this.loginForm = this.formBuilder.group({
      email: [
        '',
        [
          Validators.required,
          Validators.pattern('^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ],
      ],
      password: [
        '',
        [
          Validators.required,
        ],
      ],
    });
  }

  /*convinience getter to access form fields*/
  get f() {
    return this.loginForm.controls;
  }

  /*remember user credentials and store in sessionStorage*/
  remember(e) {
    const { email, password } = this.loginForm.value;
    if (e.target.checked) {
      if (email && password) {
        sessionStorage.setItem(
          'rememberUser',
          window.btoa(JSON.stringify({ email, password }))
        );
      }
    } else {
      sessionStorage.removeItem('rememberUser');
    }
  }

  checkRembrUserAndCurrenUserAreSame() {
    if (this.prevUserEmail !== this.f.email.value && this.isChecked) {
      const { email, password } = this.loginForm.value;
      sessionStorage.setItem(
        'rememberUser',
        window.btoa(JSON.stringify({ email, password }))
      );
    }
  }


  /*login user with credentials*/
  login() {
    if (this.loginForm.invalid) {
      return;
    }

    let loginDetails = {
      email: this.loginForm.controls.email.value.toLowerCase(),
      password: window.btoa(this.loginForm.controls.password.value)
    }
    this.checkRembrUserAndCurrenUserAreSame();
    const browserName = getBrowserName();
    if (this.ipAddress === "") this.ipAddress = extractIpString();
      this.loading = true;
      this._sub.add(this.authService.login(loginDetails, this.ipAddress, browserName).pipe(
        switchMap(token => {
          this.commonService.setAccessToken(token);
          return this.authService.getUserByToken(token);
        }),
        switchMap(user => {
          return this.authService.getUserById(user);
        }),
        switchMap(user => {
          return this.accessService.getCurrentUserAccessLevel(user).pipe(
            map(resp => ({ ...user, accesslevel: resp }))
          )
        })
      ).subscribe(
        (resp) => {
          resp.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
          resp.ipAddress = this.ipAddress;
          this.commonService.setUserData(resp);
          this.route.navigate([resp?.userLevel && resp?.userLevel === "SUPERADMINUSER" ? '/master-data' : '/dashboard']);
          this.loading = false;
          this.toastr.success(API_SUCCESS.LOGIN_SUCCESS, '');
        },
        (error) => {
          if (error?.message !== API_ERROR.USER_LOGOUT) {
            if (
              error?.message == API_ERROR.INCORRECT_CREDENTIALS ||
              error?.error == API_ERROR.DATABAE_CONNECTION_ERR
            )
              this.toastr.error(API_ERROR.INVALID_LOGIN_CREDENTIALS, '');
            else if (error?.message === API_ERROR.USER_PROFILE_LOCKED){
              this.toastr.error(API_ERROR.USER_PROFILE_LOCKED);
            }
            else if (error?.message == API_ERROR.CONFIRM_EMAIL) {
              this.route.navigate(['tenant-signup'],{state:{isResendLink:true,emailForResendLink:this.f.email.value}})
            } else this.toastr.error(API_ERROR.SOMETHING_WENT_WRONG, '');
          }
          this.loading = false;
        }
      ));
  }

  /*toggle eye icon for password*/
  toggelEyeButtonPassword() {
    if (this.f.password.value) {
      this.passwordEye = !this.passwordEye;
    }
  }

  ngOnDestroy() {
    this._sub.unsubscribe();
  }
}
