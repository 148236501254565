import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ShipmentsService } from 'src/app/modules/shipments/services/shipments.service';
import { CommunicationChainService } from 'src/app/modules/utility/services/communication-chain.service';
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { GRAPH_LIMIT } from 'src/app/core/constants/level.constants';
import { DashboardService } from 'src/app/modules/dashboard/services/dashboard.service';
import { CommonService } from 'src/app/core/services/common.service';
import { ConstantVariables } from 'src/constants/constants';
import { async } from '@angular/core/testing';
import { API_ERROR } from 'src/app/core/constants/global-error.constants';
import { calculateBatteryPercentage } from 'src/app/shared/utilites/battery-percentage-calculator.util';

@Component({
  selector: 'app-alerts',
  templateUrl: './reportShipment.component.html',
  styleUrls: ['./reportShipment.component.scss']
})
export class ReportShipmentComponent implements OnInit, OnDestroy {

  public isopenSearch = false;
  public isLoading = true;
  public heatmap: any;
  filterTotalEvents: string;
  isMapLoading: boolean = true;
  shipmentData: any = null;
  map: any = null;
  trackerData: any = [];
  optionevents: any;
  eventCount: number = 0;
  bluTagList: any = [];
  shipmentId: any;
  macId: any;
  allEndPoints: [];
  statuses: any;
  socketConnectionId: string;
  optionsensorEvents: any;
  chartDataOptions = [];
  type = 'route';
  mapType = 'roadmap';
  mapData;
  zoom = 8;
  cluster;

  public chartData = {
    backgroundColor: '',
    grid: {
      top: '8%',
      left: '4%',
      right: '4%',
      bottom: '8%',
      width: 'auto',
      height: '85%',
      containLabel: true,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: [],
      axisLabel: {
        show: true,
        fontSize: 10,
        color: '#333'
      },
      // name: 'Date',
      nameLocation: 'middle',
      nameGap: 26,
      nameTextStyle: {
        color: '#333',
        fontSize: 11,
        fontWeight: 'normal',
      },
      axisTick: {
        show: true
      },
      lineStyle: {
        color: '#333'
      },
      axisLine: {
        show: true,
      },
    },
    yAxis: {
      type: 'value',
      min: GRAPH_LIMIT.MIN,
      splitNumber: 5,
      axisLine: {
        show: true
      },
      minInterval: 1,
      lineStyle: {
        color: '#333'
      },
      axisTick: { show: false },
      axisLabel: {
        show: true,
        fontSize: 9,
        color: '#333'
      },
      nameLocation: 'middle',
      nameGap: 30,
      nameTextStyle: {
        color: '#333',
        fontSize: 11,
        fontWeight: 'normal',
      },
    },
    series: [{
      type: 'line',
      data: [],
      smooth: true,
      areaStyle: {}
    }]
  };

  public calculateBatteryPercentage = calculateBatteryPercentage;

  private _sub: Subscription = new Subscription();

  constructor(
    public constantVariables: ConstantVariables,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private communicationChainService: CommunicationChainService,
    private shipmentService: ShipmentsService,
    private dashboardService: DashboardService,
    private router: Router,
  ) { }

  ngOnInit(): void {
  
    this.isLoading = true;
    const { user } = this.commonService.getCurrentUserData();
    if (user) {
      this.socketConnectionId = user.token + new Date().getTime();

      this.dashboardService.connectSocket(this.socketConnectionId);
    }
    this._sub.add(this.route.params.subscribe(params => {
      this.shipmentId = params['sid'];
    }));
    this.getShipmentData();
  }

  ngOnDestroy() {
    this.dashboardService.disconnectSocket();
    this._sub.unsubscribe();
  }

  public navigateTo() {
    this.shipmentService.pannelEvent({ openModel: true, shipmentId: this.shipmentId });
    this.router.navigateByUrl('shipments')
  }

  getShipmentData() {
   
    if (this.shipmentId) {
      this._sub.add(this.shipmentService.getShipmentById(this.shipmentId).subscribe(
        ((res: any) => {
           console.log('res-----', res)
          this.shipmentData = res;
          this.isLoading = false;
          this.getTrackerData();
        }),
        (error => {
          this.isLoading = false;
          if (error?.message === API_ERROR.USER_LOGOUT) {
            this.commonService.logout(API_ERROR.USER_LOGOUT);
          }
          console.log("error->>", error)
        })
      ));
    }
  }

  convertedDate(date) {
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const dateObj = new Date(date);
    const month = monthNames[dateObj.getMonth()];
    const day = String(dateObj.getDate()).padStart(2, '0');
    const year = dateObj.getFullYear();
    const convertDate = `${day} ${month} ${year}, ${String(dateObj.getHours()).padStart(2, '0')}:${String(dateObj.getMinutes()).padStart(2, '0')}:${String(dateObj.getSeconds()).padStart(2, '0')}`;
    return convertDate;
  }

  getTrackerData() {
  
    this.isLoading = true;
    this._sub.add(this.communicationChainService.getTrackersWithEvents([this.shipmentData]).subscribe(
      ((res) => {
       
        this.trackerData = res;
        this.eventCount = this.trackerData[0].events.length;

        if (this.eventCount) {
          this.dashboardService.requestSensorEventsData('daily', [], [this.trackerData[0].trackers[0].data], [this.shipmentId]);

          let xAxisArr: any[] = [];
          let yAxisArr: any[] = [];
          const tagsData = [];
          const alertData = {
            min: Number(this.trackerData[0].trackers[0].min),
            max: Number(this.trackerData[0].trackers[0].max)
          };

          const uniqueTag = this.shipmentData.events.filter((v, i, a) =>
            a.findIndex(t => (t.blueTag === v.blueTag)) === i);
          uniqueTag.forEach((e) => {
            let tagData = this.shipmentData.events.filter(t => (t.blueTag === e.blueTag));
            const date = [];
            const data = [];
            tagData.reverse();
            tagData.forEach((event) => {
              if (event?.temperature) {
                date.push(this.convertedDate(event.time));
                data.push(Number(event?.temperature));
              }
            });
            tagsData.push({
              bluTag: e.blueTag,
              date,
              data,
              latestTemp: data.length ? data[data.length - 1] : 'N/A'
            })
          })

          this.ChartDataGraph(tagsData, '', 'Temperature', alertData)
          if (this.trackerData[0].events.length) this.loadMap(res);
          this._sub.add(this.dashboardService.sensorEventsData().subscribe((data: any) => {
            if (this.socketConnectionId == data[0]) {
              xAxisArr = data[1]['xAxis'];
              yAxisArr = data[1]['Data'];
              this.plotSensorEvents(xAxisArr, yAxisArr, '', data[1]['ShipmentName'])
            };
          }));
        }
        this.isLoading = false;
      }), (error => {
        this.isLoading = false;
        console.log("error", error)
      })
    ));
  }

  public ChartDataGraph(tagsData, xlable, title, alertData?) {
    let maxVal = GRAPH_LIMIT.MAX;
    let minVal = GRAPH_LIMIT.MIN;
    let chart = null;
    tagsData.forEach(e => {
      if (e?.bluTag) {
        const maxEventValue = Math.max(...e.data);
        const minEventValue = Math.min(...e.data);
        if (maxVal < maxEventValue)
          maxVal = maxEventValue + Math.round(maxEventValue * GRAPH_LIMIT.PERCENTAGE_INCREASE / 100);
        minVal = minEventValue - Math.round(minEventValue * GRAPH_LIMIT.PERCENTAGE_INCREASE / 100);
        chart = {
          tooltip: {
            trigger: 'axis'
          },
          visualMap: {
            show: false,
            seriesIndex: 0,
            pieces: [
              {
                lte: alertData.min,
                color: 'rgb(250, 37, 37)'
              },
              {
                gt: alertData.min,
                lte: alertData.max,
                color: 'rgb(84, 207, 12)'
              },
              {
                gt: alertData.max,
                color: 'rgb(250, 37, 37)'
              },
            ]
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: e.date,
            splitLine: {
              show: false
            }
          },
          yAxis: {
            max: alertData.max > maxVal ? alertData.max + GRAPH_LIMIT.MAX : maxVal,
            min: alertData.min < minVal ? alertData.min - GRAPH_LIMIT.MAX : minVal,
            name: title,
            type: 'value',
            axisLabel: {
              formatter: '{value} °C'
            },
            splitLine: {
              lineStyle: {
                color: ['#BBBBBB'],
              }
            }
          },
          series: [
            {
              name: 'Temp',
              type: 'line',
              data: e.data,
              markLine: {
                symbol: 'none',
                label:
                {
                  position: 'middle',
                  show: true,
                },
                data: [
                  {
                    yAxis: alertData.max, name: 'Max', symbol: 'circle',
                    label: {
                      position: 'middle',
                      formatter: `Max Temp: ${alertData.max} °C`
                    },
                    lineStyle: {
                      color: 'rgb(250, 37, 37)'
                    }
                  },
                  {
                    yAxis: alertData.min, name: 'Min',
                    label: {
                      position: 'insideMiddleBottom',
                      formatter: e.data.length ? `Min Temp: ${alertData.min} °C` : '',
                    },
                    symbol: e.data.length ? 'circle' : null,
                    lineStyle: {
                      color: e.data.length ? 'rgb(250, 37, 37)' : 'transparent'
                    }
                  }
                ]
              },
              areaStyle: null
            }
          ]
        };
        this.chartDataOptions.push({ bluTag: e.bluTag, latestTemp: e.latestTemp, chart });
      }
    });

  }

  async loadMap(mapData) {

    let endLat = this.shipmentData.events[0].parsedData.location.split(',')[0];
    let endLong = this.shipmentData.events[0].parsedData.location.split(',')[1];

    if (this.shipmentData.shipmentType.toLowerCase() === "fixed") {
      this.type = 'fixed';
      this.mapData = { latitude: endLat, longitude: endLong };
    }
    else {
      let waypoints = [];
      let content;
      this.shipmentData.events.forEach(element => {
        const battery = element.parsedData.battery.split('V');
        let batValue = this.calculateBatteryPercentage(battery[0]);
        const batClass = this.calculateBatteryPercentage(battery[0], true);
        let bat = `<i class="fas ${batClass}"></i>`;
        let signal = element.parsedData.signal;
        let signalIcon = signal === 0 ?
          `<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-wifi-off" viewBox="2 4 10 12">
                          <path d="M10.706 3.294A12.545 12.545 0 0 0 8 3C5.259 3 2.723 3.882.663 5.379a.485.485 0 0 0-.048.736.518.518 0 0 0 .668.05A11.448 11.448 0 0 1 8 4c.63 0 1.249.05 1.852.148l.854-.854zM8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065 8.448 8.448 0 0 1 3.51-1.27L8 6zm2.596 1.404.785-.785c.63.24 1.227.545 1.785.907a.482.482 0 0 1 .063.745.525.525 0 0 1-.652.065 8.462 8.462 0 0 0-1.98-.932zM8 10l.933-.933a6.455 6.455 0 0 1 2.013.637c.285.145.326.524.1.75l-.015.015a.532.532 0 0 1-.611.09A5.478 5.478 0 0 0 8 10zm4.905-4.905.747-.747c.59.3 1.153.645 1.685 1.03a.485.485 0 0 1 .047.737.518.518 0 0 1-.668.05 11.493 11.493 0 0 0-1.811-1.07zM9.02 11.78c.238.14.236.464.04.66l-.707.706a.5.5 0 0 1-.707 0l-.707-.707c-.195-.195-.197-.518.04-.66A1.99 1.99 0 0 1 8 11.5c.374 0 .723.102 1.021.28zm4.355-9.905a.53.53 0 0 1 .75.75l-10.75 10.75a.53.53 0 0 1-.75-.75l10.75-10.75z"/>
                        </svg>` :
          signal === 1 ?
            `  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-wifi-1" viewBox="2 4 10 12">
                          <path d="M11.046 10.454c.226-.226.185-.605-.1-.75A6.473 6.473 0 0 0 8 9c-1.06 0-2.062.254-2.946.704-.285.145-.326.524-.1.75l.015.015c.16.16.407.19.611.09A5.478 5.478 0 0 1 8 10c.868 0 1.69.201 2.42.56.203.1.45.07.611-.091l.015-.015zM9.06 12.44c.196-.196.198-.52-.04-.66A1.99 1.99 0 0 0 8 11.5a1.99 1.99 0 0 0-1.02.28c-.238.14-.236.464-.04.66l.706.706a.5.5 0 0 0 .707 0l.708-.707z"/>
                        </svg>` :
            signal < 31 ?
              `<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-wifi-2" viewBox="2 4 10 12">
                        <path d="M13.229 8.271c.216-.216.194-.578-.063-.745A9.456 9.456 0 0 0 8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065A8.46 8.46 0 0 1 8 7a8.46 8.46 0 0 1 4.577 1.336c.205.132.48.108.652-.065zm-2.183 2.183c.226-.226.185-.605-.1-.75A6.473 6.473 0 0 0 8 9c-1.06 0-2.062.254-2.946.704-.285.145-.326.524-.1.75l.015.015c.16.16.408.19.611.09A5.478 5.478 0 0 1 8 10c.868 0 1.69.201 2.42.56.203.1.45.07.611-.091l.015-.015zM9.06 12.44c.196-.196.198-.52-.04-.66A1.99 1.99 0 0 0 8 11.5a1.99 1.99 0 0 0-1.02.28c-.238.14-.236.464-.04.66l.706.706a.5.5 0 0 0 .708 0l.707-.707z"/>
                      </svg>` :
              signal < 52 ?
                `<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-wifi" viewBox="2 4 10 12">
                        <path d="M15.384 6.115a.485.485 0 0 0-.047-.736A12.444 12.444 0 0 0 8 3C5.259 3 2.723 3.882.663 5.379a.485.485 0 0 0-.048.736.518.518 0 0 0 .668.05A11.448 11.448 0 0 1 8 4c2.507 0 4.827.802 6.716 2.164.205.148.49.13.668-.049z"/>
                        <path d="M13.229 8.271a.482.482 0 0 0-.063-.745A9.455 9.455 0 0 0 8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065A8.46 8.46 0 0 1 8 7a8.46 8.46 0 0 1 4.576 1.336c.206.132.48.108.653-.065zm-2.183 2.183c.226-.226.185-.605-.1-.75A6.473 6.473 0 0 0 8 9c-1.06 0-2.062.254-2.946.704-.285.145-.326.524-.1.75l.015.015c.16.16.407.19.611.09A5.478 5.478 0 0 1 8 10c.868 0 1.69.201 2.42.56.203.1.45.07.61-.091l.016-.015zM9.06 12.44c.196-.196.198-.52-.04-.66A1.99 1.99 0 0 0 8 11.5a1.99 1.99 0 0 0-1.02.28c-.238.14-.236.464-.04.66l.706.706a.5.5 0 0 0 .707 0l.707-.707z"/>
                      </svg>` :
                signal === 99 ?
                  `<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-reception-0" viewBox="2 4 10 12">
                        <path d="M0 13.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm4 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm4 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm4 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"/>
                      </svg> `: ''

        if (element.parsedData.signal) {
          content = '<b>' + '<table>' +
            '<tr>' +
            '<td>' + 'Lat.' + '</td>' +
            '<td>' + `&nbsp;&nbsp ${element.parsedData.latitude}` + '</td>' +
            '</tr>' +
            '<tr>' +
            '<td>' + 'Long.' + '</td>' +
            '<td>' + `&nbsp;&nbsp ${element.parsedData.longitude}` + '</td>' +
            '</tr>' +
            '<tr>' +
            '<td>' + 'Time' + '</td>' +
            '<td>' + `&nbsp;&nbsp ${element.time.toLocaleString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', hour12: true, minute: '2-digit', second: '2-digit' })}` + '</td>' +
            '</tr>' +
            '<tr>' +
            '<td>' + 'Battery' + '</td>' +
            '<td>' + `&nbsp;&nbsp ${bat} ${batValue}%` + '</td>' +
            '</tr>' +
            '<tr>' +
            '<td>' + 'Signal' + '</td>' +
            '<td>' + '<b>' + `&nbsp;&nbsp ${signalIcon}${signal}` + '</b>' + ` ` + '</td>' +
            '</tr>' +
            '</table>' + '</b>'
        } else {
          content = '<b>' + '<table>' +
            '<tr>' +
            '<td>' + 'Lat.' + '</td>' +
            '<td>' + `&nbsp;&nbsp ${element.parsedData.latitude}` + '</td>' +
            '</tr>' +
            '<tr>' +
            '<td>' + 'Long.' + '</td>' +
            '<td>' + `&nbsp;&nbsp ${element.parsedData.longitude}` + '</td>' +
            '</tr>' +
            '<tr>' +
            '<td>' + 'Time' + '</td>' +
            '<td>' + `&nbsp;&nbsp ${element.time.toLocaleString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', hour12: true, minute: '2-digit', second: '2-digit' })}` + '</td>' +
            '</tr>' +
            '<tr>' +
            '<td>' + 'Battery' + '</td>' +
            '<td>' + `&nbsp;&nbsp ${bat} ${batValue}%` + '</td>' +
            '</tr>' +
            '</table>' + '</b>'

        }

        if (waypoints.findIndex(element1 => element1.latitude === element.parsedData.latitude) === -1 &&
          waypoints.findIndex(element1 => element1.longitude === element.parsedData.longitude) === -1) {
          waypoints.push({
            latitude: element.parsedData.latitude,
            longitude: element.parsedData.longitude,
            time : element.timeStamp,
            content
          });
        }
      });
      waypoints.reverse();
      this.mapData = waypoints;
      this.type = "route";
    }
  }

  /**
   * @description
   * @param event
   */
  plotSensorEvents(x, y, xlable, ylabel?) {
    let maxVal = GRAPH_LIMIT.MAX;
    let maxEvents = 0;
    let yaxis = [];
    y.map((shipments, index) => {
      if (ylabel && ylabel[index]) {
        yaxis.push({
          name: ylabel && ylabel[index] ? ylabel[index] : null,
          type: 'line',
          smooth: true,
          barWidth: '40%',
          data: shipments,
          areaStyle: { opacity: 0.5 },
        })
        if (Math.max(...shipments) > maxEvents)
          maxEvents = Math.max(...shipments)
      }
    })
    if (maxVal < maxEvents)
      maxVal = maxEvents + Math.round(maxEvents * GRAPH_LIMIT.PERCENTAGE_INCREASE / 100);
    this.optionsensorEvents = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      grid: {
        top: 20,
        left: '10%',
        right: '4%',
        bottom: '8%',
        width: 'auto',
        height: '65%',
        containLabel: true,
      },
      xAxis: [
        {
          type: 'category',
          data: x,
          splitLine: {
            show: false
          },
          axisLabel: {
            show: true,
            fontSize: 10
          },
          name: xlable,
          nameLocation: 'middle',
          nameGap: 26,
          nameTextStyle: {
            color: '#666',
            fontSize: 11,
            fontWeight: 'normal',
          },
          axisTick: {
            show: true
          },
          lineStyle: {
            color: '#fff'
          },
          axisLine: {
            show: true,
          },
        }
      ],
      yAxis: [{
        type: 'value',
        min: GRAPH_LIMIT.MIN,
        max: maxVal,
        axisLine: {
          show: true,
        },
        minInterval: 1,
        lineStyle: {
          color: '#666'
        },
        axisTick: { show: false },
        axisLabel: {
          show: true,
          fontSize: 9
        },
        name: 'No. of Events',
        nameLocation: 'middle',
        nameGap: 30,
        nameTextStyle: {
          color: '#666',
          fontSize: 11,
          fontWeight: 'normal',
        },
      }],
      series: yaxis
    };
  }

}
