export const MENUS = {
    COMMAND_VALUE_1: '1',
    COMMAND_VALUE_0: '0',
    TIME_AND_CLOCK: 'Time & Clock',
    TERMINAL: 'Terminal',
    WEATHER: 'Weather',
    NODE_MAP: 'Node Map',
    DATA_STORAGE: 'Data Storage',
    CONNECTIVITY:'Connectivity',
    USER_ACCESS_POLICIES: 'User Access Policies',
    USER_MANAGEMENT: 'User Management',
    LEDGER: 'Ledger',
    LOGS:'Logs',
    IDENTITY_CHAIN: 'Identity Chain',
    EDGE_CHAIN: 'Edge Chain',
    USER_CHAIN: 'User Chain',
    SMART_CONTRACT: 'Smart Contract Chain',
    COMMUNICATION_CHAIN: 'Communication Chain',
    SYSTEM_LOG: 'System Log',
    EVENT_LOG: 'Event Log',
    UPDATE: 'Update',
    PROVISIONING_SETTINGS: 'Provisioning Settings',
    ALERT_NOTIFICATIONS: 'Alerts & Notifications',
    RESET_SETTINGS:'Reset Settings',
    REPORTS:'Reports',
    END_POINT_MANAGEMENT:'End Point Management',
    EDGE_MANAGEMENT:'Edge Management',
    PROVISIONING_QUEUE:'Provisioning Queue',
    SHIPMENTS: 'Shipments'
}