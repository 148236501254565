import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/core/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { MustMatchValidators } from 'src/app/shared/utilites/must-match.validators';
import { API_ERROR } from 'src/app/core/constants/global-error.constants';
import { UserSignup } from 'src/app/shared/models/user-signup.model';
import { CommonService } from 'src/app/core/services/common.service';
import { countries } from 'src/app/modules/dashboard/components/edit-profile/country';
import { ShipmentsService } from 'src/app/modules/shipments/services/shipments.service';

@Component({
  selector: 'app-user-signup',
  templateUrl: './user-signup.component.html',
  styleUrls: ['./user-signup.component.css']
})
export class UserSignupComponent implements OnInit {

  userSignupForm: FormGroup;
  shipmentForm: FormGroup = this.formBuilder.group({
    shipment: ['']
  });
  selectedShipments = [];
  shipmentList = [];
  loading: boolean;
  display = 'none';
  displayShipment = 'none';
  submitted: boolean;
  passwordEye: boolean;
  confirmPasswordEye: boolean;
  org: string;
  selectcountry: any = '';
  selectcountries: any = countries;
  isInvalid: boolean;

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private toaster: ToastrService,
    private activeRoute: ActivatedRoute,
    private commonService: CommonService,
    private shipmentService: ShipmentsService
  ) { }

  ngOnInit(): void {
    this.org = this.activeRoute.snapshot.queryParamMap.get('org');
    this.createForm();
    this.getShipments();
  }

  /*create signup form */
  createForm() {
    /*Initialize all signup form fields with validation*/
    this.userSignupForm = this.formBuilder.group({
      fullName: ['', Validators.required],
      officialEmail: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      password: ['', [Validators.required, Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,30}$/)]],
      confirmPassword: ['', [Validators.required, Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,30}$/)]],
      organizationName: [this.org],
      // address: [''],
      // city: [''],
      // country: ['',Validators.required],
      // state: [''],
      // zipCode: ['',Validators.required],
      // contactNumber: ['', [Validators.required,Validators.pattern("^[0-9]{10}$")]],
      privacyPolicyTC: [false, Validators.requiredTrue],
    }, {
      validators: MustMatchValidators('password', 'confirmPassword')
    });

    this.userSignupForm.valueChanges.subscribe(() => { if (this.isInvalid) { this.isInvalid = false;}})
  }

  /* Get Shipment List */
  getShipments() {
    this.shipmentService.getShipmentsList('limit=500', true).subscribe(
      ((res: any) => {
        let list: any = res.items;
        this.shipmentList = list.map(({ shipmentName, id }) => ({ shipmentName, id }))
      })
    );
  }

  /*convinience getter to easy access form fields*/
  get f() {
    return this.userSignupForm.controls;
  }

  /** 
   * validate signup form and send data to authservice signup method 
   * based on response account created popup will display 
   * */
  userSignUp(): void {
    if (this.userSignupForm.invalid) return;
    this.loading = true;
    const user: UserSignup = { ...this.userSignupForm.value };
    user['assignedShipments'] = [];
    // user.password = window.btoa(user.password);
    delete user.confirmPassword;
    user.organizationName = this.activeRoute.snapshot.queryParamMap.get('org');
    this.authService.userSignUp(user).subscribe(
      (response) => {
        this.displayShipment = 'block';
        this.loading = false;
        this.submitted = true;
      },
      (error) => {
        if (error && error?.message !== API_ERROR.USER_LOGOUT) {
          if (error.message == API_ERROR.USER_ALREADY_EXISTS) this.toaster.error(API_ERROR.USER_ALREADY_EXISTS, '');
          else if (error.message === API_ERROR.TENANT_NOT_FOUND) this.toaster.error(API_ERROR.ORGANIZATION_NOT_FOUND, '');
          else if (error.message === API_ERROR.EMAIL_ALREADY_EXISTS) this.toaster.error(API_ERROR.EMAIL_ALREADY_EXISTS);
        } else this.toaster.error(API_ERROR.SOMETHING_WENT_WRONG, '');
        this.loading = false;
        this.isInvalid = true;
      }
    );
  }

  addAssignedShipment(shipment){
    this.selectedShipments.push(shipment);
    let index = this.shipmentList.findIndex((shipmentId) => shipmentId.id === shipment.id);
    this.shipmentList.splice(index,1);
  }

  removeAssignedShipment(shipment) {
    let index = this.selectedShipments.findIndex((shipmentId) => shipmentId.id === shipment.id);
    this.selectedShipments.splice(index,1);
    this.shipmentList.push(shipment);
  }

  addShipment() {
    let selectedShipmentsId = this.selectedShipments.map((shipment) => shipment.id);
    this.authService.assignUserShipment(this.userSignupForm.value, this.userSignupForm.value.officialEmail, selectedShipmentsId).subscribe((res) => {
      this.toaster.success('Shipment assigned to user successfully.', ' ', {
        timeOut: 3000
      });
      // this.commonService.resetToken();
      this.displayShipment = 'none';
      this.display = 'block';
    },
      ({ error }) => {
        if (error?.message === API_ERROR.USER_NOT_EXIST)
          this.toaster.error(API_ERROR.USER_NOT_EXIST, '');
        else if (error?.message === API_ERROR.COULD_NOT_ASSIGN_SHIPMENT)
          this.toaster.error(API_ERROR.COULD_NOT_ASSIGN_SHIPMENT, '');
        else this.toaster.error(API_ERROR.SOMETHING_WENT_WRONG, '');
      })
  }

  skipAssignShipment() {
    // this.commonService.resetToken();
    this.displayShipment = 'none';
    this.display = 'block';
  }

  /*toggle eye icon for password*/
  toggelEyeButtonPassword() {
    if (this.f.password.value)
      this.passwordEye = !this.passwordEye;
  }

  /*toggle eye icon for confirmPassword*/
  toggelEyeButtonConPassword() {
    if (this.f.confirmPassword.value)
      this.confirmPasswordEye = !this.confirmPasswordEye;
  }

  /*accept terms and condition for other tenant and set,clear validators for another checkbox*/
  toggelPrivacyPolicyTC() {
    this.userSignupForm.value['privacyPolicyTC'] = !this.userSignupForm.value['privacyPolicyTC'];
  }

  resetFormToAddMore() {
    this.submitted = false;
    this.display = 'none';
    this.userSignupForm.reset();
    this.shipmentForm.reset();
    this.selectedShipments = [];
    this.userSignupForm.controls['organizationName'].setValue(this.org);
  }

 }
