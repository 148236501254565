export const API_ERROR = {
    SOMETHING_WENT_WRONG: "Tenacious API Server is not responding at the moment. Please try again",
    INVALID_LOGIN_CREDENTIALS: 'Invalid login details. Please try again',
    INCORRECT_CREDENTIALS: "Incorrect credentials",
    USER_PROFILE_LOCKED: "User profile is locked due to multiple login attempts.",
    USER_ALREADY_EXISTS: 'Organization already exists',
    DATABAE_CONNECTION_ERR: "There is a Error with the Database!",
    CANNOT_DELETE_ADMIN: 'Can not delete Admin',
    CANNOT_DELETE_SUPERADMIN: 'Can not delete SuperAdmin',
    EMAIL_ALREADY_EXISTS: 'Email already exists',
    CANTNOT_CREATE_ADMIN_AGAIN: 'Can\'t create Admin again',
    CANTNOT_CREATE_SUPERADMIN_AGAIN: 'Can\'t create SuperAdmin again',
    NOT_AUTHORISED: 'You are not authorized to perform this action',
    ACCESS_LEVEL_EXISTS: "Accesslevel already exists",
    ACCESS_LEVEL_WITH_NAME: ' Accesslevel with a name ',
    ALREADY_EXISTS: ' already exists.',
    TENANT_NOT_FOUND: 'Tenant not found',
    ORGANIZATION_NOT_FOUND: 'Organization not found',
    FILE_NOT_FOUND: 'File not found',
    FILE_NOT_SUPPORTED: 'Selected file format is not supported. Use .jpg,.png format',
    FORMAT_NOT_SUPPORTED: 'Selected file format is not supported. Use only .pdf format',
    FILE_SIZE_ERROR: 'Selected file should not be greater than 2 MB.',
    CONFIRM_EMAIL: 'Please confirm your email address',
    ACCESSLEVEL_ADMIN_UPDATE_ERROR: "Can not change accesslevel of admin",
    ACCESSLEVEL_SUPERADMIN_UPDATE_ERROR: "Can not change accesslevel of superadmin",
    ADMIN_ALREADY_EXISTS: 'Admin already exists for this organization',
    SUPERADMIN_ALREADY_EXISTS: 'SuperAdmin already exists for this organization',
    EMAIL_NOT_EXISTS: 'Email does not exists',
    LINK_ALREADY_USE: 'It looks like you have already reset your password using this link. Please try again.',
    LINK_EXPIRED: 'Link has been expired',
    IP_NOTFOUND: 'Your IP address is unable to detect. ',
    RESEND_LINK_FAIL: 'Reset Link Fail.',
    PASSWORD_MISMATCH: 'New password cannot be same as the old password',
    PASSWORD_UPDATE_FAIL: 'Unable to update your password at the moment. Please try again.',
    SELECTED_EDGE_FAIL: 'Selected edge can\'t be activated at the moment. Please try again.',
    SELECTED_END_FAIL: 'Selected endpoints can\'t be activated at the moment. Please try again.',
    DELETE_ACCESSLEVEL_ERROR: 'Access Level cannot be deleted as its currently assigned to a user.',
    USER_LOGOUT: 'User logged in with another device',
    INACTIVE_LOGOUT: 'User logged out due to inactivity',
    USER_NOT_EXIST: 'User does not exist',
    COULD_NOT_ASSIGN_SHIPMENT: 'Unable to assign shipment at this moment, please try later.',
    PASSWORD_REUSED: "Old password cannot be reused.",
}