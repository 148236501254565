<header class="affix header">
    <!-- Brand Logo -->
    <div class="navbar-header clearfix d-flex flex-row">
        <a class="float-right sidebar-switch" id="sidebar-switch" (click)="clickEvent()">
            <span class="icon fa"></span>
        </a>
        <!-- <a class="nav-link float-right sidebar-switch" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a> -->
        <a [routerLink]="user?.organizationName ? '/dashboard' : '/master-data'" class="cursor-pointer navbar-brand brand-link float-left">
            <!-- <img src="assets/images/SmartAxiom-Logo-white.png" width="150" alt="App Logo" class="brand-image img-fluid"> -->
        </a>
    </div>

    <!-- Navbar -->
    <nav id="navbar" class="main-header navbar navbar-expand p-0">
        <ul class="dateTime-show list-inline navbar-nav mr-auto">
            <li class="flex-fill">
                <p class="mb-0 font-size13">{{location}}</p>
                <i class="fas fa-cloud-sun pr-2"></i>
                <span class="">{{currentTemp}}<sup class="">o</sup> C </span>
            </li>
            <li class="flex-fill">
                <p class="mb-0 font-size13">{{ rxTime | date:'longDate' }}</p>
                <span class="">{{ rxTime | date:'hh:mm a' }}</span>
            </li>
            <li class="flex-fill">
                <button *ngIf="!isLoading"  class="separator-btn no-border-btn small-text icon-text-btn" (click)="togglePlayPause()" [disabled]="isLoading">
                  <i class="fas" [ngClass]="isPlaying ? 'fa-pause' : 'fa-play'" style="font-size: 15px; margin-bottom: 10px;"></i>
                  <span class="text-class">{{ isPlaying ? 'Pause Alerts' : 'Play Alerts' }}</span>
                </button>
                <div *ngIf="isLoading" class="spinner-border text-secondary" role="status" style="margin-top: 1px;"></div>
            </li>      
        </ul>
        <!-- Left navbar links -->
     
          
          

        <!-- Right navbar links -->
        <ul class="navbar-nav ml-auto mr-2 dataBytes-ul">
            <!-- Notifications Dropdown Menu -->


            <!-- <li class="nav-item">
                <a class="nav-link" href="javascript:void(0)">
                    <div class="arrowWrap float-left"><i class="fas fa-arrow-down align-middle"></i></div>
                    <div class="dataBytes float-right">
                      <h6 class="mb-0">98.4</h6>
                      <span class="small">kbit/s</span>
                    </div>
                </a>
            </li> -->

            <!-- <li class="nav-item">
                <a class="nav-link pl-1" href="javascript:void(0)">
                    <div class="arrowWrap float-left"><i class="fas fa-arrow-up align-middle"></i></div>
                    <div class="dataBytes float-right">
                      <h6 class="mb-0">98.4</h6>
                      <span class="small">kbit/s</span>
                    </div>
                </a>
            </li> -->

            <li class="nav-item dataBytes-li mr-0" *ngIf="downloadReportButton">
                <a class="nav-link pl-1 cursor-none" href="javascript:void(0)">
                    <div class="dataBytes">
                        <div class="spinner-grow text-dark"></div>
                    </div>
                </a>
            </li>

           
            <!-- <li class="nav-item dataBytes-li mr-3 ml-0" *ngIf="user.userLevel.toLowerCase() === 'superadmin' ">
                <a class="nav-link pl-1 cursor-none" href="javascript:void(0)">
                    <div class="dataBytes">
                        <button [disabled]="downloadReportButton" (click)="downloadReport()" class="btn btn-outline-primary btn-sm onhover-outline">Email Overall Report</button>
                    </div>
                </a>
            </li> -->

            <li class="nav-item dataBytes-li">
                <a class="nav-link pl-1 cursor-none" href="javascript:void(0)">
                    <div class="dataBytes">
                        <!-- {{user | json}} -->
                      <h6 class="mb-0 text-capitalize font-weight-bold">{{user?.fullName ? user?.fullName : user?.officialEmail.substring(0, this.user.officialEmail.lastIndexOf('@'))}}</h6>
                      <span class="text-capitalize">{{user?.organizationName || 'SUPER ADMIN USER'}}</span>
                    </div>
                </a>
            </li>

            <li class="nav-item dropdown widget-li">
                <ng-container *ngIf="user.sendDailyReport || user.userLevel.toLowerCase() === 'superadmin'">
                    <a class="nav-link cursor-pointer" href="javascript:void(0)" title="Last 24 Hours Report" (click)="report.toggle($event)"><i
                            class="fas fa-file-excel"></i></a>
                </ng-container>
            </li>

            <!-- <li class="nav-item dropdown widget-li" id="show_hide_section">
                <ng-container *ngIf="showManageWidgets">
                  <a class="nav-link cursor-pointer" href="javascript:void(0)" title="Manage Widgets"
                  (click)="op.toggle($event)">
                      <i class="fas fa-sliders-h"></i>
                  </a>
                </ng-container>
            </li> -->
            <li class="nav-item  widget-li"
                *ngIf="user?.accesslevel.permissions['access-control'] && 
                user?.accesslevel.permissions['access-control'].submenu['user-management'].create"
            >
            <!-- [routerLink]="['/user-signup']" -->
            <!-- [queryParams]="{org: user?.organizationName}" -->
                <a href="javascript:void(0)"
                    class="nav-link cursor-pointer inviteUsr-link"
                    title="Add User"
                    (click)="showUserModal = true; organizationName = user?.organizationName;"
                ><i class="fas fa-user-plus pt-1"></i></a>
            </li>
            <li class="nav-item widget-li" *ngIf="user?.userLevel !== roleLevels.SUPERADMINUSER">
                <a class="nav-link cursor-pointer inviteUsr-link"
                   title="Notification"
                   (click)="notifications.length ? op.toggle($event) : ''"
                >
                   <i *ngIf="notifications.length; else notification"
                    severity="success"
                    class="pi pi-bell mt-1"
                    pBadge [value]="notifications.length > 10 ? '10+' : notifications.length"
                   ></i>
                   <ng-template #notification>
                       <i [routerLink]="['/utility/alerts-&-notifications']" class="pi pi-bell mt-1"></i>
                   </ng-template>
                </a>
                <p-overlayPanel appendTo="body" #op [showCloseIcon]="false" [style]="{width: '300px'}">
                    <ng-template pTemplate>
                        <div class="font-size13 d-flex p-2 flex-nowrap justify-content-between">
                            <label class="mb-0">{{notifications.length > 10 ? '10+' : notifications.length }} Notification{{notifications.length > 1 ? 's' : '' }}</label>
                            <label
                                [routerLink]="['/utility/alerts-&-notifications']"
                                class="mb-0 text-primary cursor-pointer"
                                (click)="op.hide()"
                            >View All</label>
                        </div>
                        <ul class="list-group list-group-flush progressbar-card">
                            <ng-container *ngFor="let notification of notifications; let i = index;">
                                <li *ngIf="!notification.readStatus"
                                    (click)="[updateNotificationStatus(notification.id), op.hide()]"
                                    class="list-group-item font-size13"
                                >
                                <p class="font_size_11 mb-0">{{notification.dateTime | notificationTimes}}</p>
                                <label class="mb-0 text-truncate d-block">{{notification.subject}}</label>
                                <p class="mb-0 text-truncate">{{notification.message}}</p>
                                </li>
                            </ng-container>
                            <!-- <li *ngFor="let ev of notifications" class="list-group-item">
                                <label>{{ev.label}}</label>
                                <p>{{ev.value}}</p>
                            </li> -->
                        </ul>
                        <!-- notifications -->
                    </ng-template>
                </p-overlayPanel>
                <!-- <p-menu appendTo="body" #menu [popup]="true" [model]="notifications"></p-menu> -->
            </li>
            <li class="nav-item widget-li">
                <a class="nav-link cursor-pointer inviteUsr-link"
                   title="User Manual" target="_blank" [routerLink]="['/user-manual']"
                ><i class="fa fa-info mt-1 mx-1" aria-hidden="true" alt="User Manual"></i>
                </a>
            </li>
            <!-- <img width="18" src="assets/images/icons/user-manual.png" alt="User Manual"> -->
<!--
            <li class="nav-item dropdown">
                <a class="nav-link" data-toggle="dropdown" href="javascript:void(0)" title="Notifications">
                    <i class="far fa-bell"></i>
                    <span class="badge badge-warning navbar-badge">3</span>
                </a>
                    <ul class="px-2 dropdown-menu extended notification dropdown-menu-lg dropdown-menu-right">
                        <li>
                            <p class="py-2">Notifications</p>
                        </li>
                        <li>
                            <div class="alert alert-info clearfix p-2">
                                <span class="alert-icon"><i class="fa fa-bolt"></i></span>
                                <div class="noti-info">
                                    <a href="javascript:void(0)"> Server #1 overloaded.</a>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="alert alert-danger clearfix p-2">
                                <span class="alert-icon"><i class="fa fa-bolt"></i></span>
                                <div class="noti-info">
                                    <a href="javascript:void(0)"> Server #2 overloaded.</a>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="alert alert-success clearfix p-2">
                                <span class="alert-icon"><i class="fa fa-bolt"></i></span>
                                <div class="noti-info">
                                    <a href="javascript:void(0)"> Server #3 overloaded.</a>
                                </div>
                            </div>
                        </li>

                    </ul>
            </li> -->

            <li class="nav-item widget-li logout">
                <a class="nav-link cursor-pointer" (click)="logout()" title="Logout">
                    <i class="fa fa-power-off"></i>
                    <!-- <span>Log Out</span> -->
                </a>
            </li>
        </ul>

    </nav>
    <!-- /.navbar -->
</header>



<aside class="main-sidebar sidebar-expand">

    <!-- Sidebar -->
    <div class="sidebar">
        <ng-scrollbar #scrollable track="vertical" visibility="always" class="sidebar-scrollbar" id="sidebar-scrollbar">
        <!-- Sidebar Menu -->
        <div class="sidebar-profile row m-0 py-2">
            <div class="profile-picture col-md-4 m-0 pt-1">
                <img [src]="profilePicUrl ? profilePicUrl : 'assets/images/icons/profile.jpg' " class="rounded-circle" alt="Profile Picture">
            </div>
            <div class="col-md-8 profile-details">
                <h5 *ngIf="user?.userLevel === 'SUPERADMINUSER'; else dropDown">
                    <a>
                        <span>{{user?.fullName ? (user?.fullName | titlecase) : (user?.officialEmail.substring(0,
                            user.officialEmail.lastIndexOf('@')) | titlecase)}}
                        </span>
                    </a>
                </h5>
                <ng-template #dropDown>

                    <h5 class="dropdown">
                        <a data-toggle="dropdown" href="javascript:void(0)" class="dropdown-toggle">
                            <span>{{user?.fullName ? (user?.fullName | titlecase) : (user?.officialEmail.substring(0, user.officialEmail.lastIndexOf('@')) | titlecase)}}
                            </span>
                        </a>
                        <ul class="dropdown-menu loginactivity">
                            <li>
                                <a class="profile-dropdown" href="javascript:void()" routerLink="/dashboard/edit-profile">
                                    <i class="fas fa-user-edit"></i>
                                    <span class="sidebar-normal pl-1">Edit Profile</span>
                                </a>
                            </li>
                            <li>
                            <a class="profile-dropdown" href="javascript:void()" routerLink="/dashboard/change-password">
                                <i class="fas fa-unlock-alt"></i>
                                <span class="sidebar-normal pl-1">Change Password</span>
                            </a>
                            </li>
                        </ul>
                    </h5>
                </ng-template>

                <h6>{{user?.userLevel | titlecase}}</h6>
                <p class="initialName text-center pt-1 mb-0 font-weight-bold d-none">{{getShortName()|uppercase}}</p>
            </div>
        </div>
        <!-- <ul class="row user-account list-unstyled m-0">
            <li class="col-4 text-center">
                <small class="smaller">BlockLock</small>
                <h6 class="small">3</h6>
            </li>
            <li class="col-4 text-center">
                <small class="smaller">Total Devices</small>
                <h6 class="small">100</h6>
            </li>
            <li class="col-4 text-center">
                <small class="smaller">Active Devices</small>
                <h6 class="small">75</h6>
            </li>
        </ul> -->
        <nav class="mt-2">
            <ul class="nav nav-pills nav-sidebar flex-column">
                <ng-container *ngFor="let menu of menuControls">
                <li class="nav-item" 
                    routerLinkActive="menu-open"
                    [class.menu-open]="menu.isOpen"
                    [class.has-treeview]="menu.submenu.length"
                    id="components">
                    <a *ngIf="!menu.submenu.length && menu.name !== 'Edge Management'" [routerLink]="menu.path" class="nav-link" (click)="openMenu(menu)">
                    <i [ngClass]="'nav-icon fas '+menuIcons[menu.name]"></i>
                    <p>{{menu.name}}</p>
                    <i *ngIf="menu?.submenu.length" class="right fas fa-angle-right"></i>
                    </a>
                    <a *ngIf="menu.submenu.length" href="javascript:void(0)" class="nav-link" (click)="openMenu(menu)" id="submenu_items">
                        <i [ngClass]="'nav-icon fas '+menuIcons[menu.name]"></i>
                        <p>{{menu.name}}</p>
                        <i *ngIf="menu?.submenu.length" class="right fas fa-angle-right"></i>
                    </a>
                    <ul *ngIf="menu?.submenu?.length" class="nav nav nav-treeview nav-subMenu" >
                    <li class="nav-item" *ngFor="let submenu of menu?.submenu" routerLinkActive="menu-open">
                        <a [routerLink]="submenu.path" class="nav-link mb-0">
                        <p>{{submenu.name}}</p>
                        </a>
                    </li>
                    </ul>
                </li>
                </ng-container>
            </ul>
        </nav>
        </ng-scrollbar>
    </div>
    <!-- /.sidebar -->
</aside>
 <!-- Welcome Modal -->
 <ng-template #welcomeModal let-modal>
    <button type="button" class="close light"  aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">×</span>
    </button>
    <div class="row mx-0">
        <div class="col-md-6 py-4 pl-3">
            <h6 class="font-weight-bold pl-3">Welcome, {{fullName | titlecase}}</h6>
            <p class="fsize13 pl-3">Get your team on-board, so that you don't feel alone!</p>
        </div>
        <div class="col-md-6 text-center rounded-top welcm-bgimg pt-4 px-0    ">
            <img class="img-fluid" src="assets/images/onboard.svg"  alt="" width="130" />
        </div>
    </div>
    <div class="modal-body">
        <p class="mt-2 mb-0 fsize13">Use the URL below to invite other users within your organization.</p>
        <div class="form-group col-md-12 mb-3 passwField px-0">
            <label class="has-float-label">
            <input class="form-control" #urlLink id="fname" [(ngModel)]="url" readonly type="text"
                placeholder="URL" />
            </label>
            <i class="far fa-clone password-field-icon" (click)="copyUrlToClipboard(urlLink)"></i>
        </div>

        <div class="modal-footer text-right py-2">
            <a href="#" class="border-dashed-btm border-white text-white"  aria-label="Close" (click)="modal.dismiss('Cross click')">Okay, got
                it</a>
        </div>
    </div>

</ng-template>

<p-overlayPanel #op appendTo="body" styleClass="settings-overlay">
  <ng-template pTemplate>
    <div class="settings-dropdown extended dropdown-menu-right">
        <div class="widget-filter">
            <h6>Manage Widgets</h6>
            <label class="form-check-label custom-control">
                <input type="checkbox" class="form-check-input"
                    [(ngModel)]="mapcard" name="mapcard"
                    (change)="onClickMap(mapcard)">Map <br />
                <i>Show all devices on map.</i>
            </label>

            <label class="form-check-label custom-control">
                <input type="checkbox" class="form-check-input"
                    [(ngModel)]="eventLog" name="eventLog"
                    (change)="onClickEventLog(eventLog)">Event Log <br />
                <i>Show all event logs.</i>
            </label>

            <label class="form-check-label custom-control">
                <input type="checkbox" class="form-check-input" [(ngModel)]="nodemaps" name="nodemaps" (change)="onClick8(nodemaps)">Node Maps <br/>
                <i>Graphical & comprehensive view of all the connected devices in the network.</i>
              </label>

              <label class="form-check-label custom-control">
                <input type="checkbox" class="form-check-input" [(ngModel)]="nodevices" name="nodevices" (change)="onClick1(nodevices)" [checked]="nodevices">Number of Devices <br/>
                <i>Monitor the trend in the number of active devices in the network.</i>
              </label>

              <label class="form-check-label custom-control">
                <input type="checkbox" class="form-check-input" [(ngModel)]="avgeventscount" name="avgeventscount" (change)="onClick2(avgeventscount)"  [checked]="avgeventscount">Average Count of Events <br/>
                <i>Analyze the events triggered by Edge and the {{constantVariables.ENDPOINT.LABEL2}}.</i>
              </label>

              <label class="form-check-label custom-control">
                <input type="checkbox" class="form-check-input" [(ngModel)]="totlevents" name="totlevents" (change)="onClick7(totlevents)">Total Events <br/>
                <i>A consolidated view of all the events triggered across the network.</i>
              </label>

              <label class="form-check-label custom-control">
                <input type="checkbox" class="form-check-input" [(ngModel)]="pqueue" name="pqueue" (change)="onClick6(pqueue)">Provisioning Queue <br/>
                <i>Quickly Activate the recently registered devices through the Dashboard.</i>
              </label>

              <!-- <label class="form-check-label custom-control">
                <input type="checkbox" class="form-check-input" [(ngModel)]="lmap" name="lmap" (change)="onClick5(lmap)">Location Map <br/>
                <i>Locate the devices commissioned across multiple locations.</i>
              </label> -->


              <label class="form-check-label custom-control">
                <input type="checkbox" class="form-check-input" [(ngModel)]="sensorevents" name="sensorevents" (change)="onClick3(sensorevents)">Sensor events <br/>
                <i>Monitor the number of events read by different Sensor types.</i>
              </label>

              <!-- <label class="form-check-label custom-control">
                <input type="checkbox" class="form-check-input" [(ngModel)]="recentactivity" name="recentactivity" (change)="onClick4(recentactivity)">Recent Activities <br/>
                <i>An activity feed to keep you updated with all the recent events.</i>
              </label>                          -->
        </div>
    </div>
  </ng-template>
</p-overlayPanel>

<p-overlayPanel #report appendTo="body">
  <ng-template pTemplate>
    <ul class="list-group list-group-flush progressbar-card">
        <li class="list-group-item font-size13">
            <button [disabled]="downloadReportButton" (click)="generateReport(true)"
                class="btn editable-table-btn btn-sm"><i class="fa fa-download mr-2" aria-hidden="true"></i> Download Report</button>
        </li>
        <li class="list-group-item font-size13">
            <button [disabled]="downloadReportButton" (click)="generateReport(false)"
                class="btn editable-table-btn btn-sm"><i class="fa fa-envelope mr-2" aria-hidden="true"></i> Email Report</button>
            </li>
    </ul>
  </ng-template>
</p-overlayPanel>

<!-- New user popup-->
<p-dialog *ngIf="showUserModal" [(visible)]="showUserModal" styleClass="user-modal" [modal]="true" [style]="{ width: '30vw' }">
    <ng-template pTemplate="header">
        <div class="modal-header bg-gradient-primary flex-fill">
            <h6 class="modal-title" id="exampleModalLongTitle">Add New User</h6>
        </div>
    </ng-template>
    <div class="modal-body">
        <app-signup-user [org]="organizationName" (handleModalEvent)="showUserModal = false"></app-signup-user>
    </div>
</p-dialog>