<section class="content mx-3 mt-4" id="notification_alerts">
  <div class="card no-border">
    <div class="card-body p-0 tab-content" id="tab1content">
      <div class="row">
        <div
          class="col-md-12 bg-white p-3 tab-content cus-rounded-left cus-rounded-right vhHeight"
        >
          <div class="tab-pane container-fluid active align-items-start justify-content-center p-2">
            <div class="row">
              <div class="col-md-12">
                <p-table
                  [value]="notificationAlerts"
                  [rows]="10"
                  [paginator]="false"
                  [loading]="isLoading"
                  [resizableColumns]="true"
                  columnResizeMode="expand"
                >
                  <ng-template pTemplate="header">
                    <tr>
                      <th pResizableColumn colspan="5">Message</th>
                      <th pResizableColumn>Type</th>
                      <th pResizableColumn>Time</th>
                      <th pResizableColumn *ngIf="userInfo.accesslevel.permissions.utility && 
                        userInfo?.accesslevel.permissions.utility?.submenu?.alertsNotifications.delete"
                      >Action</th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-row>
                    <tr
                      [ngClass]="row.readStatus ? '' : 'read'"
                      (click)="dismissAlert(row)"
                    >
                      <td colspan="5"
                        class="align-middle text-truncate"
                        [ngClass]="row.readStatus ? '' : 'textRead'"
                      >
                        {{ row.message }}
                      </td>
                      <td
                        class="align-middle"
                        [ngClass]="row.readStatus ? '' : 'textRead'"
                      >
                        {{ row.type }}
                      </td>
                      <td
                        class="align-middle"
                        [ngClass]="row.readStatus ? '' : 'textRead'"
                      >
                        {{ row.dateTime | notificationTimes  }}
                      </td>
                      <td *ngIf="userInfo.accesslevel.permissions.utility && 
                        userInfo?.accesslevel.permissions.utility?.submenu?.alertsNotifications.delete"
                        (click)="$event.stopPropagation()"
                      >
                        <button
                          class="btn editable-table-btn btn-sm icn-btn"
                          (click)="opendeleteModal(row)"
                        >
                          <i class="fas fa-trash-alt text-danger"></i>
                        </button>
                      </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage">
                    <tr>
                      <td *ngIf="userInfo.accesslevel.permissions.utility && 
                        userInfo?.accesslevel.permissions.utility?.submenu?.alertsNotifications.delete; else empty"
                        colspan="8" class="text-center"
                      >No alerts found.</td>
                      <ng-template #empty>
                        <td colspan="7" class="text-center">No alerts found.</td>
                      </ng-template>
                    </tr>
                  </ng-template>
                </p-table>
                <p-paginator
                  [pageLinkSize]="10"
                  [rows]="10"
                  [totalRecords]="totalAlerts"
                  (onPageChange)="getMoreAlerts($event)"
                >
                </p-paginator>
                <p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
              </div>
            </div>
          </div>
          <!-- /.card -->
        </div>
        <!-- /.card-body -->
      </div>
    </div>
  </div>
</section>

<p-dialog
  *ngIf="selectedNotification"
  [(visible)]="selectedNotification"
  [modal]="true"
  [closable]="false"
  [style]="{ width: '50vw' }"
  [baseZIndex]="1"
  class="alert-message"
>
  <div class="p-dialog-header d-flex w-100 justify-content-between">
    <span class="p-dialog-title">Notification Message</span>
    <div class="p-dialog-header-icons">
      <button type="button" (click)="selectedNotification = null"
        class="p-dialog-header-icon p-dialog-header-close p-link"
      ><span class="pi pi-times"></span>
      </button>
    </div>
  </div>
  <p class="mb-1 message">{{ selectedNotification.message }}</p>
  <span class="font_size_10 text-secondary">{{
    selectedNotification.dateTime | notificationTimes
  }}</span>
</p-dialog>
