import { Injectable } from '@angular/core';
import { CommonService } from 'src/app/core/services/common.service';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ApiService } from 'src/app/core/services/api.service';
import { Socket } from 'ngx-socket-io';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private commonService : CommonService, private http:HttpClient, private socket: Socket) { }

  getReportCounts(type) {
    const { user } = this.commonService.getCurrentUserData();
    const options = {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        params: new HttpParams({ fromString: `email=${user.officialEmail}&type=${type}` })
      };

    return this.http.get(ApiService.URLs.getReportsCounts, options);
  }

  getReportInfo(type) {
    const { user } = this.commonService.getCurrentUserData();
    const options = {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        params: new HttpParams({ fromString: `email=${user.officialEmail}&type=${type}` })
      };

    return this.http.get(ApiService.URLs.getReportsInfo, options);
  }

}
