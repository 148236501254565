<!-- Main content -->
<div class="row" *ngIf="user.accesslevel.permissions.utility.view">
    <div class="col-12">
      
        <section class="content mt-4" id="utility_router">
            <div class="col-12 mb-3">
              <ul class="nav nav-tabs flex-row cus-tab tab-bar" id="tab1">
                <li *ngIf="user.accesslevel.permissions.utility?.submenu?.timeclock.view" class="nav-item tab">
                  <div class="eff-7"></div>
                  <a class="nav-link text-uppercase font-weight-bold" data-toggle="pill"
                      href="javascript:void()" routerLink="/utility/time-&-clocks" routerLinkActive="active">
                    <img src="../../../assets/images/icons/clock.png" class="pr-1 img-fluid pb-1" />
                    Time & Clock
                  </a>
                </li>

                <li *ngIf="user.accesslevel.permissions.utility?.submenu?.weather.view" class="nav-item tab">
                  <div class="eff-7"></div>
                  <a class="nav-link  text-uppercase font-weight-bold"
                      data-toggle="pill" href="javascript:void()" routerLink="/utility/weather" routerLinkActive="active">
                    <img src="../../../assets/images/icons/weather.png" class="pr-1 img-fluid pb-1" />
                    Weather
                  </a>
                </li>

                <!-- <li class="nav-item tab">
                  <div class="eff-7"></div>
                  <a class="nav-link text-uppercase font-weight-bold" data-toggle="pill"
                      href="javascript:void()" routerLink="/utility/terminals" routerLinkActive="active">
                    <img src="../../../assets/images/icons/terminal.png" class="pr-1 img-fluid pb-1" />
                    Terminal
                  </a>
                </li> -->


                <li class="nav-item tab"  *ngIf="user.accesslevel.permissions.utility?.submenu?.ledger.view">
                  <div class="eff-7"></div>
                  <a class="nav-link  text-uppercase font-weight-bold"
                      data-toggle="pill" href="javascript:void()" routerLink="/utility/ledger" routerLinkActive="active">
                    <img src="../../../assets/images/icons/ledger.png" class="pr-1 img-fluid pb-1" />
                    Ledger
                  </a>
                </li>

                <li class="nav-item tab"  *ngIf="user.accesslevel.permissions.utility?.submenu?.logs.view">
                  <div class="eff-7"></div>
                  <a class="nav-link  text-uppercase font-weight-bold"
                      data-toggle="pill" href="javascript:void()" routerLink="/utility/logs" routerLinkActive="active">
                    <img src="../../../assets/images/icons/log.png" class="pr-1 img-fluid pb-1" />
                    Logs
                  </a>
                </li>
                
                <li class="nav-item tab" *ngIf="user.accesslevel.permissions.utility?.submenu['node-map'].view">
                  <div class="eff-7"></div>
                  <a class="nav-link  text-uppercase font-weight-bold"
                      data-toggle="pill" href="javascript:void()" routerLink="/utility/node-maps" routerLinkActive="active">
                    <img src="../../../assets/images/icons/node.png" class="pr-1 img-fluid pb-1" />
                    Node Maps
                  </a>
                </li>
                
                <li *ngIf="user.accesslevel.permissions.utility?.submenu['smart-contracts'] && user.accesslevel.permissions.utility?.submenu['smart-contracts'].view" class="nav-item tab">
                  <div class="eff-7"></div>
                  <a class="nav-link  text-uppercase font-weight-bold"
                      data-toggle="pill" href="javascript:void()" routerLink="/utility/smart-contracts" routerLinkActive="active">
                    <img src="../../../assets/images/icons/smart-contract.png" class="pr-1 img-fluid pb-1" />
                    Smart Contracts
                  </a>
                </li>
                <li *ngIf="user.accesslevel.permissions.utility?.submenu?.alertsNotifications.view" class="nav-item tab">
                  <div class="eff-7"></div>
                  <a class="nav-link  text-uppercase font-weight-bold"
                      data-toggle="pill" href="javascript:void()" routerLink="/utility/alerts-&-notifications" routerLinkActive="active">
                    <img src="../../../assets/images/icons/notification.png" class="pr-1 img-fluid pb-1" />
                    Alerts & Notifications
                  </a>
                </li>
                <div class="indicator"></div>
              </ul>
            </div>
            <router-outlet></router-outlet>
        </section>
    </div>
</div>
<!-- /Main content -->

<!-- Control Sidebar -->
<aside class="control-sidebar control-sidebar-dark">
    <!-- Control sidebar content goes here -->
</aside>
<!-- /.control-sidebar -->




