import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { SensorsRoutingModule } from './sensors-routing.module';
import { SensorsComponent } from './components/sensors/sensors.component';
import { NgScrollbarModule, NG_SCROLLBAR_OPTIONS } from 'ngx-scrollbar';





@NgModule({
  declarations: [SensorsComponent],
  imports: [
    CommonModule,
    SensorsRoutingModule,
    NgScrollbarModule,
    FormsModule,
    SharedModule
  ]
})
export class SensorsModule { }
