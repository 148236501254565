import { NgModule } from '@angular/core';
import { CountdownModule } from 'ngx-countdown';

import { PublicRoutingModule } from './public-routing.module';
import { LoginComponent } from './components/login/login.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { HeaderComponent } from './components/header/header.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { RouterModule } from '@angular/router';
import { UserSignupComponent } from './components/user-signup/user-signup.component';
import { TenantSignupComponent } from './components/tenant-signup/tenant-signup.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import {DropdownModule} from 'primeng/dropdown'; // include this for dropdown support
import { AccordionModule } from 'primeng/accordion';
import { UserManualComponent } from './components/user-manual/user-manual.component';

@NgModule({
  declarations: [
    LoginComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    HeaderComponent,
    UserSignupComponent,
    TenantSignupComponent,
    UserManualComponent
  ],
  imports: [SharedModule, PublicRoutingModule, RouterModule, CountdownModule, NgScrollbarModule, DropdownModule, AccordionModule],
})
export class PublicModule {}
