<footer class="main-footer small clearfix">

    <div class="d-flex justify-content-between">
        <p class="mb-0">Copyright &copy; {{copyrightYear}} <a class="text-poweredby" href="javascript:void(0)">SmartAxiom</a>. All rights reserved.</p>
        <p class="mb-0"><i>Powered by </i><b><a class="text-poweredby" href="javascript:void(0)"> SmartAxiom</a></b></p>
    </div>
    <!-- <div class="float-right"> -->
    <!-- <span class="small text-center versionText">{{releaseVersion}}</span> -->
    <!-- <p class="mb-0 versionText">Tenacious {{tagNameUI === "" ? UIreleaseVersion.branchName+'-'+UIreleaseVersion.commitID : 'version '+tagNameUI }}</p>
    <p class="mb-0 versionText">Blocklock {{tagNameBL === "" ? blReleaseVersion.branchName+'-'+blReleaseVersion.commitID : 'version '+tagNameBL }}</p> -->
    <!-- </div> -->
</footer>