import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/core/services/api.service';
import { CommonService } from 'src/app/core/services/common.service';

@Injectable({
  providedIn: 'root'
})
export class WeatherService {

  constructor(private http: HttpClient, private commonService: CommonService) { }

  /**
 * Description: This method will call weather API and return weathe information according to Zip code & country 
 * @description This method will call weather API and return weathe information according to Zip code & country   
**/
  getWeather(zip, country?): Observable<any> {
    const { user } = this.commonService.getCurrentUserData();
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      //params: new HttpParams({ fromString: `email=${user.officialEmail}zip=${zip}&country=${country}` })
      params: new HttpParams({ fromString: `zip=${zip}&country=${country}` })
    };
    return this.http.get(ApiService.URLs.getWeather, options);
  }
}
