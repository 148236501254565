import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { CommonService } from 'src/app/core/services/common.service';

@Injectable({
  providedIn: 'root'
})
export class NodeMapService {

  constructor(private http:HttpClient, private commonService:CommonService) { }

  getNodeMap() {
    const { user } = this.commonService.getCurrentUserData();
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      params: new HttpParams({ fromString: `email=${user.officialEmail}` })
    };
    return this.http.get(ApiService.URLs.getNodeMap, options);
  }
}
