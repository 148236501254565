import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { AccessControlModule } from './modules/access-control/access-control.module';
import { DeviceModule } from './modules/device/device.module';
import { PublicModule } from './modules/public/public.module';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { PrivateModule } from './modules/private/private.module';
import { ToastrModule } from 'ngx-toastr';
import { UtilityModule } from './modules/utility/utility.module';
import { SensorsModule } from './modules/sensors/sensors.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// socket io
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { environment } from 'src/environments/environment';
import { ConstantVariables } from 'src/constants/constants';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CachingInterceptor } from './core/interceptors/caching.interceptor';
import { TokenInterceptor } from './core/interceptors/token.interceptor';
import { NgxSpinnerModule } from 'ngx-spinner';
import { UserIdleModule } from 'angular-user-idle';
import { ReportShipmentModule } from './modules/reportShipment/reportShipment.module';
import { ShipmentsModule } from './modules/shipments/shipments.module';

const config: SocketIoConfig = { url: environment.host, options: { autoConnect : false ,transports: ['websocket', 'polling', 'flashsocket']} };

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [

    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    DashboardModule,
    AccessControlModule,
    DeviceModule,
    PublicModule,
    PrivateModule,
    SensorsModule,
    SharedModule,
    UtilityModule,
    ReportShipmentModule,
    ShipmentsModule,
    CoreModule,
    ToastrModule.forRoot(),
    NgbModule,
    SocketIoModule.forRoot(config),
    NgxSpinnerModule,
    UserIdleModule,
    HttpClientModule
  ],
  providers: [

  
    ConstantVariables,
   
    { provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
