
<!-- Main content -->
  <div class="row">
      <div class="col-12">
          <section [class.mt-4]="!isOpenInModal"  class="content">
              <!-- <div class="col-12 mb-3">

              </div> -->
              <!-- Default box -->
              <section [class.mt-4]="!isOpenInModal" class="content mx-3">
                  <div class="card no-border mb-3">
                      <div class="card-body p-0 tab-content" id="tab1content">
                          <div class="row">
                              <div
                                  class="col-md-12 bg-white p-3 tab-content cus-rounded-right cus-rounded-left">
                                  <div [class.mb-3]="!isOpenInModal" class="tab-pane container-fluid active" id="a">
                                      <form role="form" [formGroup]="newUserForm">
                                      <h6 *ngIf="!isOpenInModal"
                                          class="mb-3 font-weight-bold pageTitle text-uppercase col-md-12">
                                          Change password</h6>


                                          <div [class]="isOpenInModal ? 'pt-1' : 'py-2 mt-5'" class="row">
                                              <ng-container *ngIf="!editFlag">
                                                  <div
                                                      [class]="isOpenInModal ? 'col-md-8' : 'col-md-4'"
                                                      class="form-group passwField">
                                                      <label class="has-float-label">
                                                      <input [type]="oldPasswordEye ? 'text' : 'password'"
                                                          formControlName="oldPassword" class="form-control"
                                                          [placeholder]="'Old Password' " (change)="checkPassword()" id="Old_password">
                                                      <span>Old Password</span>
                                                      </label>
                                                      <i class="fa fa-eye password-field-icon"
                                                          [ngClass]="{'fa-eye-slash': !oldPasswordEye,'fa-eye': oldPasswordEye}"
                                                          (click)="toggelEyeOldButtonPassword()"></i>

                                                  </div>



                                              </ng-container>

                                          </div>
                                          <div class="row py-1 mt-2">
                                              <div
                                                [class]="isOpenInModal ? 'col-md-8' : 'col-md-4'"
                                              class="form-group passwField mb-1">
                                              <label class="has-float-label">
                                              <input [type]="passwordEye ? 'text' : 'password'"
                                                  formControlName="password" class="form-control"
                                                  [placeholder]="'New Password' ">
                                              <span>New Password</span>
                                              </label>
                                              <i class="fa fa-eye password-field-icon"
                                                  [ngClass]="{'fa-eye-slash': !passwordEye,'fa-eye': passwordEye}"
                                                  (click)="toggelEyeButtonPassword()"></i>

                                          </div>
                                          <div class="form-group col-md-12 mb-1">
                                              <span class="small">Note: Use 8 or more characters
                                                  with
                                                  a mix of letters,
                                                  numbers & symbols.</span>
                                          </div>
                                          </div>
                                          <div class="row py-3">
                                              <div
                                              [class]="isOpenInModal ? 'col-md-8' : 'col-md-4'"
                                              class="form-group passwField">
                                              <label class="has-float-label">
                                              <input
                                                  [type]="confirmPasswordEye ? 'text' : 'password'"
                                                  formControlName="confirmPassword"
                                                  class="form-control"
                                                  placeholder="Verify Password">
                                                  <span>Verify Password</span>
                                              </label>
                                              <i class="fa fa-eye password-field-icon"
                                                  [ngClass]="{'fa-eye-slash': !confirmPasswordEye,'fa-eye': confirmPasswordEye}"
                                                  (click)="toggelEyeButtonConPassword()"></i>
                                          </div>

                                          </div>
                                      </form>


                                  </div>

                                  <div class="row d-flex justify-content-between mx-0">
                                      <div class="col-md-12 px-0">
                                          <button type="submit"
                                              class="btn btn-success px-4 mb-2"
                                              data-dismiss="modal" (click)="ResetPassword()" [disabled]="newUserForm && (oldpasswordCheck || newUserForm.invalid)"
                                          >Save</button>
                                      </div>
                                  </div>

                              </div>
                          </div>
                      </div>
                  </div>
              </section>

          </section>

      </div>
  </div>
<!-- /Main content -->

<!-- Control Sidebar -->
<aside class="control-sidebar control-sidebar-dark">
    <!-- Control sidebar content goes here -->
</aside>
<!-- /.control-sidebar -->
