import { NgModule } from '@angular/core';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { FullCalendarModule } from '@fullcalendar/angular';
import {NgxPaginationModule } from 'ngx-pagination';

import { UtilityRoutingModule } from './utility-routing.module';
import { TimeClockComponent } from './components/time-clock/time-clock.component';
import { WeatherComponent } from './components/weather/weather.component';
import { UtilityComponent } from './components/utility/utility.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { TerminalComponent } from './components/terminal/terminal.component';
import { NgTerminalModule } from 'ng-terminal';
import { SystemLogComponent } from './components/logs/system-log/system-log.component';
import { EventLogComponent } from './components/logs/event-log/event-log.component';
import { UserChainComponent } from './components/ledger/user-chain/user-chain.component';
import { IdentityChainComponent } from './components/ledger/identity-chain/identity-chain.component';
import { EdgeChainComponent } from './components/ledger/edge-chain/edge-chain.component';
import { SmartContractComponent } from './components/ledger/smart-contract/smart-contract.component';
import { CommunicationChainComponent } from './components/ledger/communication-chain/communication-chain.component';
import { LogsComponent } from './components/logs/logs.component';
import { LedgerComponent } from './components/ledger/ledger.component';
import { TimeZoneService } from './components/time-clock/time-zone.service';
import { NodeMapComponent } from './components/node-map/node-map.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { AlertsNotificationComponent } from './components/alerts-notification/alerts-notification.component';
import { TableModule } from 'primeng/table';
import { PaginatorModule } from 'primeng/paginator';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { SmartContractsManagementComponent } from './components/smart-contracts-management/smart-contracts-management.component';
import { AccordionModule } from 'primeng/accordion';
import { DialogModule } from 'primeng/dialog';
import {BadgeModule} from 'primeng/badge'
import { PipesModule } from 'src/app/shared/pipes/pipes.module';


@NgModule({
  declarations: [TimeClockComponent, UserChainComponent, IdentityChainComponent, EdgeChainComponent,
    WeatherComponent, UtilityComponent, TerminalComponent, SystemLogComponent, EventLogComponent,
    SmartContractComponent, CommunicationChainComponent, LogsComponent, LedgerComponent, NodeMapComponent, AlertsNotificationComponent,
    SmartContractsManagementComponent
  ],
  imports: [
    FullCalendarModule,
    SharedModule,
    PipesModule,
    UtilityRoutingModule,
    NgTerminalModule,
    NgScrollbarModule,
    NgxPaginationModule,
    NgxEchartsModule,
    TableModule,
    PaginatorModule,
    ConfirmDialogModule,
    AccordionModule,
    DialogModule,
    BadgeModule
  ],
  providers: [TimeZoneService, ConfirmationService]
})
export class UtilityModule { }
