<div class="tab-pane container-fluid active" id="smart_contract">
    <ng-scrollbar #scrollable track="horizontal" visibility="always" class="tabContent-scrollbar text-nowrap">
    <table class="table table-border mb-2">
        <thead class="bg-light">
            <tr>
                <th>Current Hash</th>
                <th>Previous Hash</th>
                <th>Smart Contract Meta Data</th>
                <!-- <th>Remove Contract</th> -->
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="align-middle">
                    <span title="6129f88c3c0ad0593c3d5857233095c90480dfac9f968a516ea2ef8cbe7ac728">6129f88c3c0ad0593c3d5857...</span>
                </td>
                <td class="align-middle">
                    <span title="deea4ec50f545b28bd4b0539844306263b71ecde541e084a63b8c1b144a3c9b8">deea4ec50f545b28bd4b0539...</span>
                </td>
                <td class="align-middle">
                    <button type="button" data-toggle="modal" data-target="#metadataModalContract"
                    class="btn primary-btn one small">
                    <div class='insider'></div>
                    Smart Contract Meta Data
                  </button>
                 </td>

                <!-- <td class="align-middle">
                    <button type="button" data-toggle="modal" data-target="#deletesmartcontract"
                    class="btn primary-btn danger one small">
                    <div class='insider'></div>
                    Remove Contract
                    </button>
                </td> -->
            </tr>
        </tbody>
    </table>
    </ng-scrollbar>
</div>

<!--Pagination section-->
<div class="row d-flex justify-content-between mx-0">

    <div class="col-md-12 mt-2">
        <!-- <pagination-controls (pageChange)="pageChanged($event)" *ngIf="deviceData && deviceData.length>0">
        </pagination-controls> -->
    </div>
</div>


<!-- Popups code -->
<div class="modal fade" id="metadataModalContract" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header bg-gradient-primary">
                <h6 class="modal-title" id="exampleModalLongTitle">Smart Contract Meta Data</h6>
                <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form role="form">
                    <div class="row">
                        <label class="col-md-3 text-right text-capitalize small">Level Name:</label>
                        <div class="col-md-9 px-4 small">
                            <label class="font-weight-normal small">
                                {{accessLevelData.levelname}}
                            </label>
                        </div>
                    </div>
                    <div class="row">
                        <label class="col-md-3 text-right text-capitalize small">Created By :</label>
                        <div class="col-md-9 px-4 small" title="4141ea1b80b317d55f5020f775b003a037fd60ff99ca98703be15f49280df87c">
                            <label class="font-weight-normal small">{{accessLevelData.createdBy}}</label>
                        </div>
                    </div>
                    <!-- <div class="row">
                        <label class="col-md-3 text-right text-capitalize small">Created At :</label>
                        <div class="col-md-9 px-4 small">
                            <label class="font-weight-normal small">{{accessLevelData.createdAt |date}}</label>
                        </div>
                    </div> -->
                    <div class="row py-1">
                        <label class="col-md-3 text-right text-capitalize small">data :</label>
                        <div class="col-md-9 px-4 small">
                            <div class="px-3 bg-light cus-rounded-right cus-rounded-left py-2 text-break">
                                <label class="font-weight-normal small">
                                <pre style="white-space:pre-line;">
                                   {{permissionObj | json}}
                                </pre>
                                </label>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="row pt-1">
                        <label class="col-md-3 text-right text-capitalize small">Hash :</label>
                        <div class="col-md-9 px-4 small" title="">
                            <label class="font-weight-normal small">
                                2fb83ef8e36b88b0226490d465a8cf8182f2d8aff019185ba9c14c0c0a43ec0d
                            </label>
                        </div>
                    </div> -->
                </form>
            </div>
            <div class="modal-footer p-2">
                <button type="button" class="btn btn-secondary px-4" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>



<!--Delete Smart contract chain table entry popup-->
              <div class="modal fade" id="deletesmartcontract" tabindex="-1" role="dialog"
                   aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-xs modal-notify modal-danger" role="document">
                  <div class="modal-content text-center">
                    <div class="modal-header d-flex justify-content-center border-0 p-0 pt-4">
                      <h4 class="heading text-dark mb-0">Delete Smart Contract</h4>
                    </div>
                    <div class="modal-body border-0 p-0 pt-3">
                      <p class="mb-0 text-dark">
                        Delete this smart contract
                      </p>
                    </div>
                    <div class="modal-footer d-flex justify-content-center row mx-0 border-0 mb-2">
                      <button type="button"
                              class="btn w3-grey w3-hover-grey w3-opacity-min w3-hover-opacity-off col-md-6 m-0 p-2"
                              data-dismiss="modal">
                        Cancel
                      </button>
                      <button type="button"
                              class="btn w3-red w3-hover-red w3-opacity-min w3-hover-opacity-off col-md-6 m-0 p-2"
                              data-dismiss="modal">
                        Delete
                      </button>
                    </div>
                  </div>

                </div>
              </div>
<!--Delete Smart contract chain table entry popup code ends here-->
