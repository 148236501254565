import { Observable, throwError } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { AccessService } from 'src/app/modules/access-control/services/access.service';
import { catchError, map } from 'rxjs/operators';
import { CommonService } from 'src/app/core/services/common.service';
import { API_ERROR } from 'src/app/core/constants/global-error.constants';

@Component({
  selector: 'app-user-chain',
  templateUrl: './user-chain.component.html',
  styleUrls: ['./user-chain.component.css']
})
export class UserChainComponent implements OnInit {
  deviceToken: any;
  currentHash: any;
  previousHash: any;
  timestamp: any;
  userLevel: any;
  fullName: any;
  officialEmail: any;
  organizationName: any;
  generatedBy: any;
  createdAt: any;
  address: any;
  state: any;
  city: any;
  country: any;
  zipCode: any;
  accountType: any;

  config: { itemsPerPage: number; currentPage: number; totalItems: any; };
  userChain$: Observable<any>;

  constructor(private accessService: AccessService, private commonService: CommonService) { }

  ngOnInit(): void {
    this.getUserChain();
  }

  /**
    * Description: get all user chain list api calls
   * @description get all user chain list api calls
   */
  getUserChain() {
    this.userChain$ = this.accessService.getUserChain().pipe(
      map(res => {
        const userChain = res['data'] || [];
        if (userChain.length) {
          this.config = {
            itemsPerPage: 10,
            currentPage: 1,
            totalItems: userChain.length
          }
        }
        return userChain;
      }),
      catchError(error => {
        if (error?.message === API_ERROR.USER_LOGOUT) {
          this.commonService.logout(API_ERROR.USER_LOGOUT);
        } else {
          return throwError(error);
        }
      })
    )
  }


/**
    * Description: open block data
   * @description open block data
   * @param data selected data to populate block
   */
  openBlock(data) {
    this.deviceToken = data.data.token;
    this.currentHash = data.currentHash;
    this.previousHash = data.previousHash;
    this.timestamp = data.timestamp;
  }

  /**
    * Description: open meta data
   * @description open meta data
   * @param data selected data to populate meta data
   */
  openMetaData(data) {
    this.userLevel = data.data.userLevel;
    this.fullName = data.data.fullName;
    this.officialEmail = data.data.officialEmail;
    this.organizationName = data.data.organizationName;
    this.generatedBy = data.data.createdBy;
    this.createdAt = data.data.createdDate;
    this.address = data.data.address;
    this.state = data.data.state;
    this.city = data.data.city;
    this.country = data.data.country;
    this.zipCode = data.data.zipCode;
    this.accountType = data.data.accountType;
  }
}
