<!-- Main content -->
<div class="row clearfix mt-2">
  <div class="col-3 d-flex align-items-stretch">
    <div
      class="card number-chart cursor-pointer text-center progressbar-card"
      routerLink="/end-point-management"
      id="block_tracker"
    >
      <div class="body pt-2 px-0 pb-0 h-100">
        <div class="number h-100 showDataCount">
          <h6 class="mb-2 font-weight-bold font-size13 text-capitalize">
            {{ constantVariables.ENDPOINT.LABEL2 }}
          </h6>
          <ul
            class="list-inline d-flex"
            *ngIf="
              totalDevices != undefined && activeDevices != undefined;
              else loading
            "
          >
            <li class="flex-fill p-0">
              <p class="font-size13">Total</p>
              <!-- <p class="font-size13">Total<br>{{constantVariables.ENDPOINT.LABEL2}}</p> -->
              <h5 class="mb-0">{{ totalDevices }}</h5>
            </li>
            <li class="flex-fill">
              <p class="font-size13">Connected</p>
              <h5 class="mb-0">{{ connectedDevices }}</h5>
            </li>
            <li class="flex-fill">
              <p class="font-size13">Ready</p>
              <h5 class="mb-0">{{ readyDevices }}</h5>
            </li>
            <li class="flex-fill">
              <p class="font-size13">Unassigned</p>
              <h5 class="mb-0">{{ totalDevices - (connectedDevices + readyDevices) }}</h5>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>


  <div class="col-3 d-flex align-items-stretch">
    <div class="card number-chart progressbar-card text-center">
      <div class="body pt-2 px-0 pb-0 h-100">
        <div class="number h-100 showDataCount" id="total_shipments">
          <h6
            class="font-weight-bold font-size13 cursor-pointer text-capitalize"
            routerLink="/shipments"
          >
            Total shipments
            <!-- <button class="btn btn-primary ripple rounded-circle onhover" > -->
              <i class="fa fas fa-plus float-right mr-3 fa-sm badge badge-primary" (click)="navigateTo()" *ngIf="userInfo.accesslevel.permissions.shipments.create"> Add</i>
            <!-- </button> -->
          </h6>
          <ul
            class="list-inline d-flex"
            *ngIf="
              shipmentList
              else loading
            "
          >
            <li class="flex-fill p-0 cursor-pointer" routerLink="../shipments">
              <p class="font-size13">Total</p>
              <h5 class="mb-0">{{ totalShipments }}</h5>
            </li>
            <li class="flex-fill p-0 cursor-pointer" routerLink="../shipments">
              <p class="font-size13">Movable</p>
              <!-- <h5 class="mb-0">{{ connectedShipment }}</h5> -->
              <h5 class="mb-0">{{ movableShipments }}</h5>
            </li>
            <li class="flex-fill p-0 cursor-pointer" routerLink="../shipments">
              <p class="font-size13">Fixed</p>
              <h5 class="mb-0">{{ fixedShipments }}</h5>
              <!-- <h5 class="mb-0">{{ readyShipment }}</h5> -->
            </li>
            <li
              class="flex-fill cursor-pointer"
              [routerLink]="['../shipments']"
            >
              <p class="font-size13">Completed</p>
              <h5 class="mb-0">{{ completedShipments }}</h5>
            </li>
            <!-- <li
              class="flex-fill cursor-pointer"
              [routerLink]="['../shipments']"
            >
              <p class="font-size13">Unassigned</p>
              <h5 class="mb-0">{{ unassignShipments }}</h5>
            </li> -->
            <!-- <li class="flex-fill cursor-pointer"> -->
           <!--  <li *ngIf="userInfo.accesslevel.permissions.shipments.create" class="flex-fill cursor-pointer" (click)="navigateTo()">
              <button class="btn btn-primary p-2 mt-1 ripple rounded-circle onhover">
                <i class="fa fas fa-plus fa-lg"></i>
              </button>
            </li> -->
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="col-2 d-flex align-items-stretch">
    <div class="card number-chart progressbar-card text-center">
      <div class="body pt-2 px-0 pb-0 h-100">
        <div class="number h-100 showDataCount" id="battery">
          <h6
            class="font-weight-bold font-size13 cursor-pointer text-capitalize"
          >
            Battery
          </h6>
          <ul 
            class="list-inline d-flex"
            *ngIf="
             batteryCounts
              else loading
            "
          >
            <li class="flex-fill p-0 cursor-pointer" routerLink="/end-point-management/High" >
              <p class="font-size13">High</p>
              <h5 class="mb-0" style="color: #7EB337 ;">{{ batteryCounts?.high || 0 }}</h5>
            </li>
            <li class="flex-fill cursor-pointer" routerLink="/end-point-management/Medium" >
              <p class="font-size13">Medium</p>
              <h5 class="mb-0" style="color: #be851b;">{{ batteryCounts?.medium || 0 }}</h5>
            </li>
            <li class="flex-fill cursor-pointer" routerLink="/end-point-management/Low" >
              <p class="font-size13">Low</p>
              <h5 class="mb-0" style="color: #BE271B;">{{ batteryCounts?.low || 0 }}</h5>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="col-4 d-flex align-items-stretch" id="alerts">
    <div
      class="card progressbar-card number-chart notification-card text-center cursor-pointer"
    >
      <div class="body p-2">
        <div class="number showDataCount text-center">
          <h6 class="font-weight-bold font-size13 text-capitalize mb-0 pb-1">
            Alerts
          </h6>
          <ul class="list-inline d-flex m-0 p-0">
            <li class="flex-fill mb-0" >
              <p class="font-size13 font-weight-bold">Temperature</p>
              <ul class="list-inline d-flex m-0 p-0"  *ngIf="!loadAlerts else loading">
                <li class="flex-fill mb-0"   [routerLink]="['../alerts/filter/lowTempAlerts']">
                  <p class="font-size13">Low</p>
                  <h5 class="mb-0" style="color: #BE271B;">{{ tempAlertCounts?.low || 0 }}</h5>
                </li>
                <li class="flex-fill mb-0"   [routerLink]="['../alerts/filter/highTempAlerts']">
                  <p class="font-size13">High</p>
                  <h5 class="mb-0" style="color: #F1594E;">{{ tempAlertCounts?.high || 0 }}</h5>
                </li>
              </ul>
            </li>
            <li class="flex-fill mb-0">
              <p class="font-size13 font-weight-bold">Humidity</p>
              <ul class="list-inline d-flex m-0 p-0"  *ngIf="!loadAlerts else loading">
                <li class="flex-fill mb-0"  [routerLink]="['../alerts/filter/lowHumAlerts']">
                  <p class="font-size13">Low</p>
                  <h5 class="mb-0" style="color: #BE271B;">{{ humidityAlertCounts?.low || 0 }}</h5>
                </li>
                <li class="flex-fill mb-0"  [routerLink]="['../alerts/filter/highHumAlerts']">
                  <p class="font-size13">High</p>
                  <h5 class="mb-0" style="color: #F1594E;">{{ humidityAlertCounts?.high || 0 }}</h5>
                </li>
              </ul>
            </li>
            <li class="flex-fill mb-0"  [routerLink]="['../alerts/filter/geofenceAlerts']">
              <p class="font-size13 font-weight-bold">Geofence</p>
              <h5 class="mb-0 mt-4" style="color: #BE271B;" *ngIf="!loadAlerts else loading">{{ geofenceAlertCount || 0 }}</h5>
              <!-- <ul class="list-inline d-flex m-0 p-0"  *ngIf="!loadAlerts else loading">
              </ul> -->
            </li>
            <!-- <li class="flex-fill mb-0">
              <p class="font-size13 font-weight-bold">Battery</p>
              <ul class="list-inline d-flex m-0 p-0" *ngIf="!loadAlerts else loading">
                <li class="flex-fill mb-0">
                  <p class="font-size13">Low</p>
                  <h5 class="mb-0" style="color: #BE271B;">{{ batteryCounts?.low || 0 }}</h5>
                </li>
                <li class="flex-fill mb-0">
                  <p class="font-size13">High</p>
                  <h5 class="mb-0" style="color: #F1594E;">{{ batteryCounts?.high || 0 }}</h5>
                </li>
              </ul>
            </li> -->
          </ul>
        </div>
      </div>
    </div>
  </div>


</div>
<!-- Here is card section -->

<!--Shipment count section-->
<!-- <div>
    <div class="d-flex justify-content-start shadow bg-white rounded shipment-count cursor-pointer">
        <div (click)="getShipmentFilter('total')" [ngClass]="currentFilter === 'total' ? 'shipment-count-grey-tab' : 'shipment-count-tab'">
            Total Shipments ({{totalShipments}})
        </div>
        <div class="shipment-count-tab" (click)="getShipmentFilter('movable')"  [ngClass]="currentFilter === 'movable' ? 'shipment-count-grey-tab' : 'shipment-count-tab'">
             Movable ({{movableShipments}})
        </div>
        <div class="shipment-count-tab" (click)="getShipmentFilter('fixed')"  [ngClass]="currentFilter === 'fixed' ? 'shipment-count-grey-tab' : 'shipment-count-tab'">
             Fixed ({{fixedShipments}})
        </div>
        <div class="shipment-count-tab" (click)="getShipmentFilter('completed')"  [ngClass]="currentFilter === 'completed' ? 'shipment-count-grey-tab' : 'shipment-count-tab'">
            Completed ({{completedShipments}})
        </div>
    </div>
</div> -->

<!-- Shipments List-->
<div class="row d-flex">
  <div class="col-3 pb-4 align-items-stretch">
    <div class="card shadow rounded-xl mb-4 graphsCard-loader h-100 " id="shipment_list">
      <ul class="list-group list-group-flush">
        <div class="d-flex justify-content-between">
          <h6 class="ml-4 mt-2 font-weight-bold">Shipments</h6>
          <button class="btn onhover pt-0 pb-0" id="show_all" (click)="getTrackersWithEvents()"
          (click)="getAllEventDataById()">Show All</button>
        </div>
        <li class="list-group-item px-3">
          <div class="search-wrapper position-relative">
            <input type="text" class="form-control" placeholder="Search" [formControl]="search">
            <i [ngClass]="search.value ? 'fa-times cursor-pointer' : 'fa-search text-muted'"
              class="fa position-absolute search-icon"
              (click)="search.value ? reset() : ''"
            ></i>
          </div>
        </li>
        <li
          class="list-group-item m-0 p-0"
          style="max-height: 770px; overflow-y: auto; margin-top: 0px"
          *ngIf="shipmentsWithEvents.length; else noData"
        >
          <div class="card-body p-0 m-0" *ngIf="!resetAccrodian; else loading">
            <div id="accordion">
            <div
            class="card m-0 p-0 rounded-0"
            style="border-bottom: 1px solid gray"
            *ngFor="let item of shipmentsWithEvents"
            id="shipments"
            [ngClass]="isSelectedShipment && isSelectedShipmentName[0] ===item.id? 'shipmentAccordion':''"
              >
                <div
                  class="d-flex justify-content-between card-header m-1 p-0"
                  id="headingOne"
                  [ngClass]="isSelectedShipment && isSelectedShipmentName[0] ===item.id? 'shipmentAccordionName':''"
                >
                  <button
                    class="btn btn-block text-left ml-2"
                    id="shipment"
                    type="button"
                    (click)="getEvents(item)"
                    data-toggle="collapse"
                    [attr.data-target]="'#collapse-' + item.id"
                    [attr.aria-expanded]="false"
                    [attr.aria-controls]="'collapse-' + item.id"
                  >
                 <i class="mr-1 fa fa-pause-circle" height="20px" style="color: '#A8A8A8';" *ngIf="item.status === 'PAUSED'"></i>
                 <i class="mr-1 fa fa-truck" height="20px" *ngIf="item.status === 'STARTED'"></i>
                 <i class="mr-1 fa fa-ban" height="20px" style="color: red;" *ngIf="item.status === 'CANCELLED'"></i>
                 <i class="mr-1 fa fa-check-circle" height="20px" style="color: green;" *ngIf="item.status === 'COMPLETED'"></i>
                 {{ item.shipmentName }}
             
                  </button>
                  <div
                    [attr.id]="'collapse-' + item.id"
                    class="collapse hide pr-2"             
                    aria-labelledby="headingOne"
                    data-parent="#accordion"
                  >
                    <div
                      *ngIf="showLoadShipment || showLoadShipmentDetails"
                      class="spinner-grow text-dark spinner-grow-sm mt-2 float-right"
                      role="status"
                    ></div>
                    <div #arrow *ngIf="!showLoadShipment &&  !showLoadShipmentDetails"
                      class="text-dark mt-2 float-right"
                    ><i class="fa fa-arrow-right"></i></div>
                  </div>
                </div>
                <div
                  [attr.id]="'collapse-' + item.id"
                  class="collapse hide mr-2"
                  aria-labelledby="headingOne"
                  data-parent="#accordion"
                  id="collapse_panel"
                >
                  <div class="card-body mt-0 mb-2 p-0 d-flex justify-content-end">
                    <button
                      class="btn btn-outline-primary btn-xs mr-2 onhover-outline"
                      (click)="
                        getEvents(item)
                      "
                    >
                      Show Route
                    </button>
                    <button
                      class="btn btn-outline-primary btn-xs onhover-outline"
                      (click)="
                        showShipmentDetails(item)
                      "
                      id="click_show_details"
                    >
                      Show Details
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="col">
    <div class="card shadow rounded-xl mb-4 pl-2 pr-2 graphsCard-loader">
      <div
        class="card-body position-relative overflow-hidden"
        [ngClass]="noLocations ? 'noLocation': ''"
        style="height: 890px"
      >
      <ng-container *ngIf="mapData; else noMapData">
          <!-- <div class="map w-100 h-100" id="map"></div>
          <div
            *ngIf="showLoadShipment"
            class="position-absolute"
            style="
              height: 850px;
              width:100%;
              background: rgba(122, 130, 136, 0.75);
            "
          >
            <div class="spinner-grow position-absolute col mx-auto"></div>
          </div> -->
          <!-- <pre [innerHtml]="mapData | json"></pre> -->
          <app-map [ngClass]="!noLocations ? {'display':'block'} : {'display':'none'}"
            page="dashboard"
            [type]="type"
            [hData]="mapData"
            [zoom]="zoom"
            [mapType]="mapType"
            [cluster]="cluster"
            [status]="selectedDevice ? selectedDevice?.status : ''"
            [id]="selectedDevice ? selectedDevice?.id : isSelectedShipmentName[0]"
            [freq]="selectedDevice ? selectedDevice.trackers[0]?.freq : 0"
            (showLoadShipmentEvent)="showLoadShipment = $event"
            htmlEle="map"
            [pickup]="selectedDevice ? selectedDevice.pickupLocation : ''"
            [destination]="selectedDevice ? selectedDevice.destinationLocation : ''"
          ></app-map>
            <h5 *ngIf="noLocations">No locations found.</h5>
        </ng-container>
      </div>
    </div>
  </div>

  <!-- <div *ngIf="(eventLogCardSection$ | async)" [ngClass]="(mapCardSection$ | async) ? 'col-xl-4 col-lg-4' : 'col-xl-6 col-lg-6'" id="eventLogCard">
        <div class="card rounded-xl p-2 shadow mb-4 graphsCard-loader">
            <div class="card-header p-2 pb-0 clearfix">
                <h6 class="m-0 font-weight-bold float-left font-size13 text-capitalize">
                    Event Log
                </h6>
                <button class="btn btn-sm icn-btn float-right p-0" (click)="getAllEventList(true)" [disabled]="refreshEventLog"
                ><i class="fas fa-sync"></i></button>
                <div *ngIf="refreshEventLog" class="float-right font-size13 mr-2"
                ><i  class="fas fa-spinner fa-pulse"></i> please wait..</div>
            </div>
            <div [ngClass]="(mapCardSection$ | async) ? 'card-body event-log p-0' : 'card-body event-log-data p-0'">
                <ul class="list-group list-group-flush" *ngIf="eventLogData; else loading">
                    <li *ngFor="let event of eventLogData" class="list-group-item px-2 font-size13">
                        <p class="mb-1"><strong>Device UUID</strong>: {{event?.deviceUUID}}</p>
                        <p class="mb-1"><strong>Time</strong>: {{event?.time | date:'dd MMM y, HH:mm:ss'}}</p>
                        <p class="mb-1"><strong>Tracker Data</strong>: {{event?.trackerData}}</p>
                        <p class="mb-1" *ngIf="event?.sensorData.length"><strong>Sensor Data</strong>: {{event?.sensorData}}</p>
                    </li>
                </ul>
                <p *ngIf="!eventLogData?.length" class="font-size13 text-center text-muted">No results</p>
            </div>
        </div>
    </div> -->

  <!-- <div class="col-xl-6 col-lg-6" id="nodemaps" *ngIf="nodemapsFilter$ | async">
        <div class="card shadow mb-4 graphsCard-loader">
            <div class="card-header pt-3 pb-0 clearfix">
                <h6 class="m-0 font-weight-bold float-left font-size13 text-capitalize">
                    Node Maps
                </h6>
                <button class="btn btn-sm icn-btn float-right p-0">
                    <i class="fas fa-sync"></i>
                </button>
            </div>
            <div class="card-body activities-card">
                <div echarts [options]="option" *ngIf="options | async as option; else loading"
                    class="nodeMap-echarts"></div>
            </div>
        </div>
    </div> -->

  <!-- Area Chart -->
  <!--  <div class="col-xl-6 col-lg-6" id="numberofdevices" *ngIf="devicesFilter$ | async">
        <div class="card shadow mb-4 widgetCards graphsCard-loader">
            <div class="card-header py-3 clearfix">
                <h6 class="m-0 font-weight-bold float-left font-size13 text-capitalize">
                    Total Number of Shipments
                </h6>
            </div>
            <div class="card-body pt-0 px-0 dashb-userInfo noselectfilter noofdevices-card">
                <div echarts [options]="optiondevices" class="devices-chart" [autoResize]="autoResize"
                    style="width: 100%" *ngIf="optiondevices != undefined; else loading"></div>
            </div>
        </div>
    </div> -->
  <!-- Average Sensor Data -->
  <!-- <div class="col-xl-6 col-lg-6" id="avgcountofevents" *ngIf="avgcountsFilter$ | async">
        <div class="card shadow mb-4 pqueueCard">
            <div class="card-header py-3">
                <div class="clearfix" >
                    <h6 class="mb-2 font-weight-bold font-size13 float-left text-capitalize">
                        Average Sensor Data
                    </h6>
                    <div class="segmented float-right" >
                        <label [class.checked]="filterAvgSensorEvents === 'hourly'"><input type="radio" value="hourly"
                                name="filterAvgSensorEvents" [(ngModel)]="filterAvgSensorEvents"
                                (change)="filterAverageSensorEvents()" />
                            Hourly</label>
                        <label [class.checked]="filterAvgSensorEvents === 'daily'"><input type="radio" value="daily" name="filterAvgSensorEvents"
                                [(ngModel)]="filterAvgSensorEvents"
                                (change)="filterAverageSensorEvents()" />
                            Daily</label>
                        <label [class.checked]="filterAvgSensorEvents === 'weekly'"><input type="radio" value="weekly" name="filterAvgSensorEvents"
                                [(ngModel)]="filterAvgSensorEvents"
                                (change)="filterAverageSensorEvents()" />
                            Weekly</label>
                    </div>
                </div>
                <div class="row filterfields-wrap">
                    <div class="col-md-4">
                        <select class="form-control cursor-pointer" [(ngModel)]="selectedShipment"
                            (change)="filterSelectedTracker(selectedShipment)">
                            <option value="" selected>Select Shipment</option>
                            <option *ngFor="let item of shipmentGraphList" [value]="item.id">
                                {{ item.shipmentName }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-4">
                        <select class="form-control cursor-pointer"
                            [(ngModel)]="selectedTracker"
                            (change)="filterSelctedSensor(selectedTracker)"
                            >
                            <option value="" selected>Select {{constantVariables.ENDPOINT.LABEL1}}</option>
                            <option *ngFor="let tracker of trackerList"
                                [value]="tracker.id">
                                {{ tracker.data.deviceUUID }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-4">
                        <select class="form-control cursor-pointer" [(ngModel)]="selectedSensor"
                          (change)="selectedSensorId(selectedSensor)">
                            <option value="">Select Sensor</option>
                            <ng-container *ngFor="let sensor of sensorsList">
                                <option *ngIf="(!sensor.isRemoveSensor || sensor.isCompleted)" [value]="sensor.id">
                                    {{ sensor.mac_addr }}
                                </option>
                            </ng-container>
                        </select>
                    </div>
                </div>
            </div>
            <div class="card-body dashb-userInfo px-0 pt-0" >
                <div echarts [options]="optionendpt" class="sensorevents-chart" [autoResize]="autoResize" style="width: 100%"
                *ngIf="optionendpt != undefined; else loading">
                </div>
            </div>
        </div>
    </div> -->

  <!--Total Events -->
  <!-- <div class="col-xl-6 col-lg-6" id="totalevents" *ngIf="teventsFilter$ | async">
        <div class="card shadow mb-4 widgetCards graphsCard-loader">
            <div class="card-header py-3 clearfix">
                <h6 class="m-0 font-weight-bold float-left font-size13 text-capitalize">
                    Sensor Events
                </h6>
                <div class="float-right mb-0">
                    <div class="segmented">
                        <label [class.checked]="filterTotalEvents === 'hourly'"><input type="radio" value="hourly"
                                name="filterTotalEvents" [(ngModel)]="filterTotalEvents"
                                (change)="filterTotalEventsGraph()" />
                            Hourly</label>
                        <label [class.checked]="filterTotalEvents === 'daily'"><input type="radio" value="daily" name="filterTotalEvents"
                                [(ngModel)]="filterTotalEvents"
                                (change)="filterTotalEventsGraph()" />
                            Daily</label>
                        <label [class.checked]="filterTotalEvents === 'weekly'"><input type="radio" value="weekly" name="filterTotalEvents"
                                [(ngModel)]="filterTotalEvents"
                                (change)="filterTotalEventsGraph()" />
                            Weekly</label>
                    </div>
                </div>
            </div>
            <div class="card-body dashb-userInfo noselectfilter p-0 px-0">
                <div echarts [options]="optionevents" class="events-chart" [autoResize]="autoResize"
                    style="width: 100%" ></div>
            </div>
        </div>
    </div> -->

  <!-- Sensor events -->
  <!-- <div class="col-xl-6 col-lg-6" id="sensorevents" *ngIf="sensorsFilter$ | async">
        <div class="card shadow mb-4 widgetCards">
            <div class="card-header py-3">
                <div class="clearfix">
                    <h6 class="mb-2 font-weight-bold font-size13 text-capitalize float-left">
                        Total events
                    </h6>
                    <div class="segmented float-right">
                        <label [class.checked]="filterBySensorEvents === 'hourly'"><input type="radio" name="filterBySensorEvents"
                                value="hourly" [(ngModel)]="filterBySensorEvents"
                                (change)="filterSensorEvents()" />
                            Hourly</label>
                        <label [class.checked]="filterBySensorEvents === 'daily'"><input type="radio" name="filterBySensorEvents" value="daily"
                                [(ngModel)]="filterBySensorEvents"
                                (change)="filterSensorEvents()" />
                            Daily</label>
                        <label [class.checked]="filterBySensorEvents === 'weekly'"><input type="radio" name="filterBySensorEvents" value="weekly"
                                [(ngModel)]="filterBySensorEvents"
                                (change)="filterSensorEvents()" />
                            Weekly</label>
                    </div>
                </div>
                <div class="row filterfields-wrap">
                    <div class="col-md-4">
                        <select class="form-control cursor-pointer" [(ngModel)]="selectedShipmentForSE"
                            (change)="selectShipmentForSE(selectedShipmentForSE)">
                            <option value="" selected>Select Shipment</option>
                            <option *ngFor="let item of shipmentList" [value]="item.id">
                                {{ item.shipmentName }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-4">
                        <select class="form-control cursor-pointer"
                            [(ngModel)]="selectedTrackerForSE"
                            (change)="selecteTrackerForSE(selectedTrackerForSE)">
                            <option value="" selected>Select {{constantVariables.ENDPOINT.LABEL1}}</option>
                            <option *ngFor="let item of trackerListForSE"
                                [value]="item.id">
                                {{ item.data.deviceUUID }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-4">
                        <select class="form-control cursor-pointer" [(ngModel)]="selectedSensorForSE"
                            (change)="filterSelctedSensorForSE(selectedSensorForSE)">
                            <option value="" selected>Select Sensor</option>
                            <ng-container *ngFor="let sensor of sensorsListForSE">
                                <option *ngIf="(!sensor.isRemoveSensor || sensor.isCompleted)" [value]="sensor.id">
                                    {{ sensor.mac_addr }}
                                </option>
                            </ng-container>
                        </select>
                    </div>
                </div>
            </div>
            <div class="card-body dashb-userInfo p-0 px-0">
                <div echarts [options]="optionsensorEvents" class="sensorevents-chart"
                    [autoResize]="autoResize" style="width: 100%" *ngIf="optionsensorEvents != undefined; else loading"></div>
            </div>
        </div>
    </div> -->

  <!-- <div class="col-xl-6 col-lg-6"> -->
  <!-- Provisioning Queue Table -->
  <!-- <div class="card shadow mb-4 pqueueCard">
            <div class="card-header py-3 clearfix">
                <h6 class="m-0 font-weight-bold float-left font-size13 text-uppercase">Location Map</h6>
            </div>
            <div class="card-body locationMap-card p-0">
                <google-map
                height="320px"
                width="100%"
                [zoom]="zoom"
                [center]="center"
                [options]="options">
                <map-marker
                #markerElem
                *ngFor="let marker of markers"
                [position]="marker.position"
                [label]="marker.label"
                [title]="marker.title"
                [options]="marker.options"
                (mapClick)="openInfo(markerElem, marker.info)"
              >
              </map-marker>
              <map-info-window>{{ infoContent }}</map-info-window>
            </google-map>
            </div>
        </div> -->
  <!-- </div> -->

  <!-- Provisioning Queue Table -->
  <!-- <div class="col-xl-6 col-lg-6" id="pqueue" *ngIf="pqueueFilter$ | async">
        <div class="card shadow mb-4 pqueueCard">
            <div class="card-header py-3 clearfix">
                <h6 class="m-0 font-weight-bold float-left font-size13 text-capitalize">
                    Provisioning Queue
                </h6>
                <ul class="nav nav-tabs float-right pqueueCard-tabs p-0 m-0 border-0" id="myTab" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active font-size13 custom_anchor"  data-toggle="tab"
                            href="#endpoint-tab" role="tab" (click)="selectedTab = 'endpoint-tab'">
                            {{constantVariables.ENDPOINT.LABEL2}}
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link font-size13 custom_anchor"  data-toggle="tab"
                            href="#edge-tab" role="tab" (click)="selectedTab = 'edge-tab'">
                            Edge
                        </a>
                    </li>
                </ul>

            </div>
            <div class="card-body dashb-userInfo noselectfilter tab-content" id="myTabContent">
                <div role="tabpanel" class="tab-pane fade show active" id="endpoint-tab">
                        <div class="table-responsive">
                            <table class="table mb-0">
                                <thead>
                                    <tr>
                                        <th class="table_heading">Model Name</th>
                                        <th class="table_heading">MAC Address</th>
                                        <th class="table_heading">Edge Controller</th>
                                        <th class="table_heading">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let row of deviceData | slice: 0:4">
                                        <td class="align-middle table_data">{{ row.data.model_name }}</td>
                                        <td class="align-middle table_data">{{ row.data.mac_addr }}</td>
                                        <td class="align-middle table_data">{{row.edgeName | slice:0:50 }} {{row.edgeName?.length > 50 ? '...' : ''
                                            }}</td>
                                        <td class="align-middle">
                                            <button type="button" class="btn btn-outline-primary mr-3 py-0 font-size13"
                                                (click)="updateDevice(row)" id="button{{ row.index }}"
                                                [disabled]="userInfo &&  userInfo.accesslevel &&  userInfo.accesslevel.permissions && userInfo.accesslevel.permissions['Provisioning Queue']  && userInfo.accesslevel.permissions['Provisioning Queue'].update == false ">
                                                Activate
                                            </button>
                                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2"
                                                id="tick{{ row.index }}" style="display: none">
                                                <circle class="path circle" fill="none" stroke="#28a745" stroke-width="5"
                                                    stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                                                <polyline class="path check" fill="none" stroke="#28a745" stroke-width="9"
                                                    stroke-linecap="round" stroke-miterlimit="10"
                                                    points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                                            </svg>
                                        </td>
                                    </tr>
                                    <tr *ngIf="deviceData && deviceData?.length == 0">
                                        <td colspan="4" class="text-center align-middle table_data">
                                            No Results
                                        </td>
                                    </tr>
                                    <tr *ngIf="deviceData && deviceData?.length > 4">
                                        <td colspan="4" class="text-center align-middle bg-light font-weight-bold">
                                            <a class="" routerLink="../provisioning-queue" [state]="{ selectedTab: selectedTab }">
                                                <i class="fas fa-arrow-circle-down mr-2"></i>Load
                                                More
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                </div>

                <div role="tabpanel" class="tab-pane fade" id="edge-tab">
                        <div class="table-responsive">
                            <table class="table mb-0">
                                <thead>
                                    <tr>
                                        <th class="table_heading">Edge Controller</th>
                                        <th class="table_heading">MAC Address</th>
                                        <th class="table_heading">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let row of edgeList | slice: 0:4">
                                        <td class="table_data align-middle cursor-pointer">
                                            {{row.data.name | slice:0:50 }} {{row.data.name?.length > 50 ? '...' : '' }}</td>
                                        <td class="table_data align-middle">{{row.data.mac}}</td>
                                        <td class="align-middle">
                                            <button type="button" class="btn btn-outline-primary mr-3 py-0 font-size13"
                                                (click)="updateEdge(row)" id="edgebutton{{ row.index }}"
                                                [disabled]="userInfo &&  userInfo.accesslevel &&  userInfo.accesslevel.permissions && userInfo.accesslevel.permissions['Provisioning Queue']  && userInfo.accesslevel.permissions['Provisioning Queue'].update == false ">
                                                Activate
                                            </button>
                                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2"
                                                id="edgetick{{ row.index }}" style="display: none">
                                                <circle class="path circle" fill="none" stroke="#28a745" stroke-width="5"
                                                    stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                                                <polyline class="path check" fill="none" stroke="#28a745" stroke-width="9"
                                                    stroke-linecap="round" stroke-miterlimit="10"
                                                    points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                                            </svg>
                                        </td>
                                    </tr>

                                      <tr *ngIf="edgeList && edgeList?.length == 0">
                                        <td colspan="4" class="text-center align-middle table_data">
                                            No Results
                                        </td>
                                    </tr>
                                    <tr *ngIf="edgeList && edgeList?.length > 4">
                                        <td colspan="4" class="text-center align-middle bg-light font-weight-bold">
                                            <a class="" routerLink="../provisioning-queue">
                                                <i class="fas fa-arrow-circle-down mr-2"></i>Load
                                                More
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                </div>
            </div>
        </div>
    </div> -->

  <!-- <div class="col-xl-6 col-lg-6" id="recentactivity">
        <div class="card shadow mb-4">
            <div class="card-header pt-3 pb-0 clearfix">
                <h6 class="m-0 font-weight-bold float-left font-size13 text-capitalize">Recent Activities</h6>
                <button class="btn btn-sm icn-btn float-right p-0">
                    <i class="fas fa-sync"></i>
                </button>
            </div>
            <div class="card-body activities-card">
                <ng-scrollbar #scrollable track="vertical" visibility="always" class="tabContent-scrollbar">
                <ul class="list-unstyled feeds-widget">
                    <li class="user-activity">
                        <div class="row">
                        <div class="feeds-left col-md-10 px-3">
                            <p class="mb-0 font-size13">Gunjan Karun has signed up for the Tenacious application.</p>
                        </div>
                        <div class="feeds-time col-md-2">
                            <span class="font-size13"><i class="fas fa-clock mr-2"></i>Now </span>
                        </div>
                        </div>
                    </li>
                    <li class="endpt-activity">
                        <div class="row">
                          <div class="feeds-left col-md-10 px-3">
                              <p class="mb-0 font-size13">A new Endpoint is detected through Edge Controller 1.</p>
                          </div>
                          <div class="feeds-time col-md-2">
                              <span class="font-size13"><i class="fas fa-clock mr-2"></i>2 H </span>
                          </div>
                        </div>
                      </li>
                      <li class="devices-activity">
                        <div class="row">
                          <div class="feeds-left col-md-10 px-3">
                              <p class="mb-0 font-size13">Total 11 devices are offline currently.</p>
                          </div>
                          <div class="feeds-time col-md-2">
                              <span class="font-size13"><i class="fas fa-clock mr-2"></i>25 M </span>
                          </div>
                        </div>
                      </li>
                      <li class="sensor-activity">
                        <div class="row">
                          <div class="feeds-left col-md-10 px-3">
                              <p class="mb-0 font-size13">2 new Temperature Sensors discovered in the network</p>
                          </div>
                          <div class="feeds-time col-md-2">
                              <span class="font-size13"><i class="fas fa-clock mr-2"></i>15 H </span>
                          </div>
                        </div>
                      </li>
                      <li class="endpt-activity">
                        <div class="row">
                          <div class="feeds-left col-md-10 px-3">
                              <p class="mb-0 font-size13">New Endpoint is just added in the Provisioning Queue.</p>
                          </div>
                          <div class="feeds-time col-md-2">
                              <span class="font-size13"><i class="fas fa-clock mr-2"></i>11 M </span>
                          </div>
                        </div>
                      </li>
                      <li class="user-activity">
                        <div class="row">
                          <div class="feeds-left col-md-10 px-3">
                              <p class="mb-0 font-size13">Talha S. has signed up for the Tenacious application.</p>
                          </div>
                          <div class="feeds-time col-md-2">
                              <span class="font-size13"><i class="fas fa-clock mr-2"></i>Now </span>
                          </div>
                        </div>
                      </li>
                      <li class="endpt-activity">
                        <div class="row">
                          <div class="feeds-left col-md-10 px-3">
                              <p class="mb-0 font-size13">A new Endpoint is detected through Edge Controller 1.</p>
                          </div>
                          <div class="feeds-time col-md-2">
                              <span class="font-size13"><i class="fas fa-clock mr-2"></i>21 H </span>
                          </div>
                        </div>
                      </li>
                      <li class="endpt-activity">
                        <div class="row">
                          <div class="feeds-left col-md-10 px-3">
                              <p class="mb-0 font-size13">A new Endpoint is detected through Edge Controller 3.</p>
                          </div>
                          <div class="feeds-time col-md-2">
                              <span class="font-size13"><i class="fas fa-clock mr-2"></i>30 M </span>
                          </div>
                        </div>
                      </li>
                </ul>
            </ng-scrollbar>
            </div>
        </div>
    </div> -->
</div>

<!--Event Log Table-->
<div class="row">
  <div class="col-xl-12 col-lg-12">
    <div class="card shadow rounded-xl mb-4 graphsCard-loader event-logs">
      <h6
        class="m-0 pt-3 pl-3 font-weight-bold float-left font-size13 text-capitalize"
      >
        Event Log
      </h6>
      <!-- <div class="card-header p-2 pb-0 clearfix"> -->
      <!-- </div> -->
      <div class="card-body mt-0">
        <p-table #ptable
          [value]="eventData"
          [rows]="10"
          [totalRecords]="eventData.length"
          class="table"
          [paginator]="isSelectedShipment && isSelectedShipmentName.length && !(isSelectedShipmentName[1].toLowerCase() === 'fixed')"
          [loading]="isEventDataLoading"
          [resizableColumns]="true" columnResizeMode="expand"
        >
          <ng-template pTemplate="header">
            <tr>
              <th pResizableColumn>Shipment Name</th>
              <th pResizableColumn>{{ constantVariables.ENDPOINT.LABEL2 }} UUID</th>
              <th pResizableColumn *ngIf="showBluTagCol">BluTag</th>
              <th pResizableColumn *ngIf="showBluTagCol">BluTag Temperature</th>
              <th pResizableColumn>Temperature</th>
              <th pResizableColumn>Humidity</th>
              <th pResizableColumn>Battery</th>
              <th pResizableColumn>Signal</th>
              <th pResizableColumn>Location</th>
              <th pResizableColumn>Event Time</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-row>
            <tr>
              <td class="align-middle">{{ row.shipmentName }}</td>
              <td class="align-middle">{{ row.deviceUUID }}</td>

              <td class="align-middle text-center" 
              *ngIf="showBluTagCol && (!row.sensors.length) || (row.sensors.length === 1)">{{row.sensors[0] ? row.sensors[0].tag : 'N/A'}}</td>
              <td class="align-middle text-center" 
              *ngIf="showBluTagCol && (!row.sensors.length) || (row.sensors.length === 1)">{{row.sensors[0] ? row.sensors[0].temperature ? (row.sensors[0].temperature  | number : '1.2-2') : 'N/A' : 'N/A'}}</td>

              <td class="align-middle pl-0 pr-0" *ngIf="showBluTagCol && row.sensors && row.sensors.length>1 && row.sensors[1].tag">
                <table style="border-color: white!important;">
                  <tr *ngFor="let sensor of row.sensors; index as i" class="text-center align-middle" >
                    <td class="cellTable p-0" style="border-color: #FFFFFF!important;" [ngStyle]="{'border-bottom' : i === row.sensors.length-1 ? 'grey !important ' : '' }" >{{sensor.tag}}</td>
                  </tr>
                </table>
              </td>
              <td class="align-middle pl-0 pr-0" *ngIf="showBluTagCol && row.sensors && row.sensors.length>1 && row.sensors[1].tag">
                <table style="border-color: white!important;">
                  <tr *ngFor="let sensor of row.sensors; index as i" class="text-center align-middle">
                    <td class="cellTable p-0" style="border-color: #FFFFFF!important;" [ngStyle]="{'border-bottom' : i === row.sensors.length-1 ? 'grey !important ' : '' }" >{{ sensor.temperature ? (sensor.temperature  | number : '1.2-2') : 'N/A' }}</td>
                  </tr>
                </table>
              </td>

              <td class="align-middle text-truncate">{{ row.temp ? (row.temp | number : '1.2-2') : '' }}</td>
              <td class="align-middle text-truncate">{{ row.humidity ? (row.humidity  | number : '1.2-2') : '' }}</td>
              <td class="align-middle">
                {{ calculateBatteryPercentage(row?.battery.split('V')[0], false) }}%
                <i
                  *ngIf="row.battery"
                  class="fas widget-icon"
                  [title]="calculateBatteryPercentage(row?.battery.split('V')[0]) + '%'"
                  [ngClass]="calculateBatteryPercentage(row?.battery.split('V')[0], true)"
                ></i>
              </td>
              <td class="align-middle">{{ row.signal }}</td>
              <td class="align-middle" [title]="row?.formattedAddress && row?.formattedAddress !== 'N/A'
                ? row?.formattedAddress : row?.location && row?.location !== 'N/A' ? row?.location : ''">{{ row.location }}</td>
              <td class="align-middle text-truncate" [title]="[(row.time | date: 'dd MMM y, h:mm a'), ' (' + row.timeStamp + ')']">
                {{ row.time | date: "dd MMM y, h:mm a" }}
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr><td colspan="8" class="no-data-found text-muted text-center">No results found.</td></tr>
          </ng-template>
        </p-table>
        <p-paginator #paginator *ngIf="!isSelectedShipment || (isSelectedShipmentName.length && (isSelectedShipmentName[1].toLowerCase() === 'fixed'))"
          [pageLinkSize]="10" 
          [rows]="10"
          [totalRecords]="totalEvents"
          (onPageChange)="getMoreEvents($event)"
        >
        </p-paginator>
      </div>
    </div>
  </div>
</div>

<!-- <div class="editModal-container" [ngClass]="{'open': iseditBlocklockdev}"> -->
<div class="editModal-container" id="show_details">
  <div class="row bg-modal-header px-3 py-2 mx-0" id="heading">
    <h6 class="float-left">Detail</h6>
    <i class="fas fa-times" (click)="closeModel()" title="close"></i>
  </div>
  <div class="editModal-content px-3" id="details">
    <ng-scrollbar
      #scrollable
      trackx="false"
      tracky="true"
      track="vertical"
      visibility="always"
      class="tabContent-scrollbar"
    >
      <!-- Device information -->
      <div class="row clearfix mt-2">
        <div class="col d-flex align-items-stretch">
          <div
            class="card number-chart map-card cursor-pointer text-center progressbar-card"
          >
            <div class="body pt-2 px-0 pb-0">
              <div class="number showDataCount">
                <h6
                  class="mb-2 font-weight-bold text-capitalize text-left pt-2 px-2 d-flex justify-content-between"
                >
                  <span
                    ><i class="ml-1 fas fa-shipping-fast">
                      <span id="shipmentName">
                        {{ selectedDevice?.shipmentName }}</span
                      >
                    </i>
                  </span>
                  <span class="align-self-center font_size_11 mr-1"
                    ><strong>Type</strong>:
                    <span id="shipmentType">{{
                      selectedDevice?.shipmentType
                    }}</span></span
                  >
                </h6>
                <!-- <hr class="text-light"> -->
                <ul class="list-inline d-flex my-0 pt-1">
                  <li class="flex-fill p-0">
                    <!-- <p class="font-size13">Edge</p> -->
                    <h5 class="mb-0 font_size_10">
                      <strong>{{
                        selectedDevice?.shipmentType === "Fixed" ||
                        selectedDevice?.shipmentType === "fixed"
                          ? "Date"
                          : "Pickup at"
                      }}</strong
                      >:
                      <span id="pickup">
                        {{
                          selectedDevice?.startedAt
                            | date: "dd MMM y, HH:mm:ss"
                        }}
                      </span>
                    </h5>
                  </li>
                  <li
                    class="flex-fill p-0"
                    *ngIf="
                      selectedDevice?.shipmentType === 'Started' ||
                      selectedDevice?.shipmentType === 'started'
                    "
                  >
                    <!-- <p class="font-size13">Edge</p> -->
                    <h5 class="mb-0 font_size_10">
                      <strong>Delivery at</strong>:
                      <span id="delivery">
                        {{
                          selectedDevice?.deliveryDate
                            | date: "dd MMM y, HH:mm:ss"
                        }}
                      </span>
                    </h5>
                  </li>
                  <li class="flex-fill p-0">
                    <!-- <p class="font-size13">Edge</p> -->
                    <h5 class="mb-0 font_size_10">
                      <strong>Status</strong>:
                      <span id="status">{{ selectedDevice?.status }}</span>
                    </h5>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="col d-flex align-items-stretch">
            <div class="card number-chart map-card
              cursor-pointer text-center progressbar-card">
              <div class="body pt-2 px-0 pb-0">
                <div class="number showDataCount">
                  <h6 class="mb-2 font-weight-bold font-size13 text-capitalize">
                  <i class="fas fa-microchip widget-icon"></i>	Device Id
                  </h6>
                  <ul class="list-inline d-flex">
                    <li class="flex-fill p-0">
                      <h5 class="mb-0 font_size_12">{{selectedDevice?.events[0]?.deviceUUID}}</h5>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div> -->

        <!-- <div class="col d-flex align-items-stretch">
            <div class="card number-chart map-card
              cursor-pointer text-center progressbar-card">
              <div class="body pt-2 px-0 pb-0">
                <div class="number showDataCount">
                  <h6 class="mb-2 font-weight-bold font-size13 text-capitalize">
                    Battery
                  </h6>
                  <ul class="list-inline d-flex">
                    <li class="flex-fill p-0">
                        <i class="fas widget-icon"
                            [title]="calculateBatteryPercentage(selectedDevice?.events[0]?.parsedData?.battery.split('V')[0]) + '%'"
                            [ngClass]="
                            selectedDevice?.events[0]?.parsedData?.battery.split('V')[0] > 3.7 ? 'fa-battery-full text-success'
                            : selectedDevice?.events[0]?.parsedData?.battery.split('V')[0] < 3.5 ? 'fa-battery-quarter text-danger'
                            : 'fa-battery-half text-orange'"
                        ></i>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div> -->
      </div>

      <!-- Start tab from here -->
      <div class="row mr-2 edilt-modal">
        <div class="col-xl-12 col-lg-12" *ngIf="deviceDataInfo">
          <ul class="nav nav-tabs map-tabs" id="map_tabs">
            <li
              *ngFor="let tabs of deviceDataInfo"
              [ngClass]="{ active: tabs.tab === 'Overview' }"
            >
              <a
                data-toggle="tab"
                href="#{{ tabs.tab }}"
                [ngClass]="{ active: tabs.tab === 'Overview' }"
                (click)="changeTab(tabs.tab)"
              >
                {{ tabs.tab }}
              </a>
            </li>
          </ul>
          <!-- Start tab content from here -->
          <div class="tab-content">
            <div id="Overview" class="tab-pane fade in active show">
              <div *ngIf="selectedDevice?.events?.length; else loading">
                <h5
                  class="font_size_12 font-weight-bold text-center text-light"
                >
                  Shipment Details
                </h5>
                <table
                  class="table table-striped"
                  *ngIf="selectedDevice?.events?.length"
                >
                  <tr>
                    <td class="font-weight-bold">Description:</td>
                    <td colspan="3">{{ selectedDevice.shipmentDesc }}</td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">Pickup:</td>
                    <td colspan="3">{{ selectedDevice.pickupLocation }}</td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">Destination:</td>
                    <td colspan="3">
                      {{ selectedDevice.destinationLocation }}
                    </td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">Last location:</td>
                    <td
                      [title]="selectedDevice.events[0]?.formattedAddress || selectedDevice.events[0].parsedData?.location || ''"
                    >{{ selectedDevice.events[0]?.formattedAddress || selectedDevice.events[0].parsedData?.location || 'N/A' }}</td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">Event time:</td>
                    <td>
                      {{
                        selectedDevice.events[0].timeStamp * 1000
                          | date: "dd MMM y, h:mm a"
                      }}
                    </td>
                  </tr>
                  <ng-container>
                    <div *ngIf="!isSelectedShipmentData"
                    class="card-loader d-flex justify-content-center flex-wrap align-content-center h-75"
                  >
                    <div class="spinner-grow text-dark" role="status"></div>
                  </div>
                    <tr>
                      <td colspan="4" class="p-0">
                        <p-accordion>
                          <p-accordionTab
                            class="p-0"
                            *ngFor="let device of selectedDevice.detailed"
                            [disabled]="!device.bluTagsData.length"
                          >
                            <ng-template pTemplate="header">
                              <div
                                class="d-flex w-100 flex-fill justify-content-between"
                              >
                                <div
                                  [class.pl-2]="!device.bluTagsData.length"
                                  class="font-weight-bold"
                                >
                                  &nbsp;Device Id:
                                  <span class="ml-2 font-weight-normal">
                                    {{ device.uuid }}
                                    <span
                                      class="font-weight-bold ml-3 font_size_10"
                                      *ngIf="device.bluTagsData.length"
                                      >Tag{{
                                        device.bluTagsData.length > 1
                                          ? "s"
                                          : ""
                                      }}<span class="font-weight-normal">{{
                                        "(" + device.bluTagsData.length + ")"
                                      }}</span>
                                    </span>
                                  </span>
                                </div>
                                <div class="font-weight-bold">
                                  <div>
                                    Battery:
                                    {{calculateBatteryPercentage(
                                      device?.battery.split('V')[0]
                                    ) + '%'}}
                                    <i
                                      class="ml-2 fas widget-icon"
                                      [title]="
                                        calculateBatteryPercentage(
                                          device?.battery.split('V')[0]
                                        ) + '%'
                                      "
                                      [ngClass]="
                                        calculateBatteryPercentage(device?.battery.split('V')[0], true)
                                      "
                                    ></i>
                                  </div>
                                  <div *ngIf="device?.signal !== null">
                                    <span class="mr-3 mt-2">Signal:</span>
                                    <span>{{ device?.signal }}</span>
                                    <svg
                                      *ngIf="device?.signal === 0"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="15"
                                      height="15"
                                      fill="currentColor"
                                      class="bi bi-wifi-off"
                                      viewBox="2 4 10 12"
                                    >
                                      <path
                                        d="M10.706 3.294A12.545 12.545 0 0 0 8 3C5.259 3 2.723 3.882.663 5.379a.485.485 0 0 0-.048.736.518.518 0 0 0 .668.05A11.448 11.448 0 0 1 8 4c.63 0 1.249.05 1.852.148l.854-.854zM8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065 8.448 8.448 0 0 1 3.51-1.27L8 6zm2.596 1.404.785-.785c.63.24 1.227.545 1.785.907a.482.482 0 0 1 .063.745.525.525 0 0 1-.652.065 8.462 8.462 0 0 0-1.98-.932zM8 10l.933-.933a6.455 6.455 0 0 1 2.013.637c.285.145.326.524.1.75l-.015.015a.532.532 0 0 1-.611.09A5.478 5.478 0 0 0 8 10zm4.905-4.905.747-.747c.59.3 1.153.645 1.685 1.03a.485.485 0 0 1 .047.737.518.518 0 0 1-.668.05 11.493 11.493 0 0 0-1.811-1.07zM9.02 11.78c.238.14.236.464.04.66l-.707.706a.5.5 0 0 1-.707 0l-.707-.707c-.195-.195-.197-.518.04-.66A1.99 1.99 0 0 1 8 11.5c.374 0 .723.102 1.021.28zm4.355-9.905a.53.53 0 0 1 .75.75l-10.75 10.75a.53.53 0 0 1-.75-.75l10.75-10.75z"
                                      />
                                    </svg>
                                    <svg
                                      *ngIf="device?.signal === 1"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="15"
                                      height="15"
                                      fill="currentColor"
                                      class="bi bi-wifi-1"
                                      viewBox="2 4 10 12"
                                    >
                                      <path
                                        d="M11.046 10.454c.226-.226.185-.605-.1-.75A6.473 6.473 0 0 0 8 9c-1.06 0-2.062.254-2.946.704-.285.145-.326.524-.1.75l.015.015c.16.16.407.19.611.09A5.478 5.478 0 0 1 8 10c.868 0 1.69.201 2.42.56.203.1.45.07.611-.091l.015-.015zM9.06 12.44c.196-.196.198-.52-.04-.66A1.99 1.99 0 0 0 8 11.5a1.99 1.99 0 0 0-1.02.28c-.238.14-.236.464-.04.66l.706.706a.5.5 0 0 0 .707 0l.708-.707z"
                                      />
                                    </svg>
                                    <svg
                                      *ngIf="
                                        device?.signal > 1 &&
                                        device?.signal < 31
                                      "
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="15"
                                      height="15"
                                      fill="currentColor"
                                      class="bi bi-wifi-2"
                                      viewBox="2 4 10 14"
                                    >
                                      <path
                                        d="M13.229 8.271c.216-.216.194-.578-.063-.745A9.456 9.456 0 0 0 8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065A8.46 8.46 0 0 1 8 7a8.46 8.46 0 0 1 4.577 1.336c.205.132.48.108.652-.065zm-2.183 2.183c.226-.226.185-.605-.1-.75A6.473 6.473 0 0 0 8 9c-1.06 0-2.062.254-2.946.704-.285.145-.326.524-.1.75l.015.015c.16.16.408.19.611.09A5.478 5.478 0 0 1 8 10c.868 0 1.69.201 2.42.56.203.1.45.07.611-.091l.015-.015zM9.06 12.44c.196-.196.198-.52-.04-.66A1.99 1.99 0 0 0 8 11.5a1.99 1.99 0 0 0-1.02.28c-.238.14-.236.464-.04.66l.706.706a.5.5 0 0 0 .708 0l.707-.707z"
                                      />
                                    </svg>
                                    <svg
                                      *ngIf="
                                        device?.signal > 30 &&
                                        device?.signal < 52
                                      "
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="15"
                                      height="15"
                                      fill="currentColor"
                                      class="bi bi-wifi"
                                      viewBox="2 6 10 14"
                                    >
                                      <path
                                        d="M15.384 6.115a.485.485 0 0 0-.047-.736A12.444 12.444 0 0 0 8 3C5.259 3 2.723 3.882.663 5.379a.485.485 0 0 0-.048.736.518.518 0 0 0 .668.05A11.448 11.448 0 0 1 8 4c2.507 0 4.827.802 6.716 2.164.205.148.49.13.668-.049z"
                                      />
                                      <path
                                        d="M13.229 8.271a.482.482 0 0 0-.063-.745A9.455 9.455 0 0 0 8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065A8.46 8.46 0 0 1 8 7a8.46 8.46 0 0 1 4.576 1.336c.206.132.48.108.653-.065zm-2.183 2.183c.226-.226.185-.605-.1-.75A6.473 6.473 0 0 0 8 9c-1.06 0-2.062.254-2.946.704-.285.145-.326.524-.1.75l.015.015c.16.16.407.19.611.09A5.478 5.478 0 0 1 8 10c.868 0 1.69.201 2.42.56.203.1.45.07.61-.091l.016-.015zM9.06 12.44c.196-.196.198-.52-.04-.66A1.99 1.99 0 0 0 8 11.5a1.99 1.99 0 0 0-1.02.28c-.238.14-.236.464-.04.66l.706.706a.5.5 0 0 0 .707 0l.707-.707z"
                                      />
                                    </svg>
                                    <svg
                                      *ngIf="device?.signal === 99"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="15"
                                      height="15"
                                      fill="currentColor"
                                      class="bi bi-reception-0"
                                      viewBox="0 10 5 8"
                                    >
                                      <path
                                        d="M0 13.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm4 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm4 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm4 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"
                                      />
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </ng-template>
                            <ng-template pTemplate="content">
                              <div
                                *ngIf="device.bluTagsData.length"
                                class="d-flex flex-fill justify-content-between flex-wrap"
                              >
                                <table class="flex-fill w-100">
                                  <thead>
                                    <tr>
                                      <th>Mac Id</th>
                                      <th>Current Temp</th>
                                      <th>Alerts</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let bluTag of device.bluTagsData">
                                      <td>{{ bluTag.tag || "N/A" }}</td>
                                      <td>{{ bluTag.temp  | number : '1.2-2' || "N/A" }}</td>
                                      <td
                                        [ngClass]="{ alert: bluTag.highAlert || bluTag.lowAlert }"
                                      >{{bluTag.highAlert + bluTag.lowAlert || "N/A"}}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </ng-template>
                          </p-accordionTab>
                        </p-accordion>
                      </td>
                    </tr>
                  </ng-container>
                </table>
                <ng-container>
                  <div *ngFor="let device of selectedDevice.detailed">
                  <!-- <div *ngFor="let device of selectedDevice.detailed" (click)="downloadCallibration(device.uuid)"> -->
                     <a href="assets/certificates/calibration-certificate.pdf" download="calibration-certificate.pdf" style="color: cornflowerblue;margin: 10px;cursor: pointer;">
                      <u>Download callibration certificate for Tracker - {{device.uuid}}</u>
                     </a>
                  </div>
                </ng-container>
              </div>
            </div>
            <div id="Locations" class="tab-pane fade">
              <div class="row" style="height: 300px;" *ngIf="isSelectedShipmentData else loading">
                <div class="&quot;col-xl-12 col-lg-12" [ngClass]="noLocations? 'nolocation': ''">
                  <!-- <div id="deviceMap"></div> -->
                  <app-map *ngIf="!noLocations; else nolocationmessage"
                    page="smallmap" 
                    [type]="type"
                    [smallMapData]="smallMapData"
                    [hData]="hData"
                    [zoom]="zoom"
                    [mapType]="mapType"
                    [cluster]="cluster"
                    [status]="selectedDevice ? selectedDevice?.status : ''"
                    [id]="selectedDevice ? selectedDevice?.id : isSelectedShipmentName[0]"
                    [freq]="
                      selectedDevice ? selectedDevice.trackers[0]?.freq : 0
                    "
                    (showLoadShipmentEvent)="showLoadShipment = $event"
                    htmlEle="deviceMap"
                    [pickup]="selectedDevice ? selectedDevice.pickupLocation : ''"
                    [destination]="selectedDevice ? selectedDevice.destinationLocation : ''"
                  ></app-map>
                  <ng-template #nolocationmessage>
                    <h6>No locations found.</h6>
                  </ng-template>
                </div>
              </div>
              <table
                class="table table-striped"
                *ngIf="selectedDevice?.events?.length && isSelectedShipmentData else loading"
              >
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Location</th>
                    <!-- <th *ngIf="selectedDevice?.events[0]?.blueTag">Blu Tag {{selectedDevice?.events[0].shipmentId || 0}}</th> -->
                    <th>Battery</th>
                    <th *ngIf="selectedDevice?.events[0].parsedData?.signal">
                      Signal
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container
                    *ngFor="let event of selectedDevice?.events; let i = index"
                  >
                    <tr
                      *ngIf="
                        selectedDevice?.events[i - 1]?.timeStamp !=
                        event?.timeStamp
                      "
                    >
                      <td>{{ event?.time | date: "dd MMM y, h:mm a" }}</td>
                      
                      <td
                        [title]="event?.formattedAddress || event?.parsedData?.location || ''"
                      >{{ event?.parsedData?.location }}</td>
                      <!-- <td *ngIf="selectedDevice?.events[0]?.blueTag">{{event?.blueTag}}{{event?.temperature ? ', ' + event?.temperature : ''}}</td> -->
                      <td>
                         {{calculateBatteryPercentage(
                          event?.parsedData?.battery.split('V')[0]
                        ) + '%  '}}
                        <i
                          class="fas widget-icon"
                          [title]="
                            calculateBatteryPercentage(
                              event?.parsedData?.battery.split('V')[0]
                            ) + '%'
                          "
                          [ngClass]="
                            calculateBatteryPercentage(event?.parsedData?.battery.split('V')[0], true)
                          "
                        ></i>
                        <!-- {{event?.parsedData?.battery.split('V')[0]}} <i class="fas fa-bolt"></i> -->
                      </td>
                      <td *ngIf="selectedDevice?.events[0].parsedData?.signal">
                        <span>{{ event?.parsedData?.signal }}</span>
                        <svg
                          *ngIf="event?.parsedData?.signal === 0"
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          fill="currentColor"
                          class="bi bi-wifi-off"
                          viewBox="2 4 10 12"
                        >
                          <path
                            d="M10.706 3.294A12.545 12.545 0 0 0 8 3C5.259 3 2.723 3.882.663 5.379a.485.485 0 0 0-.048.736.518.518 0 0 0 .668.05A11.448 11.448 0 0 1 8 4c.63 0 1.249.05 1.852.148l.854-.854zM8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065 8.448 8.448 0 0 1 3.51-1.27L8 6zm2.596 1.404.785-.785c.63.24 1.227.545 1.785.907a.482.482 0 0 1 .063.745.525.525 0 0 1-.652.065 8.462 8.462 0 0 0-1.98-.932zM8 10l.933-.933a6.455 6.455 0 0 1 2.013.637c.285.145.326.524.1.75l-.015.015a.532.532 0 0 1-.611.09A5.478 5.478 0 0 0 8 10zm4.905-4.905.747-.747c.59.3 1.153.645 1.685 1.03a.485.485 0 0 1 .047.737.518.518 0 0 1-.668.05 11.493 11.493 0 0 0-1.811-1.07zM9.02 11.78c.238.14.236.464.04.66l-.707.706a.5.5 0 0 1-.707 0l-.707-.707c-.195-.195-.197-.518.04-.66A1.99 1.99 0 0 1 8 11.5c.374 0 .723.102 1.021.28zm4.355-9.905a.53.53 0 0 1 .75.75l-10.75 10.75a.53.53 0 0 1-.75-.75l10.75-10.75z"
                          />
                        </svg>
                        <svg
                          *ngIf="event?.parsedData?.signal === 1"
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          fill="currentColor"
                          class="bi bi-wifi-1"
                          viewBox="2 4 10 12"
                        >
                          <path
                            d="M11.046 10.454c.226-.226.185-.605-.1-.75A6.473 6.473 0 0 0 8 9c-1.06 0-2.062.254-2.946.704-.285.145-.326.524-.1.75l.015.015c.16.16.407.19.611.09A5.478 5.478 0 0 1 8 10c.868 0 1.69.201 2.42.56.203.1.45.07.611-.091l.015-.015zM9.06 12.44c.196-.196.198-.52-.04-.66A1.99 1.99 0 0 0 8 11.5a1.99 1.99 0 0 0-1.02.28c-.238.14-.236.464-.04.66l.706.706a.5.5 0 0 0 .707 0l.708-.707z"
                          />
                        </svg>
                        <svg
                          *ngIf="
                            event?.parsedData?.signal > 1 &&
                            event?.parsedData?.signal < 31
                          "
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          fill="currentColor"
                          class="bi bi-wifi-2"
                          viewBox="2 4 10 12"
                        >
                          <path
                            d="M13.229 8.271c.216-.216.194-.578-.063-.745A9.456 9.456 0 0 0 8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065A8.46 8.46 0 0 1 8 7a8.46 8.46 0 0 1 4.577 1.336c.205.132.48.108.652-.065zm-2.183 2.183c.226-.226.185-.605-.1-.75A6.473 6.473 0 0 0 8 9c-1.06 0-2.062.254-2.946.704-.285.145-.326.524-.1.75l.015.015c.16.16.408.19.611.09A5.478 5.478 0 0 1 8 10c.868 0 1.69.201 2.42.56.203.1.45.07.611-.091l.015-.015zM9.06 12.44c.196-.196.198-.52-.04-.66A1.99 1.99 0 0 0 8 11.5a1.99 1.99 0 0 0-1.02.28c-.238.14-.236.464-.04.66l.706.706a.5.5 0 0 0 .708 0l.707-.707z"
                          />
                        </svg>
                        <svg
                          *ngIf="
                            event?.parsedData?.signal > 30 &&
                            event?.parsedData?.signal < 52
                          "
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          fill="currentColor"
                          class="bi bi-wifi"
                          viewBox="2 6 10 14"
                        >
                          <path
                            d="M15.384 6.115a.485.485 0 0 0-.047-.736A12.444 12.444 0 0 0 8 3C5.259 3 2.723 3.882.663 5.379a.485.485 0 0 0-.048.736.518.518 0 0 0 .668.05A11.448 11.448 0 0 1 8 4c2.507 0 4.827.802 6.716 2.164.205.148.49.13.668-.049z"
                          />
                          <path
                            d="M13.229 8.271a.482.482 0 0 0-.063-.745A9.455 9.455 0 0 0 8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065A8.46 8.46 0 0 1 8 7a8.46 8.46 0 0 1 4.576 1.336c.206.132.48.108.653-.065zm-2.183 2.183c.226-.226.185-.605-.1-.75A6.473 6.473 0 0 0 8 9c-1.06 0-2.062.254-2.946.704-.285.145-.326.524-.1.75l.015.015c.16.16.407.19.611.09A5.478 5.478 0 0 1 8 10c.868 0 1.69.201 2.42.56.203.1.45.07.61-.091l.016-.015zM9.06 12.44c.196-.196.198-.52-.04-.66A1.99 1.99 0 0 0 8 11.5a1.99 1.99 0 0 0-1.02.28c-.238.14-.236.464-.04.66l.706.706a.5.5 0 0 0 .707 0l.707-.707z"
                          />
                        </svg>
                        <i class="fa-solid fa-wifi-exclamation"></i>
                        <svg
                          *ngIf="event?.parsedData?.signal == 99"
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          fill="currentColor"
                          class="bi bi-reception-0"
                          viewBox="0 10 5 8"
                        >
                          <path
                            d="M0 13.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm4 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm4 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm4 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"
                          />
                        </svg>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>

            <div id="Humidity" class="tab-pane fade">
              <div class="row" *ngIf="isSelectedShipmentData else loading">
                <div class="&quot;col-xl-12 col-lg-12">
                  <div
                    echarts
                    [options]="chartData"
                    [merge]="chartDataOptions"
                    class="map-chart w-100"
                    [autoResize]="autoResize"
                    *ngIf="
                      !loadDetailGraph && chartData != undefined;
                      else loading
                    "
                  ></div>
                </div>
              </div>
              <table
                class="table table-striped"
                *ngIf="selectedDevice?.events?.length && isSelectedShipmentData"
              >
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Humidity</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container
                    *ngFor="let event of selectedDevice?.events; let i = index"
                  >
                    <tr
                      *ngIf="
                        selectedDevice?.events[i - 1]?.timeStamp !=
                        event?.timeStamp
                      "
                    >
                      <td>{{ event?.time ? (event?.time | date: "dd MMM y, h:mm a") : '' }}</td>
                      <td>{{ event?.parsedData?.humidity ? (event?.parsedData?.humidity | number : '1.2-2') : '' }}</td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>

            <div id="Temperature" class="tab-pane fade">
              <div class="row" *ngIf="isSelectedShipmentData else loading">
                <div class="&quot;col-xl-12 col-lg-12">
                  <div
                    echarts
                    [options]="chartData"
                    [merge]="chartDataOptions"
                    class="map-chart w-100"
                    [autoResize]="autoResize"
                    *ngIf="
                      !loadDetailGraph && chartData != undefined;
                      else loading
                    "
                  ></div>
                </div>
              </div>
              <table
                class="table table-striped"
                *ngIf="selectedDevice?.events?.length && isSelectedShipmentData"
              >
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Temperature</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container
                    *ngFor="let event of selectedDevice?.events; let i = index"
                  >
                    <tr
                      *ngIf="
                        selectedDevice?.events[i - 1]?.timeStamp !=
                        event?.timeStamp
                      "
                    >
                      <td>{{ event?.time ? (event?.time | date: "dd MMM y, h:mm a") : '' }}</td>
                      <td>{{ event?.parsedData?.temp ? (event?.parsedData?.temp  | number : '1.2-2') : '' }}</td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>

            <div id="Tags" class="tab-pane fade">
              <div class="row mx-0" *ngIf="isSelectedShipmentData else loading">
                <div class="&quot;col-xl-12 col-lg-12">
                  <select
                    (change)="dataFilterByTag($event.target.value)"
                    class="form-control mb-2"
                  >
                    <option
                      *ngFor="let tag of bluTags"
                      [selected]="selectedBluTagData?.tag === tag"
                    >
                      {{ tag }}
                    </option>
                  </select>
                </div>
                <div class="&quot;col-xl-12 col-lg-12" *ngIf="noGraphData" >
                  <div
                    class="map-chart w-100 h-100 d-flex flex-col text-white justify-content-center"
                  > N/A</div>
                </div>
                <div class="&quot;col-xl-12 col-lg-12" *ngIf="!noGraphData" >
                  <div
                    echarts
                    [options]="chartData"
                    [merge]="chartDataOptions"
                    class="map-chart w-100"
                    [autoResize]="autoResize"
                    *ngIf="
                      !loadDetailGraph && chartData != undefined;
                      else loading
                    "
                  ></div>
                </div>
              </div>
              <table
                class="table table-striped"
                *ngIf="
                  selectedBluTagData?.filterTagData?.length && !loadDetailGraph 
                "
              >
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>BluTag</th>
                    <th>Temperature</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let event of selectedBluTagData.filterTagData">
                    <td>{{ event?.time | date: "dd MMM y, h:mm a" }}</td>
                    <td>{{ event?.blueTag }}</td>
                    <td [ngClass]="{ alert: event?.temperature && event?.alert !== 'Normal' }">
                      {{ (event?.temperature || event?.temperature === 0) ? (event?.temperature  | number : '1.2-2') : 'N/A' }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div id="Alerts" class="tab-pane fade">
              <div *ngIf="!alerts.length">
                <p class="no-data-found">No data found.</p>
              </div>
              <div class="row mb-0" *ngFor="let alertData of alerts">
                <div class="&quot;col-xl-12 col-lg-12">
                  <div class="card mb-2 m-0 p-0 alert-card">
                    <div class="card-body">
                      <i
                        class="fas fa-times remove-alert-icon"
                        (click)="removeAlert(alertData)"
                        title="remove"
                      ></i>
                      <b>Device Id :</b> {{ alertData.deviceId }}<br />
                      <b>Alert Count :</b> {{ alertData.alertCount }}<br />
                      <b>Edge Count :</b> {{ alertData.edgeCount }}<br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-scrollbar>
  </div>
</div>

<!-- Reset Password Modal -->
<ng-template #resetPasswordModal let-modal>
  <div class="row mx-0">
    <div class="col-md-12 pt-3 pl-3">
      <h6 class="font-weight-bold pl-3">Hi {{ fullName | titlecase }},</h6>
      <p class="fsize13 pl-3">
        It's time change your password!
      </p>
    </div>
  </div>
  <div class="modal-body mt-0 py-0 mx-2">
    <app-change-password [isOpenInModal]="true" (newItemEvent)="closeModal($event)"></app-change-password>
  </div>
</ng-template>

<ng-template #noData>
  <p
    *ngIf="!isShipmentsWithEvents; else loading"
    class="no-data-found text-muted"
  >
    No results found.
  </p>
</ng-template>
<ng-template #noMapData>
  <p *ngIf="!isMapLoading; else loading" class="no-data-found text-muted">
    No results found.
  </p>
</ng-template>
<ng-template #loading>
  <div
    class="card-loader d-flex justify-content-center flex-wrap align-content-center h-75"
  >
    <div class="spinner-grow text-dark" role="status"></div>
  </div>
</ng-template>


<!-- Welcome Modal -->
<ng-template #welcomeModal let-modal>
  <button type="button" class="close light" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">×</span>
  </button>
  <div class="row mx-0">
    <div class="col-md-6 py-4 pl-3">
      <h6 class="font-weight-bold pl-3">Welcome, {{ fullName | titlecase }}</h6>
      <p class="fsize13 pl-3">
        Get your team on-board, so that you don't feel alone!
      </p>
    </div>
    <div class="col-md-6 text-center rounded-top welcm-bgimg pt-4 px-0">
      <img class="img-fluid" src="assets/images/onboard.svg" alt="" width="130" />
    </div>
  </div>
  <div class="modal-body">
    <p class="mt-2 mb-0 fsize13">
      Use the URL below to invite other users within your organization.
    </p>
    <div class="form-group col-md-12 mb-3 passwField px-0">
      <label class="has-float-label">
        <input class="form-control" #urlLink id="fname" [(ngModel)]="url" readonly type="text" placeholder="URL*" />
      </label>
      <i class="far fa-clone password-field-icon" (click)="copyUrlToClipboard(urlLink)"></i>
    </div>

    <div class="modal-footer text-right py-2">
      <a href="javascript:void(0)" class="border-dashed-btm border-white text-white" aria-label="Close"
        (click)="modal.dismiss('Cross click')">Okay, got it</a>
    </div>
  </div>
</ng-template>
