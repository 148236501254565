import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ApiService } from 'src/app/core/services/api.service';
import { CommonService } from '../../../core/services/common.service';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConstantVariables } from 'src/constants/constants';
import { ShipmentsService } from '../../shipments/services/shipments.service';


@Injectable({
  providedIn: 'root'
})
export class CommunicationChainService {

  constructor(
    public constantVariables: ConstantVariables,
    private http: HttpClient,
    private commonService: CommonService,
    private shipmentService: ShipmentsService
  ) { }

  /**
   * @description get all end point list
   */
  getAllEventData() {
    const { user } = this.commonService.getCurrentUserData();
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      params: new HttpParams({ fromString: `email=${user.officialEmail}` })
    };

    return this.http.get(ApiService.URLs.eventManagement, options);
  }


  getEventAlerts(id: any) {
    const fromString = `shipmentId=${id}`
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      params: new HttpParams({ fromString })
    };
    return this.http.get(ApiService.URLs.eventAlert, options);
  }

  getLastLocation(id: any) {
    const fromString = `itemIds=${id}&limit=500000&type=shipment&lastRecordOnly=true&locationDataOnly=true`
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      params: new HttpParams({ fromString })
    };
    return this.http.get(ApiService.URLs.eventManagement, options);
  }

  getAllEventDataById(id: any, isTypeShipmentData?: boolean, page?: number, lastDataOnly?: boolean, isTypeTracker?: boolean) {
    let fromString = isTypeShipmentData ? page ? `itemIds=${id}&limit=10&page=${page}&type=shipment` : `itemIds=${id}&limit=500000&type=shipment` : `itemIds=${id}&limit=500`
    if (lastDataOnly) {
      fromString = `itemIds=${id}&limit=50000&lastRecordOnly=${lastDataOnly}&type=shipment`
    }
    if (isTypeTracker)
      fromString = `itemIds=${id}&limit=50000&lastRecordOnly=${lastDataOnly}&type=tracker`
    // const { user } = this.commonService.getCurrentUserData();
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      // params: new HttpParams({ fromString: `email=${user.officialEmail}&deviceUUID=${id}` })
      params: new HttpParams({ fromString })
    };

    // 618e344ddc46159993a837ee
    // 618e4b8ad1ebe8a1dab3b2cc

    return this.http.get(ApiService.URLs.eventManagement, options);
  }

  /**
   * @description get all trackers list with events
  */
  public getTrackersWithEvents(trackers: any[], lastDataOnly?: boolean, page?: number): Observable<any> {
    const eventAPIs: Observable<any>[] = [];
    trackers?.map((tracker: any) => {
      // console.log('trackershipment======', tracker)
      if (lastDataOnly)
        eventAPIs.push(this.getAllEventDataById(tracker.id, true, undefined, lastDataOnly));
      else if(page)
        eventAPIs.push(this.getAllEventDataById(tracker.id, true, page));
      else
        eventAPIs.push(this.getAllEventDataById(tracker.id, true));
      // eventAPIs.push(this.getAllEventDataById(tracker.data.deviceUUID));
    });
    // return this.forkJoinAllEvents(trackers);    
    return this.forkJoinAllEvents(eventAPIs, trackers);
  }


  /**
   * @description get all shipments list with events
  */
  public getShipmentsWithEvents(trackers: any[]): Observable<any> {
    const eventAPIs: Observable<any>[] = [];
    let itemIds = trackers.map((shipment) => shipment.id)
    eventAPIs.push(this.getAllEventDataById(itemIds, true, undefined, true));
    return this.forkAllEvents(eventAPIs, trackers);
  }
  private rad(x) {
    return x * Math.PI / 180;
  };

  public getDistance(lastLocation, currentLocation) {
    const R = 6378137; // Earth’s mean radius in meter
    const dLat = this.rad(Number(currentLocation.latitude) - Number(lastLocation.latitude));
    const dLong = this.rad((Number(currentLocation.longitude)) - (Number(lastLocation.longitude)));
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.rad(Number(lastLocation.latitude))) * Math.cos(this.rad(Number(currentLocation.latitude))) *
      Math.sin(dLong / 2) * Math.sin(dLong / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return d; // returns the distance in meter
  };

  private forkAllEvents(eventAPIs: Observable<any>[], trackers: any[]): Observable<any> {
    let data, groupByShipments = {};
    return forkJoin(eventAPIs).pipe(
      map(pages => {
        data = pages[0].items;
        let count = 0;
        data.map(function (a) {
          count++;
          groupByShipments[a.shipmentId] = groupByShipments[a.shipmentId] || [];
          groupByShipments[a.shipmentId].push(a);
        });
        trackers.map((tracker, index) => {
          tracker.latitude = null;
          tracker.longitude = null;
          tracker.events = groupByShipments[tracker.id] ? groupByShipments[tracker.id] : [];
          // tracker.events = tracker.events.slice(0, this.constantVariables.limit);
          if (tracker.events.length) {
            tracker.events.map(event => {
              event.parsedData = this.commonService.parseEventPayload(event.payload);
              event.time = new Date(event.timeStamp * 1000);
              if (isNaN(event.parsedData.humidity))
                event.parsedData.humidity = undefined;
              if (isNaN(event.parsedData.temp))
                event.parsedData.temp = undefined;
            });
            // tracker.latitude = tracker.events[0].parsedData?.latitude;
            // tracker.longitude = tracker.events[0].parsedData?.longitude;

            tracker.events = tracker.events.filter(evnt => evnt.parsedData && evnt.parsedData.latitude && evnt.parsedData.longitude);
            tracker.latitude = tracker.events.length ? tracker.events[0].parsedData.latitude : null;
            tracker.longitude = tracker.events.length ? tracker.events[0].parsedData.longitude : null;
          }
        });
        return trackers;
      })
    );
  }

  private forkJoinAllEvents(eventAPIs: Observable<any>[], trackers: any[]): Observable<any> {
    let data, groupByShipments = {};
    
    return forkJoin(eventAPIs).pipe(
      map(pages => {
        trackers.map((tracker, index) => {
          tracker.eventsLength = pages[index].meta.totalItems;
          tracker.latitude = null;
          tracker.longitude = null;
          tracker.events = pages[index].items;
          // tracker.events = tracker.events.slice(0, this.constantVariables.limit);
          if (tracker.events.length) {
            tracker.events.map(event => {
              event.parsedData = this.commonService.parseEventPayload(event.payload);
              event.time = new Date(event.timeStamp * 1000);
              if (isNaN(event.parsedData.humidity))
                event.parsedData.humidity = undefined;
              if (isNaN(event.parsedData.temp))
                event.parsedData.temp = undefined;
            });
            // tracker.latitude = tracker.events[0].parsedData?.latitude;
            // tracker.longitude = tracker.events[0].parsedData?.longitude;

            // tracker.events = tracker.events.filter(evnt => evnt.parsedData && evnt.parsedData.latitude && evnt.parsedData.longitude);
            tracker.latitude = tracker.events.length ? tracker.events[0].parsedData.latitude : null;
            tracker.longitude = tracker.events.length ? tracker.events[0].parsedData.longitude : null;
          }

        });
        return trackers;
      })
    );
  }

  // public getFormatedAddress(trackers) {
  //   console.log('trackers----------', trackers)
  //   let allLocations = trackers[0]?.events.map((x) => {
  //     return {
  //       lat: x.parsedData.latitude,
  //       lng: x.parsedData.longitude
  //     }
  //   });
  //   allLocations = allLocations.filter((v, i, a) => a.findIndex(t => t.lat === v.lat && t.lng && v.lng) === i)
  //   for (let event of allLocations) {
  //     const lat = event.lat;
  //     const lng = event.lng;
  //     if (lat && lng) {
  //       let geocoder = new google.maps.Geocoder;
  //       let latlng = { lat: Number(lat), lng: Number(lng) };
  //       geocoder.geocode({ location: latlng }, (results) => {
  //         if (results[0]) {
  //           event['formatedAddress'] = results[0].formatted_address;
  //         } else {
  //           console.log('No results found');
  //         }
  //       });
  //       console.log('event.parsedData', lat, lng);
  //     }
  //   }
  //   // allLocations;
  //   // console.log("allLocations-------------215", allLocations);
  //   let num = 0;
  //   for(let e of trackers[0].events) {
  //     console.log('e--------------', allLocations, e.parsedData.latitude, e.parsedData.longitude);
  //     for(let adr of allLocations) { 
  //       console.log('addr------------------', adr, adr.ltd, adr.lng);
  //       console.log('addr------------------', adr.ltd == e.parsedData.latitude && adr.lng == e.parsedData.longitude)
  //     };
  //     // if(a) {
  //     //   console.log('addr------------------', a, num);
  //     //   trackers[0].events[num].parsedData.formatedAddress = a.formatedAddress;
  //     // }
  //     num++
  //   };
  //   return trackers;
  // }
}
