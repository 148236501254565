import { Component, OnInit } from '@angular/core';
import { AccessService } from '../../services/access.service';
import { CommonService } from 'src/app/core/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { API_ERROR } from 'src/app/core/constants/global-error.constants';
import { MENUS } from 'src/app/core/constants/menu-items.constants';
import { API_SUCCESS } from 'src/app/core/constants/global-success.constants';
import { LEVELS } from 'src/app/core/constants/level.constants';
import { LIMIT } from 'src/app/core/constants/pagination.constants';
import { access } from '../../model/access-levels';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConstantVariables } from 'src/constants/constants';

@Component({
  selector: 'app-add-new-access-level',
  templateUrl: './add-new-access-level.component.html',
  styleUrls: ['./add-new-access-level.component.css']
})
export class AddNewAccessLevelComponent implements OnInit {
  loaderFlag: boolean;
  accessData: any;
  userData: any;
  deleteArr = [];
  config: { itemsPerPage: number; currentPage: number; totalItems: any; };
  levelName: any;
  command: string;
  levelData: any;
  delLevelName: any;
  permissionsObj = JSON.parse(JSON.stringify(access.permissions));  
  ledgerShowTable: boolean;
  logsShowTable: boolean;
  accessMenu = { name: 'Access Control', submenu: {}, view: false, action: false };

  constructor(
      public constantVariables: ConstantVariables,
      private accessService: AccessService,
      private commonService: CommonService,
      private toastr: ToastrService,
      private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    this.getUserData();
    this.getAccessLevels();
  }

  /**
     * 
     * @param data 
     * @description select user to delete and store into array
     */
    multiDelete(data) {
      const i = this.deleteArr.indexOf(data);
      if (i == -1) {
          this.deleteArr.push(data);
      } else {
          this.deleteArr.splice(i, 1);
      }
  }
  
      /**
       * @description get current user access-control submenu
       */
    getUserData() {
      this.userData = this.commonService.getCurrentUserData();
      if (this.userData && this.userData.accesslevel && this.userData.accesslevel.permissions) {
          this.accessMenu = this.userData.accesslevel.permissions['access-control'].submenu;
      }
   }
  
  /**
   * 
   * @param event 
   * @param menu 
   * @description show ledger and logs tabel based on checkbox condition
   */
   show(event, menu) {
          if (menu === 'Ledger') {
              event.target.checked ? this.ledgerShowTable = true : this.ledgerShowTable = false;
          } else if(menu === 'Logs') {
              event.target.checked ? this.logsShowTable = true : this.logsShowTable = false;
       }
      }
      
  
      /**
       * 
       * @param event 
       * @description  based on ledger checkbox show all ledger submenu true or false
       */
      allLedger(event) {
          if (event.target.checked) {
              $('.ledger:input:checkbox').each(function () { this['checked'] = true; });
              this.permissionsObj.utility.submenu.ledger = {
                  name: 'Ledger', view: true, create: true, update: true, delete: true, action: true, ledgerSubMenu: {
                      user: { name: 'User Chain', view: true, create: true, update: true, delete: true, action: true },
                      device: { name: 'Identity Chain', view: true, create: true, update: true, delete: true, action: true },
                      edge: { name: 'Edge Chain', view: true, create: true, update: true, delete: true, action: true },
                      event: { name: 'Communication Chain', view: true, create: true, update: true, delete: true, action: true },
                      "smart-contract":{ name: 'Smart Contract Chain', view: true, create: true, update: true, delete: true, action: true },
              } };
          } else {
              $('.ledger:input:checkbox').each(function () { this['checked'] = false; });
              this.permissionsObj.utility.submenu.ledger = {
                  name: 'Ledger', view: false, create: false, update: false, delete: false, action: false, ledgerSubMenu: {
                      user: { name: 'User Chain', view: false, create: false, update: false, delete: false, action: false },
                      device: { name: 'Identity Chain', view: false, create: false, update: false, delete: false, action: false },
                      edge: { name: 'Edge Chain', view: false, create: false, update: false, delete: false, action: false },
                      event: { name: 'Communication Chain', view: false, create: false, update: false, delete: false, action: false },
                      "smart-contract":{ name: 'Smart Contract Chain', view: false, create: false, update: false, delete: false, action: false },
              } };
          }
      }   
  
      /**
       * 
       * @param event 
       * @description based on logs checkbox show logs submenu true or false
       */
      allLogs(event) {
          if (event.target.checked) {
              $('.logs:input:checkbox').each(function () { this['checked'] = true });
              this.permissionsObj.utility.submenu.logs = {
                  name: 'Logs', view: true, create: true, update: true, delete: true, action: true, logSubMenu: {
                      systemLog: { name: 'System Log', view: true, create: true, update: true, delete: true, action: true },
                      eventLog:{ name: 'Event Log', view: true, create: true, update: true, delete: true, action: true },
                  }
              }
          } else {
              $('.logs:input:checkbox').each(function () { this['checked'] = false });
              this.permissionsObj.utility.submenu.logs = {
                  name: 'Logs', view: false, create: false, update: false, delete: false, action: false, logSubMenu: {
                      systemLog: { name: 'System Log', view: false, create: false, update: false, delete: false, action: false },
                      eventLog:{ name: 'Event Log', view: false, create: false, update: false, delete: false, action: false },
                  }
              }
          }
      }
  
  /**
   * @description get all access level list
   */
    getAccessLevels() {
      this.loaderFlag = true;
      this.accessService.getAccessLevel().subscribe(
          (response) => {
              this.loaderFlag = false;
              this.accessData = response;
              if (this.accessData && this.accessData.length > 0)
                  this.config = {
                      itemsPerPage: LIMIT.PER_PAGE,
                      currentPage: LIMIT.CURRENT_PAGE,
                      totalItems: this.accessData.length
                  }
              this.spinner.hide();
          },
          (error) => {
              this.loaderFlag = false;
              if (error?.message === API_ERROR.USER_LOGOUT) { 
                this.commonService.logout(API_ERROR.USER_LOGOUT);
              }
              error
          }
      )
      }
      
      /**
     * @description page change in pagination
     */
    pageChanged(event) {
      this.config.currentPage = event;
    }
    
  /**
   * 
   * @param event 
   * @description send command input based on check box selection
   */
    sendCommand(event) {
      if (event.target.checked) {
          this.command = MENUS.COMMAND_VALUE_1;
      } else {
          this.command = MENUS.COMMAND_VALUE_0;
      }
  }
  
      /**
       * 
       * @param form 
       * @description add new access level
       */
    addAccessLevel(form: NgForm) {
        const body = {
            type: this.levelName,
            name: this.levelName,
            levelname: this.levelName,
            permissions: this.permissionsObj,
            send_command: this.command
        }
        this.accessService.createAccessLevel(body).subscribe(
            (response) => {
                response;
                this.toastr.success(this.levelName + API_SUCCESS.CREATED_SUCCESS, ' ', {
                    timeOut: 3000
                });
                this.permissionsObj = JSON.parse(JSON.stringify(access.permissions));
                form.resetForm();
                $('input:checkbox').prop("checked", false);
                this.getAccessLevels();
                $("#AddNewPolicies .close").click();
                $('.modal-backdrop.fade').removeClass('show');
            },
            (error) => {
                if (error?.message === API_ERROR.USER_LOGOUT) {
                    this.commonService.logout(API_ERROR.USER_LOGOUT);
                } else if (error.error.message === API_ERROR.ACCESS_LEVEL_EXISTS) {
                    this.toastr.error(API_ERROR.ACCESS_LEVEL_WITH_NAME + this.levelName + API_ERROR.ALREADY_EXISTS, ' ', {
                        timeOut: 3000
                    });
                }
            }
        )
    }
  
      /**
       * @description check submenu and update permissions
       */
    checkSubmenu(menu, submenu, type, value) {
        if (submenu == MENUS.TIME_AND_CLOCK) {
            this.permissionsObj.utility.submenu.timeclock[type] = value.target.checked;
            if (type == "view" && value.target.checked == false) {
                $('#timeadd').prop('checked', false);
                $('#timeedit').prop('checked', false);
                $('#timedel').prop('checked', false);
                this.permissionsObj.utility.submenu.timeclock['create'] = false;
                this.permissionsObj.utility.submenu.timeclock['update'] = false;
                this.permissionsObj.utility.submenu.timeclock['delete'] = false;
            }
            if (value.target.checked) {
                $('#timeview').prop('checked', true);
                this.permissionsObj.utility.submenu.timeclock['view'] = value.target.checked;
                this.permissionsObj.utility.submenu.timeclock.action = true;
                this.permissionsObj.utility.action = true;
                this.permissionsObj.utility[type] = value.target.checked;
                this.permissionsObj.utility.view = value.target.checked;
            }
        } else if (submenu == MENUS.TERMINAL) {
            this.permissionsObj.utility.submenu.terminal[type] = value.target.checked;
            if (type == "view" && value.target.checked == false) {
                $('#terminaladd').prop('checked', false);
                $('#terminaladd').prop('checked', false);
                $('#terminaledit').prop('checked', false);
                this.permissionsObj.utility.submenu.terminal['create'] = false;
                this.permissionsObj.utility.submenu.terminal['update'] = false;
                this.permissionsObj.utility.submenu.terminal['delete'] = false;
            }
            if (value.target.checked) {
                $('#terminalview').prop('checked', true);
                this.permissionsObj.utility.submenu.terminal['view'] = value.target.checked;
                this.permissionsObj.utility.submenu.terminal.action = true;
                this.permissionsObj.utility.action = true;
                this.permissionsObj.utility[type] = value.target.checked;
                this.permissionsObj.utility.view = true;
            }
        } else if (submenu == MENUS.WEATHER) {
            this.permissionsObj.utility.submenu.weather[type] = value.target.checked;
            if (type == "view" && value.target.checked == false) {
                $('#weatheradd').prop('checked', false);
                $('#weatheredit').prop('checked', false);
                $('#weatherdel').prop('checked', false);
                this.permissionsObj.utility.submenu.weather['create'] = false;
                this.permissionsObj.utility.submenu.weather['update'] = false;
                this.permissionsObj.utility.submenu.weather['delete'] = false;
            }
            if (value.target.checked) {
                $('#weatherview').prop('checked', true);
                this.permissionsObj.utility.submenu.weather['view'] = value.target.checked;
                this.permissionsObj.utility.submenu.weather.action = true;
                this.permissionsObj.utility.action = true;
                this.permissionsObj.utility[type] = value.target.checked;
                this.permissionsObj.utility.view = true;
            }
        } else if (submenu == MENUS.NODE_MAP) {
            this.permissionsObj.utility.submenu["node-map"][type] = value.target.checked;

            if (type == "view" && value.target.checked == false) {
                $('#nodemapadd').prop('checked', false);
                $('#nodemapedit').prop('checked', false);
                $('#nodemapdel').prop('checked', false);
                this.permissionsObj.utility.submenu["node-map"]['create'] = false;
                this.permissionsObj.utility.submenu["node-map"]['update'] = false;
                this.permissionsObj.utility.submenu["node-map"]['delete'] = false;
            }
            if (value.target.checked) {
                $('#nodemapview').prop('checked', true);
                this.permissionsObj.utility.submenu["node-map"]['view'] = value.target.checked;
                this.permissionsObj.utility.submenu["node-map"].action = true;
                this.permissionsObj.utility.action = true;
                this.permissionsObj.utility[type] = value.target.checked;
                this.permissionsObj.utility.view = value.target.checked;
            }
        }
        else if (submenu == MENUS.DATA_STORAGE) {
            this.permissionsObj.utility.submenu.dataStorage[type] = value.target.checked;
            if (type == "view" && value.target.checked == false) {
                $('#dataStorageadd').prop('checked', false);
                $('#dataStorageedit').prop('checked', false);
                $('#dataStoragedel').prop('checked', false);
                this.permissionsObj.utility.submenu.dataStorage['create'] = false;
                this.permissionsObj.utility.submenu.dataStorage['update'] = false;
                this.permissionsObj.utility.submenu.dataStorage['delete'] = false;
            }
            if (value.target.checked) {
                $('#dataStorageview').prop('checked', true);
                this.permissionsObj.utility.submenu.dataStorage['view'] = value.target.checked;
                this.permissionsObj.utility.submenu.dataStorage.action = true;
                this.permissionsObj.utility.action = true;
                this.permissionsObj.utility[type] = value.target.checked;
                this.permissionsObj.utility.view = value.target.checked;
            }
        }
        else if (submenu == MENUS.CONNECTIVITY) {
            this.permissionsObj.utility.submenu.connectivity[type] = value.target.checked;
            if (type == "view" && value.target.checked == false) {
                $('#connectivityadd').prop('checked', false);
                $('#connectivityedit').prop('checked', false);
                $('#connectivitydel').prop('checked', false);
                this.permissionsObj.utility.submenu.connectivity['create'] = false;
                this.permissionsObj.utility.submenu.connectivity['update'] = false;
                this.permissionsObj.utility.submenu.connectivity['delete'] = false;
            }
            if (value.target.checked) {
                $('#connectivityview').prop('checked', true);
                this.permissionsObj.utility.submenu.connectivity.view = value.target.checked;
                this.permissionsObj.utility.submenu.connectivity.action = true;
                this.permissionsObj.utility.action = true;
                this.permissionsObj.utility[type] = value.target.checked;
                this.permissionsObj.utility.view = value.target.checked;
            }
        } else if (submenu == MENUS.LEDGER) {
            this.permissionsObj.utility.submenu.ledger[type] = value.target.checked;
            if (value.target.checked == true) {
                this.permissionsObj.utility.submenu.ledger.action = true;
                this.permissionsObj.utility.action = true;
                this.permissionsObj.utility[type] = value.target.checked;
                this.permissionsObj.utility.view = value.target.checked;

            }
        }
        else if (submenu == MENUS.USER_CHAIN) {
            this.permissionsObj.utility.submenu.ledger.ledgerSubMenu.user[type] = value.target.checked;
            if (type == "view" && value.target.checked == false) {
                $('#userChainadd').prop('checked', false);
                $('#userChainedit').prop('checked', false);
                $('#userChaindel').prop('checked', false);
                this.permissionsObj.utility.submenu.ledger.ledgerSubMenu.user['create'] = false;
                this.permissionsObj.utility.submenu.ledger.ledgerSubMenu.user['update'] = false;
                this.permissionsObj.utility.submenu.ledger.ledgerSubMenu.user['delete'] = false;
            }
            if (value.target.checked) {
                $('#userChainview').prop('checked', true);
                this.permissionsObj.utility.submenu.ledger.ledgerSubMenu.user.view = value.target.checked;
                this.permissionsObj.utility.submenu.ledger.ledgerSubMenu.user.action = true;
                this.permissionsObj.utility.submenu.ledger.action = true;
                this.permissionsObj.utility.submenu.ledger[type] = value.target.checked;
                this.permissionsObj.utility.action = true;
                this.permissionsObj.utility[type] = value.target.checked;
                this.permissionsObj.utility.view = value.target.checked;
            }
        }
        else if (submenu == MENUS.EDGE_CHAIN) {
            this.permissionsObj.utility.submenu.ledger.ledgerSubMenu.edge[type] = value.target.checked;
            if (type == "view" && value.target.checked == false) {
                $('#edgeChainadd').prop('checked', false);
                $('#edgeChainedit').prop('checked', false);
                $('#edgeChaindel').prop('checked', false);
                this.permissionsObj.utility.submenu.ledger.ledgerSubMenu.edge['create'] = false;
                this.permissionsObj.utility.submenu.ledger.ledgerSubMenu.edge['update'] = false;
                this.permissionsObj.utility.submenu.ledger.ledgerSubMenu.edge['delete'] = false;
            }
            if (value.target.checked) {
                $('#edgeChainview').prop('checked', true);
                this.permissionsObj.utility.submenu.ledger.ledgerSubMenu.edge.view = value.target.checked;
                this.permissionsObj.utility.submenu.ledger.ledgerSubMenu.edge.action = true;
                this.permissionsObj.utility.submenu.ledger.action = true;
                this.permissionsObj.utility.submenu.ledger[type] = value.target.checked;
                this.permissionsObj.utility.action = true;
                this.permissionsObj.utility[type] = value.target.checked;
                this.permissionsObj.utility.view = value.target.checked;
            }
        }
        else if (submenu == MENUS.IDENTITY_CHAIN) {
            this.permissionsObj.utility.submenu.ledger.ledgerSubMenu.device[type] = value.target.checked;
            if (type == "view" && value.target.checked == false) {
                $('#identityChainadd').prop('checked', false);
                $('#identityChainedit').prop('checked', false);
                $('#identityChaindel').prop('checked', false);
                this.permissionsObj.utility.submenu.ledger.ledgerSubMenu.device['create'] = false;
                this.permissionsObj.utility.submenu.ledger.ledgerSubMenu.device['update'] = false;
                this.permissionsObj.utility.submenu.ledger.ledgerSubMenu.device['delete'] = false;
            }
            if (value.target.checked) {
                $('#identityChainview').prop('checked', true);
                this.permissionsObj.utility.submenu.ledger.ledgerSubMenu.device.view = value.target.checked;
                this.permissionsObj.utility.submenu.ledger.ledgerSubMenu.device.action = true;
                this.permissionsObj.utility.submenu.ledger.action = true;
                this.permissionsObj.utility.submenu.ledger[type] = value.target.checked;
                this.permissionsObj.utility.action = true;
                this.permissionsObj.utility[type] = value.target.checked;
                this.permissionsObj.utility.view = value.target.checked;
            }
        }
        else if (submenu == MENUS.COMMUNICATION_CHAIN) {
            this.permissionsObj.utility.submenu.ledger.ledgerSubMenu.event[type] = value.target.checked;
            if (type == "view" && value.target.checked == false) {
                $('#commChainadd').prop('checked', false);
                $('#commChainedit').prop('checked', false);
                $('#commChaindel').prop('checked', false);
                this.permissionsObj.utility.submenu.ledger.ledgerSubMenu.event['create'] = false;
                this.permissionsObj.utility.submenu.ledger.ledgerSubMenu.event['update'] = false;
                this.permissionsObj.utility.submenu.ledger.ledgerSubMenu.event['delete'] = false;
            }
            if (value.target.checked) {
                $('#commChainview').prop('checked', true);
                this.permissionsObj.utility.submenu.ledger.ledgerSubMenu.event.view = value.target.checked;
                this.permissionsObj.utility.submenu.ledger.ledgerSubMenu.event.action = true;
                this.permissionsObj.utility.submenu.ledger.action = true;
                this.permissionsObj.utility.submenu.ledger[type] = value.target.checked;
                this.permissionsObj.utility.action = true;
                this.permissionsObj.utility[type] = value.target.checked;
                this.permissionsObj.utility.view = value.target.checked;
            }
        }
        else if (submenu == MENUS.SMART_CONTRACT) {
            this.permissionsObj.utility.submenu.ledger.ledgerSubMenu["smart-contract"][type] = value.target.checked;
            if (type == "view" && value.target.checked == false) {
                $('#smartConChainadd').prop('checked', false);
                $('#smartConChainedit').prop('checked', false);
                $('#smartConChaindel').prop('checked', false);
                this.permissionsObj.utility.submenu.ledger.ledgerSubMenu["smart-contract"]['create'] = false;
                this.permissionsObj.utility.submenu.ledger.ledgerSubMenu["smart-contract"]['update'] = false;
                this.permissionsObj.utility.submenu.ledger.ledgerSubMenu["smart-contract"]['delete'] = false;
            }
            if (value.target.checked) {
                $('#smartConChainview').prop('checked', true);
                this.permissionsObj.utility.submenu.ledger.ledgerSubMenu["smart-contract"].view = value.target.checked;
                this.permissionsObj.utility.submenu.ledger.ledgerSubMenu["smart-contract"].action = true;
                this.permissionsObj.utility.submenu.ledger.action = true;
                this.permissionsObj.utility.submenu.ledger[type] = value.target.checked;
                this.permissionsObj.utility.action = true;
                this.permissionsObj.utility[type] = value.target.checked;
                this.permissionsObj.utility.view = value.target.checked;
            }
        }
        else if (submenu == MENUS.USER_ACCESS_POLICIES) {
            this.permissionsObj["access-control"].submenu["user-access-policies"][type] = value.target.checked;
            if (type == "view" && value.target.checked == false) {
                $('#policyadd').prop('checked', false);
                $('#policyedit').prop('checked', false);
                $('#policydel').prop('checked', false);
                this.permissionsObj["access-control"].submenu["user-access-policies"]['create'] = false;
                this.permissionsObj["access-control"].submenu["user-access-policies"]['update'] = false;
                this.permissionsObj["access-control"].submenu["user-access-policies"]['delete'] = false;
            }
            if (value.target.checked) {
                $('#policyview').prop('checked', true);
                this.permissionsObj["access-control"].submenu["user-access-policies"]['view'] = value.target.checked;
                this.permissionsObj["access-control"].submenu["user-access-policies"].action = true;
                this.permissionsObj["access-control"].action = true;
                this.permissionsObj["access-control"][type] = value.target.checked;
                this.permissionsObj["access-control"].view = value.target.checked;
            }
        } else if (submenu == MENUS.USER_MANAGEMENT) {
            this.permissionsObj["access-control"].submenu["user-management"][type] = value.target.checked;
            if (type == "view" && value.target.checked == false) {
                $('#umadd').prop('checked', false);
                $('#umedit').prop('checked', false);
                $('#umdel').prop('checked', false);
                this.permissionsObj["access-control"].submenu["user-management"]['create'] = false;
                this.permissionsObj["access-control"].submenu["user-management"]['update'] = false;
                this.permissionsObj["access-control"].submenu["user-management"]['delete'] = false;
            }
            if (value.target.checked) {
                $('#umview').prop('checked', true);
                this.permissionsObj["access-control"].submenu["user-management"]['view'] = value.target.checked;
                this.permissionsObj["access-control"].submenu["user-management"].action = true;
                this.permissionsObj["access-control"].action = true;
                this.permissionsObj["access-control"][type] = value.target.checked;
                this.permissionsObj["access-control"].view = value.target.checked;
            }
        } else if (submenu == MENUS.LOGS) {
            this.permissionsObj.utility.submenu.logs[type] = value.target.checked;
            if (value.target.checked == true) {
                this.permissionsObj.utility.submenu.logs.action = true;
                this.permissionsObj.utility.action = true;
                this.permissionsObj.utility[type] = value.target.checked;
                this.permissionsObj.utility.view = value.target.checked;
            }
        } else if (submenu == MENUS.SYSTEM_LOG) {
            this.permissionsObj.utility.submenu.logs.logSubMenu.systemLog[type] = value.target.checked;
            if (type == "view" && value.target.checked == false) {
                $('#syslogsadd').prop('checked', false);
                $('#syslogsedit').prop('checked', false);
                $('#syslogsdel').prop('checked', false);
                this.permissionsObj.utility.submenu.logs.logSubMenu.systemLog['create'] = false;
                this.permissionsObj.utility.submenu.logs.logSubMenu.systemLog['update'] = false;
                this.permissionsObj.utility.submenu.logs.logSubMenu.systemLog['delete'] = false;
            }
            if (value.target.checked) {
                $('#syslogsview').prop('checked', true);
                this.permissionsObj.utility.submenu.logs.logSubMenu.systemLog['view'] = value.target.checked;
                this.permissionsObj.utility.submenu.logs.logSubMenu.systemLog.action = true;
                this.permissionsObj.utility.submenu.logs.action = true;
                this.permissionsObj.utility.submenu.logs[type] = value.target.checked;
                this.permissionsObj.utility.submenu.logs.view = value.target.checked;
                this.permissionsObj.utility.action = true;
                this.permissionsObj.utility[type] = value.target.checked;
                this.permissionsObj.utility['view'] = value.target.checked;
            }
        } else if (submenu == MENUS.EVENT_LOG) {
            this.permissionsObj.utility.submenu.logs.logSubMenu.eventLog[type] = value.target.checked;
            if (type == "view" && value.target.checked == false) {
                $('#eventlogsadd').prop('checked', false);
                $('#eventlogsedit').prop('checked', false);
                $('#eventlogsdel').prop('checked', false);
                this.permissionsObj.utility.submenu.logs.logSubMenu.eventLog['create'] = false;
                this.permissionsObj.utility.submenu.logs.logSubMenu.eventLog['update'] = false;
                this.permissionsObj.utility.submenu.logs.logSubMenu.eventLog['delete'] = false;
            }
            if (value.target.checked) {
                $('#eventlogsview').prop('checked', true);
                this.permissionsObj.utility.submenu.logs.logSubMenu.eventLog['view'] = value.target.checked;
                this.permissionsObj.utility.submenu.logs.logSubMenu.eventLog.action = true;
                this.permissionsObj.utility.submenu.logs.action = true;
                this.permissionsObj.utility.submenu.logs[type] = value.target.checked;
                this.permissionsObj.utility.submenu.logs.view = value.target.checked;
                this.permissionsObj.utility.action = true;
                this.permissionsObj.utility[type] = value.target.checked;
                this.permissionsObj.utility['view'] = value.target.checked;
            }
        } else if (submenu == MENUS.UPDATE) {
            this.permissionsObj.settings.submenu.update[type] = value.target.checked;
            if (type == "view" && value.target.checked == false) {
                $('#updateadd').prop('checked', false);
                $('#updateedit').prop('checked', false);
                $('#updatedel').prop('checked', false);
                this.permissionsObj.settings.submenu.update['create'] = false;
                this.permissionsObj.settings.submenu.update['update'] = false;
                this.permissionsObj.settings.submenu.update['delete'] = false;
            }
            if (value.target.checked) {
                $('#updateview').prop('checked', true);
                this.permissionsObj.settings.submenu.update['view'] = value.target.checked;
                this.permissionsObj.settings.submenu.update.action = true;
                this.permissionsObj.settings.action = true;
                this.permissionsObj.settings[type] = value.target.checked;
                this.permissionsObj.settings.view = value.target.checked;
            }
        } else if (submenu == MENUS.PROVISIONING_SETTINGS) {
            this.permissionsObj.settings.submenu.provisioningSettings[type] = value.target.checked;
            if (type == "view" && value.target.checked == false) {
                $('#provisioningadd').prop('checked', false);
                $('#provisioningedit').prop('checked', false);
                $('#provisioningdel').prop('checked', false);
                this.permissionsObj.settings.submenu.provisioningSettings['create'] = false;
                this.permissionsObj.settings.submenu.provisioningSettings['update'] = false;
                this.permissionsObj.settings.submenu.provisioningSettings['delete'] = false;
            }
            if (value.target.checked) {
                $('#provisioningview').prop('checked', true);
                this.permissionsObj.settings.submenu.provisioningSettings['view'] = value.target.checked;
                this.permissionsObj.settings.submenu.provisioningSettings.action = true;
                this.permissionsObj.settings.action = true;
                this.permissionsObj.settings[type] = value.target.checked;
                this.permissionsObj.settings.view = value.target.checked;
            }
        } else if (submenu == MENUS.RESET_SETTINGS) {
            this.permissionsObj.settings.submenu.resetSettings[type] = value.target.checked;
            if (type == "view" && value.target.checked == false) {
                $('#resetSettingadd').prop('checked', false);
                $('#resetSettingedit').prop('checked', false);
                $('#resetSettingdel').prop('checked', false);
                this.permissionsObj.settings.submenu.resetSettings['create'] = false;
                this.permissionsObj.settings.submenu.resetSettings['update'] = false;
                this.permissionsObj.settings.submenu.resetSettings['delete'] = false;
            }
            if (value.target.checked) {
                $('#resetSettingview').prop('checked', true);
                this.permissionsObj.settings.submenu.resetSettings['view'] = value.target.checked;
                this.permissionsObj.settings.submenu.resetSettings.action = true;
                this.permissionsObj.settings.action = true;
                this.permissionsObj.settings[type] = value.target.checked;
                this.permissionsObj.settings.view = value.target.checked;
            }
        } else if (menu == MENUS.REPORTS) {
            this.permissionsObj.reports[type] = value.target.checked;
            if (type == "view" && value.target.checked == false) {
                $('#reportadd').prop('checked', false);
                $('#reportedit').prop('checked', false);
                $('#reportdel').prop('checked', false);
                this.permissionsObj.reports['create'] = false;
                this.permissionsObj.reports['update'] = false;
                this.permissionsObj.reports['delete'] = false;
            }

            if (value.target.checked) {
                $('#reportview').prop('checked', true);
                this.permissionsObj.reports['view'] = value.target.checked;
                this.permissionsObj.reports.action = true;
                this.permissionsObj.reports[type] = value.target.checked;
                this.permissionsObj.reports.view = value.target.checked;
            }
        } else if (menu == MENUS.SHIPMENTS) {
            this.permissionsObj.shipments[type] = value.target.checked;
            if (type == "view" && value.target.checked == false) {
                $('#shipmentsadd').prop('checked', false);
                $('#shipmentsedit').prop('checked', false);
                $('#shipmentsdel').prop('checked', false);
                this.permissionsObj.shipments['create'] = false;
                this.permissionsObj.shipments['update'] = false;
                this.permissionsObj.shipments['delete'] = false;
            }

            if (value.target.checked) {
                $('#shipmentsview').prop('checked', true);
                this.permissionsObj.shipments['view'] = value.target.checked;
                this.permissionsObj.shipments.action = true;
                this.permissionsObj.shipments[type] = value.target.checked;
                this.permissionsObj.shipments.view = value.target.checked;
            }
        } 
        else if (menu == MENUS.END_POINT_MANAGEMENT) {
            this.permissionsObj['End Point Management'][type] = value.target.checked;
            if (type == "view" && value.target.checked == false) {
                $('#endPointadd').prop('checked', false);
                $('#endPointedit').prop('checked', false);
                $('#endPointdel').prop('checked', false);
                this.permissionsObj['End Point Management']['create'] = false;
                this.permissionsObj['End Point Management']['update'] = false;
                this.permissionsObj['End Point Management']['delete'] = false;
            }

            if (value.target.checked) {
                $('#endPointview').prop('checked', true);
                this.permissionsObj['End Point Management']['view'] = value.target.checked;
                this.permissionsObj['End Point Management'].action = true;
                this.permissionsObj['End Point Management'][type] = value.target.checked;
                this.permissionsObj['End Point Management'].view = value.target.checked;
            }
        } else if (menu == MENUS.EDGE_MANAGEMENT) {
            this.permissionsObj.edgeManagement[type] = value.target.checked;
            if (type == "view" && value.target.checked == false) {
                $('#edgeadd').prop('checked', false);
                $('#edgeedit').prop('checked', false);
                $('#edgedel').prop('checked', false);
                this.permissionsObj.edgeManagement['create'] = false;
                this.permissionsObj.edgeManagement['update'] = false;
                this.permissionsObj.edgeManagement['delete'] = false;
            }
            if (value.target.checked) {
                $('#edgeview').prop('checked', true);
                this.permissionsObj.edgeManagement['view'] = value.target.checked;
                this.permissionsObj.edgeManagement.action = true;
                this.permissionsObj.edgeManagement[type] = value.target.checked;
                this.permissionsObj.edgeManagement.view = value.target.checked;
            }
        } else if (menu == MENUS.PROVISIONING_QUEUE) {
            this.permissionsObj['Provisioning Queue'][type] = value.target.checked;
            if (type == "view" && value.target.checked == false) {
                $('#provisionadd').prop('checked', false);
                $('#provisionedit').prop('checked', false);
                $('#provisiondel').prop('checked', false);
                this.permissionsObj['Provisioning Queue']['create'] = false;
                this.permissionsObj['Provisioning Queue']['update'] = false;
                this.permissionsObj['Provisioning Queue']['delete'] = false;
            }
            if (value.target.checked) {
                $('#provisionview').prop('checked', true);
                this.permissionsObj['Provisioning Queue']['view'] = value.target.checked;
                this.permissionsObj['Provisioning Queue'].action = true;
                this.permissionsObj['Provisioning Queue'][type] = value.target.checked;
                this.permissionsObj['Provisioning Queue'].view = value.target.checked;
            }
        } else if (submenu == MENUS.ALERT_NOTIFICATIONS) {
            this.permissionsObj.utility.submenu["alertsNotifications"][type] = value.target.checked;
            if (type == "view" && value.target.checked == false) {
                $('#alertadd').prop('checked', false);
                $('#alertedit').prop('checked', false);
                $('#alertdel').prop('checked', false);
                this.permissionsObj.utility.submenu["alertsNotifications"]['create'] = false;
                this.permissionsObj.utility.submenu["alertsNotifications"]['update'] = false;
                this.permissionsObj.utility.submenu["alertsNotifications"]['delete'] = false;
            }
            if (value.target.checked) {
                $('#alertview').prop('checked', true);
                this.permissionsObj.utility.submenu["alertsNotifications"]['view'] = value.target.checked;
                this.permissionsObj.utility.submenu["alertsNotifications"].action = true;
                this.permissionsObj.utility.action = true;
                this.permissionsObj.utility[type] = value.target.checked;
                this.permissionsObj.utility.view = value.target.checked;
            }
        }
    }
    
  
      /**
       * @description remove  selected access level
       */
    removeSelected() {
      for (const k of this.deleteArr) {
          this.deleteAccessLevels(k);
      }
      if(this.levelData.levelname != LEVELS.SUPERADMIN)
      this.toastr.success(this.levelData.levelname + API_SUCCESS.DELETED_SUCCESS, ' ', {
          timeOut: 3000
      });
      $('input:checkbox').prop("checked", false);
      this.deleteArr = [];
    }
  
    openDeleteModal(data) {
      this.levelData = data;
      this.delLevelName = data.levelname;
  }
    
      /**
       * 
       * @param data 
       * @description delete perticular access level
       */
    deleteAccessLevels(data?: any) {
      if (data) {
          this.levelData = data;
      }
      this.accessService.deleteLevel(this.levelData).subscribe(
          (response) => {
              response;
              if (!data)
                  this.toastr.success(this.levelData.levelname + API_SUCCESS.DELETED_SUCCESS, ' ', {
                      timeOut: 3000
                  });
                  this.getAccessLevels();
          },
          (error) => {
            if (error?.message === API_ERROR.USER_LOGOUT) {
                this.commonService.logout(API_ERROR.USER_LOGOUT);
            } else if (error.error.message === API_ERROR.CANNOT_DELETE_SUPERADMIN) {
              this.toastr.warning(API_ERROR.NOT_AUTHORISED, ' ', {
                timeOut: 3000
            });
              }
          }
      )
  }
  
}
