<form class="form-card row px-2 w-100" style="margin:0px; padding: 0px;" [formGroup]="forgotPwdForm">
  <ng-container *ngIf="!submitted">
    <div class="w-100 p-4">

      <div class="mt-4 pt-2 mb-5 text-center">
        <img src="../../../../../assets/images/IMPACT_by_honeywell-Logo.png" class="pr-1 img-fluid pb-1" />
      </div>
      <div class="col-md-12 mt-4 mb-3 mt-2">
        <p class="lead m-0 p-0">Password Recovery</p>
        <p class="mb-4">
          Enter your email and we'll send you a link to get back into your
          account.
        </p>
      </div>
  
      <div class="form-group col-md-12 mb-4 mt-1">
        <span class="mb-0 font-size9"><b>Email</b></span>
        <label class="has-float-label">
          <input
            class="form-control"
            formControlName="email"
            type="text"
            placeholder="Email"
          />
        </label>
      </div>
  
      <!-- <div class="form-group col-md-12">
                    <a href="#" routerLink="/login" class="small pl-1">Or Back to <span class="text-primary">Login</span></a>
                    <button type="submit" (keyup.enter)="sendResetLinkOnMail()">Send</button>
                    <a href="javascript:void(0)"  [class.disabled]="!forgotPwdForm.valid || loading"   (click)="sendResetLinkOnMail()" class="btn bg-gradient-primary px-4 text-uppercase float-right">
                      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                      Send Reset Link</a> -->
      <!-- </div> -->
      <div class="col-md-12 form-group mt-3 mb-3">
        <!-- <a
          href="#"
          routerLink="/login"
          class="small pl-1 pt-2 float-left text-primary"
          >Or Back to Login</a
        > -->
        <button
          type="submit"
          (click)="sendResetLinkOnMail()"
          class="btn bg-gradient-primary px-4 text-uppercase w-100 p-2"
          [disabled]="loading || !forgotPwdForm.valid"
        >
          <span
            *ngIf="loading"
            class="spinner-border spinner-border-sm mr-1"
          ></span>
          SEND
        </button>
      </div>
    </div>
  </ng-container>

  <div class="form-group col-md-12 mb-3" [ngStyle]="{ display: display }">
    <div class="row mb-4 mt-4">
      <div class="col-md-12 text-center">
        <img class="img-fluid" src="assets/images/icons/email.png" alt="" />
      </div>
      <div class="col-md-12 pl-3 pt-3 text-center">
        <p class="">We have sent a recovery link to you at</p>
        <p class="font-weight-bold m-0 mb-2">{{ f.email.value }}</p>
      </div>
    </div>

    <div class="form-group col-md-12 px-2 text-center">
      <a
        href="javascript:void(0)"
        routerLink="/login"
        class="fsize13 text-primary"
      >
        Back to Login</a
      >
      <a
        hidden
        href="javascript:void(0)"
        (click)="submitted = false; display = 'none'; forgotPwdForm.reset()"
        class="float-right fsize13 text-primary"
      >
        Resend recovery link</a
      >
    </div>
  </div>
</form>
