import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import * as moment from 'moment-timezone';
import { Moment } from 'moment';
import 'moment-range';
import { WeatherService } from './weather.service';
import { countries } from '../../../dashboard/components/edit-profile/country';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-weather',
  templateUrl: './weather.component.html',
  styleUrls: ['./weather.component.css']
})
export class WeatherComponent implements OnInit, OnDestroy {

  screenDayBgImg = 'assets/images/weatherDay_bg.jpg';
  screenNightBgImg = 'assets/images/weather_bg.jpg';
  topCardWeaImg = 'assets/images/icons/weather-icons/day.svg';
  weatherData: any;
  location: any;
  currentTemp: any;
  feelsLike: any;
  windSpeed: any;
  humidity: any;
  pressure: any;
  clouds: any;
  duePoint: any;
  weather: any;
  time: string;
  user: any;
  country: any;
  selectedCountries: any = countries;
  countryName: any;

  private _sub: Subscription;

  constructor(private weatherService: WeatherService, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    const userData = sessionStorage.getItem('data');
    if (userData !== null) {
      this.user = JSON.parse(window.atob(userData));
      this.getCountryCode();
      this.getWeather();
    }
  }

  /**
   * Description: This method will return country code as per the country entered by user.
   * @description This method will return country code as per the country entered by user.
   */
  getCountryCode() {
    for (let i = 0; i < this.selectedCountries.length; i++) {
      if (this.user.country && this.user.country == this.selectedCountries[i].name) {
        this.country = this.selectedCountries[i].code;
      }
    }
  }
  /**
   * Description: This method will call weather API and return weathe information according to Zip code & country
   * @description This method will call weather API and return weathe information according to Zip code & country
  **/
  getWeather() {
    this.spinner.show();
    if (this.user.zipCode && this.country) {
      this._sub = this.weatherService.getWeather(this.user.zipCode, this.country).subscribe(
        ((res) => {
          this.spinner.hide();
          this.weatherData = res;
          this.location = this.weatherData.name;
          this.currentTemp = this.weatherData.main.temp;
          this.feelsLike = this.weatherData.main.feels_like;
          this.windSpeed = this.weatherData.wind.speed;
          this.humidity = this.weatherData.main.humidity;
          this.pressure = this.weatherData.main.pressure;
          this.clouds = this.weatherData.clouds.all;
          this.duePoint = this.weatherData.main.temp_min;
          this.weather = this.weatherData.weather.main;
          this.countryName = this.user.country;
          this.time = new Date().toLocaleTimeString();
        }),
        (error => {
          this.spinner.hide();
          console.log('error', error);
        })
      );
    } else {
      this.spinner.hide();
    }

  }

  ngOnDestroy() {
    if (this._sub && !this._sub.closed) {
      this._sub.unsubscribe();
    }
  }

}
