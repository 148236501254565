<!-- Main content -->
<div class="row shipments-container" id="shipments">
  <div class="col-12">
    <section class="content mt-4 mx-3">
      <div class="col-12 mb-3"></div>
      <!-- Default box -->
      <section class="content">
        <!-- Default box -->
        <div class="card no-border">
          <div class="card-body p-0">
            <div class="row">
              <div class="col-md-12 bg-white p-3 tab-content cus-rounded-left cus-rounded-right">
                <div class="tab-pane container-fluid active mb-3" id="a1">
                  <h6 class="mb-4 font-weight-bold pageTitle text-uppercase">
                    Shipments
                  </h6>
                  <div class="row py-1 mx-2">
                    <div class="col-md-12">
                      <div class="row">
                        <div class="form-group col-12 text-right p-0" id="add_new">
                          <button
                            *ngIf="userInfo?.accesslevel.permissions['shipments'] && userInfo?.accesslevel.permissions['shipments'].create"
                            type="button" class="btn primary-btn one text-uppercase" title="ADD NEW SHIPMENT"
                            (click)="addNewShipment()">
                            <div class="insider"></div>
                            <i class="fas fa-plus pr-2"></i> ADD NEW SHIPMENT
                          </button>
                          <button type="button" class="ml-3 btn primary-btn one text-uppercase" title="Refresh Data"
                            (click)="nextShipmentPage(undefined, undefined)">
                            <i class="fas fa-sync"></i>
                          </button>
                          <div class="searchDiv ml-3">
                            <input pInputText type="text" id="globalSearch" placeholder="Search by Name or Type"
                              class="" [ngClass]="{ open: isopenSearch }" [formControl]="search" />
                            <!-- <input
                              pInputText
                              type="text"
                              id="globalSearch"
                              (input)="
                                dt1.filterGlobal(
                                  $event.target.value,
                                  'contains'
                                )
                              "
                              placeholder="Search by Name or Type"
                              class=""
                              [ngClass]="{ open: isopenSearch }"
                            /> -->
                            <button type="reset" title="Search" class="searchBtn btn primary-btn one text-uppercase"
                              (click)="isopenSearch = !isopenSearch">
                              <i class="fas" [ngClass]="{
                                  'fa-search-minus': isopenSearch,
                                  'fa-search-plus': !isopenSearch
                                }"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- DATA TABLE START HERE -->
                  <!-- [autoLayout]="true" -->
                  <p-table *ngIf="loadShipments" [value]="shipments$ | async" [paginator]="loadShipments"
                    [rows]="ROWS_PER_PAGE" [lazy]="loadShipments" (onLazyLoad)="nextShipmentPage($event, meta)"
                    [totalRecords]="meta?.totalItems" columnResizeMode="expand" [resizableColumns]="loadShipments"
                    styleClass="p-datatable-gridlines" [loading]="isLoading" [resizableColumns]="true"
                    columnResizeMode="expand">
                    <!-- <p-table
                    #dt1
                    [value]="shipments$ | async"
                    [paginator]="true"
                    [globalFilterFields]="['shipmentName', 'shipmentType']"
                    [rows]="ROWS_PER_PAGE"
                    [lazy]="true"
                    (onLazyLoad)="nextShipmentPage($event, meta)"
                    [totalRecords]="meta?.totalItems"
                    columnResizeMode="expand"
                    [resizableColumns]="true"
                    styleClass="p-datatable-gridlines"
                    [loading]="isLoading"
                  > -->
                    <!-- [customSort]="true" -->
                    <!-- (sortFunction)="customSort($event)" -->
                    <ng-template pTemplate="header">
                      <tr class="thead">
                        <th pResizableColumn pSortableColumn="shipmentName">
                          Shipment Name
                          <p-sortIcon field="shipmentName"></p-sortIcon>
                        </th>
                        <th pResizableColumn pSortableColumn="shipmentType">
                          Type <p-sortIcon field="shipmentType"></p-sortIcon>
                        </th>
                        <!-- <th pResizableColumn pSortableColumn="shipmentDesc">
                          Description
                          <p-sortIcon field="shipmentDesc"></p-sortIcon>
                        </th> -->
                        <th pResizableColumn class="text-truncate align-middle">
                          {{ constantVariables.ENDPOINT.LABEL2 }} UUID
                        </th>
                        <th class="text-truncate align-middle">Battery</th>
                        <!-- <th pResizableColumn pSortableColumn="pickupLocation">
                          Start Location<p-sortIcon field="pickupLocation"></p-sortIcon>
                        </th>
                        <th pResizableColumn pSortableColumn="destinationLocation">
                          End Location<p-sortIcon field="destinationLocation"></p-sortIcon>
                        </th>
                        <th pResizableColumn>Geofence Radius</th>
                        <th pResizableColumn pSortableColumn="pickupDate">
                          Start Date<p-sortIcon field="pickupDate"></p-sortIcon>
                        </th>
                        <th pResizableColumn pSortableColumn="deliveryDate">
                          End Date<p-sortIcon field="deliveryDate"></p-sortIcon>
                        </th> -->
                        <th pResizableColumn pSortableColumn="status">
                          Status <p-sortIcon field="status"></p-sortIcon>
                        </th>
                        <th pResizableColumn>Last Connected</th>
                        <th pResizableColumn *ngIf="userInfo?.accesslevel.permissions['shipments']
                            && (userInfo?.accesslevel.permissions['shipments'].update
                            || userInfo?.accesslevel.permissions['shipments'].delete)">Action</th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-shipment>
                      <tr class="tbody">
                        <td [title]="shipment.shipmentName || ''" [routerLink]="['../reportShipment', shipment.id]"
                          class="text-truncate align-middle cursor-pointer">
                          {{ shipment.shipmentName }}
                        
                        </td>
                        <td [routerLink]="['../reportShipment', shipment.id]"
                          class="text-truncate align-middle cursor-pointer">
                          {{ shipment.shipmentType }}
                        </td>
                        <!-- <td [routerLink]="['../reportShipment', shipment.id]"
                          class="text-truncate align-middle cursor-pointer">
                          {{ shipment.shipmentDesc }}
                        </td> -->
                        <td [routerLink]="['../reportShipment', shipment.id]"
                          class="text-truncate align-middle cursor-pointer">
                          {{ shipment.trackers && shipment.trackers[0] ? shipment.trackers[0].completedAt ||
                          !shipment.trackers[0].isRemoveTracker ? shipment.trackers[0].data.deviceUUID : 'N/A' : 'N/A'
                          }}
                        </td>
                        <td>
                          {{shipment.trackers && shipment.trackers[0] && shipment?.trackers[0]?.latestEvent
                             ? getBatteryValue(shipment?.trackers[0]?.latestEvent?.payload) + '%' : 'N/A'}}

                          <i *ngIf="shipment.trackers && shipment.trackers[0] && shipment?.trackers[0]?.latestEvent"
                            class="fas widget-icon"
                            [title]="getBatteryValue(shipment?.trackers[0]?.latestEvent?.payload) + '%'"
                            [ngClass]="getBatteryValue(shipment?.trackers[0]?.latestEvent?.payload, true)"
                          ></i>
                         </td>
                        <!-- <td [routerLink]="['../reportShipment', shipment.id]"
                          class="text-truncate align-middle cursor-pointer">
                          {{ shipment.pickupLocation }}
                        </td>
                        <td [routerLink]="['../reportShipment', shipment.id]"
                          class="text-truncate align-middle cursor-pointer">
                          {{
                          shipment.shipmentType !== "Fixed" &&
                          shipment.shipmentType !== "fixed"
                          ? shipment.destinationLocation
                          : ""
                          }}
                        </td>
                        <td [routerLink]="['../reportShipment', shipment.id]"
                          class="text-truncate align-middle cursor-pointer">
                          {{ shipment.geofence_radius ? shipment.geofence_radius + 'm' : 'N/A' }}
                        </td>
                        <td [routerLink]="['../reportShipment', shipment.id]"
                          class="text-truncate align-middle cursor-pointer">
                          {{ shipment.pickupDate | date: "dd MMM y, HH:mm:ss" }}
                        </td>
                        <td [routerLink]="['../reportShipment', shipment.id]"
                          class="text-truncate align-middle cursor-pointer">
                          {{
                          shipment.shipmentType !== "Fixed" &&
                          shipment.shipmentType !== "fixed"
                          ? (shipment.deliveryDate
                          | date: "dd MMM y, HH:mm:ss")
                          : ""
                          }}
                        </td> -->
                        <td class="align-middle">
                          {{ shipment.status }}
                          <button class="btn editable-table-btn btn-xs icn-btn" (click)="statusMenu.toggle($event)"
                            *ngIf="
                              (shipment.status !== STATUS.COMPLETED &&
                              shipment.status !== STATUS.CANCELLED) && userInfo?.accesslevel.permissions['shipments']
                            && userInfo?.accesslevel.permissions['shipments'].update
                            ">
                            <i class="fas fa-pencil-alt text-primary"></i>
                          </button>
                          <p-menu appendTo="body" #statusMenu [popup]="true" [model]="status"
                            (onShow)="changeShipmentStatus(shipment)"></p-menu>
                        </td>
                        <td [routerLink]="['../reportShipment', shipment.id]"
                          class="text-truncate align-middle cursor-pointer">
                          {{ shipment.trackers && shipment.trackers.length && shipment.trackers[0].latestEvent &&
                          shipment.trackers[0].latestEvent.timeStamp ? (shipment.trackers[0].latestEvent.timeStamp |
                          notificationTimesStamp) + ' ago' : 'N/A' }}
                        </td>
                        <td class="align-middle" (click)="$event.stopPropagation()" *ngIf="userInfo?.accesslevel.permissions['shipments']
                            && (userInfo?.accesslevel.permissions['shipments'].update
                            || userInfo?.accesslevel.permissions['shipments'].delete)">
                          <ng-container *ngIf="
                              shipment.status !== STATUS.COMPLETED &&
                              shipment.status !== STATUS.CANCELLED
                            ">
                            <button class="btn editable-table-btn btn-sm icn-btn mr-1 editModal-toggle"
                              title="More Information"
                              *ngIf="userInfo?.accesslevel.permissions['shipments'] && userInfo?.accesslevel.permissions['shipments'].update"
                              (click)="getShipmentById(shipment.id)">
                              <i class="fas fa-edit text-primary"></i>
                            </button>
                            
                            
                          
                          </ng-container>
                            <button class="btn editable-table-btn btn-sm icn-btn" title="Delete"
                              *ngIf="userInfo?.accesslevel.permissions['shipments'] && userInfo?.accesslevel.permissions['shipments'].delete"
                              (click)="opendeleteModal(shipment)">
                              <i class="fas fa-trash-alt text-danger"></i>
                            </button>
                            <button class="mr-2 btn editable-table-btn btn-sm icn-btn" title="Shipment History"
                              (click)="generatePDF(shipment.id, shipment.shipmentName)">
                              <i class="fa fa-file-pdf text-primary"></i>
                            </button>
   
                        </td>
                        
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                      <tr>
                        <td colspan="6" class="no-data-found text-muted text-center">No results found.</td>
                      </tr>
                    </ng-template>
                  </p-table>
                  <!-- DATA TABLE END HERE -->
                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
          <!-- /.card-body -->
        </div>

        <!-- Edit Modal: Sidepanel -->
        <div class="editModal-container" [class.open]="showPanel">
          <div class="row bg-modal-header px-3 py-2 mx-0">
            <h6 class="float-left">
              {{ shipmentId ? "Update Shipment" : "Add new Shipment" }}
            </h6>
            <button type="button" class="float-right close text-white" (click)="closePanel()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="editModal-content px-3">
            <div class="my-2">
              <p-steps [model]="steps" [(activeIndex)]="currentStep" styleClass="shipment-steps"></p-steps>
            </div>
            <ngx-spinner name="spinner" [fullScreen]="false" size="medium"></ngx-spinner>
            <!-- add new shipment -->
            <form [formGroup]="shipmentForm" class="pl-2" (ngSubmit)="saveShipment()" *ngIf="currentStep === 0">
              <div class="form-group row mb-2">
                <div class="col-md-12 mt-2">
                  <input id="name" type="text" placeholder="Shipment Name" class="form-control p-2"
                    formControlName="shipmentName" [ngClass]="
                      shipmentForm.controls['shipmentName'].value
                        ? ''
                        : 'border-danger'
                    " />
                </div>

                <div class="col-md-12 mt-2">
                  <p-dropdown [options]="shipmentTypes" id="type" placeholder="Shipment type" [styleClass]="
                      shipmentForm.controls['shipmentType'].value
                        ? 'w-100'
                        : 'w-100 border-danger'
                    " optionLabel="name" optionValue="value" formControlName="shipmentType"></p-dropdown>
                </div>

                <div class="col-md-12 mt-2">
                  <input id="desc" type="text" placeholder="Description" class="form-control p-2"
                    formControlName="shipmentDesc" [ngClass]="
                      shipmentForm.controls['shipmentDesc'].value
                        ? ''
                        : 'border-danger'
                    " />
                </div>

                <div class="col-md-12 mt-2" style="position: relative" (mouseleave)="hideSuggestions('pickup')">
                  <input id="pickup" (input)="waitForGoogleMapApi('pickup')"
                    (click)="showResults($event.target.value, 'pickup')"
                    (input)="showResults($event.target.value, 'pickup')" type="text" formControlName="pickupLocation"
                    [placeholder]="
                      shipmentForm.value.shipmentType !== 'Fixed'
                        ? 'Pickup location'
                        : 'Location'
                    " class="form-control p-2" [ngClass]="
                      shipmentForm.controls['pickupLocation'].value
                        ? ''
                        : 'border-danger'
                    " autocomplete="off" />
                  <div id="result" style="position: absolute; z-index: 9; width: 474px" *ngIf="showsPsuggestions">
                    <ul style="
                        background-color: white;
                        max-height: 100px;
                        list-style-type: none;
                        padding-left: 7px;
                        overflow: scroll;
                        overflow-x: hidden;
                      ">
                      <li style="
                          cursor: pointer;
                          padding: 5px;
                          border-bottom: 0.5px solid grey;
                          font-size: 12px;
                        " *ngFor="let add of terms" (click)="assignValue(add, 'pickup')">
                        {{ add }}
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="col-md-12 mt-2" id="pickup_calender">
                  <!-- [showTime]="true" -->
                  <!-- [hideOnDateTimeSelect]="false" -->
                  <p-calendar #pickupDate [placeholder]="
                      shipmentForm.value.shipmentType !== 'Fixed'
                        ? 'Pickup date'
                        : 'Date'
                    " [styleClass]="
                      shipmentForm.controls['pickupDate'].value
                        ? 'w-100'
                        : 'w-100 border-danger p-inputtext p-0'
                    " formControlName="pickupDate" [minDate]="minPickupDate" [showIcon]="true"
                    (onSelect)="setMinDeliveryDate($event)">
                    <!-- <p-footer>
                      <button pButton class="p-button-sm" type="button" label="Close" (click)="pickupDate.hideOverlay()"></button>
                    </p-footer> -->
                  </p-calendar>
                </div>
                <div class="w-100" *ngIf="shipmentForm.value.shipmentType !== 'Fixed'">
                  <div class="col-md-12 mt-2" style="position: relative" (mouseleave)="hideSuggestions('destination')">
                    <input id="destination" (input)="waitForGoogleMapApi('destination')"
                      (click)="showResults($event.target.value, 'destination')"
                      (input)="showResults($event.target.value, 'destination')" type="text"
                      formControlName="destinationLocation" placeholder="Destination location" class="form-control p-2"
                      [ngClass]="
                        shipmentForm.controls['destinationLocation'].value
                          ? ''
                          : 'border-danger'
                      " autocomplete="off" />
                    <div id="result" style="position: absolute; z-index: 9; width: 474px" *ngIf="showsDsuggestions">
                      <ul style="
                          background-color: white;
                          max-height: 100px;
                          list-style-type: none;
                          padding-left: 7px;
                          overflow: scroll;
                          overflow-x: hidden;
                        ">
                        <li style="
                            cursor: pointer;
                            padding: 5px;
                            border-bottom: 0.5px solid grey;
                            font-size: 12px;
                          " *ngFor="let add of terms" (click)="assignValue(add, 'destination')">
                          {{ add }}
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="col-md-12 mt-2" id="destination_calender">
                    <!-- [showTime]="true" -->
                    <!-- [hideOnDateTimeSelect]="false" -->
                    <p-calendar #deliveryDate placeholder="Delivery date" [styleClass]="
                        shipmentForm.controls['deliveryDate'].value
                          ? 'w-100'
                          : 'w-100 border-danger p-inputtext p-0'
                      " formControlName="deliveryDate" [showIcon]="true" [minDate]="minDeliveryDate">
                      <!-- <p-footer>
                      <button pButton class="p-button-sm" type="button" label="Close" (click)="deliveryDate.hideOverlay()"></button>
                    </p-footer> -->
                    </p-calendar>
                  </div>

                </div>
                <div class="col-md-12 mt-2">
                  <input id="geofence_radius" type="number" placeholder="Geofence Radius" class="form-control p-2"
                    formControlName="geofence_radius" [value]="shipmentForm.controls['geofence_radius'].value" />
                </div>
              </div>
              <div class="d-flex justify-content-end mt-2">
                <button [disabled]="isLoading || shipmentForm.invalid" class="btn bg-gradient-success font-size13"
                  type="submit">
                  Next <i class="fas fa-chevron-right ml-2"></i>
                </button>
              </div>
            </form>

            <!-- Gateway mapping -->
            <div class="py-2" *ngIf="currentStep === 1" id="gateway_mapping">
              <ng-container *ngIf="trackerList.length; else noOptions">
                <label *ngIf="trackers.length" class="font-weight-normal font-size13 required">Please select tracker to
                  assign</label>
                <div class="d-flex justify-content-between mb-1">
                  <p-dropdown [options]="trackers" class="select-custom d-block flex-fill" [(ngModel)]="selectedTracker"
                    optionLabel="data.deviceUUID" [filter]="true" filterBy="data.deviceUUID" [showClear]="true"
                    placeholder="Select a tracker">
                    <ng-template pTemplate="selectedItem">
                      <div class="country-item country-item-value" *ngIf="selectedTracker">
                        <div>{{selectedTracker.data.deviceUUID}}</div>
                      </div>
                    </ng-template>
                    <ng-template let-tracker pTemplate="item">
                      <div class="country-item">
                        <div>{{tracker.data.deviceUUID}}</div>
                      </div>
                    </ng-template>
                  </p-dropdown>
                  <button type="button" class="btn bg-gradient-success ml-2" (click)="getTrackerOptions()">
                    <i class="mr-l fas fa-sync"></i>
                  </button>
                </div>

                <label *ngIf="selectedTracker" class="font-weight-normal font-size13 required">Please set configuration
                  to assign</label>
                <p-accordion class="p-0" *ngIf="selectedTracker">
                  <p-accordionTab class="w-100" (click)="showConfigModal(selectedTracker)">
                    <ng-template pTemplate="header">
                      <div class="tracker-item">
                        <div>
                          <p>{{ selectedTracker.data.deviceUUID }}</p>
                          <small>{{ selectedTracker.data.client_id }}</small>
                        </div>
                      </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                      <form (ngSubmit)="configGateway()" [formGroup]="configForm">
                        <div class="form-group p-2 mb-0" (click)="$event.stopPropagation()">
                          <h6 class="font-size13 mb-0 pb-0">
                            Temperature Configuration
                          </h6>
                          <div class="row pl-2 pr-2">
                            <div class="col">
                              <input type="number" class="form-control" id="min" formControlName="min"
                                placeholder="Min Temp." />
                            </div>
                            <div class="col">
                              <input type="number" class="form-control" id="max" formControlName="max"
                                placeholder="Max Temp." />
                            </div>
                          </div>
                          <h6 class="font-size13 mb-0 pb-0">
                            Humidity Configuration
                          </h6>
                          <div class="row pl-2 pr-2">
                            <div class="col">
                              <input type="number" class="form-control" id="minH" formControlName="minH"
                                placeholder="Min Humidity" />
                            </div>
                            <div class="col">
                              <input type="number" class="form-control" id="maxH" formControlName="maxH"
                                placeholder="Max Humidity" />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-6 pl-3">
                              <h6 class="font-size13 mb-0 pb-0">
                                Frequency ( in minutes)
                              </h6>
                              <input type="number" class="form-control" id="freq" formControlName="freq"
                                placeholder="Freq in minutes" />
                            </div>
                            <div class="col-6 text-right mt-3 pl-4">
                              <button
                                [disabled]="configForm.invalid || isLoading || configForm.pristine || selectedTracker.disabled"
                                class="btn py-2 mr-2 bg-gradient-success font-size13" (click)="isTrackerAssigned = 1"
                                type="submit">
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                        <div
                          *ngIf="this.configForm.controls['freq'].touched &&  this.configForm.controls['freq'].invalid"
                          class="p-0 m-0">
                          <span class="text-xs text-red">Minimum frequency limit is
                            {{ freq }} minutes.</span>
                        </div>
                      </form>
                    </ng-template>
                  </p-accordionTab>
                </p-accordion>

                <!-- <label *ngIf="trackers.length" class="font-weight-normal font-size13 required">List of trackers</label> -->

                <p-accordion class="p-0">
                  <ng-container *ngIf="selectedTrackers.length">
                    <label class="font-weight-normal font-size13">Assigned tracker(s)</label>
                    <ng-container *ngFor="let tracker of selectedTrackers">
                      <p-accordionTab class="w-100" (click)="showConfigModal(tracker)">
                        <ng-template pTemplate="header">
                          <div class="tracker-item">
                            <div>
                              <p>{{ tracker.data.deviceUUID }}</p>
                              <small>{{ tracker.data.client_id }}</small>
                            </div>
                          </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                          <form (ngSubmit)="configGateway()" [formGroup]="configForm">
                            <div class="form-group p-2 mb-0" (click)="$event.stopPropagation()">
                              <h6 class="font-size13 mb-0 pb-0">
                                Temperature Configuration
                              </h6>
                              <div class="row pl-2 pr-2">
                                <div class="col">
                                  <input type="number" class="form-control" id="min" formControlName="min"
                                    placeholder="Min Temp." />
                                </div>
                                <div class="col">
                                  <input type="number" class="form-control" id="max" formControlName="max"
                                    placeholder="Max Temp." />
                                </div>
                              </div>
                              <h6 class="font-size13 mb-0 pb-0">
                                Humidity Configuration
                              </h6>
                              <div class="row pl-2 pr-2">
                                <div class="col">
                                  <input type="number" class="form-control" id="minH" formControlName="minH"
                                    placeholder="Min Humidity" />
                                </div>
                                <div class="col">
                                  <input type="number" class="form-control" id="maxH" formControlName="maxH"
                                    placeholder="Max Humidity" />
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-6 pl-3">
                                  <h6 class="font-size13 mb-0 pb-0">
                                    Frequency ( in minutes)
                                  </h6>
                                  <input type="number" class="form-control" id="freq" formControlName="freq"
                                    placeholder="Freq in minutes" />
                                </div>
                                <div *ngIf="!selectedShipment?.trackers[0]?.isRemoveTracker" class="col-6 text-right mt-3 pl-4">
                                  <button [disabled]="configForm.invalid || isLoading ||
                                                            configForm.pristine
                                                          " class="btn py-2 bg-gradient-success font-size13"
                                    (click)="isTrackerAssigned = 1" type="submit">
                                    Update
                                  </button>
                                  <button type="button" class="btn py-2 bg-gradient-danger font-size13 ml-2 mr-2"
                                    (click)="removeTracker(tracker.data.token)" [disabled]="tracker.disabled">
                                    Remove
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div *ngIf="this.configForm.controls['freq'].touched &&
                                         this.configForm.controls['freq'].invalid" class="p-0 m-0">
                              <span class="text-xs text-red">Minimum frequency limit is
                              {{ freq }} minutes.</span>
                            </div>
                          </form>
                        </ng-template>
                      </p-accordionTab>
                    </ng-container>
                  </ng-container>
                </p-accordion>

                <!-- <p-listbox
                                [options]="trackerList"
                                [listStyle]="{'max-height':'250px'}"
                                [showToggleAll]="false"
                                styleClass="tracker-list"
                                formControlName="trackers"
                                optionValue="data.token"
                                >
                                <ng-template let-tracker pTemplate="item">
                                  <div class="tracker-item">
                                    <div>
                                      <p>{{ tracker.data.deviceUUID }}</p>
                                      <small>{{ tracker.data.client_id }}</small>
                                    </div>
                                    <div>
                                      <button pButton type="button" icon="pi pi-plus"
                                          *ngIf="!tracker.shipmentId"
                                          class="p-button-rounded p-button-text"
                                          (click)="showConfigModal(tracker)"></button>
                                      <button pButton type="button" icon="pi pi-times"
                                          *ngIf="tracker.shipmentId"
                                          class="p-button-rounded p-button-danger p-button-text"
                                          (click)="removeTracker(tracker.data.token)"></button>
                                      <button pButton type="button" icon="fas fa-cog"
                                          *ngIf="tracker.shipmentId"
                                          class="p-button-rounded p-button-text"
                                          (click)="showConfigModal(tracker)"></button>
                                    </div>
                                  </div>
                                </ng-template>
                              </p-listbox> -->
              </ng-container>
              <ng-template #noOptions id="no_trackers">
                <label class="font-weight-normal font-size13">No available trackers</label>
              </ng-template>
              <div class="d-flex justify-content-between mt-2">
                <button type="button" class="btn bg-gradient-success font-size13" (click)="goBack()">
                  <i class="fas fa-chevron-left mr-2"></i> Back
                </button>
                <button [disabled]="!isTrackerAssigned || !selectedTrackers.length"
                  class="btn bg-gradient-success font-size13" type="submit" (click)="goToStep3()" id="next">
                  Next <i class="fas fa-chevron-right ml-2"></i>
                </button>
              </div>
            </div>

            <!-- Blutag mapping -->
            <form class="py-2" (ngSubmit)="goToStep4()" [formGroup]="blutagForm" *ngIf="currentStep === 2"
              id="blutag_mapping">
              <label>Select Gateway</label>
              <p-dropdown [options]="selectedTrackers" placeholder="Select Gateway" formControlName="trackerId"
                optionLabel="data.deviceUUID" optionValue="data.token" (onChange)="selectTrackerOnTag($event)"
                styleClass="w-100"></p-dropdown>
              <div class="my-2 clearfix">
                <label> Select Blutag(s) </label>
                <button type="button" class="btn bg-gradient-success font-size13 float-right" (click)="scan()">
                  Scan <i class="mr-l fas fa-sync"></i>
                </button>
              </div>
              <!-- <p-listbox
                [options]="sensorsList"
                [listStyle]="{ 'max-height': '250px' }"
                [showToggleAll]="false"
                styleClass="tracker-list"
                optionValue="id"
              >
                <ng-template let-blutag let-index pTemplate="item">
                  <div class="blutag-item">
                    <p>{{ blutag.sensorName }}</p>
                    <div>
                      <button
                        pButton
                        type="button"
                        icon="pi pi-plus"
                        *ngIf="!(blutag.assign && blutag.shipmentId)"
                        class="p-button-rounded p-button-text"
                        (click)="saveBluTag(blutag.id)"
                      ></button>
                      <button
                        pButton
                        type="button"
                        icon="pi pi-times"
                        *ngIf="blutag.assign && blutag.shipmentId"
                        (click)="removeBlutag(blutag.id)"
                        class="p-button-rounded p-button-danger p-button-text"
                      ></button>
                    </div>
                  </div>
                </ng-template>
              </p-listbox> -->
              <p-accordion class="p-0 blutag">
                <ng-container class="mb-3" *ngFor="let blutag of sensorsList">
                  <p-accordionTab class="w-100" (click)="showBlutagConfig(blutag)">
                    <ng-template pTemplate="header" class="mb-2">
                      <div class="blutag-item">
                        <p>{{ blutag.sensorName }}</p>
                        <div>
                          <button pButton type="button" icon="pi pi-plus" *ngIf="!blutag.assign"
                            class="p-button-rounded p-button-text"></button>
                          <button pButton type="button" icon="pi pi-times" *ngIf="blutag.assign"
                            (click)="removeBlutag(blutag.id)"
                            class="p-button-rounded p-button-danger p-button-text"></button>
                        </div>
                      </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                      <form [formGroup]="blutagForm">
                        <div class="form-group p-2 mb-0" (click)="$event.stopPropagation()">
                          <h6 class="font-size13 mb-0 pb-0">
                            Temperature Configuration
                          </h6>
                          <div class="row pl-2 pr-2">
                            <div class="col-4">
                              <input type="number" class="form-control" id="min" formControlName="min"
                                placeholder="Min Temp." />
                            </div>
                            <div class="col-4">
                              <input type="number" class="form-control" id="max" formControlName="max"
                                placeholder="Max Temp." />
                            </div>
                            <div class="col-2" *ngIf="!blutag.assign">
                              <button [disabled]="
                                  blutagForm.invalid
                                " class="btn py-2 mr-2 bg-gradient-success font-size13" (click)="saveBluTag(blutag.id)"
                                type="submit">
                                Save
                              </button>
                            </div>
                            <div class="col-4" *ngIf="blutag.assign">
                              <div class="row">
                                <button [disabled]="
                                    blutagForm.invalid ||
                                    isLoading ||
                                    blutagForm.pristine
                                  " class="btn bg-gradient-success col-4 p-0 pt-1 pb-1 ml-3"
                                  (click)="saveBluTag(blutag.id)" type="submit">
                                  Update
                                </button>
                                <button type="button" class="btn bg-gradient-danger col-4 p-0 pt-1 pb-1 ml-3"
                                  (click)="removeBlutag(blutag.id)">
                                  Remove
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </ng-template>
                  </p-accordionTab>
                </ng-container>
              </p-accordion>
              <div class="d-flex justify-content-between mt-2">
                <button type="button" class="btn bg-gradient-success font-size13" (click)="goBack()">
                  <i class="fas fa-chevron-left mr-2"></i> Back
                </button>
                <button [disabled]="isLoading || blutagForm.invalid" class="btn bg-gradient-success font-size13"
                  type="submit" id="next">
                  Next <i class="fas fa-chevron-right ml-2"></i>
                </button>
              </div>
            </form>
            <!-- status -->
            <form (ngSubmit)="saveStatus()" [formGroup]="statusForm" *ngIf="currentStep === 3" id="status">
              <label class="required">Select Status</label>
              <p-dropdown [options]="statuses" placeholder="Select Status" formControlName="status" optionLabel="name"
                optionValue="value" styleClass="w-100"></p-dropdown>
              <div class="d-flex justify-content-between mt-2">
                <button type="button" class="btn bg-gradient-success font-size13" (click)="goBack()">
                  <i class="fas fa-chevron-left mr-2"></i> Back
                </button>
                <button [disabled]="isLoading || statusForm.invalid" class="btn bg-gradient-success font-size13"
                  type="submit" id="finish">
                  Finish
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </section>
  </div>
</div>
<!-- /Main content -->

<!--Modal: modalConfirmDelete-->
<p-confirmDialog [style]="{ width: '450px' }" [baseZIndex]="1052">
</p-confirmDialog>
<!--Modal: modalConfirmDelete-->

<!-- gateway configuration -->
<p-dialog header="Gateway configuration" [(visible)]="modalVisible" [modal]="true"
  [style]="{ width: '400px', 'max-width': '100%' }" [draggable]="false" [resizable]="false" [baseZIndex]="1052">
  <form (ngSubmit)="configGateway()" [formGroup]="configForm">
    <div class="form-group row">
      <label for="freq" class="col-sm-2 col-form-label font-weight-normal">Freq</label>
      <div class="col-sm-10">
        <input type="number" [readonly]="isDisabled" class="form-control" id="freq" formControlName="freq"
          placeholder="Freq" />
      </div>
    </div>
    <div class="form-group row">
      <label for="min" class="col-sm-2 col-form-label font-weight-normal">Min</label>
      <div class="col-sm-10">
        <input type="number" class="form-control" id="min" formControlName="min" placeholder="Min" />
      </div>
    </div>
    <div class="form-group row">
      <label for="max" class="col-sm-2 col-form-label font-weight-normal">Max</label>
      <div class="col-sm-10">
        <input type="number" class="form-control" id="max" formControlName="max" placeholder="Max" />
      </div>
    </div>
    <div class="text-right">
      <button [disabled]="configForm.invalid || isLoading || configForm.pristine"
        class="btn bg-gradient-success font-size13" (click)="isTrackerAssigned = 1" type="submit">
        Save
      </button>
    </div>
  </form>
</p-dialog>
<!-- gateway configuration -->