import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/auth/auth.service';
import { CommonService } from 'src/app/core/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { API_SUCCESS } from 'src/app/core/constants/global-success.constants';
import { API_ERROR } from 'src/app/core/constants/global-error.constants';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  forgotPwdForm: FormGroup;
  display = 'none';
  submitted: boolean;
  loading: boolean;

  constructor(private authService:AuthService,private toastr:ToastrService,private formBuilder:FormBuilder) { }

  ngOnInit(): void {
    this.forgotPwdForm = this.formBuilder.group({
      email:['', [Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
    })
  }

  get f() {
    return this.forgotPwdForm.controls;
  }

  sendResetLinkOnMail() {
    this.loading = true;
    this.authService.sendMail(this.forgotPwdForm.value).subscribe(
      (res => {
        this.display = 'block';
        this.submitted = true;
        this.loading = false;
      }),
      (error => {
        this.loading = false;
        if (error?.error?.message) {
          this.toastr.error(API_ERROR.EMAIL_NOT_EXISTS);    
        } else this.toastr.error(API_ERROR.SOMETHING_WENT_WRONG, '');
      })
    )
  }
}
