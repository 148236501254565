import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ShipmentsService } from 'src/app/modules/shipments/services/shipments.service';
import { CommunicationChainService } from 'src/app/modules/utility/services/communication-chain.service';
import { GRAPH_LIMIT } from 'src/app/core/constants/level.constants';
import { DashboardService } from 'src/app/modules/dashboard/services/dashboard.service';
import { CommonService } from 'src/app/core/services/common.service';
import { ConstantVariables } from 'src/constants/constants';

import jsPDF from 'jspdf';
import autoTable, { RowInput } from 'jspdf-autotable';
import html2canvas from 'html2canvas';
import { NgxSpinnerService } from 'ngx-spinner';
import { API_ERROR } from 'src/app/core/constants/global-error.constants';
import { EndPointService } from 'src/app/modules/end-point-management/services/end-point.service';
import { ToastrService } from 'ngx-toastr';

import { BASE64LOGO } from 'src/app/core/constants/global-success.constants';
import { CommentsService } from '../../services/comments.service';
import { FormControl } from '@angular/forms';
import { Table } from 'primeng/table';
import * as moment from 'moment';
import { calculateBatteryPercentage } from 'src/app/shared/utilites/battery-percentage-calculator.util';
import { event } from 'jquery';
@Component({
  selector: 'app-report-shipment-pdf',
  templateUrl: './report-shipment-pdf.component.html',
  styleUrls: ['./report-shipment-pdf.component.scss']
})
export class ReportShipmentPdfComponent implements OnInit, OnDestroy {
  @ViewChild('ptable') ptable: Table;


  public isopenSearch = false;
  public isLoading = true;
  public heatmap: any;
  type: any;
  mapData: any;
  zoom: 5;
  mapType = "roadmap";
  filterTotalEvents: string;
  isMapLoading: boolean = true;
  shipmentData: any = null;
  map: any = null;
  trackerData: any = [];
  trackerDataEvents: any = [];
  noLocation: boolean = false;
  optionevents: any;
  eventCount: number = 0;
  bluTagList: any = [];
  shipmentId: any;
  macId: any;
  allEndPoints: [];
  statuses: any;
  socketConnectionId: string;
  optionsensorEvents: any;
  chartDataOptions = [];
  tempDataCharts: any = [];
  eventAlertObj = null;
  shipmentComments: any = [];
  fromDate: FormControl = new FormControl('');
  toDate: FormControl = new FormControl({ value: '', disabled: true });
  columns: number = 7;
  isPaginated = true;
  temperatureObj = {
    latest: 'N/A',
    latestEventTime: 'N/A',
    max: 'N/A',
    maxEventTime: 'N/A',
    min: 'N/A',
    minEventTime: 'N/A',
    avg: 'N/A'
  }
  trackerWithGeocode = [];
  eventSignature;
  currentUser;
  eventFrequency;
  duration;

  public chartData = {
    backgroundColor: '',
    grid: {
      top: '8%',
      left: '4%',
      right: '4%',
      bottom: '8%',
      width: 'auto',
      height: '85%',
      containLabel: true,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: [],
      axisLabel: {
        show: true,
        fontSize: 10,
        color: '#333'
      },
      // name: 'Date',
      nameLocation: 'middle',
      nameGap: 26,
      nameTextStyle: {
        color: '#333',
        fontSize: 11,
        fontWeight: 'normal',
      },
      axisTick: {
        show: true
      },
      lineStyle: {
        color: '#333'
      },
      axisLine: {
        show: true,
      },
    },
    yAxis: {
      type: 'value',
      splitNumber: 5,
      axisLine: {
        show: true
      },
      minInterval: 1,
      lineStyle: {
        color: '#333'
      },
      axisTick: { show: false },
      axisLabel: {
        show: true,
        fontSize: 9,
        color: '#333'
      },
      nameLocation: 'middle',
      nameGap: 30,
      nameTextStyle: {
        color: '#333',
        fontSize: 11,
        fontWeight: 'normal',
      },
    },
    series: [{
      type: 'line',
      data: [],
      smooth: true,
      areaStyle: {}
    }]
  };

  public isDateFiltered: boolean
  pageScroll: any;

  public calculateBatteryPercentage = calculateBatteryPercentage;

  private _sub: Subscription = new Subscription();

  constructor(
    public constantVariables: ConstantVariables,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private communicationChainService: CommunicationChainService,
    private shipmentService: ShipmentsService,
    private endPointService: EndPointService,
    private toastr: ToastrService,
    private dashboardService: DashboardService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private commentsService: CommentsService,
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    const { user } = this.commonService.getCurrentUserData();
    if (user) {
      this.currentUser = user;
      const socketConnectionId = user.token + new Date().getTime();
      this.dashboardService.connectSocket(socketConnectionId);
      this.dashboardService.getNotificationData();
    }
    this._sub.add(this.route.params.subscribe(params => {
      if (params && params['sid']) { this.shipmentId = params['sid']; }
    }));
    this.getnewEvent();

    this.fromDate.valueChanges.subscribe(() => {
      this.isDateFiltered = false;
      this.fromDate.value ? this.toDate.enable() : this.toDate.disable();
    })
    this.toDate.valueChanges.subscribe(() => { this.isDateFiltered = false; })
    // setTimeout(() => {
    //   this.dashboardService.getLatestEvent().subscribe((data) => { });
    // }, 5000);

  }

  getShipmentComments() {
    this._sub.add(this.commentsService.getShipmentComments(this.shipmentId).subscribe((comments) => {
      this.shipmentComments = comments;
      this.shipmentComments.map((comment) => {
        let index = this.trackerDataEvents.findIndex((event) => event.signature === comment.signature)
        if (index !== -1) {
          if (this.trackerDataEvents[index]['comments'])
            this.trackerDataEvents[index]['comments'].push(comment);
          else
            this.trackerDataEvents[index]['comments'] = [comment];
        }
      });
    }));
  }

  showNewData() {
    this.trackerDataEvents = this.trackerData[0].events.filter((value, index, self) =>
      index === self.findIndex((e) => (e.deviceUUID === value.deviceUUID && e.timeStamp === value.timeStamp))
    );
    this.eventCount = this.trackerData[0].events.length;
    if (this.eventCount) {
      this.graphData(this.trackerData);
    }
    this.tempHumidityGraph();
    // this.getEventAlerts();
  }

  getnewEvent() {
    this.getShipmentData();
    this._sub.add(this.dashboardService.latestEvent$.subscribe((data) => {
        if (data && data.length) {
          const eventData = data;
          // this.pageScroll = window.pageYOffset;
          this._sub.add(this.communicationChainService
            .getAllEventDataById(this.shipmentId, true, 1)
          .subscribe((chain: any) => {
            if(chain.items[0].timeStamp === eventData[1][0].timeStamp) {
              eventData[1][0].formattedAddress = chain.items[0].formattedAddress;
            }
            let events = eventData[1];
            if (events[0].shipmentId === this.shipmentData?.id && !this.fromDate.value) {
              if (!this.shipmentData.events.length) {
                this.trackerData[0].events = events;
                this.showNewData();
                this.loadMap();
              } else if (this.shipmentData.events.length && events[0].timeStamp > this.trackerData[0].events[0].timeStamp)
              {
                for (let event of events) {
                  this.trackerData[0].events.unshift(event);
                }
                this.showNewData();
              }
            }
            this.dashboardService.getLatestEvents([]);
            }));
        }
    }
    ));
  }

 
  ngOnDestroy() {
    this.dashboardService.disconnectSocket();
    this._sub.unsubscribe();
  }

  public navigateTo() {
    if (this.currentUser.accesslevel.permissions['shipments'] && this.currentUser?.accesslevel.permissions['shipments'].update) {
      if(this.shipmentData.status === "STARTED" ||  this.shipmentData.status === "PAUSED") {
        this.shipmentService.pannelEvent({ openModel: true, shipmentId: this.shipmentId });
      }
    }
    this.router.navigateByUrl('shipments');
  }

  getShipmentData() {
   
    if (this.shipmentId) {
      this._sub.add(this.shipmentService.getShipmentById(this.shipmentId).subscribe(
        ((res: any) => {
         
          this.shipmentData = res;
          
          this.isLoading = false;
          this.getTrackerData();
        }),
        (error => {
          this.isLoading = false;
          if (error?.message === API_ERROR.USER_LOGOUT) {
            this.commonService.logout(API_ERROR.USER_LOGOUT);
          }
          console.log("error->>", error)
        })
      ));
    }
  }


  downloadCallibration(uuid) {
    this._sub.add(this.endPointService.getCalibrationCertificate(uuid).subscribe((data: Blob) => {
      const file = new Blob([data], { type: 'application/pdf' })
      const fileURL = URL.createObjectURL(file);

      window.open(fileURL, '_blank'); // target new window for preview

      /* direct download */
      // const a = document.createElement('a'); 
      // a.href = fileURL;
      // a.download = device.data.deviceUUID + '.pdf';
      // a.click();
    }, error => {
      if (error?.message !== API_ERROR.USER_LOGOUT) {
        this.toastr.error(`${uuid} certificate unable to retrieve at this moment. Please try again`, '', { timeOut: 3000 });
      }
    }));
  }

  getEventAlerts() {
    if (this.shipmentId && this.trackerDataEvents) {
      this._sub.add(this.communicationChainService.getEventAlerts(this.shipmentId).subscribe(
        ((res: any) => {
          this.eventAlertObj = res;
          const date1: any = new Date(this.shipmentData.startedAt);
          const date2: any = this.eventAlertObj['completedLocation'] ? new Date(this.shipmentData.completedAt) : new Date(this.trackerData[0]?.events[0]?.timeStamp * 1000);
          const days: any = Math.trunc((date2 - date1) / (1000 * 60 * 60 * 24));
          const hours = Math.trunc(Math.abs(date2 - date1) / (1000 * 60 * 60) % 24);
          const minutes = Math.trunc(Math.abs(date2.getTime() - date1.getTime()) / (1000 * 60) % 60);
          // const seconds = Math.trunc(Math.abs(date2.getTime() - date1.getTime()) / (1000) % 60);

          this.duration = days ? `${days}d:${hours}hr:${minutes}m` :
            hours ? `${hours}hr:${minutes}m` : `${minutes} minutes`


          // if (this.eventAlertObj && this.eventAlertObj['startedLocation']) {
          //   this.getAlertDataLocation(res.startedLocation, 'startedLocation')
          // }
          // if (this.eventAlertObj && this.eventAlertObj['completedLocation']) {
          //   this.getAlertDataLocation(res.completedLocation, 'completedLocation')
          // }
        }),
        (error => {
          this.isLoading = false;
          console.log("error->>", error)
        })
      ));
    }
  }

  getAlertDataLocation(startedLocation, key) {
    let geocoder = new google.maps.Geocoder;
    const latLng = {
      lat: Number(startedLocation.split(',')[0]),
      lng: Number(startedLocation.split(',')[1])
    }
    if (startedLocation !== "null,null") {
      geocoder.geocode({ location: latLng }, (results, err) => {
        if (results[0]?.formatted_address) {
          this.eventAlertObj[key] = results[0]?.formatted_address;
        }
      });
    } else {
      this.eventAlertObj[key] = key === 'startedLocation' ? this.shipmentData.pickupLocation : this.shipmentData.destinationLocation;
    }
  }

  convertedDate(date) {
    // const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    //   'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    // const dateObj = new Date(date);
    // const month = monthNames[dateObj.getMonth()];
    // const day = String(dateObj.getDate()).padStart(2, '0');
    // const year = dateObj.getFullYear();
    // const convertDate = `${day} ${month} ${year}, ${String(dateObj.getHours()).padStart(2, '0')}:${String(dateObj.getMinutes()).padStart(2, '0')}:${String(dateObj.getSeconds()).padStart(2, '0')}`;
    let convertDate = moment(new Date(date)).format("ddd MMM DD y h:mm a")
    return convertDate;
  }

  graphData(res?) {
    this.dashboardService.requestSensorEventsData('daily', [], [this.trackerData[0].trackers[0].data], [this.shipmentId]);

    let xAxisArr: any[] = [];
    let yAxisArr: any[] = [];
    const alertData = {
      min: Number(this.trackerData[0]?.trackers[0]?.min),
      max: Number(this.trackerData[0]?.trackers[0]?.max)
    };
    const tagsData = [];

    if (this.shipmentData?.trackers[0]?.sensors && this.shipmentData?.trackers[0]?.sensors.length) {
      this.shipmentData?.trackers[0]?.sensors.forEach((e) => {
        if (e.assign || e.isCompleted) {
          const assignedAt = e.assignedAt ? Math.trunc(new Date(e.assignedAt).getTime() / 1000) : undefined;
          let tagData = [];
          for (let t of this.trackerDataEvents) {
            if (t.sensors) {
              for (let sensor of t.sensors) {
                if ((sensor.tag === e.mac_addr) && (t.timeStamp >= assignedAt)) {
                  tagData.push({
                    time: t.time,
                    timeStamp: t.timeStamp,
                    temperature: sensor.temperature,
                    blueTag: sensor.tag
                  });
                }
              }
            }
          };
          let config = e?.config ? e?.config : { min: GRAPH_LIMIT.MIN, max: GRAPH_LIMIT.MAX };
          const date = [];
          const data = [];
          let minTemp = null;
          let maxTemp = null;
          let latestTemp = null;
          tagData.reverse();
          tagData.forEach((event) => {
            if (event?.temperature) {
              date.push(this.convertedDate(event.time));
              data.push(Number(event?.temperature));
              if (!minTemp?.val || minTemp?.val > event?.temperature) {
                minTemp = {
                  val: event?.temperature,
                  timeStamp: event?.timeStamp
                }
               
              }
              if (!maxTemp?.val || Number(maxTemp?.val) > Number(event?.temperature)) {
                maxTemp = {
                  val: event?.temperature,
                  timeStamp: event?.timeStamp
                }
              
              }
            }
            if (event?.temperature !== '')
              latestTemp = {
                val: event?.temperature,
                timeStamp: event?.timeStamp
              }
          });
          tagsData.push({
            bluTag: e.mac_addr,
            date,
            data,
            config,
            latestTemp,
            minTemp,
            maxTemp,
            avgTemp: data.length ? (data.reduce((a, b) => a + b, 0) / data.length).toFixed(2) : 'N/A'
          });
        }
      });
    }

    this.ChartDataGraph(tagsData, '', 'Temperature', alertData);
    this.tempHumidityGraph();
    this._sub.add(this.dashboardService.sensorEventsData().subscribe((data: any) => {
      if (this.socketConnectionId == data[0]) {
        xAxisArr = data[1]['xAxis'];
        yAxisArr = data[1]['Data'];
        this.plotSensorEvents(xAxisArr, yAxisArr, '', data[1]['ShipmentName'])
      };
    }));
  }

  getTrackerData() {
    this.isLoading = true;
    this._sub.add(this.communicationChainService.getTrackersWithEvents([this.shipmentData]).subscribe(
      ((res) => {
        this.trackerData = res;
        this.eventFrequency = this.trackerData && this.trackerData[0] && this.trackerData[0]?.trackers ? Math.round(this.trackerData[0]?.trackers[0]?.freq) : '';
        this.trackerDataEvents = this.trackerData[0]?.events.filter((value, index, self) =>
          index === self.findIndex((e) => (e.deviceUUID === value.deviceUUID && e.timeStamp === value.timeStamp))
        );
        if (this.trackerData[0]?.events.length)
          this.getEventAlerts();
        this.eventCount = this.trackerData[0]?.events.length;
        if (this.eventCount) {
          this.getShipmentComments();
          setTimeout(() => {
            let a: any = document.getElementsByClassName('thead')
            this.columns = a[0].cells.length + 1 || 7;
          }, 0);
          this.graphData(res);
          if (this.trackerData[0]?.events.length) this.loadMap();
        }
        this.isLoading = false;
      }), (error => {
        this.isLoading = false;
        console.log("error", error)
      })
    ));

  }

  private chartDataConfig(label, config) {
    if (config.min)
      return {
        isLoadChart: false,
        label: label,
        chart: {
          tooltip: {
            trigger: 'axis'
          },
          xAxis: {
            data: [],
            name: 'Date',
          },
          visualMap: {
            show: false,
            seriesIndex: 0,
            pieces: [
              {
                lte: Number(config.min),
                color: 'rgb(250, 37, 37)'
              },
              {
                gt: Number(config.min),
                lte: Number(config.max),
                color: '#2196F3'
              },
              {
                gt: Number(config.max),
                color: 'rgb(250, 37, 37)'
              },
            ]
          },
          yAxis: {
            max: GRAPH_LIMIT.MAX,
            min: GRAPH_LIMIT.MIN,
            name: label,
            type: "value",
            splitLine: {
              lineStyle: {
                color: ['#BBBBBB'],
              }
            }
          },
          series: {
            name: label === 'Temperature' ? 'Temp' : 'Hum',
            type: 'line',
            smooth: true,
            areaStyle: null,
            data: [],
            markArea: {
              silent: true,
              data: [
                [
                  {
                    yAxis: config.min,
                    itemStyle: {
                      color: "#7c32320a"
                    }
                  },
                  {
                    yAxis: config.max,
                    itemStyle: {
                      color: "#7c32320a"
                    }
                  }
                ]
              ]
            },
            markLine: {
              symbol: 'none',
              label:
              {
                position: 'middle',
                show: true,
              },
              data: [
                {
                  yAxis: config.max, name: 'Max', symbol: 'circle',
                  label: {
                    position: 'middle',
                    formatter: `Max ${label === 'Temperature' ? 'Temperature' : 'Relative humidity (RH)'}: ${config.max} ${label === 'Temperature' ? '°C' : '%'}`
                  },
                  lineStyle: {
                    color: 'orange'
                  }
                },
                {
                  yAxis: config.min, name: 'Min', symbol: 'circle',
                  label: {
                    position: 'insideMiddleBottom',
                    formatter: `Min ${label === 'Temperature' ? 'Temperature' : 'Relative humidity (RH)'}: ${config.min} ${label === 'Temperature' ? '°C' : '%'}`,
                  },
                  lineStyle: {
                    color: 'orange'
                  },
                }
              ]
            },
          }
        },
        maxTemp: {
          val: null,
          timeStamp: null
        },
        minTemp: {
          val: null,
          timeStamp: null
        },
        latestTemp: {
          val: null,
          timeStamp: null
        },
        avgTemp: 'N/A',
        unit: label === "Temperature" ? '°C' : '%'
      }
    else
      return {
        isLoadChart: false,
        label: label,
        chart: {
          tooltip: {
            trigger: 'axis'
          },
          xAxis: {
            data: [],
            name: 'Date',
          },
          yAxis: {
            max: GRAPH_LIMIT.MAX,
            min: GRAPH_LIMIT.MIN,
            name: label,
            splitLine: {
              lineStyle: {
                color: ['#BBBBBB'],
              }
            }
          },
          series: {
            name: label === 'Temperature' ? 'Temp' : 'Hum',
            type: 'line',
            data: [],
          }
        },
        maxTemp: {
          val: null,
          timeStamp: null
        },
        minTemp: {
          val: null,
          timeStamp: null
        },
        latestTemp: {
          val: null,
          timeStamp: null
        },
        avgTemp: 'N/A',
        unit: label === "Temperature" ? '°C' : '%'
      }
  }

  private tempHumidityGraph() {
    const uniqueTrackers = this.trackerDataEvents.filter((v, i, a) =>
      a.findIndex(t => (t.dateString === v.dateString)) === i);
    let config = {
      min: this.shipmentData?.trackers[0]?.min || this.shipmentData?.trackers[0]?.min === 0
        ? this.shipmentData?.trackers[0]?.min : GRAPH_LIMIT.MIN,
      max: this.shipmentData?.trackers[0]?.max || this.shipmentData?.trackers[0]?.max === 0
        ? this.shipmentData?.trackers[0]?.max : GRAPH_LIMIT.MAX,
      minH: this.shipmentData?.trackers[0]?.minH || this.shipmentData?.trackers[0]?.minH === 0
        ? this.shipmentData?.trackers[0]?.minH : undefined,
      maxH: this.shipmentData?.trackers[0]?.maxH || this.shipmentData?.trackers[0]?.maxH === 0
        ? this.shipmentData?.trackers[0]?.maxH : undefined,
    }
    let temp = this.chartDataConfig('Temperature', { min: config.min, max: config.max });
    let hum = this.chartDataConfig('Humidity', { min: config.minH, max: config.maxH });
    for (let tracker of uniqueTrackers) {
      if (!hum.latestTemp.timeStamp) {
        hum.latestTemp = {
          val: tracker?.parsedData?.humidity,
          timeStamp: tracker.time
        }
      }
      if (!temp.latestTemp.timeStamp) {
        temp.latestTemp = {
          val: tracker?.parsedData?.temp,
          timeStamp: tracker.time
        }
      }
      if (tracker?.parsedData?.humidity && !isNaN(tracker?.parsedData?.humidity)) {
        hum.isLoadChart = true;
        hum.chart.xAxis.data.unshift(this.convertedDate(tracker.time));
        hum.chart.series.data.unshift(Number(tracker?.parsedData?.humidity));
        if (!hum['minTemp'].val || (Number(hum['minTemp'].val) > Number(tracker?.parsedData?.humidity))) {
          hum['minTemp'] = {
            val: tracker?.parsedData?.humidity,
            timeStamp: tracker.time
          }
          hum.chart.yAxis.min = Math.floor(Number(tracker?.parsedData?.humidity) - 10);
        }
        if (Number(hum['maxTemp'].val) < Number(tracker?.parsedData?.humidity)) {
          hum['maxTemp'] = {
            val: tracker?.parsedData?.humidity,
            timeStamp: tracker.time
          }
          hum.chart.yAxis.max = Math.floor(Number(tracker?.parsedData?.humidity) + 10);
        }
      }
      if (tracker?.parsedData?.temp && !isNaN(tracker?.parsedData?.temp)) {
        temp.isLoadChart = true;
        temp.chart.xAxis.data.unshift(this.convertedDate(tracker.time));
        temp.chart.series.data.unshift(Number(tracker?.parsedData?.temp));
        if (!temp['minTemp'].val || (Number(temp['minTemp'].val) > Number(tracker?.parsedData?.temp))) {
          temp['minTemp'] = {
            val: tracker?.parsedData?.temp,
            timeStamp: tracker.time
          }
          temp.chart.yAxis.min = Math.floor(Number(tracker?.parsedData?.temp) - 10);
        }
        if (Number(temp['maxTemp'].val) < Number(tracker?.parsedData?.temp)) {
          temp['maxTemp'] = {
            val: tracker?.parsedData?.temp,
            timeStamp: tracker.time
          }
          temp.chart.yAxis.max = Math.floor(Number(tracker?.parsedData?.temp) + 10);
        }
      }
    }

    if (config.min < temp.chart.yAxis.min)
      temp.chart.yAxis.min = Math.floor(Number(config.min) - 10);
    if (config.max > temp.chart.yAxis.max)
      temp.chart.yAxis.max = Math.floor(Number(config.max) + 10);
    if (config.minH < hum.chart.yAxis.min)
      hum.chart.yAxis.min = Math.floor(Number(config.minH) - 10);
    if (config.maxH > hum.chart.yAxis.max)
      hum.chart.yAxis.max = Math.floor(Number(config.maxH) + 10);

    // temp.chart.visualMap.pieces[0].min = temp.chart.yAxis.min;
    // temp.chart.visualMap.pieces[2].max = temp.chart.yAxis.max;   
    // hum.chart.visualMap.pieces[0].min = hum.chart.yAxis.min;
    // hum.chart.visualMap.pieces[2].max = hum.chart.yAxis.max;
    hum.avgTemp = hum.chart.series.data.length
      ? (hum.chart.series.data.reduce((a, b) => a + b, 0) / hum.chart.series.data.length).toFixed(2) : 'N/A';
    temp.avgTemp = temp.chart.series.data.length
      ? (temp.chart.series.data.reduce((a, b) => a + b, 0) / temp.chart.series.data.length).toFixed(2) : 'N/A';
    this.tempDataCharts = [
      temp,
      hum
    ]
    setTimeout(() => {
      // window.scrollTo(this.pageScroll, this.pageScroll);
    }, 1);
    // Humidity
    // this.tempDataCharts
  }

  public ChartDataGraph(tagsData, xlable, title, alertData?) {
    this.chartDataOptions = [];
    let maxVal = GRAPH_LIMIT.MAX;
    let minVal = GRAPH_LIMIT.MIN;
    let chart = null;
    tagsData.forEach(e => {
      if (e?.bluTag) {
        const maxEventValue = Math.max(...e.data);
        const minEventValue = Math.min(...e.data);
        if (maxVal < maxEventValue)
          maxVal = maxEventValue;
        minVal = minEventValue;
        minVal = Math.floor(minVal)
        maxVal = Math.ceil(maxVal)
        let maxLength = e?.config?.max || e?.config?.max === 0 ? e?.config?.max : maxVal;
        let minLength = e?.config?.min || e?.config?.min === 0 ? e?.config?.min : minVal;

        chart = {
          tooltip: {
            trigger: 'axis'
          },
          visualMap: {
            show: false,
            seriesIndex: 0,
            pieces: [
              {
                lte: minLength,
                color: 'rgb(250, 37, 37)'
              },
              {
                gt: minLength,
                lte: maxLength,
                color: 'rgb(84, 207, 12)'
              },
              {
                gt: maxLength,
                color: 'rgb(250, 37, 37)'
              },
            ]
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            name: 'Date',
            data: e.date,
            splitLine: {
              show: false
            }
          },
          yAxis: {
            max: GRAPH_LIMIT.MAX,
            min: GRAPH_LIMIT.MIN,
            name: title,
            type: 'value',
            axisLabel: {
              formatter: '{value} °C'
            },
            splitLine: {
              lineStyle: {
                color: ['#BBBBBB'],
              }
            }
          },
          series: {
            name: 'Temp',
            type: 'line',
            data: e.data,
            markArea: {
              silent: true,
              data: null
            },
            markLine: {
              symbol: 'none',
              label:
              {
                position: 'middle',
                show: true,
              },
              data: []
            },
            areaStyle: null
          }
        };
        if (e.config && e.minTemp && e.maxTemp) {
          chart.yAxis.min = Math.floor((Number(e.minTemp.val) < minLength ? Number(e.minTemp.val) : minLength) - 10);
          chart.yAxis.max = Math.floor((Number(e.maxTemp.val) > maxLength ? Number(e.maxTemp.val) : maxLength) + 10);
          chart.series.markArea.data = [
            [
              {
                yAxis: minLength,
                itemStyle: {
                  color: "#fffff0d6"
                }
              },
              {
                yAxis: maxLength,
                itemStyle: {
                  color: "#fffff0d6"
                }
              }
            ]
          ];
          chart.series.markLine.data = [
            {
              yAxis: maxLength, name: 'Max', symbol: 'circle',
              label: {
                position: 'middle',
                formatter: `Max Temperature: ${maxLength} °C`
              },
              lineStyle: {
                color: 'rgb(250, 37, 37)'
              }
            },
            {
              yAxis: minLength, name: 'Min',
              label: {
                position: 'insideMiddleBottom',
                formatter: e?.data?.length ? `Min Temperature: ${minLength} °C` : '',
              },
              symbol: e?.data?.length ? 'circle' : null,
              lineStyle: {
                color: e?.data?.length ? 'rgb(250, 37, 37)' : 'transparent'
              }
            }
          ]
        }
        this.chartDataOptions.push({ bluTag: e.bluTag, latestTemp: e.latestTemp, minTemp: e.minTemp, maxTemp: e.maxTemp, avgTemp: e.avgTemp, isLoadChart: e?.data.length ? true : false, chart });
      }
    });

  }

  async loadMap() {
    let endLat, endLong;
    if(this.shipmentData?.events[0]?.parseData?.location){
      endLat = this.shipmentData?.events[0]?.parsedData?.location.split(',')[0];
      endLong = this.shipmentData?.events[0]?.parsedData?.location.split(',')[1];
    } else{
      const locationData = this.shipmentData?.events.find((e) => e?.parsedData?.location!=='N/A');
      if(locationData){
        endLat = this.shipmentData?.events[0]?.parsedData?.location.split(',')[0];
        endLong = this.shipmentData?.events[0]?.parsedData?.location.split(',')[1];
      } else
        this.noLocation = true;
    }
    
    if (this.shipmentData?.shipmentType.toLowerCase() === "fixed") {
      if (endLat === 'N/A') {
        const data = this.trackerDataEvents.find(e => e.location && e.location !== 'N/A')
        endLat = data?.parsedData?.latitude;
        endLong = data?.parsedData?.longitude;
      }
      this.type = 'fixed';
      this.mapData = (endLat === 'N/A') ? null : { latitude: endLat, longitude: endLong, geofence_radius: this.shipmentData?.geofence_radius };
      // this.mapData = { latitude: endLat, longitude: endLong, geofence_radius: this.shipmentData.geofence_radius };
    } else {
      let waypoints = [], content;
      this.trackerDataEvents.forEach(element => {
        const battery = element.parsedData.battery.split('V');
        let batValue = this.calculateBatteryPercentage(battery[0]);
        const batClass = this.calculateBatteryPercentage(battery[0], true);
        let bat = `<i class="fas ${batClass}"></i>`;
        let signal = element.parsedData.signal;

        let locationData = element.parsedData;
        locationData['time'] = element.time;
        locationData['sensors'] = element.sensors ? element.sensors : [];

        if (element.parsedData.signal) {
          content = this.commonService.getInfoWindowContent(locationData, bat, batValue, signal)
        } else {
          content = this.commonService.getInfoWindowContent(locationData, bat, batValue)
        }
        if (waypoints.findIndex(element1 => element1.latitude === element.parsedData.latitude) === -1 &&
          waypoints.findIndex(element1 => element1.longitude === element.parsedData.longitude) === -1) {
          waypoints.push({
            id: element.id,
            latitude: element.parsedData.latitude,
            longitude: element.parsedData.longitude,
            time: element.timeStamp,
            content
          });
        }
      });
      waypoints.reverse();

      this.type = "route"
      this.mapData = waypoints;

    }
  }

  /**
   * @description
   * @param event
   */
  plotSensorEvents(x, y, xlable, ylabel?) {
    let maxVal = GRAPH_LIMIT.MAX;
    let maxEvents = 0;
    let yaxis = [];
    y.map((shipments, index) => {
      if (ylabel && ylabel[index]) {
        yaxis.push({
          name: ylabel && ylabel[index] ? ylabel[index] : null,
          type: 'line',
          smooth: true,
          barWidth: '40%',
          data: shipments,
          areaStyle: { opacity: 0.5 },
        })
        if (Math.max(...shipments) > maxEvents)
          maxEvents = Math.max(...shipments)
      }
    })
    if (maxVal < maxEvents)
      maxVal = maxEvents + Math.round(maxEvents * GRAPH_LIMIT.PERCENTAGE_INCREASE / 100);
    this.optionsensorEvents = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      grid: {
        top: 20,
        left: '10%',
        right: '4%',
        bottom: '8%',
        width: 'auto',
        height: '65%',
        containLabel: true,
      },
      xAxis: [
        {
          type: 'category',
          data: x,
          splitLine: {
            show: false
          },
          axisLabel: {
            show: true,
            fontSize: 10
          },
          name: xlable,
          nameLocation: 'middle',
          nameGap: 26,
          nameTextStyle: {
            color: '#666',
            fontSize: 11,
            fontWeight: 'normal',
          },
          axisTick: {
            show: true
          },
          lineStyle: {
            color: '#fff'
          },
          axisLine: {
            show: true,
          },
        }
      ],
      yAxis: [{
        type: 'value',
        min: GRAPH_LIMIT.MIN,
        max: maxVal,
        axisLine: {
          show: true,
        },
        minInterval: 1,
        lineStyle: {
          color: '#666'
        },
        axisTick: { show: false },
        axisLabel: {
          show: true,
          fontSize: 9
        },
        name: 'No. of Events',
        nameLocation: 'middle',
        nameGap: 30,
        nameTextStyle: {
          color: '#666',
          fontSize: 11,
          fontWeight: 'normal',
        },
      }],
      series: yaxis
    };
  }

  public saveComment() {
    let comment: any = document.getElementById('commentSection');
    comment = comment.value;
    const commentObj = {
      shipmentId: this.shipmentId,
      signature: this.eventSignature,
      timeStamp: Math.round(new Date().getTime() / 1000),
      user: this.currentUser.fullName,
      comment
    }

    this._sub.add(this.commentsService.addComment(commentObj).subscribe((comment) => {
      this.shipmentComments.push(commentObj);
      let index = this.trackerDataEvents.findIndex((event) => event.signature === commentObj.signature)
      if (index !== -1) {
        if (this.trackerDataEvents[index]['comments'])
          this.trackerDataEvents[index]['comments'].push(commentObj);
        else
          this.trackerDataEvents[index]['comments'] = [commentObj];
      }
    }));
  }

  public clearText(event) {
    this.eventSignature = event.signature;
    const comment: any = document.getElementById('commentSection');
    comment.value = '';
  }

  public clearFilterDataByDates(): void {
    if (this.ptable)
      this.ptable.reset();
    this.spinner.show();
    this.trackerDataEvents = this.trackerData[0].events.filter((value, index, self) =>
      index === self.findIndex((e) => (e.deviceUUID === value.deviceUUID && e.timeStamp === value.timeStamp))
    );
    this.graphData();
    setTimeout(() => {
      this.spinner.hide();
      this.isDateFiltered = false;
      this.fromDate.setValue('');
      this.toDate.setValue('');
    }, 300);
  }

  public filterDataByDates(): void {
    if (this.ptable)
      this.ptable.reset();
    this.spinner.show();
    const fDate = this.fromDate.value ? this.fromDate.value.getTime() / 1000 : null;
    const tDate = this.fromDate.value && this.toDate.value ? this.toDate.value.getTime() / 1000
      : this.fromDate.value && !this.toDate.value ? (new Date()).getTime() / 1000 : null;
    const filterEvents = [];
    for (let ev of this.trackerData[0].events) {
      if (ev.timeStamp > fDate && ev.timeStamp < tDate) {
        filterEvents.push(ev);
      }
    }
    this.trackerDataEvents = filterEvents.filter((value, index, self) =>
      index === self.findIndex((e) => (e.deviceUUID === value.deviceUUID && e.timeStamp === value.timeStamp))
    );
    setTimeout(() => {
      this.graphData();
      this.spinner.hide();
      this.isDateFiltered = true;
    }, 300);
  }

  async generatePDF() {
    this.spinner.show();
    let pdf = new jsPDF('p', 'mm', 'a4', true);

    const tData = this.trackerDataEvents;
    const fDate = this.fromDate.value ? this.fromDate.value.getTime() / 1000 : null;
    const tDate = this.fromDate.value && this.toDate.value ? this.toDate.value.getTime() / 1000
      : this.fromDate.value && !this.toDate.value ? (new Date()).getTime() / 1000 : null;
    const filterEvents = [];
    for (let ev of this.trackerDataEvents) {
      if (!(fDate)) {
        filterEvents.push(ev);
      } else if (ev.timeStamp > fDate && ev.timeStamp < tDate) {
        filterEvents.push(ev);
      }
    }
    this.trackerDataEvents = filterEvents.filter((value, index, self) =>
      index === self.findIndex((e) => (e.deviceUUID === value.deviceUUID && e.timeStamp === value.timeStamp))
    );

    setTimeout(async () => {
      autoTable(pdf, {
        body: [
          [
            {
              content: this.shipmentData.shipmentName,
              styles: {
                halign: 'left',
                fontSize: 16
              },
              colSpan: 2
            }
          ],
          [
            {
              content: 'Type: ' + this.shipmentData.shipmentType,
              styles: {
                fontSize: 12
              }
            },
            {
              content: 'Status: ' + this.shipmentData.status,
              styles: {
                halign: 'right',
                fontSize: 12
              }
            }
          ],
          [
            {
              content: 'Description: ' + this.shipmentData.shipmentDesc,
              styles: {
                halign: 'left',
                fontSize: 12
              },
              colSpan: 2
            }
          ]
        ],
        theme: 'plain',
        margin: { top: 25, left: 4, right: 5 }
      });
      autoTable(pdf, {
        head: [
          [
            {
              content: this.constantVariables.ENDPOINT.LABEL1 + ' UUID',
              styles: {
                halign: 'left',
                fontSize: 12,
                fillColor: '#245EAA'
              }
            },
            {
              content: 'Battery',
              styles: {
                halign: 'left',
                fontSize: 12,
                fillColor: '#245EAA'
              }
            },
            {
              content: '(RSSI) Signal',
              styles: {
                halign: 'left',
                fontSize: 12,
                fillColor: '#245EAA'
              }
            },
            {
              content: 'Event Interval',
              styles: {
                halign: 'left',
                fontSize: 12,
                fillColor: '#245EAA'
              }
            },
            {
              content: 'Total Events',
              styles: {
                halign: 'left',
                fontSize: 12,
                fillColor: '#245EAA'
              }
            }
          ]
        ],
        body: [
          [
            {
              content: this.trackerData[0].trackers[0]?.data.deviceUUID,
              styles: {
                halign: 'left',
                fontSize: 12
              }
            },
            {
              content: this.calculateBatteryPercentage(this.trackerData[0]?.events[0]
                ?.parsedData?.battery.split("V")[0]) + "%",
              styles: {
                halign: 'left',
                fontSize: 12
              }
            },
            {
              content: this.trackerData[0]?.events[0]?.parsedData?.signal ? this.trackerData[0]?.events[0]?.parsedData?.signal : 'N/A',
              styles: {
                halign: 'left',
                fontSize: 12
              }
            },
            {
              content: `${Math.round(this.trackerData[0].trackers[0].freq)} minutes`,
              styles: {
                halign: 'left',
                fontSize: 12
              }
            },
            {
              content: this.trackerData[0]?.events?.length,
              styles: {
                halign: 'left',
                fontSize: 12
              }
            }
          ],

        ],
        margin: { left: 5, right: 5 },
        theme: 'striped',
        styles: {
          minCellHeight: 0
        },
      });
      const shipmentDetails = [];
      shipmentDetails.push([{
        content: this.shipmentData.shipmentType === 'Movable' ? 'Pickup from: ' : 'Location: ',
        styles: {
          halign: 'left',
          fontSize: 12,
          cellWidth: 50
        },
      },
      {
        content: this.shipmentData?.pickupLocation,
        styles: {
          halign: 'left',
          fontSize: 12,
        }
      }]);
      shipmentDetails.push([{
        content: this.shipmentData.shipmentType === 'Movable' ? 'Pickup Date: ' : 'Start At: ',
        styles: {
          halign: 'left',
          fontSize: 12,
          cellWidth: 50
        },
      },
      {
        content: moment(new Date(this.shipmentData?.pickupDate)).format("ddd MMM DD y h:mm a"),
        styles: {
          halign: 'left',
          fontSize: 12,
        }
      }]);

      if (this.trackerData[0]?.events?.length) {
        shipmentDetails.push([{
          content: 'Current location: ',
          styles: {
            halign: 'left',
            fontSize: 12,
            cellWidth: 50
          },
        },
        {
          content: this.trackerData[0]?.events[0]?.formattedAddress
            || this.trackerData[0]?.events[0]?.parsedData?.location || "N/A",
          styles: {
            halign: 'left',
            fontSize: 12,
          }
        }]);
        shipmentDetails.push([{
          content: 'Latest Event Time: ',
          styles: {
            halign: 'left',
            fontSize: 12,
            cellWidth: 50
          },
        },
        {
          content: moment(new Date(this.trackerData[0]?.events[0]?.timeStamp * 1000)).format("ddd MMM DD y h:mm a"),
          styles: {
            halign: 'left',
            fontSize: 12,
          }
        }]);
      }

      if (this.shipmentData.shipmentType === 'Movable') {
        shipmentDetails.push([{
          content: 'Drop at: ',
          styles: {
            halign: 'left',
            fontSize: 12,
            cellWidth: 50
          },
        },
        {
          content: this.shipmentData?.destinationLocation,
          styles: {
            halign: 'left',
            fontSize: 12,
          }
        }]);
        shipmentDetails.push([{
          content: 'Drop Date: ',
          styles: {
            halign: 'left',
            fontSize: 12,
            cellWidth: 50
          },
        },
        {
          content: moment(new Date(this.shipmentData?.deliveryDate)).format("ddd MMM DD y h:mm a"),
          styles: {
            halign: 'left',
            fontSize: 12,
          }
        }]);
      }

      autoTable(pdf, {
        head: [[{
          content: 'Shipment Address and Time Details ',
          styles: {
            halign: 'left',
            fontSize: 12,
            fillColor: '#245EAA'
          },
          colSpan: 2
        }]
        ],
        body: shipmentDetails,
        theme: 'striped',
        margin: { left: 4, right: 4 },
        styles: {
          minCellHeight: 0
        }
      });

      const data = document.getElementById('mapcard');
      await new Promise((resolve, reject) => {
        html2canvas(data, { useCORS: true }).then(canvas => {
          canvas.getContext('2d');
          const doc = pdf;
          let imgWidth = doc.internal.pageSize.width / 1.0455;
          let imgHeight = 120;
          let contentDataURL = canvas.toDataURL('image/png');
          pdf.setFontSize(12);
          pdf.setTextColor('#245EAA');
          let position = doc.internal.pageSize.height / (this.shipmentData.shipmentType === 'Movable' ? 1.9225 : 2.125);
          if (Number(this.shipmentData.geofence_radius))
            pdf.text(`Geofencing is enabled for radius of ${this.shipmentData.geofence_radius}m.`, 6, position - 2);
          pdf.addImage(contentDataURL, 'PNG', 5, position, imgWidth, imgHeight, '', 'FAST');
          resolve(true);
        });
      })

      pdf.setTextColor(0, 0, 0);
      pdf.addPage();
      let alertValue = [], pageHeight = 45;
      pdf.setFontSize(13);
      pdf.text('SUMMARY', 4, 33);

      let alerts = [];
      if (this.eventAlertObj) {
        for (const [key, value] of Object.entries(this.eventAlertObj)) {
          if (value && key === 'emptyLocation') {
            alertValue.push(['Location not found', value]);
          } else if (value && key === 'maxTrackerTemp') {
            alertValue.push(['Tracker reporting High Temperature', value]);
          } else if (value && key === 'minTrackerTemp') {
            alertValue.push(['Tracker reporting Low Temperature', value]);
          } else if (value && key === 'maxTrackerHumidity') {
            alertValue.push(['Tracker reporting High Humidity', value]);
          } else if (value && key === 'minTrackerHumidity') {
            alertValue.push(['Tracker reporting Low Humidity', value]);
          } else if (this.trackerData[0]?.trackers[0]?.sensors && value && key === 'emptyTagsTemp') {
            alertValue.push(['Tags not reporting temperature', value]);
          } else if (this.trackerData[0]?.trackers[0]?.sensors && value && key === 'minTagsTemp') {
            alertValue.push(['Tags reporting low temperature', value]);
          } else if (this.trackerData[0]?.trackers[0]?.sensors && value && key === 'maxTagsTemp') {
            alertValue.push(['Tags reporting high temperature', value]);
          } else if (value && key === 'batteryBelow30Percent') {
            alertValue.push(['Tracker Battery below 30.0%', value]);
          } else if (value && key === 'signalLost') {
            alertValue.push(['(RSSI) Signal lost', value]);
          }
          alerts.push(alertValue);
        }
      }
      if (alerts.length) {

        if (this.eventAlertObj['startedLocation']) {
          autoTable(pdf, {
            startY: pageHeight,
            head: [['Shipment Start Time']],
            body: [[moment(new Date(this.shipmentData?.startedAt)).format("ddd MMM DD y h:mm a")]],
            theme: 'striped',
            headStyles: {
              fillColor: '#FFFFFF',
              textColor: '#245EAA',
              fontSize: 12
            },
            bodyStyles: {
              // cellWidth: 60,
              fontSize: 11.5
            },
            margin: {
              left: 4,
              right: 145
            }
          });
          autoTable(pdf, {
            startY: pageHeight,
            head: [['Shipment Start Location']],
            body: [[this.eventAlertObj['startedLocation']]],
            theme: 'striped',
            headStyles: {
              fillColor: '#FFFFFF',
              textColor: '#245EAA',
              fontSize: 12
            },
            bodyStyles: {
              // cellWidth: (this.eventAlertObj['startedLocation'].toString().length) * 3,
              fontSize: 11.5
            },
            margin: {
              left: 85
            }
          });
          pageHeight += 15;
        }
        if (this.eventAlertObj['completedLocation']) {
          autoTable(pdf, {
            startY: pageHeight,
            head: [['Shipment Compelete Time']],
            body: [[moment(new Date(this.shipmentData?.completedAt)).format("ddd MMM DD y h:mm a")]],
            theme: 'striped',
            headStyles: {
              fillColor: '#FFFFFF',
              textColor: '#245EAA',
              fontSize: 12
            },
            bodyStyles: {
              // cellWidth: 60,
              fontSize: 11.5
            },
            margin: {
              left: 4,
              right: 145
            }
          });
          autoTable(pdf, {
            startY: pageHeight,
            head: [['Shipment Complete Location']],
            body: [[this.eventAlertObj['completedLocation']]],
            theme: 'striped',
            headStyles: {
              fillColor: '#FFFFFF',
              textColor: '#245EAA',
              fontSize: 12
            },
            bodyStyles: {
              // cellWidth: (this.eventAlertObj['completedLocation'].toString().length) * 3.5,
              fontSize: 11.5
            },
            margin: {
              left: 85
            }
          });
          pageHeight += 15;
        }
        const date1: any = new Date(this.shipmentData.startedAt);
        const date2: any = this.eventAlertObj['completedLocation'] ? new Date(this.shipmentData.completedAt) : new Date(this.trackerData[0]?.events[0]?.timeStamp * 1000);
        const days: any = Math.trunc((date2 - date1) / (1000 * 60 * 60 * 24));
        const hours = Math.trunc(Math.abs(date2 - date1) / (1000 * 60 * 60) % 24);
        const minutes = Math.trunc(Math.abs(date2.getTime() - date1.getTime()) / (1000 * 60) % 60);
        // const seconds = Math.trunc(Math.abs(date2.getTime() - date1.getTime()) / (1000) % 60);

        let duration = days ? `${days}d:${hours}hr:${minutes}m` :
          hours ? `${hours}hr:${minutes}m` : `${minutes} minutes`

        autoTable(pdf, {
          startY: pageHeight,
          head: [['Shipment Duration']],
          body: [[duration]],
          theme: 'striped',
          headStyles: {
            fillColor: '#FFFFFF',
            textColor: '#245EAA',
            fontSize: 12
          },
          bodyStyles: {
            fontSize: 11.5
          },
          margin: {
            left: 4,
            right: 165
          }
        });
        pageHeight += 50;

        autoTable(pdf, {
          startY: pageHeight,
          head: [['Alert', 'Count']],
          body: alertValue,
          theme: 'striped',
          headStyles: {
            fillColor: '#245EAA',
            fontSize: 12
          },
          bodyStyles: {
            fontSize: 11.5
          },
          margin: {
            left: 8
          }
        });
      } else {
        pdf.text('No Alerts', 4, 40)
      }
      pdf.addPage();

      for (let option of this.tempDataCharts) {
        const data = document.getElementById(`${option.label}`);
        await new Promise((resolve, reject) => {
          html2canvas(data, { useCORS: true }).then(canvas => {
            canvas.getContext('2d');
            let imgWidth = 200;
            let imgHeight = 100;
            let contentDataURL = canvas.toDataURL('image/png');
            let position = 35;
            let mean;
            if (option.label === "Temperature" && this.eventAlertObj && this.eventAlertObj['meanTemperature'])
              mean = this.eventAlertObj['meanTemperature'];
            if (option.label === "Humidity" && this.eventAlertObj && this.eventAlertObj['meanHumidity'])
              mean = this.eventAlertObj['meanHumidity'];

            pdf.addImage(contentDataURL, 'PNG', 4, position, imgWidth, imgHeight, '', 'FAST');
            pdf.setFont('helvetica', 'italic')
            pdf.setFontSize(16)
            pdf.text(`IMPACT Tracker ${option.label} Summary [ ${option?.unit} ]`, 4, 33)
            pdf.setFontSize(15);
            let tempConfig = this.trackerData[0].trackers[0].min ? [this.trackerData[0].trackers[0].min, this.trackerData[0].trackers[0].max] : [];
            let humidityConfig = this.trackerData[0].trackers[0].minH ? [this.trackerData[0].trackers[0].minH, this.trackerData[0].trackers[0].maxH] : [];
            let config = option.label === 'Temperature' ? tempConfig : humidityConfig;
            if (config.length) {
              autoTable(pdf, {
                startY: 145,
                head: [],
                body: [[`Expected ${option.label} Range`, `${config[0]}${option.unit} to ${config[1]}${option.unit}`]],
                theme: 'striped',
                headStyles: {
                  fillColor: '#245EAA',
                  fontSize: 15
                },
                bodyStyles: {
                  fontSize: 13
                },
                margin: {
                  top: 25,
                  left: 10
                }
              });
            }
            let summaryBody = [[`Current ${option.label}`, `${option.latestTemp?.val} ${option?.unit}`, `${moment(new Date(option.latestTemp.timeStamp)).format("ddd MMM DD y h:mm a")} `],
            [`Maximum ${option.label}`, `${option.maxTemp?.val} ${option?.unit}`, `${moment(new Date(option.maxTemp?.timeStamp)).format("ddd MMM DD y h:mm a")}`],
            [`Minimum ${option.label}`, `${option.minTemp?.val} ${option?.unit}`, `${moment(new Date(option.minTemp?.timeStamp)).format("ddd MMM DD y h:mm a")}`],
            [`Average ${option.label}`, `${option.avgTemp} ${option?.unit}`],
            [`Mostly Reported ${option.label}`, `${mean} ${option?.unit}`]
            ]
            if(this.eventAlertObj['MKT'] && option.label === 'Temperature'){
              summaryBody.push([
                `Mean Kinetic ${option.label}`, `${this.eventAlertObj['MKT'].toFixed(2)} ${option?.unit}`
              ])
            }
            autoTable(pdf, {
              startY: 165,
              head: [['Summary', 'Value', 'Event Time']],
              body: summaryBody,
              theme: 'striped',
              headStyles: {
                fillColor: '#245EAA',
                fontSize: 15
              },
              bodyStyles: {
                fontSize: 13
              },
              margin: {
                top: 25,
                left: 10
              }
            });
            pdf.addPage();
            resolve(true);
          });
        });
      }

      for (let option of this.chartDataOptions) {
        if (option?.chart?.xAxis?.data?.length) {
          const data = document.getElementById(`${option.bluTag}`);
          await new Promise((resolve, reject) => {
            html2canvas(data, { useCORS: true }).then(canvas => {
              canvas.getContext('2d');
              let imgWidth = 200;
              let imgHeight = 100;
              let contentDataURL = canvas.toDataURL('image/png');
              let position = 35;
              pdf.addImage(contentDataURL, 'PNG', 4, position, imgWidth, imgHeight, '', 'FAST');
              pdf.setFont('helvetica', 'italic')
              pdf.setFontSize(16)
              pdf.text(`${option.bluTag} Temperature Summary [°C]`, 4, 33)
              pdf.setFontSize(15);
              let config = this.shipmentData?.trackers[0]?.sensors
                ? this.shipmentData.trackers[0]?.sensors.find((sensor) => sensor?.mac_addr === option?.bluTag)?.config
                : [];
              let mean;
              if (this.eventAlertObj && this.eventAlertObj['bluTagMode']) {
                mean = this.eventAlertObj['bluTagMode'][option.bluTag] ? this.eventAlertObj['bluTagMode'][option.bluTag] : 'N/A';
              }
              if (config) {
                autoTable(pdf, {
                  startY: 145,
                  head: [],
                  body: [[`Expected Temperature Range`, `${config.min}°C to ${config.max}°C`]],
                  theme: 'striped',
                  headStyles: {
                    fillColor: '#245EAA',
                    fontSize: 15
                  },
                  bodyStyles: {
                    fontSize: 13
                  },
                  margin: {
                    top: 25,
                    left: 10
                  }
                });
              }
              autoTable(pdf, {
                startY: 165,
                head: [['Summary', 'Value', 'Event Time']],
                body: [[`Current Temperature`, `${option.latestTemp?.val} °C`, `${moment(new Date(option.latestTemp?.timeStamp * 1000)).format("ddd MMM DD y h:mm a")} `],
                [`Maximum Temperature`, `${option.maxTemp?.val} °C`, `${moment(new Date(option.maxTemp?.timeStamp * 1000)).format("ddd MMM DD y h:mm a")}`],
                [`Minimum Temperature`, `${option.minTemp?.val} °C`, `${moment(new Date(option.minTemp?.timeStamp * 1000)).format("ddd MMM DD y h:mm a")}`],
                [`Average Temperature`, `${option.avgTemp} °C`],
                [`Mostly Reported Temperature`, `${mean}°C`]],
                theme: 'striped',
                headStyles: {
                  fillColor: '#245EAA',
                  fontSize: 15
                },
                bodyStyles: {
                  fontSize: 13
                },
                margin: {
                  top: 25,
                  left: 10
                }
              });
              pdf.addPage();
              resolve(true);
            });
          });
        }
      }

      const tableData = [];
      let isSignal: boolean = false, tableHeader: any = ['Event Time', 'Location', 'Address', 'BluTag', 'BluTag Temp', 'Temp', 'Humidity', 'Signal'];

      if (!this.shipmentData?.trackers[0]?.sensors?.length) {
        tableHeader.splice(tableHeader.indexOf('BluTag'), 1);
        tableHeader.splice(tableHeader.indexOf('BluTag Temp'), 1);
      }
      for (let tracker of this.trackerDataEvents) {
        const event = [];
        event.push(tracker.time ? moment(new Date(tracker.time)).format("ddd MMM DD y h:mm a") : 'N/A')
        event.push(tracker.parsedData?.location ? tracker.parsedData?.location : 'N/A')
        event.push(tracker.formattedAddress ? tracker.formattedAddress : 'N/A')
        const tags = [];
        const temps = [];
        if (tableHeader.indexOf('BluTag') !== -1) {
          if (tracker?.sensors && tracker.sensors.length) {
            for (let sensor of tracker?.sensors) {
              tags.push([sensor.tag])
              temps.push(sensor.temperature ? [sensor.temperature] : 'N/A')
            }
          }
          event.push(tags.length ? tags.join('\n') : 'N/A')
          event.push(temps.length ? temps.join('\n') : 'N/A')
        }
        event.push(tracker.parsedData?.temp ? tracker.parsedData?.temp : '')
        event.push(tracker.parsedData?.humidity ? tracker.parsedData?.humidity : '')
        if (tracker.parsedData.signal !== '')
          isSignal = true;
        event.push(tracker.parsedData?.signal ? tracker.parsedData?.signal : '')
        tableData.push(event)
        if (tracker.comments) {
          let header = ['Comment'];
          for (let i = 1; i < (tableHeader.length) - 4; i++) {
            header.push('')
          }
          header.push('Commented By', '', 'Time');
          header.push('');
          tableData.push(header);
          tracker.comments.map((comment) => {
            let data = [`${comment.comment}-commentString`];
            for (let i = 1; i < (tableHeader.length) - 4; i++) {
              data.push('')
            }
            data.push(`${comment.user}-commented`, '', `${moment(new Date(comment.timeStamp * 1000)).format("ddd MMM DD y h:mm a")}-time`);
            data.push('');
            tableData.push(data);
          });
        }
      }
      if (!isSignal) {
        const index = tableHeader.indexOf('Signal');
        if (index > -1) {
          tableHeader.splice(index, 1);
        }
      }
      let colWidth = {};
      if (tableHeader.length === 9) {
        colWidth = {
          0: { cellWidth: 22 },
          1: { cellWidth: 30 },
          2: { cellWidth: 24 },
          3: { cellWidth: 25 },
          4: { cellWidth: 16 },
          5: { cellWidth: 20 },
          6: { cellWidth: 20 },
          7: { cellWidth: 18 }
        }
      } else if (tableHeader.length === 8) {
        colWidth = {
          0: { cellWidth: 27 },
          1: { cellWidth: 30 },
          2: { cellWidth: 24 },
          3: { cellWidth: 30 },
          4: { cellWidth: 20 },
          5: { cellWidth: 26 },
          6: { cellWidth: 20 }
        }
      } else if (tableHeader.length === 7) {
        colWidth = {
          0: { cellWidth: 31 },
          1: { cellWidth: 30 },
          2: { cellWidth: 34 },
          3: { cellWidth: 30 },
          4: { cellWidth: 20 },
          5: { cellWidth: 26 }
        }
      } else if (tableHeader.length === 6) {
        colWidth = {
          0: { cellWidth: 40 },
          1: { cellWidth: 42 },
          2: { cellWidth: 40 },
          3: { cellWidth: 30 },
          4: { cellWidth: 20 }
        }
      }

      pdf.text('Event Logs', 4, 26)
      if (this.trackerDataEvents.length) {
        /*Event Log*/
        autoTable(pdf, {
          head: [tableHeader],
          body: [...tableData],
          theme: 'striped',
          headStyles: {
            fillColor: '#FFFFFF',
            textColor: '#245EAA'
          },
          didParseCell: (cell) => {
            let comment: any = cell.cell.raw;
            if (comment && (comment === 'Comment')) {
              cell.cell.section = 'head';
              cell.cell.styles.textColor = '#245EAA';
              cell.cell.colSpan = Number(tableHeader.length) - 4
              cell.cell.styles.fillColor = '#eaf1fb';
            } else if (comment && isNaN(comment) && comment.match('-commentString')) {
              let text = cell.cell.raw.toString();
              text = text.split('-commentString')[0];
              cell.cell.raw = text;
              cell.cell.text = [text];
              cell.cell.colSpan = Number(tableHeader.length) - 4
              cell.cell.styles.fillColor = '#eaf1fb';
            }
            if (comment && (comment === 'Commented By')) {
              cell.cell.section = 'head';
              cell.cell.styles.textColor = '#245EAA';
              cell.cell.colSpan = 2;
              cell.cell.styles.fillColor = '#eaf1fb';
            }
            if (comment && (comment === 'Time')) {
              cell.cell.styles.textColor = '#245EAA';
              cell.cell.section = 'head';
              cell.cell.colSpan = 2;
              cell.cell.styles.fillColor = '#eaf1fb';
            }
            if (comment && isNaN(comment) && comment.match('-commented')) {
              let text = cell.cell.raw.toString();
              text = text.split('-commented')[0];
              cell.cell.raw = text;
              cell.cell.text = [text];
              cell.cell.colSpan = 2
              cell.cell.styles.fillColor = '#eaf1fb'
            }

            if (comment && isNaN(comment) && comment.match('-time')) {
              let text = cell.cell.raw.toString();
              text = text.split('-time')[0];
              cell.cell.raw = text;
              cell.cell.text = [text];
              cell.cell.colSpan = 2
              cell.cell.styles.fillColor = '#eaf1fb'
            }
          },
          columnStyles: colWidth,
          margin: {
            top: 29,
            left: 4,
            right: 4
          },
          tableLineColor: '#F2EFEF',
          tableLineWidth: 1,
        });
      } else {
        autoTable(pdf, {
          body: [[{
            content: 'There are no events found for the selected duration.',
            styles: {
              halign: 'center',
              fontSize: 10,
              fillColor: '#FFFFFF',
            }
          }
          ]
          ],
          margin: {
            top: 35,
            left: 4
          }
        })
      }

      this.addFooters(pdf);

      pdf.save(this.shipmentData?.shipmentName + '.pdf');
      pdf.output('dataurlnewwindow');

      this.trackerDataEvents = tData.filter((value, index, self) =>
        index === self.findIndex((e) => (e.deviceUUID === value.deviceUUID && e.timeStamp === value.timeStamp))
      );
      this.fromDate.setValue('');
      this.toDate.setValue('');
      this.spinner.hide();
    }, 100);
  }

  private addFooters(doc) {
    const pageCount = doc.internal.getNumberOfPages()

    doc.setFont('helvetica', 'italic')
    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i)
      doc.addImage(BASE64LOGO, 'PNG', 5.5, 10, 25, 10);
      doc.setFontSize(12)
      doc.text('Report Timestamp', (doc.internal.pageSize.width / 1.2350), 12.5)
      doc.setFontSize(8)
      const date = moment(new Date()).format("ddd MMM DD y h:mm a");
      doc.text(`${date}`, (doc.internal.pageSize.width / 1.22550), 17.5)
      doc.setFontSize(8)
      doc.text('Page ' + String(i) + ' of ' + String(pageCount), (doc.internal.pageSize.width / 1.025), 290, {
        align: 'right',
      });
    }
  }
}
