import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { EditProfileComponent } from './components/edit-profile/edit-profile.component';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { ChangePasswordComponent } from './components/change-password/change-password.component';

const routes: Routes = [
  { path: '', component: DashboardComponent, canActivate:[AuthGuard]},
  // { path: '', component: DashboardComponent},
  {path:'edit-profile',component:EditProfileComponent,canActivate:[AuthGuard]},
  {path:'change-password',component:ChangePasswordComponent,canActivate:[AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule {}
