<form class="form-card row px-2" [formGroup]="resetPwdForm">
  <div class="w-100 p-4">
    <div class="mt-4 pt-2 mb-5 text-center">
      <img src="../../../../../assets/images/IMPACT_by_honeywell-Logo.png" class="pr-1 img-fluid pb-1"/>
    </div>
    <div class="col-md-12 mt-2">
      <p class="lead mb-1">Reset Password</p>
    </div>
    <!-- <div class="col-md-12 mb-4 pr-4">
      <p class="small font-italic mb-0 helper-text">
        Optional mesage
      </p>
    </div> -->
  
    <div class="form-group col-md-12  mb-5 passwField">
      <span class="mb-0 font-size9"><b>New Password</b></span>
      <label class="has-float-label">
        <input [type]="passwordEye ? 'text' : 'password'" class="form-control" formControlName="password"
          placeholder="Password">
        <span>Set Password</span>
      </label>
      <i class="fa fa-eye password-field-icon" [ngClass]="{'fa-eye-slash': !passwordEye,'fa-eye': passwordEye}"
        (click)="toggelEyeButtonPassword()"></i>
      <!-- fa-eye -->
    </div>
  
    <div class="form-group mb-4 col-md-12 passwField">
      <span class="mb-0 font-size9"><b>Confirm Password</b></span>
      <label class="has-float-label">
        <input [type]="confirmPasswordEye ? 'text' : 'password'" class="form-control" formControlName="confirmPassword"
          placeholder="Confirm Password">
        <span>Confirm Password</span>
      </label>
      <i class="fa fa-eye password-field-icon"
        [ngClass]="{'fa-eye-slash': !confirmPasswordEye,'fa-eye': confirmPasswordEye}"
        (click)="toggelEyeButtonConPassword()"></i>
      <!-- fa-eye -->
    </div>
  
    <div class="form-group col-md-12 pt-2">
      <!-- <a href="javascript:void(0)" (click)="sendResetPassword();$event.preventDefault()" [class.disabled]="!resetPwdForm.valid" class="btn bg-gradient-primary px-4 text-uppercase float-right">Reset password</a> -->
      <button class="btn bg-gradient-primary px-4 text-uppercase w-100" type="submit" (click)="sendResetPassword()"
        [disabled]="loading || !resetPwdForm.valid">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1 w-100"></span>
        Submit</button>
    </div>
  
    <!-- <div class="form-group col-md-12 py-3 small">
      <span>Password must contain:</span>
      <ul>
        <li>Minimum 8 characters</li>
        <li>At least 1 letter</li>
        <li>At least 1 number</li>
        <li>And a special character</li>
      </ul>
    </div> -->
  </div>

</form>