


<section class="content mx-3 mt-4" id="node_map_page">
    <div class="card no-border">
        <div class="card-body p-0 tab-content" id="tab1content">
            <div class="row">
                <div class="col-md-12 bg-white p-3 tab-content cus-rounded-left cus-rounded-right">
                    <div class="tab-pane container-fluid active">
                        <div class="row">
                            <div class="col-md-12 nodemap-container">
                                <!-- Chart -->
                                <div echarts [options]="options | async" class="nodeMap-echarts"></div>
                                <button type="button" (click)="getNodeMap()" class="btn btn-sm refresh-btn" title="Refresh Node Map"><i
                                        class="fas fa-sync"></i></button>
                            </div>
                        </div>

                    </div>
                    <!-- /.card -->
                </div>
                <!-- /.card-body -->
            </div>
        </div>
    </div>
</section>