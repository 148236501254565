<section class="content mx-3 mt-4" id="user_policies">
    <div class="card no-border">
        <div class="card-body p-0 tab-content" id="tab1content">
            <div class="row">
                <div
                    class="col-md-12 bg-white p-3 tab-content cus-rounded-left cus-rounded-right">
                    <!-- User access policies tab code starts from here -->
                    <div class="tab-pane container-fluid active" >
                        <div class="row">
                            <div class="ngxTable-wrapper col-md-12">
                                <ng-scrollbar #scrollable track="horizontal" visibility="always"
                                    class="tabContent-scrollbar text-nowrap">
                                    <ngx-table [configuration]="configuration" [data]="accessData"
                                        [columns]="userDatacolumns">
                                        <ng-template let-row let-index="index">
                                            <td class="align-middle text-center">
                                                <div class="form-group mb-0">
                                                    <label class="form-checkbox mb-0 pt-1">
                                                      <input type="checkbox" (change)="onChange($event,row)" *ngIf="row.levelname != superLevel && row.levelname != 'User' &&  row.levelname != 'ADMIN' " />
                                                      <i class="form-icon"></i>
                                                    </label>
                                                  </div>
                                            </td>
                                            <td class=" align-middle cursor-pointer">
                                                {{row.levelname}}
                                            </td>
                                            <td  class=" align-middle cursor-pointer">
                                                <!-- <button class="mr-2 btn editable-table-btn btn-sm icn-btn"
                                            title="Edit" data-toggle="modal"
                                            data-target="#AddNewUser"><i
                                                class="fas fa-edit"></i></button> -->
                                                <button class="btn editable-table-btn btn-sm icn-btn"
                                                    *ngIf="(row.levelname != superLevel && row.levelname != 'User' &&  row.levelname != 'ADMIN')
                                                    && accessMenu['user-access-policies'] && accessMenu['user-access-policies'].delete"
                                                    title="Delete" data-toggle="modal"
                                                    data-target="#deleteModal" (click)="openDeleteModal(row)"
                                                ><i class="fas fa-trash-alt text-danger"></i></button>
                                            </td>

                                        </ng-template>
                                    </ngx-table>
                                </ng-scrollbar>
                            </div>
                        </div>
                    </div>

                    <div class="row d-flex justify-content-between mx-0">
                        <div class="col-md-6">
                            <button type="button"  *ngIf="accessMenu['user-access-policies'] && accessMenu['user-access-policies'].delete" id="remove_button"
                             class="btn primary-btn danger one text-uppercase mr-3" (click)="removeSelected()" [disabled]="selectedAccessLevel.length == 0">
                             <div class='insider'></div>
                             <i class="fas fa-minus pr-2"></i> Remove Selected
                            </button>
                            <button  type="button" *ngIf="accessMenu['user-access-policies'] && accessMenu['user-access-policies'].create" id="add_button"
                                class="btn primary-btn one text-uppercase"
                                routerLink="../add-new-access-level">
                                <div class='insider'></div>
                                <i class="fas fa-plus pr-2"></i> Add New Access Level
                            </button>
                        </div>
                    </div>

                </div>
                <!-- /.card -->
            </div>
            <!-- /.card-body -->
        </div>
    </div>
</section>


<!--Delete table entry popup-->
<div class="modal fade" id="deleteModal" tabindex="-1" role="dialog"
aria-labelledby="exampleModalLabel" aria-hidden="true">
<div class="modal-dialog modal-xs modal-notify modal-danger" role="document">
    <div class="modal-content text-center">
        <div class="modal-header d-flex justify-content-center border-0 p-0 pt-4">
            <h4 class="heading text-dark mb-0">Delete User Access Policy</h4>
        </div>
        <div class="modal-body border-0 p-0 pt-3">
            <p class="mb-0 text-dark">Delete the user access policy: {{delLevelName}}</p>
        </div>
        <div class="modal-footer d-flex justify-content-center row mx-0 border-0 mb-2">
            <button type="button" class="btn w3-grey w3-hover-grey w3-opacity-min w3-hover-opacity-off col-md-6 m-0 p-2"
                data-dismiss="modal">Cancel</button>
            <button type="button" class="btn w3-red w3-hover-red w3-opacity-min w3-hover-opacity-off col-md-6 m-0 p-2"
                data-dismiss="modal" (click)="deleteSingleAccesslevel()">Delete</button>
        </div>
    </div>

</div>
</div>

