import { NgModule } from '@angular/core';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { EditProfileComponent } from './components/edit-profile/edit-profile.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgxEchartsModule } from 'ngx-echarts';
import { ImageCropperModule } from 'ngx-image-cropper';
// import { GoogleMapsModule } from '@angular/google-maps'
import { AgmCoreModule } from '@agm/core';
import { environment } from 'src/environments/environment';
import { AccordionModule } from 'primeng/accordion';
import { TableModule } from 'primeng/table';
import { PaginatorModule } from 'primeng/paginator';



@NgModule({
  declarations: [
    DashboardComponent,
    EditProfileComponent,
    ChangePasswordComponent
  ],
  imports: [
    SharedModule,
    DashboardRoutingModule,
    TableModule,
    PaginatorModule,
    NgScrollbarModule,
    NgxEchartsModule,
    ImageCropperModule,
    SharedModule,
    AgmCoreModule.forRoot({
      apiKey: environment.mapAPIKey,
      libraries: ['visualization']
    }),
    AccordionModule,
    // GoogleMapsModule,
  ],
  exports:[DashboardComponent],
})
export class DashboardModule {}
