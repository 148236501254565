<section class="content mx-3 mt-4" id="user_management">
  <div class="card no-border">
    <div class="card-body tab-content" id="tab1content">
      <div class="row mt-2 mb-1 d-flex justify-content-between">
        <div class="col-md-12 text-right">
          <button type="button" *ngIf="accessMenu['user-management'].create" class="btn primary-btn one mr-2 text-uppercase"
            data-toggle="modal" (click)="showSignupUserModal=true">
            <div class="insider"></div>
            <i class="fas fa-plus pr-2"></i> Add New User
          </button>
          <div class="searchDiv mr-4">
            <input
              pInputText
              type="text"
              id="globalSearch"
              (input)="dt1.filterGlobal($event.target.value, 'contains')"
              placeholder="Search..."
              [ngClass]="{ open: isopenSearch }"
            />
            <button
              type="reset"
              title="Search"
              class="searchBtn btn primary-btn one text-uppercase"
              (click)="isopenSearch = !isopenSearch"
            >
              <i
                class="fas"
                [ngClass]="{
                  'fa-search-minus': isopenSearch,
                  'fa-search-plus': !isopenSearch
                }"
              ></i>
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          class="col-md-12 bg-white p-3 pb-0 tab-content cus-rounded-left cus-rounded-right"
        >
          <!-- User access policies tab code starts from here -->
          <div class="tab-pane container-fluid active">
            <div class="row">
              <div class="ngxTable-wrapper col-md-12">
                <p-table
                  #dt1
                  [value]="userData"
                  [rowHover]="false"
                  [paginator]="true"
                  [autoLayout]="true"
                  [globalFilterFields]="[
                    'fullName',
                    'organizationName',
                    'officialEmail',
                    'userLevel'
                  ]"
                  [rows]="5"
                  columnResizeMode="expand"
                  [resizableColumns]="true"
                  styleClass="p-datatable-gridlines"
                  [loading]="configuration.isLoading"
                  id="devicetable"
                >
                  <ng-template pTemplate="header">
                    <tr id="thead">
                      <th pResizableColumn pSortableColumn="photo">Photo</th>
                      <th pResizableColumn pSortableColumn="fullName">Name</th>
                      <th pResizableColumn pSortableColumn="organizationName">Client Name</th>
                      <th pResizableColumn pSortableColumn="officialEmail">User Name</th>
                      <th pResizableColumn pSortableColumn="userLevel">Security Level</th>
                      <th pResizableColumn pSortableColumn="actions">Actions</th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-row>
                    <tr id="tbody">
                      <td class="align-middle cursor-pointer">
                        <div class="userProfile">
                          <img
                            src="assets/images/icons/user_profile.jpg"
                            class="img-fluid img-circle"
                            width="50"
                          />
                        </div>
                      </td>

                      <td class="align-middle cursor-pointer">
                        {{ row.fullName }}
                      </td>

                      <td class="align-middle cursor-pointer">
                        {{ row.organizationName }}
                      </td>
                      <td class="align-middle cursor-pointer">
                        {{ row.officialEmail }}
                      </td>

                      <td class="align-middle cursor-pointer">
                        {{ row.userLevel }}
                      </td>
                      <!--  <td class="align-middle">
                                                <button type="button"
                                                    class="btn primary-btn one small"
                                                    (click)="getUserSmartContract(row?.roleId)">
                                                    <div class='insider'></div>
                                                    <i class="fa fa-database pr-1"></i> Smart
                                                    Contract
                                                </button>
                                            </td> -->
                      <!-- <td class="align-middle">
                                                <button type="button"
                                                    class="btn primary-btn one small"
                                                    (click)="copyUserToken(row?.token)">
                                                    <div class='insider'></div>
                                                    <i class="fa fa-copy pr-1"></i> Copy Token
                                                </button>
                                            </td> -->
                      <td
                        class="align-middle cursor-pointer"
                        *ngIf="
                          accessMenu['user-management'].update ||
                          accessMenu['user-management'].delete
                        "
                      >
                        <button
                          class="mr-2 btn editable-table-btn btn-sm icn-btn"
                          title="Edit"
                          *ngIf="
                            accessMenu['user-management'].update &&
                            row.userLevel !== logedUserLevel
                          "
                          (click)="userId = row.id; showUserModal = true"
                          >
                          <!-- routerLink="../add-new-user/{{ row.id }}" -->
                          <i class="fas fa-edit text-primary"></i>
                        </button>
                        <button
                          class="btn editable-table-btn btn-sm icn-btn"
                          *ngIf="
                            accessMenu['user-management'].delete &&
                            row.userLevel !== logedUserLevel
                          "
                          title="Delete"
                          data-toggle="modal"
                          data-target="#deleteModal"
                          (click)="openDeleteModal(row)"
                        >
                          <i class="fas fa-trash-alt text-danger"></i>
                        </button>
                        <button
                          class="mr-2 btn editable-table-btn btn-sm icn-btn"
                          title="User Session Report"
                          (click)="generatePDF(row.fullName, row.officialEmail)"
                        >
                        <i class="fa fa-file-pdf text-primary"></i>
                        </button>
                      </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage">
                    <tr>
                      <td colspan="6" class="text-center">No results found.</td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </div>
          </div>

          <div class="row d-flex justify-content-between mx-0">
            <div class="col-md-4">
              <button
                type="button"
                hidden
                *ngIf="accessMenu['user-management'].create"
                class="btn primary-btn one text-uppercase"
                data-toggle="modal"
                (click)="resetForm()"
                routerLink="../add-new-user/0"
              >
                <div class="insider"></div>
                <i class="fas fa-plus pr-2"></i> Add New User
              </button>
            </div>
          </div>
        </div>
        <!-- /.card -->
      </div>
      <!-- /.card-body -->
    </div>
  </div>
</section>

<!--Delete table entry popup-->
<div
  class="modal fade"
  id="deleteModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-xs modal-notify modal-danger" role="document">
    <div class="modal-content text-center">
      <div class="modal-header d-flex justify-content-center border-0 p-0 pt-4">
        <h4 class="heading text-dark mb-0">Delete User</h4>
      </div>
      <div class="modal-body border-0 p-0 pt-3">
        <p class="mb-0 text-dark">
          Delete the user {{ deleteUserData?.fullName }} <b></b>
        </p>
      </div>
      <div
        class="modal-footer d-flex justify-content-center row mx-0 border-0 mb-2"
      >
        <button
          type="button"
          class="btn w3-grey w3-hover-grey w3-opacity-min w3-hover-opacity-off col-md-6 m-0 p-2"
          data-dismiss="modal"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn w3-red w3-hover-red w3-opacity-min w3-hover-opacity-off col-md-6 m-0 p-2"
          data-dismiss="modal"
          (click)="deleteUser()"
        >
          Delete
        </button>
      </div>
    </div>
  </div>
</div>

<!--Smart Contract metadata popup-->
<p-dialog
  [(visible)]="showSmartContract"
  styleClass="smart-contract-modal"
  [modal]="true"
  [style]="{ width: '75vw' }"
>
  <ng-template pTemplate="header">
    <div class="modal-header bg-gradient-primary flex-fill">
      <h6 class="modal-title" id="exampleModalLongTitle">
        Smart Contract MetaData
      </h6>
    </div>
  </ng-template>
  <div class="modal-body">
    <form role="form">
      <div class="row">
        <label class="col-md-3 text-right text-capitalize small">index :</label>
        <div class="col-md-9 px-4 small">
          <label class="font-weight-normal small">
            <!-- {{index}} -->
          </label>
        </div>
      </div>
      <div class="row">
        <label class="col-md-3 text-right text-capitalize small"
          >previousHash :</label
        >
        <div
          class="col-md-9 px-4 small"
          title="4141ea1b80b317d55f5020f775b003a037fd60ff99ca98703be15f49280df87c"
        >
          <label class="font-weight-normal small">
            <!-- {{previoushash}} -->
          </label>
        </div>
      </div>
      <div class="row">
        <label class="col-md-3 text-right text-capitalize small"
          >timestamp :</label
        >
        <div class="col-md-9 px-4 small">
          <label class="font-weight-normal small">
            <!-- {{timestamp}} -->
          </label>
        </div>
      </div>
      <div class="row py-1">
        <label class="col-md-3 text-right text-capitalize small">data :</label>
        <div class="col-md-9 px-4 small">
          <div
            class="px-3 bg-light cus-rounded-right cus-rounded-left py-2 text-break"
          >
            <label class="font-weight-normal small">
              <pre style="white-space: pre-line">
                           {{ userSmartContractData | json }}
]                                   </pre
              >
            </label>
          </div>
        </div>
      </div>
      <div class="row pt-1">
        <label class="col-md-3 text-right text-capitalize small">Hash :</label>
        <div class="col-md-9 px-4 small" title="">
          <label class="font-weight-normal small">
            <!-- {{hash}} -->
          </label>
        </div>
      </div>
    </form>
    <!--   Loader -->
    <ngx-spinner
      bdColor="rgba(0, 0, 0, 0.8)"
      size="default"
      type="square-loader"
      name="modal-loading"
      [fullScreen]="false"
    >
      <p style="color: white">Loading...</p>
    </ngx-spinner>
  </div>
  <p-footer>
    <div class="modal-footer p-2">
      <button
        type="button"
        class="btn btn-secondary px-4"
        (click)="showSmartContract = false"
      >
        Close
      </button>
    </div>
  </p-footer>
</p-dialog>

<!--Add user popup-->
<p-dialog *ngIf="showSignupUserModal" [(visible)]="showSignupUserModal" styleClass="user-modal" [modal]="true" [style]="{ width: '30vw' }">
  <ng-template pTemplate="header">
    <div class="modal-header bg-gradient-primary flex-fill">
      <h6 class="modal-title" id="exampleModalLongTitle">Add New User</h6>
    </div>
  </ng-template>
  <div class="modal-body">
    <app-signup-user [org]="userInfo.organizationName" (handleModalEvent)="showSignupUserModal = false; getUserData();"></app-signup-user>
  </div>  
</p-dialog>

<!--Update user popup-->
<p-dialog *ngIf="showUserModal" [(visible)]="showUserModal" styleClass="user-modal" [modal]="true" [style]="{ width: '30vw' }">
  <ng-template pTemplate="header">
    <div class="modal-header bg-gradient-primary flex-fill">
      <h6 class="modal-title" id="exampleModalLongTitle">Update User</h6>
    </div>
  </ng-template>
  <div class="modal-body">
    <app-add-new-user *ngIf="userId" [userId]="userId" (closeModalEvent)="showUserModal = false"></app-add-new-user>
  </div>  
</p-dialog>