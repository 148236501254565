<form
  class="form-card row mb-3 px-2"
  [formGroup]="signupForm"
  (ngSubmit)="signUp()"
 style="width: 500px;"
>
  <div class="pl-4 pr-4">
    <div class="mt-4 pt-2 mb-5 text-center">
      <img
        src="../../../../../assets/images/IMPACT_by_honeywell-Logo.png"
        class="pr-1 img-fluid"
      />
    </div>
    <ng-container *ngIf="!submitted">
      <!-- <div class="col-md-12 mt-2 px-0">
      <ul class="nav nav-pills flex-row cus-tab sign-tabs" id="tab1">
        <li class="nav-item col-md-6">
          <a class="nav-link active font-weight-bold text-center" data-toggle="tab" href="#try-forfree"
            aria-expanded="true">
            Try for Free
          </a>
        </li>
        <li class="nav-item col-md-6">
          <a class="nav-link font-weight-bold text-center" (click)="clickBuyNow()" data-toggle="tab" href="#buy-now"
            role="tab" aria-expanded="false">
            Buy Now
          </a>
        </li>
      </ul>
    </div> -->

      <div class="tab-content col-md-12 px-0">
        <div class="tab-pane fade active show" id="try-forfree" role="tabpanel">
          <div class="row mx-0">
            <div class="col-md-12 mt-2">
              <p class="lead mb-2" style="font-size: 13px;">Create New Account</p>
            </div>
            <div class="col-md-12 mb-3 pr-4">
              <p
                class="small font-italic mb-0 helper-text"
                *ngIf="isEnterprise"
              >
                Register yourself and your organization with our collaborative Tenacious solution.
              </p>
              <p
                class="small font-italic mb-0 helper-text"
                *ngIf="!isEnterprise"
              >
                Create an account for your personal use at home/office etc.
              </p>
            </div>
            <!-- <div class="form-group ml-auto mb-3">
            <select class="form-control acctype-selection" formControlName="accountType" (change)="checkAccoutType()"
              name="" title="Account Type">
              <option value="Enterprise" selected>Enterprise</option>
              <option value="Personal">Personal</option>
            </select>
          </div> -->
          </div>
          <div class="row mx-0">
            <!-- <div class="form-group col-md-12 mb-3" id="full_name">
            <label class="has-float-label">
              <input class="form-control" formControlName="contactPersonName" type="text" placeholder="Full Name*" />
              <span>Full Name*</span>
            </label>
          </div> -->

            <div class="d-flex flex-row w-100">
              <div class="form-group col-md-6 mb-3 passwField" id="firstName">
                <span class="mb-0" style="font-size: 11px"
                  ><b>First Name</b></span
                >
                <label class="has-float-label">
                  <input
                    type="email"
                    class="form-control"
                    formControlName="firstName"
                    placeholder="First Name"
                  />
                </label>
              </div>

              <div class="form-group col-md-6 mb-3 passwField" id="lastName">
                <span class="mb-0" style="font-size: 11px"><b>Last Name</b></span>
                <label class="has-float-label">
                  <input
                    type="email"
                    class="form-control"
                    formControlName="lastName"
                    placeholder="Last Name"
                  />
                </label>
              </div>
            </div>

            <div class="form-group col-md-12 mb-3 passwField" id="email_id">
              <span class="mb-0" style="font-size: 11px"><b>Email</b></span>
              <label class="has-float-label">
                <input
                  type="email"
                  class="form-control"
                  formControlName="tenantEmail"
                  placeholder="Email ID"
                  autocomplete="off"
                />
              </label>
            </div>

            <div class="form-group col-md-6 passwField" id="set_password">
              <span class="mb-0" style="font-size: 11px"><b>Password</b></span>
              <label class="has-float-label">
                <input
                  [type]="passwordEye ? 'text' : 'password'"
                  class="form-control"
                  formControlName="password"
                  placeholder="Set Password"
                />
              </label>
              <i
                class="fa fa-eye password-field-icon"
                [ngClass]="{
                  'fa-eye-slash': !passwordEye,
                  'fa-eye': passwordEye
                }"
                (click)="toggelEyeButtonPassword()"
              ></i>
            </div>

            <div class="form-group col-md-6 passwField" id="confirm_password">
              <span class="mb-0" style="font-size: 11px"
                ><b>Confirm Password</b></span
              >
              <label class="has-float-label">
                <input
                  [type]="confirmPasswordEye ? 'text' : 'password'"
                  class="form-control"
                  formControlName="confirmPassword"
                  placeholder="Confirm Password"
                />
              </label>
              <i
                class="fa fa-eye password-field-icon"
                [ngClass]="{
                  'fa-eye-slash': !confirmPasswordEye,
                  'fa-eye': confirmPasswordEye
                }"
                (click)="toggelEyeButtonConPassword()"
              ></i>
            </div>

            <!-- <div class="form-group col-md-12 mb-3">
            <span class="small"
              >Note: Use 8 or more characters with a mix of letters, numbers &
              symbols.</span
            >
          </div> -->

            <div
              class="form-group col-md-12 mb-3"
              *ngIf="isEnterprise"
              id="organization_name"
            >
              <span class="mb-0" style="font-size: 11px"
                ><b>Organization Name</b></span
              >
              <label class="has-float-label">
                <input
                  type="text"
                  class="form-control"
                  formControlName="tenantName"
                  placeholder="Organization Name"
                />
              </label>
            </div>

            <!-- <div
              class="form-group col-md-6 mb-3"
              *ngIf="isEnterprise"
              id="company_size"
            >
              <span class="mb-0" style="font-size: 11px"
                ><b>Company Size (Employee)*</b></span
              >
              <label class="has-float-label">
                <select
                  class="form-control"
                  formControlName="tenantEmpSize"
                  name=""
                >
                  <option value="">Company Size (Employee)</option>
                  <option
                    *ngFor="let size of company_Size | keyvalue"
                    value="{{ size.value }}"
                  >
                    {{ size.value }}
                  </option>
                </select>
              </label>
            </div> -->

            <!-- <div class="form-group col-md-12 mb-3" id="address">
            <label class="has-float-label">
              <input type="text" class="form-control" formControlName="address" placeholder="Address">
              <span>Address</span>
            </label>
          </div> -->

            <!-- <div class="form-group col-md-6 mb-3" id="city">
            <label class="has-float-label">
              <input type="text" class="form-control" formControlName="city" placeholder="City">
              <span>City</span>
            </label>
          </div> -->

            <!-- <div class="form-group col-md-6 mb-3" id="state">
            <label class="has-float-label">
              <input type="text" class="form-control" formControlName="state" placeholder="State">
              <span>State</span>
            </label>
          </div> -->

            <!-- <div class="form-group col-md-6 mb-3" id="select_country">
            <label class="has-float-label">
              <select class="form-control" name="country" formControlName="country">
                <option value="" selected>Select Country</option>
                <option *ngFor="let country of selectcountries" [ngValue]="country.name">{{country.name}}</option>
              </select>
              <span>Select Country</span>
            </label>
          </div> -->

            <!-- <div class="form-group col-md-6 mb-3" id="zip">
            <label class="has-float-label">
              <input type="text" class="form-control" formControlName="zipCode" placeholder="Zip">
              <span>Zip*</span>
            </label>
          </div> -->

          
          <div class="col-md-12 mt-2" id="enable_sms">
            <div class="custom-control custom-checkbox">
              <input
              type="checkbox"
              class="custom-control-input"
              formControlName="enableSms"
              id="enableSms"
              name=""
              />
              <label
              class="font-weight-normal custom-control-label fsize13"
              (click)="toggleContactNumber()"
              for="enableSms"
              > Do you wish to receive messaging alerts from us? 
            </label>
            <div class="form-group mb-3" id="contact_number" *ngIf="showContactNumber">
            <label>
              <span class="font-weight-normal font-size13">Please enter your Contact Number</span>
              <div class="row">
                  <p-dropdown class="col-md-3"
                    aria-autocomplete="off"
                    editable="true"
                    [options]="selectcountries"
                    id="type"
                    placeholder=""
                    optionLabel="phoneCode"
                    optionValue="phoneCode"
                    formControlName="phoneCode"
                ></p-dropdown>
                <input type="number" class="col-md-8 form-control" formControlName="contactNumber" placeholder="Contact Number*">
              </div>
            </label>
          </div>
          </div>
        </div>


            <div class="col-md-12 mb-2 mt-2" id="accept_condition">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  formControlName="privacyPolicyTC"
                  id="termsconditions"
                  name=""
                />
                <label
                  class="font-weight-normal custom-control-label fsize13"
                  (click)="toggelPrivacyPolicyTC()"
                  for="termsconditions"
                >
                  By creating this account you agree to Honeywell's
                  <a
                    href="javascript:void(0)"
                    class="text-primary"
                    (click)="
                      termsConditionFlag = true;
                      submitted = true;
                      display = 'none';
                      showResendLink = false
                    "
                    >terms and conditions</a
                  ></label
                >
              </div>
            </div>
            <div class="col-md-12 text-center mt-3 mb-3" id="register_button">
              <button
                type="submit"
                class="btn bg-gradient-primary text-uppercase w-75"
                [disabled]="!signupForm.valid || loading"
              >
                <span
                  *ngIf="loading"
                  class="spinner-border spinner-border-sm mr-1"
                ></span>
                CREATE ACCOUNT
              </button>
            </div>

            <!-- <div class="col-md-12 text-center mt-2 mb-2" id="login_button">
              <p class="fsize13 mb-0">
                Already have an account?
                <a href="#" routerLink="/login" class="text-primary"
                  >Login here</a
                >
              </p>
            </div> -->
          </div>
        </div>

        <div class="tab-pane fade h-100" id="buy-now" role="tabpanel">
          <div class="row h-100 tab-pane">
            <div
              class="col-md-12 text-center d-flex align-items-center justify-content-center"
            >
              <p class="font-weight-bold">
                Subscription plans to be launched soon!
              </p>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <div
      class="col-md-12 mt-3 pb-2 text-center"
      [ngStyle]="{ display: display }"
      id="signup_confirmation"
    >
      <p class="mb-4 mt-4">Thank you for showing your interest with us!</p>
      <img
        src="assets/images/icons/email.png"
        class="text-center img-fluid"
        width=""
      />
      <p class="mt-4 mb-0">We have sent you a confirmation link at</p>
      <p class="font-weight-bold mb-4" id="signup_email">
        {{ f.tenantEmail.value }}
      </p>

      <p class="small mb-4">
        *If you didn't get the email, check your Junk or Spam folder or
        <a
          class="text-primary cursor-pointer"
          [ngStyle]="{ 'pointer-events': isDisabled ? 'none' : 'auto' }"
          (click)="resendEmail()"
          >try again <span *ngIf="countdownFlag">in </span></a
        >
        <span [hidden]="!countdownFlag">
          <countdown
            #cd
            [config]="{ leftTime: 15, demand: true, format: 'mm:ss' }"
            (event)="afterFinishCounter($event)"
          >
          </countdown>
        </span>
      </p>
    </div>

    <div *ngIf="showResendLink" class="col-md-12 mt-3 pb-2 text-center">
      <p class="mb-4 mt-3">Thank you for showing your interest with us!</p>
      <img
        src="assets/images/icons/email.png"
        class="text-center img-fluid"
        width=""
      />
      <p class="mt-4 mb-0">
        A verification link was sent to your email ID
        <span class="font-weight-bold">{{ f.tenantEmail.value }}</span
        >.
      </p>
      <p>Please click on that link to verify your email ID with us.</p>
      <p class="fsize13">
        If you haven't received any email yet or deleted it by mistake, please
        resend a new one.
      </p>
      <a
        class="text-primary mt-4 mb-4 d-block cursor-pointer"
        [ngStyle]="{ 'pointer-events': isDisabled ? 'none' : 'auto' }"
        (click)="resendEmail()"
        >Resend Verification Email <span *ngIf="countdownFlag">In:- </span></a
      >
      <span [hidden]="!countdownFlag">
        <countdown
          #cd
          [config]="{ leftTime: 15, demand: true, format: 'mm:ss' }"
          (event)="afterFinishCounter($event)"
        >
        </countdown>
      </span>
    </div>

    <div
      *ngIf="termsConditionFlag"
      class="col-md-12 mt-3 pb-2 privacypolicy-content"
    >
      <h2 class="lead text-center">HONEYWELL END USER LICENSE AGREEMENT</h2>
      <ng-scrollbar
        #scrollable
        track="vertical"
        visibility="always"
        class="policyScrollbar"
        id="policyScrollbar"
      >
        <div class="text-left pr-2">
          <p>By agreeing to these terms and conditions you consent to be bound by the terms and conditions in this End User License
          Agreement (“EULA”). You agree that you are the end-user of the System and you represent that you are of legal age and
          are authorized to enter into this EULA on behalf of yourself and any party to whom you grant access to the System.</p>
          <p>
            <b>1. LICENSE, LIMITS.</b><br />
            
            This EULA is for the use of certain Honeywell products and services, including Honeywell software for use on any
            Honeywell products and communications networks, Honeywell software allowing web-based interface and underlying
            functionality and access to Honeywell services, Honeywell web and mobile device applications software, and any Honeywell
            networks related to any of the foregoing (collectively, the “Honeywell Services” or “Services”) and the licensure of
            software products identified above, which includes computer and application software and may include associated media,
            printed materials, and "online" or electronic documentation, and any future versions, releases, updates, patches, error
            fixes and bug fixes of the above software ("Honeywell Software"). For convenience, we refer to the combination of
            products, software, and services we provide to you as the “System.”<br/>
            
            The System includes software owned by Honeywell and software licensed to Honeywell, and is protected by United
            States/Indian and international copyright laws and treaties, as well as other intellectual property laws and treaties.
            The System is licensed to you, not sold. Subject to the terms of this EULA, Honeywell grants you a limited,
            non-exclusive, non-transferable license (without the right to sublicense except as set forth below) to use the System,
            and its related Honeywell Software, solely for your personal use with your home or business in India. The foregoing
            license includes the right to install the Honeywell Software on your personal computer and/or mobile device and to use
            the Honeywell Software in conjunction with your licensed use of the System. Unregistered use, reproduction and
            distribution of the System is not permitted by Honeywell and is in violation of U.S., Indian and international copyright
            laws and is subject to civil and criminal penalties. You are specifically prohibited from making or distributing any
            copies of the System. All rights of any kind in the System and all other rights of Honeywell, which are not expressly
            granted in this EULA, are entirely and exclusively reserved to and by Honeywell (including the software object code and
            source code). You may not rent, lease, copy, modify, or translate the System, or create derivative works based on the
            System. You may not alter or remove any of Honeywell’s copyright or proprietary rights notices or legends appearing on
            or in the System. You may not reverse engineer, decompile or disassemble the System. Except as expressly set forth
            herein, you may not make access to the System available to any third party, nor are you authorized to make the output
            generated by or the results of any performance or functional evaluation of the System available to any third parties.<br />
            
            If you are a dealer, you shall have the right to sublicense access to security or lifestyle Systems solely to end users,
            provided each end user agrees to the terms of this EULA and all applicable fees are paid to Honeywell.<br />
            
            The System may contain or be derived from materials of third party licensors. Such third party materials may be subject
            to restrictions in addition to those listed in this EULA. You agree that any third party supplier shall have the right
            to enforce this EULA with respect to such third party’s software.<br />
            
            The System may contain Open Source Software that is provided to you under the terms of the open source license agreement
            or copyright notice accompanying such Open Source Software. As used herein, the term “Open Source Software” means any
            software, program, module, code, library, database, driver or similar component (or portion thereof) that is royalty
            free, proprietary software, the use of which requires any contractual obligations by the user to a third party or any
            license that has been approved by the Open Source Initiative, Free Software Foundation or similar group.<br />
            
            If you subscribe to GPS Tracking Services as part of your System, you agree that you will only use the location based
            services in accordance with applicable law and as intended as described in the documentation provided with the Service.
            You acknowledge that Honeywell shall have no liability for your usage of the Service that does not comply with law or
            the intended purpose.
          </p>
          <p>
            <b>2. PASSWORD AND ACCESS.</b><br/>
            You agree to: (a) provide true, accurate, current and complete information about yourself as prompted by the
            registration form and (b) maintain and promptly update such information. You must create a username and password to
            access the System. If you are provided with a temporary username and password you must modify the temporary credentials
            and create your own username and password. You are responsible for maintaining the confidentiality of your password and
            account and you are fully responsible for all activities that occur under your password, account or any subaccount. You
            agree to (a) immediately change your password in the event of any breach of security, (b) notify your central station
            (if applicable) if you have experienced a breach of security, and (c) ensure that you exit from your account at the end
            of each session. You agree that the terms of this EULA will be binding upon and govern the relationship between
            Honeywell and any third party to whom you grant access to your account or permit to maintain a subaccount. You further
            agree to take any and all action necessary to ensure compliance with, and enforce the terms of, this EULA, and to
            indemnify Honeywell for any and all costs, damages, losses or expenses incurred in respect of such third party user.
            Honeywell will not be liable for any loss or damage arising from your failure to comply with this Section.<br/>
            
            You acknowledge and agree that you have the option to add additional parties to your account and such parties will have
            full or partial access to the System, including, but not limited to, the ability to view and change your daily schedule
            and thermostat and/or security or home automation settings and programming. You further acknowledge and agree that
            Honeywell has no responsibility to you with respect to the actions of such parties. Further, you acknowledge and agree
            that you have the option to include your contractor’s or dealer’s information in your account information and such party
            may receive alerts from the System regarding your home (e.g. in the event of a security or safety breach) along with
            certain personally-identifiable information in order to be able to identify the source of the alert. Your contractor or
            dealer may contact you in the event it receives such alerts. You acknowledge and agree that Honeywell has no
            responsibility with respect to any actions or inactions on the part of such contractor.<br />
            
            You acknowledge and agree that Honeywell and its affiliates, service providers, suppliers, and dealers are permitted at
            any time and without prior notice to remotely push software and firmware updates, enhancements, changes, modifications,
            additional functionality or bug fixes to the System. You hereby consent to the foregoing and release Honeywell and its
            affiliates, service providers, suppliers, and dealers from any and all liability arising from such action.
          </p>
          <p>
            <b>3. TERM AND CANCELLATION; MODIFICATIONS TO SYSTEM; FEE.</b><br/>
            This EULA is effective on the day you first use the System and will continue for so long as you continue to use the
            System and Services and to otherwise make any required System service payments. Honeywell may terminate this EULA at any
            time if you fail to comply with any of the terms hereof, including failure to make required System service payments, if
            applicable, effective immediately. If you have a contract with a dealer for certain Services, your right to use the
            Services is subject to your dealer’s payment of all fees due to Honeywell for such Services and Honeywell may suspend or
            terminate your access to the Services in the event such party fails to pay for Services being provided by Honeywell or
            you fail to pay such party any sums otherwise due. Your payment to your dealer does not guarantee your right to the
            System Services. You may incur additional fees from third party providers in connection with your use of the System
            Services, an independent handheld device, or the internet, for data transmission, video clip or still image
            transmission, internet usage, SMS, short code or other transmission fees, charges or taxes. If Honeywell ceases
            supplying Services to you due to your or your dealer’s failure to pay for Services, Honeywell will have no liability to
            you and you must look to your dealer for the return of any amounts pre-paid by you to the dealer. Depending on the
            Services contracted or subscribed to by you, Honeywell may also suspend or terminate a user account because of user
            inactivity. Depending on the Services contracted or subscribed by you, you may terminate this EULA upon written notice
            to Honeywell. Upon termination of this EULA, the license granted hereunder will terminate and you must stop all use of
            the System immediately. Honeywell reserves the right at any time and from time to time to charge for use of the System,
            and modify or discontinue, temporarily or permanently, the System (or any part thereof) with or without notice. You
            agree that Honeywell will not be liable to you or to any third party for any modification, suspension or discontinuance
            of the System.
          </p>
          <p> <b>4. WARRANTY DISCLAIMERS AND LIABILITY LIMITATIONS.</b><br/>
            THE SYSTEM IS PROVIDED AS IS, AND WITH NO WARRANTIES OR REPRESENTATIONS OF ANY KIND, AND HONEYWELL EXPRESSLY DISCLAIMS
            ANY EXPRESS OR IMPLIED WARRANTIES, INCLUDING WARRANTIES OF NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A
            PARTICULAR PURPOSE. THE ENTIRE RISK ARISING OUT OF USE, PERFORMANCE OR NON-PERFORMANCE OF THE SYSTEM REMAINS WITH YOU.
            TO THE FULLEST EXTENT PERMITTED BY LAW, HONEYWELL DISCLAIMS ANY WARRANTIES FOR THE SECURITY, RELIABILITY, TIMELINESS,
            ACCURACY, AND PERFORMANCE OF THE SYSTEM.<br />
            
            HONEYWELL AND ITS THIRD PARTY SERVICE PROVIDERS HAVE NO LIABILITY FOR THE CHOICE OF AIR PURIFICATION DEVICES OR OTHER
            PRODUCTS INSTALLED AT YOUR PREMISES, THEIR INSTALLATION AND CONNECTION, OR OPERATION OF YOUR DEALER. YOU EXPRESSLY
            UNDERSTAND AND AGREE THAT HONEYWELL DISCLAIMS ANY AND ALL RESPONSIBILITY OR LIABILITY FOR (1) THE ACCURACY,
            COMPLETENESS, LEGALITY, RELIABILITY, OR OPERABILITY OR AVAILABILITY OF ANY CONTENT OR INFORMATION OR MATERIAL PROVIDED
            BY OR ACCESSIBLE THROUGH THE SYSTEM; AND (2) THE USE OF OR INABILITY TO USE THE SYSTEM; AND (3) THE DELETION, FAILURE TO
            STORE, MISDELIVERY, OR UNTIMELY DELIVERY OF ANY INFORMATION OR MATERIAL; AND (4) BREACHES OF SECURITY; AND (5)
            INFORMATION COMMUNICATED THROUGH MESSAGE BOARDS OR OTHER FORUMS; AND (6) ANY OTHER FAILURE TO PERFORM BY HONEYWELL; AND
            (7) DAMAGE, INJURY, OR LOSS OF LIFE OR DAMAGE TO PROPERTY.<br />
            
            IF HONEYWELL SHOULD NEVERTHELESS BE FOUND LIABLE, WHETHER DIRECTLY OR INDIRECTLY, FOR ANY LOSS, DAMAGE OR INJURY ARISING
            UNDER THIS AGREEMENT OR OTHERWISE, REGARDLESS OF CAUSE OR ORIGIN, ON ANY BASIS WHATSOEVER, (INCLUDING BUT NOT LIMITED TO BREACH OF CONTRACT, WARRANTY, NEGLIGENCE, STRICT LIABILITY IN TORT OR OTHERWISE), ITS TOTAL MAXIMUM
            LIABILITY IS LIMITED TO THE PURCHASE PRICE OF THE PRODUCT WHICH WILL BE THE COMPLETE AND EXCLUSIVE REMEDY AGAINST
            HONEYWELL. IN ADDITION, IN NO EVENT WILL HONEYWELL OR ITS PRINCIPALS, SHAREHOLDERS, OFFICERS, EMPLOYEES, AFFILIATES,
            CONTRACTORS, SUBSIDIARIES, OR SUPPLIERS, BE LIABLE FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES
            WHATSOEVER RELATING TO THE USE OF THE SYSTEM OR ANY COMPONENT THEREOF OR TO YOUR RELATIONSHIP WITH HONEYWELL, EVEN IF
            HONEYWELL HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.<br />
            
            UNDER NO CIRCUMSTANCES WILL HONEYWELL BE HELD LIABLE FOR ANY HARM RESULTING FROM DOWNLOADING OR ACCESSING ANY
            INFORMATION OR MATERIAL THROUGH THE SYSTEM, ANY DELAY OR FAILURE IN PERFORMANCE RESULTING DIRECTLY OR INDIRECTLY FROM
            ACTS OF NATURE, FORCES OR CAUSES BEYOND ITS REASONABLE CONTROL, INCLUDING, BUT NOT LIMITED TO, INTERNET FAILURES,
            COMPUTER EQUIPMENT FAILURES, TELECOMMUNICATION EQUIPMENT FAILURES, OTHER EQUIPMENT FAILURES, ELECTRICAL POWER FAILURES,
            STRIKES, LABOR DISPUTES, RIOTS, INSURRECTIONS, CIVIL DISTURBANCES, SHORTAGES OF LABOR OR MATERIALS, FIRES, FLOODS,
            STORMS, EXPLOSIONS, ACTS OF GOD, WAR, GOVERNMENTAL ACTIONS, ORDERS OF DOMESTIC OR FOREIGN COURTS OR TRIBUNALS,
            NON-PERFORMANCE OF THIRD PARTIES, OR LOSS OF OR FLUCTUATIONS IN HEAT, LIGHT, OR AIR CONDITIONING.<br />
            
            IN THE EVENT YOU (1) UTILIZE A NON-HONEYWELL APPLICATION TO CONTROL YOUR HONEYWELL PRODUCT; (2) CONNECT A NON-HONEYWELL
            DEVICE TO YOUR SYSTEM; (3) UTILIZE A THIRD PARTY API; OR (4) BECOME PART OF A THIRD PARTY CONNECTED HOME OR BUSINESS
            ECOSYSTEM, YOU ACKNOWLEDGE AND AGREE THAT IN NO EVENT WILL HONEYWELL BE RESPONSIBLE FOR THE ACCURACY, FUNCTIONALITY,
            RELIABILITY, AVAILABILITY, INTEROPERABILITY, LEGALITY OR USEFULNESS OF SUCH APPLICATION, DEVICE, API, OR ECOSYSTEM NOR
            WILL HONEYWELL BE LIABLE FOR ANY DAMAGES THAT MAY OCCUR AS A RESULT OF SUCH USE. USE OF A THIRD PARTY APPLICATION,
            DEVICE, API, OR ECOSYSTEM IS ENTIRELY AT YOUR OWN RISK AND EXPENSE.
          </p>
          <p>
            <b>5. NO GUARANTEE.</b><br/>
            YOU ACKNOWLEDGE THAT THE AVAILABILITY OF THE SYSTEM SERVICES IS DEPENDENT ON YOUR COMPUTER, MOBILE DEVICE, HOME WIRING,
            YOUR INTERNET SERVICE PROVIDER, YOUR SATELLITE PROVIDER IN THE CASE OF GEOLOCATION SERVICES, AND YOUR MOBILE DEVICE
            CARRIER AND YOU ACKNOWLEDGE THAT YOU ARE RESPONSIBLE FOR ALL FEES ASSOCIATED WITH SUCH USE AND FOR COMPLIANCE WITH ANY
            AGREEMENTS RELATED TO SUCH USE. YOU FURTHER ACKNOWLEDGE THAT HONEYWELL AND ANY NETWORK SERVICE PROVIDERS CANNOT
            GUARANTEE THE SECURITY OF ANY WIRELESS TRANSMISSION AND WILL NOT BE LIABLE FOR ANY LACK OF SECURITY RELATING TO THE USE
            OF ANY SERVICE. YOU AGREE THAT YOU WILL NOT RESELL ANY OF THE WIRELESS SERVICES PROVIDED TO YOU AS PART OF THE SYSTEM.
            HONEYWELL WILL HAVE NO LIABILITY FOR ANY PERSONAL INJURY, PROPERTY DAMAGE OR ANY OTHER LOSS BASED ON A CLAIM THAT THE
            SYSTEM FAILED TO GIVE WARNING OR ALERT OF ANY TYPE AND YOU HEREBY RELEASE HONEYWELL FROM ALL LIABILITY RESULTING
            THEREFROM. HONEYWELL MAKES NO GUARANTEE THAT THE SYSTEM WILL BE COMPATIBLE WITH ALL COMPUTERS OR HANDHELD DEVICES.YOU SHOULD FIRST TEST THOSE DEVICES AT HOME FOR COMPATIBILITY BEFORE USING THEM AWAY FROM HOME.
          </p>
          <p>
            <b>6. MISCELLANEOUS.</b>
            The failure of Honeywell to enforce at any time any of the provisions of this EULA will not be construed to be a
            continuing waiver of any provisions hereunder nor will any such failure prejudice the right of Honeywell to take any
            action in the future to enforce any provisions hereunder.<br />
            
            Software and technical information delivered under this EULA are subject to U.S. export control laws and may be subject
            to export or import regulations in other countries. You agree to strictly comply with all such laws and regulations, and
            you will be solely responsible for obtaining any import, export, re-export approvals and licenses required for such
            software any technical information, and retaining documentation to support compliance with those laws and regulations.<br />
            
            This EULA will be deemed entered into in India and will be governed by and construed according to the laws of India ,
            without regard to conflict of law principles. Any action against any party to this EULA will be commenced only in the
            courts of Delhi which courts will have exclusive jurisdiction over such actions and proceedings and the parties hereby
            irrevocably consent to personal jurisdiction over them by such courts. It is understood and agreed that, notwithstanding
            any other provisions of this EULA, breach of any provision of this EULA by you may cause Honeywell irreparable damage
            for which recovery of money damages would be inadequate, and that Honeywell will therefore be entitled to obtain timely
            injunctive relief to protect Honeywell’s rights under this EULA in addition to any and all remedies available at law.<br />
            
            This EULA, including the Privacy Statement, your subscription agreement (if applicable) and Honeywell’s terms and
            conditions of sale that accompanied your purchase of any equipment forms the entire agreement between you and Honeywell
            and supersedes in their entirety any and all oral or written agreements previously existing between you and Honeywell
            with respect to your access to and use of the System and related Services.<br />
            
            Neither this EULA nor any of the rights, interests or obligations provided by this EULA may be transferred or assigned
            by you without the prior written consent of Honeywell. Honeywell may assign this EULA, in whole or in part, in its sole
            discretion. This EULA will be binding upon the parties and each of their present and future officers, directors,
            employees, parents, subsidiaries, agents, successors, assigns, contractors, licensees, affiliates, family members and
            guests and authorized users.<br />
            
            The provisions of this EULA will apply to the fullest extent permitted by law and be interpreted and applied to a lesser
            extent, where necessary to be valid. The invalidity or unenforceability of any provision of this EULA will not affect
            any other provision and all such other provisions will remain in full force and effect without change or modification
            thereof. You consent to the exclusive use of the English language in this EULA, as well as in all communications from
            Honeywell.
          </p>
          <p>
            <b>7. APPLE DEVICE TERMS; THIRD PARTY BENEFICIARY</b>
            This Section applies to you only if you use the System on an Apple device (e.g., iPhone, iPad, iPod Touch) (any such
            device, an “Apple Device”); this section does not apply to you if you do not use the System on an Apple Device. The
            parties acknowledge that this EULA is concluded solely between Honeywell and you, and not with Apple, and Apple is not
            responsible for the System and the content thereof. Any support that may be offered by Honeywell in connection with the
            System is solely the responsibility of Honeywell and it is acknowledged by the parties that Apple has no obligation whatsoever to furnish any maintenance and support services with
            respect to the System. The parties acknowledge that Apple has no obligation to furnish any warranty with respect to the
            System. To the maximum extent permitted by applicable law, Apple will have no other warranty obligation whatsoever with
            respect to the System. The parties acknowledge that Apple is not responsible for addressing product claims. Apple is not
            responsible for addressing any claims of any end-user or any third party relating to the System or the end-user’s
            possession and/or use of that System, including, but not limited to: (i) product liability claims; (ii) any claim that
            the System fails to conform to any applicable legal or regulatory requirement; and (iii) claims arising under consumer
            protection or similar legislation. The parties acknowledge that, in the event of any third party claim that the System
            or your possession and use of that System infringes that third party’s intellectual property rights, Apple will not be
            responsible for the investigation, defense, settlement and discharge of any such intellectual property infringement
            claim. Complaints or claims with respect to the System should be directed to Honeywell at the following address:
            impact.sps@honeywell.com The parties acknowledge and agree that Apple, and Apple’s subsidiaries, are third party
            beneficiaries of this EULA, and that, upon your acceptance of the terms and conditions of this EULA, Apple will have the
            right (and will be deemed to have accepted the right) to enforce this EULA against you as a third party beneficiary
            thereof.
          </p>          
        </div>
        <button
          type="submit"
          (click)="
            termsConditionFlag = false;
            submitted = false;
            display = 'none';
            showResendLink = false
          "
          class="mb-2 px-3 btn bg-gradient-primary text-uppercase"
        >
          Back
        </button>
      </ng-scrollbar>
    </div>
  </div>
</form>
