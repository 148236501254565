import { Component } from '@angular/core';

@Component({
  selector: 'app-user-manual',
  templateUrl: './user-manual.component.html',
  styleUrls: ['./user-manual.component.scss'],
})
export class UserManualComponent {
  public copyrightYear: number = new Date().getFullYear();
}
