import { IPermission } from './../../../../shared/models/side-nav.model';
import { IUser } from './../../../../shared/models/user-signup.model';
import { switchMap } from 'rxjs/operators';
import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { AccessService } from '../../../access-control/services/access.service';
import { ToastrService } from 'ngx-toastr';
import { API_ERROR } from 'src/app/core/constants/global-error.constants';
import { CommonService } from 'src/app/core/services/common.service';
import { Router } from '@angular/router';
import { DashboardService } from '../../services/dashboard.service';
import { countries } from './country';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { environment } from 'src/environments/environment';
import { forkJoin, Subscription } from 'rxjs';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent implements OnInit, OnDestroy {

  passwordEye: boolean;
  confirmPasswordEye: boolean;
  loader: boolean;
  userData: any;
  editFlag = false;
  isReadOnly: boolean;
  accessData: any;
  userSmartContractData: any;
  userId: any;
  deleteUserData: any;
  accessMenu: { [key: string]: IPermission };
  activeClass = 'active';
  currentUser: IUser;
  username: string;
  email : string;
  phoneno : number;
  address : string;
  profilePic: any = "";
  fileName = "";
  city: any;
  zip: any;
  sendAlerts = false;
  isPlaying:boolean
  isloading:boolean=false
  // sendReport = false;
  // country: any;
  state: any;
  imageUrl: any;
  selectcountry: any = '';
  selectcountries: any = countries;
  enableBase64: boolean;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  options: string[] = ['Default', 'Interval Based'];
  selectedReportType = this.options[0]
  // @Output() setUserImage: EventEmitter<any> = new EventEmitter();
  private _sub: Subscription = new Subscription();

  constructor(private accessService: AccessService, private dashboardService:DashboardService
    , private toastr:ToastrService, private commonService:CommonService,private route:Router) { }

  ngOnInit(): void {
    this.enableBase64 = environment.enableBase64
    this.getUserDataAndAccessLevel();
    this.getLoginUserDetails();
    const { user } = this.commonService.getCurrentUserData();
    if (user) {
      const socketConnectionId = user.token + new Date().getTime();
      this.dashboardService.connectSocket(socketConnectionId);
      this.dashboardService.getNotificationData();
    }
  }


  /**
   * @description disable scrolling on mobile number
   */
  @HostListener('window:keydown', ['$event'])
  disableScrolling(event:KeyboardEvent) {
     if (event.keyCode === 38 || event.keyCode === 40) {
        event.preventDefault();
      }
    }

  /**
   * Description: get user data api call
   * @description get user data api call
   */
  getUserDataAndAccessLevel() {
    forkJoin([
      this.accessService.getUserManagement(),
      this.accessService.getAccessLevel()
    ]).subscribe(([userData, accessData]: [any, any]) => {
      this.userData = userData.data;
      this.accessData = accessData;
    }, error => {
      if (error?.message === API_ERROR.USER_LOGOUT) {
        this.commonService.logout(API_ERROR.USER_LOGOUT);
      }
      console.log(error);
    });
  }


  /**
   *
   * @param event
   * @description upload user profile image
   */
  onFileChanged(event) {
    const [file] = event.target.files;
    this.fileName = ''
    this.profilePic = '';
    if (file) {
      this.profilePic = file;
      this.fileName = file.name;
      if (!this.validateFile(file.name)) {
        this.toastr.error(API_ERROR.FILE_NOT_SUPPORTED, ' ', {
          timeOut: 3000
        });
        this.fileName = ''
        this.profilePic = '';
        return false;
      }
    }
  }

  enablesound()
  {
this.isPlaying=!this.isPlaying
  }


  /**
   * @description remove selected image
   */
  removeImage() {
   this.fileName = '';
   this.profilePic = '';
  }

  /**
   *
   * @param name
   * @description validate file name
   */
  validateFile(name: string) {
    const ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() == 'jpg' || ext.toLowerCase() == 'png' || ext.toLowerCase() == 'jpeg') {
        return true;
    }
    else {
        return false;
    }
}

  /**
   * @description get login user data
   */
  getLoginUserDetails() {
    const { user } = this.commonService.getCurrentUserData();
    this.currentUser = user;
    this.username = this.currentUser.fullName;
    this.email = this.currentUser.officialEmail;
    this.phoneno = this.currentUser.contactNumber;
    this.address = this.currentUser.address;
    this.userId = this.currentUser.id;
    this.city = this.currentUser.city;
    this.zip = this.currentUser.zipCode;
    this.state = this.currentUser.state;
    this.selectcountry = this.currentUser.country;
    this.sendAlerts = this.currentUser.sendEmailAlerts || false;
    this.selectedReportType = this.currentUser.reportType || 'Default';
    // this.sendReport = this.currentUser.sendDailyReport || false;
    this.isPlaying=this.currentUser.enableNotificationSound
    
    if(this.currentUser.profilePicture)
    this.fileName = this.currentUser.profilePicture;
    const { accesslevel } = this.currentUser;
    if (accesslevel?.permissions) {
      this.accessMenu = accesslevel?.permissions['access-control']?.submenu;
    }
  }


  /**
   * Description : edit profile api call
   * @description edit profile
   */
  editProfile(){
    this.isloading = true;
    const userInfo = {
      id: this.userId,
      officialEmail: this.email,
      address: this.address,
      fullName: this.username,
      contactNumber: this.phoneno,
      zipCode: this.zip,
      country: this.selectcountry,
      city: this.city,
      profilePicture: null,
      sendEmailAlerts: this.sendAlerts,
      reportType: this.selectedReportType,
      enableNotificationSound:this.isPlaying
      // sendDailyReport: this.sendReport
    };
    if (this.profilePic) {
      userInfo.profilePicture = this.profilePic;
      
    }
    this._sub.add(this.accessService.editProfile(userInfo).pipe(
      switchMap(response => {
        this.currentUser.fullName = this.username;
        this.currentUser.officialEmail = this.email;
        this.currentUser.contactNumber = this.phoneno;
        this.currentUser.address = this.address;
        this.currentUser.id = this.userId;
        this.currentUser.city = this.city;
        this.currentUser.zipCode = this.zip;
        this.currentUser.state = this.state;
        this.currentUser.country = this.selectcountry;
        this.currentUser.sendEmailAlerts = this.sendAlerts;
        this.currentUser.reportType = this.selectedReportType;
        // this.currentUser.sendDailyReport = this.sendReport;
        return this.accessService.getUserManagement();
      })
    ).subscribe(
      (res) => {
        this.userData = res['data'];
        this.currentUser.profilePicture = this.userData[0].profilePicture;
        this.currentUser.enableNotificationSound=this.userData[0].enableNotificationSound
        this.commonService.setUserData(this.currentUser);
        this.isloading = false;
       // window.location.reload();
        setTimeout(() => {
          
          window.location.reload();
        }, 100);
        
        this.toastr.success('User Updated Successfully', ' ',
        
        {
          timeOut: 3000,
          
        });
       
        // this.route.navigate(['/dashboard']);
      },
      (error) => {
        console.log(error);
        if (error?.message === API_ERROR.USER_LOGOUT) {
          this.commonService.logout(API_ERROR.USER_LOGOUT);
        } else {
          this.toastr.error('Personal details can\'t be updated the moment. Please try again.', ' ', {
            timeOut: 3000
          });
        }
      }
    ));

  }

  public checkImageSize(image) {
    if (!image.name.match(/.(jpg|jpeg|png)$/i) || image.size == 0) {
      return false;
    }
    const file = Math.round((image.size / 1024));
    if (file >= 2048) {
      this.toastr.error(API_ERROR.FILE_SIZE_ERROR, ' ', {
        timeOut: 3000
      });
      return false;
    } else {
      return true;
    }
  }

  fileChangeEvent(event: any) {
    const [file] = event.target.files;
    if (!this.validateFile(file.name)) {
      this.toastr.error(API_ERROR.FILE_NOT_SUPPORTED, ' ', {
        timeOut: 3000
      });
      return false;
    } else {
      if (event.target.files.length && this.checkImageSize(event.target.files[0]))
        this.imageChangedEvent = event;
    }
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    event.width = 200;
    this.profilePic = event.base64;
  }
  imageLoaded(image: HTMLImageElement) {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  ngOnDestroy(){
    this._sub.unsubscribe();
  }
}
