import { Component, OnInit, ChangeDetectionStrategy, ViewChild, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { AccessService } from '../../services/access.service';
import { ToastrService } from 'ngx-toastr';
import { API_ERROR } from 'src/app/core/constants/global-error.constants';
import { LEVELS } from 'src/app/core/constants/level.constants';
import { LIMIT } from 'src/app/core/constants/pagination.constants';
import { API_SUCCESS } from 'src/app/core/constants/global-success.constants';
import { CommonService } from 'src/app/core/services/common.service';
import { TreeviewItem, TreeviewConfig } from 'ngx-treeview';
import { EdgeService } from 'src/app/modules/edge-management/services/edge.service';
import { APIDefinition, Columns, Config, DefaultConfig } from 'ngx-easy-table';
import { NgScrollbar } from 'ngx-scrollbar';
import { AuthService } from 'src/app/core/auth/auth.service';
import { countries } from 'src/app/modules/dashboard/components/edit-profile/country';
import { NgxSpinnerService } from 'ngx-spinner';
import { ShipmentsService } from 'src/app/modules/shipments/services/shipments.service';

@Component({
  selector: 'app-add-new-user',
  templateUrl: './add-new-user.component.html',
  styleUrls: ['./add-new-user.component.css'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddNewUserComponent implements OnInit {
  @Input() userId: string;
  @Output() closeModalEvent:EventEmitter<boolean> = new EventEmitter<boolean>();

  passwordEye: boolean;
  confirmPasswordEye: boolean;
  loader: boolean;
  userData: any;
  config: { itemsPerPage: number; currentPage: number; totalItems: any; };
  editFlag = false;
  isReadOnly: boolean;
  accessData: any = [];
  userSmartContractData: any;
  // userId: any;
  userInfo;
  deleteUserData: any;
  accessMenu = { name: 'Access Control', submenu: {}, view: false, action: false };
  activeClass = 'active';
  selectaccess: any = '';
  /**multiselect dropdown property for logical group */
  logicalGrpList = [];
  selectedLogicalGrp = [];
  logicalGrpSettings = {};
  logical_grp_1_devices = [];
  /**multiselect dropdown property for blocklock group */
  blocklockGrpList = [];
  selectedblocklockGrp = [];
  blocklockGrpSettings = {};
  sendAlerts = false;
  sendReport = false;

  shipmentList = [];
  availableShipmentList = [];
  showAssignShipment: boolean = false;

  nextButtonFlag = true;
  saveButtonFlag: boolean;
  tabIdTag: string;

  // Treeview Dropdown
  dropdownEnabled = true;
  items: TreeviewItem[];
  values: number[];
  treeviewconfig = TreeviewConfig.create({
    hasAllCheckBox: true,
    hasFilter: true,
    hasCollapseExpand: true,
    decoupleChildFromParent: false,
    maxHeight: 200
  });
  blocklockData: any;
  blocklockitems: TreeviewItem[];
  userObj: any;
  fullname: any;
  email: any;
  address: any;
  phoneno: any;
  selectcountry: any = '';
  selectedShipments: any = [];
  selectedShipmentId: any = [];
  selectcountries: any = countries;

  // ngx table configuration
  public configuration: Config;
  public devicesAccesscolumns: Columns[];

  @ViewChild(NgScrollbar, { static: true }) scrollbarRef: NgScrollbar;
  @ViewChild('table', { static: true }) table: APIDefinition;
  currentUser: any;
  zip: any;
  city: any;
  state: any;
  country: any;
  fileName: string;
  profilePic: string;
  loaderFlag: boolean;
  officialEmail: any;

  userLevel = LEVELS;

  onFilterChange(value: string): void {
    // console.log('filter:', value);
  }

  constructor(private accessService: AccessService,
    private toastr: ToastrService, private commonService: CommonService,
    private edgeService: EdgeService,
    private shipmentsService: ShipmentsService,
    private cdr: ChangeDetectorRef,
    private authService: AuthService,
    private spinner: NgxSpinnerService
  ) {
    // this.route.params.subscribe(params =>
    //   this.userId = params.id
    // );
    const { user } = this.commonService.getCurrentUserData();
    this.userInfo = user;
    // if (this.userId) {
    //   this.getShipments();
    //   this.getLoginUserDetails();
    //   this.getUserDataById(this.userId);
    //   setTimeout(function () { $('#user_div').trigger('click'); }, 10);
    // }
  }

  ngOnInit(): void {   
     const { user } = this.commonService.getCurrentUserData();
    if (this.userId) {
      
      this.getUserDataById(this.userId);
      this.getAccessLevels();
      this.getShipments();
      // this.getLoginUserDetails();
      this.spinner.show();
      this.userInfo = user;
      // this.setupMultiselectDropdownForLogicalGroup();
      // this.setupMultiselectDropdownForBlocklockGroup();
      // this.toggleActiveClassOnLinksAndTabs();
      // this.getAllEdgeList();
      // this.getShipments();
        
        // Treeview Dropdown
        this.items = this.getBooks();
    
        // //ngx Table
        //  this.configuration.paginationEnabled = true;
        //  this.configuration.paginationMaxSize = 20;
        //  this.configuration.horizontalScroll = false;
        //  // Devices columns
        this.devicesAccesscolumns = [
          { key: 'deviceName', title: 'Device Name' },
          { key: 'macAddress', title: 'MAC Address' },
          { key: 'status', title: 'Status' },
          { key: 'access', title: 'Access' },
        ];
    }

  }

  /**
    * @description get login user data
    */
  getLoginUserDetails() {
    this.currentUser = this.commonService.getCurrentUserData();
    //this.fullname = this.currentUser.user.fullName;
    this.email = this.currentUser.user.officialEmail;
    // this.phoneno = this.currentUser.user.contactNumber;
    // this.address = this.currentUser.user.address;
    // this.userId = this.currentUser.user.id;
    // this.city = this.currentUser.user.city;
    // this.zip = this.currentUser.user.zipCode;
    // this.state = this.currentUser.user.state;
    // this.country = this.currentUser.user.country;
    const { accesslevel } = this.currentUser.user;
    if (accesslevel && accesslevel.permissions) {
      this.accessMenu = accesslevel.permissions['access-control'].submenu;
    }
  }

  getBooks(): TreeviewItem[] {
    const childrenCategory = new TreeviewItem({
      text: 'Children', value: 1, collapsed: true, children: [
        { text: 'Baby 3-5', value: 11 },
        { text: 'Baby 6-8', value: 12 },
        { text: 'Baby 9-12', value: 13 }
      ]
    });
    const itCategory = new TreeviewItem({
      text: 'IT', value: 9, children: [
        {
          text: 'Programming', value: 91, children: [{
            text: 'Frontend', value: 911, children: [
              { text: 'Angular 1', value: 9111 },
              { text: 'Angular 2', value: 9112 },
              { text: 'ReactJS', value: 9113, disabled: true }
            ]
          }, {
            text: 'Backend', value: 912, children: [
              { text: 'C#', value: 9121 },
              { text: 'Java', value: 9122 },
              { text: 'Python', value: 9123, checked: false, disabled: true }
            ]
          }]
        },
        {
          text: 'Networking', value: 92, children: [
            { text: 'Internet', value: 921 },
            { text: 'Security', value: 922 }
          ]
        }
      ]
    });
    const teenCategory = new TreeviewItem({
      text: 'Teen', value: 2, collapsed: true, disabled: true, children: [
        { text: 'Adventure', value: 21 },
        { text: 'Science', value: 22 }
      ]
    });
    const othersCategory = new TreeviewItem({ text: 'Others', value: 3, checked: false, disabled: true });
    return [childrenCategory, itCategory, teenCategory, othersCategory];
  }

  getShipments() {
    this.authService.getUserAssignedShipments(this.userId, this.userInfo.organizationName).subscribe((res:any) => {
      this.shipmentList = res.allShipments;
      this.showAssignShipment = true;
      this.selectedShipmentId = res.userShipments.map((id) => id._id);
      this.selectedShipments = res.userShipments;
      this.cdr.detectChanges();
    });
  }

  // assignShipment(event){
  //   if(event && event !== 'Select shipment to be assigned'){
  //     let index = this.shipmentList.findIndex((shipmentId) => shipmentId._id === event);
  //     this.selectedShipments.push(this.shipmentList[index]);
  //   this.shipmentList.splice(index,1);
  //   }
  // }

  // removeAssignedShipment(shipment) {
  //   let index = this.selectedShipments.findIndex((shipmentId) => shipmentId._id === shipment._id);
  //   this.selectedShipments.splice(index,1);
  //   this.shipmentList.push(shipment);
  // }

  toggleActiveClassOnLinksAndTabs() {
    let i;
    const items = $('.myclass');
    const pane = $('.my-tab-pane');
    // next
    $('.nexttab').on('click', () => {
      for (i = 0; i < items.length; i++) {
        if ($(items[i]).hasClass('active') == true) {
          break;
        }
      }
      if (i < items.length - 1) {
        // for tab
        $(items[i]).removeClass('active');
        $(items[i + 1]).addClass('active');
        // for pane
        $(pane[i]).removeClass('active');
        $(pane[i + 1]).addClass('active');
        if (pane[i + 1].id === 'b1-accessLevel') {
          $('.nexttab').css('display', 'none');
          this.saveButtonFlag = true;
        }
      }
    });
  }
  resetButton() {
    $('.nexttab').css('display', 'block');
    this.saveButtonFlag = false;
  }

  resetNextButton() {
    $('.nexttab').css('display', 'none');
    this.saveButtonFlag = true;
  }

  setupMultiselectDropdownForLogicalGroup() {
    this.logicalGrpList = [
      { 'id': 1, 'itemName': 'logical_grp_1' },
      { 'id': 2, 'itemName': 'logical_grp_2' },
      { 'id': 3, 'itemName': 'logical_grp_3' },
      { 'id': 4, 'itemName': 'logical_grp_4' },
      { 'id': 5, 'itemName': 'logical_grp_5' },
      { 'id': 6, 'itemName': 'logical_grp_6' }
    ];
    this.selectedLogicalGrp = [];
    this.logicalGrpSettings = {
      singleSelection: false,
      text: 'Select Logical Group',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: 'myclass custom-class',
    };
  }

  setupMultiselectDropdownForBlocklockGroup() {
    this.blocklockGrpList = [
      { 'id': 1, 'itemName': 'Edge Device 1' },
      { 'id': 2, 'itemName': 'Edge Device 2' },
      { 'id': 3, 'itemName': 'Edge Device 3' },
      { 'id': 4, 'itemName': 'Edge Device 4' },
      { 'id': 5, 'itemName': 'Edge Device 5' },
      { 'id': 6, 'itemName': 'Edge Device 6' }
    ];
    this.selectedblocklockGrp = [];
    this.blocklockGrpSettings = {
      singleSelection: false,
      text: 'Select Edge Device',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      classes: 'myclass custom-class',
    };
  }



  /**
   *
   * @param event
   * @description upload user profile image
   */
  onFileChanged(event) {
    const [file] = event.target.files;
    this.fileName = ''
    this.profilePic = '';
    if (file) {
      this.profilePic = file;
      this.fileName = file.name;
      if (!this.validateFile(file.name)) {
        this.toastr.error(API_ERROR.FILE_NOT_SUPPORTED, ' ', {
          timeOut: 3000
        });
        this.fileName = ''
        this.profilePic = '';
        return false;
      }
    }
  }


  /**
   * @description remove selected image
   */
  removeImage() {
    this.fileName = '';
    this.profilePic = '';
  }

  /**
   *
   * @param name
   * @description validate file name
   */
  validateFile(name: string) {
    const ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() == 'jpg' || ext.toLowerCase() == 'png') {
      return true;
    }
    else {
      return false;
    }
  }

  setupActiveClassOnTabs() {
    $('#policy').removeClass('active');
    $('#device-access').removeClass('active');
    $('#manage-rules').removeClass('active');
    $('#user').addClass('active');
    $('#a1-user').addClass('active');
    $('#d1-manageRule').removeClass('active');
    $('#c1-device').removeClass('active');
    $('#b1-accessLevel').removeClass('active');
    $('.nexttab').css('display', 'block');
    this.saveButtonFlag = false;
  }


  /**
   *
   * @param type
   * @description based on type toggle usertab flag
   */
  resetFlag(type) {
    if (type != 'manage-rules') {
      $('.nexttab').css('display', 'block');
      this.saveButtonFlag = false;
    } else {
      $('.nexttab').css('display', 'none');
      this.saveButtonFlag = true;
    }
  }

  /**
   * @description page change in pagination
   */
  pageChanged(event) {
    this.config.currentPage = event;
  }


  /**
   *
   * @param data
   * @description open delete modal
   */
  openDeleteModal(data) {
    this.deleteUserData = data;
  }


  /**
   * @description close new user modal popup and make  email field readonly flag false
   */
  closeAddNewUserModel() {
    this.isReadOnly = false;
    this.editFlag = false;
  }



  // /*toggle eye icon for password*/
  // toggelEyeButtonPassword() {
  //   if (this.f.password.value)
  //     this.passwordEye = !this.passwordEye;
  // }

  // /*toggle eye icon for confirmPassword*/
  // toggelEyeButtonConPassword() {
  //   if (this.f.confirmPassword.value)
  //     this.confirmPasswordEye = !this.confirmPasswordEye;
  // }

  // onItemSelect(item: any) {
  //   this.logical_grp_1_devices = [
  //     { id: '1', deviceName: 'Dell', macAddress: '12.23.12', status: 'active', access: 'View Access' },
  //     { id: '2', deviceName: 'intel', macAddress: '12.23.12', status: 'active', access: 'View Access' },
  //     { id: '3', deviceName: 'hp', macAddress: '12.23.12', status: 'active', access: 'View Access' },
  //   ]
  //   // console.log(item);
  //   // console.log(this.selectedItems);
  // }
  // OnItemDeSelect(item: any) {
  //   this.logical_grp_1_devices = [];
  //   // console.log(item);
  //   // console.log(this.selectedItems);
  // }
  // onSelectAll(items: any) {
  //   // this.selectedItems = items;
  //   // // this.newUserForm.controls['logicalGroup'].setValue(items);
  //   // console.log(items);
  // }
  // onDeSelectAll(items: any) {
  //   // this.selectedItems = items;
  //   // this.newUserForm.controls['logicalGroup'].setValue(items);
  //   // console.log(items);
  // }

  getAllEdgeList() {
    this.edgeService.getAllEdge().subscribe(
      ((res) => {
        // console.log('res', res);
        // let tempRes = res;
        this.blocklockData = res;
        const options = []
        for (let i = 0; i < this.blocklockData.length; i++) {
          options[i] = new TreeviewItem({ text: this.blocklockData[i].data.name, value: this.blocklockData[i].data.serialNumber, checked: true });
        }
        this.blocklockitems = options;
      }),
      (error => {
        console.log('error', error);
      })
    );
  }

  // selectFilterType(type) {
  //   console.log('e', type);
  //  if (type === 'blocklock') {
  //     // this.secondDropdownFilterType = 'Select Blocklock';
  //     var options = []
  //     for(var i=0 ; i < this.blocklockData.length ; i++){
  //       options[i] = new TreeviewItem({ text: this.blocklockData[i].data.name, value: this.blocklockData[i].data.serialNumber, checked: true});
  //     }
  //     this.items = options;

  //  } else {
  //    this.items = [];
  //  }
  //   // } else if (type === 'device_type') {
  //   //   this.secondDropdownFilterType = 'Select Devices';
  //   // } else if(type === 'broker_node') {
  //   //   this.secondDropdownFilterType = 'Select BrokerNode';
  //   // } else {
  //   //   this.secondDropdownFilterType = 'Please select filter type';
  //   // }
  // }

  selectedItems(value) {
    // console.log('filter:', value);
    // if(value.length == 1){
    // }
  }

  getAccessLevels() {
    this.loaderFlag = true;
    this.accessService.getAccessLevel().subscribe(
      (response) => {
        this.loaderFlag = false;
        this.accessData = response;
        this.cdr.detectChanges();
        if (this.accessData && this.accessData.length > 0)
          this.config = {
            itemsPerPage: LIMIT.PER_PAGE,
            currentPage: LIMIT.CURRENT_PAGE,
            totalItems: this.accessData.length
          }
      },
      (error) => {
        if (error?.message === API_ERROR.USER_LOGOUT) {
          this.commonService.logout(API_ERROR.USER_LOGOUT);
        }
        this.loaderFlag = false;
        this.spinner.hide();
        error
      }
    )
  }

  /**
  * @description get all user list
  */
  getUserData() {
    this.configuration = { ...DefaultConfig };
    this.configuration.isLoading = true;
    this.accessService.getUserManagement().subscribe(
      (response) => {
        this.userData = response;
        this.configuration.isLoading = false;
        this.userData = this.userData.data;
        if (this.userData && this.userData.length > 0)
          this.config = {
            itemsPerPage: LIMIT.PER_PAGE,
            currentPage: LIMIT.CURRENT_PAGE,
            totalItems: this.userData.length,
          };
        this.getAccessLevels();
      },

      (error) => {
        if (error?.message === API_ERROR.USER_LOGOUT) {
          this.commonService.logout(API_ERROR.USER_LOGOUT);
        }
        this.configuration.isLoading = false;
        error;
      }
    );
  }

  /**
   * @description save new user and update new user based on edit flag
  */
  addNewUser() {
    let body = {
      fullName: this.fullname,
      officialEmail: this.email,
      address: this.address,
      contactNumber: this.phoneno,
      city: this.city,
      state: this.state,
      zipCode: this.zip,
      country: this.selectcountry,
      userLevel: this.selectaccess,
      sendEmailAlerts: this.sendAlerts || false,
      sendDailyReport: this.sendReport || false,
    }
    if(this.selectedShipments.length){
      let ids = this.selectedShipments.map((shipment) => shipment._id);
      body['assignedShipments'] = ids;
    } else
      body['assignedShipments'] = [];

    this.isReadOnly = false;
    if (this.userId) {
      const updatedData = { ...body, id: this.userId };
      this.accessService.postUserManagement(updatedData).subscribe(
        (res) => {
          this.toastr.success(this.fullname + API_SUCCESS.UPDATED_SUCCESS, ' ');
          this.editFlag = false;
          this.closeModalEvent.emit(true);
          // this.router.navigate(['access-control', 'user-management']);
        },
        (error) => {
          if (error?.message === API_ERROR.USER_LOGOUT) {
            this.commonService.logout(API_ERROR.USER_LOGOUT);
          } else if (error.message == API_ERROR.CANTNOT_CREATE_SUPERADMIN_AGAIN) {
            this.toastr.error(API_ERROR.SUPERADMIN_ALREADY_EXISTS, '');
          } else if (error.message == API_ERROR.ACCESSLEVEL_SUPERADMIN_UPDATE_ERROR) {
            this.toastr.error(API_ERROR.ACCESSLEVEL_SUPERADMIN_UPDATE_ERROR, '')
          } else {
            this.toastr.error(API_ERROR.SOMETHING_WENT_WRONG, "");
          }
          this.editFlag = false;
        }
      )
    } else {
      this.accessService.postUserManagement(body).subscribe(
        (response) => {
          response
          this.toastr.success(this.fullname + API_SUCCESS.CREATED_SUCCESS, ' ',);
          this.editFlag = false;
          this.getUserData();
        },
        (error) => {
          this.editFlag = false;
          if (error?.message === API_ERROR.USER_LOGOUT) {
            this.commonService.logout(API_ERROR.USER_LOGOUT);
          } else if (error.message == API_ERROR.EMAIL_ALREADY_EXISTS) {
            this.toastr.error(API_ERROR.EMAIL_ALREADY_EXISTS, '');
          } else if (error.message == API_ERROR.CANTNOT_CREATE_SUPERADMIN_AGAIN) {
            this.toastr.error(API_ERROR.SUPERADMIN_ALREADY_EXISTS, '');
          } else if (error.message == API_ERROR.ACCESSLEVEL_SUPERADMIN_UPDATE_ERROR) {
            this.toastr.error(API_ERROR.ACCESSLEVEL_SUPERADMIN_UPDATE_ERROR, '')
          }
          else {
            this.toastr.error(API_ERROR.SOMETHING_WENT_WRONG, "");
          }
        }
      )
    }
  }

  getUserDataById(userId) {
    let user = {
      emailId: this.email,
      id: userId
    }
    this.authService.getUserById(user).subscribe(
      (res) => {
        this.fullname = res.fullName || '';
        this.email = res.officialEmail || '';
        this.phoneno = res.contactNumber || '';
        this.address = res.address || '';
        // this.userId = res.userId || '';
        this.city = res.city || '';
        this.zip = res.zipCode || '';
        this.state = res.state || '';
        this.selectcountry = res.country || '';
        this.selectaccess = res.userLevel || '';
        this.selectedShipmentId = res.assignedShipments || '';
        this.sendAlerts = res.sendEmailAlerts || false;
        this.sendReport = res.sendDailyReport || false;
        const accesslevel = res.userLevel;
        if (accesslevel && accesslevel.permissions) {
          this.accessMenu = accesslevel.permissions['access-control'].submenu;
        }
        this.cdr.detectChanges();
        this.spinner.hide();
      },
      (error) => {
        if (error?.message === API_ERROR.USER_LOGOUT) {
          this.commonService.logout(API_ERROR.USER_LOGOUT);
        }
      }
    )

  }

}
