import { Component, OnInit, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { API_ERROR } from 'src/app/core/constants/global-error.constants';
import { CommonService } from 'src/app/core/services/common.service';
import { DashboardService } from 'src/app/modules/dashboard/services/dashboard.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit, OnDestroy {
  UIreleaseVersion: {commitID:string, branchName:string, TagName:string};
  blReleaseVersion: any;
  tagNameUI: string;
  tagNameBL: string;
  user: any;
  copyrightYear: number = new Date().getFullYear(); 

  private _sub: Subscription = new Subscription();

  constructor(private dashboardService:DashboardService,
    private commonService: CommonService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    const { user } = this.commonService.getCurrentUserData();
    if (user) {
      this.user = user;
      // this.getBlReleaseVersion();
      this.getUIReleaseVersion();
    }
  }

  /**
   * Description: get ui release version
   * @description get ui release version
   */
  getUIReleaseVersion() {
    this._sub.add(this.dashboardService.getUIReleaseVersion(this.user).subscribe(res => {
      this.UIreleaseVersion = res as any;
      this.tagNameUI = this.UIreleaseVersion?.TagName;
    }, error => {
        console.log(error);
        if (error?.message === API_ERROR.USER_LOGOUT) {
          this.commonService.logout(API_ERROR.USER_LOGOUT);
        } else {
          this.toastr.error(error.message, 'Error', {
            timeOut: 3000
          });
        }
    }));
  }

  /**
   * Description: get blocklock release version
   * @description get blocklock release version
   */
  getBlReleaseVersion() {
    this._sub.add(this.dashboardService.getBLReleaseVersion(this.user).subscribe(res => {
      this.blReleaseVersion = res as any;
      this.tagNameBL = this.blReleaseVersion?.TagName ? this.blReleaseVersion?.TagName : this.blReleaseVersion?.Tag
    }, error => {
        console.log(error);
        if (error?.message === API_ERROR.USER_LOGOUT) {
          this.commonService.logout(API_ERROR.USER_LOGOUT);
        } else {
          this.toastr.error(error.message, 'Error', {
            timeOut: 3000
          });
        }
    }));
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }
}
