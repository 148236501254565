import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgScrollbar } from 'ngx-scrollbar';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from 'primeng/api';
import { API_ERROR } from 'src/app/core/constants/global-error.constants';
import { CommonService } from 'src/app/core/services/common.service';
import { ISmartContract } from '../../models/smart-contract.model';
import { SmartContractsManagementService } from './services/smart-contracts-management.service';

@Component({
  selector: 'app-smart-contracts-management',
  templateUrl: './smart-contracts-management.component.html',
  styleUrls: ['./smart-contracts-management.component.scss']
})
export class SmartContractsManagementComponent implements OnInit {

  @ViewChild(NgScrollbar, { static: true }) scrollbarRef: NgScrollbar;

  public actionType = [
    { label: 'After create', value: 'afterCreate', isSelected: false },
    { label: 'After update', value: 'afterUpdate', isSelected: false },
    { label: 'Before create', value: 'beforeCreate', isSelected: false },
    { label: 'Before update', value: 'beforeUpdate', isSelected: false }
  ]
  public chainType = [
    { label: 'Communication', value: 'communication_chain' },
    { label: 'Edge', value: 'edge_chain' },
    { label: 'Identity', value: 'identity_chain' },
    { label: 'User', value: 'user_chain' }
  ]
  public templateType = [
    { label: 'Template DB', value: 'db'},
    { label: 'Template Email', value: 'email'}
  ]
  public contractForm: FormGroup;
  public isopenSearch = false;
  public status = true;
  public selectedContractId = '';
  public showPanel: boolean
  public smartContracts: ISmartContract[];

  get formData(): FormArray { return this.contractForm.get('rules') as FormArray; }

  constructor(
    private confirmationService: ConfirmationService,
    private fb: FormBuilder,
    private smartContractService: SmartContractsManagementService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private commonService: CommonService
  ) { }

  public ngOnInit(): void {
    this.getSmartContracts();
  }

  public addAndUpdateSmartContract(): void {
    let reqObj = {};
    let obj = this.contractForm.value;
    reqObj['smartContractNm'] = obj.contractName || '';
    reqObj['smartContractDesc'] = obj.description || '';
    reqObj['isEnableRules'] = obj.isEnableRules;
    let isInvalidTemplate = false;
    if (obj.rules && obj.rules.length) {
      obj.rules.map((e) => {
        if (e.templateType === 'email') {
          let templateRule = null;
          try {
            templateRule = JSON.parse(e.rule);
            const objKeys = Object.keys(templateRule);
            const validKey = ['to', 'subject', 'message'];
            validKey.map(k => {
              if (!objKeys.includes(k)) {
                this.toastr.error(`"${k}" is mandatory in this template.`, '', { timeOut: 3000 });
                isInvalidTemplate = true;
              }
            })
          } catch (err) {
            if (err?.message === API_ERROR.USER_LOGOUT) {
              this.commonService.logout(API_ERROR.USER_LOGOUT)
            } else {
              this.toastr.error(`use string delimeter("")`, '', { timeOut: 3000 });
            }
          }
          templateRule['type'] = e.templateType;
          reqObj[e.chainType] = { ...reqObj[e.chainType], [e.actionType]: JSON.stringify(templateRule), ['isEnable' + e.actionType]: e.isEnable };
        } else {
          reqObj[e.chainType] = { ...reqObj[e.chainType], [e.actionType]: e.rule, ['isEnable' + e.actionType]: e.isEnable };
        }
      })
    }
    if (!isInvalidTemplate) {
      const smContract = this.selectedContractId
        ? this.smartContractService.updateSmartContract(reqObj, this.selectedContractId)
        : this.smartContractService.addSmartContract(reqObj);
      smContract.subscribe((res: ISmartContract) => {
        this.getSmartContracts()
        this.toastr.success('Smart contract added Successfully', '', { timeOut: 3000 });
        this.showPanel = false;
        this.contractForm.reset();
      }, error => {
        if (error?.message === API_ERROR.USER_LOGOUT) {
          this.commonService.logout(API_ERROR.USER_LOGOUT)
        } else {
          this.toastr.error(error?.message ? error?.message : 'Failed to removed smart contract, please try again', '', { timeOut: 3000 });
        }
      })
    }

  }

  public addNewContract(selectedContract?: ISmartContract): void {
    this.contractForm = this.fb.group({
      'contractName': [selectedContract && selectedContract.smartContractNm ? selectedContract.smartContractNm : '', Validators.required],
      'description': [selectedContract && selectedContract.smartContractNm ? selectedContract.smartContractNm : '', Validators.required],
      'isEnableRules': [selectedContract && selectedContract.hasOwnProperty('isEnableRules') ? selectedContract.isEnableRules : true , Validators.required],
      'rules': this.fb.array([])
    })

    if(selectedContract) {
      const objKey = Object.keys(selectedContract);
      this.chainType.map((t) => {
        if (objKey.find((e) => e === t.value)) {
          const obj = selectedContract[t.value];
          const subObjKey = Object.keys(obj);
          this.actionType.map((x) => {
            if (subObjKey.find(y => y === x.value)) {
              let data = null;
              try {
                data = JSON.parse(selectedContract[t.value][x.value]);
              } catch {
                data = null;
              }
              (this.contractForm.get('rules') as FormArray).push(
                new FormGroup({
                  chainType: new FormControl(t.value, Validators.required),
                  actionType: new FormControl(x.value),
                  templateType: new FormControl(data && data.type ? data.type : ''),
                  rule: new FormControl(selectedContract[t.value][x.value]),
                  isEnable: new FormControl(selectedContract[t.value]['isEnable' + x.value], Validators.required),
                }))
            }
          });
        } 
      })
    } else {
      this.addRules();
    }

    this.disableSelectedAction();
    this.selectedContractId = selectedContract && selectedContract.id ? selectedContract.id : '';
    this.showPanel = true;
  }

  public addRules(): void {
    (this.contractForm.get('rules') as FormArray).push(
      new FormGroup({
        chainType: new FormControl('', Validators.required),
        actionType: new FormControl({ value: '', disabled: true }, Validators.required),
        templateType: new FormControl(''),
        rule: new FormControl('', Validators.required),
        isEnable: new FormControl(true, Validators.required),
    }));
  }

  public closePanel(): void {
    this.showPanel = false;
    this.selectedContractId = '';
    this.contractForm.reset();
  }

  public disableSelectedActionStaus(index: number): void {
    if ((<FormArray>this.contractForm.get('rules')).at(index).get('chainType').value){
      (<FormArray>this.contractForm.get('rules')).at(index).get('actionType').enable();
    }
    this.disableSelectedAction(index);
  }

  public disableSelectedAction(index?: number): void {
    const rulesArr = (<FormArray>this.contractForm.get('rules')).value;
    if (index || index === 0 ) {
      const filterRules = rulesArr.filter(r => r.chainType === (<FormArray>this.contractForm.get('rules')).at(index).get('chainType').value)
      this.actionType.map((ev) => {
        if (filterRules.find(r => r.actionType === ev.value)) {
          ev.isSelected = true;
        } else {
          ev.isSelected = false
        }
      })
    } else {
      this.actionType.map((ev) => {
        if (rulesArr.find(r => r.actionType === ev.value)) {
          ev.isSelected = true;
        } else {
          ev.isSelected = false
        }
      })
    }
  }

  public opendeleteModal(event): void {
    this.confirmationService.confirm({
      message: `Delete this smart contract <p class="font-weight-bold">${event.contractName}</p>`,
      header: `Delete Smart Contract`,
      acceptButtonStyleClass: 'p-button-outlined p-button-danger',
      rejectButtonStyleClass: 'p-button-outlined p-button-secondary',
      accept: () => {
        this.removingContract(event)
      }
    });
  }

  public removeRules(index: number): void {
    (<FormArray>this.contractForm.get('rules')).removeAt(index);
    this.disableSelectedAction();
  }

  public selectTemplate(index: number): void {
    const template = (<FormArray>this.contractForm.get('rules')).at(index).get('templateType').value;
    let emailTemplate = template === 'email' ? { "to": "userEmail", "subject": "Ritualistik User Manual", "message": "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Exercitationem nam odio neque harum. Tempora quaerat provident impedit sed dolores sapiente nobis veniam officia quibusdam nihil deserunt saepe, similique aut itaque." } : '';
    (<FormArray>this.contractForm.get('rules')).at(index).get('rule').setValue(emailTemplate ? JSON.stringify(emailTemplate) : '');
  }

  private getSmartContracts(): void {
    this.spinner.show();
    this.smartContractService.getSmartContracts().subscribe((res: any) => {
      this.smartContracts = res.items;
      setTimeout(() => { this.spinner.hide(); }, 100);
    }, error => {
      this.spinner.hide();
      if (error?.message === API_ERROR.USER_LOGOUT) {
        this.commonService.logout(API_ERROR.USER_LOGOUT);
      } else {
        this.toastr.error(error?.message ? error?.message : 'Unable to fetch smart contracts, please try again', '', { timeOut: 3000 });
      }
    })
  }

  private removingContract(event: ISmartContract): void {
    this.smartContractService.removeSmartContract(event.id).subscribe((res: ISmartContract) => {
      this.toastr.success('Remove smart contract Successfully', '', { timeOut: 3000 });
      this.getSmartContracts()
    }, error => {
      if (error?.message === API_ERROR.USER_LOGOUT) {
        this.commonService.logout(API_ERROR.USER_LOGOUT)
      } else {
        this.toastr.error(error?.message ? error?.message : 'Failed to removed smart contract, please try again', '', { timeOut: 3000 });
      }
    })
  }
}
