<div class="form-card row px-2 pl-4 pr-4 w-100">
  <div class="mt-4 pt-2 text-center">
    <img
    src="../../../../../assets/images/IMPACT_by_honeywell-Logo.png"
    class="pr-1 img-fluid"
    />
  </div>
  <form
    class="form-card row px-2"
    [formGroup]="userSignupForm"
    (ngSubmit)="userSignUp()"
  >
    <ng-container *ngIf="!submitted">
      <div class="col-md-12 mt-3">
        <p class="lead" style="font-size: 16px">Register with us</p>
      </div>

      <div class="form-group col-md-12 mb-3 mt-1">
        <span class="mb-0" style="font-size: 11px"><b>Full Name</b></span>
        <label class="has-float-label">
          <input
            class="form-control"
            formControlName="fullName"
            id="fname"
            type="text"
            placeholder="Full Name"
          />
          <!-- <span>Full Name</span> -->
        </label>
      </div>

      <div class="form-group col-md-12 mb-3 passwField">
        <span class="mb-0" style="font-size: 11px"><b>Email Id</b></span>
        <label class="has-float-label">
          <input
            type="email"
            class="form-control"
            formControlName="officialEmail"
            placeholder="Email ID"
            autocomplete="off"
          />
          <!-- <span>Email ID</span> -->
        </label>
      </div>

      <div class="row pl-3 pr-3">
        <div class="form-group col-md-6 passwField">
          <span class="mb-0" style="font-size: 11px"><b>Set Password</b></span>
          <label class="has-float-label">
            <input
              [type]="passwordEye ? 'text' : 'password'"
              class="form-control"
              formControlName="password"
              placeholder="Set Password"
              autocomplete="off"
            />
            <!-- <span>Set Password</span> -->
          </label>
          <i
            class="fa fa-eye password-field-icon"
            [ngClass]="{ 'fa-eye-slash': !passwordEye, 'fa-eye': passwordEye }"
            (click)="toggelEyeButtonPassword()"
          ></i>
        </div>
        <div class="form-group col-md-6 passwField">
          <span class="mb-0" style="font-size: 11px"
            ><b>Confirm Password</b></span
          >
          <label class="has-float-label">
            <input
              [type]="confirmPasswordEye ? 'text' : 'password'"
              class="form-control"
              formControlName="confirmPassword"
              placeholder="Confirm Password"
            />
            <!-- <span>Confirm Password</span> -->
          </label>
          <i
            class="fa fa-eye password-field-icon"
            [ngClass]="{
              'fa-eye-slash': !confirmPasswordEye,
              'fa-eye': confirmPasswordEye
            }"
            (click)="toggelEyeButtonConPassword()"
          ></i>
        </div>
      </div>
      <!-- 
  <div class="form-group col-md-12 mb-3">
    <span class="small">Note: Use 8 or more characters with a mix of letters, numbers & symbols.</span>
 </div> -->

      <!-- Organization name will come pre-populate here -->
      <div class="form-group col-md-12 mb-3">
        <span class="mb-0" style="font-size: 11px"><b>Organization Name</b></span>
        <label class="has-float-label">
          <input
            type="text"
            class="form-control"
            readonly
            formControlName="organizationName"
            placeholder="Organization Name"
          />
        </label>
        <!-- <label for="">Organization Name</label> -->
      </div>
      <!-- <div class="form-group col-md-6 mb-3">
        <span class="mb-0" style="font-size: 11px"><b>Contact Number</b></span>
        <label class="has-float-label">
          <input
            type="number"
            onKeyPress="if(this.value.length==10) return false;"
            class="form-control"
            formControlName="contactNumber"
            placeholder="Contact Number"
          />
          <span>Contact Number</span>
        </label>
      </div> -->

      <!-- <div class="form-group col-md-12 mb-3">
        <span class="mb-0" style="font-size: 11px"><b>Address</b></span>
        <label class="has-float-label">
          <input
            type="text"
            class="form-control"
            formControlName="address"
            placeholder="Address"
          />
          <span>Address</span>
        </label>
      </div> -->

      <!-- <div class="row pl-3 pr-3">
        <div class="form-group col-md-6 mb-3">
          <span class="mb-0" style="font-size: 11px"><b>City</b></span>
          <label class="has-float-label">
            <input
              type="text"
              class="form-control"
              formControlName="city"
              placeholder="City"
            />
            <span>City</span>
          </label>
        </div>

        <div class="form-group col-md-6 mb-3">
          <span class="mb-0" style="font-size: 11px"><b>State</b></span>
          <label class="has-float-label">
            <input
              type="text"
              class="form-control"
              formControlName="state"
              placeholder="State"
            />
            <span>State</span>
          </label>
        </div>
      </div> -->

      <!-- <div class="form-group col-md-6 mb-3">
    <label class="has-float-label">
    <input type="text" class="form-control" formControlName="country" placeholder="Country" >
    <span>Country</span>
    </label>
  </div> -->
      <!-- <div class="row pl-3 pr-3">
        <div class="form-group col-md-6 mb-3">
          <span class="mb-0" style="font-size: 11px"><b>Country</b></span>
          <label class="has-float-label">
            <select
              class="form-control"
              name="country"
              formControlName="country"
            >
              <option value="" selected>Select Country</option>
              <option
                *ngFor="let country of selectcountries"
                [ngValue]="country.name"
              >
                {{ country.name }}
              </option>
            </select>
            <span>Select Country</span>
          </label>
        </div>

        <div class="form-group col-md-6 mb-3">
          <span class="mb-0" style="font-size: 11px"><b>Zip</b></span>
          <label class="has-float-label">
            <input
              type="text"
              class="form-control"
              formControlName="zipCode"
              placeholder="Zip"
            />
            <span>Zip</span>
          </label>
        </div>
      </div> -->

      <div class="col-md-12 mb-2">
        <div class="custom-control custom-checkbox">
          <input
            type="checkbox"
            class="custom-control-input"
            formControlName="privacyPolicyTC"
            id="termsconditions"
            name=""
          />
          <label
            class="font-weight-normal custom-control-label fsize13"
            (click)="toggelPrivacyPolicyTC()"
            for="termsconditions"
          >
            By creating this account you agree to Honeywell's
            <a href="javascript:void(0)" class="text-primary">privacy policy</a>
            and
            <a href="javascript:void(0)" class="text-primary">terms </a></label
          >
        </div>
      </div>

      <div class="col-md-12 mb-4 mt-3 text-center">
        <button
          type="submit"
          class="btn bg-gradient-primary text-uppercase w-75"
          [disabled]="!userSignupForm.valid || loading || isInvalid"
        >
          <span
            *ngIf="loading"
            class="spinner-border spinner-border-sm mr-1"
          ></span>
          Create Account
        </button>
      </div>

      <!-- <div class="col-md-12 text-center mt-2 mb-2">
        <p class="fsize13 mb-0">
          Already have an account?
          <a href="#" routerLink="/login" class="text-primary">Login here</a>
        </p>
      </div> -->
    </ng-container>
  </form>

    <div class="col-md-12" [ngStyle]="{ display: displayShipment }">
      <h5 class="mb-4 mt-2">Assign shipment to the User</h5>
      <b>Available Shipments</b>
      <div [formGroup]="shipmentForm" *ngIf="shipmentList.length">
        <div class="form-group pb-2">
          <p-dropdown
            *ngIf="shipmentList.length"
            (onChange)="addAssignedShipment($event.value)"
            [options]="shipmentList"
            formControlName="shipment"
            placeholder="Select a shipment"
            optionLabel="shipmentName"
          ></p-dropdown>
        </div>
      </div>
      <div *ngIf="!shipmentList.length" class="pb-2 ml-2">
        No shipments available.
      </div>
      <div *ngIf="selectedShipments.length">
        <b>Assigned Shipments</b>
        <ul class="mt-1">
          <li *ngFor="let shipment of selectedShipments">
            <h6>{{shipment.shipmentName}}
            <button class="btn editable-table-btn btn-sm icn-btn"
            title="Delete" (click)="removeAssignedShipment(shipment); shipmentForm.reset()">
            <i class="fas fa-trash-alt text-danger"></i>
          </button></h6>
          </li>
        </ul>
      </div>
      <div class="d-flex justify-content-between align-item-center mt-3 mb-4">
        <a class="align-self-center border-bottom border-primary" href="javascript:void()" (click)="skipAssignShipment()">Skip for now</a>
        <button class="mt-2 mb-2 btn btn-primary" [disabled]="!selectedShipments.length" (click)="addShipment()">Assign Shipment</button>
      </div>
    </div>

    <!------------------ account setup successfully message --------------------->
    <div
      class="col-md-12 pb-2 text-center"
      [ngStyle]="{ display: display }"
    >
      <h5 class="mb-4 mt-2">Your account has been successfully created.</h5>
      <img
        src="assets/images/icons/check.png"
        class="text-center img-fluid"
        width="80"
      />
      <!-- <p class="mt-3 mb-4">
        For security reasons, please login again with your credentials to access
        the Tenacious application.
      </p> -->
      <p class="col-md-12 mb-4 -mt-2">
        <button class="mt-3 btn btn-primary" (click)="resetFormToAddMore()">Add more user</button>
      </p>
    </div>
    <div class="col-md-12 mb-4 -mt-2 text-center">
      <a class="border-bottom border-primary" href="javascript:void()" routerLink="/dashboard">Go Back to
        Dashboard</a>
    </div>
  </div>
