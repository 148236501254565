<!-- Main content -->
<div class="row" id="report_shipment">
  <div class="col-12">
    <section class="content mt-4 mx-3">
      <section class="content">
        <!-- Default box -->
        <div class="card no-border">
          <div class="card-body p-0">
            <div class="row">
              <div
                class="col-md-12 bg-white p-3 pb-1 tab-content cus-rounded-left cus-rounded-right"
              >
                <div class="tab-pane container-fluid active" id="a">
                  <div class="row clearfix mb-3">
                    <h6
                      class="mb-0 font-weight-bold pageTitle text-uppercase col-md-8"
                    >
                      Shipment Report
                    </h6>
                  </div>
                  <div class="row clearfix" id="widgets">
                    <div
                      id="name"
                      class="d-flex align-items-stretch"
                      [ngClass]="{
                        'col-lg-2 col-md-2 col-sm-2':
                          trackerData[0]?.events[0]?.parsedData?.signal,
                        'col-lg-3 col-md-3 col-sm-3': !trackerData[0]?.events[0]
                          ?.parsedData?.signal
                      }"
                    >
                      <div class="card number-chart text-center">
                        <div class="body pt-2 px-0 pb-0 h-100">
                          <div class="number h-100">
                            <h6
                              class="mb-2 font-weight-bold font-size13 text-capitalize"
                            >
                              <a>Shipment Name</a>
                            </h6>
                            <h5
                              *ngIf="shipmentData; else loading"
                              (click)="navigateTo()"
                              class="cursor-pointer"
                            >
                              <i class="fas fa-pencil-alt text-primary"></i>
                              {{ shipmentData?.shipmentName }}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="type"
                      class="col-lg-2 col-md-2 col-sm-2 d-flex align-items-stretch"
                    >
                      <div class="card number-chart text-center">
                        <div class="body pt-2 px-0 pb-0 h-100">
                          <div class="number h-100">
                            <h6
                              class="mb-2 font-weight-bold font-size13 text-capitalize"
                            >
                              <a>Shipment Type</a>
                            </h6>
                            <h5 *ngIf="shipmentData; else loading">
                              {{ shipmentData?.shipmentType }}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      id="uuid"
                      class="d-flex align-items-stretch"
                      [ngClass]="{
                        'col-lg-2 col-md-2 col-sm-2':
                          trackerData[0]?.events[0]?.parsedData?.signal,
                        'col-lg-3 col-md-3 col-sm-3': !trackerData[0]?.events[0]
                          ?.parsedData?.signal
                      }"
                    >
                      <div class="card number-chart">
                        <div class="body pt-2 px-0 pb-0 h-100 text-center">
                          <div class="number h-100">
                            <h6
                              class="mb-2 font-weight-bold font-size13 text-capitalize"
                            >
                              <a
                                >{{ constantVariables.ENDPOINT.LABEL1 }} UUID</a
                              >
                            </h6>
                            <h5
                              *ngIf="
                                trackerData[0]?.trackers?.length;
                                else emptyuuid
                              "
                            >
                              {{ trackerData[0].trackers[0]?.data.deviceUUID }}
                            </h5>
                            <ng-template #emptyuuid>
                              <div class="font-weight-bold">-</div>
                            </ng-template>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="battery"
                      class="col-lg-2 col-md-2 col-sm-2 d-flex align-items-stretch"
                    >
                      <div class="card number-chart">
                        <div class="body pt-2 px-0 pb-0 h-100 text-center">
                          <div class="number h-100">
                            <h6
                              class="mb-2 font-weight-bold font-size13 text-capitalize"
                            >
                              <a>Battery</a>
                            </h6>
                            <h5
                              *ngIf="
                                !shipmentData?.events?.length;
                                else batteryStatus
                              "
                            >
                              N/A
                            </h5>
                            <ng-template #batteryStatus>
                              <h5 *ngIf="trackerData.length; else emptyBattery">
                                {{
                                  calculateBatteryPercentage(
                                    trackerData[0]?.events[0]?.parsedData?.battery.split(
                                      "V"
                                    )[0]
                                  ) + "%"
                                }}
                                <i
                                  class="ml-2 fas widget-icon"
                                  [ngClass]="
                                    calculateBatteryPercentage(
                                      trackerData[0].events[0]?.parsedData?.battery.split('V')[0], true
                                    )"
                                ></i>
                              </h5>
                            </ng-template>
                            <ng-template #emptyBattery>
                              <div class="font-weight-bold">
                                0%
                                <i
                                  class="ml-2 fas widget-icon fa-battery-quarter text-danger"
                                ></i>
                              </div>
                            </ng-template>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      id="signal"
                      *ngIf="trackerData[0]?.events[0]?.parsedData?.signal"
                      class="col-lg-2 col-md-2 col-sm-2 d-flex align-items-stretch"
                    >
                      <div class="card number-chart">
                        <div class="body pt-2 px-0 pb-0 h-100 text-center">
                          <div class="number h-100">
                            <h6
                              class="mb-2 font-weight-bold font-size13 text-capitalize"
                            >
                              <a> Signal</a>
                            </h6>
                            <div class="d-flex justify-content-center">
                              <h5>
                                {{
                                  trackerData[0]?.events[0]?.parsedData?.signal
                                }}
                              </h5>
                              <div>
                                <svg
                                  *ngIf="
                                    trackerData[0]?.events[0]?.parsedData
                                      ?.signal === 0
                                  "
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  fill="currentColor"
                                  class="bi bi-wifi-off m-0 p-0 pb-1"
                                  viewBox="2 4 10 12"
                                >
                                  <path
                                    d="M10.706 3.294A12.545 12.545 0 0 0 8 3C5.259 3 2.723 3.882.663 5.379a.485.485 0 0 0-.048.736.518.518 0 0 0 .668.05A11.448 11.448 0 0 1 8 4c.63 0 1.249.05 1.852.148l.854-.854zM8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065 8.448 8.448 0 0 1 3.51-1.27L8 6zm2.596 1.404.785-.785c.63.24 1.227.545 1.785.907a.482.482 0 0 1 .063.745.525.525 0 0 1-.652.065 8.462 8.462 0 0 0-1.98-.932zM8 10l.933-.933a6.455 6.455 0 0 1 2.013.637c.285.145.326.524.1.75l-.015.015a.532.532 0 0 1-.611.09A5.478 5.478 0 0 0 8 10zm4.905-4.905.747-.747c.59.3 1.153.645 1.685 1.03a.485.485 0 0 1 .047.737.518.518 0 0 1-.668.05 11.493 11.493 0 0 0-1.811-1.07zM9.02 11.78c.238.14.236.464.04.66l-.707.706a.5.5 0 0 1-.707 0l-.707-.707c-.195-.195-.197-.518.04-.66A1.99 1.99 0 0 1 8 11.5c.374 0 .723.102 1.021.28zm4.355-9.905a.53.53 0 0 1 .75.75l-10.75 10.75a.53.53 0 0 1-.75-.75l10.75-10.75z"
                                  />
                                </svg>
                                <svg
                                  *ngIf="
                                    trackerData[0]?.events[0]?.parsedData
                                      ?.signal === 1
                                  "
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  fill="currentColor"
                                  class="bi bi-wifi-1 m-0 p-0 pb-1"
                                  viewBox="2 4 10 12"
                                >
                                  <path
                                    d="M11.046 10.454c.226-.226.185-.605-.1-.75A6.473 6.473 0 0 0 8 9c-1.06 0-2.062.254-2.946.704-.285.145-.326.524-.1.75l.015.015c.16.16.407.19.611.09A5.478 5.478 0 0 1 8 10c.868 0 1.69.201 2.42.56.203.1.45.07.611-.091l.015-.015zM9.06 12.44c.196-.196.198-.52-.04-.66A1.99 1.99 0 0 0 8 11.5a1.99 1.99 0 0 0-1.02.28c-.238.14-.236.464-.04.66l.706.706a.5.5 0 0 0 .707 0l.708-.707z"
                                  />
                                </svg>
                                <svg
                                  *ngIf="
                                    trackerData[0]?.events[0]?.parsedData
                                      ?.signal > 1 &&
                                    trackerData[0]?.events[0]?.parsedData
                                      ?.signal < 31
                                  "
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  fill="currentColor"
                                  class="bi bi-wifi-2 m-0 p-0 pb-1"
                                  viewBox="2 4 10 12"
                                >
                                  <path
                                    d="M13.229 8.271c.216-.216.194-.578-.063-.745A9.456 9.456 0 0 0 8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065A8.46 8.46 0 0 1 8 7a8.46 8.46 0 0 1 4.577 1.336c.205.132.48.108.652-.065zm-2.183 2.183c.226-.226.185-.605-.1-.75A6.473 6.473 0 0 0 8 9c-1.06 0-2.062.254-2.946.704-.285.145-.326.524-.1.75l.015.015c.16.16.408.19.611.09A5.478 5.478 0 0 1 8 10c.868 0 1.69.201 2.42.56.203.1.45.07.611-.091l.015-.015zM9.06 12.44c.196-.196.198-.52-.04-.66A1.99 1.99 0 0 0 8 11.5a1.99 1.99 0 0 0-1.02.28c-.238.14-.236.464-.04.66l.706.706a.5.5 0 0 0 .708 0l.707-.707z"
                                  />
                                </svg>
                                <svg
                                  *ngIf="
                                    trackerData[0]?.events[0]?.parsedData
                                      ?.signal > 30 &&
                                    trackerData[0]?.events[0]?.parsedData
                                      ?.signal < 52
                                  "
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  fill="currentColor"
                                  class="bi bi-wifi m-0 p-0 pb-1"
                                  viewBox="2 4 10 12"
                                >
                                  <path
                                    d="M15.384 6.115a.485.485 0 0 0-.047-.736A12.444 12.444 0 0 0 8 3C5.259 3 2.723 3.882.663 5.379a.485.485 0 0 0-.048.736.518.518 0 0 0 .668.05A11.448 11.448 0 0 1 8 4c2.507 0 4.827.802 6.716 2.164.205.148.49.13.668-.049z"
                                  />
                                  <path
                                    d="M13.229 8.271a.482.482 0 0 0-.063-.745A9.455 9.455 0 0 0 8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065A8.46 8.46 0 0 1 8 7a8.46 8.46 0 0 1 4.576 1.336c.206.132.48.108.653-.065zm-2.183 2.183c.226-.226.185-.605-.1-.75A6.473 6.473 0 0 0 8 9c-1.06 0-2.062.254-2.946.704-.285.145-.326.524-.1.75l.015.015c.16.16.407.19.611.09A5.478 5.478 0 0 1 8 10c.868 0 1.69.201 2.42.56.203.1.45.07.61-.091l.016-.015zM9.06 12.44c.196-.196.198-.52-.04-.66A1.99 1.99 0 0 0 8 11.5a1.99 1.99 0 0 0-1.02.28c-.238.14-.236.464-.04.66l.706.706a.5.5 0 0 0 .707 0l.707-.707z"
                                  />
                                </svg>
                                <svg
                                  *ngIf="
                                    trackerData[0]?.events[0]?.parsedData
                                      ?.signal === 99
                                  "
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  fill="currentColor"
                                  class="bi bi-reception-0 m-0 p-0 pb-1"
                                  viewBox="2 4 10 12"
                                >
                                  <path
                                    d="M0 13.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm4 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm4 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm4 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="total_events"
                      class="col-lg-2 col-md-2 col-sm-2 d-flex align-items-stretch"
                    >
                      <div class="card number-chart">
                        <div class="body pt-2 px-0 pb-0 h-100 text-center">
                          <div class="number h-100">
                            <h6
                              class="mb-2 font-weight-bold font-size13 text-capitalize"
                            >
                              <a> Total Events</a>
                            </h6>
                            <div>
                              <h5
                                *ngIf="
                                  !shipmentData?.events?.length;
                                  else trackerStatus
                                "
                              >
                                N/A
                              </h5>
                              <ng-template #trackerStatus>
                                <h5 class="margin-btm10">
                                  {{ trackerData[0]?.events?.length }}
                                </h5>
                              </ng-template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div
                      *ngIf="shipmentData?.events?.length; else loading"
                      class="col"
                      id="event_log"
                    >
                      <div
                        class="card shadow rounded-xl mb-4 graphsCard-loader event-logs"
                      >
                        <h6
                          class="m-0 pt-3 pl-3 font-weight-bold float-left font-size13 text-capitalize"
                        >
                          Event Log
                        </h6>
                        <!-- <div class="card-header p-2 pb-0 clearfix"> -->
                        <!-- </div> -->
                        <div class="card-body mt-0">
                          <p-table
                            [value]="trackerData[0]?.events"
                            [rows]="10"
                            class="table"
                            [paginator]="true"
                            [loading]="!trackerData[0].events"
                            [resizableColumns]="true" columnResizeMode="expand"
                          >
                            <ng-template pTemplate="header">
                              <tr id="thead">
                                <ng-container *ngIf="chartDataOptions.length">
                                  <th pResizableColumn>BluTag</th>
                                  <th pResizableColumn>Temperature</th>
                                </ng-container>
                                <th pResizableColumn>Battery</th>
                                <th pResizableColumn
                                  *ngIf="
                                    trackerData[0].events[0].parsedData.signal
                                  "
                                >
                                  Signal
                                </th>
                                <th pResizableColumn>Location</th>
                                <th pResizableColumn>Event Time</th>
                              </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-row>
                              <tr class="tbody">
                                <ng-container *ngIf="chartDataOptions.length">
                                  <td
                                    [title]="row.blueTag"
                                    class="align-middle text-truncate"
                                  >
                                    {{ row.blueTag }}
                                  </td>
                                  <td class="align-middle">
                                    {{ row.temperature | number : '1.2-2' }}
                                  </td>
                                </ng-container>

                                <td class="align-middle">
                                  {{ row.parsedData?.battery }}
                                </td>
                                <td
                                  *ngIf="
                                    trackerData[0].events[0].parsedData.signal
                                  "
                                  class="align-middle"
                                >
                                  {{ row.parsedData?.signal }}
                                </td>
                                <td
                                  [title]="row?.parsedData?.location"
                                  class="align-middle text-truncate"
                                >
                                  {{ row.parsedData?.location }}
                                </td>
                                <td
                                  class="align-middle text-truncate"
                                  [title]="row.time"
                                >
                                  {{ row.time | date: "dd MMM y, h:mm a" }}
                                </td>
                              </tr>
                            </ng-template>
                          </p-table>
                        </div>
                      </div>
                    </div>

                    <div
                      [class.d-none]="!trackerData[0]?.events.length"
                      class="col-xl-4 col-lg-4"
                      id="mapcard"
                    >
                      <div
                        class="card shadow rounded-xl mb-3 graphsCard-loader"
                      >
                        <div
                          class="card-body p-1 row position-relative d-flex align-items-center justify-content-center"
                        >
                          <!-- <div class="map col" style="height: 302px" id="map"></div>
                          <div *ngIf="isMapLoading" class="spinner-grow text-dark col position-absolute mx-auto"></div> -->
                          <app-map
                            class="map col"
                            style="height: 302px"
                            page="report"
                            [type]="type"
                            [hData]="mapData"
                            [zoom]="zoom"
                            [mapType]="mapType"
                            [cluster]="cluster"
                            htmlEle="map"
                            [status]="shipmentData?shipmentData.status : ''"
                            [id]="shipmentData ?shipmentData.id : ''"
                            [freq]="shipmentData ? shipmentData.trackers[0]?.freq : 0"
                            [pickup]="shipmentData ? shipmentData.pickupLocation : ''"
                            [destination]="shipmentData ? shipmentData.destinationLocation : ''"
                          ></app-map>
                        </div>
                      </div>

                      <!--Total Events -->
                      <div
                        id="totalevents"
                        class="card shadow mb-3 widgetCards graphsCard-loader"
                      >
                        <div class="card-header pt-3 pb-2 clearfix">
                          <h6
                            class="m-0 font-weight-bold float-left font-size13 text-capitalize"
                          >
                            Total Events
                          </h6>
                        </div>
                        <div style="height: 302px" class="card-body p-0 px-0">
                          <div
                            echarts
                            [options]="optionsensorEvents"
                            [autoResize]="true"
                            class="events-chart"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!--BluTag Temperature Range Alert -->
                  <div
                    class="row"
                    *ngIf="chartDataOptions.length"
                    id="blutag_graph"
                  >
                    <ng-container
                      *ngFor="let option of chartDataOptions; let i = index"
                    >
                      <div class="col-md-4 mb-3 d-flex align-items-stretch">
                        <div
                          class="card shadow mb-1 widgetCards graphsCard-loader"
                        >
                          <div class="card-header py-3 clearfix">
                            <h6
                              class="m-0 font-weight-bold font-size13 text-capitalize d-flex justify-content-between"
                            >
                              <span>{{ option.bluTag }}</span>
                              <span class="font-weight-light"
                                >{{
                                  option.latestTemp !== "N/A"
                                    ? option.latestTemp + " °C"
                                    : ""
                                }}
                              </span>
                            </h6>
                          </div>
                          <div class="card-body p-0 px-0">
                            <div
                              *ngIf="
                                option.latestTemp !== 'N/A';
                                else notAvailable
                              "
                              style="height: 302px"
                              echarts
                              [options]="chartData"
                              [merge]="option.chart"
                              [autoResize]="true"
                            ></div>
                            <ng-template #notAvailable>
                              <div
                                class="d-flex justify-content-center w-100 h-100 align-items-center"
                              >
                                {{ option.latestTemp }}
                              </div>
                            </ng-template>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  </div>
</div>
<!-- /Main content -->

<ng-template #loading>
  <div
    class="card-loader d-flex justify-content-center flex-wrap align-content-center w-100 h-75"
  >
    <div
      *ngIf="isLoading; else noData"
      class="spinner-grow text-dark"
      role="status"
    ></div>
  </div>
</ng-template>
<ng-template #noData>
  <div
    *ngIf="!shipmentData?.events?.length"
    id="no_events"
    class="card-loader d-flex justify-content-center flex-wrap align-content-center w-100 h-75"
  >
    <div class="text-dark" role="status">No events found.</div>
  </div>
</ng-template>
