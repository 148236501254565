<form class="form-card row " [formGroup]="loginForm" (ngSubmit)="login()">
  <div class="w-100 p-4">
    <div class="mt-4 pt-2 mb-5 text-center">
      <img src="../../../../../assets/images/IMPACT_by_honeywell-Logo.png" class="pr-1 img-fluid pb-1"/>
    </div>
  
    <div class="form-group col-md-12 mb-4 mt-1">
      <span class="mb-0 font-size9"><b>Email</b></span>
      <label class="has-float-label">
        <input class="form-control" formControlName="email" id="fname" type="text" placeholder="User Name" autocomplete="off"/>
      </label>
    </div>
  
    <div class="form-group col-md-12 mb-4 mt-1 passwField">
      <span class="mb-0 font-size9"><b>Password</b></span>
        <label class="has-float-label">
          <input [type]="passwordEye ? 'text' : 'password'" class="form-control" formControlName="password" id="password"
            placeholder="Password" autocomplete="off">
        </label>
        <i class="fa fa-eye password-field-icon" [ngClass]="{'fa-eye-slash': !passwordEye,'fa-eye': passwordEye}"
          (click)="toggelEyeButtonPassword()"></i>
    </div>
  
    <!-- <div class="col-md-12 form-group" >
      <div class="d-flex flex-row mb-0">
        <input type="checkbox" class="check mt-1" [checked]="isChecked" (click)="remember($event)">
        <div class="ml-2">
          <span class="remember">Remember Me</span>
        </div>
      </div>
    </div> -->
    
    <div class="col-md-12 text-right mt-2">
      <button type="submit" class="btn bg-gradient-primary onhover mt-3 mb-3 text-uppercase w-100" [disabled]="loading || !loginForm.valid" id="login_button">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        SIGN IN</button>
    </div>

    <div class="col-md-6 float-right mt-0">
      <a href="javascript:void(0)" routerLink="/forgot-password" class="text-primary font-weight-bold float-right">Forgot password</a>
    </div>
  
  
    <div class="col-md-12 float-right mt-3">
      <a href="#" routerLink="/tenant-signup" class="text-primary font-weight-bold float-right">Create new account</a> 
    </div>
  </div>


</form>