import { Injectable } from '@angular/core';
import { Moment } from 'moment';
import * as moment from 'moment-timezone';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { CommonService } from 'src/app/core/services/common.service';
import { ApiService } from 'src/app/core/services/api.service';



@Injectable()
export class TimeZoneService {
    public tenantTimeZone: string;

    constructor(private http: HttpClient,private commonService: CommonService) {
        this.tenantTimeZone = 'GMT';
    }

    public setTenantTimeZone(tenantTz: string) {
        this.tenantTimeZone = tenantTz;
    }

    public utcToTenant(utcDateTime: Moment): Moment {
        return moment.tz(utcDateTime, this.tenantTimeZone);
    }

    public utcToTenantString(utcDateTime: Moment, format: string): string {
        format = moment.tz.guess() === this.tenantTimeZone ? format : format + ' (UTC Z)';
        return moment.tz(utcDateTime, this.tenantTimeZone).format(format);
    }

    public tenantToUtc(tenantDateTime: Moment): Moment {
        return moment(tenantDateTime).utc();
    }

    UpdateTimezone(data) {
        const body = JSON.stringify(data);
        const { user } = this.commonService.getCurrentUserData();
        const options = {
          headers: new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', "Bearer "+ user.token,),
          params: new HttpParams({fromString:`email=${ user.officialEmail}`})
        };
        return this.http.post(ApiService.URLs.setTimezone, body, options);
    }

}
