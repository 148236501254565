
<div class="row" id="add_access">
    <div class="col-12">
        <section class="content mt-3">
            <form role="form" #myForm="ngForm">
                <div class="col-md-12 px-3">
                    <nav aria-label="breadcrumb" id="breadcrumb">
                        <ol class="breadcrumb amber lighten-4">
                            <li class="breadcrumb-item">
                                Access Control
                                <i class="fas fa-caret-left mx-2" aria-hidden="true"></i>
                            </li>
                            <li class="breadcrumb-item">
                                <a class="" routerLink="../user-access-policies">User Access Policies</a>
                                <i class="fas fa-caret-left mx-2" aria-hidden="true"></i>
                            </li>
                            <li class="breadcrumb-item">
                                <a class="active text-primary" href="#">Add New Access Level</a>
                            </li>
                        </ol>
                    </nav>
                </div>

                <section class="content mx-3 mt-3">
                    <div class="card no-border">
                        <div class="card-body p-0 tab-content" id="tab1content">
                            <div class="row">
                                <div
                                    class="col-md-12 bg-white p-3 tab-content cus-rounded-left cus-rounded-right" id="add_section">
                                    <div class="row">
                                        <div class="form-group col-md-6 col-xl-4 col-lg-4 col-sm-12" id="name" >
                                            <span class="badge text-danger">*</span>
                                            <label class="has-float-label">
                                                <input type="text" class="form-control"  placeholder="Access Level Name" name="levelName" [(ngModel)]="levelName" required>
                                                <span>Access Level Name</span>
                                            </label>
                                        </div>
                                        <div class="form-group col-md-6 col-xl-8 col-lg-8 col-sm-12 pt-2">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input" id="command" (click)="sendCommand($event)" >
                                                <label class="pt-1 small custom-control-label font-weight-normal" for="command">
                                                    Check this to allow send command on the devices
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="card no-border">
                                            <div class="card-body p-0 mb-0">
                                                <div class="row">
                                                    <div class="col-md-2 bg-light pb-3 p-0 pt-3 cus-rounded-left shadow">
                                                        <div class="row" >
                                                            <div class="col-md-12">
                                                                <ul class="nav nav-pills flex-column cus-tab" id="access_side_nav">

                                                                    <li class="nav-item border-0">
                                                                        <a class="nav-link active" data-toggle="pill" href="#aa">Access Control</a>
                                                                    </li>
                                                                    <li class="nav-item border-0">
                                                                        <a class="nav-link" data-toggle="pill" href="#dd">Reports</a>
                                                                    </li>
                                                                    <li class="nav-item border-0">
                                                                        <a class="nav-link" data-toggle="pill" href="#ee">{{constantVariables.ENDPOINT.LABEL1}} Management</a>
                                                                    </li>
                                                                    <li class="nav-item border-0">
                                                                        <a class="nav-link" data-toggle="pill" href="#ff">Edge Management</a>
                                                                    </li>
                                                                    <li class="nav-item border-0">
                                                                        <a class="nav-link" data-toggle="pill" href="#gg">Provisioning Queue</a>
                                                                    </li>
                                                                    <li class="nav-item border-0">
                                                                        <a class="nav-link" data-toggle="pill" href="#bb">Utility</a>
                                                                    </li>
                                                                    <li class="nav-item border-0">
                                                                        <a class="nav-link" data-toggle="pill" href="#ss">Shipments</a>
                                                                    </li>
                                                                    <li class="nav-item border-0" hidden>
                                                                        <a class="nav-link" data-toggle="pill" href="#cc">System Settings</a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-10 p-3 tab-content cus-rounded-right shadow">
                                                <!-- Utility -->
                                                <div class="tab-pane container bg-white p-0 " id="bb" >
                                                    <div class="table-responsive text-nowrap">
                                                        <table class="table table-border">
                                                            <thead class="bg-light">
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>Utility</th>
                                                                    <th>View</th>
                                                                    <th>Add</th>
                                                                    <th>Edit</th>
                                                                    <th>Delete</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr hidden>
                                                                    <td class="align-middle">1</td>
                                                                    <td class="align-middle">Connectivity</td>
                                                                    <td class="align-middle">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox" class="custom-control-input" id="connectivityview" (click)="checkSubmenu('Utility','Connectivity','view',$event)" >
                                                                            <label class="small pt-1 custom-control-label" for="connectivityview"></label>
                                                                        </div>
                                                                    </td>
                                                                    <td class="align-middle">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox" class="custom-control-input" id="connectivityadd" (click)="checkSubmenu('Utility','Connectivity','add',$event)">
                                                                            <label class="small pt-1 custom-control-label" for="connectivityadd"></label>
                                                                        </div>
                                                                    </td>
                                                                    <td class="align-middle">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox" class="custom-control-input" id="connectivityedit" (click)="checkSubmenu('Utility','Connectivity','update',$event)">
                                                                            <label class="small pt-1 custom-control-label" for="connectivityedit"></label>
                                                                        </div>
                                                                    </td>
                                                                    <td class="align-middle">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox" class="custom-control-input" id="connectivitydel" (click)="checkSubmenu('Utility','Connectivity','delete',$event)">
                                                                            <label class="small pt-1 custom-control-label" for="connectivitydel"></label>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="align-middle">1</td>
                                                                    <td class="align-middle">Time & Clock</td>
                                                                    <td class="align-middle">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox" class="custom-control-input" id="timeview" (click)="checkSubmenu('Utility','Time & Clock','view',$event)" >
                                                                            <label class="small pt-1 custom-control-label" for="timeview"></label>
                                                                        </div>
                                                                    </td>
                                                                    <td class="align-middle">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox" class="custom-control-input" id="timeadd" (click)="checkSubmenu('Utility','Time & Clock','add',$event)">
                                                                            <label class="small pt-1 custom-control-label" for="timeadd"></label>
                                                                        </div>
                                                                    </td>
                                                                    <td class="align-middle">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox" class="custom-control-input" id="timeedit" (click)="checkSubmenu('Utility','Time & Clock','update',$event)">
                                                                            <label class="small pt-1 custom-control-label" for="timeedit"></label>
                                                                        </div>
                                                                    </td>
                                                                    <td class="align-middle">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox" class="custom-control-input" id="timedel" (click)="checkSubmenu('Utility','Time & Clock','delete',$event)">
                                                                            <label class="small pt-1 custom-control-label" for="timedel"></label>
                                                                        </div>
                                                                    </td>

                                                                </tr>
                                                                <tr>
                                                                    <td class="align-middle">2</td>
                                                                    <td class="align-middle">Weather</td>
                                                                    <td class="align-middle">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox" class="custom-control-input" id="weatherview" (click)="checkSubmenu('Utility','Weather','view',$event)" >
                                                                            <label class="small pt-1 custom-control-label" for="weatherview"></label>
                                                                        </div>
                                                                    </td>
                                                                    <td class="align-middle">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox" class="custom-control-input" id="weatheradd" (click)="checkSubmenu('Utility','Weather','add',$event)">
                                                                            <label class="small pt-1 custom-control-label" for="weatheradd"></label>
                                                                        </div>
                                                                    </td>
                                                                    <td class="align-middle">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox" class="custom-control-input" id="weatheredit" (click)="checkSubmenu('Utility','Weather','update',$event)">
                                                                            <label class="small pt-1 custom-control-label" for="weatheredit"></label>
                                                                        </div>
                                                                    </td>
                                                                    <td class="align-middle">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox" class="custom-control-input" id="weatherdel" (click)="checkSubmenu('Utility','Weather','delete',$event)">
                                                                            <label class="small pt-1 custom-control-label" for="weatherdel"></label>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr hidden>
                                                                    <td class="align-middle">3</td>
                                                                    <td class="align-middle">Data Storage</td>
                                                                    <td class="align-middle">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox" class="custom-control-input" id="dataStorageview" (click)="checkSubmenu('Utility','Data Storage','view',$event)" >
                                                                            <label class="small pt-1 custom-control-label" for="dataStorageview"></label>
                                                                        </div>
                                                                    </td>
                                                                    <td class="align-middle">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox" class="custom-control-input" id="dataStorageadd" (click)="checkSubmenu('Utility','Data Storage','add',$event)">
                                                                            <label class="small pt-1 custom-control-label" for="dataStorageadd"></label>
                                                                        </div>
                                                                    </td>
                                                                    <td class="align-middle">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox" class="custom-control-input" id="dataStorageedit" (click)="checkSubmenu('Utility','Data Storage','update',$event)">
                                                                            <label class="small pt-1 custom-control-label" for="dataStorageedit"></label>
                                                                        </div>
                                                                    </td>
                                                                    <td class="align-middle">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox" class="custom-control-input" id="dataStoragedel" (click)="checkSubmenu('Utility','Data Storage','delete',$event)">
                                                                            <label class="small pt-1 custom-control-label" for="dataStoragedel"></label>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="align-middle">3</td>
                                                                    <td class="align-middle">Node Map</td>
                                                                    <td class="align-middle">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox" class="custom-control-input" id="nodemapview" (click)="checkSubmenu('Utility','Node Map','view',$event)" >
                                                                            <label class="small pt-1 custom-control-label" for="nodemapview"></label>
                                                                        </div>
                                                                    </td>
                                                                    <td class="align-middle">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox" class="custom-control-input" id="nodemapadd" (click)="checkSubmenu('Utility','Node Map','add',$event)">
                                                                            <label class="small pt-1 custom-control-label" for="nodemapadd"></label>
                                                                        </div>
                                                                    </td>
                                                                    <td class="align-middle">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox" class="custom-control-input" id="nodemapedit" (click)="checkSubmenu('Utility','Node Map','update',$event)">
                                                                            <label class="small pt-1 custom-control-label" for="nodemapedit"></label>
                                                                        </div>
                                                                    </td>
                                                                    <td class="align-middle">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox" class="custom-control-input" id="nodemapdel" (click)="checkSubmenu('Utility','Node Map','delete',$event)">
                                                                            <label class="small pt-1 custom-control-label" for="nodemapdel"></label>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr hidden>
                                                                    <td class="align-middle">6</td>
                                                                    <td class="align-middle">Terminal</td>
                                                                    <td class="align-middle">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox" class="custom-control-input" id="terminalview" (click)="checkSubmenu('Utility','Terminal','view',$event)" >
                                                                            <label class="small pt-1 custom-control-label" for="terminalview"></label>
                                                                        </div>
                                                                    </td>
                                                                    <td class="align-middle">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox" class="custom-control-input" id="terminaladd" (click)="checkSubmenu('Utility','Terminal','add',$event)">
                                                                            <label class="small pt-1 custom-control-label" for="terminaladd"></label>
                                                                        </div>
                                                                    </td>
                                                                    <td class="align-middle">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox" class="custom-control-input" id="terminaledit" (click)="checkSubmenu('Utility','Terminal','update',$event)">
                                                                            <label class="small pt-1 custom-control-label" for="terminaledit"></label>
                                                                        </div>
                                                                    </td>
                                                                    <td class="align-middle">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox" class="custom-control-input" id="terminaldel" (click)="checkSubmenu('Utility','Terminal','delete',$event)">
                                                                            <label class="small pt-1 custom-control-label" for="terminaldel"></label>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="align-middle">4</td>
                                                                    <td class="align-middle">Ledger</td>
                                                                    <td class="align-middle" colspan="4">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox" class="custom-control-input" id="ledgerview" (click)="checkSubmenu('Utility','Ledger', 'view', $event);show($event, 'Ledger')">
                                                                            <label class="small pt-1 custom-control-label" for="ledgerview"></label>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr *ngIf="ledgerShowTable" id="ledger_detail">
                                                                    <td colspan="1"></td>
                                                                    <td colspan="6">
                                                                        <div class="table-responsive text-nowrap my-1">
                                                                            <table class="table border table-border mb-0" id="sub_table">
                                                                                <thead class="bg-light">
                                                                                    <tr>
                                                                                        <th>#</th>
                                                                                        <th>
                                                                                            <div class="custom-control custom-checkbox">
                                                                                                <input type="checkbox" class="custom-control-input" id="ledgerCheck" (click)="allLedger($event)">
                                                                                                <label for="ledgerCheck" class="pt-1 custom-control-label">Ledger</label>
                                                                                            </div>
                                                                                        </th>
                                                                                        <th>View</th>
                                                                                        <th>Add</th>
                                                                                        <th>Edit</th>
                                                                                        <th>Delete</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td class="align-middle">1</td>
                                                                                        <td class="align-middle">User Chain</td>
                                                                                        <td class="align-middle">
                                                                                            <div class="custom-control custom-checkbox">
                                                                                                <input type="checkbox" class="custom-control-input ledger" id="userChainview" (click)="checkSubmenu('Utility','User Chain','view',$event)" >
                                                                                                <label class="small pt-1 custom-control-label" for="userChainview"></label>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td class="align-middle">
                                                                                            <div class="custom-control custom-checkbox">
                                                                                                <input type="checkbox" class="custom-control-input ledger" id="userChainadd" (click)="checkSubmenu('Utility','User Chain','add',$event)">
                                                                                                <label class="small pt-1 custom-control-label" for="userChainadd"></label>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td class="align-middle">
                                                                                            <div class="custom-control custom-checkbox">
                                                                                                <input type="checkbox" class="custom-control-input ledger" id="userChainedit" (click)="checkSubmenu('Utility','User Chain','update',$event)">
                                                                                                <label class="small pt-1 custom-control-label" for="userChainedit"></label>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td class="align-middle">
                                                                                            <div class="custom-control custom-checkbox">
                                                                                                <input type="checkbox" class="custom-control-input ledger" id="userChaindel" (click)="checkSubmenu('Utility','User Chain','delete',$event)">
                                                                                                <label class="small pt-1 custom-control-label" for="userChaindel"></label>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td class="align-middle">2</td>
                                                                                        <td class="align-middle">Identity Chain</td>
                                                                                        <td class="align-middle">
                                                                                            <div class="custom-control custom-checkbox">
                                                                                                <input type="checkbox" class="custom-control-input ledger" id="identityChainview" (click)="checkSubmenu('Utility','Identity Chain','view',$event)" >
                                                                                                <label class="small pt-1 custom-control-label" for="identityChainview"></label>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td class="align-middle">
                                                                                            <div class="custom-control custom-checkbox">
                                                                                                <input type="checkbox" class="custom-control-input ledger" id="identityChainadd" (click)="checkSubmenu('Utility','Identity Chain','add',$event)">
                                                                                                <label class="small pt-1 custom-control-label" for="identityChainadd"></label>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td class="align-middle">
                                                                                            <div class="custom-control custom-checkbox">
                                                                                                <input type="checkbox" class="custom-control-input ledger" id="identityChainedit" (click)="checkSubmenu('Utility','Identity Chain','update',$event)">
                                                                                                <label class="small pt-1 custom-control-label" for="identityChainedit"></label>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td class="align-middle">
                                                                                            <div class="custom-control custom-checkbox">
                                                                                                <input type="checkbox" class="custom-control-input ledger" id="identityChaindel" (click)="checkSubmenu('Utility','Identity Chain','delete',$event)">
                                                                                                <label class="small pt-1 custom-control-label" for="identityChaindel"></label>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td class="align-middle">3</td>
                                                                                        <td class="align-middle">Edge Chain</td>
                                                                                        <td class="align-middle">
                                                                                            <div class="custom-control custom-checkbox">
                                                                                                <input type="checkbox" class="custom-control-input ledger" id="edgeChainview" (click)="checkSubmenu('Utility','Edge Chain','view',$event)" >
                                                                                                <label class="small pt-1 custom-control-label" for="edgeChainview"></label>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td class="align-middle">
                                                                                            <div class="custom-control custom-checkbox">
                                                                                                <input type="checkbox" class="custom-control-input ledger" id="edgeChainadd" (click)="checkSubmenu('Utility','Edge Chain','add',$event)">
                                                                                                <label class="small pt-1 custom-control-label" for="edgeChainadd"></label>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td class="align-middle">
                                                                                            <div class="custom-control custom-checkbox">
                                                                                                <input type="checkbox" class="custom-control-input ledger" id="edgeChainedit" (click)="checkSubmenu('Utility','Edge Chain','update',$event)">
                                                                                                <label class="small pt-1 custom-control-label" for="edgeChainedit"></label>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td class="align-middle">
                                                                                            <div class="custom-control custom-checkbox">
                                                                                                <input type="checkbox" class="custom-control-input ledger" id="edgeChaindel" (click)="checkSubmenu('Utility','Edge Chain','delete',$event)">
                                                                                                <label class="small pt-1 custom-control-label" for="edgeChaindel"></label>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td class="align-middle">4</td>
                                                                                        <td class="align-middle">Communication Chain</td>
                                                                                        <td class="align-middle">
                                                                                            <div class="custom-control custom-checkbox">
                                                                                                <input type="checkbox" class="custom-control-input ledger" id="commChainview" (click)="checkSubmenu('Utility','Communication Chain','view',$event)" >
                                                                                                <label class="small pt-1 custom-control-label" for="commChainview"></label>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td class="align-middle">
                                                                                            <div class="custom-control custom-checkbox">
                                                                                                <input type="checkbox" class="custom-control-input ledger" id="commChainadd" (click)="checkSubmenu('Utility','Communication Chain','add',$event)">
                                                                                                <label class="small pt-1 custom-control-label" for="commChainadd"></label>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td class="align-middle">
                                                                                            <div class="custom-control custom-checkbox">
                                                                                                <input type="checkbox" class="custom-control-input ledger" id="commChainedit" (click)="checkSubmenu('Utility','Communication Chain','update',$event)">
                                                                                                <label class="small pt-1 custom-control-label" for="commChainedit"></label>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td class="align-middle">
                                                                                            <div class="custom-control custom-checkbox">
                                                                                                <input type="checkbox" class="custom-control-input ledger" id="commChaindel" (click)="checkSubmenu('Utility','Communication Chain','delete',$event)">
                                                                                                <label class="small pt-1 custom-control-label" for="commChaindel"></label>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td class="align-middle">5</td>
                                                                                        <td class="align-middle">Smart Contract Chain</td>
                                                                                        <td class="align-middle">
                                                                                            <div class="custom-control custom-checkbox">
                                                                                                <input type="checkbox" class="custom-control-input ledger" id="smartConChainview" (click)="checkSubmenu('Utility','Smart Contract Chain','view',$event)" >
                                                                                                <label class="small pt-1 custom-control-label" for="smartConChainview"></label>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td class="align-middle">
                                                                                            <div class="custom-control custom-checkbox">
                                                                                                <input type="checkbox" class="custom-control-input ledger" id="smartConChainadd" (click)="checkSubmenu('Utility','Smart Contract Chain','add',$event)">
                                                                                                <label class="small pt-1 custom-control-label" for="smartConChainadd"></label>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td class="align-middle">
                                                                                            <div class="custom-control custom-checkbox">
                                                                                                <input type="checkbox" class="custom-control-input ledger" id="smartConChainedit" (click)="checkSubmenu('Utility','Smart Contract Chain','update',$event)">
                                                                                                <label class="small pt-1 custom-control-label" for="smartConChainedit"></label>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td class="align-middle">
                                                                                            <div class="custom-control custom-checkbox">
                                                                                                <input type="checkbox" class="custom-control-input ledger" id="smartConChaindel" (click)="checkSubmenu('Utility','Smart Contract Chain','delete',$event)">
                                                                                                <label class="small pt-1 custom-control-label" for="smartConChaindel"></label>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr  id="logs">
                                                                    <td class="align-middle">5</td>
                                                                    <td class="align-middle">Logs</td>
                                                                    <td class="align-middle" colspan="4">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox" class="custom-control-input " id="logsview" (click)="checkSubmenu('Utility','Logs', 'view', $event);show($event, 'Logs')">
                                                                            <label class="small pt-1 custom-control-label" for="logsview"></label>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr *ngIf="logsShowTable">
                                                                    <td colspan="1"></td>
                                                                    <td colspan="6">
                                                                        <div class="table-responsive text-nowrap my-1">
                                                                            <table class="table border table-border mb-0" id="log_sub_table">
                                                                                <thead class="bg-light">
                                                                                    <tr>
                                                                                        <th>#</th>
                                                                                        <th>
                                                                                            <div class="custom-control custom-checkbox">
                                                                                                <input type="checkbox" class="custom-control-input" id="logsCheck" (click)="allLogs($event)">
                                                                                                <label for="logsCheck" class="pt-1 custom-control-label">Logs</label>
                                                                                            </div>
                                                                                        </th>
                                                                                        <th>View</th>
                                                                                        <th>Add</th>
                                                                                        <th>Edit</th>
                                                                                        <th>Delete</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td class="align-middle">1</td>
                                                                                        <td class="align-middle">System Log</td>
                                                                                        <td class="align-middle">
                                                                                            <div class="custom-control custom-checkbox">
                                                                                                <input type="checkbox" class="custom-control-input logs" id="syslogsview" (click)="checkSubmenu('Utility','System Log','view',$event)" >
                                                                                                <label class="small pt-1 custom-control-label" for="syslogsview"></label>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td class="align-middle">
                                                                                            <div class="custom-control custom-checkbox">
                                                                                                <input type="checkbox" class="custom-control-input logs" id="syslogsadd" (click)="checkSubmenu('Utility','System Log','add',$event)">
                                                                                                <label class="small pt-1 custom-control-label" for="syslogsadd"></label>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td class="align-middle">
                                                                                            <div class="custom-control custom-checkbox">
                                                                                                <input type="checkbox" class="custom-control-input logs" id="syslogsedit" (click)="checkSubmenu('Utility','System Log','update',$event)">
                                                                                                <label class="small pt-1 custom-control-label" for="syslogsedit"></label>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td class="align-middle">
                                                                                            <div class="custom-control custom-checkbox">
                                                                                                <input type="checkbox" class="custom-control-input logs" id="syslogsdel" (click)="checkSubmenu('Utility','System Log','delete',$event)">
                                                                                                <label class="small pt-1 custom-control-label" for="syslogsdel"></label>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td class="align-middle">2</td>
                                                                                        <td class="align-middle">Event Log</td>
                                                                                        <td class="align-middle">
                                                                                            <div class="custom-control custom-checkbox">
                                                                                                <input type="checkbox" class="custom-control-input logs" id="eventlogsview" (click)="checkSubmenu('Utility','Event Log','view',$event)" >
                                                                                                <label class="small pt-1 custom-control-label" for="eventlogsview"></label>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td class="align-middle">
                                                                                            <div class="custom-control custom-checkbox">
                                                                                                <input type="checkbox" class="custom-control-input logs" id="eventlogsadd" (click)="checkSubmenu('Utility','Event Log','add',$event)">
                                                                                                <label class="small pt-1 custom-control-label" for="eventlogsadd"></label>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td class="align-middle">
                                                                                            <div class="custom-control custom-checkbox">
                                                                                                <input type="checkbox" class="custom-control-input logs" id="eventlogsedit" (click)="checkSubmenu('Utility','Event Log','update',$event)">
                                                                                                <label class="small pt-1 custom-control-label" for="eventlogsedit"></label>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td class="align-middle">
                                                                                            <div class="custom-control custom-checkbox">
                                                                                                <input type="checkbox" class="custom-control-input logs" id="eventlogsdel" (click)="checkSubmenu('Utility','Event Log','delete',$event)">
                                                                                                <label class="small pt-1 custom-control-label" for="eventlogsdel"></label>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                      </td>
                                                                </tr>

                                                                <tr>
                                                                    <td class="align-middle">6</td>
                                                                    <td class="align-middle">Alerts & Notifications</td>
                                                                    <td class="align-middle">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox" class="custom-control-input" id="alertview" (click)="checkSubmenu('Utility','Alerts & Notifications','view',$event)" >
                                                                            <label class="small pt-1 custom-control-label" for="alertview"></label>
                                                                        </div>
                                                                    </td>
                                                                    <td class="align-middle">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox" class="custom-control-input" id="alertadd" (click)="checkSubmenu('Utility','Alerts & Notifications','add',$event)">
                                                                            <label class="small pt-1 custom-control-label" for="alertadd"></label>
                                                                        </div>
                                                                    </td>
                                                                    <td class="align-middle">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox" class="custom-control-input" id="alertedit" (click)="checkSubmenu('Utility','Alerts & Notifications','update',$event)">
                                                                            <label class="small pt-1 custom-control-label" for="alertedit"></label>
                                                                        </div>
                                                                    </td>
                                                                    <td class="align-middle">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox" class="custom-control-input" id="alertdel" (click)="checkSubmenu('Utility','Alerts & Notifications','delete',$event)">
                                                                            <label class="small pt-1 custom-control-label" for="alertdel"></label>
                                                                        </div>
                                                                    </td>

                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>

                                          <!-- Access control -->
                                                <div class="tab-pane container bg-white p-0 active" id="aa">
                                                    <div class="table-responsive text-nowrap">
                                                        <table class="table table-border">
                                                            <thead class="bg-light">
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>Access Control</th>
                                                                    <th>View</th>
                                                                    <th>Add</th>
                                                                    <th>Edit</th>
                                                                    <th>Delete</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td class="align-middle">1</td>
                                                                    <td class="align-middle">User Access Policies</td>
                                                                    <td class="align-middle">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox" class="custom-control-input" id="policyview" (click)="checkSubmenu('Access Control','User Access Policies','view',$event)" >
                                                                            <label class="small pt-1 custom-control-label" for="policyview"></label>
                                                                        </div>
                                                                    </td>
                                                                    <td class="align-middle">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox" class="custom-control-input" id="policyadd" (click)="checkSubmenu('Access Control','User Access Policies','add',$event)">
                                                                            <label class="small pt-1 custom-control-label" for="policyadd"></label>
                                                                        </div>
                                                                    </td>
                                                                    <td class="align-middle">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox" class="custom-control-input" id="policyedit" (click)="checkSubmenu('Access Control','User Access Policies','update',$event)">
                                                                            <label class="small pt-1 custom-control-label" for="policyedit"></label>
                                                                        </div>
                                                                    </td>
                                                                    <td class="align-middle">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox" class="custom-control-input" id="policydel" (click)="checkSubmenu('Access Control','User Access Policies','delete',$event)">
                                                                            <label class="small pt-1 custom-control-label" for="policydel"></label>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="align-middle">2</td>
                                                                    <td class="align-middle">User Management</td>
                                                                    <td class="align-middle">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox" class="custom-control-input" id="umview" (click)="checkSubmenu('Access Control','User Management','view',$event)" >
                                                                            <label class="small pt-1 custom-control-label" for="umview"></label>
                                                                        </div>
                                                                    </td>
                                                                    <td class="align-middle">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox" class="custom-control-input" id="umadd" (click)="checkSubmenu('Access Control','User Management','add',$event)">
                                                                            <label class="small pt-1 custom-control-label" for="umadd"></label>
                                                                        </div>
                                                                    </td>
                                                                    <td class="align-middle">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox" class="custom-control-input" id="umedit" (click)="checkSubmenu('Access Control','User Management','update',$event)">
                                                                            <label class="small pt-1 custom-control-label" for="umedit"></label>
                                                                        </div>
                                                                    </td>
                                                                    <td class="align-middle">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox" class="custom-control-input" id="umdel" (click)="checkSubmenu('Access Control','User Management','delete',$event)">
                                                                            <label class="small pt-1 custom-control-label" for="umdel"></label>
                                                                        </div>
                                                                    </td>

                                                                </tr>

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>

                                                <!-- Reports -->
                                                <div class="tab-pane container bg-white p-0" id="ss">
                                                <div class="table-responsive text-nowrap">
                                                    <table class="table table-border">
                                                        <thead class="bg-light">
                                                            <tr>
                                                                <th>#</th>
                                                                <th></th>
                                                                <th>View</th>
                                                                <th>Add</th>
                                                                <th>Edit</th>
                                                                <th>Delete</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td class="align-middle">1</td>
                                                                <td class="align-middle">Shipments</td>
                                                                <td class="align-middle">
                                                                    <div class="custom-control custom-checkbox">
                                                                        <input type="checkbox" class="custom-control-input" id="shipmentsview" (click)="checkSubmenu('Shipments','','view',$event)" >
                                                                        <label class="small pt-1 custom-control-label" for="shipmentsview"></label>
                                                                    </div>
                                                                </td>
                                                                <td class="align-middle">
                                                                    <div class="custom-control custom-checkbox">
                                                                        <input type="checkbox" class="custom-control-input" id="shipmentsadd" (click)="checkSubmenu('Shipments','','add',$event)">
                                                                        <label class="small pt-1 custom-control-label" for="shipmentsadd"></label>
                                                                    </div>
                                                                </td>
                                                                <td class="align-middle">
                                                                    <div class="custom-control custom-checkbox">
                                                                        <input type="checkbox" class="custom-control-input" id="shipmentsedit" (click)="checkSubmenu('Shipments','','update',$event)">
                                                                        <label class="small pt-1 custom-control-label" for="shipmentsedit"></label>
                                                                    </div>
                                                                </td>
                                                                <td class="align-middle">
                                                                    <div class="custom-control custom-checkbox">
                                                                        <input type="checkbox" class="custom-control-input" id="shipmentsdel" (click)="checkSubmenu('Shipments','','delete',$event)">
                                                                        <label class="small pt-1 custom-control-label" for="shipmentsdel"></label>
                                                                    </div>
                                                                </td>
                                                            </tr>


                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                                <div class="tab-pane container bg-white p-0" id="dd">
                                                <div class="table-responsive text-nowrap">
                                                    <table class="table table-border">
                                                        <thead class="bg-light">
                                                            <tr>
                                                                <th>#</th>
                                                                <th></th>
                                                                <th>View</th>
                                                                <th>Add</th>
                                                                <th>Edit</th>
                                                                <th>Delete</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td class="align-middle">1</td>
                                                                <td class="align-middle">Reports</td>
                                                                <td class="align-middle">
                                                                    <div class="custom-control custom-checkbox">
                                                                        <input type="checkbox" class="custom-control-input" id="reportview" (click)="checkSubmenu('Reports','','view',$event)" >
                                                                        <label class="small pt-1 custom-control-label" for="reportview"></label>
                                                                    </div>
                                                                </td>
                                                                <td class="align-middle">
                                                                    <div class="custom-control custom-checkbox">
                                                                        <input type="checkbox" class="custom-control-input" id="reportadd" (click)="checkSubmenu('Reports','','add',$event)">
                                                                        <label class="small pt-1 custom-control-label" for="reportadd"></label>
                                                                    </div>
                                                                </td>
                                                                <td class="align-middle">
                                                                    <div class="custom-control custom-checkbox">
                                                                        <input type="checkbox" class="custom-control-input" id="reportedit" (click)="checkSubmenu('Reports','','update',$event)">
                                                                        <label class="small pt-1 custom-control-label" for="reportedit"></label>
                                                                    </div>
                                                                </td>
                                                                <td class="align-middle">
                                                                    <div class="custom-control custom-checkbox">
                                                                        <input type="checkbox" class="custom-control-input" id="reportdel" (click)="checkSubmenu('Reports','','delete',$event)">
                                                                        <label class="small pt-1 custom-control-label" for="reportdel"></label>
                                                                    </div>
                                                                </td>
                                                            </tr>


                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                              <!-- End Point Management -->
                                              <div class="tab-pane container bg-white p-0" id="ee">
                                                                                                            <div class="table-responsive text-nowrap">
                                                                                                                <table class="table table-border">
                                                                                                                    <thead class="bg-light">
                                                                                                                        <tr>
                                                                                                                            <th>#</th>
                                                                                                                            <th></th>
                                                                                                                            <th>View</th>
                                                                                                                            <th>Add</th>
                                                                                                                            <th>Edit</th>
                                                                                                                            <th>Delete</th>
                                                                                                                        </tr>
                                                                                                                    </thead>
                                                                                                                    <tbody>
                                                                                                                        <tr>
                                                                                                                            <td class="align-middle">1</td>
                                                                                                                            <td class="align-middle">{{constantVariables.ENDPOINT.LABEL1}} Management</td>
                                                                                                                            <td class="align-middle">
                                                                                                                                <div class="custom-control custom-checkbox">
                                                                                                                                    <input type="checkbox" class="custom-control-input" id="endPointview" (click)="checkSubmenu('End Point Management','','view',$event)" >
                                                                                                                                    <label class="small pt-1 custom-control-label" for="endPointview"></label>
                                                                                                                                </div>
                                                                                                                            </td>
                                                                                                                            <td class="align-middle">
                                                                                                                                <div class="custom-control custom-checkbox">
                                                                                                                                    <input type="checkbox" class="custom-control-input" id="endPointadd" (click)="checkSubmenu('End Point Management','','add',$event)">
                                                                                                                                    <label class="small pt-1 custom-control-label" for="endPointadd"></label>
                                                                                                                                </div>
                                                                                                                            </td>
                                                                                                                            <td class="align-middle">
                                                                                                                                <div class="custom-control custom-checkbox">
                                                                                                                                    <input type="checkbox" class="custom-control-input" id="endPointedit" (click)="checkSubmenu('End Point Management','','update',$event)">
                                                                                                                                    <label class="small pt-1 custom-control-label" for="endPointedit"></label>
                                                                                                                                </div>
                                                                                                                            </td>
                                                                                                                            <td class="align-middle">
                                                                                                                                <div class="custom-control custom-checkbox">
                                                                                                                                    <input type="checkbox" class="custom-control-input" id="endPointdel" (click)="checkSubmenu('End Point Management','','delete',$event)">
                                                                                                                                    <label class="small pt-1 custom-control-label" for="endPointdel"></label>
                                                                                                                                </div>
                                                                                                                            </td>
                                                                                                                        </tr>
                                                                                                                    </tbody>
                                                                                                                </table>
                                                                                                            </div>
                                              </div>


                                                <!-- Edge Management -->
                                                <div class="tab-pane container bg-white p-0" id="ff">
                                                <div class="table-responsive text-nowrap">
                                                    <table class="table table-border">
                                                        <thead class="bg-light">
                                                            <tr>
                                                                <th>#</th>
                                                                <th></th>
                                                                <th>View</th>
                                                                <th>Add</th>
                                                                <th>Edit</th>
                                                                <th>Delete</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td class="align-middle">1</td>
                                                                <td class="align-middle">Edge Management</td>
                                                                <td class="align-middle">
                                                                    <div class="custom-control custom-checkbox">
                                                                        <input type="checkbox" class="custom-control-input" id="edgeview" (click)="checkSubmenu('Edge Management','','view',$event)" >
                                                                        <label class="small pt-1 custom-control-label" for="edgeview"></label>
                                                                    </div>
                                                                </td>
                                                                <td class="align-middle">
                                                                    <div class="custom-control custom-checkbox">
                                                                        <input type="checkbox" class="custom-control-input" id="edgeadd" (click)="checkSubmenu('Edge Management','','add',$event)">
                                                                        <label class="small pt-1 custom-control-label" for="edgeadd"></label>
                                                                    </div>
                                                                </td>
                                                                <td class="align-middle">
                                                                    <div class="custom-control custom-checkbox">
                                                                        <input type="checkbox" class="custom-control-input" id="edgeedit" (click)="checkSubmenu('Edge Management','','update',$event)">
                                                                        <label class="small pt-1 custom-control-label" for="edgeedit"></label>
                                                                    </div>
                                                                </td>
                                                                <td class="align-middle">
                                                                    <div class="custom-control custom-checkbox">
                                                                        <input type="checkbox" class="custom-control-input" id="edgedel" (click)="checkSubmenu('Edge Management','','delete',$event)">
                                                                        <label class="small pt-1 custom-control-label" for="edgedel"></label>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr hidden>
                                                                <td class="align-middle">1</td>
                                                                <td class="align-middle">Manage Devices</td>
                                                                <td class="align-middle">
                                                                    <div class="custom-control custom-checkbox">
                                                                        <input type="checkbox" class="custom-control-input" id="manageview" (click)="checkSubmenu('Access Control','User Access Policies','view',$event)" >
                                                                        <label class="small pt-1 custom-control-label" for="manageview"></label>
                                                                    </div>
                                                                </td>
                                                                <td class="align-middle">
                                                                    <div class="custom-control custom-checkbox">
                                                                        <input type="checkbox" class="custom-control-input" id="manageadd" (click)="checkSubmenu('Access Control','User Access Policies','add',$event)">
                                                                        <label class="small pt-1 custom-control-label" for="manageadd"></label>
                                                                    </div>
                                                                </td>
                                                                <td class="align-middle">
                                                                    <div class="custom-control custom-checkbox">
                                                                        <input type="checkbox" class="custom-control-input" id="manageedit" (click)="checkSubmenu('Access Control','User Access Policies','update',$event)">
                                                                        <label class="small pt-1 custom-control-label" for="manageedit"></label>
                                                                    </div>
                                                                </td>
                                                                <td class="align-middle">
                                                                    <div class="custom-control custom-checkbox">
                                                                        <input type="checkbox" class="custom-control-input" id="managedel" (click)="checkSubmenu('Access Control','User Access Policies','delete',$event)">
                                                                        <label class="small pt-1 custom-control-label" for="managedel"></label>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                              <!-- Provisioning Queue -->
                                              <div class="tab-pane container bg-white p-0" id="gg">
                                                <div class="table-responsive text-nowrap">
                                                    <table class="table table-border">
                                                        <thead class="bg-light">
                                                            <tr>
                                                                <th>#</th>
                                                                <th></th>
                                                                <th>View</th>
                                                                <th>Add</th>
                                                                <th>Edit</th>
                                                                <th>Delete</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td class="align-middle">1</td>
                                                                <td class="align-middle">Provisioning Queue</td>
                                                                <td class="align-middle">
                                                                    <div class="custom-control custom-checkbox">
                                                                        <input type="checkbox" class="custom-control-input" id="provisionview" (click)="checkSubmenu('Provisioning Queue','','view',$event)" >
                                                                        <label class="small pt-1 custom-control-label" for="provisionview"></label>
                                                                    </div>
                                                                </td>
                                                                <td class="align-middle">
                                                                    <div class="custom-control custom-checkbox">
                                                                        <input type="checkbox" class="custom-control-input" id="provisionadd" (click)="checkSubmenu('Provisioning Queue','','add',$event)">
                                                                        <label class="small pt-1 custom-control-label" for="provisionadd"></label>
                                                                    </div>
                                                                </td>
                                                                <td class="align-middle">
                                                                    <div class="custom-control custom-checkbox">
                                                                        <input type="checkbox" class="custom-control-input" id="provisionedit" (click)="checkSubmenu('Provisioning Queue','','update',$event)">
                                                                        <label class="small pt-1 custom-control-label" for="provisionedit"></label>
                                                                    </div>
                                                                </td>
                                                                <td class="align-middle">
                                                                    <div class="custom-control custom-checkbox">
                                                                        <input type="checkbox" class="custom-control-input" id="provisiondel" (click)="checkSubmenu('Provisioning Queue','','delete',$event)">
                                                                        <label class="small pt-1 custom-control-label" for="provisiondel"></label>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                                <!-- System Settings -->
                                                <div class="tab-pane container bg-white p-0 " id="cc">
                                                    <div class="table-responsive text-nowrap">
                                                        <table class="table table-border">
                                                            <thead class="bg-light">
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>System Settings</th>
                                                                    <th>View</th>
                                                                    <th>Add</th>
                                                                    <th>Edit</th>
                                                                    <th>Delete</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                <tr>
                                                                    <td class="align-middle">1</td>
                                                                    <td class="align-middle">Provisioning Settings</td>
                                                                    <td class="align-middle">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox" class="custom-control-input" id="provisioningview" (click)="checkSubmenu('System Settings','Provisioning Settings','view',$event)" >
                                                                            <label class="small pt-1 custom-control-label" for="provisioningview"></label>
                                                                        </div>
                                                                    </td>
                                                                    <td class="align-middle">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox" class="custom-control-input" id="provisioningadd" (click)="checkSubmenu('System Settings','Provisioning Settings','add',$event)">
                                                                            <label class="small pt-1 custom-control-label" for="provisioningadd"></label>
                                                                        </div>
                                                                    </td>
                                                                    <td class="align-middle">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox" class="custom-control-input" id="provisioningedit" (click)="checkSubmenu('System Settings','Provisioning Settings','update',$event)">
                                                                            <label class="small pt-1 custom-control-label" for="provisioningedit"></label>
                                                                        </div>
                                                                    </td>
                                                                    <td class="align-middle">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox" class="custom-control-input" id="provisioningdel" (click)="checkSubmenu('System Settings','Provisioning Settings','delete',$event)">
                                                                            <label class="small pt-1 custom-control-label" for="provisioningdel"></label>
                                                                        </div>
                                                                    </td>

                                                                </tr>

                                                                <tr>
                                                                    <td class="align-middle">2</td>
                                                                    <td class="align-middle">Alerts & Notifications</td>
                                                                    <td class="align-middle">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox" class="custom-control-input" id="alertNotificationview" (click)="checkSubmenu('System Settings','Alerts & Notifications','view',$event)" >
                                                                            <label class="small pt-1 custom-control-label" for="alertNotificationview"></label>
                                                                        </div>
                                                                    </td>
                                                                    <td class="align-middle">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox" class="custom-control-input" id="alertNotificationadd" (click)="checkSubmenu('System Settings','Alerts & Notifications','add',$event)">
                                                                            <label class="small pt-1 custom-control-label" for="alertNotificationadd"></label>
                                                                        </div>
                                                                    </td>
                                                                    <td class="align-middle">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox" class="custom-control-input" id="alertNotificationedit" (click)="checkSubmenu('System Settings','Alerts & Notifications','update',$event)">
                                                                            <label class="small pt-1 custom-control-label" for="alertNotificationedit"></label>
                                                                        </div>
                                                                    </td>
                                                                    <td class="align-middle">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox" class="custom-control-input" id="alertNotificationdel" (click)="checkSubmenu('System Settings','Alerts & Notifications','delete',$event)">
                                                                            <label class="small pt-1 custom-control-label" for="alertNotificationdel"></label>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="align-middle">3</td>
                                                                    <td class="align-middle">Reset Settings</td>
                                                                    <td class="align-middle">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox" class="custom-control-input" id="resetSettingview" (click)="checkSubmenu('System Settings','Reset Settings','view',$event)" >
                                                                            <label class="small pt-1 custom-control-label" for="resetSettingview"></label>
                                                                        </div>
                                                                    </td>
                                                                    <td class="align-middle">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox" class="custom-control-input" id="resetSettingadd" (click)="checkSubmenu('System Settings','Reset Settings','add',$event)">
                                                                            <label class="small pt-1 custom-control-label" for="resetSettingadd"></label>
                                                                        </div>
                                                                    </td>
                                                                    <td class="align-middle">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox" class="custom-control-input" id="resetSettingedit" (click)="checkSubmenu('System Settings','Reset Settings','update',$event)">
                                                                            <label class="small pt-1 custom-control-label" for="resetSettingedit"></label>
                                                                        </div>
                                                                    </td>
                                                                    <td class="align-middle">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox" class="custom-control-input" id="resetSettingdel" (click)="checkSubmenu('System Settings','Reset Settings','delete',$event)">
                                                                            <label class="small pt-1 custom-control-label" for="resetSettingdel"></label>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="align-middle">4</td>
                                                                    <td class="align-middle">Logical Groups</td>
                                                                    <td class="align-middle">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox" class="custom-control-input" id="updateview" (click)="checkSubmenu('System Settings','Update','view',$event)" >
                                                                            <label class="small pt-1 custom-control-label" for="updateview"></label>
                                                                        </div>
                                                                    </td>
                                                                    <td class="align-middle">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox" class="custom-control-input" id="updateadd" (click)="checkSubmenu('System Settings','Update','add',$event)">
                                                                            <label class="small pt-1 custom-control-label" for="updateadd"></label>
                                                                        </div>
                                                                    </td>
                                                                    <td class="align-middle">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox" class="custom-control-input" id="updateedit" (click)="checkSubmenu('System Settings','Update','update',$event)">
                                                                            <label class="small pt-1 custom-control-label" for="updateedit"></label>
                                                                        </div>
                                                                    </td>
                                                                    <td class="align-middle">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox" class="custom-control-input" id="updatedel" (click)="checkSubmenu('System Settings','Update','delete',$event)">
                                                                            <label class="small pt-1 custom-control-label" for="updatedel"></label>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="align-middle">5</td>
                                                                    <td class="align-middle">Physical Groups</td>
                                                                    <td class="align-middle">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox" class="custom-control-input" id="physicalview" (click)="checkSubmenu('System Settings','Update','view',$event)" >
                                                                            <label class="small pt-1 custom-control-label" for="updateview"></label>
                                                                        </div>
                                                                    </td>
                                                                    <td class="align-middle">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox" class="custom-control-input" id="physicaladd" (click)="checkSubmenu('System Settings','Update','add',$event)">
                                                                            <label class="small pt-1 custom-control-label" for="physicaladd"></label>
                                                                        </div>
                                                                    </td>
                                                                    <td class="align-middle">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox" class="custom-control-input" id="physicaledit" (click)="checkSubmenu('System Settings','Update','update',$event)">
                                                                            <label class="small pt-1 custom-control-label" for="physicaledit"></label>
                                                                        </div>
                                                                    </td>
                                                                    <td class="align-middle">
                                                                        <div class="custom-control custom-checkbox">
                                                                            <input type="checkbox" class="custom-control-input" id="physicaldel" (click)="checkSubmenu('System Settings','Update','delete',$event)">
                                                                            <label class="small pt-1 custom-control-label" for="physicaldel"></label>
                                                                        </div>
                                                                    </td>
                                                                </tr>

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="row d-flex justify-content-between mx-0">
                                        <div class="col-md-12 text-right">
                                            <button type="button" id="save_button" class="btn btn-success px-4" (click)="addAccessLevel(myForm)" [disabled]="!levelName" >Save</button>
                                        </div>
                                    </div>

                                </div>
                                <!-- /.card -->
                            </div>
                            <!-- /.card-body -->
                        </div>
                    </div>
                </section>
            </form>
        </section>
    </div>
</div>

<!-- Control Sidebar -->
<aside class="control-sidebar control-sidebar-dark">
    <!-- Control sidebar content goes here -->
</aside>
<!-- /.control-sidebar -->
