import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ApiService } from 'src/app/core/services/api.service';
import { CommonService } from 'src/app/core/services/common.service';
import { Socket } from 'ngx-socket-io';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { BehaviorSubject, Subject } from 'rxjs';
import * as CryptoJS from 'crypto-js';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  timeZone = moment.tz.guess();
  timeZoneOffset = new Date().getTimezoneOffset();

  private _devicesFilter: BehaviorSubject<boolean> = new BehaviorSubject(true);
  devicesFilter$ = this._devicesFilter.asObservable();
  toggleDevicesFilter(value: boolean) {
    this._devicesFilter.next(value);
  }

  private _avgcountsFilter: BehaviorSubject<boolean> = new BehaviorSubject(true);
  avgcountsFilter$ = this._avgcountsFilter.asObservable();
  toggleAvgcountsFilter(value: boolean) {
    this._avgcountsFilter.next(value);
  }

  private _sensorsFilter: BehaviorSubject<boolean> = new BehaviorSubject(true);
  sensorsFilter$ = this._sensorsFilter.asObservable();
  toggleSensorsFilter(value: boolean) {
    this._sensorsFilter.next(value);
  }

  private _pqueueFilter: BehaviorSubject<boolean> = new BehaviorSubject(true);
  pqueueFilter$ = this._pqueueFilter.asObservable();
  togglePqueueFilter(value: boolean) {
    this._pqueueFilter.next(value);
  }

  private _teventsFilter: BehaviorSubject<boolean> = new BehaviorSubject(true);
  teventsFilter$ = this._teventsFilter.asObservable();
  toggleTeventsFilter(value: boolean) {
    this._teventsFilter.next(value);
  }

  private _nodemapsFilter: BehaviorSubject<boolean> = new BehaviorSubject(true);
  nodemapsFilter$ = this._nodemapsFilter.asObservable();
  toggleNodemapsFilter(value: boolean) {
    this._nodemapsFilter.next(value);
  }

  private _mapCardSection: BehaviorSubject<boolean> = new BehaviorSubject(true);
  mapCardSection$ = this._mapCardSection.asObservable();
  toggleMapCardSection(value: boolean) {
    this._mapCardSection.next(value);
  }

  private _eventLogCardSection: BehaviorSubject<boolean> = new BehaviorSubject(true);
  eventLogCardSection$ = this._eventLogCardSection.asObservable();
  toggleEventLogCardSection(value: boolean) {
    this._eventLogCardSection.next(value);
  }

  private _openPopup: Subject<boolean> = new Subject();
  openPopup$ = this._openPopup.asObservable();
  togglePopup(value: boolean) {
    this._openPopup.next(value);
  }

  constructor(private cookieService: CookieService, private http: HttpClient, private sanitizer: DomSanitizer, private commonService: CommonService, private socket: Socket,) { }

  /**
   *
   * @param user
   * @description set first time login status
   */
  setFirstTimeLoginStatus(user: any) {
    const data = {
      id: user.id,
      firstTimeLogin: true,
    }
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      params: new HttpParams({ fromString: `email=${user.officialEmail}&userId=${user.id}` })
    };
    return this.http.put(ApiService.URLs.firstTimeLogin, data, options);
  }

  getImage(): any {
    const { user } = this.commonService.getCurrentUserData();

    return this.http.get(ApiService.URLs.profileImage, { responseType: 'blob', params: new HttpParams({ fromString: `email=${user.officialEmail}&userId=${user.id}&base64=${environment.enableBase64}` }) })
      .pipe(
        map((res: any) => {
          const urlCreator = window.URL;
          return this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(res));
        })
      );
  }

  getImageBase64() {
    const { user } = this.commonService.getCurrentUserData();
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      params: new HttpParams({ fromString: `base64=${environment.enableBase64}&userId=${user.id}` })
      //params: new HttpParams({ fromString: `email=${user.officialEmail}&base64=${environment.enableBase64}&userId=${user.id}` })
    };
    return this.http.get(ApiService.URLs.profileImage, options);
  }

  getTotalDevices() {
    const { user } = this.commonService.getCurrentUserData();
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      params: new HttpParams({ fromString: `email=${user.officialEmail}` })
    };

    return this.http.get(ApiService.URLs.getTotalDevices, options);
  }

  getTotalProvisons() {
    const { user } = this.commonService.getCurrentUserData();
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      params: new HttpParams({ fromString: `email=${user.officialEmail}` })
    };

    return this.http.get(ApiService.URLs.getTotalProvisons, options);
  }

  getTotalBlocklock() {
    const { user } = this.commonService.getCurrentUserData();
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      params: new HttpParams({ fromString: `email=${user.officialEmail}` })
    };

    return this.http.get(ApiService.URLs.getTotalBlocklock, options);
  }

  getAllUsers() {
    const { user } = this.commonService.getCurrentUserData();
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    };

    return this.http.get(`${ApiService.URLs.getNewUsers}?email=${user.officialEmail}`, options);
  }

  getTotalEventByDuration(type) {
    const { user } = this.commonService.getCurrentUserData();
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      params: new HttpParams({ fromString: `email=${user.officialEmail}&duration=${type}` })
    };

    return this.http.get(ApiService.URLs.getTotalEventByDuration, options);
  }

  getSparklineGraph(type) {
    const { user } = this.commonService.getCurrentUserData();
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      params: new HttpParams({ fromString: `email=${user.officialEmail}&type=${type}` })
    };

    return this.http.get(ApiService.URLs.getSparklineGraph, options);
  }

  /**
   * Description: get tagname for ui release
   * @description get tagname for ui release
   */
  getUIReleaseVersion(user) {
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      //params: new HttpParams({ fromString: `email=${user.officialEmail}` })
    };
    return this.http.get(ApiService.URLs.getUIReleaseVersion, options);
  }

  /** 
   * Description: get tagname for blocklock release
   * @description get tagname for blocklock release
   */
  getBLReleaseVersion(user) {
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      params: new HttpParams({ fromString: `email=${user.officialEmail}` })
    };
    return this.http.get(ApiService.URLs.getBlReleaseVersion, options);
  }

  connectSocket(socketConnectionId: string = '') {
    const { user } = this.commonService.getCurrentUserData();
    let encryptedEmail = CryptoJS.AES.encrypt(user.officialEmail, 'tenacious').toString();
    encryptedEmail = encryptedEmail.substring(0, 10);
    let encryptedSocketConnectionId = CryptoJS.AES.encrypt(socketConnectionId, 'tenacious').toString();
    encryptedSocketConnectionId = encryptedSocketConnectionId.substring(0, 10);
    this.socket.ioSocket.io.opts.query = { tenantName: user.organizationName, socketConnectionId: socketConnectionId } //new options
    this.socket.ioSocket.io.uri = environment.host//new uri
    this.socket.connect(); //manually connection
    const userDetails = {
      officialEmail: user.officialEmail,

    };

    // Set the user details in the cookie
    //   this.cookieService.set('User', JSON.stringify(userDetails));;

  }

  requestCounts(user?) {
    const edge = this.requestEdgeChainCount();
    const provison = this.requestProvisionEndPointCount();
    const activeEdge = this.requestActiveEdgeCount();
    const events = this.requestEventCount();
    const endpoint = this.requestEndPointCount(user);
    return;
  }

  requestEdgeChainCount() {
    const data = this.socket.emit('requestEdgeChainCount');
    return (data);
  }

  requestActiveEdgeCount() {
    const data = this.socket.emit('requestActiveEdgeCount');
    return (data);
  }

  edgeChainCount() {
    const data = this.socket.fromEvent('receiveEdgeChainCount');
    return (data);
  }

  activeEdgeCount() {
    const data = this.socket.fromEvent('receiveActiveEdgeCount');
    return (data);
  }

  requestEndPointCount(user) {
    const data = this.socket.emit('requestEndPointCount', { userId: user.id, userLevel: user.userLevel, token: user.token });
    return (data);
  }

  endPointCount() {
    const data = this.socket.fromEvent('receiveEndPointCount');
    return (data);
  }

  requestProvisionEndPointCount() {
    const data = this.socket.emit('requestProvisionEndPointCount');
    return (data);
  }

  provisionEndPointCount() {
    const data = this.socket.fromEvent('receiveProvisionEndPointCount');
    return (data);
  }

  requestEventCount() {
    const data = this.socket.emit('requestEventCount');
    return (data);
  }

  eventCount() {
    const data = this.socket.fromEvent('receiveEventCount');
    return (data);
  }

  requestgenerateAlerts() {
    const data = this.socket.emit('requestGenerateAlerts');
    return (data);
  }

  generateAlerts() {
    const data = this.socket.fromEvent('receiveGenerateAlerts');
    return (data);
  }

  requestSparkLine(type: string) {
    const data = this.socket.emit('requestSparkLine', { type: type });
    return (data);
  }

  usersSparklineGraph() {
    const data = this.socket.fromEvent('usersSparkline');
    return (data);
  }

  eventsSparklineGraph() {
    const data = this.socket.fromEvent('eventsSparkline');
    return (data);
  }

  activeEndpointsSparklineGraph() {
    const data = this.socket.fromEvent('activeEndpointsSparkline');
    return (data);
  }

  provisioningQueueSparklineGraph() {
    const data = this.socket.fromEvent('provisioningQueueSparkline');
    return (data);
  }

  totalEndpointsSparklineGraph() {
    const data = this.socket.fromEvent('totalEndpointsSparkline');
    return (data);
  }

  requestEventByDuration(duration: string) {
    const data = this.socket.emit('requestEventByDuration', { duration: duration, timeZone: this.timeZone });
    return (data);
  }

  eventByDuration() {
    const data = this.socket.fromEvent('receiveEventByDuration');
    return (data);
  }


  requesteventsOfSelectedShipment(shipment: any, i: number) {
    const data = this.socket.emit('requestEventsOfSelectedShipment', { shipment: shipment, i: i });
    return (data);
  }

  eventsOfSelectedShipment() {
    const data = this.socket.fromEvent('receiveEventsOfSelectedShipment');
    return (data);
  }

  requestDeviceByDuration(duration: string) {
    const data = this.socket.emit('requestDeviceByDuration', { duration: duration, timeZone: this.timeZone });
    return (data);
  }

  deviceByDuration() {
    const data = this.socket.fromEvent('receiveDeviceByDuration');
    return (data);
  }

  requestAverageSensorData(duration: string, sensorMacAddr: string, blSerialNumber: string, deviceToken: string, shipmentId: string) {
    // tslint:disable-next-line: max-line-length
    const data = this.socket.emit('requestMovingAverageSensorData', { duration, sensorMacAddr, timeZone: this.timeZone, blSerialNumber, deviceToken, shipmentId });
    return (data);
  }
  // requestAverageSensorData(duration: string, sensorName:string, edgeToken:string, endToken:string){
  //   const data = this.socket.emit('requestMovingAverageSensorData', { duration: duration, sensorType:sensorName, timeZone: this.timeZone, edgeToken:edgeToken, endToken:endToken });
  //   return (data);
  // }

  averageSensorData() {
    const data = this.socket.fromEvent('receiveAverageSensorData');
    return (data);
  }

  requestSensorEventsData(duration: string, sensorMacAddr: any, deviceToken: any, shipmentId: any) {
    // console.log("requestSensorEventsData", duration, sensorName, edgeToken, endToken);
    const data = this.socket.emit('requestSensorEventsData', { duration, sensorMacAddr, timeZone: this.timeZone, deviceToken, shipmentId });
    return (data);
  }

  sensorEventsData() {
    const data = this.socket.fromEvent('receiveSensorEventsData');
    return (data);
  }

  requestEvents() {
    const data = this.socket.emit('requestEvents');
    return (data);
  }

  requestSensorList() {
    const data = this.socket.emit('requestSensorList');
    return (data);
  }

  receiveEvents() {
    const data = this.socket.fromEvent('receiveEventList');
    return (data);
  }

  reciveSensor() {
    const data = this.socket.fromEvent('reciveSensorList');
    return (data);
  }

  requestAvgEventDataSensorList(edgeToken: string, endToken: string) {
    const data = this.socket.emit('requestAvgEventDataSensorList', { edgeToken: edgeToken, endToken: endToken });
    return (data);
  }

  requestSensorGraphData(shipmentId: any, trackerId: any, sensorId: any, duration: string) {
    const data = this.socket.emit('requestAverageSensorData', { shipmentId, trackerId, sensorId, duration });
    return (data);
  }

  requestEvnetDataSensorList(edgeToken: string, endToken: string) {
    const data = this.socket.emit('requestEventDataSensorList', { edgeToken: edgeToken, endToken: endToken });
    return (data);
  }

  avgEventDataSensorList() {
    const data = this.socket.fromEvent('reciveAvgEventDataSensorList');
    return (data);
  }
  evnetDataSensorList() {
    const data = this.socket.fromEvent('reciveEventDataSensorList');
    return (data);
  }

  disconnectSocket() {
    if (this.socket) this.socket.disconnect();
  }

  getTotalDevicesGraph(type) {
    const { user } = this.commonService.getCurrentUserData();
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      params: new HttpParams({ fromString: `email=${user.officialEmail}&duration=${type}` })
    };

    return this.http.get(ApiService.URLs.getTotalDevicesGraph, options);
  }

  getAverageSensorGraph(type, sensor) {
    const { user } = this.commonService.getCurrentUserData();
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      params: new HttpParams({ fromString: `email=${user.officialEmail}&duration=${type}&sensorType=${sensor}` })
    };

    return this.http.get(ApiService.URLs.getAverageSensorGraph, options);
  }

  getSensorEvents(type, sensor) {
    const { user } = this.commonService.getCurrentUserData();
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      params: new HttpParams({ fromString: `email=${user.officialEmail}&duration=${type}&sensorType=${sensor}` })
    };

    return this.http.get(ApiService.URLs.getSensorEvents, options);
  }

  public getNotificationData() {
    const { user } = this.commonService.getCurrentUserData();
    this.socket.emit('notificationRequest', user.token);
    return this.socket.fromEvent('notificationReceived');
  }

  public requestLatestEvent() {
    const data = this.socket.fromEvent('receivelatestEvent');
    return (data);
  }

  public latestEvent = new BehaviorSubject<any>([]);
  public latestEvent$ = this.latestEvent.asObservable();
  getLatestEvents(data: any) { this.latestEvent.next(data); }


  /* public getLatestEvent() {
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    };
    let event = 
      {
        "id": "6232cf9bd2bea3ab42a9bcae",
        "deviceToken": "eb2b22bc53b9869cc447bbfe1ea3770269d7931bebdccca2feb8afcf20aca3ba",
        "genesisBlk": "327ad97038f6153addce880dd5051179cb30f40c49844e493482eac3a273bd6e",
        "frameInfo": 30,
        "msgId": 1,
        "timeStamp": 1651561556,
        "deviceUUID": "864431043701707",
        "previousSign": "9d05da1c852f9a4ca895b33a5a61c7438b8a8f59b04cbcbaab0005a1a09608002aeeed731b360e47d85c04eb189d777ea2c5d87d174697c3cb4a8e8722b8b308",
        "payloadLength": 58,
        "payload": "s_id:2b37eef0f041,gps:33.888911,-117.965627,bat:3.90V,TH:24,39,RSSI:20#80c138727834,+20.193;40c138727834,+20.193",
        "signature": "24be085a597400239281b9ac492d90f2f7fabafe8217bd0a6a49d7dfa34992f5819027bfbd86001b84c4b9b512e5a6a44f9d2042751c10cd2af668860ca1df09",
        "crc": "24be085a597400239281b9ac492d90f2f7faba"
      }
    return this.http.post(`${ApiService.URLs.locationAlerts}`, event, options);
  } */

  public trackerAlertsData(shipmentId?, userLevel?, userId?) {
    if (shipmentId)
      return this.http.get(`${ApiService.URLs.trackerAlertsData}?sid=${shipmentId}`);
    else
      return this.http.get(`${ApiService.URLs.trackerAlertsData}?userId=${userId}&userLevel=${userLevel}`);
  }

  public getSmartNotification() {
    return this.socket.fromEvent('receiveSmartNotification');
  }

  public updateSmartNotification() {
    return this.socket.fromEvent('receiveNotificationReadStatus');
  }

  public removedSmartNotification() {
    return this.socket.fromEvent('removedSmartNotification');
  }

  public getShipmentList() {
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      // params: new HttpParams({ fromString: `email=${user.officialEmail}` })
    };
    return this.http.get(ApiService.URLs.getShipmentList, options);
  }


  public getShipmentById(shipmentId: string) {
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
    };
    return this.http.get(`${ApiService.URLs.getShipmentById}/${shipmentId}`, options);
  }


  public getPolygonCoordinates(id: string) {
    return this.http.get(`${ApiService.URLs.locationAlerts}/${id}`)
  }

  requestAlertCount(userLevel, userId, userToken) {
    this.socket.emit('requestAlertCounts', { userLevel, userId, userToken });
    const data = this.socket.fromEvent('receiveAlertCount');
    return data;
  }

  public downloadOverallReport(user, isDownload) {
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      params: new HttpParams({ fromString: `email=${user.officialEmail}&tenant=${user.organizationName}&name=${user.fullName}&timeZone=${user.timeZone}&downloadReport=${isDownload}` })
    };
    if (isDownload) { options['responseType'] = 'blob'; }
    return this.http.get(`${ApiService.URLs.downloadOverallReport}`, options)
  }

}
