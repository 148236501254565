<!-- Main content -->

<section class="content mx-3 mt-4" id="weather">
    <div class="card no-border">
        <div class="card-body p-0 tab-content" id="tab1content">
            <div class="row">
                <div class="col-md-12 tab-contentBg p-3 tab-content cus-rounded-left cus-rounded-right"
                    [ngStyle]="{'background-image': 'url(' + screenDayBgImg + ')'}">
                    <!-- Touch screen tab code starts from here -->
                    <div class="tab-pane container-fluid active">
                        <div class="card mt-1">
                            <div class="card-body p-0">
                                <div class="row mx-0 text-white" id="detail_section">
                                    <ng-container *ngIf="user.zipCode && country; else noData">

                                    <div class="col-md-12 text-center" id="country">
                                        <h4 class="mb-3 font-weight-normal">{{location}}, {{countryName}}</h4>
                                    </div>
                                    <div class="col-md-4 text-center topCard-bgImg"
                                        [ngStyle]="{'background-image': 'url(' + topCardWeaImg + ')'}">

                                    </div>

                                    <div class="col-md-4 text-center pt-2 mt-5 weatherTopcard">
                                        <div class="">
                                            <h1 class="mb-0 font-weight-normal d-inline-block">{{currentTemp}} <sup
                                                    class="">o</sup> </h1>

                                            <div class="weather-unit text-white pl-2 d-inline-block align-top">
                                                <a id="cel" class="cursor-pointer celsius d-block font-weight-normal"
                                                    >C</a>
                                            </div>
                                        </div>

                                        <h4 class="font-weight-normal mt-4 pr-5 pl-2">{{weather}}</h4>
                                        <h6 class="font-weight-normal font-italic text-center pr-5 mb-0">Updated as of
                                            <span id="time-part">{{time}}</span></h6>
                                    </div>

                                    <div class="col-md-4 mt-4 weatherCard-details">
                                        <!--<div class="row pt-2">
                                                <h6 class=" text-uppercase col-md-2 pl-0">Details</h6>
                                                <span class="hr-rule col-md-10"></span>
                                            </div>-->
                                        <h6 class="text-uppercase pl-0 font-weight-normal">
                                            <span>Details</span>
                                        </h6>
                                        <div class="row mb-2 mt-1 mx-0">
                                            <div class="col-md-6 col-lg-4 col-xl-4 text-center" id="feel_like">
                                                <div class="detailsCard col-md-12 col-md-12 pb-1">
                                                    <div class="pt-2">
                                                        <img
                                                            src="../../../assets/images/icons/weather-icons/temperature.png" />
                                                    </div>
                                                    <p class="mb-0 pt-1 infoHeading">Feels Like</p>
                                                    <p class="mb-1 small">{{feelsLike}} <sup>o</sup>c</p>
                                                </div>
                                            </div>

                                            <div class="col-md-6 col-lg-4 col-xl-4 text-center" id="wind">
                                                <div class="detailsCard col-md-12 col-md-12 pb-1">
                                                    <div class="pt-2">
                                                        <img
                                                            src="../../../assets/images/icons/weather-icons/wind.png" />
                                                    </div>
                                                    <p class="mb-0 pt-1 infoHeading">Wind</p>
                                                    <p class="mb-1 small">{{windSpeed}} m/s</p>
                                                </div>
                                            </div>

                                            <div class="col-md-6 col-lg-4 col-xl-4 text-center" id="humidity">
                                                <div class="detailsCard col-md-12 col-md-12 pb-1">
                                                    <div class="pt-2">
                                                        <img
                                                            src="../../../assets/images/icons/weather-icons/humidity.png" />
                                                    </div>
                                                    <p class="mb-0 pt-1 infoHeading">Humidity</p>
                                                    <p class="mb-1 small">{{humidity}}%</p>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="row mb-3 mx-0">
                                            <div class="col-md-6 col-lg-4 col-xl-4 text-center" id="presure">
                                                <div class="detailsCard col-md-12 col-md-12 pb-1">
                                                    <div class="pt-2">
                                                        <img
                                                            src="../../../assets/images/icons/weather-icons/pressure.png" />
                                                    </div>
                                                    <p class="mb-0 pt-1 infoHeading">Presure</p>
                                                    <p class="mb-1 small">{{pressure}} hPa</p>
                                                </div>
                                            </div>

                                            <div class="col-md-6 col-lg-4 col-xl-4 text-center" id="clouds">
                                                <div class="detailsCard col-md-12 col-md-12 pb-1">
                                                    <div class="pt-2">
                                                        <img
                                                            src="../../../assets/images/icons/weather-icons/cloud.png" />
                                                    </div>
                                                    <p class="mb-0 pt-1 infoHeading">Clouds</p>
                                                    <p class="mb-1 small">{{clouds}}%</p>
                                                </div>
                                            </div>

                                            <div class="col-md-6 col-lg-4 col-xl-4 text-center" id="dew_point">
                                                <div class="detailsCard col-md-12 col-md-12 pb-1">
                                                    <div class="pt-2">
                                                        <img
                                                            src="../../../assets/images/icons/weather-icons/drop.png" />
                                                    </div>
                                                    <p class="mb-0 pt-1 infoHeading">Dew Point</p>
                                                    <p class="mb-1 small">{{duePoint}} <sup>o</sup>c</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    </ng-container>
                                    
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
                <!-- /.card -->
            </div>
            <!-- /.card-body -->
        </div>
    </div>
</section>
<ng-template #noData>
    <div class="m-auto">No data found.</div>
</ng-template>
<!-- /.content -->
