import { Component, OnInit, ViewChild } from '@angular/core';
import { NgScrollbar } from 'ngx-scrollbar';
import { CommonService } from 'src/app/core/services/common.service';

@Component({
  selector: 'app-smart-contract',
  templateUrl: './smart-contract.component.html',
  styleUrls: ['./smart-contract.component.css']
})
export class SmartContractComponent implements OnInit {

  @ViewChild(NgScrollbar, { static: true }) scrollbarRef: NgScrollbar;

  constructor(private commonService:CommonService) { }
   accessLevelData: any;
   permissionObj: any;
   ngOnInit(): void {
      this.getAccessLevel();
   }

   getAccessLevel() {
      const { user } = this.commonService.getCurrentUserData();
      this.accessLevelData = user.accesslevel;
      this.permissionObj = this.accessLevelData.permissions;
   }

}
