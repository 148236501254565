import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { UserIdleService } from 'angular-user-idle';
import { Router } from '@angular/router';
import { CommonService } from './core/services/common.service';
import { API_ERROR } from './core/constants/global-error.constants';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  scripts: any;

  constructor(
    private route: Router,
    private userIdle: UserIdleService,
    private commonService: CommonService,
    
  ) {}
  ngOnInit() {
    // Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe(count => {});

    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(() => {
      // console.log('Time is up!');
      this.commonService.logout(API_ERROR.INACTIVE_LOGOUT);
    });
    this.loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${environment.mapAPIKey}&libraries=visualization,places`
    ).then(() => {
      console.log('Map loaded');
    });
  }
  loadScript(name: string) {
    return new Promise<void>((resolve, reject) => {
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = name;
      document.getElementsByTagName('head')[0].appendChild(script);
      console.log('Script Loaded');
      resolve();
    });
  }
}
