import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ApiService } from 'src/app/core/services/api.service';
import { CommonService } from 'src/app/core/services/common.service';

@Injectable({
  providedIn: 'root'
})
export class SysLogService {

  constructor(private http:HttpClient, private commonService:CommonService) { }

  /**
   * @description get user syslogs
   */
  getSystemLogs() {
    const { user } = this.commonService.getCurrentUserData();
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      params: new HttpParams({fromString:`email=${user.officialEmail}&userId=${user.id}`})
    }
    return this.http.get(ApiService.URLs.sysLogs, options);
  }
}
