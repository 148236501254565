<section class="content mx-3 mt-4">
    <div class="card no-border" id="time_clock">
        <div class="card-body p-0 tab-content" id="tab1content">
            <div class="row">
                <div
                    class="col-md-12 bg-white p-3 tab-content cus-rounded-left cus-rounded-right">
                    <!-- User access policies tab code starts from here -->
                    <div class="tab-pane container-fluid active" >
                        <form>
                            <div class="row pt-2">
                                <div class="col-md-12 col-lg-5 col-xl-5 px-3">
                                    <div class="row" *ngIf="userInfo.accesslevel.permissions.utility && 
                                        userInfo?.accesslevel.permissions.utility?.submenu?.timeclock.update"
                                    >
                                        <div class="form-group has-float-label col-md-12 mb-4">
                                            <div class="input-group">
                                                <select name="timeZone" class="form-control" [ngModel]="selectedTz" (ngModelChange)="timeZoneChanged($event)">
                                                    <option selected>Select Timezone</option>
                                                    <option *ngFor="let tz of offsetTmz" [value]="tz.value">
                                                        {{ tz.name }}
                                                    </option>
                                                </select>
                                                <div class="input-group-append">
                                                    <button class="btn bg-gradient-primary cursor-pointer"
                                                    (click)="setTimeZone()">Set Timezone</button>
                                                </div> 
                                            </div>
                                        </div>
                                    </div>

                                    <!--Digital clock code starts from here-->
                                    <div class="row digital-clock bg-light d-flex align-items-center justify-content-center mx-0">
                                        <div class="col-md-12 px-3">
                                            <div class="clock-container py-4">
                                                <div class="clock-col">
                                                    <p class="clock-day clock-timer">
                                                    </p>
                                                    <p class="clock-label">
                                                        Day
                                                    </p>
                                                </div>
                                                <div class="clock-col">
                                                    <p class="clock-hours clock-timer">
                                                    </p>
                                                    <p class="clock-label" id="hours">
                                                        Hours
                                                    </p>
                                                </div>
                                                <div class="clock-col">
                                                    <p class="clock-minutes clock-timer">
                                                    </p>
                                                    <p class="clock-label">
                                                        Min
                                                    </p>
                                                </div>
                                                <div class="clock-col">
                                                    <p class="timer-seconds clock-timer" id="ss">
                                                    </p>
                                                    <p class="clock-label">
                                                        Sec
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <!-- Calender here -->
                                <!-- <div class="col-md-12 col-lg-7 col-xl-7 px-3">
                                    <div class="border pt-3">
                                        <full-calendar #calendar defaultView="dayGridMonth" theme="true" themeSystem="bootstrap" [header]="{
                                    left: 'prev,next today',
                                    center: 'title',
                                    right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
                                    weekNumbers: true,
                                    eventLimit: true }"
                                                        [plugins]="calendarPlugins" [events]=eventsArr>
                                        </full-calendar>
                                    </div>
                                </div> -->
                            </div>
                        </form>
                    </div>
                
                </div>
                <!-- /.card -->
            </div>
            <!-- /.card-body -->
        </div>
    </div>
</section>