import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { Subscription } from 'rxjs';
import { DashboardService } from 'src/app/modules/dashboard/services/dashboard.service';
import { CommunicationChainService } from 'src/app/modules/utility/services/communication-chain.service';
import { calculateBatteryPercentage } from 'src/app/shared/utilites/battery-percentage-calculator.util';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class MapComponent implements OnChanges {

  @Input() page: any = null;
  @Input() hData: any = null;
  @Input() smallMapData: any = null;
  @Input() zoom: number = 12;
  @Input() mapType: any;
  @Input() cluster: any;
  @Input() type: any;
  @Input() status: any;
  @Input() freq: any;
  @Input() id: any;
  @Input() htmlEle: any;
  @Input() pickup: any;
  @Input() destination: any;

  @Output() showLoadShipmentEvent: EventEmitter<boolean> = new EventEmitter();

  public calculateBatteryPercentage = calculateBatteryPercentage;

  private map: any;

  showLoadShipment: boolean = false;
  directionsService: any;
  directionsDisplay: any;
  infoWindow: any;
  bounds: any;
  clusterer: any;
  marker: any;
  interval: any;
  polygon: any;
  circle: any;
  currentMarker: any;
  StartMarker: any;
  EndMarker: any;
  markers: any = [];

  constructor(
    private communicationChainService: CommunicationChainService,
    private dashboardService: DashboardService,
    private ref: ChangeDetectorRef
  ) { }

  ngAfterContentChecked() {
    this.ref.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      clearInterval(this.interval);
      if (changes?.hData && (changes?.hData?.currentValue?.length !== changes?.hData?.previousValue?.length)) {
        if (Array.isArray(changes?.hData?.currentValue)) {
          this.hData = changes?.hData?.currentValue.filter(evnt => evnt.latitude && evnt.longitude)
        }
        try {
          const focusPoint = new google.maps.LatLng(this.hData[0] ? this.hData[0].latitude : this.hData.latitude, this.hData[0] ? this.hData[0].longitude : this.hData.longitude);
          if (this.hData && this.hData.length && this.hData[0].latitude) {
            this.map = new google.maps.Map(document.getElementById(this.htmlEle), {
              zoom: this.zoom,
              mapTypeId: this.mapType,
              center: focusPoint,
              disableDefaultUI: true
            });
            this.directionsService = new google.maps.DirectionsService();
            this.directionsDisplay = new google.maps.DirectionsRenderer();
            this.bounds = new google.maps.LatLngBounds();
            this.infoWindow = new google.maps.InfoWindow();
          }
        } catch {
        }
      }
      this.marker?.setMap(null);
      if (this.htmlEle == "map") {
        this.clusterer?.setMap(null);
      }
    }
    if (this.type === "cluster") {
      this.loadDashboardMap();
    } else if (this.type === "fixed") {
      if (this.smallMapData !== undefined && (changes?.smallMapData?.currentValue?.latitude !== changes?.smallMapData?.previousValue?.latitude)) {
        this.loadSmallMap()
      } else if (this.htmlEle === "map") {
        this.loadFixedMap(this.hData);
      } else if (this.htmlEle === "deviceMap") {

        this.loadFixedMap(this.smallMapData);
      }
    } else if (this.type === "route") {
      if (this.hData !== undefined && (changes?.hData?.currentValue !== changes?.hData?.previousValue)) {
        this.loadRouteMap(this.hData);
      } else {
        // this.showLoadShipmentEvent.emit(this.showLoadShipment)
        // this.showLoadShipment = false;
      }

      if (this.smallMapData !== undefined && (changes?.smallMapData?.currentValue?.length !== changes?.smallMapData?.previousValue?.length)) {
        if (Array.isArray(changes?.smallMapData?.currentValue)) {
          this.smallMapData = changes?.smallMapData?.currentValue.filter(evnt => evnt.latitude && evnt.longitude)
        }
        this.loadSmallMap()
      }
    } else if (changes && changes?.hData?.currentValue && changes?.mapType.currentValue) {
      this.loadDashboardMap();
    }
    if (this.id && this.htmlEle && this.type === "route") {
      if (this.polygon)
        this.polygon.setMap(null);
      this.drawPolygon(this.id);
    }
  }

  loadSmallMap() {
    this.map = new google.maps.Map(document.getElementById(this.htmlEle), {
      zoom: this.zoom,
      mapTypeId: this.mapType,
      disableDefaultUI: true
    });
    if (this.type === "route")
      this.loadRouteMap(this.smallMapData);
    else if (this.type === "fixed")
      this.loadFixedMap(this.smallMapData);
  }


  loadDashboardMap() {
    let time = 0;
    if (document.getElementById('map') === null)
      time = 5
    setTimeout(() => {
      if (this.hData && this.hData[0] && document.getElementById("map")) {
        const focusPoint = new google.maps.LatLng(this.hData[0].latitude, this.hData[0].longitude);
        this.map = new google.maps.Map(document.getElementById("map"), {
          zoom: 5,
          mapTypeId: this.mapType,
          center: focusPoint,
          disableDefaultUI: true
        });
        this.bounds = new google.maps.LatLngBounds();
        this.hData.map((location) => {
          this.bounds.extend(new google.maps.LatLng(location.latitude, location.longitude));
          this.map.fitBounds(this.bounds);
        });
        this.directionsService = new google.maps.DirectionsService();
        this.directionsDisplay = new google.maps.DirectionsRenderer();
        this.infoWindow = new google.maps.InfoWindow();
        this.clusterer = new MarkerClusterer({
          markers: this.cluster,
          map: this.map,
        });
        this.showLoadShipment = false;
      }
    }, time);
  }

  loadFixedMap(event: any) {
    if (event && event.latitude) {
      if (this.circle) {
        this.circle.setMap(null);
      }
      const focusPoint = new google.maps.LatLng(event.latitude, event.longitude);
      this.map = new google.maps.Map(document.getElementById(this.htmlEle), {
        zoom: this.zoom,
        mapTypeId: this.mapType,
        center: focusPoint,
        disableDefaultUI: true,
      });
      this.marker = new google.maps.Marker({
        position: new google.maps.LatLng(event.latitude, event.longitude),
        map: this.map,
      });
      this.drawCircle(event);
      this.map.setZoom(20);
      this.showLoadShipment = false;
    }
    this.loadLiveMap();
  }

  async makeRoute(request, waypoints, waypts, setBounds?) {
    let gstatus;
    try {
      await this.directionsService.route(request, (result, status) => {
        gstatus = status;
        if (status == 'OK') {
          this.directionsDisplay = new google.maps.DirectionsRenderer({
            suppressBicyclingLayer: true,
            suppressMarkers: true,
            preserveViewport: true
          });
          this.directionsDisplay.setMap(this.map);
          this.directionsDisplay.setDirections(result);
          this.bounds.union(result.routes[0].bounds);
          if (setBounds === undefined) {
            this.map.fitBounds(this.bounds);
          }
          if (setBounds) {
            this.map.setCenter(request.destination)
          }
          this.showLoadShipment = false;
          return this.showLoadShipment;
        }
        else
          if (status === google.maps.DirectionsStatus.OVER_QUERY_LIMIT) {
            console.log("error--", status)
            return false;
          }
      });
    } catch {
      if (gstatus !== "OVER_QUERY_LIMIT")
        return await this.zeroRoute(waypoints, waypts);
      else
        console.log("error--", gstatus)
    }
    return this.showLoadShipment;
  }

  async zeroRoute(waypoints, waypts) {
    this.showLoadShipment = true;
    let lastLocation = 0;
    let locations = this.htmlEle === "deviceMap" ? this.smallMapData : this.hData;
    for (let i = 0; i < locations.length - 1; i++) {
      let first = {
        latitude: locations[i].latitude,
        longitude: locations[i].longitude
      }
      let second = {
        latitude: locations[i + 1].latitude,
        longitude: locations[i + 1].longitude
      }
      let a = this.communicationChainService.getDistance(first, second);
      if (a > 1000000) {
        if (lastLocation !== i || lastLocation === 0) {
          var request = {
            origin: new google.maps.LatLng(locations[lastLocation].latitude, locations[lastLocation].longitude),
            destination: new google.maps.LatLng(locations[i].latitude, locations[i].longitude),
            waypoints: waypts.splice(lastLocation, i - 1),
            optimizeWaypoints: true,
            travelMode: google.maps.TravelMode.DRIVING,
          };
          await this.makeRoute(request, waypoints, waypts).then((res) => {
            this.showLoadShipment = res;
            return res
          });
        }
        let flight = [
          { lat: Number(locations[i].latitude), lng: Number(locations[i].longitude) },
          { lat: Number(locations[i + 1].latitude), lng: Number(locations[i + 1].longitude) }
        ];
        new google.maps.Polyline({
          path: flight,
          map: this.map,
          strokeOpacity: 0,
          icons: [
            {
              icon: {
                path: "M 0,-1 0,1",
                strokeOpacity: 1,
                strokeColor: "#3CE64D",
                scale: 4,
              },
              offset: "0",
              repeat: "20px",
            },
          ],
        });
        lastLocation = i + 1;
      }
    }
    if (lastLocation !== locations.length - 1) {
      var request = {
        origin: new google.maps.LatLng(locations[lastLocation].latitude, locations[lastLocation].longitude),
        destination: new google.maps.LatLng(locations[locations.length - 1].latitude, locations[locations.length - 1].longitude),
        waypoints: waypts.splice(lastLocation, locations.length - 2),
        optimizeWaypoints: true,
        travelMode: google.maps.TravelMode.DRIVING,
      };
      await this.makeRoute(request, waypoints, waypts).then((res) => {
        this.showLoadShipment = res;
        return res;
      });
    }
    this.showLoadShipment = false;
    return this.showLoadShipment;

  }

  async loadRouteMap(waypoints: any) {
    waypoints = waypoints.filter((e) => e.latitude !== null && e.longitude !== null)
    if (waypoints.length && waypoints.find((e) => e.latitude && e.longitude)) {
      this.showLoadShipment = true;
      let route = [];
      if (this.markers.length) {
        for (let i = 0; i < this.markers.length; i++)
          this.markers[i].setMap(null)
      }
      for (let i = 0; i < waypoints.length - 1; i++) {
        const pointA = new google.maps.LatLng(waypoints[i + 1].latitude, waypoints[i + 1].longitude);
        const marker = new google.maps.Marker({
          position: pointA,
          map: this.map,
          optimized: false,
          icon: {
            path: google.maps.SymbolPath.CIRCLE,
            scale: 6,
            strokeColor: "#FFFFFF",
            strokeOpacity: 0.2,
            strokeWeight: 1,
            fillColor: "#5384ED",
            fillOpacity: 5,
          },
          visible: true,
        });
        marker.addListener("click", () => {
          this.infoWindow.close();
          this.infoWindow.setContent(waypoints[i + 1].content);
          this.infoWindow.open(marker.getMap(), marker);
        });
        this.markers.push(marker);
      }
      this.directionsDisplay.setMap(this.map);
      if (this.StartMarker)
        this.StartMarker.setMap(null)
      if (this.EndMarker)
        this.EndMarker.setMap(null)
      if (this.currentMarker)
        this.currentMarker.setMap(null)
      this.directionsDisplay.set('directions', null);
      const map = this.map;
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ 'address': this.pickup }, (results, status) => {
        if (status == google.maps.GeocoderStatus.OK) {
          this.StartMarker = new google.maps.Marker({
            position: results[0].geometry.location,
            map: this.map,
            draggable: false,
            icon: "http://maps.google.com/mapfiles/ms/icons/green-dot.png"
          });
        } else {
          console.log('Geocode was not successful for the following reason: ' + status);
        }
      });
      geocoder.geocode({ 'address': this.destination }, (results, status) => {
        if (status == google.maps.GeocoderStatus.OK) {
          this.EndMarker = new google.maps.Marker({
            position: results[0].geometry.location,
            map: this.map,
            draggable: false,
            icon: "http://maps.google.com/mapfiles/ms/icons/red-dot.png"
          });
        } else {
          console.log('Geocode was not successful for the following reason: ' + status);
        }
      });
      this.currentMarker = new google.maps.Marker({
        position: new google.maps.LatLng(waypoints[waypoints.length - 1].latitude, waypoints[waypoints.length - 1].longitude),
        map: this.map,
        draggable: false,
        icon: "http://maps.google.com/mapfiles/ms/icons/bus.png"
      });

      for (let i = 1; i < waypoints.length - 1; i++) {
        route.push({
          location: new google.maps.LatLng(waypoints[i].latitude, waypoints[i].longitude),
          stopover: true,
        });
      }

      let waypointCluster = [[]], j = 0;
      waypointCluster[0].push(waypoints[0])
      for (let i = 0; i < waypoints.length - 1; i++) {
        let first = {
          latitude: waypoints[i].latitude,
          longitude: waypoints[i].longitude
        }
        let second = {
          latitude: waypoints[i + 1].latitude,
          longitude: waypoints[i + 1].longitude
        }

        let a = this.communicationChainService.getDistance(first, second);
        let timediff = waypoints[i + 1].time - waypoints[i].time;

        if (((a > 1000000) && (timediff <= a)) || (a > 100000 && (timediff <= (a / 200)))) {
          let flight = [
            { lat: Number(waypoints[i].latitude), lng: Number(waypoints[i].longitude) },
            { lat: Number(waypoints[i + 1].latitude), lng: Number(waypoints[i + 1].longitude) }
          ];
          new google.maps.Polyline({
            path: flight,
            map: map,
            strokeOpacity: 0,
            icons: [
              {
                icon: {
                  path: "M 0,-1 0,1",
                  strokeOpacity: 1,
                  strokeColor: "#3CE64D",
                  scale: 4,
                },
                offset: "0",
                repeat: "20px",
              },
            ],
          });
          j++;
          waypointCluster[j] = [];
        } else {
          if (waypointCluster[j].length <= 23) {
            waypointCluster[j].push(waypoints[i + 1])
          } else {
            j++;
            waypointCluster[j] = []
            waypointCluster[j].push(waypoints[i])
            waypointCluster[j].push(waypoints[i + 1])
          }
        }
        this.showLoadShipment = false;
      }
      let wayptCluster = [];
      for (let i = 0; i < waypointCluster.length; i++) {
        wayptCluster[i] = [];
        for (let j = 0; j < waypointCluster[i].length; j++) {
          wayptCluster[i].push({
            location: new google.maps.LatLng(waypointCluster[i][j].latitude, waypointCluster[i][j].longitude),
            stopover: true,
          });
        }
      }

      for (let i = 0; i < waypointCluster.length; i++) {
        if (waypointCluster[i].length) {
          let a = await this.calcRoute(waypointCluster[i], wayptCluster[i].slice(1, wayptCluster[i].length - 1));
        }
      }

      // let i = 0;
      // for (i = 0; i < (waypoints.length / 25) - 1; i++) {
      //   if (i) {
      //     this.showLoadShipment = true;
      //     let endPts = waypoints.slice((25 * i) - 1, (25 * (i + 1)));
      //     let way: any = route.slice((25 * i), (25 * (i + 1)));
      //     let a = await this.calcRoute(endPts, way);
      //   } else {
      //     this.showLoadShipment = true;
      //     let endPts = waypoints.slice((25 * i), (25 * (i + 1)));
      //     let way: any = route.slice((25 * i), (25 * (i + 1)));
      //     let a = await this.calcRoute(endPts, way);
      //   }
      // }
      // if (i) {
      //   this.showLoadShipment = true;
      //   let endPts = waypoints.slice((25 * i) - 1, (25 * (i + 1)))
      //   let way: any = route.slice((25 * i), (25 * (i + 1)))
      //   this.showLoadShipment = await this.calcRoute(endPts, way);
      //   this.showLoadShipmentEvent.emit(this.showLoadShipment)
      // } else {
      //   this.showLoadShipment = true;
      //   this.showLoadShipment = await this.calcRoute(waypoints, route);
      //   this.showLoadShipmentEvent.emit(this.showLoadShipment)
      // }
      this.showLoadShipment = false;
      this.showLoadShipmentEvent.emit(this.showLoadShipment)
      if (!this.showLoadShipment)
        this.loadLiveMap()
    } else
      this.showLoadShipment = false;
  }


  async calcRoute(waypoints, waypts) {
    this.showLoadShipment = true;
    var request = {
      origin: new google.maps.LatLng(waypoints[0].latitude, waypoints[0].longitude),
      destination: new google.maps.LatLng(waypoints[waypoints.length - 1].latitude, waypoints[waypoints.length - 1].longitude),
      waypoints: waypts,
      optimizeWaypoints: true,
      travelMode: google.maps.TravelMode.DRIVING,
    };
    await this.makeRoute(request, waypoints, waypts).then((res) => {
      this.showLoadShipment = res;
    });
    return this.showLoadShipment;
  }

  loadLiveMap() {
    if (this.status.toLowerCase() === "started") {
      this.dashboardService.requestLatestEvent().subscribe(async (socketEvent) => {
        let newEvent = socketEvent[1][0];
        if (newEvent.shipmentId === this.id) {
          if (this.type.toLowerCase() === 'movable' || this.type === "route") {
            const data = [{
              battery: Number(newEvent.parsedData.battery.split('V')[0]),
              blueTag: newEvent.blueTag,
              dateString: newEvent.dateString,
              deviceUUID: newEvent.deviceUUID,
              id: newEvent.id,
              signal: newEvent.signal ? newEvent.signal : null,
              latitude: newEvent.parsedData.latitude,
              location: newEvent.parsedData.location,
              longitude: newEvent.parsedData.longitude,
              shipmentId: newEvent.shipmentId,
              temperature: newEvent.temperature,
              timeStamp: newEvent.timeStamp,
              trackerId: newEvent.trackerId,
            }];
            if (data) {
              const event = this.hData[this.hData.length - 1];
              if (event.latitude !== data[0].latitude || event.longitude !== data[0].longitude) {
                var request = {
                  origin: new google.maps.LatLng(event.latitude, event.longitude),
                  destination: new google.maps.LatLng(data[0].latitude, data[0].longitude),
                  travelMode: google.maps.TravelMode.DRIVING,
                };
                await this.makeRoute(request, [], [], true).then((res) => {
                  this.showLoadShipment = res;
                  return res;
                });
                const battery = data[0].battery;
                let batValue = this.calculateBatteryPercentage(battery[0]);
                const batClass = this.calculateBatteryPercentage(battery[0], true);
                let bat = `<i class="fas ${batClass}"></i>`;
                let signal = data[0].signal;
                let signalIcon = signal === 0 ?
                  `<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-wifi-off" viewBox="2 4 10 12">
                                        <path d="M10.706 3.294A12.545 12.545 0 0 0 8 3C5.259 3 2.723 3.882.663 5.379a.485.485 0 0 0-.048.736.518.518 0 0 0 .668.05A11.448 11.448 0 0 1 8 4c.63 0 1.249.05 1.852.148l.854-.854zM8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065 8.448 8.448 0 0 1 3.51-1.27L8 6zm2.596 1.404.785-.785c.63.24 1.227.545 1.785.907a.482.482 0 0 1 .063.745.525.525 0 0 1-.652.065 8.462 8.462 0 0 0-1.98-.932zM8 10l.933-.933a6.455 6.455 0 0 1 2.013.637c.285.145.326.524.1.75l-.015.015a.532.532 0 0 1-.611.09A5.478 5.478 0 0 0 8 10zm4.905-4.905.747-.747c.59.3 1.153.645 1.685 1.03a.485.485 0 0 1 .047.737.518.518 0 0 1-.668.05 11.493 11.493 0 0 0-1.811-1.07zM9.02 11.78c.238.14.236.464.04.66l-.707.706a.5.5 0 0 1-.707 0l-.707-.707c-.195-.195-.197-.518.04-.66A1.99 1.99 0 0 1 8 11.5c.374 0 .723.102 1.021.28zm4.355-9.905a.53.53 0 0 1 .75.75l-10.75 10.75a.53.53 0 0 1-.75-.75l10.75-10.75z"/>
                                      </svg>` :
                  signal === 1 ?
                    `  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-wifi-1" viewBox="2 4 10 12">
                                        <path d="M11.046 10.454c.226-.226.185-.605-.1-.75A6.473 6.473 0 0 0 8 9c-1.06 0-2.062.254-2.946.704-.285.145-.326.524-.1.75l.015.015c.16.16.407.19.611.09A5.478 5.478 0 0 1 8 10c.868 0 1.69.201 2.42.56.203.1.45.07.611-.091l.015-.015zM9.06 12.44c.196-.196.198-.52-.04-.66A1.99 1.99 0 0 0 8 11.5a1.99 1.99 0 0 0-1.02.28c-.238.14-.236.464-.04.66l.706.706a.5.5 0 0 0 .707 0l.708-.707z"/>
                                      </svg>` :
                    signal < 31 ?
                      `<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-wifi-2" viewBox="2 4 10 12">
                                      <path d="M13.229 8.271c.216-.216.194-.578-.063-.745A9.456 9.456 0 0 0 8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065A8.46 8.46 0 0 1 8 7a8.46 8.46 0 0 1 4.577 1.336c.205.132.48.108.652-.065zm-2.183 2.183c.226-.226.185-.605-.1-.75A6.473 6.473 0 0 0 8 9c-1.06 0-2.062.254-2.946.704-.285.145-.326.524-.1.75l.015.015c.16.16.408.19.611.09A5.478 5.478 0 0 1 8 10c.868 0 1.69.201 2.42.56.203.1.45.07.611-.091l.015-.015zM9.06 12.44c.196-.196.198-.52-.04-.66A1.99 1.99 0 0 0 8 11.5a1.99 1.99 0 0 0-1.02.28c-.238.14-.236.464-.04.66l.706.706a.5.5 0 0 0 .708 0l.707-.707z"/>
                                    </svg>` :
                      signal < 52 ?
                        `<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-wifi" viewBox="2 4 10 12">
                                      <path d="M15.384 6.115a.485.485 0 0 0-.047-.736A12.444 12.444 0 0 0 8 3C5.259 3 2.723 3.882.663 5.379a.485.485 0 0 0-.048.736.518.518 0 0 0 .668.05A11.448 11.448 0 0 1 8 4c2.507 0 4.827.802 6.716 2.164.205.148.49.13.668-.049z"/>
                                      <path d="M13.229 8.271a.482.482 0 0 0-.063-.745A9.455 9.455 0 0 0 8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065A8.46 8.46 0 0 1 8 7a8.46 8.46 0 0 1 4.576 1.336c.206.132.48.108.653-.065zm-2.183 2.183c.226-.226.185-.605-.1-.75A6.473 6.473 0 0 0 8 9c-1.06 0-2.062.254-2.946.704-.285.145-.326.524-.1.75l.015.015c.16.16.407.19.611.09A5.478 5.478 0 0 1 8 10c.868 0 1.69.201 2.42.56.203.1.45.07.61-.091l.016-.015zM9.06 12.44c.196-.196.198-.52-.04-.66A1.99 1.99 0 0 0 8 11.5a1.99 1.99 0 0 0-1.02.28c-.238.14-.236.464-.04.66l.706.706a.5.5 0 0 0 .707 0l.707-.707z"/>
                                    </svg>` :
                        signal === 99 ?
                          `<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-reception-0" viewBox="2 4 10 12">
                                      <path d="M0 13.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm4 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm4 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm4 0a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"/>
                                    </svg> `: ''
                let content;
                data[0].timeStamp = new Date(data[0].timeStamp * 1000);
                if (data[0].signal) {
                  content = '<b>' + '<table>' +
                    '<tr>' +
                    '<td>' + 'Lat.' + '</td>' +
                    '<td>' + `&nbsp;&nbsp ${data[0].latitude}` + '</td>' +
                    '</tr>' +
                    '<tr>' +
                    '<td>' + 'Long.' + '</td>' +
                    '<td>' + `&nbsp;&nbsp ${data[0].longitude}` + '</td>' +
                    '</tr>' +
                    '<tr>' +
                    '<td>' + 'Time' + '</td>' +
                    '<td>' + `&nbsp;&nbsp ${data[0].timeStamp.toLocaleDateString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', hour12: true, minute: '2-digit', second: '2-digit' })}` + '</td>' +
                    '</tr>' +
                    '<tr>' +
                    '<td>' + 'Battery' + '</td>' +
                    '<td>' + `&nbsp;&nbsp ${bat} ${batValue}%` + '</td>' +
                    '</tr>' +
                    '<tr>' +
                    '<td>' + 'Signal' + '</td>' +
                    '<td>' + '<b>' + `&nbsp;&nbsp ${signalIcon}${signal}` + '</b>' + ` ` + '</td>' +
                    '</tr>' +
                    '</table>' + '</b>'
                } else {
                  content = '<b>' + '<table>' +
                    '<tr>' +
                    '<td>' + 'Lat.' + '</td>' +
                    '<td>' + `&nbsp;&nbsp ${data[0].latitude}` + '</td>' +
                    '</tr>' +
                    '<tr>' +
                    '<td>' + 'Long.' + '</td>' +
                    '<td>' + `&nbsp;&nbsp ${data[0].longitude}` + '</td>' +
                    '</tr>' +
                    '<tr>' +
                    '<td>' + 'Time' + '</td>' +
                    '<td>' + `&nbsp;&nbsp ${data[0].timeStamp.toLocaleString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', hour12: true, minute: '2-digit', second: '2-digit' })}` + '</td>' +
                    '</tr>' +
                    '<tr>' +
                    '<td>' + 'Battery' + '</td>' +
                    '<td>' + `&nbsp;&nbsp ${bat} ${batValue}%` + '</td>' +
                    '</tr>' +
                    '</table>' + '</b>'

                }
                if (this.hData.findIndex(element1 => element1.latitude === data[0].latitude) === -1 &&
                  this.hData.findIndex(element1 => element1.longitude === data[0].longitude) === -1) {
                  this.hData.push({
                    latitude: data[0].latitude,
                    longitude: data[0].longitude,
                    content: content
                  });
                }
                this.currentMarker.setPosition(new google.maps.LatLng(data[0].latitude, data[0].longitude))
                const marker = new google.maps.Marker({
                  position: new google.maps.LatLng(data[0].latitude, data[0].longitude),
                  map: this.map,
                  optimized: false,
                  icon: {
                    path: google.maps.SymbolPath.CIRCLE,
                    scale: 6,
                    strokeColor: "#FFFFFF",
                    strokeOpacity: 0.2,
                    strokeWeight: 1,
                    fillColor: "#5384ED",
                    fillOpacity: 5,
                  },
                  visible: true,
                });
                marker.addListener("click", () => {
                  this.infoWindow.close();
                  this.infoWindow.setContent(content);
                  this.infoWindow.open(marker.getMap(), marker);
                });
              }
            }
          } else if (this.type.toLowerCase() === 'fixed') {
            if (this.marker)
              this.marker.setMap(null);
            this.marker = new google.maps.Marker({
              position: new google.maps.LatLng(newEvent.parsedData.latitude, newEvent.parsedData.longitude),
              map: this.map
            });
          }
        }
      })
    }
  }

  public async drawPolygon(id) {
    this.dashboardService.getPolygonCoordinates(id).subscribe((data) => {
      if (data !== []) {
        const poly_coord: any = data
        this.polygon = new google.maps.Polygon({
          paths: poly_coord,
          strokeColor: "#FF0000",
          strokeOpacity: 0.1,
          strokeWeight: 0,
          fillColor: "#FF0000",
          fillOpacity: 0.35,
        });
        this.polygon.setMap(this.map);
      } else {
        console.log("data---", data);
      }
    });
  }

  public async drawCircle(location) {
    this.dashboardService.getPolygonCoordinates(this.id).subscribe((data: any) => {
      if (data.length) {
        this.circle = new google.maps.Circle({
          strokeColor: "#FF0000",
          strokeOpacity: 0.1,
          strokeWeight: 0,
          fillColor: "#FF0000",
          fillOpacity: 0.35,
          map: this.map,
          center: { lat: parseFloat(data[0].lat), lng: parseFloat(data[0].lng) },
          radius: location.geofence_radius
        });
        this.circle.setMap(this.map);
      } else {
        const newpickup = this.pickup;
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ address: newpickup }, (results, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            const newlocation = results[0].geometry.location;
            const circleCenter = {
              lat: newlocation.lat(),
              lng: newlocation.lng()
            };
            this.circle = new google.maps.Circle({
              strokeColor: "#FF0000",
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: "#FF0000",
              fillOpacity: 0.25,
              map: this.map,
              center: circleCenter,
              radius: location.geofence_radius,
            });
          }
        });
      }
    });
  }
}
